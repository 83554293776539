import React, { useCallback, useEffect, useState } from 'react';
import {
  Column as TableColumn,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row, Table as BoostrapTable } from 'reactstrap';
import { JsonParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { debounce } from 'lodash';

import { PaginationData, PaginationDataFilter } from '../types';
import SortOrder from './Table/SortOrder';
import SearchablePagination from './Table/Paginations/SearchablePagination';
import { FilterSelectValuesResponseDto, TableInitialSortBy } from 'src/types/api/common';
import ShowTableColumnsDropdown, { useHiddenColumns } from './ShowTableColumnsDropdown';
import { CommonDocumentTypeEnum } from 'src/helpers/Enums/CommonDocumentTypeEnum';
import ExportTableButton from './Table/Buttons/ExportTableButton';

export interface ExportTableLinkProp {
  onExport: (request: PaginationDataFilter | undefined) => Promise<any>;
  type: CommonDocumentTypeEnum;
  fileName: string;
  buttonTitle?: string;
}

export interface FilterProps {
  request?: Promise<FilterSelectValuesResponseDto[]>;
  options?: FilterSelectValuesResponseDto[];
}

export type Column<D extends object> = TableColumn<D> & {
  filterProps?: FilterProps;
  isOverflowVisible?: boolean;
};

interface Props {
  title: string;
  columns: Column<any>[];
  data: PaginationData<any> | undefined;
  onFetchData: (request: PaginationDataFilter | undefined) => Promise<any>;
  searchable?: boolean;
  enableQueryFilter?: boolean;
  renderRowSubComponent?: (row: any) => any;
  rowProps?: (row: any) => any;
  createComponent?: JSX.Element;
  initialSortBy?: TableInitialSortBy[];
  initialFilters?: any;
  striped?: boolean;
  exportLinks?: ExportTableLinkProp[];
  importLink?: JSX.Element;
  disableFiltersInsideTable?: boolean;
  disableFiltersOutsideTable?: boolean;
  refreshOnValueChange?: any;
}

export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 10;
export const SEARCH_DEBOUNCE_DELAY = 200;
export const FETCH_DEBOUNCE_DELAY = 150;

const Table: React.FC<Props> = ({
  title,
  columns,
  data,
  onFetchData,
  searchable,
  renderRowSubComponent,
  createComponent: CreateComponent,
  enableQueryFilter,
  disableFiltersInsideTable = false,
  disableFiltersOutsideTable = false,
  exportLinks,
  importLink,
  initialSortBy,
  initialFilters,
  rowProps,
  striped = true,
  refreshOnValueChange,
}) => {
  const { t } = useTranslation();
  const [innerRefresh, setInnerRefresh] = useState({});
  const [isLoading, setLoading] = useState(false);

  const [queryPage, setQueryPage] = useQueryParam('page', NumberParam);
  const [queryLimit, setQueryLimit] = useQueryParam('limit', NumberParam);
  const [querySort, setQuerySort] = useQueryParam('sort', StringParam);
  const [querySearch, setQuerySearch] = useQueryParam('search', StringParam);
  const [queryFilters, setQueryFilters] = useQueryParam('filters', JsonParam);

  const { hiddenColumns } = useHiddenColumns();

  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    gotoPage,
    setGlobalFilter,
    visibleColumns,
    setPageSize,
    setSortBy,
    state: { pageSize, pageIndex, sortBy, globalFilter, filters },
    setHiddenColumns,
  } = useTable(
    {
      columns,
      title,
      data: data?.data ?? [],
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      defaultCanSort: false,
      autoResetPage: false,
      autoResetResize: false,
      autoResetExpanded: false,
      pageCount: data?.meta.last_page ? data?.meta.last_page + 1 : 1,
      initialState: {
        pageIndex: queryPage ?? DEFAULT_PAGE,
        pageSize: queryLimit ?? DEFAULT_LIMIT,
        sortBy: querySort ? JSON.parse(querySort) : initialSortBy ? initialSortBy : [],
        globalFilter: querySearch ?? undefined,
        filters: queryFilters ? queryFilters : initialFilters ? initialFilters : [],
        hiddenColumns: hiddenColumns,
      },

      stateReducer: (newState, action, previousState) => {
        // triggers data refreshing

        if (action.type == 'refreshData') {
          setInnerRefresh({});
        }

        // // API starts counting pages from 1, react-table counts it from 0, setting default page to 1
        if (
          newState.pageIndex < DEFAULT_PAGE ||
          (previousState.pageSize && newState.pageSize !== previousState.pageSize) ||
          newState.globalFilter !== previousState.globalFilter
        ) {
          return { ...newState, pageIndex: DEFAULT_PAGE };
        }

        return newState;
      },
    },
    useResizeColumns,
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const [searchText, setSearchText] = useState<string>(
    globalFilter == undefined ? '' : globalFilter,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(
    debounce((request: PaginationDataFilter) => {
      setLoading(true);

      return onFetchData(request).finally(() => {
        setLoading(false);
      });
    }, FETCH_DEBOUNCE_DELAY),
    [onFetchData],
  );

  const setFilters = (): void => {
    if (enableQueryFilter) {
      setQueryPage(pageIndex === DEFAULT_PAGE ? undefined : pageIndex);
      setQueryLimit(pageSize === DEFAULT_LIMIT ? undefined : pageSize);
      setQuerySort(sortBy && sortBy.length == 0 ? undefined : JSON.stringify(sortBy));
      setQueryFilters(filters.length ? filters : undefined);
      setQuerySearch(globalFilter);
    }
  };

  useEffect(() => {
    setFilters();
    setSearchText(globalFilter ?? '');

    fetchData({
      page: pageIndex,
      limit: pageSize,
      sort: sortBy,
      search: globalFilter,
      filters: filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, pageIndex, pageSize, sortBy, innerRefresh, filters, refreshOnValueChange]);

  // On filtering changes, reset the page:
  useEffect(() => {
    gotoPage(DEFAULT_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (globalFilter === undefined) {
      setQuerySearch(undefined);
    } else {
      setFilters();
    }

    fetchData({
      page: pageIndex,
      limit: pageSize,
      sort: sortBy,
      search: globalFilter,
      filters: filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  //If table filters resets somehow (by clicking a external link with no parameters), resetting table to default
  useEffect(() => {
    if (querySearch === undefined) {
      setGlobalFilter(undefined);
    }

    if (queryPage === undefined) {
      gotoPage(DEFAULT_PAGE);
    }

    if (data?.data.length == 0) {
      gotoPage(DEFAULT_PAGE);
    }

    if (querySort == undefined && sortBy.length != 0) {
      setSortBy(initialSortBy ? initialSortBy : []);
    }

    if (queryLimit == undefined) {
      setPageSize(DEFAULT_LIMIT);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySearch, querySort, queryPage, queryLimit]);

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setGlobalFilterDebounced = useCallback(
    debounce((value: string | undefined) => {
      setGlobalFilter(value);
    }, SEARCH_DEBOUNCE_DELAY),
    [onFetchData],
  );

  const handleSearch = () => {
    if (searchText.length >= 3) {
      setGlobalFilterDebounced(searchText);
    } else {
      setGlobalFilterDebounced(undefined);
    }
  };

  const resetSearchField = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearchText('');
    setGlobalFilter(undefined);
  };

  // pagination reactstrap column sizes except pagination
  const paginationColumnsSizes = {
    md: 3,
    lg: 3,
    xl: 2,
    sm: 12,
    xs: 12,
  };

  const dateFilterColumnSize = {
    md: 12,
  };

  const columnSizes = CreateComponent
    ? {
        md: 5,
        lg: 5,
        xl: 5,
      }
    : {
        md: 8,
        lg: 8,
        xl: 7,
      };

  return (
    <>
      <Row className={'mb-2'}>
        <Col {...dateFilterColumnSize} className={'advance-filters'}>
          {exportLinks &&
            exportLinks.map((link, index) => (
              <div key={index} className={'download-button'}>
                <ExportTableButton
                  exportLink={link}
                  request={{
                    page: pageIndex,
                    limit: pageSize,
                    sort: sortBy,
                    search: globalFilter,
                    filters: filters,
                  }}
                />
              </div>
            ))}
          {importLink && <div className={'download-button'}>{importLink}</div>}
          {allColumns &&
            !disableFiltersOutsideTable &&
            allColumns
              .filter((column) => column.Filter != undefined)
              .map((column, index) => (
                <div key={index}>
                  {column.Filter
                    ? column.render('Filter', (column as Column<any>).filterProps)
                    : null}
                </div>
              ))}
        </Col>
        <Col {...paginationColumnsSizes} className={'me-auto mb-2 mb-sm-0'}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setGlobalFilter(searchText);
            }}
          >
            {searchable && (
              <div className={'d-flex'}>
                <div className={'form-control input-field-block me-2'}>
                  <div className={'form-input'}>
                    <Input
                      className={'mb-3'}
                      value={searchText}
                      placeholder={
                        isLoading
                          ? t('common.loading_ellipsis')
                          : data?.meta.total
                          ? t('table.search_records_count', { count: data?.meta.total })
                          : t('table.search_records')
                      }
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <button className={'btn-form'} type={'button'} onClick={resetSearchField}>
                    &times;
                  </button>
                </div>
                <button className={'btn btn-primary'} onClick={() => handleSearch}>
                  {t('common.search')}
                </button>
              </div>
            )}
          </form>
        </Col>
        <Col {...columnSizes}>
          <div className="pagination justify-content-center">
            {data?.meta.last_page && data?.meta.last_page > 1 && (
              <SearchablePagination
                pageIndex={pageIndex}
                lastPage={data.meta.last_page}
                gotoPage={gotoPage}
              />
            )}
          </div>
        </Col>
        {CreateComponent && (
          <Col {...paginationColumnsSizes} className={'text-end'}>
            {CreateComponent}
          </Col>
        )}
        <Col {...paginationColumnsSizes} className={'text-end d-flex align-items-start'}>
          <Input
            id={'page-size'}
            className="d-inline-block form-select w-100"
            type={'select'}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize, key) => (
              <option key={key} value={pageSize}>
                {t('common.show')} {pageSize}
              </option>
            ))}
          </Input>
          <ShowTableColumnsDropdown
            columns={columns}
            updateHiddenColumns={(cols) => {
              setHiddenColumns(cols);
            }}
            title={title}
          />
        </Col>
      </Row>

      <BoostrapTable
        responsive={!(columns as Column<any>[]).some((column) => column.isOverflowVisible)}
        striped={striped}
        bordered
        hover
        {...getTableProps()}
      >
        <thead className={'table-secondary'}>
          {headerGroups.map((headerGroup, i) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                    <div className={'d-flex flex-column justify-content-between h-100 gap-2'}>
                      <div className={'d-flex'}>
                        <span className={'me-auto'}>{column.render('Header')}</span>
                        <span>
                          {column.isSorted && (
                            <SortOrder type={column.sortType} isDesc={column.isSortedDesc} />
                          )}
                        </span>
                      </div>

                      {!disableFiltersInsideTable && (
                        <div>
                          {column.Filter
                            ? column.render('Filter', (column as Column<any>).filterProps)
                            : null}
                        </div>
                      )}
                    </div>

                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        {/* we need to set height to correctly work with react-select options menu */}
        <tbody
          {...getTableBodyProps()}
          style={
            rows.length === 0 ? { height: '150px' } : rows.length < 10 ? { height: '500px' } : {}
          }
        >
          {!isLoading && rows.length === 0 && (
            <>
              <tr>
                <td className={'text-center'} colSpan={visibleColumns.length}>
                  {t('table.no_data')}
                </td>
              </tr>
            </>
          )}
          {isLoading &&
            [...Array(pageSize).keys()].map((i) => (
              <tr key={i}>
                <td className={'text-center'}>
                  <div className={'placeholder-glow'}>
                    <span className={'placeholder w-100'} />
                  </div>
                </td>
              </tr>
            ))}
          {!isLoading &&
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr {...row.getRowProps(rowProps ? rowProps(row) : undefined)}>
                    {row.cells.map((cell, i) => {
                      if ((cell.column as Column<any>).isOverflowVisible) {
                        return (
                          <td {...cell.getCellProps()} key={i} className={'table-cell'}>
                            {cell.render('Cell')}
                          </td>
                        );
                      }

                      return (
                        <td {...cell.getCellProps()} key={i} className={'table-cell'}>
                          <div className={'text-truncate'}>{cell.render('Cell')}</div>
                        </td>
                      );
                    })}
                  </tr>
                  {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                  {renderRowSubComponent && row.isExpanded ? (
                    <tr key={i}>
                      <td colSpan={visibleColumns.length}>
                        {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
        </tbody>
      </BoostrapTable>

      <Row className={'mb-2'}>
        <Col {...paginationColumnsSizes} className={'me-auto mb-2 mb-sm-0'} />
        <Col {...columnSizes}>
          <div className="pagination justify-content-center">
            {data?.meta.last_page && data?.meta.last_page > 1 && (
              <SearchablePagination
                pageIndex={pageIndex}
                lastPage={data.meta.last_page}
                gotoPage={gotoPage}
              />
            )}
          </div>
        </Col>
        {CreateComponent && <Col {...paginationColumnsSizes} className={'text-end'} />}
        <Col {...paginationColumnsSizes} className={'text-end'} />
      </Row>
    </>
  );
};

export default Table;
