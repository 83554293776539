// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { default as env } from '../env.json';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const enableSentry = (): void => {
  if ('SENTRY_DSN' in env && env.SENTRY_DSN) {
    Sentry.init({
      dsn: env.SENTRY_DSN,
      integrations: [new BrowserTracing()],
      environment: env.NODE_ENV,
      release: env.VERSION ?? undefined,
      tracesSampleRate: Number.parseFloat(env.SENTRY_DSN) ?? 0,
    });
  }
};
