export { useProfileQuery } from './useProfileQuery';

const queries = {
  profile: 'profile',
  partialReturn: 'partial-return',
  notifications: 'notifications',
} as const;

const statistics = {
  currentMonth: 'statistics.current_month',
  lastMonth: 'statistics.last_month',
  marketingGeneral: 'marketing.statistics.general',
  marketingCurrentMonth: 'marketing.statistics.current_month',
  marketingLastMonth: 'marketing.statistics.last_month',
  generic: 'statistics.generic',
  walletInformation: 'statistics.wallet_information',
  richestWallets: 'statistics.richest_wallets',
  investors: 'statistics.investors',
} as const;

const projects = {
  projectsInvestmentsGroupedInvestments: 'projects.investments.grouped_investments',
} as const;

export { queries, statistics, projects };
