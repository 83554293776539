import React from 'react';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import usePermissions from 'src/helpers/usePermissions';
import { Permissions } from 'src/helpers/auth/permissions';
import ProtocolAnalytic from 'src/pages/Application/Update/Tabs/Protocol/ProtocolAnalytic';
import ProtocolSalesManager from 'src/pages/Application/Update/Tabs/Protocol/ProtocolSalesManager';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';

interface Props extends ApplicationTabProps, ApplicationContextState {}

const TabProtocol: React.FC<Props> = ({ application, showSubmit }) => {
  const { can } = usePermissions();

  if (
    !application?.protocol?.submitted_sales_manager_at &&
    can(Permissions.BO__APPLICATIONS__UPDATE_SALES_MANAGER_PROTOCOL)
  ) {
    return <ProtocolSalesManager showSubmit={showSubmit} />;
  }

  if (can(Permissions.BO__APPLICATIONS__UPDATE_ANALYTIC_PROTOCOL)) {
    return <ProtocolAnalytic showSubmit={showSubmit} />;
  }

  return <></>;
};

export default withApplication(TabProtocol);
