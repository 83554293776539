import React, { useEffect, useState } from 'react';
import {
  SaveStaticPageSeoRequestDto,
  StaticPageSeoResponseDto,
} from 'src/types/api/landingPage/seo';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { defaultTranslatableValue, translatableValueSchema } from 'src/helpers';
import { Col, ColProps, FormGroup } from 'reactstrap';
import LocaleFormik from 'src/components/Form/LocaleFormik/LocaleFormik';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import TextInput from 'src/components/Form/TextInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';

interface SeoLandingPageFormProps {
  staticSeoPages?: StaticPageSeoResponseDto;
  onSubmit: (request: SaveStaticPageSeoRequestDto, helper: any) => Promise<any>;
}

const INITIAL_STATE: SaveStaticPageSeoRequestDto = {
  slug: defaultTranslatableValue(),
  meta_title: defaultTranslatableValue(),
  meta_description: defaultTranslatableValue(),
  h1: defaultTranslatableValue(),
  h2: defaultTranslatableValue(),
  h3: defaultTranslatableValue(),
  h4: defaultTranslatableValue(),
  h5: defaultTranslatableValue(),
  h6: defaultTranslatableValue(),
  alt_texts: {},
};

const SeoLandingPageForm: React.FC<SeoLandingPageFormProps> = ({ staticSeoPages, onSubmit }) => {
  const { t } = useTranslation();

  const StaticPageSeoSchema = Yup.object().shape({
    slug: translatableValueSchema(Yup.string().required()),
    meta_title: translatableValueSchema(Yup.string().required()),
    meta_description: translatableValueSchema(Yup.string().required()),
    h1: translatableValueSchema(Yup.string().nullable()),
    h2: translatableValueSchema(Yup.string().nullable()),
    h3: translatableValueSchema(Yup.string().nullable()),
    h4: translatableValueSchema(Yup.string().nullable()),
    h5: translatableValueSchema(Yup.string().nullable()),
    h6: translatableValueSchema(Yup.string().nullable()),
  });

  const [staticSeoPageRequest, setStaticSeoPageRequest] =
    useState<SaveStaticPageSeoRequestDto>(INITIAL_STATE);

  useEffect(() => {
    if (staticSeoPages) {
      setStaticSeoPageRequest(staticSeoPages);
    }
  }, [staticSeoPages]);

  const colProps: ColProps = {
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <LocaleFormik
        initialValues={staticSeoPageRequest}
        enableReinitialize={true}
        validationSchema={StaticPageSeoSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, locale, setLocale, multiLocaleAttrs }) => {
          return (
            <>
              <LanguageSwitcher locale={locale} setLocale={setLocale} />
              <FormGroup>
                <Col {...colProps}>
                  <TextInput
                    name={'base_slug'}
                    disabled={true}
                    value={staticSeoPages ? staticSeoPages['base_slug'] : ''}
                    placeholder={t('label.base_slug')}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    name={'key'}
                    disabled={true}
                    value={staticSeoPages ? staticSeoPages['key'] : ''}
                    placeholder={t('label.key')}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('slug')} placeholder={t('label.slug')} />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    {...multiLocaleAttrs('meta_title')}
                    placeholder={t('label.meta_title')}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    {...multiLocaleAttrs('meta_description')}
                    placeholder={t('label.meta_description')}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('h1')} placeholder={t('label.h1')} />
                </Col>
                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('h2')} placeholder={t('label.h2')} />
                </Col>
                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('h3')} placeholder={t('label.h3')} />
                </Col>
                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('h4')} placeholder={t('label.h4')} />
                </Col>
                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('h5')} placeholder={t('label.h5')} />
                </Col>
                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('h6')} placeholder={t('label.h6')} />
                </Col>
                {staticSeoPages?.extra_columns.map((extra_column, i) => {
                  return (
                    <Col {...colProps} key={i}>
                      <TextInput
                        {...{
                          ...multiLocaleAttrs(extra_column),
                          name: `alt_texts.` + multiLocaleAttrs(extra_column).name,
                        }}
                        placeholder={t('label.' + extra_column)}
                      />
                    </Col>
                  );
                })}
                <Col {...colProps}>
                  <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
                </Col>
              </FormGroup>
            </>
          );
        }}
      </LocaleFormik>
    </React.Fragment>
  );
};

export default SeoLandingPageForm;
