import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  AmlFrozenStatusChangeLogListResponseDto,
  AmlIssuesLogListResponseDto,
  AmlMarkIssueRequestDto,
  AmlRiskLevelChangeLogListResponseDto,
} from 'src/types/api/amlManagement';

class AmlManagement extends Client {
  api = {
    FETCH_FILTERED_RISK_LEVEL_CHANGE_LOG: `${this.http.baseUrl}/aml-management/risk-level/filter`,
    FETCH_FILTERED_FROZEN_STATUS_CHANGE_LOG: `${this.http.baseUrl}/aml-management/frozen-status/filter`,
    FETCH_FILTERED_ISSUES_LOG: `${this.http.baseUrl}/aml-management/issues/filter`,
    MARK_ISSUE: `${this.http.baseUrl}/aml-management/issues/issue/{auditId}/mark`,
  };

  fetchFilteredRiskLevelChangeLog = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<AmlRiskLevelChangeLogListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_RISK_LEVEL_CHANGE_LOG, { params: request });
  };

  fetchFilteredFrozenStatusChangeLog = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<AmlFrozenStatusChangeLogListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_FROZEN_STATUS_CHANGE_LOG, { params: request });
  };

  fetchFilteredIssuesLog = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<AmlIssuesLogListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_ISSUES_LOG, { params: request });
  };

  markIssue = (
    auditId: string,
    request: AmlMarkIssueRequestDto,
  ): Promise<PaginationData<AmlIssuesLogListResponseDto>> => {
    const url = this.buildUrl(this.api.MARK_ISSUE, { auditId });

    return this.http.put(url, request);
  };
}
export default AmlManagement;
