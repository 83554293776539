import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { Link, useParams } from 'react-router-dom';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import TeamMemberForm from '../TeamMemberForm';
import {
  SaveTeamMemberRequestDto,
  TeamMemberResponseDto,
} from 'src/types/api/landingPage/teamMembers';
import { FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';
import { navigate } from 'src/helpers/navigate';
import { RouteList } from 'src/routes';
import PrimaryButton from 'src/components/Form/PrimaryButton';

const TeamMemberUpdate: React.FC = () => {
  const { t } = useTranslation();
  const { teamMemberId } = useParams<{ teamMemberId: string }>();
  const [teamMemberResponse, setTeamMemberResponse] = useState<TeamMemberResponseDto>();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);

  const onSubmit = useCallback(
    async (request: SaveTeamMemberRequestDto, helpers: FormikHelpers<SaveTeamMemberRequestDto>) => {
      try {
        await Api.teamMembers.updateTeamMember(teamMemberId, request).then((response) => {
          setTeamMemberResponse(response);
          fileUploadRef?.current?.reset();
          success(t('common.updated_success'));
          navigate(RouteList.LANDING_PAGE.TEAM_MEMBERS.LIST);
        });
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }

      return true;
    },
    [teamMemberId, t],
  );

  useEffect(() => {
    Api.teamMembers.fetchTeamMember(teamMemberId).then((response) => {
      setTeamMemberResponse(response);
      setGlobalLoading(false);
    });
  }, [teamMemberId]);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={1} className={'mb-4'}>
            <Link to={RouteList.LANDING_PAGE.TEAM_MEMBERS.LIST}>
              <PrimaryButton title={t('common.back')} type={'button'} />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Card>
              <CardBody>
                <h4 className={'mb-3'}>{t('pages.update_team_member')}</h4>
                <TeamMemberForm
                  onSubmit={onSubmit}
                  teamMember={teamMemberResponse}
                  uploadRef={fileUploadRef}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TeamMemberUpdate;
