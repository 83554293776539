import React from 'react';
import { Input, InputProps, Label } from 'reactstrap';

interface Props extends InputProps {
  label: string;
  isHighlight?: boolean;
}

const TextInput: React.FC<Props> = ({ label, isHighlight, ...props }) => {
  return (
    <div className={isHighlight ? 'site-input highlight' : 'site-input'}>
      <Label className={'label'} for={props.name}>
        {label}
      </Label>

      <Input {...props} type={'text'} />
    </div>
  );
};

export default TextInput;
