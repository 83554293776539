import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Container, Form, FormGroup, UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { setGlobalLoading } from 'src/modules/app/actions';
import TextInput from 'src/components/Form/TextInput';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { success } from 'src/services/toastr';
import Api from 'src/api';
import FileUpload, { CSV_TYPES, FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';
import { CustomEmailListRequestDto } from 'src/types/api/communication';

const EXAMPLE_FILE_URL = 'https://profitus-live.s3.eu-north-1.amazonaws.com/email-list-example.csv';

const INITIAL_STATE: CustomEmailListRequestDto = {
  subject: '',
  message: '',
  media_ids: [],
};

const CustomEmailCreate: React.FC = () => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const [request] = useState<CustomEmailListRequestDto>(INITIAL_STATE);

  const MessageSchema = Yup.object().shape({
    subject: Yup.string().required(),
    message: Yup.string().required(),
  });

  const onSubmit = useCallback(
    (request: CustomEmailListRequestDto, helper: any) => {
      return Api.communication
        .notifyImportedEmailList(request)
        .then(() => {
          success(t('communication.messages_successfully_sent'));
        })
        .catch((e) => {
          helper.setErrors(e.response?.errors);
        });
    },
    [t],
  );

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <div className="d-flex align-items-center mb-4">
              <h4 className="me-2 mb-0">{t('communication.send_emails_to_uploaded_list')}</h4>
              <a target={'_blank'} download href={EXAMPLE_FILE_URL} rel="noreferrer">
                <i className={'fas fa-download'} id="downloadExample" />
              </a>

              <UncontrolledTooltip placement="top" target="downloadExample">
                {t('common.download_example')}
              </UncontrolledTooltip>
            </div>
            <Formik
              initialValues={request}
              enableReinitialize={true}
              validationSchema={MessageSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="mb-3">
                      <label htmlFor={'media_ids'}>{t('common.upload_file')}</label>
                      <FileUpload
                        accept={CSV_TYPES}
                        ref={fileUploadRef}
                        name={'media_ids'}
                        onPresign={(request) => Api.communication.importCsvEmailList(request)}
                      />
                    </div>
                    <div className={'mb-3'}>
                      <TextInput name={'subject'} placeholder={t('label.subject')} />
                    </div>
                    <div className={'mb-3'}>
                      <WYSIWYGInput name={'message'} placeholder={t('label.message')} />
                    </div>
                    <div className={'mt-4 mb-4'}>
                      <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default CustomEmailCreate;
