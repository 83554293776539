import React, { useCallback, useMemo, useState } from 'react';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import SimpleTable from 'src/components/SimpleTable';
import DownloadButton from 'src/components/DownloadButton';
import { Form, Formik, FormikHelpers } from 'formik';
import FileUpload, { FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';
import Api from 'src/api';
import { AttachFilesRequestDto, DocumentResponseDto } from 'src/types/api/common';
import DeleteButton from 'src/components/DeleteButton';
import { FormikProps } from 'formik/dist/types';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';
import { transformErrors } from 'src/helpers';
import IsOutdatedBadge from 'src/components/Badges/IsOutdatedBadge';
import CopyPublicMediaLinkButton from 'src/components/CopyPublicMediaLinkButton';
import { Permissions } from 'src/helpers/auth/permissions';
import usePermissions from 'src/helpers/usePermissions';

interface Props extends ApplicationTabProps, ApplicationContextState {}

const TabDocuments: React.FC<Props> = ({ application, setApplication, showSubmit }) => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);
  const { can } = usePermissions();

  const [initialFormValues] = useState<AttachFilesRequestDto>({
    media_ids: [],
  });

  const columns = useMemo<Column<AttachFilesRequestDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
      },
      {
        Header: t('table.file_name'),
        accessor: 'name',
      },
      {
        Header: t('table.mime_type'),
        accessor: 'mime_type',
      },
      {
        Header: t('table.size'),
        accessor: 'size',
      },
      {
        Header: t('table.information'),
        width: 100,
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div className={'d-flex justify-content-center gap-2 text-center'}>
            <IsOutdatedBadge show={!!cell.row.original.is_outdated} />
          </div>
        ),
      },
      {
        Header: t('table.uploader'),
        accessor: 'uploader',
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<AttachFilesRequestDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        accessor: 'url',
        Cell: (cell: Cell<DocumentResponseDto>) => {
          if (!application) return null;
          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.value} />
              {can(Permissions.BO__MEDIA_GENERATE_PUBLIC_URL) && (
                <CopyPublicMediaLinkButton mediaId={cell.row.original.id} />
              )}
              <DeleteButton
                onDelete={() =>
                  Api.applications
                    .detachDocuments(application.id, cell.row.values.id)
                    .then(setApplication)
                }
              />
            </div>
          );
        },
      },
    ],
    [application, setApplication, t, can],
  );

  const onSubmit = useCallback(
    async (request: AttachFilesRequestDto, helpers: FormikHelpers<any>) => {
      if (!application) return;

      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        const response = await Api.applications.attachDocuments(application.id, request);
        setApplication(response);
        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [application, setApplication],
  );

  if (!application) {
    return <></>;
  }

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.documents.list')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton
              title={t('applications.tabs_submit.documents')}
              tabName={'documents'}
            />
          </div>
        )}
      </div>
      <hr />
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ submitForm, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FileUpload
              ref={fileUploadRef}
              name={'media_ids'}
              onPresign={(request) => Api.applications.uploadDocuments(application.id, request)}
              onDropAccepted={submitForm}
            />
          </Form>
        )}
      </Formik>

      <SimpleTable columns={columns} data={application?.documents ?? []} />
    </React.Fragment>
  );
};

export default withApplication(TabDocuments);
