import Client from './client';
import {
  NotificationResponseDto,
  NotificationShortListResponseDto,
  NotificationShortResponseDto,
} from '../../types/api/notifications';
import { PaginationData, PaginationDataFilter } from '../../types';

class Notifications extends Client {
  api = {
    FETCH_LATEST_NOTIFICATIONS: `${this.http.baseUrl}/notifications/latest`,
    FETCH_ALL_NOTIFICATIONS: `${this.http.baseUrl}/notifications`,
    FETCH_NOTIFICATION: `${this.http.baseUrl}/notifications/notification/{notificationId}`,
    MARK_ALL_AS_READ: `${this.http.baseUrl}/notifications/mark-all-as-read`,
  };
  fetchShortNotifications = (): Promise<NotificationShortListResponseDto> => {
    return this.http.get(this.api.FETCH_LATEST_NOTIFICATIONS);
  };

  markAllAsRead = (): Promise<Response> => {
    return this.http.post(this.api.MARK_ALL_AS_READ);
  };

  fetchNotifications = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<NotificationShortResponseDto>> => {
    return this.http.get(this.api.FETCH_ALL_NOTIFICATIONS, { params: request });
  };

  fetchNotification = (notificationId: string): Promise<NotificationResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_NOTIFICATION, { notificationId });
    return this.http.get(url);
  };
}

export default Notifications;
