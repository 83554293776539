import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  via_email: boolean;
  via_phone: boolean;
}

const DirectMarketingBadge: React.FC<Props> = ({ via_email, via_phone }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <i
        title={t('common.direct_marketing_via_email')}
        className={`fas fa-envelope ${via_email ? 'text-success' : 'text-danger'}`}
      />
      <i
        title={t('common.direct_marketing_via_phone')}
        className={`fas fa-mobile-alt ${via_phone ? 'text-success' : 'text-danger'}`}
      />
    </React.Fragment>
  );
};

export default DirectMarketingBadge;
