import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { success } from 'src/services/toastr';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import TextInput from 'src/components/Form/TextInput';
import { FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';
import { FormikProps } from 'formik/dist/types';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { ReportListResponseDto, UpdateReportRequestDto } from 'src/types/api/reports';

interface Props {
  report: ReportListResponseDto;
  onSubmit: (request: UpdateReportRequestDto) => Promise<any>;
}

const EditReportModal: React.FC<Props> = ({ report, onSubmit }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const [updateReport] = useState<UpdateReportRequestDto>({
    name: report.name ?? '',
  });

  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);

  const ReportSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const onAction = useCallback(
    async (values: UpdateReportRequestDto, helpers) => {
      try {
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        return onSubmit(values).then(() => {
          success(t('common.updated_success'));
          hideModal();
        });
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [hideModal, onSubmit, t],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'lg'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('common.edit')}
          </ModalHeader>
          <Formik
            innerRef={formRef}
            initialValues={updateReport}
            enableReinitialize={true}
            validationSchema={ReportSchema}
            onSubmit={onAction}
          >
            {({ handleSubmit, isSubmitting }) => (
              <>
                <ModalBody className={''}>
                  <Form id={'reportForm'} onSubmit={handleSubmit}>
                    <FormGroup>
                      <div className="mb-3">
                        <TextInput name={'name'} />
                      </div>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      title={t('common.submit')}
                      submitting={isSubmitting}
                      form={'reportForm'}
                    />
                  </Col>
                </ModalFooter>
              </>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default EditReportModal;
