export enum BorrowerPayoutTypeEnum {
  INTEREST = 'interest',
  PARTIAL_RETURN = 'partial_return',

  EARLY_PARTIAL_RETURN = 'early_partial_return',
  EARLY_FINAL_RETURN = 'early_final_return',

  FINAL_RETURN = 'final_return',
  DEBT = 'debt',
  PERMISSION_TO_SELL = 'permission_to_sell',
}
