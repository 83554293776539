import React from 'react';
import WalletsSumByDayChart from 'src/pages/Common/Dashboard/Charts/WalletsSumByDayChart';
import ProjectsWalletsSumByDayChart from 'src/pages/Common/Dashboard/Charts/ProjectsWalletsSumByDayChart';

const WalletInfoDashboardCharts = () => {
  return (
    <>
      <div style={{ display: 'inline-flex' }} className={'w-100'}>
        <div style={{ display: 'inline-flex', justifyContent: 'center' }} className={'w-100'}>
          <WalletsSumByDayChart />
        </div>
        <div style={{ display: 'inline-flex', justifyContent: 'center' }} className={'w-100'}>
          <ProjectsWalletsSumByDayChart />
        </div>
      </div>
    </>
  );
};

export default WalletInfoDashboardCharts;
