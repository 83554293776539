import { useTranslation } from 'react-i18next';
import { NewsResponseDto, SaveNewsRequestDto } from 'src/types/api/landingPage/news';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Col, ColProps, FormGroup } from 'reactstrap';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import TextInput from 'src/components/Form/TextInput';
import { UseNumbers } from 'src/helpers/useNumbers';
import FileUpload, {
  FileUploadHandle,
  IMAGE_TYPES,
} from 'src/components/Form/FileUpload/FileUpload';
import Api from 'src/api';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import CheckboxInput from 'src/components/Form/CheckboxInput';
import { defaultTranslatableValue, translatableValueSchema } from 'src/helpers';
import { SelectInput } from 'src/components/Form/Select';
import { NewsThemeEnum } from 'src/helpers/Enums/News/NewsThemeEnum';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import TitleInput from 'src/components/Form/TitleInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { getEnumSelectValues } from 'src/helpers/Enums/enumHelper';
import LocaleFormik from 'src/components/Form/LocaleFormik/LocaleFormik';
import SimplePictureList from 'src/components/PictureList/SimplePictureList';

interface NewsFormProps {
  news?: NewsResponseDto;
  uploadRef: RefObject<FileUploadHandle> | null;
  onSubmit: (request: SaveNewsRequestDto, helper: any) => Promise<any>;
}

const INITIAL_STATE: SaveNewsRequestDto = {
  title: defaultTranslatableValue(),
  slug: defaultTranslatableValue(),
  theme: NewsThemeEnum.ACHIEVEMENTS,
  description: defaultTranslatableValue(),
  published_at: null,
  media_ids: [],
  visible_landing_page: false,
  order_number: null,
};

const NewsForm: React.FC<NewsFormProps> = ({ news, onSubmit, uploadRef }) => {
  const { t } = useTranslation();
  const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

  const NewsSchema = useMemo(
    () =>
      Yup.object().shape({
        title: translatableValueSchema(Yup.string().required()),
        slug: translatableValueSchema(
          Yup.string()
            .required()
            .matches(slugRegex, t('validation.invalid_slug'))
            .max(255, t('validation.max', { max: 255 })),
        ),
        theme: Yup.string().required(),
        description: translatableValueSchema(Yup.string().required()),
        published_at: Yup.date().required(),
        media_ids: Yup.array().required().min(1).max(1),
        visible_landing_page: Yup.boolean(),
        order_number: Yup.number().nullable(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [newsRequest, setNewsRequest] = useState<SaveNewsRequestDto>(INITIAL_STATE);

  useEffect(() => {
    if (news) {
      setNewsRequest(news);
    }
  }, [news]);

  const colProps: ColProps = {
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <LocaleFormik
        initialValues={newsRequest}
        enableReinitialize={true}
        validationSchema={NewsSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, locale, setLocale, multiLocaleAttrs }) => {
          return (
            <>
              <LanguageSwitcher locale={locale} setLocale={setLocale} />
              <FormGroup>
                <Col {...colProps}>
                  <TitleInput {...multiLocaleAttrs('title')} placeholder={t('label.title')} />
                </Col>
                <Col {...colProps}>
                  <TextInput {...multiLocaleAttrs('slug')} placeholder={t('label.slug')} />
                </Col>
                <Col {...colProps}>
                  <SelectInput
                    name={'theme'}
                    placeholder={t('label.theme')}
                    isMulti={false}
                    options={getEnumSelectValues(NewsThemeEnum, 'news.theme')}
                    locale={locale}
                  />
                </Col>
                <Col {...colProps}>
                  <DatePickerInput name={'published_at'} showTimeSelect={true} />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    name={'order_number'}
                    step={'1'}
                    type={'number'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <WYSIWYGInput
                    {...multiLocaleAttrs('description')}
                    placeholder={t('label.description')}
                  />
                </Col>
                <Col {...colProps}>
                  <FileUpload
                    maxFiles={1}
                    accept={IMAGE_TYPES}
                    ref={uploadRef}
                    name={'media_ids'}
                    onPresign={(request) => Api.news.uploadPicture(request)}
                    disabled={isSubmitting}
                  />
                  {news?.pictures && (
                    <div className="row">
                      <SimplePictureList pictures={news?.pictures} />
                    </div>
                  )}
                </Col>
                <Col {...colProps}>
                  <CheckboxInput
                    name={'visible_landing_page'}
                    placeholder={t('label.visible_landing_page')}
                    showPlaceholder={true}
                  />
                </Col>
                <Col {...colProps}>
                  <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
                </Col>
              </FormGroup>
            </>
          );
        }}
      </LocaleFormik>
    </React.Fragment>
  );
};

export default NewsForm;
