import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PermissionAction from 'src/components/PermissionAction';
import { useTranslation } from 'react-i18next';

export interface BaseTabProps {
  // TODO remove this and use context
  id?: string;
}

export interface TabInfo<Props extends BaseTabProps> {
  name: string;
  iconClass: string;
  component: FunctionComponent<Props>;
  permission: string | string[];
}

interface TabDataProps<T extends BaseTabProps> {
  tabs: TabInfo<T>[];
  props?: T;
  vertical?: boolean;
  initialActive?: string | null;
}

const TabData: React.FC<TabDataProps<any>> = ({ tabs, props, vertical, initialActive }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<TabInfo<BaseTabProps>>(tabs[0]);

  const changeTab = useCallback(
    (tab: TabInfo<BaseTabProps>) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab],
  );

  useEffect(() => {
    if (initialActive) {
      const tab = tabs.find((tab) => tab.name == initialActive);

      if (tab) {
        changeTab(tab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialActive]);

  return (
    <React.Fragment>
      <div className={`nav-direction-${vertical ? 'vertical' : 'horizontal'}`}>
        <Nav tabs justified={true} className={`vertical-nav-tabs bg-blue-400 p-2`}>
          {tabs.map((tab, key) => (
            <PermissionAction key={key} permissions={tab.permission}>
              <NavItem className={`text-start d-flex align-self-stretch ${vertical ? 'mb-1' : ''}`}>
                <NavLink
                  active={activeTab === tab}
                  onClick={() => changeTab(tab)}
                  className={'cursor d-flex align-self-stretch'}
                >
                  <div className={'d-flex align-middle text-light align-items-center'}>
                    <div className={'d-flex align-items-center'}>
                      <i className={`${tab.iconClass} icon-md pe-1`} />
                      <span className="ms-2">{t(tab.name)}</span>
                    </div>
                  </div>
                </NavLink>
              </NavItem>
            </PermissionAction>
          ))}
        </Nav>
        <TabContent activeTab={activeTab.name} className={`p-4`}>
          {tabs.map(({ name, component: Component, permission }, key) => (
            <PermissionAction key={key} permissions={permission}>
              <TabPane tabId={name}>
                {activeTab.name === name && activeTab.component === Component && (
                  <Component {...props} />
                )}
              </TabPane>
            </PermissionAction>
          ))}
        </TabContent>
      </div>
    </React.Fragment>
  );
};

export default TabData;
