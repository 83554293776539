import { ErrorMessage, useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { formatInputPlaceholder } from 'src/helpers';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  hideLabel?: boolean;
}

const PasswordInput: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  const [field, meta, helper] = useField(props.name);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, ...restProps } = props;

  if (!props.placeholder) {
    props.placeholder = formatInputPlaceholder(t('placeholder.' + props.name));
  }

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const isError = meta?.touched && !!meta?.error;

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <>
      <div
        className={`form-label-group in-border position-relative ${isError ? 'is-invalid' : ''}`}
      >
        <input
          onChange={field.onChange}
          onBlur={field.onBlur}
          onInput={() => helper.setTouched(true, true)}
          value={field.value}
          id={props.name}
          autoComplete="on"
          placeholder={props.placeholder}
          className={`form-control form-password${isError ? ' is-invalid' : ''}`}
          type={isPasswordVisible ? 'text' : 'password'}
          {...restProps}
        />
        {field.value.length ? (
          <span
            onClick={togglePasswordVisibility}
            className={`input-password-show ${isPasswordVisible ? 'show' : ''}`}
          >
            {t('common.show')}
          </span>
        ) : (
          ''
        )}
      </div>
      {isError && (
        <div className={'invalid-feedback text-pre'}>
          {props.name && (
            <ErrorMessage
              component={'div'}
              name={props.name}
              render={(msg: string | string[]) => {
                if (typeof msg === 'string') {
                  return msg;
                }

                return msg.join('\n');
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PasswordInput;
