import React, { useEffect, useRef, useState } from 'react';
import { Col, UncontrolledTooltip } from 'reactstrap';
import CopyOnClick from 'src/components/CopyOnClick';
import { ColProps } from 'reactstrap/types/lib/Col';

interface SidebarDataRowProps {
  label: string;
  displayValue?: any | null | undefined;
  value: any | null | undefined;
  isVisible?: boolean;
  colProps?: ColProps;
  tooltip?: string;
  id?: string;
  helperText?: string;
}

const DEFAULT_COLUMN_PROPS: ColProps = {
  lg: 3,
  md: 4,
  sm: 6,
  xs: 12,
};

const SidebarDataRow: React.FC<SidebarDataRowProps> = ({
  label,
  value,
  displayValue,
  isVisible,
  colProps,
  tooltip,
  id,
  helperText,
}) => {
  const [cProps, setCProps] = useState<ColProps>(colProps ?? DEFAULT_COLUMN_PROPS);
  const labelRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    setCProps(colProps ?? DEFAULT_COLUMN_PROPS);
  }, [colProps]);

  if (isVisible !== undefined && !isVisible) {
    return null;
  }

  return (
    <>
      <Col className={'mb-3'} {...cProps}>
        <label ref={labelRef}>
          {tooltip && <i className={'fas fa-info-circle'} />} {label}:
        </label>
        {tooltip && <UncontrolledTooltip target={labelRef}>{tooltip}</UncontrolledTooltip>}
        <div className="fw-bold" id={id}>
          <CopyOnClick value={value !== null && value !== undefined ? value : '-'}>
            {value !== null && value !== undefined ? displayValue : '-'}
          </CopyOnClick>
        </div>
        <span className="text-primary">{helperText}</span>
      </Col>
    </>
  );
};
export default SidebarDataRow;
