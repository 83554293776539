import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Card, CardBody, Col, Container, Form, FormGroup, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import Api from 'src/api';
import { RouteList } from 'src/routes';
import { success } from 'src/services/toastr';
import { setGlobalLoading } from 'src/modules/app/actions';
import TextInput from 'src/components/Form/TextInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import SaveButton from 'src/components/Form/SaveButton';
import { StoreInvoiceRequestDto } from 'src/types/api/payments/invoices';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { InvoiceStatusEnum } from 'src/helpers/Enums/InvoiceStatusEnum';
import { PaginationDataFilter } from 'src/types';
import { formatArray } from 'src/helpers/formatters/common';
import { SelectAsyncInput } from 'src/components/Form/Select';
import { UseNumbers } from 'src/helpers/useNumbers';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import FileUpload, {
  DOCUMENT_TYPES,
  FileUploadHandle,
} from 'src/components/Form/FileUpload/FileUpload';
import { navigate } from 'src/helpers/navigate';

const INITIAL_STATE: StoreInvoiceRequestDto = {
  name: '',
  amount: undefined,
  purpose: '',
  payment_date: undefined,
  status: undefined,
  project_owner_id: '',
  project_id: null,
};

const InvoiceCreate: React.FC = () => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);

  const Schema = Yup.object().shape({
    name: Yup.string().required(),
    amount: Yup.number().required(),
    purpose: Yup.string().required(),
    payment_date: Yup.date().required(),
    status: Yup.string().required(),
    project_owner_id: Yup.string().required(),
    project_id: Yup.string().nullable(),
    media_ids: Yup.array().required().min(1).max(1),
  });

  const [roleRequest] = useState<StoreInvoiceRequestDto>(INITIAL_STATE);
  const [companyId, setCompanyId] = useState<string | null>(null);

  const onSubmit = useCallback(
    async (request: StoreInvoiceRequestDto, helpers: FormikHelpers<StoreInvoiceRequestDto>) => {
      try {
        await Api.payments.invoices.storeInvoice(request);
        success(t('common.created_success'));
        navigate(RouteList.PAYMENTS.INVOICES.LIST);
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }

      return true;
    },
    [t],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const [options] = useState<ReactSelectOption[]>([
    {
      label: t('payments.invoices.status.unpaid'),
      value: InvoiceStatusEnum.UNPAID,
    },
    {
      label: t('payments.invoices.status.paid'),
      value: InvoiceStatusEnum.PAID,
    },
  ]);

  const fetchCompanies = async (inputValue?: string) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
    };

    const response = await Api.payments.invoices.fetchCompaniesBriefList(request);
    const companies: ReactSelectOption[] = [];

    response.data.map((company) => {
      companies.push({
        value: company.id ?? '',
        label: formatArray([company.private_id, company.name]),
      });
    });

    return companies;
  };

  const fetchCompanyProjects = useCallback(
    async (inputValue?: string) => {
      if (!companyId) return [];

      const request: PaginationDataFilter = {
        page: 1,
        limit: 100,
        sort: [],
        search: inputValue,
      };

      const response = await Api.payments.invoices.fetchCompanyProjectsBriefList(
        companyId,
        request,
      );
      const projects: ReactSelectOption[] = [];

      response.data.map((project) => {
        projects.push({
          value: project.id ?? '',
          label: formatArray([project.private_id, project.project_name]),
        });
      });

      return projects;
    },
    [companyId],
  );

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col md={1} className={'mb-4'}>
            <Link to={RouteList.PAYMENTS.INVOICES.LIST}>
              <PrimaryButton title={t('common.back')} type={'button'} />
            </Link>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <h4 className={'mb-4'}>{t('payments.invoices.create')}</h4>
            <Formik
              initialValues={roleRequest}
              enableReinitialize={true}
              validateOnChange={true}
              validateOnBlur={true}
              validationSchema={Schema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className={'mb-3'}>
                      <TextInput name={'name'} />
                    </div>
                    <div className={'mb-3'}>
                      <TextInput
                        name={'amount'}
                        type={'number'}
                        onKeyPress={UseNumbers.preventNonNumericalInput}
                      />
                    </div>
                    <div className={'mb-3'}>
                      <TextAreaInput name={'purpose'} />
                    </div>
                    <div className={'mb-3'}>
                      <DatePickerInput name={'payment_date'} />
                    </div>
                    <div className={'mb-3'}>
                      <SelectInput name={'status'} options={options} />
                    </div>
                    <div className={'mb-3'}>
                      <SelectAsyncInput
                        placeholder={t('label.owner_id')}
                        name={'project_owner_id'}
                        isClearable={true}
                        loadOptions={fetchCompanies}
                        onChange={(option) => {
                          if ((option as ReactSelectOption)?.value) {
                            setCompanyId((option as ReactSelectOption).value as string);
                            setFieldValue('project_id', null);
                          }
                        }}
                      />
                    </div>
                    {values?.project_owner_id && (
                      <div className={'mb-3'}>
                        <SelectAsyncInput
                          placeholder={t('label.project_id')}
                          name={'project_id'}
                          isClearable={true}
                          loadOptions={fetchCompanyProjects}
                        />
                      </div>
                    )}
                    <div className={'mb-3'}>
                      <FileUpload
                        maxFiles={1}
                        accept={DOCUMENT_TYPES}
                        ref={fileUploadRef}
                        name={'media_ids'}
                        onPresign={(request) => Api.payments.invoices.uploadDocument(request)}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className={'mt-4 mb-4'}>
                      <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default InvoiceCreate;
