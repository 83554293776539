import React from 'react';
import { ArrayHelpers } from 'formik/dist/FieldArray';
import { ApplicationSingleSecurityDepositDto } from 'src/types/api/applications';
import ViewSingleSecurityDeposit from 'src/pages/Application/Update/Tabs/Securities/ViewSingleSecurityDeposit';
import CreateSingleSecurityDeposit from 'src/pages/Application/Update/Tabs/Securities/CreateSingleSecurityDeposit';
import { SecurityDepositActionType } from 'src/helpers/Enums/SecurityDepositActionType';
import ModifySingleSecurityDeposit from 'src/pages/Application/Update/Tabs/Securities/ModifySingleSecurityDeposit';
import SplitSingleSecurityDeposit from 'src/pages/Application/Update/Tabs/Securities/SplitSingleSecurityDeposit';
import ReleaseSingleSecurityDeposit from 'src/pages/Application/Update/Tabs/Securities/ReleaseSingleSecurityDeposit';
import SplitMutatedSingleSecurityDeposit from 'src/pages/Application/Update/Tabs/Securities/SplitMutatedSingleSecurityDeposit';

export type SingleSecurityDepositProp = {
  index: number;
  formikArrayHelpers?: ArrayHelpers;
  deposit: ApplicationSingleSecurityDepositDto;
  deposits: ApplicationSingleSecurityDepositDto[];
};

const SingleSecurityDeposit: React.FC<SingleSecurityDepositProp> = (props) => {
  if (props.deposit.action_type === SecurityDepositActionType.RELEASE) {
    return <ReleaseSingleSecurityDeposit {...props} />;
  }

  if (props.deposit.action_type === SecurityDepositActionType.MODIFY) {
    return <ModifySingleSecurityDeposit {...props} />;
  }

  if (props.deposit.action_type === SecurityDepositActionType.SPLIT) {
    return <SplitSingleSecurityDeposit {...props} />;
  }

  if (props.deposit.action_type === SecurityDepositActionType.SPLIT_MUTATED_DEPOSIT) {
    return <SplitMutatedSingleSecurityDeposit {...props} />;
  }

  if (props.deposit.id == null) {
    return <CreateSingleSecurityDeposit {...props} />;
  }

  // else, that means we can perform actions on this
  return <ViewSingleSecurityDeposit {...props} />;
};

export default SingleSecurityDeposit;
