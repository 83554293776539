import React, { useEffect, useState } from 'react';
import { FilterValue } from 'react-table';
import Select from 'react-select';

import { FilterSelectValuesResponseDto } from 'src/types/api/common';

export interface ColumnFilterProps {
  column: {
    filterValue: FilterValue;
    setFilter: (updater: ((filterValue: FilterValue) => FilterValue) | FilterValue) => void;
  };
  request?: Promise<FilterSelectValuesResponseDto[]>;
  options?: FilterSelectValuesResponseDto[];
}

const ColumnMultiSelectFilter: React.FC<ColumnFilterProps> = ({ column, request, options }) => {
  const [data, setData] = useState<FilterSelectValuesResponseDto[]>([]);

  useEffect(() => {
    if (request) {
      request.then((response) => setData(response));
    } else if (options) {
      setData(options);
    }
  }, [options, request]);

  if (!request && !options) return null;

  const handleChange = (values: any) => {
    if (values.length === 0) return column.setFilter(undefined);
    const filterValues: string[] = [];

    values.map((value: any) => {
      filterValues.push(value.value);
    });

    column.setFilter(filterValues || undefined);
  };

  return (
    <>
      {data.length > 0 ? (
        <div onClick={(e) => e.stopPropagation()}>
          <Select
            isMulti={true}
            className={'form-control p-0'}
            options={data.map((option: any) => {
              return { value: option.value, label: option.key };
            })}
            hideSelectedOptions={true}
            controlShouldRenderValue={true}
            closeMenuOnSelect={false}
            onChange={(values) => handleChange(values)}
            defaultValue={
              data.map((option: any) => {
                if (column.filterValue?.includes(option.value)) {
                  return { value: option.value, label: option.key };
                }
              }) ?? []
            }
          />
        </div>
      ) : (
        <>...</>
      )}
    </>
  );
};

export default ColumnMultiSelectFilter;
