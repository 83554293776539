import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import DocumentTemplateList from 'src/pages/DocumentTemplates/List';
import { Permissions } from 'src/helpers/auth/permissions';
import DocumentTemplate from 'src/pages/DocumentTemplates/DocumentTemplate';

const DocumentTemplateRoutes: any = () => [
  <AuthRoute
    path={RouteList.DOCUMENT_TEMPLATES.LIST}
    component={DocumentTemplateList}
    layout={PageLayout}
    permissions={[Permissions.BO__DOCUMENT_TEMPLATES__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.DOCUMENT_TEMPLATES.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.DOCUMENT_TEMPLATES.VIEW}
    component={DocumentTemplate}
    layout={PageLayout}
    permissions={[Permissions.BO__DOCUMENT_TEMPLATES__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.DOCUMENT_TEMPLATES.VIEW}
    exact
  />,
];

export default DocumentTemplateRoutes;
