import create from 'zustand';

interface Actions {
  borrowerPayoutRecalculateLoading: boolean;
  setBorrowerPayoutRecalculateLoading: (loading: boolean) => void;

  partialReturnPreviewRecalculateLoading: boolean;
  setPartialReturnPreviewRecalculateLoading: (loading: boolean) => void;

  partialReturnConfirmRecalculateLoading: boolean;
  setPartialReturnConfirmRecalculateLoading: (loading: boolean) => void;
}

const usePusherStoreState = create<Actions>()((set) => ({
  borrowerPayoutRecalculateLoading: false,
  setBorrowerPayoutRecalculateLoading: (loading) =>
    set({ borrowerPayoutRecalculateLoading: loading }),

  partialReturnPreviewRecalculateLoading: false,
  setPartialReturnPreviewRecalculateLoading: (loading) =>
    set({ partialReturnPreviewRecalculateLoading: loading }),

  partialReturnConfirmRecalculateLoading: false,
  setPartialReturnConfirmRecalculateLoading: (loading) =>
    set({ partialReturnConfirmRecalculateLoading: loading }),
}));

export default usePusherStoreState;
