import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik } from 'formik';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import { success } from 'src/services/toastr';
import SaveButton from 'src/components/Form/SaveButton';
import DenyButton from 'src/components/Form/DenyButton';
import {
  BorrowerPayoutResponseDto,
  PrecalculateBorrowerPayoutResponseDto,
} from 'src/types/api/payments/borrowerPayouts';

interface Props {
  debtInfo: PrecalculateBorrowerPayoutResponseDto;
  borrowerPayout: BorrowerPayoutResponseDto;
  onAction: (request: {
    received_at: Date | null;
    skip_recalculation: boolean | null;
  }) => Promise<any>;
  onException?: () => void;
}

const DebtWarningModal: React.FC<Props> = ({ debtInfo, borrowerPayout, onAction, onException }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const onSubmit = useCallback(
    async (request: BorrowerPayoutResponseDto) => {
      if (borrowerPayout) {
        return onAction({ received_at: request.received_at, skip_recalculation: null })
          .then(() => {
            success(t('payments.borrower_payouts.recalculate.success'));
            hideModal();
          })
          .catch(() => {
            onException && onException();
          });
      }
    },
    [borrowerPayout, onAction, t, hideModal, onException],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('payments.borrower_payouts.confirm.debt.title')}
          </ModalHeader>
          <Formik initialValues={borrowerPayout} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <div>
                    {t('payments.borrower_payouts.debt_amount')}:{' '}
                    {t('common.money', { value: debtInfo.debt_amount })}
                  </div>
                  <div>
                    {t('payments.borrower_payouts.allowed_amount_to_pay')}:{' '}
                    {t('common.money', { value: debtInfo.allowed_amount_to_pay })}
                  </div>
                  <div>
                    {t('payments.borrower_payouts.total_for_borrower')}:{' '}
                    {t('common.money', { value: debtInfo.total_for_borrower })}
                  </div>
                  <div>
                    {t('payments.borrower_payouts.wallet_balance')}:{' '}
                    {t('common.money', { value: debtInfo.wallet_balance })}
                  </div>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      title={t('payments.borrower_payouts.recalculate')}
                      submitting={isSubmitting}
                    />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default DebtWarningModal;
