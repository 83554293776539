import React from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';

import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { useLocalStorage } from 'src/helpers';

const TEXT = [
  'You have a very important message from the technology support team! 🤩 💌',
  "We haven't seen each other for a long time, so we're sending you virtual wishes and congratulations on the occasion of the upcoming holidays in a way that's convenient for us! 🍾 🎉",
  'Wishing you a life so fantastic that even aliens are jealous! 👾 Sending you cosmic vibes of joy and laughter. May your life be a constellation of happiness, and your troubles vanish like dark matter! 🚀',
  'Merry Christmas! 🎁 🎄 ❄️',
];

const useTypewriter = (textArray: string[], speed = 50) => {
  const [displayText, setDisplayText] = React.useState('');
  const [currentTextIndex, setCurrentTextIndex] = React.useState(0);
  const [currentCharIndex, setCurrentCharIndex] = React.useState(0);

  React.useEffect(() => {
    const typingInterval = setInterval(() => {
      if (currentCharIndex < textArray[currentTextIndex].length) {
        setDisplayText(
          (prevText) => prevText + textArray[currentTextIndex].charAt(currentCharIndex),
        );
        setCurrentCharIndex((prevIndex) => prevIndex + 1);
      } else if (currentTextIndex < textArray.length - 1) {
        setCurrentTextIndex((prevIndex) => prevIndex + 1);
        setCurrentCharIndex(0);
        setDisplayText((prevText) => prevText + '<br /><br />');
      } else {
        clearInterval(typingInterval);
      }
    }, speed);

    return () => {
      clearInterval(typingInterval);
    };
  }, [textArray, currentCharIndex, currentTextIndex, speed]);

  return displayText;
};

const Modal = ({ hideModal }: { hideModal: () => void }) => {
  const displayText = useTypewriter(TEXT);
  return (
    <React.Fragment>
      <ModalComponent size={'xl'} className="modal-christmas">
        <div className={'p-3'}>
          <ModalHeader
            toggle={hideModal}
            close={<ModalCloseButton />}
            tag={'h2'}
            className={'mt-2'}
          >
            Hello Superheroes! 🌟
          </ModalHeader>
          <ModalBody className="d-flex">
            <div className="wishes-text">
              <div dangerouslySetInnerHTML={{ __html: displayText }} />
            </div>
            <iframe
              src="https://giphy.com/embed/S99cgkURVO62qemEKM"
              width="700px"
              height="500px"
              frameBorder="0"
              className="giphy-embed"
              allowFullScreen
            ></iframe>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

const ChristmasWishes: React.FC<{ isGuest: boolean }> = ({ isGuest }) => {
  const { hideModal, showModal } = useGlobalModalContext();
  const [isShown, setShown] = useLocalStorage<boolean>('christmas_modal', false);

  const currentDate = new Date();
  const isChristmasTime =
    currentDate.getMonth() === 11 && (currentDate.getDate() >= 12 || currentDate.getDate() <= 31);

  React.useEffect(() => {
    if (isGuest || isShown || !isChristmasTime) return;

    const delay = 5000;
    const timeoutId = setTimeout(() => {
      showModal(<Modal hideModal={hideModal} />);
      setShown(true);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGuest]);

  return null;
};

export default ChristmasWishes;
