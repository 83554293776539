import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { Card, CardBody, Container } from 'reactstrap';
import Table from 'src/components/Table';
import Api from 'src/api';
import { setGlobalLoading } from 'src/modules/app/actions';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { BonusListResponseDto } from 'src/types/api/bonusSystem';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';
import { generatePath, Link } from 'react-router-dom';
import { RouteList } from 'src/routes';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { BonusConditionEnum } from 'src/helpers/Enums/BonusSystem/BonusConditionEnum';
import ColumnMultiSelectFilter from 'src/components/Table/Filters/ColumnMultiSelectFilter';
import { getEnumFilterSelectValues } from 'src/helpers/Enums/enumHelper';
import { BonusTypeEnum } from 'src/helpers/Enums/BonusSystem/TypeEnum';
import { BonusStatusEnum } from 'src/helpers/Enums/BonusSystem/StatusEnum';

const BonusSystemList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<BonusListResponseDto>>();
  const [shouldShowAllTypes, setShouldShowAllTypes] = useState<boolean>(false);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  // takes BonusSystemEnum and instead of `affiliate_reward_v2` & `affiliate_reward` returns `affiliate`
  const transformBonusSystemEnum = (enumObj: any) => {
    const result: any = {};
    for (const key in enumObj) {
      if (enumObj[key] === 'affiliate_reward') {
        result[key] = 'affiliate';
      } else if (enumObj[key] !== 'affiliate_reward_v2') {
        result[key] = enumObj[key];
      }
    }
    return result;
  };

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      if (request?.filters) {
        request.filters = request?.filters.filter((filter) => filter.id !== 'show_all_types');

        request.filters.push({
          id: 'show_all_types',
          value: shouldShowAllTypes,
        });
      }

      return Api.bonusSystem.fetchFilteredBonuses(request).then(setData);
    },
    [shouldShowAllTypes],
  );

  const handleOnCheckboxChange = useCallback((e) => {
    setShouldShowAllTypes(e.target.checked);
  }, []);

  const columns = useMemo<Column<BonusListResponseDto>[] | any>(() => {
    return [
      {
        Header: t('table.investor'),
        accessor: 'private_id',
        sortType: 'string',
        disableSortBy: true,
      },
      {
        Header: t('table.project_name'),
        sortType: 'string',
        accessor: 'projects',
        Cell: (cell: Cell<BonusListResponseDto>) => {
          return cell.row.original.projects
            .map(function (project) {
              let title = project.private_id ?? '';

              if (project.project_name) title = title + ' - ' + project.project_name;

              return title;
            })
            .join(', ');
        },
        width: 200,
      },
      {
        Header: t('table.code'),
        accessor: 'code',
        sortType: 'text',
        disableSortBy: true,
      },
      {
        Header: t('table.value'),
        sortType: 'number',
        Cell: (cell: Cell<BonusListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {row.bonus_condition === BonusConditionEnum.AMOUNT
                ? t('common.money', { value: row.amount })
                : t('common.percents', { value: row.percentage })}
            </div>
          );
        },
      },
      {
        Header: t('table.summable'),
        accessor: 'summable',
        sortType: 'boolean',
        disableSortBy: true,
        Cell: (cell: Cell<BonusListResponseDto>) => (
          <div>{cell.value ? t('common.yes') : t('common.no')}</div>
        ),
      },
      {
        Header: t('table.visible'),
        accessor: 'visible',
        sortType: 'boolean',
        disableSortBy: true,
        Cell: (cell: Cell<BonusListResponseDto>) => (
          <div>{cell.value ? t('common.yes') : t('common.no')}</div>
        ),
      },
      {
        Header: t('table.multiple'),
        accessor: 'multiple',
        sortType: 'boolean',
        disableSortBy: true,
        Cell: (cell: Cell<BonusListResponseDto>) => (
          <div>{cell.value ? t('common.yes') : t('common.no')}</div>
        ),
      },
      {
        Header: t('table.sellable'),
        accessor: 'sellable',
        sortType: 'boolean',
        disableSortBy: true,
        Cell: (cell: Cell<BonusListResponseDto>) => (
          <div>{cell.value ? t('common.yes') : t('common.no')}</div>
        ),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'text',
        width: 200,
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          options: getEnumFilterSelectValues(BonusStatusEnum, 'bonus_system.status'),
        },
        Cell: (cell: Cell<BonusListResponseDto>) => (
          <div>{t(`bonus_system.status.${cell.value}`)}</div>
        ),
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        sortType: 'text',
        Filter: ColumnMultiSelectFilter,
        width: 300,
        filterProps: {
          options: getEnumFilterSelectValues(
            transformBonusSystemEnum(BonusTypeEnum),
            'bonus_system.types',
          ),
        },
        Cell: (cell: Cell<BonusListResponseDto>) => (
          <div>{t(`bonus_system.types.${cell.value}`)}</div>
        ),
      },
      {
        Header: t('table.start_at'),
        accessor: 'start_at',
        sortType: 'datetime',

        Cell: (cell: Cell<BonusListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.expire_at'),
        accessor: 'expire_at',
        sortType: 'datetime',

        Cell: (cell: Cell<BonusListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 100,
        Cell: (cell: Cell<BonusListResponseDto>) => {
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                permissions={Permissions.BO__BONUS_SYSTEM__EDIT}
                route={generatePath(RouteList.BONUS_SYSTEM.UPDATE, {
                  bonusId: cell.row.original.id,
                })}
              />
            </div>
          );
        },
      },
    ];
  }, [t]);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <div className={'d-flex align-items-center mb-4'}>
              <h4 className={'m-0'}>{t('menu.bonus_system')}</h4>
            </div>
            <div className={'w-25'}>
              <input
                type="checkbox"
                name={'showAllTypes'}
                className={'me-2'}
                onChange={handleOnCheckboxChange}
              />
              <label htmlFor="showAllTypes">{t('bonus_system.filter.show_all_types')}</label>
            </div>
            <HiddenColumnsProvider title={'BonusSystemListIndex'}>
              <Table
                title={'BonusSystemListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                disableFiltersOutsideTable={true}
                createComponent={
                  <PermissionAction permissions={Permissions.BO__BONUS_SYSTEM__STORE}>
                    <Link to={RouteList.BONUS_SYSTEM.CREATE} className={'btn btn-primary w-100'}>
                      {t('common.create_new')}
                    </Link>
                  </PermissionAction>
                }
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default BonusSystemList;
