import React, { useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import FileUpload, {
  DOCUMENT_TEMPLATE_DEVELOPER_INVESTOR_AGREEMENT,
  FileUploadHandle,
} from 'src/components/Form/FileUpload/FileUpload';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { FormikProps } from 'formik/dist/types';
import DataBlock from 'src/components/DataBlocks/DataBlock';
import DownloadButton from 'src/components/DownloadButton';
import { SelectInput } from 'src/components/Form/Select';
import { getEnumSelectValues } from 'src/helpers/Enums/enumHelper';
import { InvestorProjectLoanAgreementDocumentTypeEnum } from 'src/helpers/Enums/Project/InvestorProjectLoanAgreementDocumentTypeEnum';
import SaveButton from 'src/components/Form/SaveButton';
import { UpdateInvestorProjectLoanAgreementTypeRequestDto } from 'src/types/api/projects';
import * as Yup from 'yup';
import { success } from 'src/services/toastr';
import StreamFileButton from 'src/components/Table/Buttons/StreamFileButton';

type Props = ProjectContextState;

const InvestorLoanAgreementSection: React.FC<Props> = ({ project, setProject }) => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);

  const [initialFormValues] = useState<UpdateInvestorProjectLoanAgreementTypeRequestDto>({
    type: project?.investor_loan_agreement_template
      ? InvestorProjectLoanAgreementDocumentTypeEnum.CUSTOM
      : InvestorProjectLoanAgreementDocumentTypeEnum.DEFAULT,
    media_ids: [],
  });

  const schema = Yup.object().shape({
    type: Yup.string()
      .required()
      .oneOf(Object.values(InvestorProjectLoanAgreementDocumentTypeEnum)),
    media_ids: Yup.array().max(1),
  });

  const onSubmit = useCallback(
    async (
      request: UpdateInvestorProjectLoanAgreementTypeRequestDto,
      helpers: FormikHelpers<UpdateInvestorProjectLoanAgreementTypeRequestDto>,
    ) => {
      if (!project) return;

      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        const response = await Api.projects.updateInvestorLoanAgreementDocumentTemplate(
          project.id,
          request,
        );

        success(t('common.success'));
        setProject(response);
        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [project, setProject, t],
  );

  if (!project) {
    return <></>;
  }

  return (
    <>
      <div className={'d-flex justify-content-between mt-5'}>
        <div>
          <h3>{t('projects.documents.types.investor_loan_agreement_template')}</h3>
        </div>
        <div>
          <StreamFileButton
            buttonTitle={t('common.preview')}
            fileName={'InvestorLoanAgreement'}
            extension={'docx'}
            request={() => Api.projects.previewInvestorLoanTemplate(project.id)}
          />
        </div>
      </div>
      <hr />
      <div className={'mb-5'}>
        <div className={'mb-4'}>
          {project.investor_loan_agreement_template && (
            <Row>
              <DataBlock
                label={t('table.file_name')}
                value={project.investor_loan_agreement_template.name}
              />
              <DataBlock
                label={t('table.created_at')}
                value={t('common.date_full', {
                  date: project.investor_loan_agreement_template.created_at,
                })}
              />
              <DataBlock
                label={t('table.size')}
                value={project.investor_loan_agreement_template.size}
              />
              {project.investor_loan_agreement_template.url && (
                <Col>
                  <div className={'float-end'}>
                    <DownloadButton url={project.investor_loan_agreement_template.url} />
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validationSchema={schema}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <div className={'mb-4'}>
                <SelectInput
                  name={'type'}
                  placeholder={t('label.type')}
                  isMulti={false}
                  options={getEnumSelectValues(
                    InvestorProjectLoanAgreementDocumentTypeEnum,
                    'projects.documents.types.investor_loan_agreement_type',
                  )}
                />
              </div>
              {values.type == InvestorProjectLoanAgreementDocumentTypeEnum.CUSTOM && (
                <div className={'mb-4'}>
                  <FileUpload
                    ref={fileUploadRef}
                    name={'media_ids'}
                    onPresign={(request) =>
                      Api.projects.uploadInvestorLoanAgreementDocumentTemplate(project.id, request)
                    }
                    accept={DOCUMENT_TEMPLATE_DEVELOPER_INVESTOR_AGREEMENT}
                    maxFiles={1}
                  />
                </div>
              )}
              <div className={'mb-4'}>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default withProject(InvestorLoanAgreementSection);
