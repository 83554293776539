import Client from './client';
import {
  JobPositionResponseDto,
  SaveJobPositionRequestDto,
} from 'src/types/api/landingPage/jobPosition';
import { PaginationData, PaginationDataFilter } from 'src/types';

class JobPosition extends Client {
  api = {
    FETCH_JOB_POSITION: `${this.http.baseUrl}/job-positions/job-position/{jobPositionId}`,
    FETCH_JOB_POSITIONS: `${this.http.baseUrl}/job-positions/filter`,
    CREATE_JOB_POSITION: `${this.http.baseUrl}/job-positions/job-position`,
    UPDATE_JOB_POSITION: `${this.http.baseUrl}/job-positions/job-position/{jobPositionId}`,
    DELETE_JOB_POSITION: `${this.http.baseUrl}/job-positions/job-position/{jobPositionId}`,
  };

  fetchJobPosition = (jobPositionId: number | string): Promise<JobPositionResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_JOB_POSITION, { jobPositionId });

    return this.http.get(url);
  };

  fetchFilteredJobPositions = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<JobPositionResponseDto>> => {
    return this.http.get(this.api.FETCH_JOB_POSITIONS, { params: request });
  };

  createJobPosition = (request: SaveJobPositionRequestDto): Promise<JobPositionResponseDto> => {
    return this.http.post(this.api.CREATE_JOB_POSITION, request);
  };

  updateJobPosition = (
    jobPositionId: number | string,
    request: SaveJobPositionRequestDto,
  ): Promise<JobPositionResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_JOB_POSITION, { jobPositionId });

    return this.http.put(url, request);
  };

  deleteJobPosition = (jobPositionId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_JOB_POSITION, { jobPositionId });

    return this.http.delete(url);
  };
}

export default JobPosition;
