import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput, { ReactSelectOption } from './SelectInput';
import { CompanyRepresentativeTitleEnum } from 'src/helpers/Enums/CompanyRepresentativeTitleEnum';

interface Props {
  name: string;
  placeholder: string;
}

const SelectRepresentativeTitleInput: React.FC<Props> = ({ name, placeholder }) => {
  const { t } = useTranslation();

  const [options] = useState<ReactSelectOption[]>([
    {
      label: t('users.company.proxy'),
      value: CompanyRepresentativeTitleEnum.PROXY,
    },
    {
      label: t('users.company.board_member'),
      value: CompanyRepresentativeTitleEnum.BOARD_MEMBER,
    },
    {
      label: t('users.company.director'),
      value: CompanyRepresentativeTitleEnum.DIRECTOR,
    },
    {
      label: t('users.other'),
      value: CompanyRepresentativeTitleEnum.OTHER,
    },
  ]);

  return <SelectInput name={name} options={options} placeholder={placeholder} />;
};

export default SelectRepresentativeTitleInput;
