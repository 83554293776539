import React from 'react';
import { useGlobalModalContext } from './GlobalModal';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from 'reactstrap';
import Loader from 'src/components/Loader';

export interface ModalComponentProps extends Omit<ModalProps, 'title'> {
  body?: JSX.Element;
  footer?: JSX.Element;
  isLoading?: boolean;
  skeletonHeight?: number;
  title?: string | JSX.Element;
}

const ModalSkeleton: React.FC<{ skeletonHeight: number }> = ({ skeletonHeight }) => {
  return (
    <div
      className={'d-flex justify-content-center align-items-center'}
      style={{ height: skeletonHeight }}
    >
      <Loader />
    </div>
  );
};

export const ModalComponent: React.FC<ModalComponentProps> = ({
  title,
  body,
  footer,
  children,
  skeletonHeight,
  isLoading,
  ...props
}) => {
  const { hideModal } = useGlobalModalContext();
  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <Modal
      {...props}
      centered
      fade
      toggle={handleModalToggle}
      isOpen={true}
      onClose={handleModalToggle}
    >
      {isLoading && <ModalSkeleton skeletonHeight={skeletonHeight ?? 250} />}
      {!isLoading &&
        (children ?? (
          <>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>{body}</ModalBody>
            <ModalFooter>{footer}</ModalFooter>
          </>
        ))}
    </Modal>
  );
};
