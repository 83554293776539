import React, { useCallback, useEffect } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import RoleForm from '../RoleForm';
import { RouteList } from 'src/routes';
import { SaveRoleRequestDto } from 'src/types/api/roles';
import { navigate } from 'src/helpers/navigate';

const RoleCreate: React.FC = () => {
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (request: SaveRoleRequestDto, helpers: FormikHelpers<SaveRoleRequestDto>) => {
      try {
        await Api.roles.createRole(request);
        success(t('common.created_success'));
        navigate(RouteList.ROLES.LIST);
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }

      return true;
    },
    [t],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-4'}>{t('pages.create_role')}</h4>
            <RoleForm onSubmit={onSubmit} />
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default RoleCreate;
