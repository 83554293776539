import { AvailableBonusSelect } from 'src/types/api/bonusSystem';

export const shouldBonusOptionBeDisabled = (option: any, selectValue: any) => {
  const selectedNonSummable =
    selectValue.findIndex((item: AvailableBonusSelect) => !item.summable) !== -1;

  if (selectedNonSummable) {
    return true;
  }

  const selectedSummable =
    selectValue.findIndex((item: AvailableBonusSelect) => item.summable) !== -1;

  return selectedSummable && !option.summable;
};
