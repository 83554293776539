import Http from '../http';
import { RedirectResponseDto } from 'src/types/api/common';

class Client {
  http: Http;

  protected api: { [key: string]: string } = {};

  constructor(http: Http) {
    this.http = http;
  }

  buildUrl = (url: string, params: { [key: string]: string | number }): string => {
    for (const key in params) {
      const value = params[key];
      url = url.replace('{' + key + '}', value.toString());
    }

    return url;
  };

  fetchUrl = (url: string): Promise<RedirectResponseDto> => {
    return this.http.get(url);
  };
}

export default Client;
