import React from 'react';

interface Props
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  icon: string;
  locale: string;
}

const FlagIcon: React.FC<Props> = ({ icon, locale, ...props }) => {
  return (
    <img
      style={{ maxWidth: '1.25rem', width: '100%', height: '0.833rem' }}
      src={`data:image/svg+xml;base64,${window.btoa(icon)}`}
      alt={locale.toLocaleUpperCase()}
      title={locale.toLocaleUpperCase()}
      {...props}
    />
  );
};

export default FlagIcon;
