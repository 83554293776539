import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';

import Api from 'src/api';
import { RouteList } from 'src/routes';
import { PaginationData, PaginationDataFilter } from 'src/types';
import PermissionAction from 'src/components/PermissionAction';
import { setGlobalLoading } from 'src/modules/app/actions';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';
import { InvoiceListResponseDto } from 'src/types/api/payments/invoices';
import { formatArray } from 'src/helpers/formatters/common';
import DownloadButton from 'src/components/DownloadButton';
import InvoiceDropdown from 'src/pages/Payment/Invoices/components/InvoiceDropdown';
import { Permissions } from 'src/helpers/auth/permissions';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const InvoiceList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<InvoiceListResponseDto>>();

  const columns = useMemo<Column<InvoiceListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.name'),
        accessor: 'name',
        sortType: 'string',
      },
      {
        Header: t('table.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<InvoiceListResponseDto>) => (
          <span>{t('common.money', { value: cell.value })}</span>
        ),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        width: 100,
        Cell: (cell: Cell<InvoiceListResponseDto>) => (
          <span>{t('payments.invoices.status.' + cell.value)}</span>
        ),
      },
      {
        Header: t('table.project_owner'),
        accessor: 'project_owner_pid',
        sortType: 'string',
        width: 400,
        Cell: (cell: Cell<InvoiceListResponseDto>) => {
          const row = cell.row.original;
          return <span>{formatArray([row.project_owner_pid, row.project_owner_name])}</span>;
        },
      },
      {
        Header: t('table.payment_date'),
        accessor: 'payment_date',
        sortType: 'datetime',
        width: 150,
        Cell: (cell: Cell<InvoiceListResponseDto>) => (
          <span>{t('common.date', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.action'),
        accessor: 'document',
        disableSortBy: true,
        isOverflowVisible: true,
        Cell: (cell: Cell<InvoiceListResponseDto>) => {
          const doc = cell.row.original.document;
          return (
            <div className={'d-flex'}>
              <PermissionAction permissions={Permissions.BO__PAYMENTS__INVOICES__DOWNLOAD_DOCUMENT}>
                {doc?.url && <DownloadButton url={doc.url} />}
              </PermissionAction>
              <PermissionAction permissions={Permissions.BO__PAYMENTS__INVOICES__UPDATE}>
                <InvoiceDropdown
                  invoiceId={cell.row.original.id}
                  cell={cell}
                  className={'m-auto'}
                  direction={'down'}
                />
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback((request: PaginationDataFilter | undefined) => {
    return Api.payments.invoices
      .fetchFilteredInvoices(request)
      .then((response) => setData(response));
  }, []);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'InvoicesListIndex'}>
        <Container fluid>
          <Card>
            <CardBody>
              <h4 className={'mb-4'}>{t('menu.payments.invoices')}</h4>
              <Table
                title={'InvoicesListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                createComponent={
                  <PermissionAction permissions={Permissions.BO__PAYMENTS__INVOICES__STORE}>
                    <Link
                      to={RouteList.PAYMENTS.INVOICES.CREATE}
                      className={'btn btn-primary w-100'}
                    >
                      {t('common.create_new')}
                    </Link>
                  </PermissionAction>
                }
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default InvoiceList;
