import React from 'react';
import { withRouter } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';

const PageLayout: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar theme={'leftSideBarTheme'} type={'default'} />
        <div className="main-content">
          <div className={'page-content'}>{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PageLayout);
