import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import UserList from './List';
import { Company, User } from './Update';
import { Permissions } from 'src/helpers/auth/permissions';
import AdminList from 'src/pages/User/AdminList';

const UserRoutes: any = () => [
  <AuthRoute
    path={RouteList.USERS.LIST}
    component={UserList}
    layout={PageLayout}
    permissions={[Permissions.BO__USERS__FILTER]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.USERS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.USERS.ADMIN_LIST}
    component={AdminList}
    layout={PageLayout}
    permissions={[Permissions.BO__USERS__FILTER]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.USERS.ADMIN_LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.USERS.VIEW_USER}
    component={User}
    layout={PageLayout}
    permissions={[Permissions.BO__USERS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.USERS.VIEW_USER}
    exact
  />,
  <AuthRoute
    path={RouteList.USERS.VIEW_COMPANY}
    component={Company}
    layout={PageLayout}
    permissions={[Permissions.BO__USERS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.USERS.VIEW_COMPANY}
    exact
  />,
];

export default UserRoutes;
