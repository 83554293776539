import React, { useCallback } from 'react';
import { Row } from 'reactstrap';
import {
  PaymentContextState,
  withPayment,
} from 'src/pages/Payment/ProjectPayments/Update/PaymentContext';
import { useTranslation } from 'react-i18next';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { ColProps } from 'reactstrap/types/lib/Col';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { Permissions } from 'src/helpers/auth/permissions';
import PermissionAction from 'src/components/PermissionAction';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import Api from 'src/api';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';

const TabProjectFinancing: React.FC<PaymentContextState> = ({ payment, setPayment }) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const transferFunds = useCallback(() => {
    if (!payment) {
      return;
    }

    showModal(
      <ActionModal
        title={t('payments.project_financing.transfer_funds.title')}
        body={t('payments.project_financing.transfer_funds.body')}
        successMessage={t('payments.project_financing.transfer_funds.success')}
        actionText={t('common.submit')}
        onAction={async () => {
          return Api.payments.projectPayouts.transferFunds(payment.id).then(setPayment);
        }}
      />,
    );
  }, [payment, setPayment, showModal, t]);

  if (!payment) return null;

  const colProps: ColProps = {
    md: 4,
    sm: 6,
    xs: 12,
  };

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('payments.project_financing')}</h3>
        </div>
        <div>
          <PermissionAction permissions={Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__TRANSFER_FUNDS}>
            <PrimaryButton
              title={t('payments.project_financing.transfer_funds')}
              onClick={transferFunds}
            />
          </PermissionAction>
        </div>
      </div>
      <hr />
      <Row>
        <SidebarDataRow
          label={t('label.project_wallet_amount')}
          value={payment.project_wallet_amount}
          displayValue={t('common.money', { value: payment.project_wallet_amount })}
          colProps={colProps}
        />
        <SidebarDataRow
          label={t('label.owner_wallet_amount')}
          value={payment.owner_wallet_amount}
          displayValue={t('common.money', { value: payment.owner_wallet_amount })}
          colProps={colProps}
        />
        <SidebarDataRow
          label={t('label.funds_status')}
          value={t(
            'label.funds_status.' +
              (payment.is_funds_transferred ? 'transferred' : 'untransferred'),
          )}
          colProps={colProps}
        />
      </Row>
    </>
  );
};

export default withPayment(TabProjectFinancing);
