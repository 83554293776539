export enum ProjectStatus {
  Preparing = 'preparing',
  ReadyForInvestments = 'ready_for_investments',
  ComingSoon = 'coming_soon',
  OpenForInvestments = 'open_for_investments',
  Funded = 'funded',
  NotFunded = 'not_funded',
  Confirmed = 'confirmed',
  Finished = 'finished',
}

export const projectStatusColors = (status: ProjectStatus) => {
  switch (status) {
    case ProjectStatus.Preparing:
      return 'table-red';
    case ProjectStatus.ReadyForInvestments:
      return 'table-yellow';
    case ProjectStatus.ComingSoon:
      return 'table-blue';
    case ProjectStatus.OpenForInvestments:
      return 'table-green';
    case ProjectStatus.Funded:
      return 'table-gray';
    case ProjectStatus.NotFunded:
      return 'table-brown';
    case ProjectStatus.Confirmed:
      return 'table-pink';
    case ProjectStatus.Finished:
      return 'table-purple';
    default:
      return '';
  }
};
