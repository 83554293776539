import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Form, FormGroup, Row } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { RouteList } from 'src/routes';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import { ProjectListResponseDto } from 'src/types/api/projects';
import { SelectAsyncInput, SelectInput } from 'src/components/Form/Select';
import TextInput from 'src/components/Form/TextInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { navigate } from 'src/helpers/navigate';
import { Formik, FormikHelpers } from 'formik';
import {
  CalculatePartialReturnResponseDto,
  CreatePartialReturnRequestDto,
} from 'src/types/api/payments/borrowerPayouts';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { PartialReturnCalculationTypeEnum } from 'src/helpers/Enums/Payments/PartialReturnCalculationTypeEnum';
import { BorrowerEarlyPayoutTypeEnum } from 'src/helpers/Enums/Payments/BorrowerEarlyPayoutTypeEnum';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import { setGlobalLoading } from 'src/modules/app/actions';
import PartialReturnInformation from 'src/pages/Payment/BorrowerPayouts/CreatePartialReturn/PartialReturnInformation';
import { ProjectStatus } from 'src/types/app/projects';
import { format } from 'date-fns';
import { generatePath } from 'react-router-dom';
import CheckboxInput from 'src/components/Form/CheckboxInput';
import { StaticContext } from 'react-router';
import LaravelPusher from 'src/services/LaravelPusher';
import { LaravelPusherEventEnum } from 'src/helpers/Enums/Pusher/LaravelPusherEventEnum';
import { useProfileQuery } from 'src/api/queries';
import { success, error } from 'src/services/toastr';
import { CreatePartialReturnResponseDto } from 'src/types/api/payments/projectPayouts';
import Loader from 'src/components/Loader';

const INITIAL_STATE: CreatePartialReturnRequestDto = {
  project_id: null,
  type: PartialReturnCalculationTypeEnum.EXACT,
  payout_type: BorrowerEarlyPayoutTypeEnum.EARLY_PARTIAL_RETURN,
  amount: 0,
  payment_date: format(new Date(), 'yyyy-MM-dd'),
  transfer_funds: false,
};

interface LocationProps {
  prevPath?: string;
}

interface PusherCreatePartialReturnResponseDto {
  data: CreatePartialReturnResponseDto | null;
  success: boolean;
  error: string | null;
}

interface PusherCalculatePartialReturnResponseDto {
  data: CalculatePartialReturnResponseDto | null;
  success: boolean;
  error: string | null;
}

const CreatePartialReturn: React.FC<RouteComponentProps<object, StaticContext, LocationProps>> = ({
  location,
}) => {
  const { t, i18n } = useTranslation();
  const { data: currentUser } = useProfileQuery({ enabled: false });
  const [startedCalculating, setStartedCalculating] = useState<boolean>(false);
  const [calculated, setCalculated] = useState<boolean>(false);
  const [startedSubmitting, setStartedSubmitting] = useState<boolean>(false);
  const [calculatedData, setCalculatedData] = useState<
    CalculatePartialReturnResponseDto | undefined
  >(undefined);

  useEffect(() => {
    setGlobalLoading(false);

    if (currentUser) {
      const laravelPusherCreated = new LaravelPusher(
        'partial-return-created-' + currentUser.id,
        LaravelPusherEventEnum.InitPartialReturnCompletedEvent,
        (data: PusherCreatePartialReturnResponseDto) => {
          if (data.success && data.data) {
            success(t('common.created_success'));
            navigate(
              generatePath(RouteList.PAYMENTS.BORROWER_PAYOUTS.VIEW, {
                status: 'all',
                borrowerPayoutId: data.data.id,
              }),
            );
          } else {
            error(data.error);
            setCalculated(false);
            setStartedCalculating(false);
            setStartedSubmitting(false);
          }
        },
      );

      const laravelPusherCalculated = new LaravelPusher(
        'partial-return-calculate-' + currentUser.id,
        LaravelPusherEventEnum.PartialReturnCalculationCompletedEvent,
        (data: PusherCalculatePartialReturnResponseDto) => {
          if (data.success && data.data) {
            setCalculatedData(data.data);
            success(t('payments.borrower_payouts.recalculate.success'));
            setCalculated(true);
          } else {
            setCalculated(false);
            setStartedCalculating(false);
            error(data.error);
          }
        },
      );

      return () => {
        laravelPusherCreated.stopListening(LaravelPusherEventEnum.InitPartialReturnCompletedEvent);
        laravelPusherCalculated.stopListening(
          LaravelPusherEventEnum.PartialReturnCalculationCompletedEvent,
        );
      };
    }
  }, [t, currentUser]);

  const Schema = Yup.object().shape({
    project_id: Yup.string().required(),
    type: Yup.string().required(),
    amount: Yup.number().required().min(1),
    payment_date: Yup.date().required(),
  });

  const [options] = useState<ReactSelectOption[]>([
    ...Object.values(PartialReturnCalculationTypeEnum).map((type) => ({
      value: type,
      label: t('payments.borrower_payouts.partial_return.type.' + type),
    })),
  ]);

  const [payoutOptions] = useState<ReactSelectOption[]>([
    ...Object.values(BorrowerEarlyPayoutTypeEnum).map((type) => ({
      value: type,
      label: t('payments.borrower_payouts.partial_return.early_type.' + type),
    })),
  ]);

  const fetchProjects = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
      filters: [
        {
          id: 'status',
          value: ProjectStatus.Confirmed,
        },
      ],
    };
    const response = await Api.projects.fetchFilteredProjects(request).promise;

    return response.data.map((project: ProjectListResponseDto) => ({
      value: project.id,
      label: `${project.pid} ${
        project.project_name?.[i18n.resolvedLanguage as LanguageEnum] ?? ''
      }`,
    }));
  };

  const onSubmit = useCallback(
    async (
      request: CreatePartialReturnRequestDto,
      helpers: FormikHelpers<CreatePartialReturnRequestDto>,
    ) => {
      if (calculated) {
        try {
          setStartedSubmitting(true);
          Api.payments.borrowerPayouts.createPartialReturn(request);
        } catch (e: any) {
          helpers.setErrors(e.response?.errors);
        }
      } else {
        setStartedCalculating(true);
        Api.payments.borrowerPayouts.calculatePartialReturn(request);
      }
      return true;
    },
    [calculated],
  );

  const resetCalculatedStatus = () => {
    setStartedCalculating(false);
    setCalculated(false);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={1} className={'mb-4'}>
            <Link to={location.state?.prevPath ?? RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.ALL}>
              <PrimaryButton title={t('common.back')} type={'button'} />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Card>
              <CardBody>
                <h4 className={'mb-4'}>{t('payments.borrower_payouts.partial_return.create')}</h4>
                {startedSubmitting ? (
                  <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <Loader />
                    <span className="ml-5">
                      {t('payments.borrower_payouts.creating_partial_return')}
                    </span>
                  </div>
                ) : (
                  <Formik
                    initialValues={INITIAL_STATE}
                    enableReinitialize={true}
                    validateOnMount={true}
                    isInitialValid={false}
                    validateOnChange={true}
                    validationSchema={Schema}
                    onSubmit={onSubmit}
                  >
                    {({ handleSubmit, isSubmitting, handleChange, isValid }) => (
                      <Form onSubmit={handleSubmit} onChange={handleChange}>
                        <FormGroup>
                          <div className="mb-3">
                            <SelectAsyncInput
                              name={'project_id'}
                              loadOptions={fetchProjects}
                              isClearable={true}
                              onChange={resetCalculatedStatus}
                            />
                          </div>
                          <div className="mb-3">
                            <SelectInput
                              name={'payout_type'}
                              options={payoutOptions}
                              onChangeAction={resetCalculatedStatus}
                            />
                          </div>
                          <div className="mb-3">
                            <SelectInput
                              name={'type'}
                              options={options}
                              onChangeAction={resetCalculatedStatus}
                            />
                          </div>
                          <div className="mb-3">
                            <TextInput
                              type={'number'}
                              step={'any'}
                              name={'amount'}
                              onChange={resetCalculatedStatus}
                            />
                          </div>
                          <div className="mb-3">
                            <DatePickerInput
                              name={'payment_date'}
                              onChangeRaw={resetCalculatedStatus}
                            />
                          </div>
                          <div className={'mt-4 mb-4'}>
                            {startedCalculating && (
                              <PartialReturnInformation
                                setStartedCalculating={setStartedCalculating}
                                calculated={calculated}
                                data={calculatedData}
                              />
                            )}
                          </div>
                          <hr />
                          <div>
                            <CheckboxInput name="transfer_funds" onChange={resetCalculatedStatus} />
                          </div>
                          <div className={'mt-4 mb-4 d-flex gap-2'}>
                            {calculated ? (
                              <PrimaryButton
                                title={t('common.submit')}
                                submitting={isSubmitting}
                                disabled={isSubmitting || !isValid || !calculated}
                              />
                            ) : (
                              <button
                                className={'btn btn-success w-100'}
                                disabled={startedCalculating}
                              >
                                {t('common.calculate')}
                              </button>
                            )}
                          </div>
                        </FormGroup>
                      </Form>
                    )}
                  </Formik>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreatePartialReturn;
