import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Notification from './Notification';
import NotificationSkeleton from './NotificationSkeleton';
import NotificationsSidebar from './NotificationSidebar';
import { useNotifications } from './NotificationProvider';
import { NotificationShortResponseDto } from 'src/types/api/notifications';
import { PaginationMeta } from 'src/types';
import Api from 'src/api';

const NotificationsList: React.FC = () => {
  const { t } = useTranslation();
  const { selectedNotification, isLoading, markAllRead } = useNotifications();
  const [notifications, setNotifications] = useState<Array<NotificationShortResponseDto>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [latestMeta, setLatestMeta] = useState<PaginationMeta>();
  const [isLoadingNotifications, setIsLoading] = useState<boolean>(true);

  const fetchNotifications = useCallback(() => {
    Api.notifications
      .fetchNotifications({
        limit: 10,
        page: 1,
      })
      .then((data) => {
        setNotifications(data.data);
        setLatestMeta(data.meta);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setNotifications([]);
    setCurrentPage(1);
    fetchNotifications();
  }, [fetchNotifications]);

  const onNextFetch = useCallback(() => {
    Api.notifications
      .fetchNotifications({
        limit: 10,
        page: currentPage + 1,
      })
      .then((data) => {
        setCurrentPage((prevState) => prevState + 1);
        setNotifications((prevState) => [...prevState, ...data.data]);
        setLatestMeta(data.meta);
      });
  }, [currentPage]);

  const markRead = async () => {
    if (markAllRead) {
      await markAllRead();
      fetchNotifications();
    }
  };
  return (
    <Container fluid>
      <Card>
        <CardBody>
          <div className={'mb-6'}>
            <div className={'mb-2 d-flex justify-content-between align-items-center'}>
              <h2 className={'m-0'}>{t('common.notifications')}</h2>
              {markAllRead && (
                <a onClick={markRead} className="small cursor-pointer">
                  {t('menu.mark_all_read')}
                </a>
              )}
            </div>
            <Row className={'notifications-layout'}>
              <Col sm={12} md={4} lg={3} className={'ps-0 pe-0'}>
                <NotificationsSidebar
                  notifications={notifications}
                  onNextFetch={onNextFetch}
                  latestMeta={latestMeta}
                  isLoading={isLoadingNotifications}
                />
              </Col>
              <Col sm={12} md={8} lg={9} className={'d-none d-md-block'}>
                {isLoading && <NotificationSkeleton />}
                {!isLoading && selectedNotification && <Notification {...selectedNotification} />}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default NotificationsList;
