import React from 'react';
import { t } from 'i18next';
import { Row } from 'reactstrap';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { BaseTabProps } from 'src/components/Tabs';
import {
  DocumentTemplateContextState,
  withDocumentTemplate,
} from 'src/pages/DocumentTemplates/DocumentTemplateContext';

interface Props extends BaseTabProps, DocumentTemplateContextState {}

const TabTemplateData: React.FC<Props> = ({ documentTemplate }) => {
  return (
    <>
      <h5 className="mb-3">{t('document_templates.document_template')}</h5>
      <Row>
        <SidebarDataRow label={t('table.name')} value={documentTemplate?.name} />
        <SidebarDataRow label={t('table.class_name')} value={documentTemplate?.class_name} />
        <SidebarDataRow label={t('table.version')} value={documentTemplate?.version} />
        <SidebarDataRow label={t('table.description')} value={documentTemplate?.description} />
        <SidebarDataRow
          label={t('table.created_at')}
          value={t('common.date_full', { date: documentTemplate?.created_at })}
        />
        <SidebarDataRow
          label={t('table.updated_at')}
          value={t('common.date_full', { date: documentTemplate?.updated_at })}
        />
      </Row>
      <hr />
      <h5 className="mb-3">{t('document_templates.current_document')}</h5>
      <Row>
        <SidebarDataRow label={t('table.file_name')} value={documentTemplate?.file_name} />
        <SidebarDataRow label={t('table.mime_type')} value={documentTemplate?.mime_type} />
        <SidebarDataRow label={t('table.size')} value={documentTemplate?.size} />
        <SidebarDataRow label={t('table.uploaded_at')} value={documentTemplate?.uploaded_at} />
      </Row>
    </>
  );
};

export default withDocumentTemplate(TabTemplateData);
