import { statistics } from 'src/api/queries';
import Api from 'src/api/index';
import { defaultOptions, UseQueryOptionsObject } from 'src/api/queries/queries.utils';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { DashboardPageResponseDto } from 'src/types/api/dashboard';

const localDefaultOptions: typeof defaultOptions = {
  ...defaultOptions,
  retry: false,
  retryOnMount: false,
};

const useLastMonthStatisticQuery = (options?: UseQueryOptionsObject<DashboardPageResponseDto>) => {
  return useMakeQuery<DashboardPageResponseDto>(
    [statistics.lastMonth],
    () => Api.dashboard.fetchDashboardLastMonth(),
    {
      staleTime: 120 * 1000,
      ...localDefaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useLastMonthStatisticQuery };
