import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';

interface Props {
  value: Date | null;
}

const UserFrozenStatusBadge: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const getClass = () => {
    if (value === null) {
      return 'bg-success';
    }

    return 'bg-danger';
  };

  return (
    <Badge className={getClass()}>
      {t('users.aml.frozen_status.' + (value === null ? 'unfrozen' : 'frozen'))}
    </Badge>
  );
};

export default UserFrozenStatusBadge;
