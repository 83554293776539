export enum ProjectInvestmentStatusEnum {
  PENDING = 'pending',
  ACTIVE = 'active',
  FINISHED = 'finished',
  LATE = 'late',
  SOLD = 'sold',
  RECOVERING = 'recovering',
  OTHER = 'other',
  RESERVED = 'reserved',
  REFUNDED = 'refunded',
}
