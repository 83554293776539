import React, { createContext, useContext } from 'react';
import { UserResponseDto } from 'src/types/api/user';

export interface UserContextState {
  user: UserResponseDto | undefined;
  setUser: (user: UserResponseDto) => void;
}

const UserContext = createContext<UserContextState>({
  user: undefined,
  setUser: () => {
    return;
  },
});

export const UserProvider: React.FC<UserContextState> = ({ user, setUser, children }) => {
  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export function withUser<P extends UserContextState = UserContextState>(
  Component: React.ComponentType<P>,
) {
  const WithUser = ({ ...props }: Optionalize<P, UserContextState>) => {
    const c = useContext(UserContext);

    return <Component {...c} {...(props as P)} />;
  };

  WithUser.displayName = `withUser${Component.displayName}`;

  return WithUser;
}

export default UserProvider;
