import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouterProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';
import { Card, CardBody, Container } from 'reactstrap';

import Api from 'src/api';
import { LegalEntity, PaginationData, PaginationDataFilter } from 'src/types';
import { setGlobalLoading } from 'src/modules/app/actions';
import Table, { Column } from 'src/components/Table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { AmlRiskLevelChangeLogListResponseDto } from 'src/types/api/amlManagement';
import { ModelField } from 'src/components/Table/Fields/ModelField';
import UserRiskLevelBadge from 'src/components/Badges/UserRiskLevelBadge';
import { UserCompanyAmlRiskLevelChangeLogListResponseDto } from 'src/types/api/userCompanies';
import ColumnFilter from 'src/components/Table/Filters/ColumnFilter';
import { getEnumFilterSelectValues } from 'src/helpers/Enums/enumHelper';
import { UserTypeEnum } from 'src/helpers/Enums/UserTypeEnum';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const AmlRiskLevelChangeLog: React.FC<RouterProps> = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<AmlRiskLevelChangeLogListResponseDto>>();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<AmlRiskLevelChangeLogListResponseDto>[] | any>(() => {
    return [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'number',
        width: 150,
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<AmlRiskLevelChangeLogListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.account_id'),
        accessor: 'account.id',
        width: 100,
      },
      {
        Header: t('table.account'),
        accessor: 'account',
        Cell: (cell: Cell<AmlRiskLevelChangeLogListResponseDto>) => {
          const value = cell.row.original.account;

          return <ModelField model={value} />;
        },
      },
      {
        Header: t('table.account_legal_entity'),
        accessor: 'account_legal_entity',
        width: 100,
        Cell: (cell: Cell<AmlRiskLevelChangeLogListResponseDto>) => {
          return t('users.legal_entity.' + cell.value);
        },
        Filter: ColumnFilter,
        filterProps: {
          options: getEnumFilterSelectValues(LegalEntity, 'users.legal_entity'),
        },
      },
      {
        Header: t('table.account_type'),
        accessor: 'account_type',
        width: 150,
        Cell: (cell: Cell<AmlRiskLevelChangeLogListResponseDto>) => {
          return t('users.type.' + cell.value);
        },
        Filter: ColumnFilter,
        filterProps: {
          options: getEnumFilterSelectValues(UserTypeEnum, 'users.type'),
        },
      },
      {
        Header: t('table.old_risk_level'),
        accessor: 'old_risk_level',
        width: 100,
        Cell: (cell: Cell<AmlRiskLevelChangeLogListResponseDto>) => {
          return <UserRiskLevelBadge value={cell.value} />;
        },
      },
      {
        Header: t('table.new_risk_level'),
        accessor: 'new_risk_level',
        width: 100,
        Cell: (cell: Cell<AmlRiskLevelChangeLogListResponseDto>) => {
          return <UserRiskLevelBadge value={cell.value} />;
        },
      },
      {
        Header: t('table.risk_level_trigger'),
        accessor: 'risk_level_trigger',
        width: 400,
        Cell: (cell: Cell<UserCompanyAmlRiskLevelChangeLogListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {t('users.aml.triggers.' + row.risk_level_trigger, {
                reason: row.manual_change_reason ?? '-',
              })}
            </div>
          );
        },
      },
    ];
  }, [t]);

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.amlManagement
      .fetchFilteredRiskLevelChangeLog(request)
      .then((response) => setData(response));
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <div className={'d-flex align-items-center mb-4'}>
              <h4 className={'m-0'}>{t('menu.aml_management.risk_level')}</h4>
            </div>
            <HiddenColumnsProvider title={'AmlRiskLevelChangeLogListIndex'}>
              <Table
                title={'AmlRiskLevelChangeLogListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                disableFiltersOutsideTable={true}
                initialSortBy={INITIAL_SORT_ORDER}
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(AmlRiskLevelChangeLog);
