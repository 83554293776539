import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LegalEntity } from 'src/types';

interface Props {
  entity: keyof LegalEntity | string | undefined | null;
}

const LegalEntityView: React.FC<Props> = ({ entity }) => {
  const [legalEntity, setLegalEntity] = useState('---');
  const { t } = useTranslation();

  useEffect(() => {
    if (entity === LegalEntity.Personal) {
      setLegalEntity(t('common.legal_entity_personal'));
    } else if (entity === LegalEntity.Company) {
      setLegalEntity(t('common.legal_entity_company'));
    }
  }, [t, entity]);

  return <span>{legalEntity}</span>;
};

export default LegalEntityView;
