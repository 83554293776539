import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onView: () => void;
}

const EditButton: React.FC<Props> = ({ onView }) => {
  const { t } = useTranslation();

  return (
    <button className={`btn btn-secondary`} type={'button'} onClick={() => onView()}>
      {t('common.view')}
    </button>
  );
};

export default EditButton;
