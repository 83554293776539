import React, { useRef } from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent, ModalComponentProps } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import SanitizedHtmlBlock from 'src/components/SanitizedHtmlBlock';
import { NotificationCallToActionResponseDto } from 'src/types/api/notifications';
import timeAgo from 'src/helpers/timeAgo';

interface Props extends ModalComponentProps {
  title: string | JSX.Element;
  content: string;
  cta: NotificationCallToActionResponseDto[] | null;
  created_at: Date;
}

const NotificationModal: React.FC<Props> = ({ title, content, cta, created_at, ...props }) => {
  const { hideModal } = useGlobalModalContext();
  const ref = useRef<SimpleBar>();

  return (
    <React.Fragment>
      <ModalComponent
        size={'lg'}
        {...props}
        className={'hidden-lg hidden-md hidden-xl'}
        contentClassName={'notifications-page'}
      >
        <div className={'ps-2 py-2'}>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />} tag={'div'} />
          <ModalBody className={'notification-modal mt-3 pe-0'}>
            <div className={'pe-4'}>
              <h2 className={'notification-title'}>{title}</h2>
              <p className="d-flex align-items-center justify-content-center text-muted small">
                <i className={'icon icon-clock align-middle'} />
                {timeAgo(created_at)}
              </p>
            </div>
            <SimpleBar
              className={'selected-notification pt-0 mt-2 pe-4'}
              ref={ref as React.LegacyRef<SimpleBar>}
            >
              <SanitizedHtmlBlock content={content} className={'ps-2 pt-0 pe-1'} />
              <div>
                {cta && (
                  <div className={'d-flex flex-wrap g-3 mt-4'}>
                    {cta.map((c, index) => (
                      <Link key={index} className={'btn btn-primary p-3 me-2 mb-2'} to={c.link}>
                        {c.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </SimpleBar>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default NotificationModal;
