import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import SimpleBar from 'simplebar-react';

import FormikField from 'src/components/Form/FormikField';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';

interface Props {
  columns: string[];
  name?: string;
}

const SelectColumnsInput: React.FC<Props> = ({ columns, name = 'columns' }) => {
  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();
  const [field, , helper] = useField(name);
  const { type } = useReportTypeStore();

  const handleSelectAll = () => {
    if (field.value && field.value.length !== columns.length) {
      helper.setValue(columns);
    } else {
      helper.setValue([]);
    }
  };

  const handleClick = (e: { target: { id: string } }) => {
    const { id } = e.target;

    let x: string[];

    if (field.value && field.value.find((a: string) => a === id)) {
      x = field.value.filter((item: string) => item !== id);
    } else {
      x = [...field.value, id];
    }

    helper.setValue(x);
  };

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu((val) => !val)}
        className={'dropdown d-inline-block pr-0'}
        tag="li"
      >
        <div>
          <label htmlFor={name}>{t('label.' + name)}</label>
        </div>
        <DropdownToggle
          className={'btn noti-icon waves-effect'}
          tag={'button'}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <i className={'fa fa-columns font-size-18 d-flex'} />
        </DropdownToggle>

        <DropdownMenu className={'dropdown-menu-start dropdown-border p-0 w-22'}>
          <SimpleBar className={'h-14'}>
            <div className={'d-flex flex-column justify-content-center'}>
              <FormikField name={name} wrapperClass={''}>
                <div className={'d-flex flex-column'}>
                  <label className={'p-1'}>
                    <input
                      className={'mx-3'}
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={field.value && field.value.length == columns.length}
                    />
                    {t('common.select_all')}
                  </label>
                  {columns.map((column: string, key: React.Key | null | undefined) => {
                    return (
                      <label className={'p-1'} key={key}>
                        <input
                          id={column}
                          name={name}
                          type={'checkbox'}
                          value={column}
                          checked={field.value && field.value.includes(column)}
                          className={'mx-3'}
                          onChange={handleClick}
                        />
                        {type && t('reports.' + type + '.columns.' + column)}
                      </label>
                    );
                  })}
                </div>
              </FormikField>
            </div>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
export default SelectColumnsInput;
