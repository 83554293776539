import React from 'react';
import RegistrationsBarChart from './Charts/RegistrationsBarChart';
import InvestorsBarChart from './Charts/InvestorsBarChart';
import CollectedMoneyBarChart from './Charts/CollectedMoneyBarChart';
import PaidInterestBarChart from './Charts/PaidInterestBarChart';

const DashboardCharts = () => {
  return (
    <>
      <div style={{ display: 'inline-flex' }} className={'w-100'}>
        <RegistrationsBarChart />
        <InvestorsBarChart />
      </div>
      <div style={{ display: 'inline-flex' }} className={'w-100'}>
        <CollectedMoneyBarChart />
        <PaidInterestBarChart />
      </div>
    </>
  );
};

export default DashboardCharts;
