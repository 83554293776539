import i18n from 'i18next';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { FilterSelectValuesResponseDto } from 'src/types/api/common';

export const getEnumValues = (enumType: object): Array<string> => {
  return Object.values(enumType);
};

export const getEnumSelectValues = (
  enumType: object,
  translationPrefix: string,
): ReactSelectOption[] => {
  return [
    ...getEnumValues(enumType).map((type) => ({
      label: i18n.t(translationPrefix + '.' + type),
      value: type,
    })),
  ];
};

export const getEnumFilterSelectValues = (
  enumType: object,
  translationPrefix: string,
): FilterSelectValuesResponseDto[] => {
  return [
    ...getEnumValues(enumType).map((type) => ({
      key: i18n.t(translationPrefix + '.' + type),
      value: type,
    })),
  ];
};
