import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import SanitizedHtmlBlock from 'src/components/SanitizedHtmlBlock';

import { CustomNotificationMessageResponseDto } from 'src/types/api/communication';

interface Props {
  notification: CustomNotificationMessageResponseDto;
  date: Date | null;
}

const ViewCustomHistoryNoticiationModal: React.FC<Props> = ({ notification, date }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const capitalizeFirst = (str: string) => {
    if (!str) {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
          {t('common.view_custom_message')}
        </ModalHeader>

        <ModalBody className={'custom-history-notification-form-style'}>
          <Form id={'viewCustomHistoryNotificationIndex'}>
            <FormGroup>
              <Row>
                <Col sm={12}>
                  <div className={'mb-3'}>
                    <label htmlFor="">{t('label.date')}</label>
                    <div className={'custom-history-notification'}>{date}</div>
                  </div>

                  <div className={'mb-3'}>
                    <label htmlFor="">{t('label.type')}</label>
                    <input
                      name="message_types"
                      type="text"
                      value={capitalizeFirst(notification.type)}
                      className={'custom-history-notification'}
                      readOnly
                    />
                  </div>

                  <div className={'mb-3'}>
                    <label htmlFor="">{t('label.subject')}</label>
                    <input
                      name="subject"
                      type="text"
                      value={notification.subject}
                      className={'custom-history-notification'}
                      readOnly
                    />
                  </div>

                  <div>
                    <label htmlFor="">{t('label.message')}</label>
                    <div>
                      <SanitizedHtmlBlock
                        content={notification.message}
                        className={'custom-history-notification-textarea'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className={'d-flex justify-content-center'}></ModalFooter>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ViewCustomHistoryNoticiationModal;
