import React, { ButtonHTMLAttributes } from 'react';
import PrimaryButton from 'src/components/Form/PrimaryButton';

interface Props
  extends React.DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  title?: string;
  submitting?: any;
}

const SaveButton: React.FC<Props> = (props) => {
  return <PrimaryButton btype={'btn-success'} {...props} />;
};

export default SaveButton;
