import React from 'react';
import { Container } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DashboardPortfolioInformationBlock } from './DashboardPortfolioInformationBlock';

const PortfolioInfo: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <DashboardPortfolioInformationBlock />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PortfolioInfo);
