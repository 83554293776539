import React from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'src/components/Loader';
import { useRichestWalletsQuery } from 'src/api/queries/statistics/useWalletInformationStatisticQuery/useWalletInformationStatisticQuery';
import { Table } from 'reactstrap';
import { ModelField } from 'src/components/Table/Fields/ModelField';

export const DashboardRichestWalletsBlock = () => {
  const { t } = useTranslation();

  const richestWalletsQuery = useRichestWalletsQuery();

  return (
    <div className={'w-98'}>
      <h3 className="card-ml-1">{t('dashboard.top_20_wallets_by_amount')}</h3>
      {richestWalletsQuery.isLoading || richestWalletsQuery.data === undefined ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <Table striped bordered hover className={'card-m-1'}>
          <thead>
            <tr>
              <th className={'w-50'}>{t('dashboard.owner')}</th>
              <th className={'w-50'}>{t('dashboard.wallet_sum')}</th>
            </tr>
          </thead>
          <tbody>
            {richestWalletsQuery.data.map((item) => (
              <tr key={item.id}>
                <td>
                  <ModelField model={item.owner} showPid={true} />
                </td>

                <td>
                  {t('common.money', {
                    value: item.wallet_sum,
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
