import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  DocumentTemplateActionListResponseDto,
  DocumentTemplateListResponseDto,
  DocumentTemplateResponseDto,
  UpdateDocumentTemplateRequestDto,
} from 'src/types/api/documentTemplates';
import { SignUploadRequestDto } from 'src/types/api/common';

class DocumentTemplates extends Client {
  api = {
    FETCH_FILTERED_TEMPLATES: `${this.http.baseUrl}/document-templates/filter`,
    DOCUMENT_TEMPLATE_ACTION: `${this.http.baseUrl}/document-templates/document-template/{documentTemplateId}`,
    DOCUMENTS: `${this.http.baseUrl}/document-templates/document-template/{documentTemplateId}/documents`,
    FETCH_DOCUMENT_TEMPLATE_ACTIONS: `${this.http.baseUrl}/document-templates/document-template/{documentTemplateId}/actions`,
  };

  fetchFilteredDocumentTemplates = (
    filter?: PaginationDataFilter,
  ): Promise<PaginationData<DocumentTemplateListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_TEMPLATES, { params: filter });
  };

  fetchDocumentTemplate = (
    documentTemplateId: number | string,
  ): Promise<DocumentTemplateResponseDto> => {
    const url = this.buildUrl(this.api.DOCUMENT_TEMPLATE_ACTION, { documentTemplateId });
    return this.http.get(url);
  };

  fetchDocumentTemplateActions = (
    documentTemplateId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<DocumentTemplateActionListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_DOCUMENT_TEMPLATE_ACTIONS, { documentTemplateId });

    return this.http.get(url, { params: request });
  };

  updateDocumentTemplate = (
    documentTemplateId: number | string,
    request: UpdateDocumentTemplateRequestDto,
  ): Promise<DocumentTemplateResponseDto> => {
    const url = this.buildUrl(this.api.DOCUMENT_TEMPLATE_ACTION, { documentTemplateId });
    return this.http.put(url, request);
  };

  uploadDocuments = (
    documentTemplateId: string | number,
    request: SignUploadRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.DOCUMENTS, { documentTemplateId });
    return this.http.put(url, request);
  };
}

export default DocumentTemplates;
