import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { StoreUserCommentRequestDto } from 'src/types/api/user';
import { Formik } from 'formik';
import { Form, FormGroup } from 'reactstrap';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import TextInput from 'src/components/Form/TextInput';

const INITIAL_STATE: StoreUserCommentRequestDto = {
  user_id: '',
  user_type: '',
  comment: '',
};

interface UserCommentFormProps {
  initial?: StoreUserCommentRequestDto;
  onSubmit: (request: StoreUserCommentRequestDto, helper: any) => void;
}

const UserCommentForm: React.FC<UserCommentFormProps> = ({ initial, onSubmit }) => {
  const { t } = useTranslation();

  const UserCommentSchema = Yup.object().shape({
    user_id: Yup.number().required(),
    user_type: Yup.string().required(),
    comment: Yup.string().required(),
  });
  const [userCommentRequest, setUserCommentRequest] =
    useState<StoreUserCommentRequestDto>(INITIAL_STATE);

  useEffect(() => {
    if (initial) {
      setUserCommentRequest(initial);
    }
  }, [initial]);

  return (
    <React.Fragment>
      <Formik
        initialValues={userCommentRequest}
        enableReinitialize={true}
        validationSchema={UserCommentSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div hidden>
                <TextInput name={'user_id'} />
              </div>
              <div hidden>
                <TextInput name={'user_type'} />
              </div>
              <div className="mb-3">
                <TextAreaInput name={'comment'} />
              </div>
              <div className={'mt-4 mb-4'}>
                <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default UserCommentForm;
