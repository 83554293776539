import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  JobPositionResponseDto,
  SaveJobPositionRequestDto,
} from 'src/types/api/landingPage/jobPosition';
import { setGlobalLoading } from 'src/modules/app/actions';
import { FormikHelpers } from 'formik';
import Api from 'src/api';
import { RouteList } from 'src/routes';
import { toast } from 'react-toastify';
import success = toast.success;
import { navigate } from 'src/helpers/navigate';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import JobPositionsForm from 'src/pages/LandingPage/JobPositions/JobPositionsForm';

const JobPositionUpdate: React.FC = () => {
  const { t } = useTranslation();
  const { jobPositionId } = useParams<{ jobPositionId: string }>();
  const [jobPositionResponse, setJobPositionResponse] = useState<JobPositionResponseDto>();

  const onSubmit = useCallback(
    async (
      request: SaveJobPositionRequestDto,
      helpers: FormikHelpers<SaveJobPositionRequestDto>,
    ) => {
      try {
        await Api.jobPositions.updateJobPosition(jobPositionId, request).then((response) => {
          setJobPositionResponse(response);
          success(t('common.updated_success'));
          navigate(RouteList.LANDING_PAGE.JOB_POSITIONS.LIST);
        });
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }

      return true;
    },
    [jobPositionId, t],
  );

  useEffect(() => {
    Api.jobPositions.fetchJobPosition(jobPositionId).then((response) => {
      setJobPositionResponse(response);
      setGlobalLoading(false);
    });
  }, [jobPositionId]);
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={1} className={'mb-4'}>
            <Link to={RouteList.LANDING_PAGE.JOB_POSITIONS.LIST}>
              <PrimaryButton title={t('common.back')} type={'button'} />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Card>
              <CardBody>
                <h4 className={'mb-3'}>{t('pages.update_job_position')}</h4>
                <JobPositionsForm onSubmit={onSubmit} jobPosition={jobPositionResponse} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default JobPositionUpdate;
