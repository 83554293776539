import React from 'react';

import * as Flags from 'country-flag-icons/string/3x2';
import FlagIcon from 'src/components/FlagIcon';

const Flag: React.FC<FlagProps> = ({ countryCode = 'en' }) => {
  const getFlag = (code: string) => {
    if (code == 'en') code = 'gb';

    return Flags[code.toUpperCase() as keyof typeof Flags];
  };

  return <FlagIcon icon={getFlag(countryCode)} locale={countryCode} />;
};

interface FlagProps {
  countryCode: string;
}

export default Flag;
