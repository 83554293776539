import React, { useMemo } from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SimpleTable from 'src/components/SimpleTable';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import EditButton from 'src/components/EditButton';
import NewsArticleEditModal from 'src/pages/Project/Update/Tabs/Modals/NewsArticleEditModal';
import IsVisibleLocaleBadge from './Badges/IsVisibleLocaleBadge';
import NewsArticleCreateModal from './Modals/NewsArticleCreateModal';
import { ProjectArticleResponse } from 'src/types/api/projects';
import { Cell } from 'react-table';
import SaveButton from 'src/components/Form/SaveButton';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import DeleteButton from 'src/components/DeleteButton';
import Api from 'src/api';

const TabNewsArticle: React.FC<ProjectContextState> = ({ project, language, setProject }) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const columns = useMemo<any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
      },
      {
        Header: t('table.title'),
        accessor: 'title',
        Cell: (cell: Cell<ProjectArticleResponse>) => {
          if (language && language in cell.row.original.title) {
            return cell.row.original.title[language];
          }

          return <Badge className={'bg-danger'}>{t('common.translations.missing')}</Badge>;
        },
      },
      {
        Header: t('table.type'),
        accessor: 'type',
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<ProjectArticleResponse>) => {
          return <div>{t('common.date_full', { date: cell.value })}</div>;
        },
      },
      {
        Header: t('table.published_at'),
        accessor: 'published_at',
        Cell: (cell: Cell<ProjectArticleResponse>) => {
          return <div>{t('common.date_full', { date: cell.value })}</div>;
        },
      },
      {
        Header: t('table.notification_to_investors_sent'),
        accessor: 'notification_to_investors_sent',
        Cell: (cell: Cell<ProjectArticleResponse>) => {
          return <div>{cell.value ? t('common.yes') : t('common.no')}</div>;
        },
      },
      {
        Header: t('table.translations'),
        accessor: 'translations',
        Cell: (cell: Cell<ProjectArticleResponse>) => {
          const translationLocales: string[] = Object.values(LanguageEnum).filter((key: string) => {
            const v = cell.row.original.title as any;

            return key in v && v[key] !== null;
          });

          return (
            <>
              {translationLocales.map((locale, idx) => (
                <IsVisibleLocaleBadge locale={locale} show={true} className={'me-1'} key={idx} />
              ))}
            </>
          );
        },
      },
      {
        Header: t('table.action'),
        accessor: 'url',
        Cell: (cell: any) => {
          const article: ProjectArticleResponse = cell.row.original;

          if (!project) return;

          return (
            <div className={'d-flex gap-2'}>
              <EditButton
                onEdit={() =>
                  showModal(
                    <NewsArticleEditModal
                      projectId={project.id}
                      articleId={article.id}
                      article={article}
                      setProject={setProject}
                    />,
                  )
                }
              />
              <DeleteButton
                onDelete={() =>
                  Api.projects.deleteArticle(project.id, cell.row.values.id).then(setProject)
                }
              />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language],
  );

  return (
    <>
      <Row className={'mb-4'}>
        <Col xs={12}>
          {project && (
            <SaveButton
              title={t('common.add')}
              onClick={() =>
                showModal(<NewsArticleCreateModal projectId={project.id} setProject={setProject} />)
              }
            />
          )}
        </Col>
      </Row>
      <SimpleTable columns={columns} data={project?.articles ?? []} />
    </>
  );
};
export default withProject(TabNewsArticle);
