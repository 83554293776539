import React, { createContext, useContext } from 'react';
import { DocumentTemplateResponseDto } from 'src/types/api/documentTemplates';

export interface DocumentTemplateContextState {
  documentTemplate: DocumentTemplateResponseDto | undefined;
  setDocumentTemplate: (documentTemplate: DocumentTemplateResponseDto) => void;
}

const DocumentTemplateContext = createContext<DocumentTemplateContextState>({
  documentTemplate: undefined,
  setDocumentTemplate: () => {
    return;
  },
});

export const DocumentTemplateProvider: React.FC<DocumentTemplateContextState> = ({
  documentTemplate,
  setDocumentTemplate,
  children,
}) => {
  return (
    <DocumentTemplateContext.Provider value={{ documentTemplate, setDocumentTemplate }}>
      {children}
    </DocumentTemplateContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export function withDocumentTemplate<
  P extends DocumentTemplateContextState = DocumentTemplateContextState,
>(Component: React.ComponentType<P>) {
  const WithDocumentTemplate = ({ ...props }: Optionalize<P, DocumentTemplateContextState>) => {
    const c = useContext(DocumentTemplateContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithDocumentTemplate.displayName = `withDocumentTemplate${Component.displayName}`;

  return WithDocumentTemplate;
}

export default DocumentTemplateProvider;
