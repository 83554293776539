import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownItem,
  DropdownMenu,
  DropdownProps,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';
import { useHistory } from 'react-router-dom';
import { RouteList } from 'src/routes';

type Props = DropdownProps;

const BorrowerPayoutDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <React.Fragment>
      <UncontrolledDropdown {...props}>
        <DropdownToggle tag={'a'} className={'cursor text-body font-size-16'} caret>
          <i className={'fas fa-ellipsis-h'} />
        </DropdownToggle>
        <DropdownMenu className={'dropdown-menu-end'}>
          <PermissionAction
            permissions={Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__CREATE_PARTIAL_RETURN}
          >
            <DropdownItem
              onClick={() =>
                history.push({
                  pathname: RouteList.PAYMENTS.BORROWER_PAYOUTS.CREATE_PARTIAL_RETURN,
                  state: { prevPath: location.pathname },
                })
              }
            >
              {t('payments.borrower_payouts.partial_return.create')}
            </DropdownItem>
          </PermissionAction>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
};

export default BorrowerPayoutDropdown;
