import { projects } from 'src/api/queries';
import Api from 'src/api/index';
import { defaultOptions, UseQueryOptionsObject } from 'src/api/queries/queries.utils';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { GroupedInvestmentResponseDto } from 'src/types/api/projects';

const useGroupedInvestmentsQuery = (
  projectId: string,
  projectInvestmentId: string,
  options?: UseQueryOptionsObject<GroupedInvestmentResponseDto[]>,
) => {
  return useMakeQuery<GroupedInvestmentResponseDto[]>(
    [projects.projectsInvestmentsGroupedInvestments + projectId + projectInvestmentId],
    () => Api.projects.filterGroupedProjectInvestments(projectId, projectInvestmentId),
    {
      staleTime: 60 * 1000,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useGroupedInvestmentsQuery };
