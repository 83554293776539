import { endOfMonth, format, startOfMonth } from 'date-fns';

export const getCurrentMonthFirstDay = (): string => {
  const firstDayOfMonth = startOfMonth(new Date());
  return format(firstDayOfMonth, 'yyyy-MM-dd');
};

export const getCurrentMonthLastDay = (): string => {
  const firstDayOfMonth = endOfMonth(new Date());
  return format(firstDayOfMonth, 'yyyy-MM-dd');
};

export const multipleArgSum = (...numbers: (number | null | undefined)[]) => {
  const filteredNumbers = numbers.filter((num) => num != null && num !== undefined) as number[];
  return filteredNumbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};
