import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import Api from 'src/api';
import { GroupBase } from 'react-select';
import ReactSelectGroupInput, { GroupedOption } from './ReactSelectGroupInput';
import CountryLabel from 'src/components/Labels/CountryLabel';

interface Props extends StateManagerProps {
  name: string;
  setCountriesTaxes?: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>;
}

const SelectCountryInputGroup: React.FC<Props> = ({ name, ...props }) => {
  const [countries, setCountries] = useState<GroupedOption[]>([]);
  const { t, i18n } = useTranslation();

  const getOption = (code: string) => {
    return { value: code, label: <CountryLabel code={code} />, customText: t('countries.' + code) };
  };

  const compareOption = (o1: ReactSelectOption, o2: ReactSelectOption) =>
    o1.customText && o2.customText ? o1.customText.localeCompare(o2.customText) : 0;

  useEffect(() => {
    Api.countries.fetchCountries().then(function (results) {
      const options: GroupedOption[] = [];

      options.push({
        label: 'primary_countries',
        options: results.primary_countries.map(getOption).sort(compareOption),
      });

      options.push({
        label: 'secondary_countries',
        options: results.secondary_countries.map(getOption).sort(compareOption),
      });

      setCountries(options);

      if (props.setCountriesTaxes && results.countries_taxes !== undefined) {
        props.setCountriesTaxes(results.countries_taxes);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      setCountries((groups) => [
        ...groups.map((group) => ({
          ...group,
          options: group.options
            .map((option) => getOption(option.value.toString()))
            .sort(compareOption),
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const formatGroupLabel = (data: GroupBase<any>) => {
    return data.label === 'primary_countries' ? null : <hr className={'m-0'} />;
  };

  const customStyles = {
    group: (provided: any) => ({
      ...provided,
      padding: '0.2rem 0',
    }),
  };

  return (
    <ReactSelectGroupInput
      name={name}
      groupedOptions={countries}
      isSearchable={false}
      formatGroupLabel={formatGroupLabel}
      styles={customStyles}
      {...props}
    />
  );
};

export default SelectCountryInputGroup;
