import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { Form, Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { BaseTabProps } from 'src/components/Tabs';
import Table from 'src/components/Table';
import DownloadButton from 'src/components/DownloadButton';
import { AttachFilesRequestDto, DocumentResponseDto } from 'src/types/api/common';
import FileUpload, { FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';
import { FormikProps } from 'formik/dist/types';
import { DEFAULT_LIMIT as TABLE_DEFAULT_LIMIT } from 'src/components/Table';
import { DEFAULT_PAGE as TABLE_DEFAULT_PAGE } from 'src/components/Table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { UserContextState, withUser } from 'src/pages/User/Update/User/UserContext';
import IsOutdatedBadge from 'src/components/Badges/IsOutdatedBadge';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

interface Props extends UserContextState, BaseTabProps {}

const TabDocuments: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<DocumentResponseDto>>();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);

  const [initialFormValues] = useState<AttachFilesRequestDto>({
    media_ids: [],
  });

  const columns = useMemo<Column<DocumentResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
      },
      {
        Header: t('table.file_name'),
        accessor: 'name',
      },
      {
        Header: t('table.mime_type'),
        accessor: 'mime_type',
      },
      {
        Header: t('table.size'),
        accessor: 'size',
      },
      {
        Header: t('table.information'),
        width: 100,
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div className={'d-flex justify-content-center gap-2 text-center'}>
            <IsOutdatedBadge show={!!cell.row.original.is_outdated} />
          </div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        accessor: 'url',
        Cell: (cell: Cell<DocumentResponseDto>) => {
          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.value} />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      if (user) {
        return Api.user.filterUserDocuments(user.id, request).then((response) => setData(response));
      }
    },
    [user],
  );

  const onSubmit = useCallback(
    async (request: AttachFilesRequestDto, helpers: FormikHelpers<any>) => {
      if (!user) return;

      try {
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        await Api.user.attachDocuments(user.id, request);
        // on new file upload refresh table
        await fetchData({
          page: TABLE_DEFAULT_PAGE,
          limit: TABLE_DEFAULT_LIMIT,
          sort: [],
          search: undefined,
        });

        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [user, fetchData],
  );

  if (!user) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ submitForm, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FileUpload
              ref={fileUploadRef}
              name={'media_ids'}
              onPresign={(request) => Api.user.uploadDocuments(user.id, request)}
              onDropAccepted={submitForm}
            />
          </Form>
        )}
      </Formik>
      <hr />
      <HiddenColumnsProvider title={'UserUpdateCompanyTabsTabDocuments'}>
        <Table
          title={'UserUpdateCompanyTabsTabDocuments'}
          onFetchData={fetchData}
          columns={columns}
          data={data}
          searchable={true}
          enableQueryFilter={true}
          initialSortBy={INITIAL_SORT_ORDER}
        />
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withUser(TabDocuments);
