import React from 'react';
import { useTranslation } from 'react-i18next';

import { useWalletInformationStatisticQuery } from 'src/api/queries/statistics/useWalletInformationStatisticQuery';
import { DashboardCardGrid } from 'src/pages/Common/Dashboard/DashboardCardGrid';
import Loader from 'src/components/Loader';

export const DashboardWalletInformationBlock = () => {
  const { t } = useTranslation();

  const walletInformationStatisticQuery = useWalletInformationStatisticQuery();

  return (
    <div>
      <h3 className="card-ml-1">{t('dashboard.wallet_information')}</h3>
      {walletInformationStatisticQuery.isLoading ||
      walletInformationStatisticQuery.data === undefined ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <DashboardCardGrid
          cards={[
            {
              title: t('dashboard.investor_wallets_sum'),
              value: t('common.money', {
                value: walletInformationStatisticQuery.data.total_investors_amount,
              }),
            },
            {
              title: t('dashboard.projects_wallets_sum'),
              value: t('common.money', {
                value: walletInformationStatisticQuery.data.total_projects_amount,
              }),
            },
          ]}
        />
      )}
    </div>
  );
};
