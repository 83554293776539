import React, { useCallback, useMemo, useState } from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import { CompanyContextState, withCompany } from 'src/pages/User/Update/Company/CompanyContext';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import Table from 'src/components/Table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { CommonActionListResponseDto } from 'src/types/api/common';

interface Props extends BaseTabProps, CompanyContextState {}

const TabAgreements: React.FC<Props> = ({ company }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<CommonActionListResponseDto>>();

  const columns = useMemo<Column<CommonActionListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.user'),
        accessor: 'user_name',
        Cell: (cell: Cell<CommonActionListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {row.user_name} {row.original_user_name && <>({row.original_user_name})</>}
            </div>
          );
        },
      },
      {
        Header: t('table.event'),
        accessor: 'event',
        Cell: (cell: Cell<CommonActionListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {t('audit.event.' + row.event, { type: t('audit.entity.' + row.audit_type) })}
            </div>
          );
        },
      },
      {
        Header: t('table.email'),
        accessor: 'new_values',
        Cell: (cell: Cell<CommonActionListResponseDto>) => <div>{cell.value?.email}</div>,
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<CommonActionListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!company) return Promise.resolve();

      return Api.userCompanies
        .fetchCompanyAgreements(company.id, request)
        .then((response) => setResponse(response));
    },
    [company],
  );

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'UserUpdateCompanyTabsTabAgreements'}>
        <Table
          title={'UserUpdateCompanyTabsTabAgreements'}
          columns={columns}
          data={response}
          onFetchData={fetchData}
        />
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withCompany(TabAgreements);
