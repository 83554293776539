import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  val_invested: boolean;
}

const InvestedBadge: React.FC<Props> = ({ val_invested }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <i
        title={t(`${val_invested ? 'common.invested' : 'common.not_invested'}`)}
        className={`fas fa-euro-sign ${val_invested ? 'text-success' : 'text-danger'}`}
      />
    </React.Fragment>
  );
};

export default InvestedBadge;
