import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { NewsResponseDto, SaveNewsRequestDto } from 'src/types/api/landingPage/news';
import { SignUploadRequestDto } from 'src/types/api/common';

class News extends Client {
  api = {
    FETCH_SINGLE_NEWS: `${this.http.baseUrl}/news-tab/news/{newsId}`,
    FETCH_NEWS: `${this.http.baseUrl}/news-tab/filter`,
    CREATE_NEWS: `${this.http.baseUrl}/news-tab/news`,
    UPDATE_NEWS: `${this.http.baseUrl}/news-tab/news/{newsId}`,
    UPLOAD_PICTURES: `${this.http.baseUrl}/news-tab/news/pictures/upload`,
    DELETE_NEWS: `${this.http.baseUrl}/news-tab/news/{newsId}`,
  };

  fetchSingleNews = (newsId: number | string): Promise<NewsResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_SINGLE_NEWS, { newsId });

    return this.http.get(url);
  };

  fetchFilteredNews = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<NewsResponseDto>> => {
    return this.http.get(this.api.FETCH_NEWS, { params: request });
  };

  createNews = (request: SaveNewsRequestDto): Promise<NewsResponseDto> => {
    return this.http.post(this.api.CREATE_NEWS, request);
  };

  updateNews = (newsId: number | string, request: SaveNewsRequestDto): Promise<NewsResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_NEWS, { newsId });

    return this.http.put(url, request);
  };

  uploadPicture = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.put(this.api.UPLOAD_PICTURES, request);
  };

  deleteNews = (newsId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_NEWS, { newsId });

    return this.http.delete(url);
  };
}

export default News;
