import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions from 'src/helpers/usePermissions';
import { openMedia } from 'src/services/media';

interface Props {
  permissions?: string | string[];
  show?: boolean;
  url: string;
}

const DownloadFileButton: React.FC<Props> = ({ permissions, url, show }) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const p = usePermissions();

  if ((show !== undefined && !show) || (permissions && p.cannot(permissions))) {
    return null;
  }

  return (
    <React.Fragment>
      <button
        disabled={disabled}
        className={'btn btn-link p-0 mx-2'}
        title={t('common.download')}
        onClick={() => {
          setDisabled(true);

          openMedia(url).finally(() => {
            setDisabled(false);
          });
        }}
      >
        <i className={'fas fa-download'} />
      </button>
    </React.Fragment>
  );
};

export default DownloadFileButton;
