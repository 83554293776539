import React, { useCallback, useState } from 'react';
import { PaginationDataFilter } from 'src/types';
import { ExportTableLinkProp } from 'src/components/Table';
import { Button } from 'reactstrap';

interface Props {
  exportLink: ExportTableLinkProp;
  request: any;
  icon?: 'download-file';
}

const ExportTableButton: React.FC<Props> = (props) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const exportData = useCallback(
    (
      onExport: (request: PaginationDataFilter | undefined) => Promise<any>,
      request: PaginationDataFilter | undefined,
    ) => {
      setIsDisabled(true);
      return onExport(request)
        .then((response) => {
          const blob = new Blob(
            [
              new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8-BOM encoding for excel
              response,
            ],
            {
              type: 'application/octet-stream',
            },
          );

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = props.exportLink.fileName + '.' + props.exportLink.type;
          a.click();
        })
        .finally(() => setIsDisabled(false));
    },
    [props.exportLink.fileName, props.exportLink.type],
  );

  return (
    <Button
      onClick={() => exportData(props.exportLink.onExport, props.request)}
      disabled={isDisabled}
    >
      {props.exportLink.buttonTitle ?? props.exportLink.type.toUpperCase()}
    </Button>
  );
};

export default ExportTableButton;
