import React, { useMemo } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { ErrorMessage, useField } from 'formik';
import { Card, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { getClassName, SHOWN_ERRORS } from 'src/components/Form/FileUpload/FileUpload';
import FileUploadListItem from 'src/components/Form/FileUpload/FileUploadListItem';
import { error } from 'src/services/toastr';

export interface FileUploadDirectProps extends DropzoneOptions {
  name: string;
}

const FileUploadDirect: React.FC<FileUploadDirectProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  const isSingleFileInput = props.maxFiles && props.maxFiles === 1;

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (isSingleFileInput) {
        helper.setValue(acceptedFiles[0]);
      } else {
        const files = field.value;
        acceptedFiles.forEach(
          (file) =>
            (!props.maxFiles || (props.maxFiles && files.length < props.maxFiles)) &&
            files.push(file),
        );
        helper.setValue(files);
      }
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map((rejection) =>
        rejection.errors
          .filter((error) => SHOWN_ERRORS.includes(error.code))
          .map((err) =>
            error(t('validation.file_upload.' + err.code, { file: rejection.file.name })),
          ),
      );
    },
    ...props,
  });

  const isError = meta?.touched && !!meta?.error;

  const className = useMemo(
    () => getClassName(isFocused, isDragAccept, isDragReject, props.disabled),
    [props.disabled, isFocused, isDragAccept, isDragReject],
  );

  const removeFile = (index: number) => {
    const files = field.value;
    files.splice(index, 1);
    helper.setValue(files);
  };

  return (
    <>
      <div className={isError ? 'is-invalid' : ''}>
        <div className={`file-upload ${isError ? 'is-invalid' : ''}`}>
          <div {...getRootProps({ className })}>
            <input {...getInputProps()} />
            <div>
              <i className={'icon-upload'} />
              {t('label.file_upload.drag_and_drop')}
            </div>
            <div className={'splitter'}>{t('label.file_upload.or')}</div>
            <div>
              <a>{t('label.file_upload.open_file_browser')}</a>
            </div>
          </div>
        </div>
        {isError && (
          <div className={'invalid-feedback text-pre'}>
            {props.name && (
              <ErrorMessage
                component={'div'}
                name={props.name}
                render={(msg: string | string[]) => {
                  if (typeof msg === 'string') {
                    return msg;
                  }

                  return msg.join('\n');
                }}
              />
            )}
          </div>
        )}
      </div>
      {field.value && isSingleFileInput && (
        <div className={'file-upload-list mt-2'}>
          <Card>
            <Row>
              <Col>
                <FileUploadListItem
                  file={field.value}
                  onRemove={() => helper.setValue(null)}
                  isDirect={true}
                />
              </Col>
            </Row>
          </Card>
        </div>
      )}
      {field.value && field.value.length > 0 && (
        <div className={'file-upload-list mt-2'}>
          <Card>
            <Row>
              <Col>
                {Object.keys(field.value).map((key, index) => (
                  <FileUploadListItem
                    file={field.value[key]}
                    key={index}
                    onRemove={() => removeFile(index)}
                    isDirect={true}
                  />
                ))}
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </>
  );
};

export default FileUploadDirect;
