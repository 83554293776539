import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { format, formatRFC3339 } from 'date-fns';

import FormikField from './FormikField';

export interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  placeholder?: string;
  hideLabel?: boolean;
  showTimeSelect?: boolean;
  onChangeAction?: (option: any) => void;
  matchFieldValueAndDisplayValue?: boolean;
}

const DatePickerInput: React.FC<Props> = ({
  hideLabel,
  showTimeSelect,
  placeholder,
  onChangeAction,
  matchFieldValueAndDisplayValue = false,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const [field, meta, helper] = useField(props.name);
  const [date, setDate] = useState<Date | null>(field.value ? new Date(field.value) : null);

  if (!placeholder) {
    placeholder = t('label.' + props.name);
  }

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const isError = meta?.touched && !!meta?.error;
  const inputFormat = showTimeSelect ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';

  return (
    <>
      <FormikField name={props.name} wrapperClass={'form-label-group'}>
        {!hideLabel && <label htmlFor={props.name}>{placeholder}</label>}
        <DatePicker
          showTimeSelect={showTimeSelect}
          onChange={(val) => {
            try {
              const x = val
                ? showTimeSelect
                  ? formatRFC3339(val)
                  : format(val, 'yyyy-MM-dd')
                : undefined;

              helper.setValue(x);
              helper.setTouched(true, true);
              setDate(val);
              onChangeAction && onChangeAction(val);
            } catch (e: any) {
              console.log('err', e);
            }
          }}
          id={props.name}
          timeFormat={'HH:mm'}
          onBlur={field.onBlur}
          dateFormat={inputFormat}
          selected={
            matchFieldValueAndDisplayValue ? (field.value ? new Date(field.value) : null) : date
          }
          className={`form-control${isError ? ' is-invalid' : ''}`}
          placeholderText={placeholder}
          {...props}
        />
      </FormikField>
    </>
  );
};
export default DatePickerInput;

DatePickerInput.defaultProps = {
  showTimeSelect: false,
  timeIntervals: 30,
};
