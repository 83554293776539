import React, { useCallback, useState } from 'react';
import { Permissions } from 'src/helpers/auth/permissions';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Form/TextInput';
import { UseNumbers } from 'src/helpers/useNumbers';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SelectRealEstateTypeInput from 'src/components/Form/Select/SelectRealEstateTypeInput';
import { SingleSecurityDepositProp } from 'src/pages/Application/Update/Tabs/Securities/SingleSecurityDeposit';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import PermissionAction from 'src/components/PermissionAction';
import { SecurityDepositActionType } from 'src/helpers/Enums/SecurityDepositActionType';
import { useField } from 'formik';

const ViewSingleSecurityDeposit: React.FC<SingleSecurityDepositProp> = ({
  index,
  formikArrayHelpers,
}) => {
  const { t } = useTranslation();

  const [menu, setMenu] = useState(false);
  const [, , h] = useField(`security_deposits[${index}].action_type`);

  const realEstateType = `security_deposits[${index}].real_estate_type`;
  const realEstateUnique = `security_deposits[${index}].real_estate_unique`;
  const realEstatePlot = `security_deposits[${index}].real_estate_plot`;
  const realEstateAddress = `security_deposits[${index}].real_estate_address`;
  const realEstateValue = `security_deposits[${index}].real_estate_value`;
  const realEstateAppraiser = `security_deposits[${index}].real_estate_appraiser`;
  const realEstateAppraiserAt = `security_deposits[${index}].real_estate_appraised_at`;

  const changeAction = useCallback(
    (action: SecurityDepositActionType) => {
      h.setValue(action);

      if (action === SecurityDepositActionType.SPLIT) {
        formikArrayHelpers?.insert(index + 1, {
          action_type: SecurityDepositActionType.SPLIT_MUTATED_DEPOSIT,
        });
        formikArrayHelpers?.insert(index + 1, {
          action_type: SecurityDepositActionType.SPLIT_MUTATED_DEPOSIT,
        });
      }
    },
    [formikArrayHelpers, h, index],
  );

  return (
    <>
      <tr className={'table-tr-distort-fix'}>
        <td>
          <SelectRealEstateTypeInput
            name={realEstateType}
            isClearable={false}
            isDisabled={true}
            placeholder={t('label.real_estate_type')}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateUnique}
            placeholder={t('label.real_estate_unique')}
            hideLabel={true}
            disabled={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstatePlot}
            placeholder={t('label.real_estate_plot')}
            hideLabel={true}
            disabled={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateAddress}
            placeholder={t('label.real_estate_address')}
            hideLabel={true}
            disabled={true}
          />
        </td>
        <td>
          <TextInput
            type={'number'}
            step={'any'}
            name={realEstateValue}
            placeholder={t('label.real_estate_value')}
            hideLabel={true}
            disabled={true}
            onKeyPress={UseNumbers.preventNonNumericalInput}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateAppraiser}
            placeholder={t('label.real_estate_appraiser')}
            hideLabel={true}
            disabled={true}
          />
        </td>
        <td className={'align-top'}>
          <DatePickerInput
            name={realEstateAppraiserAt}
            placeholder={t('label.real_estate_appraised_at')}
            hideLabel={true}
            disabled={true}
          />
        </td>
        <td className={'align-middle'}>
          <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className={'dropdown d-inline-block pr-0'}
            tag="li"
          >
            <DropdownToggle tag="a" className="cursor text-body font-size-16" caret>
              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              <PermissionAction permissions={Permissions.BO__APPLICATIONS__UPDATE_SECURITIES}>
                <DropdownItem onClick={() => changeAction(SecurityDepositActionType.RELEASE)}>
                  {t('applications.stage_action_types.release')}
                </DropdownItem>
              </PermissionAction>
              <PermissionAction permissions={Permissions.BO__APPLICATIONS__UPDATE_SECURITIES}>
                <DropdownItem onClick={() => changeAction(SecurityDepositActionType.MODIFY)}>
                  {t('applications.stage_action_types.modify')}
                </DropdownItem>
              </PermissionAction>
              <PermissionAction permissions={Permissions.BO__APPLICATIONS__UPDATE_SECURITIES}>
                <DropdownItem onClick={() => changeAction(SecurityDepositActionType.SPLIT)}>
                  {t('applications.stage_action_types.split')}
                </DropdownItem>
              </PermissionAction>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};

export default ViewSingleSecurityDeposit;
