import React, { useCallback, useMemo } from 'react';
import { Cell, Column } from 'react-table';
import { ProjectInvestmentListResponseDto, ProjectResponseDto } from 'src/types/api/projects';
import { ModelField } from 'src/components/Table/Fields/ModelField';
import { useTranslation } from 'react-i18next';
import EditEntityButtonEvent from 'src/components/Table/Buttons/EditEntityButtonEvent';
import FormModal from 'src/components/Modal/Modals/FormModal';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import EditReservedInvestmentModalForm from 'src/pages/Project/Update/Tabs/Investments/modals/EditReservedInvestmentModalForm';
import ListGridButtonEvent from '../../../../../../components/Table/Buttons/ListGridButtonEvent';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import Api from 'src/api';
import { EditReservedInvestmentRequestDto } from 'src/types/api/user';
import { success } from 'src/services/toastr';
import DeleteEntityButton from 'src/components/Table/Buttons/DeleteEntityButton';

interface Props {
  project: ProjectResponseDto | undefined;
  moveReservedInvestmentToInvestmentsTable: (investment: ProjectInvestmentListResponseDto) => void;
  updateReservedInvestmentTable: (investment: ProjectInvestmentListResponseDto) => void;
}

const useReservedInvestmentColumns = ({
  project,
  moveReservedInvestmentToInvestmentsTable,
  updateReservedInvestmentTable,
}: Props) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const handleInvestmentUpdate = useCallback(
    async (investment: ProjectInvestmentListResponseDto, dto: EditReservedInvestmentRequestDto) => {
      if (project) {
        const res = await Api.projects.updateReservedInvestment(project.id, investment.id, dto);

        success(t('common.success'));

        updateReservedInvestmentTable(res);
      }
    },
    [project, t, updateReservedInvestmentTable],
  );

  const handleChangeStatusReservedInvestment = useCallback(
    (investment: ProjectInvestmentListResponseDto) => () => {
      showModal(
        <FormModal
          title={t('projects.investment.edit_reserved_investment')}
          size={'xl'}
          form={
            <EditReservedInvestmentModalForm
              project={project}
              investment={investment}
              handleInvestmentUpdate={handleInvestmentUpdate}
            />
          }
        />,
      );
    },
    [handleInvestmentUpdate, project, showModal, t],
  );

  const handleShowDialogForConvertingReservedInvestmentToInvestment = useCallback(
    (investment: ProjectInvestmentListResponseDto) => () => {
      showModal(
        <ActionModal
          title={t('projects.investment.make_investment_active')}
          body={t('projects.investment.question_make_investment_active')}
          successMessage={t('projects.investment.make_investment_active_success')}
          actionText={t('common.change')}
          onAction={async () => {
            if (project) {
              const res = await Api.projects.makeReservedInvestmentAsRegularInvestment(
                project.id,
                investment.id,
              );

              moveReservedInvestmentToInvestmentsTable(res);
            }
          }}
        />,
      );
    },
    [moveReservedInvestmentToInvestmentsTable, project, showModal, t],
  );

  return useMemo<Column<ProjectInvestmentListResponseDto>[] | any>(
    () => [
      {
        Header: t('label.owner'),
        accessor: 'owner',
        disableSortBy: true,
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => {
          const value = cell.row.original.owner;

          return <ModelField model={value} />;
        },
      },
      {
        Header: t('label.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'string',
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.change_status'),
        accessor: '',
        Cell: (cell: Cell<ProjectInvestmentListResponseDto> | any) => (
          <div className="d-inline-flex gap-2">
            <EditEntityButtonEvent
              event={handleChangeStatusReservedInvestment(cell.row.original)}
            />
            <DeleteEntityButton
              onDelete={() => {
                if (project) {
                  return Api.projects
                    .deleteReservedInvestment(project?.id, cell.row.original.id)
                    .then(() => cell.dispatch({ type: 'refreshData' }));
                }

                return Promise.resolve();
              }}
            />
            <ListGridButtonEvent
              event={handleShowDialogForConvertingReservedInvestmentToInvestment(cell.row.original)}
              icon={'fas fa-check-circle'}
            />
          </div>
        ),
      },
    ],
    [
      t,
      handleChangeStatusReservedInvestment,
      handleShowDialogForConvertingReservedInvestmentToInvestment,
      project,
    ],
  );
};

export default useReservedInvestmentColumns;
