import Client from 'src/api/services/client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  BorrowerPayoutListResponseDto,
  BorrowerPayoutResponseDto,
  CalculatePartialReturnRequestDto,
  CalculatePartialReturnResponseDto,
  ConfirmBorrowerPayoutRequestDto,
  CreatePartialReturnRequestDto,
  InvestmentPayoutListResponseDto,
  PrecalculateBorrowerPayoutResponseDto,
  SyncWalletsDto,
} from 'src/types/api/payments/borrowerPayouts';
import {
  AttachFilesRequestDto,
  FilterSelectValuesResponseDto,
  SignUploadRequestDto,
} from 'src/types/api/common';
import { ConfirmRecalculateBorrowerPayoutRequestDto } from 'src/pages/Payment/BorrowerPayouts/Update/Modal/RecalculateBorrowerPayoutModal';

class BorrowerPayouts extends Client {
  api = {
    FETCH_FILTERED_PAYOUTS: `${this.http.baseUrl}/payments/borrower-payouts/filter`,
    FETCH_PAYOUT_PROJECTS: `${this.http.baseUrl}/payments/borrower-payouts/filter/projects`,
    FETCH_PAYOUT_PROJECT_OWNERS: `${this.http.baseUrl}/payments/borrower-payouts/filter/owners`,

    CALCULATE_PARTIAL_RETURN: `${this.http.baseUrl}/payments/borrower-payouts/calculate-partial-return`,
    CREATE_PARTIAL_RETURN: `${this.http.baseUrl}/payments/borrower-payouts/partial-return`,

    FETCH_BORROWER_PAYOUT: `${this.http.baseUrl}/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}`,
    CONFIRM_BORROWER_PAYOUT: `${this.http.baseUrl}/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}/confirm`,
    RECALCULATE_BORROWER_PAYOUT: `${this.http.baseUrl}/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}/recalculate`,
    PRECALCULATE_BORROWER_PAYOUT: `${this.http.baseUrl}/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}/precalculate`,

    SYNC_WALLETS: `${this.http.baseUrl}/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}/sync-wallets`,

    EXPORT_OBSOLETE_INVESTMENT_PAYOUTS: `${this.http.baseUrl}/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}/investment-payouts/export-obsolete`,
    FETCH_BORROWER_INVESTMENT_PAYOUTS: `${this.http.baseUrl}/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}/investment-payouts/filter`,
    IMPORT_INVESTMENT_PAYOUTS: `${this.http.baseUrl}/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}/investment-payouts`,

    CONFIRM_INVESTMENT_PAYOUT: `${this.http.baseUrl}/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}/investment-payouts/investment-payout/{investmentPayoutId}/confirm`,
    RETRY_INVESTMENT_PAYOUT: `${this.http.baseUrl}/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}/investment-payouts/investment-payout/{investmentPayoutId}/retry`,
  };

  fetchFilteredPayouts = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<BorrowerPayoutListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_PAYOUTS, { params: request });
  };

  fetchPayoutProjects = (): Promise<PaginationData<FilterSelectValuesResponseDto[]>> => {
    return this.http.get(this.api.FETCH_PAYOUT_PROJECTS);
  };

  fetchPayoutProjectOwners = (): Promise<PaginationData<FilterSelectValuesResponseDto[]>> => {
    return this.http.get(this.api.FETCH_PAYOUT_PROJECT_OWNERS);
  };

  calculatePartialReturn = (
    request: CalculatePartialReturnRequestDto,
  ): Promise<CalculatePartialReturnResponseDto> => {
    return this.http.post(this.api.CALCULATE_PARTIAL_RETURN, request);
  };

  createPartialReturn = (request: CreatePartialReturnRequestDto): Promise<any> => {
    return this.http.post(this.api.CREATE_PARTIAL_RETURN, request);
  };

  fetchBorrowerPayout = (borrowerPayoutId: string | number): Promise<BorrowerPayoutResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_BORROWER_PAYOUT, { borrowerPayoutId });
    return this.http.get(url);
  };

  confirmBorrowerPayout = (
    borrowerPayoutId: string | number,
    request: ConfirmBorrowerPayoutRequestDto,
  ): Promise<BorrowerPayoutResponseDto> => {
    const url = this.buildUrl(this.api.CONFIRM_BORROWER_PAYOUT, { borrowerPayoutId });
    return this.http.put(url, request);
  };

  precalculateBorrowerPayout = (
    borrowerPayoutId: string | number,
    request: ConfirmRecalculateBorrowerPayoutRequestDto,
  ): Promise<PrecalculateBorrowerPayoutResponseDto> => {
    const url = this.buildUrl(this.api.PRECALCULATE_BORROWER_PAYOUT, { borrowerPayoutId });
    return this.http.put(url, request);
  };

  recalculateBorrowerPayout = (
    borrowerPayoutId: string | number,
    request: ConfirmRecalculateBorrowerPayoutRequestDto,
  ): Promise<BorrowerPayoutResponseDto> => {
    const url = this.buildUrl(this.api.RECALCULATE_BORROWER_PAYOUT, { borrowerPayoutId });
    return this.http.put(url, request);
  };

  uploadInvestmentPayoutsImport = (
    borrowerPayoutId: string | number,
    request: SignUploadRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.IMPORT_INVESTMENT_PAYOUTS, { borrowerPayoutId });

    return this.http.put(url, request);
  };

  attachInvestmentPayoutsImport = (
    borrowerPayoutId: string | number,
    request: AttachFilesRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.IMPORT_INVESTMENT_PAYOUTS, { borrowerPayoutId });

    return this.http.post(url, request);
  };

  exportObsoleteInvestmentPayouts = (borrowerPayoutId: string | number): Promise<any> => {
    const url = this.buildUrl(this.api.EXPORT_OBSOLETE_INVESTMENT_PAYOUTS, { borrowerPayoutId });

    return this.http.get(url, { responseType: 'arraybuffer' });
  };

  fetchBorrowerInvestmentPayouts = (
    borrowerPayoutId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestmentPayoutListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_BORROWER_INVESTMENT_PAYOUTS, { borrowerPayoutId });
    return this.http.get(url, { params: request });
  };

  confirmInvestmentPayout = (
    borrowerPayoutId: string | number,
    investmentPayoutId: string | number,
  ): Promise<BorrowerPayoutResponseDto> => {
    const url = this.buildUrl(this.api.CONFIRM_INVESTMENT_PAYOUT, {
      borrowerPayoutId,
      investmentPayoutId,
    });
    return this.http.put(url);
  };

  retryInvestmentPayout = (
    borrowerPayoutId: string | number,
    investmentPayoutId: string | number,
  ): Promise<BorrowerPayoutResponseDto> => {
    const url = this.buildUrl(this.api.RETRY_INVESTMENT_PAYOUT, {
      borrowerPayoutId,
      investmentPayoutId,
    });
    return this.http.put(url);
  };

  syncOwnerAndProjectWallets = (borrowerPayoutId: string | number): Promise<SyncWalletsDto> => {
    const url = this.buildUrl(this.api.SYNC_WALLETS, { borrowerPayoutId });
    return this.http.get(url);
  };
}

export default BorrowerPayouts;
