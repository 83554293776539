import React, { ChangeEvent, useCallback, useState } from 'react';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  initialChecked?: boolean;
}

const CheckboxInput: React.FC<Props> = ({ name, initialChecked, onChange, ...props }) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!initialChecked);

  const onFieldChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.target.checked);
      onChange && onChange(e);
    },
    [onChange],
  );

  return (
    <input
      type={'checkbox'}
      name={name}
      className={'w-100 h-100'}
      checked={isChecked}
      onChange={onFieldChange}
      {...props}
    />
  );
};

export default CheckboxInput;
