import Client from 'src/api/services/client';
import {
  CustomEmailListHistoryResponseDto,
  CustomEmailListRequestDto,
  CustomHistoryResponseDto,
  NotifyUserGroupsRequestDto,
} from 'src/types/api/communication';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { FetchProjectsBriefResponse } from 'src/types/api/projects';
import { AutoHistoryResponseDto } from 'src/types/api/communication';
import { SignUploadRequestDto } from 'src/types/api/common';

class Communication extends Client {
  api = {
    NOTIFY_USER_GROUPS: `${this.http.baseUrl}/communication/notify-user-groups`,
    FETCH_PROJECTS_BRIEF: `${this.http.baseUrl}/communication/projects/brief`,
    FETCH_FILTERED_USER_AUTO_HISTORY: `${this.http.baseUrl}/communication/auto-history/filter`,
    FETCH_FILTERED_USER_CUSTOM_NOTIFICATION_HISTORY: `${this.http.baseUrl}/communication/custom-history/filter`,
    EXPORT_AUTO_HISTORY_CSV: `${this.http.baseUrl}/communication/auto-history/export/csv`,

    IMPORT_CSV_EMAIL_LIST: `${this.http.baseUrl}/communication/notify-emails/import/csv`,
    NOTIFY_EMAILS: `${this.http.baseUrl}/communication/notify-emails`,
    FETCH_NOTIFY_EMAILS_HISTORY: `${this.http.baseUrl}/communication/notify-emails/history/filter`,
  };

  notifyUserGroups = (request: NotifyUserGroupsRequestDto): Promise<any> => {
    return this.http.post(this.api.NOTIFY_USER_GROUPS, request);
  };

  fetchProjectsBriefList = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<FetchProjectsBriefResponse>> => {
    return this.http.get(this.api.FETCH_PROJECTS_BRIEF, { params: request });
  };

  fetchFilteredUsersHistory = (
    filter?: PaginationDataFilter,
  ): Promise<PaginationData<AutoHistoryResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_USER_AUTO_HISTORY, { params: filter });
  };

  exportAutoHistoryDataCsv = (filter?: PaginationDataFilter): Promise<any> => {
    return this.http.get(this.api.EXPORT_AUTO_HISTORY_CSV, { params: filter });
  };

  fetchFilteredUsersCustomNotifications = (
    filter?: PaginationDataFilter,
  ): Promise<PaginationData<CustomHistoryResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_USER_CUSTOM_NOTIFICATION_HISTORY, {
      params: filter,
    });
  };

  importCsvEmailList = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.put(this.api.IMPORT_CSV_EMAIL_LIST, request);
  };

  notifyImportedEmailList = (request: CustomEmailListRequestDto): Promise<any> => {
    return this.http.post(this.api.NOTIFY_EMAILS, request);
  };

  fetchNotifyEmailHistory = (
    filter?: PaginationDataFilter,
  ): Promise<PaginationData<CustomEmailListHistoryResponseDto>> => {
    return this.http.get(this.api.FETCH_NOTIFY_EMAILS_HISTORY, {
      params: filter,
    });
  };
}

export default Communication;
