import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';
import { Card, CardBody, Container } from 'reactstrap';

import Api from 'src/api';
import WalletProviderBadge from 'src/components/Badges/WalletProviderBadge';
import PermissionAction from 'src/components/PermissionAction';
import BorrowerPayoutType from 'src/components/Projects/BorrowerPayoutType';
import InvestmentPayoutStatus from 'src/components/Projects/InvestmentPayoutStatus';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table, { Column } from 'src/components/Table';
import { getEnumFilterSelectValues } from 'src/helpers/Enums/enumHelper';
import { InvestmentPayoutTypeEnum } from 'src/helpers/Enums/Payments/InvestmentPayoutTypeEnum';
import { setGlobalLoading } from 'src/modules/app/actions';
import { Permissions } from 'src/helpers/auth/permissions';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { BorrowerPayoutListResponseDto } from 'src/types/api/payments/borrowerPayouts';
import { FailedInvestmentPayoutsListResponseDto } from 'src/types/api/payments/failedInvestmentPayouts';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ColumnMultiSelectFilter from 'src/components/Table/Filters/ColumnMultiSelectFilter';

const INITIAL_SORT_ORDER = [
  {
    id: 'payment_date',
    desc: false,
  },
];

const MigratedAccountsFailedInvestmentPayoutsList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<FailedInvestmentPayoutsListResponseDto>>();
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return await Api.payments.failedInvestmentPayouts
      .fetchMigratedAccountsFailedInvestmentPayouts(request)
      .then((response) => setData(response));
  }, []);

  const retryFailedPayouts = useCallback(() => {
    showModal(
      <ActionModal
        title={t('payments.failed_investment_payouts.initiate_payments')}
        body={t('payments.failed_investment_payouts.initiate_payments.body')}
        successMessage={t('payments.failed_investment_payouts.initiate_payments.success')}
        actionText={t('payments.failed_investment_payouts.initiate_payments.retry')}
        onAction={async () => {
          return await Api.payments.failedInvestmentPayouts
            .retryMigratedAccountsFailedInvestmentPayouts()
            .then(() => {
              fetchData({});
            });
        }}
      />,
    );
  }, [showModal, t, fetchData]);

  const columns = useMemo<Column<FailedInvestmentPayoutsListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.project_pid'),
        accessor: 'project_pid',
        sortType: 'string',
        width: 100,
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          request: Api.payments.failedInvestmentPayouts.fetchPayoutProjects(),
        },
      },
      {
        Header: t('table.borrower_payout_id'),
        accessor: 'borrower_payout_id',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.investment_payout_id'),
        accessor: 'id',
        sortType: 'number',
        width: 200,
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        width: 100,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <InvestmentPayoutStatus value={cell.value} />
        ),
      },
      {
        Header: t('table.investor_pid'),
        accessor: 'investor_pid',
        sortType: 'string',
        width: 100,
      },
      {
        Header: t('table.investor_name'),
        accessor: 'investor_name',
        sortType: 'string',
        width: 150,
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        width: 200,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <BorrowerPayoutType value={cell.value} />
        ),
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          options: getEnumFilterSelectValues(
            InvestmentPayoutTypeEnum,
            'payments.borrower_payouts.type',
          ),
        },
      },
      {
        Header: t('label.investor_wallet_provider'),
        accessor: 'wallet_provider',
        width: 100,
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <WalletProviderBadge value={cell.value} />
        ),
      },
      {
        Header: t('table.borrower_total'),
        accessor: 'borrower_total',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.profitus_total'),
        accessor: 'profitus_total',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.loan_repayment'),
        accessor: 'loan_repayment',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{cell.value !== null ? t('common.money', { value: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.payment_date'),
        accessor: 'payment_date',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{t('common.date', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.paid_at'),
        accessor: 'paid_at',
        sortType: 'string',
        Cell: (cell: Cell<BorrowerPayoutListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={t('payments.failed_investment_payouts.migrated_accounts')}>
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-4">
                <h4 className="m-0">{t('payments.failed_investment_payouts.migrated_accounts')}</h4>
              </div>
              <Table
                title={'FailedMigratedAccountsInvestmentPayoutListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                disableFiltersOutsideTable={true}
                striped={false}
                createComponent={
                  <PermissionAction
                    permissions={
                      Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__INVESTMENT_PAYOUTS__RETRY_FAILED_INVESTMENT_PAYOUT
                    }
                  >
                    <button onClick={retryFailedPayouts} className={'btn btn-primary w-100'}>
                      {t('payments.failed_investment_payouts.initiate_payments')}
                    </button>
                  </PermissionAction>
                }
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default MigratedAccountsFailedInvestmentPayoutsList;
