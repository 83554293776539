import Client from '../client';
import Http from '../../http';
import { CountriesListResponseDto } from 'src/types/api/common';

class Countries extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_COUNTRIES: `${this.http.baseUrl}/locale/countries`,
    };
  }

  fetchCountries = (): Promise<CountriesListResponseDto> => {
    return this.http.get(this.api.FETCH_COUNTRIES);
  };
}

export default Countries;
