import Client from 'src/api/services/client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { ManualWithdrawalListResponseDto } from 'src/types/api/payments/manualWithdrawals';
import { BorrowerPayoutResponseDto } from 'src/types/api/payments/borrowerPayouts';

class ManualWithdrawals extends Client {
  api = {
    FETCH_FILTERED_MANUAL_WITHDRAWALS: `${this.http.baseUrl}/payments/manual-withdrawals/filter`,
    CONFIRM_MANUAL_WITHDRAWAL: `${this.http.baseUrl}/payments/manual-withdrawals/{walletOperationId}/confirm`,
    CONFIRM_ALL_MANUAL_WITHDRAWALS: `${this.http.baseUrl}/payments/manual-withdrawals/confirm-all`,
  };

  fetchFilteredManualWithdrawals = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ManualWithdrawalListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_MANUAL_WITHDRAWALS, { params: request });
  };

  confirmManualWithdrawal = (
    walletOperationId: string | number,
  ): Promise<BorrowerPayoutResponseDto> => {
    const url = this.buildUrl(this.api.CONFIRM_MANUAL_WITHDRAWAL, { walletOperationId });
    return this.http.put(url);
  };

  confirmAllManualWithdrawals = (): Promise<any> => {
    return this.http.post(this.api.CONFIRM_ALL_MANUAL_WITHDRAWALS);
  };
}

export default ManualWithdrawals;
