import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  AddUserCompanyAccreditationRequestDto,
  FreezeUserCompanyAccountRequestDto,
  ImpersonateUserCompanyResponseDto,
  RemoveUserCompanyAccreditationRequestDto,
  SuspendUserCompanyRequestDto,
  UpdateUserCompanyRequestDto,
  UpdateUserCompanyRiskLevelRequestDto,
  UserCompanyAmlRiskLevelChangeLogListResponseDto,
  UserCompanyBrief,
  UserCompanyResponseDto,
  AssignCompanyManagerRequestDto,
} from 'src/types/api/userCompanies';
import {
  AttachFilesRequestDto,
  CommonActionListResponseDto,
  SignUploadRequestDto,
} from 'src/types/api/common';
import {
  FinancialRiskCalculatorResponseDto,
  StoreUserCommentRequestDto,
  UpdateLemonwayDocumentRequestDto,
  UserCommentResponseDto,
  UserInvestmentsResponseDto,
} from 'src/types/api/user';
import {
  WalletMoneyOperationsResponseDto,
  WalletP2POperationsResponseDto,
} from 'src/types/api/operations';
import { AddAffiliateAmbassadorModalRequest } from 'src/components/Modal/Modals/AddAffiliateAmbassadorModal';
import { EditAffiliateAmbassadorModalRequest } from 'src/components/Modal/Modals/EditAffiliateAmbassadorModal';

class UserCompanies extends Client {
  api = {
    FETCH_COMPANY: `${this.http.baseUrl}/users/company/{companyId}`,
    FETCH_COMPANIES_BRIEF_LIST: `${this.http.baseUrl}/users/company/brief`,
    IMPERSONATE_COMPANY: `${this.http.baseUrl}/users/company/{companyId}/impersonate`,
    SYNC_COMPANY_WALLET: `${this.http.baseUrl}/users/company/{companyId}/sync-wallet`,
    FETCH_RELATED_ACCOUNTS: `${this.http.baseUrl}/users/company/{companyId}/related-accounts`,
    FETCH_COMPANY_ACTIONS: `${this.http.baseUrl}/users/company/{companyId}/actions`,
    FETCH_COMPANY_AGREEMENTS: `${this.http.baseUrl}/users/company/{companyId}/agreements`,
    FETCH_INVESTMENTS: `${this.http.baseUrl}/users/company/{companyId}/investments`,

    FILTER_DOCUMENTS: `${this.http.baseUrl}/users/company/{companyId}/documents/filter`,
    UPLOAD_DOCUMENTS: `${this.http.baseUrl}/users/company/{companyId}/documents`,
    ATTACH_DOCUMENTS: `${this.http.baseUrl}/users/company/{companyId}/documents`,
    MARK_AS_LEMONWAY_DOCUMENT: `${this.http.baseUrl}/users/company/{companyId}/documents/{mediaId}/mark-as-lemonway-document`,

    FILTER_LEMONWAY_DOCUMENTS: `${this.http.baseUrl}/users/company/{companyId}/lemonway-documents/filter`,
    UPLOAD_LEMONWAY_DOCUMENTS: `${this.http.baseUrl}/users/company/{companyId}/lemonway-documents`,
    ATTACH_LEMONWAY_DOCUMENTS: `${this.http.baseUrl}/users/company/{companyId}/lemonway-documents`,
    UNMARK_AS_LEMONWAY_DOCUMENT: `${this.http.baseUrl}/users/company/{companyId}/lemonway-documents/{mediaId}/unmark-as-lemonway-document`,
    SEND_FILE_TO_LEMONWAY: `${this.http.baseUrl}/users/company/{companyId}/lemonway-documents/{mediaId}/send-to-lemonway`,
    UPDATE_LEMONWAY_DOCUMENT: `${this.http.baseUrl}/users/company/{companyId}/lemonway-documents/{mediaId}`,

    UPDATE_COMPANY: `${this.http.baseUrl}/users/company/{companyId}`,
    DELETE_COMPANY: `${this.http.baseUrl}/users/company/{companyId}`,

    ASSIGN_MANAGER: `${this.http.baseUrl}/users/company/{companyId}/manager`,

    SUSPEND_COMPANY: `${this.http.baseUrl}/users/company/{companyId}/suspend`,
    REMOVE_COMPANY_SUSPENSION: `${this.http.baseUrl}/users/company/{companyId}/suspend`,

    ADD_COMPANY_ACCREDITATION: `${this.http.baseUrl}/users/company/{companyId}/accreditation`,
    REMOVE_COMPANY_ACCREDITATION: `${this.http.baseUrl}/users/company/{companyId}/accreditation`,

    FETCH_COMPANY_COMMENTS: `${this.http.baseUrl}/users/company/{companyId}/comments`,
    CREATE_COMPANY_COMMENT: `${this.http.baseUrl}/users/user/comments`,
    UPDATE_COMPANY_COMMENT: `${this.http.baseUrl}/users/user/comments/{commentId}`,
    DELETE_COMPANY_COMMENT: `${this.http.baseUrl}/users/user/comments/{commentId}`,

    FETCH_WALLET_MONEY_OPERATIONS: `${this.http.baseUrl}/users/company/{companyId}/operations/money`,
    FETCH_WALLET_P2P_OPERATIONS: `${this.http.baseUrl}/users/company/{companyId}/operations/p2p`,

    FETCH_AML_RISK_LEVEL_CHANGE_LOG: `${this.http.baseUrl}/users/company/{companyId}/aml/risk-level/filter`,
    UPDATE_AML_RISK_LEVEL: `${this.http.baseUrl}/users/company/{companyId}/aml/risk-level`,
    FREEZE_USER_ACCOUNT: `${this.http.baseUrl}/users/company/{companyId}/aml/freeze-status`,
    UNFREEZE_USER_ACCOUNT: `${this.http.baseUrl}/users/company/{companyId}/aml/freeze-status`,

    ADD_AFFILIATE_AMBASSADOR: `${this.http.baseUrl}/users/company/{companyId}/affiliate-ambassador`,
    EDIT_AFFILIATE_AMBASSADOR: `${this.http.baseUrl}/users/company/{companyId}/affiliate-ambassador/{affiliateAmbassadorId}`,
    REMOVE_AFFILIATE_AMBASSADOR: `${this.http.baseUrl}/users/company/{companyId}/affiliate-ambassador/{affiliateAmbassadorId}`,

    ENABLE_GROUPABLE_INVESTMENTS: `${this.http.baseUrl}/users/company/{companyId}/groupable-investments`,
    DISABLE_GROUPABLE_INVESTMENTS: `${this.http.baseUrl}/users/company/{companyId}/groupable-investments`,

    FETCH_FINANCIAL_RISK_SUBMITS: `${this.http.baseUrl}/users/company/{companyId}/financial-risk-submits`,
  };

  fetchCompany = (companyId: string | number): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_COMPANY, { companyId });

    return this.http.get(url);
  };

  fetchCompaniesBriefList = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserCompanyBrief>> => {
    return this.http.get(this.api.FETCH_COMPANIES_BRIEF_LIST, { params: request });
  };

  addAccreditation = (
    companyId: number | string,
    request: AddUserCompanyAccreditationRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.ADD_COMPANY_ACCREDITATION, { companyId });

    return this.http.put(url, request);
  };

  assignManager = (
    companyId: number | string,
    request: AssignCompanyManagerRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.ASSIGN_MANAGER, { companyId });

    return this.http.put(url, request);
  };

  removeAccreditation = (
    companyId: number | string,
    request: RemoveUserCompanyAccreditationRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.REMOVE_COMPANY_ACCREDITATION, { companyId });

    return this.http.delete(url, request);
  };

  fetchCompanyRelatedAccount = (
    companyId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<any>> => {
    const url = this.buildUrl(this.api.FETCH_RELATED_ACCOUNTS, { companyId });

    return this.http.get(url, { params: request });
  };

  impersonateCompany = (companyId: string | number): Promise<ImpersonateUserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.IMPERSONATE_COMPANY, { companyId });

    return this.http.post(url);
  };

  syncCompanyWallet = (companyId: string | number): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.SYNC_COMPANY_WALLET, { companyId });

    return this.http.post(url);
  };

  suspend = (
    companyId: number | string,
    request: SuspendUserCompanyRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.SUSPEND_COMPANY, { companyId });

    return this.http.put(url, request);
  };

  removeSuspension = (companyId: number | string): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.REMOVE_COMPANY_SUSPENSION, { companyId });

    return this.http.delete(url);
  };

  fetchCompanyActions = (
    companyId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CommonActionListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_COMPANY_ACTIONS, { companyId });

    return this.http.get(url, { params: request });
  };

  fetchCompanyAgreements = (
    companyId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CommonActionListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_COMPANY_AGREEMENTS, { companyId });

    return this.http.get(url, { params: request });
  };

  updateUserCompany = (
    companyId: number | string,
    request: UpdateUserCompanyRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_COMPANY, { companyId });

    return this.http.put(url, request);
  };

  filterCompanyDocuments = (
    companyId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.FILTER_DOCUMENTS, { companyId });

    return this.http.get(url, { params: request });
  };

  filterCompanyLemonwayDocuments = (
    companyId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.FILTER_LEMONWAY_DOCUMENTS, { companyId });

    return this.http.get(url, { params: request });
  };

  attachDocuments = (
    companyId: string | number,
    request: AttachFilesRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.ATTACH_DOCUMENTS, { companyId });

    return this.http.post(url, request);
  };

  attachLemonwayDocuments = (
    companyId: string | number,
    request: AttachFilesRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.ATTACH_LEMONWAY_DOCUMENTS, { companyId });

    return this.http.post(url, request);
  };

  uploadDocuments = (companyId: string | number, request: SignUploadRequestDto): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_DOCUMENTS, { companyId });

    return this.http.put(url, request);
  };

  uploadLemonwayDocuments = (
    companyId: string | number,
    request: SignUploadRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_LEMONWAY_DOCUMENTS, { companyId });

    return this.http.put(url, request);
  };

  unmarkAsLemonwayDocument = (
    companyId: string | number,
    mediaId: string | number,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UNMARK_AS_LEMONWAY_DOCUMENT, { companyId, mediaId });

    return this.http.put(url);
  };

  markAsLemonwayDocument = (companyId: string | number, mediaId: string | number): Promise<any> => {
    const url = this.buildUrl(this.api.MARK_AS_LEMONWAY_DOCUMENT, { companyId, mediaId });

    return this.http.put(url);
  };

  sendFileToLemonway = (companyId: string | number, mediaId: string | number): Promise<any> => {
    const url = this.buildUrl(this.api.SEND_FILE_TO_LEMONWAY, { companyId, mediaId });

    return this.http.post(url);
  };

  updateLemonwayDocument = (
    companyId: string | number,
    mediaId: string | number,
    request: UpdateLemonwayDocumentRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPDATE_LEMONWAY_DOCUMENT, { companyId, mediaId });

    return this.http.put(url, request);
  };

  fetchCompanyComments = (companyId: string | number): Promise<Array<UserCommentResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_COMPANY_COMMENTS, { companyId });

    return this.http.get(url);
  };
  createCompanyComment = (request: StoreUserCommentRequestDto): Promise<UserCommentResponseDto> => {
    return this.http.post(this.api.CREATE_COMPANY_COMMENT, request);
  };

  updateCompanyComment = (
    commentId: number | string,
    request: StoreUserCommentRequestDto,
  ): Promise<UserCommentResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_COMPANY_COMMENT, { commentId });

    return this.http.put(url, request);
  };
  deleteCompanyComment = (commentId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_COMPANY_COMMENT, { commentId });

    return this.http.delete(url);
  };

  fetchWalletP2POperations = (
    companyId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<WalletP2POperationsResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_WALLET_P2P_OPERATIONS, { companyId });

    return this.http.get(url, { params: request });
  };

  fetchWalletMoneyOperations = (
    companyId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<WalletMoneyOperationsResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_WALLET_MONEY_OPERATIONS, { companyId });

    return this.http.get(url, { params: request });
  };

  fetchUserCompanyInvestments = (
    companyId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserInvestmentsResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_INVESTMENTS, { companyId });

    return this.http.get(url, { params: request });
  };

  fetchAmlRiskLevelChangeLog = (
    companyId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserCompanyAmlRiskLevelChangeLogListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_AML_RISK_LEVEL_CHANGE_LOG, { companyId });

    return this.http.get(url, { params: request });
  };

  updateRiskLevel = (
    companyId: number | string,
    request: UpdateUserCompanyRiskLevelRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_AML_RISK_LEVEL, { companyId });

    return this.http.put(url, request);
  };

  freezeUserAccount = (
    companyId: number | string,
    request: FreezeUserCompanyAccountRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.FREEZE_USER_ACCOUNT, { companyId });

    return this.http.put(url, request);
  };

  unfreezeUserAccount = (
    companyId: number | string,
    request: FreezeUserCompanyAccountRequestDto,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.UNFREEZE_USER_ACCOUNT, { companyId });

    return this.http.delete(url, request);
  };

  deleteCompany = (companyId: number | string): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.DELETE_COMPANY, { companyId });

    return this.http.delete(url);
  };

  addAffiliateAmbassador = (
    companyId: number | string,
    request: AddAffiliateAmbassadorModalRequest,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.ADD_AFFILIATE_AMBASSADOR, { companyId });

    return this.http.post(url, request);
  };

  editAffiliateAmbassador = (
    companyId: number | string,
    affiliateAmbassadorId: number | string,
    request: EditAffiliateAmbassadorModalRequest,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.EDIT_AFFILIATE_AMBASSADOR, {
      companyId,
      affiliateAmbassadorId,
    });

    return this.http.put(url, request);
  };

  deleteAffiliateAmbassador = (
    companyId: number | string,
    affiliateAmbassadorId: number | string,
  ): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.REMOVE_AFFILIATE_AMBASSADOR, {
      companyId,
      affiliateAmbassadorId,
    });

    return this.http.delete(url);
  };

  enableGroupableInvestments = (companyId: number | string): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.ENABLE_GROUPABLE_INVESTMENTS, {
      companyId,
    });

    return this.http.put(url);
  };

  disableGroupableInvestments = (companyId: number | string): Promise<UserCompanyResponseDto> => {
    const url = this.buildUrl(this.api.DISABLE_GROUPABLE_INVESTMENTS, {
      companyId,
    });

    return this.http.delete(url);
  };

  fetchFinancialRiskSubmits = (
    companyId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<FinancialRiskCalculatorResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_FINANCIAL_RISK_SUBMITS, { companyId });

    return this.http.get(url, { params: request });
  };
}

export default UserCompanies;
