import { handleActions } from 'redux-actions';
import { MediaInfoResponseDto, SingUploadSingleFileResponseDto } from 'src/types/api/common';

export const FILES_ADDED = 'FILE_UPLOAD.FILES_ADDED';
export const REMOVE_FILES = 'FILE_UPLOAD.REMOVE_FILES';
export const FILE_UPLOADING = 'FILE_UPLOAD.FILE_UPLOADING';
export const FILE_UPLOADED = 'FILE_UPLOAD.FILE_UPLOADED';
export const FILE_REMOVED = 'FILE_UPLOAD.FILE_REMOVED';

export enum FileStateEnum {
  Add,
  Uploading,
  Uploaded,
}

export interface IFile {
  name: string;
  state: FileStateEnum;
  file: File;
  meta: MediaInfoResponseDto | null;
}

export interface FileState {
  files: { [key: string]: IFile };
}

export const initialState: FileState = {
  files: {},
};

export const FileUploadReducer = handleActions<FileState, any>(
  {
    [FILES_ADDED]: (state: FileState, action: { payload: File[] }) => {
      const files: { [key: string]: IFile } = { ...state.files };

      action.payload.forEach((file) => {
        let isNew = true;
        Object.keys(files).forEach((key) => {
          if (files[key].name === file.name) {
            isNew = false;
            return;
          }
        });

        if (isNew) {
          files[Math.random()] = {
            name: file.name,
            state: FileStateEnum.Add,
            file: file,
            meta: null,
          };
        }
      });

      return {
        ...state,
        files: files,
      };
    },
    [REMOVE_FILES]: () => {
      return {
        ...initialState,
      };
    },
    [FILE_UPLOADING]: (state: FileState, action: { payload: string }) => {
      const files = { ...state.files };
      const file = files[action.payload];
      file.state = FileStateEnum.Uploading;

      return {
        ...state,
        files: files,
      };
    },
    [FILE_UPLOADED]: (state: FileState, action: { payload: SingUploadSingleFileResponseDto }) => {
      const hash = action.payload.hash;

      const files = { ...state.files };
      const file = files[hash];
      file.state = FileStateEnum.Uploaded;
      file.meta = {
        id: action.payload.media_id,
        name: action.payload.name,
      };

      return {
        ...state,
        files: files,
      };
    },
    [FILE_REMOVED]: (state: FileState, action: { payload: string }) => {
      const files = { ...state.files };
      delete files[action.payload];

      return {
        ...state,
        files: files,
      };
    },
  },
  initialState,
);
