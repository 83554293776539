import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';
import { Card, CardBody, Container } from 'reactstrap';

import Api from 'src/api';
import Table, { Column } from 'src/components/Table';
import { setGlobalLoading } from 'src/modules/app/actions';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { success } from 'src/services/toastr';
import { EditableField, EditableFieldData } from 'src/components/Table/Fields/EditableFied';
import { Permissions } from 'src/helpers/auth/permissions';
import { TranslationListResponseDto } from 'src/types/api/translations';
import { locales } from 'src/helpers';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { CommonDocumentTypeEnum } from 'src/helpers/Enums/CommonDocumentTypeEnum';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ImportDataModal from 'src/components/Modal/Modals/ImportDataModal';
import { ImportDataRequestDto } from 'src/types/api/common';
import DeleteEntityButton from 'src/components/Table/Buttons/DeleteEntityButton';
import PermissionAction from 'src/components/PermissionAction';
import ColumnMultiSelectFilter from 'src/components/Table/Filters/ColumnMultiSelectFilter';

const LocaleList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<TranslationListResponseDto>>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const onSubmit = useCallback(
    async (fieldData: EditableFieldData): Promise<void> => {
      const id = fieldData.id;

      const locale = fieldData.locale?.toString();
      const text = fieldData.value?.toString();

      if (locale === null) {
        return;
      }

      await Api.translations.updateTranslation(id, {
        locale: locale,
        text: text,
      });

      success(t('common.updated_success'));
    },
    [t],
  );

  const columns = useMemo<Column<TranslationListResponseDto>[] | any>(() => {
    return [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'number',
        width: 200,
      },
      {
        Header: t('table.service'),
        accessor: 'service',
        sortType: 'string',
      },
      {
        Header: t('table.group'),
        accessor: 'group',
        sortType: 'string',
        width: 250,
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          request: Api.translations.fetchTranslationGroups(),
        },
      },
      {
        Header: t('table.key'),
        accessor: 'key',
        sortType: 'string',
      },
      ...locales.map((l) => ({
        Header: t('table.' + l),
        accessor: 'text.' + l,
        sortType: 'string',
        Cell: (cell: Cell<TranslationListResponseDto>) => {
          const row = cell.row.original;

          const data = {
            id: row.id,
            locale: l,
          };

          return (
            <EditableField
              permissions={Permissions.BO__LOCALE__TRANSLATIONS__UPDATE}
              data={data}
              text={cell.value}
              submit={onSubmit}
            />
          );
        },
      })),
      {
        Header: t('table.action'),
        Cell: (cell: Cell<TranslationListResponseDto> | any) => {
          const row = cell.row.original;

          return (
            <div className={'actions d-flex gap-2'}>
              <PermissionAction permissions={[Permissions.BO__LOCALE__TRANSLATIONS__DELETE]}>
                <DeleteEntityButton
                  onDelete={() =>
                    Api.translations.deleteTranslation(row.id).then(() => {
                      cell.dispatch({ type: 'refreshData' });
                    })
                  }
                />
              </PermissionAction>
            </div>
          );
        },
      },
    ];
  }, [onSubmit, t]);

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.translations
        .fetchFilteredTranslations(request)
        .then((response) => setData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh],
  );

  const exportCsv = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.translations.exportTranslationDataCsv(request);
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <div className={'d-flex align-items-center mb-4'}>
              <h4 className={'m-0'}>{t('menu.translations')}</h4>
            </div>
            <HiddenColumnsProvider title={'LocaleListIndex'}>
              <Table
                title={'LocaleListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                disableFiltersOutsideTable={true}
                exportLinks={[
                  {
                    onExport: exportCsv,
                    type: CommonDocumentTypeEnum.CSV,
                    fileName: 'translations',
                    buttonTitle: t('common.export'),
                  },
                ]}
                importLink={
                  <PrimaryButton
                    title={t('common.import')}
                    type={'button'}
                    onClick={() => {
                      showModal(
                        <ImportDataModal
                          onAction={async (request: ImportDataRequestDto) => {
                            return Api.translations
                              .importTranslationData(request)
                              .then(() => setRefresh((val) => !val));
                          }}
                        />,
                      );
                    }}
                  />
                }
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default LocaleList;
