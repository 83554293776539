import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import BonusForm from 'src/pages/BonusSystem/BonusForm';
import { navigate } from 'src/helpers/navigate';
import { RouteList } from 'src/routes';
import { BonusSystemResponseDto, StoreBonusRequestDto } from 'src/types/api/bonusSystem';
import { Link, useParams } from 'react-router-dom';
import { BonusConditionEnum } from 'src/helpers/Enums/BonusSystem/BonusConditionEnum';
import PrimaryButton from 'src/components/Form/PrimaryButton';

const BonusSystemUpdate: React.FC = () => {
  const { t } = useTranslation();
  const { bonusId } = useParams<{ bonusId: string }>();
  const [bonusRequest, setBonusRequest] = useState<BonusSystemResponseDto>();

  const onSubmit = useCallback(
    async (request: StoreBonusRequestDto, helpers: FormikHelpers<StoreBonusRequestDto>) => {
      switch (request.bonus_condition) {
        case BonusConditionEnum.PERCENTAGE:
          request.amount = null;
          break;
        case BonusConditionEnum.AMOUNT:
          request.percentage = null;
          break;
      }

      try {
        await Api.bonusSystem.updateBonus(bonusId, request);
        success(t('common.updated_success'));
        navigate(RouteList.BONUS_SYSTEM.LIST);
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
      return true;
    },
    [bonusId, t],
  );

  useEffect(() => {
    setGlobalLoading(true);

    Api.bonusSystem.fetchBonus(bonusId).then((bonus) => {
      setBonusRequest(bonus);
      setGlobalLoading(false);
    });
  }, [bonusId]);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={1} className={'mb-4'}>
            <div>
              <Link to={RouteList.BONUS_SYSTEM.LIST}>
                <PrimaryButton title={t('common.back')} type={'button'} />
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Card>
              <CardBody>
                <h4 className={'mb-4'}>{t('pages.edit_bonus')}</h4>
                {bonusRequest && <BonusForm onSubmit={onSubmit} bonusSystem={bonusRequest} />}
              </CardBody>
            </Card>{' '}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default BonusSystemUpdate;
