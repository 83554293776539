import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PaymentContextState,
  withPayment,
} from 'src/pages/Payment/ProjectPayments/Update/PaymentContext';
import { Formik, FormikHelpers } from 'formik';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SaveButton from 'src/components/Form/SaveButton';
import {
  UpdatePaymentProjectLoanRequestDto,
  ProjectLoanResponseDto,
} from 'src/types/api/payments/projectPayouts';
import * as Yup from 'yup';
import Api from 'src/api';
import { useParams } from 'react-router-dom';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import { ProjectLoanStatusEnum } from 'src/helpers/Enums/Project/ProjectLoanStatusEnum';

const DEFAULT_REQUEST: UpdatePaymentProjectLoanRequestDto =
  {} as UpdatePaymentProjectLoanRequestDto;

const TabData: React.FC<PaymentContextState> = ({ payment, setPayment }) => {
  const { t } = useTranslation();

  const { projectId } = useParams<{ projectId: string }>();

  const [projectLoan] = useState<UpdatePaymentProjectLoanRequestDto>(
    payment?.project_loan ?? DEFAULT_REQUEST,
  );

  const onSubmit = useCallback(
    async (
      request: UpdatePaymentProjectLoanRequestDto,
      helpers: FormikHelpers<UpdatePaymentProjectLoanRequestDto>,
    ) => {
      try {
        const response = await Api.payments.projectPayouts.updateProjectLoan(projectId, request);
        success(t('common.updated_success'));
        setPayment(response);
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [projectId, setPayment, t],
  );

  const DataSchema = Yup.object().shape({
    start_date: Yup.date().required(),
    end_date: Yup.date().required(),
  });

  const isProjectLoanConfirmed = (project_loan: ProjectLoanResponseDto | undefined | null) => {
    return !!(
      project_loan?.status &&
      ![
        ProjectLoanStatusEnum.INIT,
        ProjectLoanStatusEnum.NEW,
        ProjectLoanStatusEnum.SCHEDULED,
      ].includes(project_loan.status)
    );
  };

  return (
    <React.Fragment>
      <h3>{t('payments.data')}</h3>
      <hr />
      <Formik
        initialValues={projectLoan}
        enableReinitialize={true}
        validationSchema={DataSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col sm={6} className={'mb-4'}>
                  <DatePickerInput
                    disabled={isProjectLoanConfirmed(payment?.project_loan)}
                    name={'start_date'}
                  />
                </Col>
                <Col sm={6} className={'mb-4'}>
                  <DatePickerInput
                    disabled={isProjectLoanConfirmed(payment?.project_loan)}
                    name={'end_date'}
                  />
                </Col>
              </Row>
              <div className={'mt-4 mb-4'}>
                <SaveButton
                  disabled={isProjectLoanConfirmed(payment?.project_loan)}
                  title={t('common.submit')}
                  submitting={isSubmitting}
                />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withPayment(TabData);
