import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import usePermissions from 'src/helpers/usePermissions';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import { GenerateReportRequestDto, TransactionReportFilterForm } from 'src/types/api/reports';
import { appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import SelectWalletOwnerInput from 'src/pages/Reports/View/Filters/Selects/SelectWalletOwnerInput';

const AVAILABLE_COLUMNS: string[] = [
  'user_pid',
  'user_name',
  'user_legal_code',
  'user_residence',
  'relative_amount',
  'wallet_amount_after',
  'type',
  'status',
  'executed_at',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.TRANSACTIONS;

const TransactionReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<TransactionReportFilterForm>({
    name: null,
    columns: AVAILABLE_COLUMNS,
    user_pid: null,
    project_pid: null,
    owner_type: null,
  });

  const mapData = (form: TransactionReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendFilter(request, 'user_pid', form.user_pid);
    appendFilter(request, 'project_pid', form.project_pid);
    appendFilter(request, 'owner_type', form.owner_type);

    return request;
  };

  const onSubmit = useCallback(
    async (
      request: TransactionReportFilterForm,
      helper: FormikHelpers<TransactionReportFilterForm>,
    ) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  if (!p.hasAll([mapManagePermission(TYPE)])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik initialValues={request} onSubmit={onSubmit} enableReinitialize={true}>
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'user_pid'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'project_pid'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectWalletOwnerInput name={'owner_type'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default TransactionReportFilter;
