import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import Api from 'src/api';
import { ConvertApplicationRequestDto } from 'src/types/api/applications';
import SaveButton from 'src/components/Form/SaveButton';
import DenyButton from 'src/components/Form/DenyButton';
import FileUpload, {
  DATA_IMPORT_TYPES,
  FileUploadHandle,
} from 'src/components/Form/FileUpload/FileUpload';
import * as Yup from 'yup';
import {
  BorrowerPayoutContextState,
  withBorrowerPayout,
} from 'src/pages/Payment/BorrowerPayouts/Update/BorrowerPayoutContext';
import { success } from 'src/services/toastr';

const ImportInvestmentPayoutsModal: React.FC<BorrowerPayoutContextState> = ({
  borrowerPayout,
  setBorrowerPayout,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);

  const [attachFilesRequest] = useState<ConvertApplicationRequestDto>({
    media_ids: [],
    owner_id: borrowerPayout?.id ?? '',
  });

  const Schema = Yup.object().shape({
    media_ids: Yup.array().min(1),
  });

  const onSubmit = useCallback(
    async (
      request: ConvertApplicationRequestDto,
      helpers: FormikHelpers<ConvertApplicationRequestDto>,
    ) => {
      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        if (!borrowerPayout) {
          return;
        }

        const response = await Api.payments.borrowerPayouts.attachInvestmentPayoutsImport(
          borrowerPayout.id,
          request,
        );
        setBorrowerPayout(response);
        success(t('common.imported_successfully'));
        hideModal();
        fileUploadRef?.current?.reset();
      } catch (e: any) {
        console.log(e);
        helpers.setErrors(e.response?.errors);
      }
    },
    [borrowerPayout, hideModal, setBorrowerPayout, t],
  );

  if (!borrowerPayout) {
    return <></>;
  }

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('payments.borrower_payouts.import_investment_payouts.title')}
          </ModalHeader>
          <Formik initialValues={attachFilesRequest} onSubmit={onSubmit} validationSchema={Schema}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <p>{t('payments.borrower_payouts.import_investment_payouts.body')}</p>
                  <p>
                    <a
                      target={'_blank'}
                      href={
                        'https://profitus-live.s3.eu-north-1.amazonaws.com/investment_payout_import_sample.xlsx'
                      }
                      rel="noreferrer"
                    >
                      investment_payout_import_sample.xlsx
                    </a>
                  </p>
                  <div className={'mb-3'}>
                    <FileUpload
                      accept={DATA_IMPORT_TYPES}
                      ref={fileUploadRef}
                      name={'media_ids'}
                      maxFiles={1}
                      onPresign={(request) =>
                        Api.payments.borrowerPayouts.uploadInvestmentPayoutsImport(
                          borrowerPayout.id,
                          request,
                        )
                      }
                    />
                  </div>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export const ImportInvestmentPayouts = withBorrowerPayout(ImportInvestmentPayoutsModal);
