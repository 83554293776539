import React, { useCallback, useState } from 'react';
import { ModalComponent } from '../ModalComponent';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from '../GlobalModal';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ModalCloseButton from '../ModalCloseButton';
import { success } from 'src/services/toastr';
import { Formik, FormikHelpers } from 'formik';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import * as Yup from 'yup';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';

interface Props {
  onAction: (reason: string) => Promise<any>;
  actionText: string;
  title: string;
  body: string;
  successMessage: string;
  required?: boolean;
}

interface ReasonRequest {
  reason: string;
}

const ReasonModal: React.FC<Props> = ({
  onAction,
  title,
  body,
  successMessage,
  actionText,
  required = true,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const SuspendRequestSchema = Yup.object().shape({
    reason: required ? Yup.string().required() : Yup.string().nullable(),
  });

  const [reasonRequest] = useState<ReasonRequest>({ reason: '' });

  const onSubmit = useCallback(
    (request: ReasonRequest, helper: FormikHelpers<ReasonRequest>) => {
      return onAction(request.reason)
        .then(() => {
          success(successMessage);
          hideModal();
        })
        .catch((e) => {
          helper.setErrors(e.response?.errors);
        });
    },
    [hideModal, onAction, successMessage],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title}
          </ModalHeader>
          <Formik
            initialValues={reasonRequest}
            validationSchema={SuspendRequestSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <>
                <ModalBody className={''}>
                  <p className={'text-pre'}>{body}</p>

                  <Form onSubmit={handleSubmit} id={'reason-form'}>
                    <FormGroup>
                      <div className="mb-3">
                        <TextAreaInput value={values.reason} name="reason" />
                      </div>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      form={'reason-form'}
                      submitting={isSubmitting}
                      title={actionText}
                      type={'submit'}
                    />
                  </Col>
                </ModalFooter>
              </>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ReasonModal;
