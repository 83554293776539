import React, { useCallback, useState } from 'react';
import usePermissions from 'src/helpers/usePermissions';

export interface EditableFieldData {
  id: number | string;

  [key: string]: string | number;
}

interface Props {
  permissions?: string | string[];
  data: EditableFieldData;
  text: string;
  submit: (fieldData: EditableFieldData) => void;
}

export const EditableField: React.FC<Props> = ({ permissions, data, text, submit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);
  const p = usePermissions();

  const handleOnClick = useCallback(() => {
    if (permissions && p.can(permissions)) {
      setIsEditing(true);
    }
  }, [p, permissions]);

  const onSubmit = async () => {
    submit({
      ...data,
      value: value,
    });

    setIsEditing(false);
  };

  const style = {
    p: {
      minWidth: '30px',
      minHeight: '20px',
    },
    textarea: {
      width: '100%',
      borderRadius: '.5em',
      padding: '.5em',
    },
  };

  if (isEditing) {
    return (
      <textarea
        onChange={(e) => setValue(e.target.value)}
        onBlur={onSubmit}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
          }
        }}
        value={value}
        style={style.textarea}
      />
    );
  }

  return (
    <p style={style.p} onClick={handleOnClick}>
      {value}
    </p>
  );
};
