import React from 'react';
import { ModelResponseDto } from 'src/types/api/common';
import WalletProviderBadge from 'src/components/Badges/WalletProviderBadge';
import { WalletProviderEnum } from 'src/helpers/Enums/Payments/WalletProviderEnum';
import { ModelField } from 'src/components/Table/Fields/ModelField';

interface Props {
  model: ModelResponseDto | null;
  provider: WalletProviderEnum | null;
}

export const WalletOwnerField: React.FC<Props> = ({ model, provider }) => {
  return (
    <div className={'d-flex gap-2'}>
      {provider && (
        <div>
          <WalletProviderBadge value={provider} />
        </div>
      )}
      <div>
        <ModelField model={model} />
      </div>
    </div>
  );
};
