import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { success } from 'src/services/toastr';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import Api from 'src/api';
import { ProjectResponseDto } from 'src/types/api/projects';
import { ProjectStatus } from 'src/types/app/projects';

interface Props {
  project: ProjectResponseDto;
  onException?: () => void;
  actionText: string;
  title: string;
  body: string;
  checkboxText: string;
  successMessage?: string;
  status: ProjectStatus;
  setProject: (response: ProjectResponseDto) => void;
}

const MarkProjectAsWithCheckbox: React.FC<Props> = ({
  project,
  onException,
  title,
  body,
  checkboxText,
  successMessage,
  actionText,
  status,
  setProject,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [isInAction, setIsInAction] = useState(false);
  const [boolCheck, setBoolCheck] = useState(false);

  const onSubmit = useCallback(() => {
    if (isInAction) return;
    setIsInAction(true);

    switch (status) {
      case ProjectStatus.Confirmed:
        return Api.projects
          .markAsConfirmed(project.id, {
            ignore_validation: boolCheck,
          })
          .then((response) => {
            success(successMessage);
            hideModal();
            setProject(response);
          })
          .finally(() => {
            setIsInAction(false);
          })
          .catch(() => {
            onException && onException();
          });
      case ProjectStatus.Finished:
        return Api.projects
          .markAsFinished(project.id, {
            disable_notifications: boolCheck,
          })
          .then((response) => {
            success(successMessage);
            hideModal();
            setProject(response);
          })
          .finally(() => {
            setIsInAction(false);
          })
          .catch(() => {
            onException && onException();
          });
    }
  }, [
    hideModal,
    boolCheck,
    isInAction,
    onException,
    project.id,
    successMessage,
    status,
    setProject,
  ]);

  return (
    <React.Fragment>
      <ModalComponent size={'sm'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title}
          </ModalHeader>
          <ModalBody className={''}>
            <p className={'text-pre'}>{body}</p>
            <label className={'p-1'}>
              <input
                className={'me-2'}
                type="checkbox"
                onChange={() => setBoolCheck((prevState) => !prevState)}
                checked={boolCheck}
              />
              {checkboxText}
            </label>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-center'}>
            <Col>
              <DenyButton
                title={t('common.cancel')}
                onClick={(e) => {
                  e.preventDefault();
                  hideModal();
                }}
              />
            </Col>
            <Col>
              <SaveButton
                title={actionText}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
                submitting={isInAction}
              />
            </Col>
          </ModalFooter>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default MarkProjectAsWithCheckbox;
