import { Card, CardBody, CardTitle, UncontrolledTooltip } from 'reactstrap';
import React from 'react';

export interface CardInterface {
  title: string;
  value: string | number;
  tooltipElementId?: string;
  tooltipValue?: string;
}

export const DashboardCard = ({ title, value, tooltipElementId, tooltipValue }: CardInterface) => {
  return (
    <Card className={'w-100 card-m-1 shadow-sm'} id={tooltipElementId}>
      <CardBody>
        <CardTitle className={'text-center'}>{title}</CardTitle>
        {tooltipElementId && tooltipValue && (
          <UncontrolledTooltip placement="top" target={tooltipElementId}>
            {tooltipValue}
          </UncontrolledTooltip>
        )}
      </CardBody>
      <h2 className={'font-weight-bold text-center text-dark'}>{value}</h2>
    </Card>
  );
};
