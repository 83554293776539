import React, { useCallback, useEffect, useState } from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { Col, ColProps, Form, FormGroup, Row } from 'reactstrap';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import SaveButton from 'src/components/Form/SaveButton';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import { ApplicationUpdateCreditDetailsOtherRequestDto } from 'src/types/api/applications';
import { transformErrors } from 'src/helpers';

interface Props extends BaseTabProps, ApplicationContextState {
  showSubmit?: boolean;
}

const INITIAL_REQUEST: ApplicationUpdateCreditDetailsOtherRequestDto = {
  loan_details: '',
};

const Other: React.FC<Props> = ({ application, setApplication, showSubmit }) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<any>(INITIAL_REQUEST);

  useEffect(() => {
    if (!application) return;

    setRequest({
      loan_details: application.other?.loan_details,
    });
  }, [application]);

  const OtherSchema = Yup.object().shape({});

  const onSubmit = useCallback(
    async (request: any, helpers: FormikHelpers<any>) => {
      if (!application) return;

      try {
        const response = await Api.applications.UpdateCreditDetailsOther(application.id, request);
        setApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [application, setApplication, t],
  );

  const colProps: ColProps = {
    md: 12,
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.type.other')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton
              title={t('applications.tabs_submit.credit_details')}
              tabName={'credit_details'}
            />
          </div>
        )}
      </div>
      <hr />
      <Formik
        initialValues={request}
        enableReinitialize={true}
        validationSchema={OtherSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colProps}>
                  <TextAreaInput name={'loan_details'} />
                </Col>
              </Row>
              <div className={'mt-4 mb-4'}>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withApplication(Other);
