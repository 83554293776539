export enum ApplicationStatus {
  Pending = 'pending',
  Submitted = 'submitted',
  Analyzing = 'analyzing',
  CommitteeReview = 'committee_review',
  ChiefCommitteeMemberReview = 'chief_committee_member_review',
  Confirmed = 'confirmed',
  OfferAccepted = 'offer_accepted',
  Rejected = 'rejected',
}
