import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import Select, { SingleValue } from 'react-select';

import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import usePermissions from 'src/helpers/usePermissions';
import { StringParam, useQueryParam } from 'use-query-params';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';

const ReportTypeSelect: React.FC = () => {
  const [, setQueryType] = useQueryParam('type', StringParam);
  const { type, setType } = useReportTypeStore();
  const { t } = useTranslation();
  const p = usePermissions();

  const [options] = useState<ReactSelectOption[]>([
    ...Object.values(ReportTypeEnum)
      .filter((type) => p.can(mapManagePermission(type)))
      .map((type) => ({
        value: type,
        label: t('reports.type.' + type),
      })),
  ]);

  const value = (): ReactSelectOption | undefined => {
    if (type) {
      return {
        value: type.toString(),
        label: t('reports.type.' + type),
      };
    }

    return undefined;
  };

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('menu.reports')}</h4>
          <div className={'mb-3'}>
            <Select
              options={options}
              onChange={(newValue: SingleValue<ReactSelectOption>) => {
                if (newValue?.value) {
                  setType(newValue.value as ReportTypeEnum);
                  setQueryType(typeof newValue.value === 'boolean' ? '' : newValue.value);
                }
              }}
              value={value()}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ReportTypeSelect;
