import React from 'react';
import { Link } from 'react-router-dom';
import { ModelResponseDto } from 'src/types/api/common';

interface Props {
  model: ModelResponseDto | null;
  showPid?: boolean;
}

export const ModelField: React.FC<Props> = ({ model, showPid = false }) => {
  if (!model) return <>-</>;

  const getText = () => {
    if (showPid) {
      return '(' + model.pid + ') ' + model.name;
    }

    return model.name;
  };

  if (!model.url) return <>{getText()}</>;

  return <Link to={model.url}>{getText()}</Link>;
};
