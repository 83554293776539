import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';

interface Props {
  request: () => Promise<Blob>;
  buttonTitle: string;
  fileName: string;
  extension: string;
}

const StreamFileButton: React.FC<Props> = (props) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const exportData = useCallback(
    (request: () => Promise<Blob>) => {
      setIsDisabled(true);
      return request()
        .then((response) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(response);
          link.download = props.fileName + '.' + props.extension;
          link.click();
        })
        .finally(() => setIsDisabled(false));
    },
    [props.extension, props.fileName],
  );

  return (
    <Button onClick={() => exportData(props.request)} disabled={isDisabled}>
      {props.buttonTitle}
    </Button>
  );
};

export default StreamFileButton;
