import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { setGlobalLoading } from 'src/modules/app/actions';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';
import { StaticPageSeoResponseDto } from 'src/types/api/landingPage/seo';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import i18n from 'src/services/i18n';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { ApplicationListResponseDto } from 'src/types/api/applications';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import { Permissions } from 'src/helpers/auth/permissions';

const SEOLandingPage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<StaticPageSeoResponseDto>>();
  const [language, setLanguage] = useState<LanguageEnum>(i18n.resolvedLanguage as LanguageEnum);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<StaticPageSeoResponseDto>[] | any>(
    () => [
      {
        Header: t('table.base_slug'),
        accessor: 'base_slug',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.base_slug}</span>;
        },
      },
      {
        Header: t('table.key'),
        accessor: 'key',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.key}</span>;
        },
      },
      {
        Header: t('table.slug'),
        accessor: 'slug',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.slug?.[language]}</span>;
        },
      },
      {
        Header: t('table.meta_title'),
        accessor: 'meta_title',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.meta_title?.[language]}</span>;
        },
      },
      {
        Header: t('table.meta_description'),
        accessor: 'meta_description',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.meta_description?.[language]}</span>;
        },
      },
      {
        Header: t('table.h1'),
        accessor: 'h1',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.h1?.[language]}</span>;
        },
      },
      {
        Header: t('table.h2'),
        accessor: 'h2',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.h2?.[language]}</span>;
        },
      },
      {
        Header: t('table.h3'),
        accessor: 'h3',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.h3?.[language]}</span>;
        },
      },
      {
        Header: t('table.h4'),
        accessor: 'h4',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.h4?.[language]}</span>;
        },
      },
      {
        Header: t('table.h5'),
        accessor: 'h5',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.h5?.[language]}</span>;
        },
      },
      {
        Header: t('table.h6'),
        accessor: 'h6',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<StaticPageSeoResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.h6?.[language]}</span>;
        },
      },
      {
        Header: t('table.action'),
        Cell: (cell: Cell<ApplicationListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                permissions={Permissions.BO__LANDING_PAGE__SEO__UPDATE}
                route={generatePath(RouteList.LANDING_PAGE.SEO.UPDATE, { landingPageId: row.id })}
              />
            </div>
          );
        },
      },
    ],
    [language, t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.seo.fetchFilteredSeoPages(request).then((response) => setData(response));
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-3'}>{t('menu.landing_page.seo')}</h4>
            <LanguageSwitcher locale={language} setLocale={setLanguage} />
            <HiddenColumnsProvider title={'SeoPagesIndex'}>
              <Table
                title={'SeoPagesIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default SEOLandingPage;
