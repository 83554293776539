import React, { useCallback, useMemo, useState } from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import UserTypeView from 'src/components/Views/UserTypeView';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { UserRelatedAccountListResponseDto, UserResponseDto } from 'src/types/api/user';
import { Cell, Column } from 'react-table';
import { Permissions } from 'src/helpers/auth/permissions';
import Api from 'src/api';
import { useTranslation } from 'react-i18next';
import { UserContextState, withUser } from 'src/pages/User/Update/User/UserContext';
import Table from 'src/components/Table';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import VerifiedBadge from 'src/pages/User/Badges/VerifiedBadge';
import DirectMarketingBadge from 'src/pages/User/Badges/DirectMarketingBadge';
import InvestedBadge from 'src/pages/User/Badges/InvestedBadge';
import WalletExistsBadge from 'src/pages/User/Badges/WalletExistsBadge';

interface Props extends BaseTabProps, UserContextState {}

const TabRelatedAccounts: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<UserRelatedAccountListResponseDto>>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'pid',
        sortType: 'string',
        width: 100,
      },
      {
        Header: t('table.name'),
        accessor: 'name',
        width: 250,
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        Cell: (cell: any) => <UserTypeView type={cell.value} />,
      },
      {
        Header: t('table.legal_code'),
        accessor: 'legal_code',
      },
      {
        Header: t('table.vat_code'),
        accessor: 'vat_code',
      },
      {
        Header: t('table.email'),
        accessor: 'email',
        width: 250,
      },
      {
        Header: t('table.phone'),
        accessor: 'phone',
      },
      {
        Header: t('table.registration_date'),
        accessor: 'created_at',
        sortType: 'datetime',
        width: 160,
        Cell: (cell: Cell<UserResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.information'),
        width: 110,
        Cell: (cell: Cell<UserRelatedAccountListResponseDto>) => {
          const val = cell.row.original;

          return (
            <div className={'actions d-flex gap-2'}>
              <VerifiedBadge verified={val.verified} />
              <DirectMarketingBadge
                via_email={val?.direct_marketing?.via_email ?? false}
                via_phone={val?.direct_marketing?.via_phone ?? false}
              />
              <InvestedBadge val_invested={val.invested} />
              <WalletExistsBadge wallet_exists={val.wallet_exists} />
            </div>
          );
        },
      },
      {
        Header: t('table.actions'),
        width: 100,
        Cell: (cell: any) => {
          const val: UserRelatedAccountListResponseDto = cell.row.original;

          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                route={generatePath(RouteList.USERS.VIEW_COMPANY, { companyId: val?.id })}
                permissions={Permissions.BO__APPLICATIONS__VIEW_COMPANY}
              />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!user) return Promise.resolve();

      return Api.user
        .fetchUserRelatedAccounts(user.id, request)
        .then((response) => setResponse(response));
    },
    [user],
  );

  return (
    <React.Fragment>
      <Table
        title={'asd'}
        columns={columns}
        data={response}
        onFetchData={fetchData}
        searchable={true}
      />
    </React.Fragment>
  );
};

export default withUser(TabRelatedAccounts);
