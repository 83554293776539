import { BorrowerPayoutStatusEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutStatusEnum';
import { RouteList } from 'src/routes';

export const BorrowerPayoutRouteStatusMap = {
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.ALL]: undefined,
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.DRAFT]: BorrowerPayoutStatusEnum.DRAFT,
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.INIT]: BorrowerPayoutStatusEnum.INIT,
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.PAID]: BorrowerPayoutStatusEnum.PAID,
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.PENDING]: BorrowerPayoutStatusEnum.PENDING,
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.RECEIVED]: BorrowerPayoutStatusEnum.RECEIVED,
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.SCHEDULED]: BorrowerPayoutStatusEnum.SCHEDULED,
};

export const BorrowerPayoutsRouteTranslationMap = {
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.ALL]: 'common.all',
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.DRAFT]: 'payments.borrower_payouts.status.draft',
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.INIT]: 'payments.borrower_payouts.status.init',
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.PAID]: 'payments.borrower_payouts.status.paid',
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.PENDING]: 'payments.borrower_payouts.status.pending',
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.RECEIVED]: 'payments.borrower_payouts.status.received',
  [RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST.SCHEDULED]:
    'payments.borrower_payouts.status.scheduled',
};
