import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardCardGrid } from 'src/pages/Common/Dashboard/DashboardCardGrid';
import Loader from 'src/components/Loader';
import { useInvestorsCategoriesStatisticsQuery } from 'src/api/queries/statistics/useInvestorsCategoriesStatisticsQuery';

export const DashboardInvestorsCategories = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useInvestorsCategoriesStatisticsQuery();

  return (
    <div>
      <h3 className="card-ml-1">{t('dashboard.investors_categories')}</h3>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <>
          {data && (
            <DashboardCardGrid
              cards={[
                {
                  title: t('dashboard.novice_investors'),
                  value: data.novice_investors,
                },
                {
                  title: t('dashboard.experienced_investors'),
                  value: data.experienced_investors,
                },
              ]}
            />
          )}
        </>
      )}
    </div>
  );
};
