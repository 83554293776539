import { ErrorMessage, useField, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RadioInput from './RadioInput';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  values: string[];
  showPlaceholder: boolean;
}

const RadioInputGroup: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  const [, meta] = useField(props.name);
  const formik = useFormikContext();

  if (!props.placeholder && props.showPlaceholder) {
    props.placeholder = t('label.' + props.name);
  }

  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;

  const radios = props.values.map((value) => (
    <div className={meta.error ? 'is-invalid' : ''} key={value}>
      <RadioInput
        key={value}
        name={props.name}
        value={value}
        showPlaceholder={props.showPlaceholder}
      />
    </div>
  ));

  return (
    <div className={`form-input mb-3`}>
      <label className={'form-question-label'} htmlFor={props.name}>
        {props.placeholder}
      </label>
      {radios}
      {isError && (
        <div className={'invalid-feedback ms-2'}>
          {props.name && <ErrorMessage name={props.name} />}
        </div>
      )}
    </div>
  );
};

export default RadioInputGroup;
