import Client from './client';
import Http from '../http';
import {
  ConfirmPasswordResetRequestDto,
  LoginRequestDto,
  LoginResponseDto,
  ResetPasswordRequestDto,
} from 'src/types/api/authentication';

class Authentication extends Client {
  api = {
    LOGIN: `${this.http.baseUrl}/auth/login`,
    RESET_PASSWORD: `${this.http.baseUrl}/auth/reset-password`,
    CONFIRM_PASSWORD: `${this.http.baseUrl}/auth/confirm-password-reset`,
  };

  constructor(http: Http) {
    super(http);
  }

  login = (data: LoginRequestDto): Promise<LoginResponseDto> => {
    return this.http.post(this.api.LOGIN, data);
  };

  resetPassword = (data: ResetPasswordRequestDto): Promise<any> => {
    return this.http.post(this.api.RESET_PASSWORD, data);
  };

  confirmPassword = (data: ConfirmPasswordResetRequestDto): Promise<any> => {
    return this.http.post(this.api.CONFIRM_PASSWORD, data);
  };
}

export default Authentication;
