import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Form/TextInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { UseNumbers } from 'src/helpers/useNumbers';
import SelectRealEstateTypeInput from 'src/components/Form/Select/SelectRealEstateTypeInput';
import { SingleSecurityDepositProp } from 'src/pages/Application/Update/Tabs/Securities/SingleSecurityDeposit';

const CreateSingleSecurityDeposit: React.FC<SingleSecurityDepositProp> = ({
  index,
  formikArrayHelpers,
}) => {
  const { t } = useTranslation();

  const realEstateType = `security_deposits[${index}].real_estate_type`;
  const realEstateUnique = `security_deposits[${index}].real_estate_unique`;
  const realEstatePlot = `security_deposits[${index}].real_estate_plot`;
  const realEstateAddress = `security_deposits[${index}].real_estate_address`;
  const realEstateValue = `security_deposits[${index}].real_estate_value`;
  const realEstateAppraiser = `security_deposits[${index}].real_estate_appraiser`;
  const realEstateAppraiserAt = `security_deposits[${index}].real_estate_appraised_at`;

  return (
    <>
      <tr className={'table-tr-distort-fix'}>
        <td>
          <SelectRealEstateTypeInput
            name={realEstateType}
            isClearable={false}
            placeholder={t('label.real_estate_type')}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateUnique}
            placeholder={t('label.real_estate_unique')}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstatePlot}
            placeholder={t('label.real_estate_plot')}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateAddress}
            placeholder={t('label.real_estate_address')}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            type={'number'}
            step={'any'}
            name={realEstateValue}
            placeholder={t('label.real_estate_value')}
            hideLabel={true}
            onKeyPress={UseNumbers.preventNonNumericalInput}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateAppraiser}
            placeholder={t('label.real_estate_appraiser')}
            hideLabel={true}
          />
        </td>
        <td className={'align-top'}>
          <DatePickerInput
            name={realEstateAppraiserAt}
            placeholder={t('label.real_estate_appraised_at')}
            hideLabel={true}
          />
        </td>
        <td className={'align-top'}>
          <div>
            <PrimaryButton
              type={'button'}
              className={'btn btn-danger m-0'}
              title={t('common.delete')}
              style={{ backgroundColor: '#f46a6a' }}
              onClick={() => formikArrayHelpers?.remove(index)}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default CreateSingleSecurityDeposit;
