export enum ProjectLoanStatusEnum {
  INIT = 'init',
  NEW = 'new',
  SCHEDULED = 'scheduled',
  CONFIRMED = 'confirmed',
  LATE = 'late',
  RECOVERING = 'recovering',
  OTHER = 'other',
  FINISHED = 'finished',
}
