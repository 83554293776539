import React from 'react';
import { Formik } from 'formik';
import { ProjectInvestmentListResponseDto } from 'src/types/api/projects';
import { Card, CardBody, Col, Form, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SelectAsyncInput } from 'src/components/Form/Select';
import Api from 'src/api';
import { AvailableBonusSelect, BonusCodesDto } from 'src/types/api/bonusSystem';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import SaveButton from 'src/components/Form/SaveButton';

interface Props {
  investment: ProjectInvestmentListResponseDto;
  projectId: string | undefined;
}

export const AddBonusToInvestmentModal = ({ investment, projectId }: Props) => {
  const { t } = useTranslation();
  const [initialValues] = React.useState<BonusCodesDto>({ bonus_codes: [] });
  const [availableBonuses, setAvailableBonuses] = React.useState<any[]>([]);
  const { hideModal } = useGlobalModalContext();

  React.useEffect(() => {
    Api.bonusSystem.getBonusesByInvestment(investment.id).then((res) => setAvailableBonuses(res));
  }, [investment.id]);

  const getAvailableBonuses = async (): Promise<AvailableBonusSelect[]> => {
    return availableBonuses.map((item) => ({
      value: item.code,
      summable: item.summable,
      label: `${item.code} (+${item.percentage}%) (${
        item.summable ? t('label.summable') : t('label.not_summable')
      })`,
    }));
  };

  const onSubmit = React.useCallback(
    async (request: any) => {
      if (projectId) await Api.projects.updateInvestmentBonuses(projectId, investment.id, request);
      hideModal();
    },
    [projectId, investment.id, hideModal],
  );

  return (
    <Card className="card h-100">
      <CardBody>
        <div className={'float-end'} />
        <Row>
          <Col>
            <div>{t('table.id')}</div>
            <div>{investment.id}</div>
          </Col>
          <Col>
            <div>{t('table.owner_pid')}</div>
            <div>{investment.owner?.pid}</div>
          </Col>
          <Col>
            <div>{t('table.owner_name')}</div>
            <div>{investment.owner?.name}</div>
          </Col>

          <Col>
            <div>{t('table.status')}</div>
            <div>{investment.calculated_status}</div>
          </Col>
          <Col>
            <div>{t('table.type')}</div>
            <div>{investment.type}</div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <div>{t('table.amount')}</div>
            <div>{investment.amount}</div>
          </Col>
          <Col>
            <div>{t('table.basic_interest')}</div>
            <div>{t('common.percents', { value: investment.basic_interest })}</div>
          </Col>
          <Col>
            <div>{t('table.profitus_interest')}</div>
            <div>{t('common.percents', { value: investment.profitus_interest })}</div>
          </Col>
          <Col>
            <div>{t('label.owner_interest')}</div>
            <div>{t('common.percents', { value: investment.owner_interest })}</div>
          </Col>
          <Col>
            <div>{t('label.total_interest')}</div>
            <div>{t('common.percents', { value: investment.total_interest })}</div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <div>{t('table.created_at')}</div>
            <div>{t('common.date_full', { date: investment.created_at })}</div>
          </Col>
          <Col>
            <div>{t('bonus_system.bonus_condition')}</div>

            <div>
              {investment.bonuses.length > 0
                ? investment.bonuses.map(
                    (bonus) =>
                      `${bonus.code} ${bonus.percentage ? `(+${bonus.percentage}%)` : ''} ${
                        bonus.amount ? `(+${t('common.money', { value: bonus.amount })})` : ''
                      }(${bonus.summable ? t('label.summable') : t('label.not_summable')}); `,
                  )
                : '-'}
            </div>
          </Col>
        </Row>
        <br />
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting }) => (
            <>
              <Row>
                <Form onSubmit={handleSubmit} id={'add-bonus-for-investment-form'}>
                  <SelectAsyncInput
                    name={'bonus_codes'}
                    isClearable={true}
                    isSearchable={false}
                    isMulti={true}
                    loadOptions={getAvailableBonuses}
                  />
                </Form>
              </Row>
              <br />
              <Row>
                <SaveButton
                  form={'add-bonus-for-investment-form'}
                  submitting={isSubmitting}
                  title={t('common.save')}
                  type={'submit'}
                />
              </Row>
            </>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};
