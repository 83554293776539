import { ProjectResponseDto } from 'src/types/api/projects';
import React, { createContext, useContext } from 'react';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';

export interface ProjectContextState {
  language: LanguageEnum | undefined;
  setLanguage: (language: LanguageEnum) => void;
  project: ProjectResponseDto | undefined;
  setProject: (project: ProjectResponseDto) => void;
  onErrorHandling: (errors: any) => void;
}

const ProjectContext = createContext<ProjectContextState>({
  project: undefined,
  language: undefined,
  setLanguage: () => {
    return;
  },
  setProject: () => {
    return;
  },
  onErrorHandling: () => {
    return;
  },
});

export const ProjectProvider: React.FC<ProjectContextState> = ({
  project,
  setProject,
  setLanguage,
  language,
  onErrorHandling,
  children,
}) => {
  return (
    <ProjectContext.Provider
      value={{ project, setProject, language, setLanguage, onErrorHandling }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export function withProject<P extends ProjectContextState = ProjectContextState>(
  Component: React.ComponentType<P>,
) {
  const WithProject = ({ ...props }: Optionalize<P, ProjectContextState>) => {
    const c = useContext(ProjectContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithProject.displayName = `withProject${Component.displayName}`;

  return WithProject;
}

export const useProject = (): ProjectContextState => {
  return useContext(ProjectContext);
};

export default ProjectProvider;
