import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { setGlobalLoading } from 'src/modules/app/actions';

import { PaginationData, PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import { AutoHistoryResponseDto } from 'src/types/api/communication';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';
import DateFilter from 'src/components/Table/AdvanceFilters/DateFilter';
import { CommonDocumentTypeEnum } from 'src/helpers/Enums/CommonDocumentTypeEnum';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const AutoHistoryList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<AutoHistoryResponseDto>>();

  const columns = useMemo<Column<AutoHistoryResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.subject'),
        accessor: 'subject',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.path'),
        accessor: 'path',
        sortType: 'string',
        width: 150,
      },
      {
        Header: t('table.channel'),
        accessor: 'channel',
        width: 200,
      },
      {
        Header: t('table.created_at_history'),
        accessor: 'created_at',
        Cell: (cell: Cell<AutoHistoryResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
        Filter: DateFilter,
      },
    ],
    [t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.communication.fetchFilteredUsersHistory(request).then((response) => {
      setData(response);
    });
  }, []);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const exportCsv = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.communication.exportAutoHistoryDataCsv(request);
  }, []);

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'AutoHistoryListIndex'}>
        <Container fluid className={'auto-history-height'}>
          <Card>
            <CardBody>
              <h4 className={'mb-4'}>{t('menu.auto_history')}</h4>
              <Table
                title={'AutoHistoryListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                disableFiltersInsideTable={true}
                exportLinks={[
                  {
                    onExport: exportCsv,
                    type: CommonDocumentTypeEnum.CSV,
                    fileName: 'auto_history',
                    buttonTitle: t('common.export'),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default AutoHistoryList;
