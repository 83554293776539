import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { success } from 'src/services/toastr';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { DATA_IMPORT_TYPES } from 'src/components/Form/FileUpload/FileUpload';
import { ImportDataRequestDto } from 'src/types/api/common';
import FileUploadDirect from 'src/components/Form/FileUpload/FileUploadDirect';

interface Props {
  onAction: (request: any) => Promise<any>;
}

const ImportDataModal: React.FC<Props> = ({ onAction }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const RequestSchema = Yup.object().shape({
    document: Yup.mixed().required(),
  });

  const [request] = useState<ImportDataRequestDto>({
    document: null,
  });

  const onSubmit = useCallback(
    async (values: ImportDataRequestDto, helpers) => {
      return onAction(values)
        .then(() => {
          success(t('table.import_modal.success'));
          hideModal();
        })
        .catch((e) => {
          helpers.setErrors(e.response?.errors);
        });
    },
    [hideModal, onAction, t],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('table.import_modal.title')}
          </ModalHeader>
          <Formik initialValues={request} validationSchema={RequestSchema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <>
                <ModalBody>
                  <p className={'text-pre'}>{t('table.import_modal.text')}</p>
                  <Form onSubmit={handleSubmit} id={'reason-form'}>
                    <FileUploadDirect
                      name={'document'}
                      maxFiles={1}
                      accept={DATA_IMPORT_TYPES}
                      disabled={isSubmitting}
                    />
                  </Form>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      form={'reason-form'}
                      submitting={isSubmitting}
                      title={t('common.import')}
                      type={'submit'}
                    />
                  </Col>
                </ModalFooter>
              </>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ImportDataModal;
