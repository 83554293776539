import { BaseFilterForm, GenerateReportRequestDto } from 'src/types/api/reports';
import { ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';

export const initialRequest = (
  request: BaseFilterForm,
  type: ReportTypeEnum,
): GenerateReportRequestDto => {
  return {
    type: type,
    name: request.name,
    columns: request.columns,
    filters: [],
  };
};

export const appendFilter = (
  request: GenerateReportRequestDto,
  id: string,
  value: string | object | boolean | null | undefined,
): void => {
  if (value !== null && value !== undefined && value !== '') {
    request.filters.push({
      id: id,
      value: value,
    });
  }
};

export const appendDateFilter = (
  request: GenerateReportRequestDto,
  id: string,
  from: Date | null | undefined,
  to: Date | null | undefined,
): void => {
  if (from || to) {
    appendFilter(request, id, {
      startDate: from,
      endDate: to,
    });
  }
};
