import Client from './client';
import { ApplicationResponseDto } from 'src/types/api/applications';

class ApplicationSecurityDeposit extends Client {
  api = {
    RELEASE_APPLICATION_SECURITY: `${this.http.baseUrl}/applications/application/{applicationId}/security-deposits/{applicationSecurityDepositId}/release`,
  };

  releaseApplicationSecurityDeposit = (
    applicationId: string | number,
    applicationSecurityDepositId: string | number,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.RELEASE_APPLICATION_SECURITY, {
      applicationId,
      applicationSecurityDepositId,
    });

    return this.http.post(url);
  };
}

export default ApplicationSecurityDeposit;
