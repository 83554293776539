import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Api from 'src/api';
import { RouteList } from 'src/routes';
import { Permissions } from 'src/helpers/auth/permissions';
import { default as DataTab } from 'src/pages/Payment/ProjectPayments/Update/Tabs/TabData';
import TabBorrowerPayouts from 'src/pages/Payment/ProjectPayments/Update/Tabs/TabBorrowerPayouts';
import TabLoanInformation from 'src/pages/Payment/ProjectPayments/Update/Tabs/TabLoanInformation';
import { PaymentResponseDto } from 'src/types/api/payments/projectPayouts';
import { setGlobalLoading } from 'src/modules/app/actions';
import TabData, { BaseTabProps, TabInfo } from 'src/components/Tabs';
import PaymentProvider from 'src/pages/Payment/ProjectPayments/Update/PaymentContext';
import TabLoanFines from 'src/pages/Payment/ProjectPayments/Update/Tabs/TabLoanFines/List';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import TabAdditionalLoanAttribute from 'src/pages/Payment/ProjectPayments/Update/Tabs/AdditionalLoanAttribute';
import TabProjectFinancing from 'src/pages/Payment/ProjectPayments/Update/Tabs/TabProjectFinancing';

const Tabs: TabInfo<BaseTabProps>[] = [
  {
    name: 'payments.data',
    iconClass: 'far fa-file',
    component: DataTab,
    permission: [Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__VIEW__DATA],
  },
  {
    name: 'payments.borrower_payouts',
    iconClass: 'far fa-file',
    component: TabBorrowerPayouts,
    permission: [Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__VIEW],
  },
  {
    name: 'payments.loan_information',
    iconClass: 'far fa-file',
    component: TabLoanInformation,
    permission: [Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__VIEW],
  },
  {
    name: 'payments.loan_fines',
    iconClass: 'far fa-file',
    component: TabLoanFines,
    permission: [Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__VIEW__LOAN_FINES],
  },
  {
    name: 'payments.additional_loan_attribute',
    iconClass: 'far fa-file',
    component: TabAdditionalLoanAttribute,
    permission: [Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__SET__ADDITIONAL_LOAN_ATTRIBUTE],
  },
  {
    name: 'payments.project_financing',
    iconClass: 'far fa-file',
    component: TabProjectFinancing,
    permission: [Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__TRANSFER_FUNDS],
  },
];

const Payment: React.FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const [payment, setPayment] = useState<PaymentResponseDto>();

  useEffect(() => {
    setGlobalLoading(true);
    if (projectId) {
      Api.payments.projectPayouts.fetchPayment(projectId).then((response) => {
        setPayment(response);
        setGlobalLoading(false);
      });
    }
  }, [projectId]);

  if (!payment) {
    return <></>;
  }

  return (
    <React.Fragment>
      <PaymentProvider payment={payment} setPayment={setPayment}>
        <Container fluid>
          <Row>
            <Col md={1} className={'mb-4'}>
              <div>
                <Link to={RouteList.PAYMENTS.PROJECT_PAYOUTS.LIST}>
                  <PrimaryButton title={t('common.back')} type={'button'} />
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={'mb-4'}>
              <Card className="card h-100">
                <CardBody>
                  <Row>
                    <Col>
                      <div>{payment.pid}</div>
                      <div>{payment.project_name}</div>
                      <div>{t('projects.status.' + payment.project_status)}</div>
                    </Col>
                    <Col>
                      <div>{payment?.owner_name}</div>
                      <div>{payment?.owner_pid}</div>
                    </Col>
                    <Col>
                      <div>{t('common.money', { value: payment.amount })}</div>
                      <div>{t('common.date', { date: payment.funded_at })}</div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-4">
                <CardBody>{payment && <TabData tabs={Tabs} vertical={true} />}</CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </PaymentProvider>
    </React.Fragment>
  );
};

export default Payment;
