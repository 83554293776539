import React, { useState } from 'react';
import SelectInput, { ReactSelectOption } from './SelectInput';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { ProjectLoanFineType } from 'src/helpers/Enums/ProjectLoanFineType';
import { useTranslation } from 'react-i18next';

interface Props extends StateManagerProps {
  name: string;
  placeholder: string;
}

const SelectLoanFineTypeInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [options] = useState<ReactSelectOption[]>([
    ...Object.values(ProjectLoanFineType).map((type) => ({
      value: type,
      label: t('label.loan_fine.' + type),
    })),
  ]);

  return <SelectInput {...props} options={options} />;
};

export default SelectLoanFineTypeInput;
