import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput, { ReactSelectOption } from './SelectInput';
import { GovernmentDocumentEnum } from 'src/helpers/Enums/GovernmentDocumentEnum';

interface Props {
  name: string;
  placeholder: string;
}

const SelectGovDocumentTypeInput: React.FC<Props> = ({ name, placeholder }) => {
  const { t } = useTranslation();

  const [options] = useState<ReactSelectOption[]>([
    {
      label: t(`users.document_type.${GovernmentDocumentEnum.IdentificationCard}`),
      value: GovernmentDocumentEnum.IdentificationCard,
    },
    {
      label: t(`users.document_type.${GovernmentDocumentEnum.IdCard}`),
      value: GovernmentDocumentEnum.IdCard,
    },
    {
      label: t(`users.document_type.${GovernmentDocumentEnum.Passport}`),
      value: GovernmentDocumentEnum.Passport,
    },
    {
      label: t(`users.document_type.${GovernmentDocumentEnum.DriverLicense}`),
      value: GovernmentDocumentEnum.DriverLicense,
    },
    {
      label: t(`users.document_type.${GovernmentDocumentEnum.ResidencePermit}`),
      value: GovernmentDocumentEnum.ResidencePermit,
    },
    {
      label: t(`users.document_type.${GovernmentDocumentEnum.InternalPassport}`),
      value: GovernmentDocumentEnum.InternalPassport,
    },
    {
      label: t(`users.document_type.${GovernmentDocumentEnum.SocialId}`),
      value: GovernmentDocumentEnum.SocialId,
    },
  ]);

  return <SelectInput name={name} options={options} placeholder={placeholder} />;
};

export default SelectGovDocumentTypeInput;
