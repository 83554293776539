import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { WalletOwnerTypeEnum } from 'src/helpers/Enums/Wallet/WalletOwnerTypeEnum';

interface Props extends StateManagerProps {
  name: string;
  placeholder?: string;
}

const SelectWalletOwnerInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [options] = useState<ReactSelectOption[]>([
    {
      value: '',
      label: t('common.all'),
    },
    ...Object.values(WalletOwnerTypeEnum).map((type) => ({
      value: type,
      label: t('reports.wallet_owner_type.' + type),
    })),
  ]);

  return <SelectInput {...props} options={options} />;
};

export default SelectWalletOwnerInput;
