import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import Api from 'src/api';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { success } from 'src/services/toastr';

import { SelectAsyncInput } from 'src/components/Form/Select';
import { UserBriefResponseDto } from 'src/types/api/user';
import SaveButton from 'src/components/Form/SaveButton';
import DenyButton from 'src/components/Form/DenyButton';
import { PaginationDataFilter } from 'src/types';

interface AssignAnalyticRequestDto {
  analytic_id: string;
}

interface AssignAnalyticModalProps {
  onAction: (request: AssignAnalyticRequestDto) => Promise<any>;
  currentAnalyticId?: string;
  title?: string;
  body?: string;
}

const AssignAnalyticModal: React.FC<AssignAnalyticModalProps> = ({
  onAction,
  currentAnalyticId,
  title,
  body,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [changeApplicationAnalyticRequest] = useState<AssignAnalyticRequestDto>({
    analytic_id: currentAnalyticId ?? '',
  });

  const fetchAnalytics = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response = await Api.user.fetchFilterableAnalytics(request);
    const items: ReactSelectOption[] = [];

    response.data.map((analytic: UserBriefResponseDto) => {
      items.push({
        value: analytic.id ?? '',
        label: analytic.name ?? '',
      });
    });

    return items;
  };

  const onSubmit = useCallback(
    (request: AssignAnalyticRequestDto, helper: FormikHelpers<AssignAnalyticRequestDto>) => {
      return onAction(request)
        .then(() => {
          success(t('common.updated_success'));
          hideModal();
        })
        .catch((e) => {
          helper.setErrors(e.response?.errors);
        });
    },
    [t, hideModal, onAction],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title}
          </ModalHeader>
          <Formik initialValues={changeApplicationAnalyticRequest} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <SelectAsyncInput
                    placeholder={body}
                    name={'analytic_id'}
                    isClearable={true}
                    loadOptions={fetchAnalytics}
                  />
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default AssignAnalyticModal;
