import { statistics } from 'src/api/queries';
import Api from 'src/api/index';
import { defaultOptions, UseQueryOptionsObject } from 'src/api/queries/queries.utils';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { MarketingDashboardPageResponseDto } from 'src/types/api/dashboard';

const localDefaultOptions: typeof defaultOptions = {
  ...defaultOptions,
  retry: false,
  retryOnMount: false,
};

const useCurrentMonthMarketingStatisticQuery = (
  options?: UseQueryOptionsObject<MarketingDashboardPageResponseDto>,
) => {
  return useMakeQuery<MarketingDashboardPageResponseDto>(
    [statistics.marketingCurrentMonth],
    () => Api.dashboard.fetchMarketingDashboardCurrentMonth(),
    {
      staleTime: 120 * 1000,
      ...localDefaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useCurrentMonthMarketingStatisticQuery, localDefaultOptions };
