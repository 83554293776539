import React from 'react';
import { Card, CardBody, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CurrentUserResponseDto } from 'src/types/api/currentUser';

interface Props {
  user: CurrentUserResponseDto;
}

const ProfileCard: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <Card className={'mb-4'}>
      <CardBody>
        <h4 className={'mb-4'}>{t('label.profile')}</h4>
        <div>
          <FormGroup>
            <div className={'mb-3'}>
              <input name={'email'} value={user?.email} disabled className={'form-control'} />
            </div>
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProfileCard;
