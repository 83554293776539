import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import SidebarContent from './SidebarContent';
import ImageAsset from 'src/helpers/Enums/ImageAsset';
import { RouteList } from 'src/routes';
import useAppState from 'src/modules/appState';

interface Props extends RouteComponentProps {
  type: string;
  theme: string;
}

const Sidebar: React.FC<Props> = () => {
  const { toggleSidebar } = useAppState();

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to={RouteList.HOME} className="logo">
            <span className={'logo-lg'}>
              <img src={ImageAsset.LogoMainLg} height={26} alt={'Logo'} />
            </span>
            <span className={'logo-sm'}>
              <img src={ImageAsset.LogoMainSm} height={26} alt={'Logo'} />
            </span>
          </Link>
        </div>
        <button
          type="button"
          onClick={toggleSidebar}
          className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
          id="vertical-menu-btn"
        >
          <i className="fa fa-fw fa-bars" />
        </button>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Sidebar);
