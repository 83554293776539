import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import { Col, Form, FormGroup, ModalBody, ModalFooter } from 'reactstrap';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import * as Yup from 'yup';
import { CreateReservedInvestmentRequestDto } from 'src/types/api/user';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import TextInput from 'src/components/Form/TextInput';
import Api from 'src/api';
import { ProjectInvestmentListResponseDto, ProjectResponseDto } from 'src/types/api/projects';
import { SelectAsyncInput, SelectInput } from 'src/components/Form/Select';
import { PaginationDataFilter } from 'src/types';
import { AvailableBonusSelect, BonusSystemBriefResponseDto } from 'src/types/api/bonusSystem';
import { shouldBonusOptionBeDisabled } from 'src/pages/Project/Update/Tabs/Investments/utils/optionDisabledService';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { BonusConditionEnum } from 'src/helpers/Enums/BonusSystem/BonusConditionEnum';
import SelectBonusTypesInput from 'src/components/Form/Select/SelectBonusTypeInput';
import FileUpload, {
  DOCUMENT_TYPES,
  FileUploadHandle,
} from 'src/components/Form/FileUpload/FileUpload';
import { getEnumSelectValues } from 'src/helpers/Enums/enumHelper';
import { InvestorProjectLoanAgreementDocumentTypeEnum } from 'src/helpers/Enums/Project/InvestorProjectLoanAgreementDocumentTypeEnum';

interface Props {
  project: ProjectResponseDto | undefined;
  afterCreateInvestmentEvent: (investment: ProjectInvestmentListResponseDto) => void;
}

const CreateReservedInvestmentModalForm = ({ project, afterCreateInvestmentEvent }: Props) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const [initialValues] = useState<CreateReservedInvestmentRequestDto>({
    owner: null,
    user_id: null,
    user_type: null,
    amount: 100,
    bonus: [],
    new_bonus: undefined,
    media_ids: [],
    tac_document_type: InvestorProjectLoanAgreementDocumentTypeEnum.DEFAULT,
  });

  const [availableBonuses, setAvailableBonuses] = useState<BonusSystemBriefResponseDto[]>([]);
  const [creatingNewBonus, setCreatingNewBonus] = useState<boolean>(false);
  const [isCreatingNewBonusAllowed, setIsCreatingNewBonusAllowed] = useState<boolean>(false);
  const fileUploadRef = React.useRef<FileUploadHandle>(null);

  const CreateReservedInvestmentRequestSchema = (creatingNewBonus: boolean) => {
    let schema = Yup.object().shape({
      owner: Yup.string().required(),
      user_id: Yup.string().nullable(),
      user_type: Yup.string().nullable(),
      amount: Yup.number(),
    });
    if (creatingNewBonus) {
      schema = schema.shape({
        new_bonus: Yup.object().shape({
          type: Yup.string().required(),
          percentage: Yup.number().max(5).required(),
        }),
      });
    }

    return schema;
  };

  const onSubmit = useCallback(
    async (request: CreateReservedInvestmentRequestDto) => {
      if (!creatingNewBonus) {
        request.new_bonus = undefined;
      }
      if (project) {
        if (request.owner) {
          const user = JSON.parse(request.owner);
          request.user_id = user.user_id;
          request.user_type = user.user_type;
          request.media_ids =
            request.tac_document_type === InvestorProjectLoanAgreementDocumentTypeEnum.DEFAULT
              ? []
              : request.media_ids;
        }

        const res = await Api.projects.createReservedInvestment(project.id, request);

        hideModal();
        afterCreateInvestmentEvent(res);
      }
    },
    [project, hideModal, afterCreateInvestmentEvent, creatingNewBonus],
  );

  const fetchUsers = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response = await Api.user.fetchFilteredInvestorsUsers(request);

    return response.data.map((item) => ({
      value: JSON.stringify({
        user_id: item.id,
        user_type: item.class,
      }),
      label: item.private_id + ' - ' + item.name,
    }));
  };

  const getAvailableBonuses = async (): Promise<AvailableBonusSelect[]> => {
    return availableBonuses.map((item) => ({
      value: item.code,
      summable: item.summable,
      label: `${item.code} (+${item.percentage}%) (${
        item.summable ? t('label.summable') : t('label.not_summable')
      })`,
    }));
  };

  const onUserChange = async (value: any) => {
    if (project && value) {
      const user = JSON.parse(value.value);

      const res = await Api.bonusSystem.getBonusesByUserAndProject({
        user_id: user.user_id,
        user_type: user.user_type,
        project_id: project.id,
      });

      setIsCreatingNewBonusAllowed(true);
      setAvailableBonuses(res);
    } else {
      setIsCreatingNewBonusAllowed(false);
      setCreatingNewBonus(false);
    }
  };

  const createNewBonus = () => {
    return (
      <>
        <div className="mb-3">
          <SelectBonusTypesInput name={'new_bonus.type'} placeholder={t('label.type')} />
        </div>
        <div className={`mt-4`}>
          <div className="mb-3">
            <TextInput
              type={'number'}
              step={'any'}
              name={'new_bonus.' + BonusConditionEnum.PERCENTAGE}
              placeholder={t('label.' + BonusConditionEnum.PERCENTAGE)}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateReservedInvestmentRequestSchema(creatingNewBonus)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          <ModalBody className={''}>
            <Form onSubmit={handleSubmit} id={'create-reserved-investment-form'}>
              <FormGroup>
                <div className="mb-3">
                  <SelectAsyncInput
                    name={'owner'}
                    isClearable={true}
                    loadOptions={fetchUsers}
                    onChange={onUserChange}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <TextInput name="amount" type="number" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <SelectAsyncInput
                    name={'bonus'}
                    isClearable={true}
                    isSearchable={false}
                    isMulti={true}
                    loadOptions={getAvailableBonuses}
                    isOptionDisabled={(option: any, selectValue: any) =>
                      shouldBonusOptionBeDisabled(option, selectValue)
                    }
                  />
                </div>
              </FormGroup>
              <FormGroup>
                {!creatingNewBonus ? (
                  <PrimaryButton
                    disabled={!isCreatingNewBonusAllowed}
                    className={'btn btn-primary mb-3'}
                    onClick={() => setCreatingNewBonus(true)}
                    title={t('common.create')}
                    type={'button'}
                  />
                ) : (
                  <PrimaryButton
                    className={'btn btn-primary mb-3'}
                    onClick={() => setCreatingNewBonus(false)}
                    title={t('common.cancel')}
                    type={'button'}
                  />
                )}
                {creatingNewBonus && createNewBonus()}
              </FormGroup>
            </Form>
            <div className={'mb-3'}>
              <h4 className={'mb-2'}>{t('projects.documents.types.custom_investment_tac')}</h4>
              <div className={'mb-4'}>
                <SelectInput
                  name={'tac_document_type'}
                  placeholder={t('label.type')}
                  isMulti={false}
                  options={getEnumSelectValues(
                    InvestorProjectLoanAgreementDocumentTypeEnum,
                    'projects.documents.types.investor_loan_agreement_type',
                  )}
                />
              </div>
              {values.tac_document_type == InvestorProjectLoanAgreementDocumentTypeEnum.CUSTOM &&
                project && (
                  <FileUpload
                    ref={fileUploadRef}
                    name={'media_ids'}
                    onPresign={(request) =>
                      Api.projects.uploadReservedInvestmentTacDocument(project.id, request)
                    }
                    accept={DOCUMENT_TYPES}
                    maxFiles={1}
                  />
                )}
            </div>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-center'}>
            <Col>
              <DenyButton title={t('common.cancel')} onClick={hideModal} />
            </Col>
            <Col>
              <SaveButton
                form={'create-reserved-investment-form'}
                submitting={isSubmitting}
                title={t('common.save')}
                type={'submit'}
              />
            </Col>
          </ModalFooter>
        </>
      )}
    </Formik>
  );
};

export default CreateReservedInvestmentModalForm;
