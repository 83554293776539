import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import WalletP2POperationList from './P2P/List';
import WalletMoneyOperationList from 'src/pages/Operation/Money/List';
import { Permissions } from 'src/helpers/auth/permissions';

const OperationRoutes: any = () => [
  <AuthRoute
    path={RouteList.OPERATIONS.P2P.LIST}
    component={WalletP2POperationList}
    layout={PageLayout}
    permissions={[Permissions.BO__OPERATIONS__P2P__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.OPERATIONS.P2P.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.OPERATIONS.MONEY.LIST}
    component={WalletMoneyOperationList}
    layout={PageLayout}
    permissions={[Permissions.BO__OPERATIONS__MONEY__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.OPERATIONS.MONEY.LIST}
    exact
  />,
];

export default OperationRoutes;
