import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownItem,
  DropdownMenu,
  DropdownProps,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import AssignCommitteeMemberModal from 'src/pages/Application/List/AssignCommitteeMemberModal';

type Props = DropdownProps;

const ControlDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const assignCommitteeMembers = useCallback(() => {
    showModal(<AssignCommitteeMemberModal />);
  }, [showModal]);

  return (
    <React.Fragment>
      <UncontrolledDropdown {...props}>
        <DropdownToggle tag="a" className="cursor text-body font-size-16" caret>
          <i className="fas fa-ellipsis-h" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <PermissionAction permissions={Permissions.BO__APPLICATIONS__REJECT}>
            <DropdownItem onClick={assignCommitteeMembers}>
              {t('applications.assign_committee_members')}
            </DropdownItem>
          </PermissionAction>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
};

export default ControlDropdown;
