import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import { BaseTabProps } from 'src/components/Tabs';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import CountryLabel from 'src/components/Labels/CountryLabel';
import {
  ApplicationBeneficiary,
  UpdateApplicationOwnerCommentRequestDto,
} from 'src/types/api/applications';
import SaveButton from 'src/components/Form/SaveButton';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import TextAreaInput from 'src/components/Form/TextAreaInput';

interface Props extends BaseTabProps, ApplicationContextState {}

const INITIAL_REQUEST: UpdateApplicationOwnerCommentRequestDto = {
  owner_comment: null,
};

const TabCompany: React.FC<Props> = ({ application, setApplication }) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<UpdateApplicationOwnerCommentRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!application) return;

    setRequest({
      owner_comment: application.owner_comment,
    });
  }, [application]);

  const onSubmit = useCallback(
    async (
      request: UpdateApplicationOwnerCommentRequestDto,
      helpers: FormikHelpers<UpdateApplicationOwnerCommentRequestDto>,
    ) => {
      if (!application) return;

      try {
        const response = await Api.applications.UpdateOwnerComment(application.id, request);
        setApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [application, setApplication, t],
  );

  const OwnerCommentForm: React.FC = () => {
    return (
      <Formik initialValues={request} enableReinitialize={true} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col>
                  <TextAreaInput name={'owner_comment'} />
                </Col>
              </Row>
              <div className={'mt-4 mb-4'}>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  };

  if (!application?.owner) {
    return (
      <React.Fragment>
        <h3>{t('applications.company')}</h3>
        <hr />
        <h5 className="mb-4">{t('applications.no_company')}</h5>
        <OwnerCommentForm />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h3>{t('applications.company')}</h3>
      <hr />
      <h5 className="mb-3">{t('applications.company_details')}</h5>
      <Row>
        <SidebarDataRow label={t('users.email')} value={application.owner.email} />
        <SidebarDataRow label={t('users.legal_code')} value={application.owner.legal_code} />
        <SidebarDataRow label={t('users.vat_code')} value={application.owner.vat_code} />
        <SidebarDataRow label={t('users.phone')} value={application.owner.phone} />
        <SidebarDataRow label={t('common.verified')} value={application.owner.verified} />
        <SidebarDataRow label={t('users.suspended_at')} value={application.owner.suspended_at} />
        <SidebarDataRow
          label={t('users.type')}
          value={t('common.type_' + application.owner.type)}
        />
        <SidebarDataRow
          label={t('users.country')}
          value={application.owner.country ? <CountryLabel code={application.owner.country} /> : ''}
        />
        <hr />
      </Row>
      <h5 className="mb-3">{t('applications.executive_details')}</h5>
      <Row>
        <SidebarDataRow
          label={t('users.first_name')}
          value={application.owner.executive_first_name}
        />
        <SidebarDataRow
          label={t('users.last_name')}
          value={application.owner.executive_last_name}
        />
        <SidebarDataRow
          label={t('users.house_number')}
          value={application.owner.executive_address_house_number}
        />
        <SidebarDataRow
          label={t('users.street')}
          value={application.owner.executive_address_street}
        />
        <SidebarDataRow label={t('users.city')} value={application.owner.executive_address_city} />
        <SidebarDataRow
          label={t('users.country')}
          value={
            application.owner.executive_address_country ? (
              <CountryLabel code={application.owner.executive_address_country} />
            ) : (
              ''
            )
          }
        />
        <SidebarDataRow label={t('users.phone')} value={application.owner.executive_phone} />
        <SidebarDataRow label={t('users.email')} value={application.owner.executive_email} />
        <SidebarDataRow label={t('users.gov_code')} value={application.owner.executive_gov_code} />
        <SidebarDataRow
          label={t('users.gov_document.type')}
          value={t(
            application.owner.executive_gov_document_type
              ? 'users.document_type.' +
                  application.owner.executive_gov_document_type?.toUpperCase()
              : '',
          )}
        />
        <SidebarDataRow
          label={t('users.gov_document.number')}
          value={application.owner.executive_gov_document_number}
        />
        <SidebarDataRow
          label={t('users.gov_document.issuer_country')}
          value={
            application.owner.executive_gov_document_issue_country ? (
              <CountryLabel code={application.owner.executive_gov_document_issue_country} />
            ) : (
              ''
            )
          }
        />
        <SidebarDataRow
          label={t('users.gov_document.expiration_date')}
          value={t('common.date', {
            date: application.owner.executive_gov_document_expiration_date,
          })}
        />
      </Row>
      <hr />
      <h5 className="mb-3">{t('label.company.beneficiaries')}</h5>
      {application.beneficiaries?.data?.map(
        (beneficiary: ApplicationBeneficiary, index: number) => {
          return (
            <Row key={index} className={'mb-5'}>
              <SidebarDataRow label={t('label.full_name')} value={beneficiary.full_name} />
              <SidebarDataRow label={t('label.company.shares')} value={beneficiary.shares} />
              <SidebarDataRow label={t('label.gov_code')} value={beneficiary.gov_code} />
              <SidebarDataRow
                label={t('label.company.is_public_official')}
                value={beneficiary.is_public_official ? t('common.yes') : t('common.no')}
              />
              <SidebarDataRow
                label={t('label.company.is_family_member_public_official')}
                value={
                  beneficiary.is_family_member_public_official ? t('common.yes') : t('common.no')
                }
              />
              <SidebarDataRow
                label={t('label.nationality')}
                value={t(`countries.${beneficiary.nationality}`)}
              />
            </Row>
          );
        },
      )}
      <hr />
      <OwnerCommentForm />
    </React.Fragment>
  );
};

export default withApplication(TabCompany);
