import React, { useCallback, useMemo, useState } from 'react';
import { PaginationData, PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import Table from 'src/components/Table';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { CommonActionListResponseDto } from 'src/types/api/common';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const TabActionHistory: React.FC<ApplicationContextState> = ({ application }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<CommonActionListResponseDto>>();

  const columns = useMemo<Column<CommonActionListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.user'),
        accessor: 'user_name',
        Cell: (cell: Cell<CommonActionListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {row.user_name} {row.original_user_name && <>({row.original_user_name})</>}
            </div>
          );
        },
      },
      {
        Header: t('table.event'),
        width: 300,
        accessor: 'event',
        Cell: (cell: Cell<CommonActionListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {t('audit.event.' + row.event, { type: t('audit.entity.' + row.audit_type) })}
            </div>
          );
        },
      },
      {
        Header: t('table.reason'),
        width: 400,
        accessor: 'new_values',
        Cell: (cell: Cell<CommonActionListResponseDto>) => <div>{cell.value?.reason}</div>,
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<CommonActionListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!application) return Promise.resolve();

      return Api.applications
        .fetchApplicationActionsStatus(application.id, request)
        .then((response) => setResponse(response));
    },
    [application],
  );

  return (
    <React.Fragment>
      <h3>{t('applications.status_history')}</h3>
      <hr />
      <div className={'small lh-sm'}>
        <HiddenColumnsProvider title={'ApplicationUpdateTabsTabStatusHistory'}>
          <Table
            title={'ApplicationUpdateTabsTabStatusHistory'}
            columns={columns}
            data={response}
            onFetchData={fetchData}
            initialSortBy={INITIAL_SORT_ORDER}
          />
        </HiddenColumnsProvider>
      </div>
    </React.Fragment>
  );
};

export default withApplication(TabActionHistory);
