import { CardInterface, DashboardCard } from 'src/pages/Common/Dashboard/DashboardCard';
import React from 'react';

interface Props {
  cards: CardInterface[];
}

export const DashboardCardGrid = ({ cards }: Props) => {
  return (
    <>
      <div style={{ display: 'inline-flex' }} className={'w-100'}>
        {cards.map((card, index) => (
          <DashboardCard
            key={index}
            title={card.title}
            value={card.value}
            tooltipElementId={card.tooltipElementId}
            tooltipValue={card.tooltipValue}
          />
        ))}
      </div>
    </>
  );
};
