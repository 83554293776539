import React, { useCallback, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Api from 'src/api';
import { toast } from 'react-toastify';
import success = toast.success;
import { RouteList } from 'src/routes';
import { navigate } from 'src/helpers/navigate';
import { transformErrors } from 'src/helpers';
import { setGlobalLoading } from 'src/modules/app/actions';
import { Link } from 'react-router-dom';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { SaveNewsRequestDto } from 'src/types/api/landingPage/news';
import NewsForm from 'src/pages/LandingPage/News/NewsForm';
import { FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';

const NewsCreate: React.FC = () => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);

  const onAction = useCallback(
    async (values: SaveNewsRequestDto, helpers) => {
      return Api.news
        .createNews(values)
        .then(() => {
          success(t('common.created_success'));
          navigate(RouteList.LANDING_PAGE.NEWS.LIST);
        })
        .catch((e) => {
          helpers.setErrors(transformErrors(e.response?.errors));
        });
    },
    [t],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={1} className={'mb-4'}>
            <Link to={RouteList.LANDING_PAGE.NEWS.LIST}>
              <PrimaryButton title={t('common.back')} type={'button'} />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Card>
              <CardBody>
                <h4 className={'mb-3'}>{t('pages.create_news')}</h4>
                <NewsForm onSubmit={onAction} uploadRef={fileUploadRef} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default NewsCreate;
