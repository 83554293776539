import React from 'react';
import DeleteEntityButton from 'src/components/Table/Buttons/DeleteEntityButton';
import { EditableField, EditableFieldData } from './Table/Fields/EditableFied';
import { useTranslation } from 'react-i18next';

interface ActivityFeedItemData {
  id: number | string;
  date: Date;
  author: string;
  message: string;
}

interface Props {
  data: ActivityFeedItemData;
  onDelete: () => Promise<any>;
  onUpdate?: (fieldData: EditableFieldData) => Promise<any>;
  permissions?: string | string[];
}

const ActivityFeedItem: React.FC<Props> = ({ data, onDelete, onUpdate, permissions }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <li className="feed-item">
        <div className="row">
          <div className="feed-item-list col">
            <p className="text-muted mb-1 font-size-13">
              <span className="text-primary mr-2"> {data.author}</span>
              &nbsp;
              {t('common.date_full', { date: data.date })}
            </p>
            <div className="mt-0 mb-0">
              {onUpdate ? (
                <EditableField
                  permissions={permissions}
                  data={{ id: data.id }}
                  text={data.message}
                  submit={onUpdate}
                />
              ) : (
                <p>{data.message}</p>
              )}
            </div>
          </div>
          <div className="col d-flex justify-content-end gap-3">
            <DeleteEntityButton permissions={permissions} onDelete={onDelete} />
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default ActivityFeedItem;
