import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import Table from 'src/components/Table';
import Api from 'src/api';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { capitalize, replace } from 'lodash';
import VerifiedBadge from 'src/pages/User/Badges/VerifiedBadge';
import DirectMarketingBadge from 'src/pages/User/Badges/DirectMarketingBadge';
import InvestedBadge from 'src/pages/User/Badges/InvestedBadge';
import { Permissions } from 'src/helpers/auth/permissions';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { LegalEntity, PaginationData, PaginationDataFilter } from 'src/types';
import { UserResponseDto } from 'src/types/api/user';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import WalletExistsBadge from 'src/pages/User/Badges/WalletExistsBadge';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const UserList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<UserResponseDto>>();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<UserResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'pid',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.name'),
        accessor: 'name',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<UserResponseDto>) => {
          return capitalize(replace(cell.value, '_', ' '));
        },
      },
      {
        Header: t('table.email'),
        accessor: 'email',
        sortType: 'string',
        width: 300,
      },
      {
        Header: t('table.phone'),
        accessor: 'phone',
        sortType: 'string',
      },
      {
        Header: t('table.registration_date'),
        accessor: 'created_at',
        sortType: 'datetime',
        width: 200,
        Cell: (cell: Cell<UserResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.information'),
        width: 100,
        Cell: (cell: Cell<UserResponseDto>) => {
          const val = cell.row.original;

          return (
            <div className={'actions d-flex gap-2'}>
              <VerifiedBadge verified={val.verified} />
              <DirectMarketingBadge
                via_email={val?.direct_marketing?.via_email ?? false}
                via_phone={val?.direct_marketing?.via_phone ?? false}
              />
              <InvestedBadge val_invested={val.invested} />
              <WalletExistsBadge wallet_exists={val.wallet_verified} />
            </div>
          );
        },
      },
      {
        Header: t('table.action'),
        width: 100,
        Cell: (cell: Cell<UserResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                route={generatePath(RouteList.USERS.VIEW_USER, {
                  userId: row.id,
                })}
                permissions={Permissions.BO__USERS__VIEW}
                show={row.legal_entity === LegalEntity.Personal}
                previousRoute={RouteList.USERS.LIST}
              />

              <EditEntityButton
                route={generatePath(RouteList.USERS.VIEW_COMPANY, { companyId: row.id })}
                permissions={Permissions.BO__USERS__VIEW}
                show={row.legal_entity === LegalEntity.Company}
                previousRoute={RouteList.USERS.LIST}
              />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.user.fetchFilteredUsers(request).then(setData);
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-4'}>{t('menu.users')}</h4>
            <HiddenColumnsProvider title={'UserListIndex'}>
              <Table
                title={'UserListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default UserList;
