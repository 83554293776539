import React from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import { useGlobalModalContext } from '../GlobalModal';
import ModalCloseButton from '../ModalCloseButton';
import { ModalComponent } from '../ModalComponent';

interface Props {
  title: string;
  size?: string;
  form: any;
}
const FormModal: React.FC<Props> = ({ title, size, form }) => {
  const { hideModal } = useGlobalModalContext();

  return (
    <React.Fragment>
      <ModalComponent size={size ?? 'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title}
          </ModalHeader>
          <ModalBody className={''}>{form}</ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default FormModal;
