import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
}

const IsVisibleBadge: React.FC<Props> = ({ show }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <i
        style={{ lineHeight: 'unset' }}
        title={t(show ? 'common.visible' : 'common.hidden')}
        className={'far fa-eye ' + (show ? 'text-success' : 'text-danger')}
      />
    </React.Fragment>
  );
};

export default IsVisibleBadge;
