import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import ImportRead from 'src/pages/Import/Read';

const ImportRoutes: any = () => [
  <AuthRoute
    path={RouteList.IMPORTS.VIEW}
    component={ImportRead}
    layout={PageLayout}
    permissions={[Permissions.BO__IMPORTS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.IMPORTS.VIEW}
    exact
  />,
];

export default ImportRoutes;
