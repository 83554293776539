import React from 'react';
import ProfileMenu from '../Common/ProfileMenu';
import useAppState from 'src/modules/appState';
import { NotificationDropdown } from 'src/components/Notifications';

//i18n.tsx

const Header: React.FC = () => {
  const { toggleSidebar } = useAppState();

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={toggleSidebar}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex align-items-center">
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
