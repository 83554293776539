import React from 'react';
import { useTranslation } from 'react-i18next';
import { success } from 'src/services/toastr';
import Api from 'src/api';
import copy from 'copy-to-clipboard';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  mediaId: string;
}

const DownloadButton: React.FC<Props> = ({ mediaId, className, type, ...props }) => {
  const { t } = useTranslation();

  const handleOnClick = () => {
    Api.media.copyPublicUrl(mediaId).then((response) => {
      copy(response.url);
      success(t('common.copied_to_clipboard'));
    });
  };

  return (
    <button
      className={`btn btn-success ${className ?? ''}`}
      type={type ?? 'button'}
      onClick={handleOnClick}
      {...props}
    >
      {t('common.copy_public_url')}
    </button>
  );
};

export default DownloadButton;
