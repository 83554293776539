import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { InquiryResponseDto } from 'src/types/api/landingPage/inquiry';

class Inquiries extends Client {
  api = {
    FETCH_INQUIRIES: `${this.http.baseUrl}/inquiries/filter`,
  };

  fetchFilteredInquiries = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InquiryResponseDto>> => {
    return this.http.get(this.api.FETCH_INQUIRIES, { params: request });
  };
}

export default Inquiries;
