import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import { GenerateReportRequestDto, InvestorReportFilterForm } from 'src/types/api/reports';
import { appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import { SelectInput } from 'src/components/Form/Select';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import SelectDirectMarketingInput from 'src/pages/Reports/View/Filters/Selects/SelectDirectMarketingInput';

const AVAILABLE_COLUMNS: string[] = [
  'pid',
  'has_invested',
  'name',
  'email',
  'phone',
  'is_accredited',
  'direct_marketing_email',
  'direct_marketing_phone',
  'residence',
  'channel_notification_language',
  'gender',
  'dob',
  'city',
  'gpm',
  'total_amount_invested',
  'number_of_investments',
  'total_amount_invested_active',
  'number_of_investments_active',
  'registered_at',
  'last_login_at',
  'last_investment_at',
  'has_used_referrals',
  'has_used_auto_investing',
  'has_used_secondary_market',
  'is_wallet_verified',
  'wallet_balance',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.INVESTORS;

const BonusReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<InvestorReportFilterForm>({
    name: null,
    has_invested: null,
    direct_marketing: null,
    columns: AVAILABLE_COLUMNS,
  });

  const mapData = (form: InvestorReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendFilter(request, 'has_invested', form.has_invested);
    appendFilter(request, 'direct_marketing', form.direct_marketing);

    return request;
  };

  const onSubmit = useCallback(
    async (request: InvestorReportFilterForm, helper: FormikHelpers<InvestorReportFilterForm>) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  const [investorStatusOptions] = useState<ReactSelectOption[]>([
    {
      value: '',
      label: t('common.all'),
    },
    {
      value: false,
      label: t('reports.investor_status.has_not_invested'),
    },
    {
      value: true,
      label: t('reports.investor_status.has_invested'),
    },
  ]);

  if (!p.hasAll([mapManagePermission(TYPE)])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput name={'has_invested'} options={investorStatusOptions} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectDirectMarketingInput name={'direct_marketing'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default BonusReportFilter;
