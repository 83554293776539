import { toast, ToastContent } from 'react-toastify';
import LinkButton from 'src/components/LinkButton';
import i18n from 'i18next';

export function success(content: ToastContent): void {
  toast(content, {
    type: 'success',
    toastId: 'success',
  });
}

export function error(content: ToastContent): void {
  toast(content, {
    type: 'error',
    toastId: 'error',
  });
}

export function errorCta(content: ToastContent, action_url: string): void {
  toast(
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <div className={'mb-2'}>{content}</div>
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <LinkButton href={action_url} target={'_blank'}>
        {i18n.t('common.open')}
      </LinkButton>
    </>,
    {
      autoClose: 10000,
      type: 'error',
      toastId: 'errorCta',
    },
  );
}
