import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  SaveStaticPageSeoRequestDto,
  StaticPageSeoResponseDto,
} from 'src/types/api/landingPage/seo';

class Seo extends Client {
  api = {
    FETCH_SEO_PAGES: `${this.http.baseUrl}/seo-pages/filter`,
    UPDATE_SEO_PAGE: `${this.http.baseUrl}/seo-pages/{seoPageId}`,
    GET_SEO_PAGE: `${this.http.baseUrl}/seo-pages/{seoPageId}`,
  };

  fetchFilteredSeoPages = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<StaticPageSeoResponseDto>> => {
    return this.http.get(this.api.FETCH_SEO_PAGES, { params: request });
  };

  updateSeoPage = (
    seoPageId: number | string,
    request: SaveStaticPageSeoRequestDto,
  ): Promise<StaticPageSeoResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_SEO_PAGE, { seoPageId });

    return this.http.put(url, request);
  };

  fetchSingleSeoPage = (seoPageId: number | string): Promise<StaticPageSeoResponseDto> => {
    const url = this.buildUrl(this.api.GET_SEO_PAGE, { seoPageId });

    return this.http.get(url);
  };
}

export default Seo;
