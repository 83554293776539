import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LegalEntity, PaginationData, PaginationDataFilter } from 'src/types';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import Table from 'src/components/Table';
import { Permissions } from 'src/helpers/auth/permissions';
import ViewEntityButton from 'src/components/Table/Buttons/ViewEntityButton';
import { BaseTabProps } from 'src/components/Tabs';
import { CompanyContextState, withCompany } from 'src/pages/User/Update/Company/CompanyContext';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { UserResponseDto } from 'src/types/api/user';
import { capitalize, replace } from 'lodash';
import VerifiedBadge from 'src/pages/User/Badges/VerifiedBadge';
import DirectMarketingBadge from 'src/pages/User/Badges/DirectMarketingBadge';
import InvestedBadge from 'src/pages/User/Badges/InvestedBadge';
import WalletExistsBadge from 'src/pages/User/Badges/WalletExistsBadge';

interface Props extends BaseTabProps, CompanyContextState {}

const TabRelatedAccounts: React.FC<Props> = ({ company }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<any>>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'pid',
        sortType: 'string',
        width: 100,
      },
      {
        Header: t('table.legal_entity'),
        accessor: 'legal_entity',
        sortType: 'string',
        width: 100,
        Cell: (cell: Cell<UserResponseDto>) => {
          return capitalize(cell.value);
        },
      },
      {
        Header: t('table.name'),
        accessor: 'name',
        sortType: 'string',
        width: 250,
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<UserResponseDto>) => {
          return capitalize(replace(cell.value, '_', ' '));
        },
      },
      {
        Header: t('table.email'),
        accessor: 'email',
        sortType: 'string',
        width: 250,
      },
      {
        Header: t('table.phone'),
        accessor: 'phone',
        sortType: 'string',
      },
      {
        Header: t('table.registration_date'),
        accessor: 'created_at',
        sortType: 'datetime',
        width: 160,
        Cell: (cell: Cell<UserResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.information'),
        width: 110,
        Cell: (cell: Cell<UserResponseDto>) => {
          const val = cell.row.original;

          return (
            <div className={'actions d-flex gap-2'}>
              <VerifiedBadge verified={val.verified} />
              <DirectMarketingBadge
                via_email={val?.direct_marketing?.via_email ?? false}
                via_phone={val?.direct_marketing?.via_phone ?? false}
              />
              <InvestedBadge val_invested={val.invested} />
              <WalletExistsBadge wallet_exists={val.wallet_verified} />
            </div>
          );
        },
      },
      {
        Header: t('table.action'),
        width: 100,

        Cell: (cell: Cell<UserResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <ViewEntityButton
                route={generatePath(RouteList.USERS.VIEW_USER, { userId: row.id })}
                permissions={Permissions.BO__USERS__VIEW}
                show={row.legal_entity === LegalEntity.Personal}
              />

              <ViewEntityButton
                route={generatePath(RouteList.USERS.VIEW_COMPANY, { companyId: row.id })}
                permissions={Permissions.BO__USERS__VIEW}
                show={row.legal_entity === LegalEntity.Company}
              />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!company) return Promise.resolve();

      return Api.userCompanies
        .fetchCompanyRelatedAccount(company.id, request)
        .then((response) => setResponse(response));
    },
    [company],
  );

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'UserUpdateCompanyTabsTabRelatedAccounts'}>
        <Table
          title={'UserUpdateCompanyTabsTabRelatedAccounts'}
          columns={columns}
          data={response}
          onFetchData={fetchData}
          searchable={true}
        />
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withCompany(TabRelatedAccounts);
