export enum LanguageEnum {
  LT = 'lt',
  EN = 'en',
  DE = 'de',
  EE = 'ee',
}

export type TranslatableObject = { [key in LanguageEnum]: string };

export const isTranslatable = (arg: any): arg is TranslatableObject => {
  return (
    !!arg &&
    typeof arg === 'object' &&
    LanguageEnum.LT in arg &&
    LanguageEnum.EN in arg &&
    LanguageEnum.DE in arg &&
    LanguageEnum.EE in arg
  );
};

export const isOfLanguage = (arg: any): arg is LanguageEnum => {
  return Object.values(LanguageEnum).includes(arg);
};
