import React, { useState } from 'react';
import SelectInput, { ReactSelectOption } from './SelectInput';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

interface Props extends StateManagerProps {
  name: string;
  placeholder: string;
}

const Ratings: Array<string> = ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D', 'E', 'F'];

const SelectRatingInput: React.FC<Props> = (props) => {
  const [options] = useState<ReactSelectOption[]>([
    ...Ratings.map((rating) => ({
      label: rating,
      value: rating,
    })),
  ]);

  return <SelectInput {...props} options={options} />;
};

export default SelectRatingInput;
