/**
 * Wrapper used for storing sensitive data in local storage.
 */
export class DataStorage {
  static get(key: string): string | null {
    return window.localStorage.getItem(key);
  }

  static set(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }

  static setData<T>(key: string, value: T): void {
    window.localStorage.setItem(key, this.serialize(value));
  }

  static getData<T>(key: string): T | null {
    const data = window.localStorage.getItem(key);

    if (data === null) {
      return null;
    }
    const json = window.atob(data);

    return JSON.parse(json) as T;
  }

  static serialize(value: any): string {
    return window.btoa(JSON.stringify(value));
  }

  static deserialize<T>(value: string): T {
    const json = window.atob(value);

    return JSON.parse(json) as T;
  }

  static remove(key: string): void {
    try {
      if (localStorage.getItem(key) === null) {
        return;
      }

      const oldVal = localStorage.getItem(key);
      localStorage.removeItem(key);
      window.dispatchEvent(
        new StorageEvent('storage', {
          key: key,
          oldValue: oldVal,
          newValue: localStorage.getItem(key),
        }),
      );
    } catch (e) {
      console.error(e);
    }
  }
}
