import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { TeamMemberResponseDto } from 'src/types/api/landingPage/teamMembers';
import { setGlobalLoading } from 'src/modules/app/actions';
import { Cell, Column } from 'react-table';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { generatePath, Link } from 'react-router-dom';
import { RouteList } from 'src/routes';
import Api from 'src/api';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';
import DeleteEntityModal from 'src/components/Modal/Modals/DeleteEntityModal';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';

const TeamMembers: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<TeamMemberResponseDto>>();
  const { showModal } = useGlobalModalContext();
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.LT);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const INITIAL_SORT_ORDER = [
    {
      id: 'order_number',
      desc: false,
    },
  ];

  const columns = useMemo<Column<TeamMemberResponseDto>[] | any>(
    () => [
      {
        Header: t('table.picture'),
        accessor: 'picture',
        sortType: 'string',
        width: 100,
        disableSortBy: true,
        Cell: (cell: Cell<TeamMemberResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {row.picture ? (
                <img src={row.picture} alt={'Picture of the team member'} width={100} />
              ) : (
                ''
              )}
            </div>
          );
        },
      },
      {
        Header: t('table.name'),
        accessor: 'name',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<TeamMemberResponseDto>) => {
          const row = cell.row.original;
          return <div className={'d-flex gap-2'}>{row.name + ' ' + row.surname}</div>;
        },
      },
      {
        Header: t('table.linkedin_url'),
        accessor: 'linkedin_url',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<TeamMemberResponseDto>) => {
          const row = cell.row.original;
          return (
            <a href={row.linkedin_url} target={'_blank'} rel="noreferrer">
              {row.linkedin_url}
            </a>
          );
        },
      },
      {
        Header: t('table.responsibilities'),
        accessor: 'responsibilities',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<TeamMemberResponseDto>) => {
          const row = cell.row.original;
          return <div className={'d-flex'}>{row.responsibilities?.[language]}</div>;
        },
      },
      {
        Header: t('table.order_number'),
        accessor: 'order_number',
        sortType: 'string',
        width: 80,
      },
      {
        Header: t('table.action'),
        width: 80,
        Cell: (cell: Cell<TeamMemberResponseDto> | any) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                route={generatePath(RouteList.LANDING_PAGE.TEAM_MEMBERS.UPDATE, {
                  teamMemberId: row.id,
                })}
                permissions={Permissions.BO__LANDING_PAGE__TEAM_MEMBERS__UPDATE}
              />
              <PermissionAction permissions={Permissions.BO__LANDING_PAGE__TEAM_MEMBERS__DELETE}>
                <Link
                  to={'#'}
                  title={t('common.delete')}
                  onClick={(e) => {
                    e.preventDefault();
                    showModal(
                      <DeleteEntityModal
                        title={t('pages.delete_team_member')}
                        body={t('common.delete_specific', { value: cell.row.values.name })}
                        onDelete={() => {
                          return Api.teamMembers
                            .deleteTeamMember(row.id)
                            .then(() => cell.dispatch({ type: 'refreshData' }));
                        }}
                      />,
                    );
                  }}
                  className={'link-danger'}
                >
                  <i className={'fas fa-trash-alt'} />
                </Link>
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [language, showModal, t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.teamMembers.fetchFilteredTeamMembers(request).then((response) => setData(response));
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-3'}>{t('menu.landing_page.team_members')}</h4>
            <LanguageSwitcher locale={language} setLocale={setLanguage} />
            <HiddenColumnsProvider title={'TeamMemberIndex'}>
              <Table
                title={'TeamMemberIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                createComponent={
                  <PermissionAction
                    permissions={Permissions.BO__LANDING_PAGE__TEAM_MEMBERS__CREATE}
                  >
                    <Link
                      to={RouteList.LANDING_PAGE.TEAM_MEMBERS.CREATE}
                      className={'btn btn-primary w-100'}
                    >
                      {t('common.create_new')}
                    </Link>
                  </PermissionAction>
                }
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default TeamMembers;
