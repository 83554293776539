import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { RouteList } from 'src/routes';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { useTranslation } from 'react-i18next';
import {
  SaveStaticPageSeoRequestDto,
  StaticPageSeoResponseDto,
} from 'src/types/api/landingPage/seo';
import { FormikHelpers } from 'formik';
import Api from 'src/api';
import { navigate } from 'src/helpers/navigate';
import { transformErrors } from 'src/helpers';
import success = toast.success;
import { toast } from 'react-toastify';
import SeoLandingPageForm from 'src/pages/LandingPage/SEO/SeoLandingPageForm';
import { setGlobalLoading } from 'src/modules/app/actions';

const SEOLandingPageUpdate: React.FC = () => {
  const { t } = useTranslation();

  const { landingPageId } = useParams<{ landingPageId: string }>();
  const [staticPageSeoResponse, setStaticPageSeoResponse] = useState<StaticPageSeoResponseDto>();

  const onSubmit = useCallback(
    async (
      request: SaveStaticPageSeoRequestDto,
      helpers: FormikHelpers<SaveStaticPageSeoRequestDto>,
    ) => {
      await Api.seo
        .updateSeoPage(landingPageId, request)
        .then((response) => {
          setStaticPageSeoResponse(response);
          success(t('common.updated_success'));
          navigate(RouteList.LANDING_PAGE.SEO.LIST);
        })
        .catch((e) => {
          helpers.setErrors(transformErrors(e.response?.errors));
        });

      return true;
    },
    [landingPageId, t],
  );

  useEffect(() => {
    Api.seo.fetchSingleSeoPage(landingPageId).then((response) => {
      setStaticPageSeoResponse(response);
      setGlobalLoading(false);
    });
  }, [landingPageId]);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={1} className={'mb-4'}>
            <Link to={RouteList.LANDING_PAGE.SEO.LIST}>
              <PrimaryButton title={t('common.back')} type={'button'} />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Card>
              <CardBody>
                <h4 className={'mb-3'}>{t('pages.update_static_seo_pages')}</h4>
                <SeoLandingPageForm onSubmit={onSubmit} staticSeoPages={staticPageSeoResponse} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default SEOLandingPageUpdate;
