import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';

import { BaseTabProps } from 'src/components/Tabs';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';

import {
  FinancialRiskCalculatorResponseDto,
  UserKnowYourCustomerResponseDto,
  UserResponseDto,
} from 'src/types/api/user';
import { Cell, Column } from 'react-table';
import { PaginationData, PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import Table from 'src/components/Table';

interface Props extends BaseTabProps {
  user?: UserResponseDto;
}

const INITIAL_SORT_ORDER = [
  {
    id: 'submitted_at',
    desc: true,
  },
];

const TabLegal: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  const [knowYourCustomerData, setKnowYourCustomerData] =
    useState<UserKnowYourCustomerResponseDto | null>(null);
  const [financialRiskSubmits, setFinancialRiskSubmits] =
    useState<PaginationData<FinancialRiskCalculatorResponseDto>>();

  useEffect(() => {
    if (props && props.user) {
      setKnowYourCustomerData(props.user.kyc);
    }
  }, [props]);

  const formattedWork = () => {
    return knowYourCustomerData?.work
      .map((item) => {
        if (item === 'other') {
          return t(`users.work.${item}`) + ' (' + knowYourCustomerData?.other_work_text + ')';
        } else if (item === 'worker') {
          return t(`users.work.${item}`) + ' (' + knowYourCustomerData?.worker_work_text + ')';
        }

        return t(`users.work.${item}`);
      })
      .join('; ');
  };

  const formattedSelfEmployedActivity = () => {
    return knowYourCustomerData?.self_employed_activity
      ?.map((item) => {
        if (item === 'other') {
          return knowYourCustomerData?.other_self_employed_activity_text;
        }
        return t(`users.self_employed_activity.${item}`);
      })
      ?.join('; ');
  };

  const formattedMainIncomeSource = () => {
    return knowYourCustomerData?.main_income_source
      .map((item) => {
        if (item === 'other') {
          return knowYourCustomerData?.other_main_income_source_text;
        }
        return t(`users.main_income.${item}`);
      })
      .join('; ');
  };

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'string',
      },
      {
        Header: t('table.annual_income'),
        accessor: 'annual_income',
        sortType: 'number',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.liquid_net_worth'),
        accessor: 'liquid_net_worth',
        sortType: 'number',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.annual_obligations'),
        accessor: 'annual_obligations',
        sortType: 'number',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.result'),
        accessor: 'result',
        sortType: 'number',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.submitted_at'),
        accessor: 'submitted_at',
        sortType: 'string',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!props.user?.id) return Promise.resolve();

      return Api.user
        .fetchFinancialRiskSubmits(props.user.id, request)
        .then((response) => setFinancialRiskSubmits(response));
    },
    [props.user?.id],
  );

  return (
    <>
      {knowYourCustomerData ? (
        <Container fluid>
          <Row>
            <SidebarDataRow label={t('label.work')} value={formattedWork()} />
            <SidebarDataRow
              label={t('users.self_employed_activity')}
              value={formattedSelfEmployedActivity()}
            />
            <SidebarDataRow
              label={t('label.planned_investment_amount')}
              value={t(`users.${knowYourCustomerData.planned_investment_amount}`)}
            />
            <SidebarDataRow
              label={t('label.average_income')}
              value={t('common.money', { value: knowYourCustomerData?.average_income })}
            />
            <SidebarDataRow
              label={t('label.main_income_source')}
              value={formattedMainIncomeSource()}
            />
            <SidebarDataRow
              label={t('label.public_office_confirm')}
              value={knowYourCustomerData.public_office_confirm}
            />
            <SidebarDataRow
              label={t('label.public_office_family_confirm')}
              value={knowYourCustomerData.public_office_family_confirm}
            />
            <SidebarDataRow
              label={t('label.bankruptcy_procedure')}
              value={knowYourCustomerData.bankruptcy_procedure ? t('common.yes') : t('common.no')}
            />
            <SidebarDataRow
              label={t('label.agreement')}
              value={knowYourCustomerData.agreement ? t('common.yes') : t('common.no')}
            />
          </Row>
        </Container>
      ) : (
        <p>{t('common.no_data')}</p>
      )}
      <hr />
      <h1>{t('common.calculated_financial_risks')}</h1>
      <Table
        title={'financial-risk-submits'}
        columns={columns}
        data={financialRiskSubmits}
        onFetchData={fetchData}
        initialSortBy={INITIAL_SORT_ORDER}
      />
    </>
  );
};

export default TabLegal;
