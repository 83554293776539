import React, { useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import FileUpload, {
  DOCUMENT_TEMPLATE_DEVELOPER_INVESTOR_AGREEMENT,
  FileUploadHandle,
} from 'src/components/Form/FileUpload/FileUpload';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { AttachFilesRequestDto } from 'src/types/api/common';
import { FormikProps } from 'formik/dist/types';
import DataBlock from 'src/components/DataBlocks/DataBlock';
import DownloadButton from 'src/components/DownloadButton';

type Props = ProjectContextState;

const ProjectDeveloperLoanAgreementSection: React.FC<Props> = ({ project, setProject }) => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);

  const [initialFormValues] = useState<AttachFilesRequestDto>({
    media_ids: [],
  });

  const onSubmit = useCallback(
    async (request: AttachFilesRequestDto, helpers: FormikHelpers<any>) => {
      if (!project) return;

      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        const response = await Api.projects.attachProjectDeveloperLoanAgreementDocument(
          project.id,
          request,
        );
        setProject(response);
        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [project, setProject],
  );

  if (!project) {
    return <></>;
  }

  return (
    <>
      <h3 className={'mt-5'}>{t('projects.documents.types.project_developer_loan_agreement')}</h3>
      <hr />
      {project.developer_loan_agreement_draft && (
        <>
          <div className={'mb-5'}>
            <h4 className={'mb-4'}>
              {t('projects.documents.types.project_developer_loan_agreement_draft')}
            </h4>
            {project.developer_loan_agreement_draft && (
              <Row>
                <DataBlock
                  label={t('table.file_name')}
                  value={project.developer_loan_agreement_draft.name}
                />
                <DataBlock
                  label={t('table.size')}
                  value={project.developer_loan_agreement_draft.size}
                />
                <DataBlock
                  label={t('table.created_at')}
                  value={t('common.date_full', {
                    date: project.developer_loan_agreement_draft.created_at,
                  })}
                />
                {project.developer_loan_agreement_draft.url && (
                  <Col>
                    <div className={'float-end'}>
                      <DownloadButton url={project.developer_loan_agreement_draft.url} />
                    </div>
                  </Col>
                )}
              </Row>
            )}
          </div>
          <hr />
        </>
      )}
      <div className={'mb-5'}>
        <div className={'mb-4'}>
          <h4 className={'mb-4'}>
            {t('projects.documents.types.project_developer_loan_agreement_final')}
          </h4>
          {project.developer_loan_agreement && (
            <Row>
              <DataBlock
                label={t('table.file_name')}
                value={project.developer_loan_agreement.name}
              />
              <DataBlock label={t('table.size')} value={project.developer_loan_agreement.size} />
              <DataBlock
                label={t('table.created_at')}
                value={t('common.date_full', {
                  date: project.developer_loan_agreement.created_at,
                })}
              />
              {project.developer_loan_agreement.url && (
                <Col>
                  <div className={'float-end'}>
                    <DownloadButton url={project.developer_loan_agreement.url} />
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={initialFormValues}
          onSubmit={onSubmit}
        >
          {({ submitForm, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FileUpload
                ref={fileUploadRef}
                name={'media_ids'}
                onPresign={(request) =>
                  Api.projects.uploadProjectDeveloperLoanAgreementDocument(project.id, request)
                }
                accept={DOCUMENT_TEMPLATE_DEVELOPER_INVESTOR_AGREEMENT}
                maxFiles={1}
                onDropAccepted={submitForm}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default withProject(ProjectDeveloperLoanAgreementSection);
