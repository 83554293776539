import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import CreatableSelect from 'react-select/creatable';
import { CreatableProps } from 'react-select/dist/declarations/src/Creatable';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';

export interface ReactSelectProps extends CreatableProps<any, any, any> {
  name: string;
  options: ReactSelectOption[] | undefined;
  hideLabel?: boolean;
  placeholder?: string;
}

const CreatableSelectInput: React.FC<ReactSelectProps> = ({ name, options, ...props }) => {
  const { t, i18n } = useTranslation();
  const [field, meta, helper] = useField(name);
  const [internalOptions, setInternalOptions] = useState<ReactSelectOption[] | undefined>();

  if (!props.placeholder) {
    props.placeholder = t('label.' + name);
  }

  useEffect(() => {
    let newOptions = options ? [...options] : undefined;
    if (
      options &&
      field.value &&
      !newOptions?.find((x) => x?.value?.toString() === field.value?.toString())
    ) {
      newOptions = [...options, { value: field.value, label: field.value }];
    }

    setInternalOptions(newOptions);
  }, [field.value, options]);

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const selectValue = () => {
    if (Array.isArray(field.value)) {
      return field.value.map((value) => {
        return (
          internalOptions?.find((option) => option.value?.toString() == value.toString()) ?? ''
        );
      });
    }

    return internalOptions
      ? internalOptions.find((option) => option.value?.toString() === field.value?.toString())
      : '';
  };

  return (
    <>
      <div className={`${meta?.error ? 'is-invalid' : ''}`}>
        {!props.hideLabel && <label htmlFor={field.name}>{props.placeholder}</label>}
        <CreatableSelect
          options={internalOptions}
          name={name}
          onChange={(option: any) => {
            if (Array.isArray(option)) {
              helper.setValue((option as ReactSelectOption[]).map((option) => option.value));
            } else {
              helper.setValue(option?.value);
            }
          }}
          onBlur={field.onBlur}
          value={selectValue()}
          isLoading={options === undefined}
          {...props}
        />
      </div>
      {meta.error && <div className={!!meta.error ? 'invalid-feedback' : ''}>{meta.error}</div>}
    </>
  );
};
export default CreatableSelectInput;
