import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'src/helpers';

type TColumnsContext = {
  state: string[];
  setState: React.Dispatch<React.SetStateAction<string[]>>;
};

type THiddenColumnsControl = {
  push: (col: string) => void;
  remove: (col: string) => void;
  hiddenColumns: string[];
  setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>;
};

export const columnsContextInitialState = () => ({
  state: [],
  setState: () => {
    return;
  },
});

const ColumnsContext = createContext<TColumnsContext>(columnsContextInitialState());

export const HiddenColumnsProvider: React.FC<{ title: string }> = ({
  title,
  children,
}): JSX.Element => {
  const [state, setState] = useState<string[]>([]);

  const [x, setX] = useLocalStorage<string[]>(title, []);

  useEffect(() => {
    setState(x);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setX(state);
    // eslint-disable-next-line
  }, [state]);

  return <ColumnsContext.Provider value={{ state, setState }}>{children}</ColumnsContext.Provider>;
};

export const useHiddenColumns = (): THiddenColumnsControl => {
  const { state, setState } = useContext(ColumnsContext);

  return useMemo<THiddenColumnsControl>(() => {
    return {
      push: (col: string) => {
        state.push(col);
        setState([...state]);
      },
      remove: (col: string) => {
        setState(state.filter((c) => col !== c));
      },
      hiddenColumns: state,
      setHiddenColumns: setState,
    };
  }, [state, setState]);
};

interface Props {
  columns: Column<any>[];
  updateHiddenColumns: (cols: string[]) => void;
  title: string;
}

const ShowTableColumnsDropdown: React.FC<Props> = ({ columns, title }) => {
  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();

  const cols = useMemo<any[]>(
    () => columns.filter((column) => column.Header != 'Action'),
    [columns],
  );

  const { hiddenColumns, setHiddenColumns } = useHiddenColumns();

  const handleSelectAll = () => {
    if (hiddenColumns.length !== cols.length) {
      setHiddenColumns(cols.map((c) => c.accessor as string));
    } else {
      setHiddenColumns([]);
    }

    localStorage.setItem(title, JSON.stringify(hiddenColumns));
  };

  const handleClick = (e: { target: { id: string } }) => {
    const { id } = e.target;

    let x: string[];

    if (hiddenColumns.find((a) => a === id)) {
      x = hiddenColumns.filter((item) => item !== id);
    } else {
      x = [...hiddenColumns, id];
    }

    setHiddenColumns(x);
    localStorage.setItem(title, JSON.stringify(x));
  };

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className={'dropdown d-inline-block pr-0'}
        tag="li"
      >
        <DropdownToggle className={'btn noti-icon waves-effect'} tag={'button'}>
          <i className={'fa fa-columns font-size-18 d-flex'} />
        </DropdownToggle>

        <DropdownMenu className={'dropdown-menu-end dropdown-border p-0 w-14'}>
          <SimpleBar className={'h-14'}>
            <div className={'d-flex flex-column justify-content-center'}>
              <div className={'d-flex flex-column'}>
                <label className={'p-1'}>
                  <input
                    className={'mx-3'}
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={hiddenColumns.length !== cols.length}
                  />
                  {t('common.select_all')}
                </label>
                {cols.map(
                  (
                    column: {
                      accessor: string;
                      Header:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined;
                    },
                    key: React.Key | null | undefined,
                  ) => {
                    return (
                      <label className={'p-1'} key={key}>
                        <input
                          id={column.accessor as string}
                          type={'checkbox'}
                          value={column.accessor as string}
                          checked={!hiddenColumns.includes(column.accessor as string)}
                          className={'mx-3'}
                          onChange={handleClick}
                        />
                        {column.Header}
                      </label>
                    );
                  },
                )}
              </div>
            </div>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ShowTableColumnsDropdown;
