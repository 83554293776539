import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { Card, CardBody, Container } from 'reactstrap';

import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import PermissionAction from 'src/components/PermissionAction';
import { setGlobalLoading } from 'src/modules/app/actions';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';
import { Permissions } from 'src/helpers/auth/permissions';
import { WalletOperationStatusEnum } from 'src/helpers/Enums/Wallet/WalletOperationStatusEnum';
import { ModelField } from 'src/components/Table/Fields/ModelField';
import WalletOperationStatusBadge from 'src/components/Badges/WalletOperationStatusBadge';
import { ManualWithdrawalListResponseDto } from 'src/types/api/payments/manualWithdrawals';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import LegalEntityView from 'src/components/Views/LegalEntityView';
import WalletProviderBadge from 'src/components/Badges/WalletProviderBadge';

const INITIAL_SORT_ORDER = [
  {
    id: 'requested_at',
    desc: true,
  },
];

const ManualWithdrawalList: React.FC = () => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [data, setData] = useState<PaginationData<ManualWithdrawalListResponseDto>>();
  const [refreshCount, setRefreshCount] = useState(0);

  const fetchData = useCallback((request: PaginationDataFilter | undefined) => {
    return Api.payments.manualWithdrawals
      .fetchFilteredManualWithdrawals(request)
      .then((response) => setData(response));
  }, []);

  const confirmInvestmentPayout = useCallback(
    (cell: Cell<ManualWithdrawalListResponseDto> | any) => {
      showModal(
        <ActionModal
          title={t('payments.manual_withdrawals.confirm.title')}
          body={t('payments.manual_withdrawals.confirm.body')}
          successMessage={t('payments.manual_withdrawals.confirm.success')}
          actionText={t('payments.manual_withdrawals.confirm')}
          onAction={async () => {
            return Api.payments.manualWithdrawals
              .confirmManualWithdrawal(cell.row.original.id)
              .then(() => cell.dispatch({ type: 'refreshData' }));
          }}
        />,
      );
    },
    [showModal, t],
  );

  const confirmAllPendingInvestmentPayout = useCallback(() => {
    showModal(
      <ActionModal
        title={t('payments.manual_withdrawals.confirm.title_all')}
        body={t('payments.manual_withdrawals.confirm.body_all')}
        successMessage={t('payments.manual_withdrawals.confirm.success')}
        actionText={t('payments.manual_withdrawals.confirm_all')}
        onAction={async () => {
          return Api.payments.manualWithdrawals
            .confirmAllManualWithdrawals()
            .then(() => setRefreshCount((prev) => prev + 1));
        }}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, t, fetchData]);

  const columns = useMemo<Column<ManualWithdrawalListResponseDto>[] | any>(
    () => [
      {
        Header: t('label.requested_at'),
        accessor: 'requested_at',
        Cell: (cell: Cell<ManualWithdrawalListResponseDto>) => {
          const value = cell.row.original.requested_at;

          return <div>{t('common.date_full', { date: value })}</div>;
        },
      },
      {
        Header: t('label.executed_at'),
        accessor: 'executed_at',
        Cell: (cell: Cell<ManualWithdrawalListResponseDto>) => {
          const value = cell.row.original.executed_at;

          return <div>{t('common.date_full', { date: value })}</div>;
        },
      },
      {
        Header: t('label.sender'),
        accessor: 'sender',
        disableSortBy: true,
        Cell: (cell: Cell<ManualWithdrawalListResponseDto>) => {
          const value = cell.row.original.sender;

          return <ModelField model={value} />;
        },
      },
      {
        Header: t('label.investor_type'),
        accessor: 'investor_type',
        disableSortBy: true,
        width: 100,
        Cell: (cell: Cell<ManualWithdrawalListResponseDto>) => {
          return <LegalEntityView entity={cell.value} />;
        },
      },
      {
        Header: t('label.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<ManualWithdrawalListResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('label.iban'),
        accessor: 'iban',
        disableSortBy: true,
        width: 150,
      },
      {
        Header: t('label.swift'),
        accessor: 'swift',
        disableSortBy: true,
        width: 100,
      },
      {
        Header: t('label.status'),
        accessor: 'status',
        width: 100,
        Cell: (cell: Cell<ManualWithdrawalListResponseDto>) => {
          const value = cell.row.original.status;

          return <WalletOperationStatusBadge value={value} />;
        },
      },
      {
        Header: t('label.wallet_provider'),
        accessor: 'wallet_provider',
        width: 100,
        Cell: (cell: Cell<ManualWithdrawalListResponseDto>) => {
          const value = cell.row.original.wallet_provider;

          return <WalletProviderBadge value={value} />;
        },
      },
      {
        Header: t('table.action'),
        width: 100,
        Cell: (cell: Cell<ManualWithdrawalListResponseDto>) => {
          if (
            cell.row.original.status != WalletOperationStatusEnum.RESERVED ||
            cell.row.original.confirmed_at
          ) {
            return null;
          }

          return (
            <div className={'actions d-flex gap-2'}>
              <PermissionAction permissions={Permissions.BO__PAYMENTS__MANUAL_WITHDRAWALS__CONFIRM}>
                <PrimaryButton
                  title={t('payments.manual_withdrawals.confirm.title')}
                  onClick={() => confirmInvestmentPayout(cell)}
                  btype={'btn-primary btn-sm'}
                />
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [confirmInvestmentPayout, t],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'ManualWithdrawalsListIndex'}>
        <Container fluid>
          <Card>
            <CardBody>
              <h4 className={'mb-4'}>{t('menu.payments.manual_withdrawals')}</h4>
              <Table
                title={'ManualWithdrawalsListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                refreshOnValueChange={refreshCount}
                createComponent={
                  <PermissionAction
                    permissions={Permissions.BO__PAYMENTS__MANUAL_WITHDRAWALS__CONFIRM}
                  >
                    <button
                      onClick={confirmAllPendingInvestmentPayout}
                      className={'btn btn-primary w-100'}
                    >
                      {t('common.confirm_all')}
                    </button>
                  </PermissionAction>
                }
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default ManualWithdrawalList;
