import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';

import NotificationsSidebarItem from 'src/components/Notifications/NotificationSidebarItem';
import { PaginationMeta } from 'src/types';
import { NotificationShortResponseDto } from 'src/types/api/notifications';

const NotificationsSidebar: React.FC<{
  notifications: Array<NotificationShortResponseDto>;
  onNextFetch: () => void;
  latestMeta: PaginationMeta | undefined;
  isLoading: boolean;
}> = ({ notifications, onNextFetch, latestMeta, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        'd-flex flex-column align-items-stretch flex-shrink-0 bg-white notifications-sidebar'
      }
    >
      <SimpleBar
        className={'list-group rounded-0 overflow-auto pt-0 pb-0'}
        forceVisible={'y'}
        scrollableNodeProps={{ id: 'notifications-sidebar-group' }}
      >
        <InfiniteScroll
          next={onNextFetch}
          hasMore={latestMeta?.current_page !== latestMeta?.last_page}
          loader={
            <Skeleton
              className={'notifications-sidebar-item-skeleton'}
              enableAnimation={true}
              height={150}
            />
          }
          dataLength={notifications.length}
          style={{ height: 'inherit' }}
          scrollableTarget={'notifications-sidebar-group'}
        >
          {!isLoading && notifications.length === 0 && (
            <div className={'p-4'}>{t('menu.no_notifications')}</div>
          )}
          {notifications?.map((notification: any) => (
            <NotificationsSidebarItem key={notification.id} {...notification} />
          ))}
        </InfiniteScroll>
      </SimpleBar>
    </div>
  );
};

export default NotificationsSidebar;
