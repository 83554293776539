import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader, Table } from 'reactstrap';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { useGroupedInvestmentsQuery } from 'src/api/queries/projects/investments';
import { ModelField } from 'src/components/Table/Fields/ModelField';

interface Props {
  projectId: string;
  projectInvestmentId: string;
}

const GroupedInvestmentsModal: React.FC<Props> = ({ projectId, projectInvestmentId }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const { isLoading, data } = useGroupedInvestmentsQuery(projectId, projectInvestmentId);

  return (
    <React.Fragment>
      <ModalComponent size={'lg'} skeletonHeight={350} isLoading={isLoading}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('projects.project.grouped_investments')}
          </ModalHeader>
          {!isLoading && data && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{t('projects.project.owner')}</th>
                  <th>{t('projects.project.amount')}</th>
                  <th>{t('projects.project.bonuses')}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((investment) => (
                  <tr key={investment.id}>
                    <td>
                      <ModelField model={investment.owner} showPid={true} />
                    </td>
                    <td>
                      {t('common.money', {
                        value: investment.amount,
                      })}
                    </td>
                    <td>{investment.bonuses.length > 0 ? investment.bonuses.join(', ') : '-'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default GroupedInvestmentsModal;
