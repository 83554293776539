import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Col, Form, FormGroup, ModalBody, ModalFooter, Row } from 'reactstrap';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import * as Yup from 'yup';
import { EditReservedInvestmentRequestDto } from 'src/types/api/user';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ProjectInvestmentListResponseDto, ProjectResponseDto } from 'src/types/api/projects';
import Api from 'src/api';
import { SelectAsyncInput, SelectInput } from 'src/components/Form/Select';
import { PaginationDataFilter } from 'src/types';
import { AvailableBonusSelect, BonusSystemBriefResponseDto } from 'src/types/api/bonusSystem';
import { shouldBonusOptionBeDisabled } from 'src/pages/Project/Update/Tabs/Investments/utils/optionDisabledService';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import SelectBonusTypesInput from 'src/components/Form/Select/SelectBonusTypeInput';
import TextInput from 'src/components/Form/TextInput';
import { BonusConditionEnum } from 'src/helpers/Enums/BonusSystem/BonusConditionEnum';
import DataBlock from 'src/components/DataBlocks/DataBlock';
import DownloadButton from 'src/components/DownloadButton';
import FileUpload, {
  DOCUMENT_TYPES,
  FileUploadHandle,
} from 'src/components/Form/FileUpload/FileUpload';
import { InvestorProjectLoanAgreementDocumentTypeEnum } from 'src/helpers/Enums/Project/InvestorProjectLoanAgreementDocumentTypeEnum';
import { getEnumSelectValues } from 'src/helpers/Enums/enumHelper';

interface Props {
  project: ProjectResponseDto | undefined;
  investment: ProjectInvestmentListResponseDto;
  handleInvestmentUpdate: (
    investment: ProjectInvestmentListResponseDto,
    dto: EditReservedInvestmentRequestDto,
  ) => void;
}

const EditReservedInvestmentModalForm = ({
  project,
  investment,
  handleInvestmentUpdate,
}: Props) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);

  const [availableBonuses, setAvailableBonuses] = useState<BonusSystemBriefResponseDto[]>([]);
  const [creatingNewBonus, setCreatingNewBonus] = useState<boolean>(false);
  const [isCreatingNewBonusAllowed, setIsCreatingNewBonusAllowed] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<EditReservedInvestmentRequestDto>({
    owner: null,
    user_id: null,
    user_type: null,
    bonus: [],
    media_ids: [],
    tac_document_type: undefined,
  });

  const EditReservedInvestmentRequestSchema = (creatingNewBonus: boolean) => {
    let schema = Yup.object().shape({
      owner: Yup.string().required(),
      user_id: Yup.string().nullable(),
      user_type: Yup.string().nullable(),
      amount: Yup.number(),
    });
    if (creatingNewBonus) {
      schema = schema.shape({
        new_bonus: Yup.object().shape({
          type: Yup.string().required(),
          percentage: Yup.number().max(5).required(),
        }),
      });
    }

    return schema;
  };

  const onSubmit = useCallback(
    async (request: EditReservedInvestmentRequestDto) => {
      if (project) {
        if (request.owner) {
          const user = JSON.parse(request.owner);
          request.user_id = user.user_id;
          request.user_type = user.user_type;
          request.media_ids =
            request.tac_document_type === InvestorProjectLoanAgreementDocumentTypeEnum.DEFAULT
              ? []
              : request.media_ids;
        } else {
          request.user_id = null;
          request.user_type = null;
        }

        await handleInvestmentUpdate(investment, request);
        hideModal();
      }

      return true;
    },
    [project, investment, handleInvestmentUpdate, hideModal],
  );

  const fetchUsers = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith && loadWith.hasOwnProperty(0) ? [JSON.parse(loadWith[0]).user_id] : [],
    };

    if (investment.owner && loadWith?.length === 0) {
      return [];
    }

    const response = await Api.user.fetchFilteredInvestorsUsers(request);

    return response.data.map((item) => ({
      value: JSON.stringify({
        user_id: item.id,
        user_type: item.class,
      }),
      label: item.private_id + ' - ' + item.name,
    }));
  };

  const onUserChange = useCallback(
    async (value: any) => {
      if (project && value) {
        const user = JSON.parse(value.value);

        const res = await Api.bonusSystem.getBonusesByUserAndProject({
          user_id: user.user_id,
          user_type: user.user_type,
          project_id: project.id,
        });

        let combined = res;

        if (investment.owner?.type === user.user_type && investment.owner?.id === user.user_id) {
          combined = investment.bonuses
            .concat(res)
            .filter((value, index, self) => index === self.findIndex((t) => t.code === value.code));
        }

        setAvailableBonuses(combined);
        setInitialValues({
          owner: value.value,
          user_id: user.user_id,
          user_type: user.user_type,
          bonus: [],
          tac_document_type: investment.investment_conditions
            ? InvestorProjectLoanAgreementDocumentTypeEnum.CUSTOM
            : InvestorProjectLoanAgreementDocumentTypeEnum.DEFAULT,
          media_ids: investment.investment_conditions
            ? [{ id: investment.investment_conditions.id }]
            : [],
        });
        setIsCreatingNewBonusAllowed(true);
      } else {
        setIsCreatingNewBonusAllowed(false);
        setCreatingNewBonus(false);
      }
    },
    [investment.bonuses, investment.owner, investment.investment_conditions, project],
  );

  const getAvailableBonuses = async (): Promise<AvailableBonusSelect[]> => {
    return availableBonuses.map((item) => ({
      value: item.code,
      summable: item.summable,
      label: `${item.code} (+${item.percentage}%) (${
        item.summable ? t('label.summable') : t('label.not_summable')
      })`,
    }));
  };

  useEffect(() => {
    const owner = investment.owner
      ? JSON.stringify({
          user_id: investment.owner.id,
          user_type: investment.owner.type,
        })
      : null;

    if (owner) {
      onUserChange({ value: owner }).then(() => {
        setInitialValues({
          owner: owner,
          user_id: investment.owner ? investment.owner.id : null,
          user_type: investment.owner ? investment.owner.type : null,
          bonus: investment.bonuses.map((item) => item.code),
          tac_document_type: investment.investment_conditions
            ? InvestorProjectLoanAgreementDocumentTypeEnum.CUSTOM
            : InvestorProjectLoanAgreementDocumentTypeEnum.DEFAULT,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment]);

  const createNewBonus = () => {
    return (
      <>
        <div className="mb-3">
          <SelectBonusTypesInput name={'new_bonus.type'} placeholder={t('label.type')} />
        </div>
        <div className={`mt-4`}>
          <div className="mb-3">
            <TextInput
              type={'number'}
              step={'any'}
              name={'new_bonus.' + BonusConditionEnum.PERCENTAGE}
              placeholder={t('label.' + BonusConditionEnum.PERCENTAGE)}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={EditReservedInvestmentRequestSchema(creatingNewBonus)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          <ModalBody className={''}>
            <Form onSubmit={handleSubmit} id={'edit-reserved-investment-form'}>
              <FormGroup>
                <div className="mb-3">
                  <SelectAsyncInput
                    name={'owner'}
                    isClearable={true}
                    loadOptions={fetchUsers}
                    onChange={onUserChange}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <SelectAsyncInput
                    name={'bonus'}
                    isClearable={true}
                    isSearchable={false}
                    isMulti={true}
                    loadOptions={getAvailableBonuses}
                    isOptionDisabled={(option: any, selectValue: any) =>
                      shouldBonusOptionBeDisabled(option, selectValue)
                    }
                  />
                </div>
              </FormGroup>
              <FormGroup>
                {!creatingNewBonus ? (
                  <PrimaryButton
                    disabled={!isCreatingNewBonusAllowed}
                    className={'btn btn-primary mb-3'}
                    onClick={() => setCreatingNewBonus(true)}
                    title={t('common.create')}
                    type={'button'}
                  />
                ) : (
                  <PrimaryButton
                    className={'btn btn-primary mb-3'}
                    onClick={() => setCreatingNewBonus(false)}
                    title={t('common.cancel')}
                    type={'button'}
                  />
                )}
                {creatingNewBonus && createNewBonus()}
              </FormGroup>
            </Form>

            <div className={'mb-3'}>
              <h4 className={'mb-2'}>{t('projects.documents.types.custom_investment_tac')}</h4>
              <div className={'mb-4'}>
                <SelectInput
                  name={'tac_document_type'}
                  placeholder={t('label.type')}
                  isMulti={false}
                  options={getEnumSelectValues(
                    InvestorProjectLoanAgreementDocumentTypeEnum,
                    'projects.documents.types.investor_loan_agreement_type',
                  )}
                />
              </div>
              {values.tac_document_type === InvestorProjectLoanAgreementDocumentTypeEnum.CUSTOM &&
                project && (
                  <>
                    <div className={'mb-4'}>
                      {investment.investment_conditions && (
                        <Row>
                          <DataBlock
                            label={t('table.file_name')}
                            value={investment.investment_conditions.name}
                          />
                          <DataBlock
                            label={t('table.size')}
                            value={investment.investment_conditions.size}
                          />
                          <DataBlock
                            label={t('table.created_at')}
                            value={t('common.date_full', {
                              date: investment.investment_conditions.created_at,
                            })}
                          />
                          {investment.investment_conditions.url && (
                            <Col>
                              <div className={'float-end'}>
                                <DownloadButton url={investment.investment_conditions.url} />
                              </div>
                            </Col>
                          )}
                        </Row>
                      )}
                    </div>
                    <FileUpload
                      ref={fileUploadRef}
                      name={'media_ids'}
                      onPresign={(request) =>
                        Api.projects.uploadReservedInvestmentTacDocument(project.id, request)
                      }
                      accept={DOCUMENT_TYPES}
                      maxFiles={1}
                    />
                  </>
                )}
            </div>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-center'}>
            <Col>
              <DenyButton title={t('common.cancel')} onClick={hideModal} />
            </Col>
            <Col>
              <SaveButton
                form={'edit-reserved-investment-form'}
                submitting={isSubmitting}
                title={t('common.save')}
                type={'submit'}
              />
            </Col>
          </ModalFooter>
        </>
      )}
    </Formik>
  );
};

export default EditReservedInvestmentModalForm;
