import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { BonusInterestReportFilterForm, GenerateReportRequestDto } from 'src/types/api/reports';
import { appendDateFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import { endOfMonth, formatRFC3339 } from 'date-fns';

const AVAILABLE_COLUMNS: string[] = ['project_pid', 'project_name', 'amount', 'paid_at'];

const TYPE: ReportTypeEnum = ReportTypeEnum.BONUSES_INTEREST;

const BonusInterestReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<BonusInterestReportFilterForm>({
    name: null,
    columns: AVAILABLE_COLUMNS,
    paid_at_from: null,
    paid_at_to: null,
  });

  const Schema = Yup.object().shape({
    invested_at_from: Yup.date().nullable(),
    invested_at_to: Yup.date().when(
      'paid_at_from',
      (paid_at_from: Date, schema: Yup.DateSchema) => {
        return paid_at_from ? schema.min(paid_at_from, t('label.paid_at_from')) : schema.nullable();
      },
    ),
  });

  const mapData = (form: BonusInterestReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendDateFilter(request, 'paid_at', form.paid_at_from, form.paid_at_to);

    return request;
  };

  const onSubmit = useCallback(
    async (
      request: BonusInterestReportFilterForm,
      helper: FormikHelpers<BonusInterestReportFilterForm>,
    ) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  if (!p.hasAll([mapManagePermission(TYPE)])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={Schema}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput
                          name={'interval_at'}
                          showMonthYearPicker={true}
                          autoComplete={'off'}
                          dateFormat={'yyyy-MM'}
                          onChangeAction={(option: any) => {
                            const m1Start = option ? formatRFC3339(option) : undefined;
                            const m2End = option ? formatRFC3339(endOfMonth(option)) : undefined;
                            setFieldValue('paid_at_from', m1Start);
                            setFieldValue('paid_at_to', m2End);
                          }}
                        />
                      </Col>
                      <Col sm={3} className={'mb-4'}>
                        <DatePickerInput
                          name={'paid_at_from'}
                          showTimeSelect
                          matchFieldValueAndDisplayValue={true}
                        />
                      </Col>
                      <Col sm={3} className={'mb-4'}>
                        <DatePickerInput
                          name={'paid_at_to'}
                          showTimeSelect
                          matchFieldValueAndDisplayValue={true}
                        />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default BonusInterestReportFilter;
