import React from 'react';
import { error, success } from '../services/toastr';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string | number | undefined;
}

const CopyOnClick: React.FC<Props> = ({ value, children }) => {
  const { t } = useTranslation();

  const onCopy = (value: string | number | undefined) => {
    if (!value) return;

    try {
      navigator.clipboard.writeText(value.toString());
      success(t('common.text_copied'));
    } catch (e: any) {
      error(t('common.text_copy_failed'));
    }
  };

  return (
    <span className={'cursor'} onClick={() => onCopy(value)}>
      {children ?? value}
    </span>
  );
};

export default CopyOnClick;
