import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import { Badge } from 'reactstrap';
import { ApplicationStatus } from 'src/types/app/applications';

type Props = ApplicationContextState;

const ApplicationStatusBadge: React.FC<Props> = ({ application }) => {
  const { t } = useTranslation();

  if (!application) return <></>;

  const getBadgeColor = () => {
    return application.status === ApplicationStatus.Rejected ? 'danger' : 'success';
  };

  return <Badge color={getBadgeColor()}>{t('applications.status.' + application.status)}</Badge>;
};

export default withApplication(ApplicationStatusBadge);
