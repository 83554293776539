import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import { success } from 'src/services/toastr';
import usePermissions from 'src/helpers/usePermissions';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  permissions?: string | string[];
  onDelete: () => Promise<any>;
  show?: boolean;
}

const DeleteEntityButton: React.FC<Props> = ({
  permissions,
  onDelete,
  show,
  onClick,
  ...props
}) => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { showModal } = useGlobalModalContext();

  const deleteModal = useCallback(() => {
    showModal(
      <ActionModal
        title={t('common.delete')}
        onAction={() => onDelete().then(() => success(t('common.deleted_success')))}
        body={t('common.delete_generic')}
        actionText={t('common.delete')}
      />,
    );
  }, [onDelete, showModal, t]);

  if ((show !== undefined && !show) || (permissions && p.cannot(permissions))) {
    return null;
  }

  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <i className={'fas fa-trash-alt text-danger'} onClick={onClick ?? deleteModal} {...props} />
    </a>
  );
};

export default DeleteEntityButton;
