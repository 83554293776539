import React, { useCallback, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import FileUpload, {
  FileUploadHandle,
  IMAGE_TYPES,
} from 'src/components/Form/FileUpload/FileUpload';
import Api from 'src/api';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik/dist/types';
import { AttachFilesRequestDto } from 'src/types/api/common';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import PictureList from 'src/components/PictureList/PictureList';

const TabPictures: React.FC<ProjectContextState> = ({ project, setProject }) => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);

  const [initialFormValues] = useState<AttachFilesRequestDto>({
    media_ids: [],
  });

  const onSubmit = useCallback(
    async (request: AttachFilesRequestDto, helpers: FormikHelpers<any>) => {
      if (!project) return;

      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        const response = await Api.projects.attachPictures(project.id, request);
        setProject(response);
        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [project, setProject],
  );

  if (!project) {
    return <></>;
  }

  return (
    <React.Fragment>
      <h3>{t('projects.pictures')}</h3>
      <hr />

      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ submitForm, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FileUpload
              accept={IMAGE_TYPES}
              ref={fileUploadRef}
              name={'media_ids'}
              onPresign={(request) => Api.projects.uploadPictures(project.id, request)}
              onDropAccepted={submitForm}
            />
          </Form>
        )}
      </Formik>
      <div className="row">
        <PictureList
          pictures={project.pictures}
          onOrderUpdate={(request) =>
            Api.projects.updateOrder(project.id, request).then(setProject)
          }
          onPictureDelete={(mediaId) =>
            Api.projects.detachPictures(project.id, mediaId).then(setProject)
          }
          onPictureRename={(name, mediaId) =>
            Api.projects.renameMedia(project.id, mediaId, { name }).then(setProject)
          }
        />
      </div>
    </React.Fragment>
  );
};

export default withProject(TabPictures);
