import React, { forwardRef } from 'react';
import { FileUploadContextProvider } from './module/FileUploadContext';
import FileUploadComponent from './FileUploadComponent';
import { DropzoneOptions } from 'react-dropzone';
import { SignUploadFilesResponseDto, SignUploadRequestDto } from 'src/types/api/common';

export const IMAGE_TYPES: Array<string> = [
  'image/png',
  'image/x-png',
  'image/jpeg',
  'image/x-citrix-jpeg',
];

export const DOCUMENT_TEMPLATE_TYPES: Array<string> = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
];

export const DOCUMENT_TEMPLATE_DEVELOPER_INVESTOR_AGREEMENT: Array<string> = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/pdf',
];

export const DATA_IMPORT_TYPES: Array<string> = [
  'text/csv', // .csv
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  '.xlsx',
  'application/vnd.ms-excel', // .xls
  '.xls',
];

export const DOCUMENT_TYPES: Array<string> = [
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/pdf',
  'image/png',
  'image/x-png',
  'image/jpeg',
];

export const CSV_TYPES: Array<string> = ['text/csv', '.csv'];

export interface FileUploadProps extends DropzoneOptions {
  name: string;
  onPresign: (request: SignUploadRequestDto) => Promise<SignUploadFilesResponseDto>;
}

export type FileUploadHandle = {
  upload: () => Promise<boolean>;
  reset: () => void;
};

export const getClassName = (
  isFocused: boolean,
  isDragAccept: boolean,
  isDragReject: boolean,
  isDisabled: boolean | undefined,
): string => {
  const baseStyle = 'file-upload-zone';
  const disabledStyle = `${baseStyle} disabled`;
  const focusedStyle = `${baseStyle} focused`;
  const acceptStyle = `${baseStyle} accept`;
  const rejectStyle = `${baseStyle} reject`;

  if (isDisabled) return disabledStyle;
  if (isFocused) return focusedStyle;
  if (isDragAccept) return acceptStyle;
  if (isDragReject) return rejectStyle;
  return baseStyle;
};

export const SHOWN_ERRORS = ['file-invalid-type'];

const FileUpload: React.ForwardRefRenderFunction<FileUploadHandle, FileUploadProps> = (
  props,
  fileUploadRef,
) => {
  return (
    <FileUploadContextProvider>
      <FileUploadComponent ref={fileUploadRef} {...props} />
    </FileUploadContextProvider>
  );
};

export default forwardRef(FileUpload);
