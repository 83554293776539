import Client from '../client';
import Http from '../../http';
import { ExternalMediaResponseDto } from 'src/types/api/common';

class Media extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      COPY_PUBLIC_URL: `${this.http.baseUrl}/media/{mediaId}/public-url`,
    };
  }

  fetchExternal = (url: string): Promise<ExternalMediaResponseDto> => {
    return this.http.get(url);
  };

  copyPublicUrl = (mediaId: string): Promise<ExternalMediaResponseDto> => {
    const url = this.buildUrl(this.api.COPY_PUBLIC_URL, { mediaId });

    return this.http.post(url, {});
  };
}

export default Media;
