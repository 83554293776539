import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import LocaleList from 'src/pages/Locale/List';
import { Permissions } from 'src/helpers/auth/permissions';

const LocaleRoutes: any = () => [
  <AuthRoute
    path={RouteList.LOCALE.LIST}
    component={LocaleList}
    layout={PageLayout}
    permissions={[Permissions.BO__LOCALE__TRANSLATIONS__FILTER]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LOCALE.LIST}
    exact
  />,
];

export default LocaleRoutes;
