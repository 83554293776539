import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownProps } from 'reactstrap';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ApplicationContext, {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import Api from 'src/api';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import SubmitCommitteeMemberVoteModal from 'src/pages/Application/Update/SubmitCommitteeMemberVoteModal';
import { ApplicationStatus } from 'src/types/app/applications';
import SaveButton from 'src/components/Form/SaveButton';
import ConvertApplicationToProject from 'src/pages/Application/Update/ConvertApplicationToProjectModal';

interface Props extends DropdownProps, ApplicationContextState {
  openProtocolTab: any;
}

const ApplicationStatusActions: React.FC<Props> = ({
  application,
  setApplication,
  openProtocolTab,
}) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const submitSalesManager = useCallback(() => {
    if (!application) {
      return;
    }

    showModal(
      <ActionModal
        title={t('applications.protocol.sales_manager.submit.title')}
        body={t('applications.protocol.sales_manager.submit.body')}
        successMessage={t('common.submit_success')}
        actionText={t('common.submit')}
        onAction={async () => {
          return Api.applications.submitProtocolSalesManager(application.id).then((response) => {
            setApplication(response);
            openProtocolTab();
          });
        }}
      />,
    );
  }, [openProtocolTab, application, setApplication, showModal, t]);

  const submitAnalytic = useCallback(() => {
    if (!application) {
      return;
    }

    showModal(
      <ActionModal
        title={t('applications.protocol.analytic.submit.title')}
        body={t('applications.protocol.analytic.submit.body')}
        successMessage={t('common.submit_success')}
        actionText={t('common.submit')}
        onAction={async () => {
          return Api.applications
            .submitProtocolAnalytic(application.id)
            .then((response) => setApplication(response));
        }}
      />,
    );
  }, [application, setApplication, showModal, t]);

  const submitCommitteeMemberVote = useCallback(() => {
    if (!application) {
      return;
    }

    showModal(
      <ApplicationContext application={application} setApplication={setApplication}>
        <SubmitCommitteeMemberVoteModal />
      </ApplicationContext>,
    );
  }, [application, setApplication, showModal]);

  const submitChiefCommitteeMemberVote = useCallback(() => {
    if (!application) {
      return;
    }

    showModal(
      <ApplicationContext application={application} setApplication={setApplication}>
        <SubmitCommitteeMemberVoteModal isCommitteeChief={true} />
      </ApplicationContext>,
    );
  }, [application, setApplication, showModal]);

  const convertApplicationToProject = useCallback(() => {
    if (!application) {
      return;
    }

    showModal(<ConvertApplicationToProject application={application} />);
  }, [application, showModal]);

  return (
    <React.Fragment>
      {application?.protocol &&
        !application?.protocol?.submitted_sales_manager_at &&
        application.status === ApplicationStatus.Submitted && (
          <PermissionAction
            permissions={Permissions.BO__APPLICATIONS__UPDATE_SALES_MANAGER_PROTOCOL}
          >
            <SaveButton
              onClick={submitSalesManager}
              title={t('applications.protocol.submit_sales_manager')}
            />
          </PermissionAction>
        )}
      {application?.protocol &&
        application.status === ApplicationStatus.Analyzing &&
        !application?.protocol?.submitted_analytic_at &&
        application?.protocol?.analytic_protocol_saved && (
          <PermissionAction permissions={Permissions.BO__APPLICATIONS__UPDATE_ANALYTIC_PROTOCOL}>
            <SaveButton
              onClick={submitAnalytic}
              title={t('applications.protocol.submit_analytic')}
            />
          </PermissionAction>
        )}
      {application?.status == ApplicationStatus.CommitteeReview && (
        <PermissionAction
          permissions={Permissions.BO__APPLICATIONS__UPDATE_COMMITTEE_MEMBER_PROTOCOL}
        >
          <SaveButton
            onClick={submitCommitteeMemberVote}
            title={t('applications.protocol.submit_committee_member_vote')}
          />
        </PermissionAction>
      )}
      {application?.status == ApplicationStatus.ChiefCommitteeMemberReview && (
        <PermissionAction
          permissions={Permissions.BO__APPLICATIONS__UPDATE_COMMITTEE_MEMBER_PROTOCOL}
        >
          <SaveButton
            onClick={submitChiefCommitteeMemberVote}
            title={t('applications.protocol.submit_chief_committee_member_vote')}
          />
        </PermissionAction>
      )}
      {application?.status == ApplicationStatus.Confirmed && (
        <PermissionAction permissions={Permissions.BO__APPLICATIONS__CONVERT_TO_PROJECT}>
          <SaveButton
            onClick={convertApplicationToProject}
            title={t('applications.convert_to_project')}
          />
        </PermissionAction>
      )}
    </React.Fragment>
  );
};

export default withApplication(ApplicationStatusActions);
