import React, { useCallback, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import { useTranslation } from 'react-i18next';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import TeamMemberForm from '../TeamMemberForm';
import { SaveTeamMemberRequestDto } from 'src/types/api/landingPage/teamMembers';
import { FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';
import { navigate } from 'src/helpers/navigate';
import { RouteList } from 'src/routes';
import { Link } from 'react-router-dom';
import PrimaryButton from 'src/components/Form/PrimaryButton';

const TeamMemberCreate: React.FC = () => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);

  const onAction = useCallback(
    async (values: SaveTeamMemberRequestDto, helpers) => {
      try {
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        return Api.teamMembers.createTeamMember(values).then(() => {
          success(t('common.created_success'));
          navigate(RouteList.LANDING_PAGE.TEAM_MEMBERS.LIST);
        });
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [t],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={1} className={'mb-4'}>
            <Link to={RouteList.LANDING_PAGE.TEAM_MEMBERS.LIST}>
              <PrimaryButton title={t('common.back')} type={'button'} />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Card>
              <CardBody>
                <h4 className={'mb-3'}>{t('pages.create_team_member')}</h4>
                <TeamMemberForm onSubmit={onAction} uploadRef={fileUploadRef} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TeamMemberCreate;
