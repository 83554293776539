import React, { useCallback } from 'react';

import { BaseTabProps } from 'src/components/Tabs';
import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { WalletP2POperationsResponseDto } from 'src/types/api/operations';
import P2POperationTable from 'src/components/Operations/P2POperationTable';

interface Props extends BaseTabProps, ProjectContextState {}

const TabWalletP2POperations: React.FC<Props> = ({ project }) => {
  const fetchData = useCallback(
    (
      request: PaginationDataFilter | undefined,
    ): Promise<PaginationData<WalletP2POperationsResponseDto>> => {
      if (!project) {
        return Promise.reject();
      }

      return Api.projects.fetchWalletP2POperations(project?.id, request);
    },
    [project],
  );

  if (!project) {
    return null;
  }

  return (
    <>
      <P2POperationTable name={'ProjectWalletP2POperations'} onFetch={fetchData} />
    </>
  );
};

export default withProject(TabWalletP2POperations);
