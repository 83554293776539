import React, { useRef, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useShortNotificationsQuery } from 'src/api/queries/useNotificaitonsQuery/useShortNotificationsQuery';
import Loader from 'src/components/Loader';
import Api from 'src/api';
import SanitizedHtmlBlock from 'src/components/SanitizedHtmlBlock';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import timeAgo from 'src/helpers/timeAgo';
import { RouteList } from 'src/routes';

const MAX_UNREAD_NOTIFICATIONS = 9;

const NotificationDropdown: React.FC = () => {
  const [menu, setMenu] = useState(false);
  const ref = useRef<any>();
  const { t } = useTranslation();
  const { data, isFetching, refetch } = useShortNotificationsQuery();
  const numOfNotifications = (): string => {
    if (data?.unread_amount == undefined || data.unread_amount <= 0) {
      return '';
    }

    if (data.unread_amount > 9) {
      return `${MAX_UNREAD_NOTIFICATIONS}+`;
    }

    return data.unread_amount.toString();
  };

  const markAllRead = async () => {
    await Api.notifications.markAllAsRead();
    return refetch();
  };

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="noti-icon waves-effect change-bells-position cursor-pointer"
          tag="div"
          id="page-header-notifications-dropdown"
        >
          <i
            className={`fas fa-bell align-middle me-4 ${
              !data?.unread_amount || data?.unread_amount <= 0 ? '' : ''
            }`}
          />
          <span className="badge">{numOfNotifications()}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          {data?.notifications === undefined || isFetching ? (
            <Loader />
          ) : (
            <>
              <div className="p-3">
                <Row className="align-items-center">
                  <Col>
                    <h5 className="m-0 font-size-16">{t('menu.notifications')}</h5>
                  </Col>
                  <div className="col-auto">
                    <a onClick={() => markAllRead()} className="small cursor-pointer">
                      {t('menu.mark_all_read')}
                    </a>
                  </div>
                </Row>
              </div>
              <SimpleBar
                className={'notification-container'}
                forceVisible={'y'}
                ref={ref as React.LegacyRef<SimpleBar>}
              >
                {data?.notifications?.length == 0 && (
                  <p className={'no-notifications-text text-muted'}>
                    {t('menu.no_new_notifications')}
                  </p>
                )}
                {data?.notifications?.map((el, idx) => (
                  <Link
                    to={{
                      pathname: RouteList.NOTIFICATIONS.LIST,
                      hash: el.id,
                    }}
                    className="notification-item"
                    key={idx}
                  >
                    <div
                      className={`notification-item-inner ${el.has_been_read ? 'text-muted' : ''}`}
                    >
                      <h6
                        className={`notification-title mt-0 mb-2 ${
                          el.has_been_read ? 'text-muted' : ''
                        }`}
                      >
                        {el.title}
                      </h6>
                      <div className="font-size-12">
                        <SanitizedHtmlBlock content={el.body ?? ''} className={'mb-2'} />
                        <p className="mb-0 ps-0 notification-item-time">
                          <i className={'far fa-clock align-middle me-1'} />
                          {timeAgo(el.created_at)}
                        </p>
                      </div>
                    </div>
                    {data.notifications.length - 1 !== idx && <hr className={'m-0'} />}
                  </Link>
                ))}
              </SimpleBar>
              <div className="p-2 border-top">
                <Link
                  to={{
                    pathname: RouteList.NOTIFICATIONS.LIST,
                    hash: data?.notifications.length ? data?.notifications[0]?.id : '',
                  }}
                  className={'d-grid'}
                  onClick={() => setMenu(false)}
                >
                  <PrimaryButton title={t('common.view_all')} />
                </Link>
              </div>
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default NotificationDropdown;
