import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Api from 'src/api';
import { RouteList } from 'src/routes';
import { useAuth } from 'src/services/useAuth';
import { useProfileQuery } from 'src/api/queries';

const ProfileMenu: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);

  const { data, isLoading } = useProfileQuery();
  const { logout } = useAuth();

  if (isLoading || data === undefined) return <></>;

  const handleLogout = async () => {
    await logout(Api.currentUser.logout);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className={'btn header-item waves-effect d-flex align-items-center'}
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{data.email}</span>{' '}
          <i className="fas fa-chevron-down m-1" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to={RouteList.PROFILE.PROFILE} className="dropdown-item">
            <i className="far fa-user  font-size-18 align-middle me-1 text-muted" />
            <span>{t('menu.profile')}</span>
          </Link>
          <div className="dropdown-divider" />
          <DropdownItem onClick={() => handleLogout()}>
            <span>
              <i className="fas fa-sign-out-alt font-size-18 align-middle me-1 text-muted" />
              {t('menu.logout')}
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(ProfileMenu);
