import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormGroup, Col, ColProps } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInput from 'src/components/Form/TextInput';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import {
  ExternalNewsResponseDto,
  SaveExternalNewsRequestDto,
  TranslatableValueDto,
} from 'src/types/api/landingPage/externalNews';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import CheckboxInput from 'src/components/Form/CheckboxInput';

const INITIAL_STATE: SaveExternalNewsRequestDto = {
  short_description: {
    ...Object.values(LanguageEnum).reduce((accumulator, currentValue) => {
      return {
        ...accumulator,
        [currentValue]: '',
      };
    }, {} as TranslatableValueDto<string>),
  },
  alt_text: {
    ...Object.values(LanguageEnum).reduce((accumulator, currentValue) => {
      return {
        ...accumulator,
        [currentValue]: '',
      };
    }, {} as TranslatableValueDto<string>),
  },
  picture_url: '',
  news_url: '',
  published_at: null,
  visible_landing_page: false,
};

interface ExternalNewsFormProps {
  externalNews?: ExternalNewsResponseDto;
  onSubmit: (request: SaveExternalNewsRequestDto, helper: any) => void;
}

const ExternalNewsForm: React.FC<ExternalNewsFormProps> = ({ externalNews, onSubmit }) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.LT);

  const ExternalNewsSchema = Yup.object().shape({
    short_description: Yup.string().translatableValueSchema(Yup.string().nullable().max(255)),
    picture_url: Yup.string().required(),
    news_url: Yup.string().required(),
    published_at: Yup.date().nullable(),
    visible_landing_page: Yup.boolean().required(),
  });

  const [externalNewsRequest, setExternalNewsRequest] =
    useState<SaveExternalNewsRequestDto>(INITIAL_STATE);

  useEffect(() => {
    if (externalNews) {
      setExternalNewsRequest(externalNews);
    }
  }, [externalNews]);

  const multiLocaleAttrs = useCallback(
    (inputName: string) => {
      return {
        name: inputName + '.' + language,
        placeholder: t('label.' + inputName),
      };
    },
    [language, t],
  );

  const colProps: ColProps = {
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <LanguageSwitcher locale={language} setLocale={setLanguage} />
      <Formik
        initialValues={externalNewsRequest}
        enableReinitialize={true}
        validationSchema={ExternalNewsSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Col {...colProps}>
                <DatePickerInput name={'published_at'} showTimeSelect={true} />
              </Col>
              <Col {...colProps}>
                <TextInput type={'text'} name={'picture_url'} />
              </Col>
              <Col {...colProps}>
                <TextInput type={'text'} name={'news_url'} />
              </Col>
              <Col {...colProps}>
                <WYSIWYGInput {...multiLocaleAttrs('short_description')} />
              </Col>
              <Col {...colProps}>
                <TextInput {...multiLocaleAttrs('alt_text')} placeholder={t('label.alt_text')} />
              </Col>
              <Col {...colProps}>
                <CheckboxInput
                  name={'visible_landing_page'}
                  placeholder={t('label.visible_landing_page')}
                  showPlaceholder={true}
                />
              </Col>
              <Col {...colProps}>
                <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
              </Col>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default ExternalNewsForm;
