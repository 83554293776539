import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import TabData, { BaseTabProps, TabInfo } from 'src/components/Tabs';
import { Permissions } from 'src/helpers/auth/permissions';
import TabProject from 'src/pages/AutoInvestment/Tabs/TabProject';
import TabFilter from 'src/pages/AutoInvestment/Tabs/TabFilter';
import { setGlobalLoading } from 'src/modules/app/actions';

const Tabs: TabInfo<BaseTabProps>[] = [
  {
    name: 'auto_investment.project_id',
    iconClass: 'fas fa-handshake',
    component: TabProject,
    permission: Permissions.BO__AUTO_INVESTMENT__VIEW,
  },
  {
    name: 'auto_investment.criteria',
    iconClass: 'fas fa-filter',
    component: TabFilter,
    permission: Permissions.BO__AUTO_INVESTMENT__VIEW,
  },
];

const AutoInvestment: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs="12">
            <Card className="mb-5">
              <CardBody>
                <div className={'d-flex align-items-center mb-4'}>
                  <h4 className={'m-0'}>{t('menu.auto_investment')}</h4>
                </div>
                <hr />
                <TabData tabs={Tabs} vertical={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AutoInvestment;
