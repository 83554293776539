import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  ExternalNewsResponseDto,
  SaveExternalNewsRequestDto,
} from 'src/types/api/landingPage/externalNews';

class ExternalNews extends Client {
  api = {
    FETCH_EXTERNAL_SINGLE_NEWS: `${this.http.baseUrl}/external-news/news/{externalNewsId}`,
    FETCH_EXTERNAL_NEWS: `${this.http.baseUrl}/external-news/filter`,
    CREATE_EXTERNAL_NEWS: `${this.http.baseUrl}/external-news/news`,
    UPDATE_EXTERNAL_NEWS: `${this.http.baseUrl}/external-news/news/{externalNewsId}`,
    DELETE_EXTERNAL_NEWS: `${this.http.baseUrl}/external-news/news/{externalNewsId}`,
  };

  fetchSingleExternalNews = (externalNewsId: number | string): Promise<ExternalNewsResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_EXTERNAL_SINGLE_NEWS, { externalNewsId });

    return this.http.get(url);
  };

  fetchFilteredExternalNews = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ExternalNewsResponseDto>> => {
    return this.http.get(this.api.FETCH_EXTERNAL_NEWS, { params: request });
  };

  createExternalNews = (request: SaveExternalNewsRequestDto): Promise<ExternalNewsResponseDto> => {
    return this.http.post(this.api.CREATE_EXTERNAL_NEWS, request);
  };

  updateExternalNews = (
    externalNewsId: number | string,
    request: SaveExternalNewsRequestDto,
  ): Promise<ExternalNewsResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_EXTERNAL_NEWS, { externalNewsId });

    return this.http.put(url, request);
  };

  deleteExternalNews = (externalNewsId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_EXTERNAL_NEWS, { externalNewsId });

    return this.http.delete(url);
  };
}

export default ExternalNews;
