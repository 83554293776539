import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import { success } from 'src/services/toastr';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  onDelete: () => Promise<any>;
}

const DeleteButton: React.FC<Props> = ({ onDelete, className, type, onClick, ...props }) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const deleteModal = useCallback(() => {
    showModal(
      <ActionModal
        title={t('common.delete')}
        onAction={() => onDelete().then(() => success(t('common.deleted_success')))}
        body={t('common.delete_generic')}
        actionText={t('common.delete')}
      />,
    );
  }, [onDelete, showModal, t]);

  return (
    <button
      className={`btn btn-danger ${className ?? ''}`}
      type={type ?? 'button'}
      onClick={onClick ?? deleteModal}
      {...props}
    >
      {t('common.delete')}
    </button>
  );
};

export default DeleteButton;
