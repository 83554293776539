import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import { Permissions } from 'src/helpers/auth/permissions';
import usePermissions from 'src/helpers/usePermissions';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import { GenerateReportRequestDto, WalletReportFilterForm } from 'src/types/api/reports';
import { appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import SelectWalletOwnerInput from 'src/pages/Reports/View/Filters/Selects/SelectWalletOwnerInput';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';

const AVAILABLE_COLUMNS: string[] = [
  'owner_pid',
  'owner_type',
  'type',
  'status',
  'owner_name',
  'owner_legal_code',
  'balance',
  'available_balance',
  'latest_operation_at',
  'created_at',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.WALLETS;

const WalletReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<WalletReportFilterForm>({
    name: null,
    columns: AVAILABLE_COLUMNS,
    user_pid: null,
    project_pid: null,
    owner_type: null,
  });

  const mapData = (form: WalletReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendFilter(request, 'user_pid', form.user_pid);
    appendFilter(request, 'project_pid', form.project_pid);
    appendFilter(request, 'owner_type', form.owner_type);

    return request;
  };

  const onSubmit = useCallback(
    async (request: WalletReportFilterForm, helper: FormikHelpers<WalletReportFilterForm>) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  if (!p.hasAll([mapManagePermission(TYPE), Permissions.BO__REPORTS__GENERATE])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik initialValues={request} onSubmit={onSubmit} enableReinitialize={true}>
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'user_pid'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'project_pid'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectWalletOwnerInput name={'owner_type'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default WalletReportFilter;
