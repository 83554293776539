import React, { useCallback, useState } from 'react';
import { Card, CardBody, CardImg, CardSubtitle } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import ViewButton from 'src/components/ViewButton';

type SimplePictureDto = {
  name: string;
  url: string;
};

interface Props {
  pictures: Array<SimplePictureDto>;
}

const SimplePictureList: React.FC<Props> = ({ pictures }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);

  const onView = useCallback((index: number) => {
    setPhotoIndex(index);
    setIsOpen(true);
  }, []);

  return (
    <>
      {pictures.map((picture, key) => {
        return (
          <Card key={key} style={{ minWidth: '225px', maxWidth: '300px' }}>
            <CardImg
              onClick={() => {
                setPhotoIndex(key);
                setIsOpen(true);
              }}
              top
              src={picture.url}
              className={'fit-image placeholder-glow'}
              style={{ height: '150px', width: '100%' }}
            />
            <CardBody className={'p-0 py-3'}>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                {picture.name}
              </CardSubtitle>
              <div className={'d-flex gap-2'}>
                <ViewButton onView={() => onView(key)} />
              </div>
            </CardBody>
          </Card>
        );
      })}
      {isOpen && pictures[photoIndex]?.url && (
        <Lightbox
          mainSrc={pictures[photoIndex]?.url}
          nextSrc={pictures[photoIndex]?.url}
          prevSrc={pictures[photoIndex]?.url}
          imageTitle={pictures[photoIndex].name}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((prevState) => (prevState - 1 < 0 ? pictures.length - 1 : prevState - 1))
          }
          onMoveNextRequest={() =>
            setPhotoIndex((prevState) => (prevState + 1 >= pictures.length ? 0 : prevState + 1))
          }
        />
      )}
    </>
  );
};

export default SimplePictureList;
