import React, { useCallback, useEffect } from 'react';
import { setGlobalLoading } from 'src/modules/app/actions';
import { useTranslation } from 'react-i18next';
import Api from 'src/api';
import { navigate } from 'src/helpers/navigate';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { RouteList } from 'src/routes';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { SaveFaqRequestDto } from 'src/types/api/landingPage/faq';
import { toast } from 'react-toastify';
import success = toast.success;
import FaqForm from 'src/pages/LandingPage/FAQ/FaqForm';

const FaqCreate: React.FC = () => {
  const { t } = useTranslation();

  const onAction = useCallback(
    (values: SaveFaqRequestDto, helpers) => {
      try {
        return Api.faq.createFaq(values).then(() => {
          success(t('common.created_success'));
          navigate(RouteList.LANDING_PAGE.FAQ.LIST);
        });
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [t],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={1} className={'mb-4'}>
            <Link to={RouteList.LANDING_PAGE.FAQ.LIST}>
              <PrimaryButton title={t('common.back')} type={'button'} />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Card>
              <CardBody>
                <h4 className={'mb-3'}>{t('pages.create_faq')}</h4>
                <FaqForm onSubmit={onAction} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default FaqCreate;
