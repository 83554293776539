import Client from './client';
import { CommitteeMemberResponseDto } from 'src/types/api/user';

class Information extends Client {
  api = {
    FETCH_COMMITTEE_MEMBERS: `${this.http.baseUrl}/information/committee-members`,
  };

  fetchCommitteeMembers = (): Promise<CommitteeMemberResponseDto[]> => {
    return this.http.get(this.api.FETCH_COMMITTEE_MEMBERS);
  };
}

export default Information;
