import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

const RiskSkeleton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="mb-3">{t('applications.risks')}</h3>
      <hr />
      <Skeleton height={40} count={7} className={'mb-3'} />
    </>
  );
};

export default RiskSkeleton;
