export enum LemonwayDocumentInternalTypeEnum {
  IDENTITY_CARD = 'identity_card',
  PROOF_OF_ADDRESS = 'proof_of_address',
  PROOF_OF_BANK_INFORMATION = 'proof_of_bank_information',
  PASSPORT_EUROPEAN = 'passport_european',
  PASSPORT_OUTSIDE_EUROPE = 'passport_outside_europe',
  RESIDENCE_PERMIT = 'residence_permit',
  OTHER_DOCUMENTS = 'other_documents',
  ARTICLES_OF_ASSOCIATION = 'articles_of_association',
  OFFICIAL_COMPANY_REGISTRATION_DOCUMENT = 'official_company_registration_document',
  DRIVING_LICENSE = 'driving_license',
  STATUS = 'status',
  SELFIE = 'selfie',
  SDD_MANDATE = 'sdd_mandate',
}

export enum LemonwayDocumentInternalStatusEnum {
  PENDING = 'pending',
  SENT = 'sent',
  PROCESSED = 'processed',
  CANT_SEND_NO_ROOM = 'cant_send_no_room',
  FAILED_TO_SEND = 'failed_to_send',
}

export enum LemonWayDocumentTypeEnum {
  ID_CARD = '0',
  PROOF_OF_ADDRESS = '1',
  SCAN_OF_A_PROOF_OF_IBAN = '2',
  PASSPORT_EUROPEAN_UNION = '3',
  PASSPORT_OUTSIDE_THE_EUROPEAN_UNION = '4',
  RESIDENCE_PERMIT = '5',
  OTHER_1 = '6',
  OFFICIAL_COMPANY_REGISTRATION_DOCUMENT = '7',
  DRIVER_LICENCE = '11',
  STATUS = '12',
  SELFIE = '13',
  OTHER_2 = '14',
  OTHER_3 = '15',
  OTHER_4 = '16',
  OTHER_5 = '17',
  OTHER_6 = '18',
  OTHER_7 = '19',
  OTHER_8 = '20',
  SDD_MANDATE = '21',
}

export enum LemonWayDocumentStatusEnum {
  PUT_ON_HOLD = '0',
  WAITING_FOR_VERIFICATION = '1',
  ACCEPTED = '2',
  REJECTED = '3',
  REJECTED_UNREADABLE = '4',
  REJECTED_EXPIRED = '5',
  REJECTED_WRONG_TYPE = '6',
  REJECTED_WRONG_HOLDER_NAME = '7',
  REJECTED_DUPLICATED = '8',
}
