import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownItem,
  DropdownMenu,
  DropdownProps,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ApplicationContext, {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import Api from 'src/api';
import ReasonModal from 'src/components/Modal/Modals/ReasonModal';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import ChangeOwnerModal from 'src/pages/Application/Update/ChangeOwnerModal';
import AssignManagerModal from 'src/components/Modal/Modals/AssignManagerModal';
import { ApplicationStatus } from 'src/types/app/applications';
import AssignAnalyticModal from 'src/components/Modal/Modals/AssignAnalyticModal';

interface Props extends DropdownProps, ApplicationContextState {}

const ApplicationDropdownComponent: React.FC<Props> = ({ application, setApplication, props }) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const reject = useCallback(() => {
    if (!application) {
      return;
    }

    showModal(
      <ReasonModal
        title={t('applications.reject.title')}
        body={t('applications.reject.body', { name: 'test' })}
        successMessage={t('common.updated_success')}
        actionText={t('applications.reject')}
        onAction={async (reason) => {
          return Api.applications
            .rejectApplication(application.id, { reason })
            .then((response) => setApplication(response));
        }}
      />,
    );
  }, [application, setApplication, showModal, t]);

  const changeOwner = useCallback(() => {
    if (!application) {
      return;
    }

    showModal(
      <ApplicationContext application={application} setApplication={setApplication}>
        <ChangeOwnerModal />
      </ApplicationContext>,
    );
  }, [application, setApplication, showModal]);

  const submitApplication = useCallback(() => {
    if (!application) {
      return;
    }

    showModal(
      <ActionModal
        title={t('applications.submit.title')}
        body={t('applications.submit.body')}
        successMessage={t('applications.submit.success')}
        actionText={t('common.submit')}
        onAction={async () => {
          return Api.applications
            .submitApplication(application.id)
            .then((response) => setApplication(response));
        }}
      />,
    );
  }, [application, setApplication, showModal, t]);

  const changeManager = useCallback(() => {
    if (!application) {
      return;
    }

    showModal(
      <AssignManagerModal
        currentManagerId={application?.manager?.id}
        onAction={async (request) => {
          return Api.applications.changeManager(application.id, request).then((application) => {
            setApplication(application);
          });
        }}
        title={t('applications.manager.assign.title')}
        body={t('applications.manager.assign.body')}
      />,
    );
  }, [t, showModal, application, setApplication]);

  const changeAnalytic = useCallback(() => {
    if (!application) {
      return;
    }

    showModal(
      <AssignAnalyticModal
        currentAnalyticId={application?.analytic?.id}
        onAction={async (request) => {
          return Api.applications.changeAnalytic(application.id, request).then((application) => {
            setApplication(application);
          });
        }}
        title={t('applications.analytic.assign.title')}
        body={t('applications.analytic.assign.body')}
      />,
    );
  }, [t, showModal, application, setApplication]);

  return (
    <React.Fragment>
      <UncontrolledDropdown {...props}>
        <DropdownToggle tag="a" className="cursor text-light font-size-16" caret>
          <i className="fas fa-ellipsis-h" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {application && application.status != ApplicationStatus.Rejected && (
            <PermissionAction permissions={Permissions.BO__APPLICATIONS__REJECT}>
              <DropdownItem onClick={reject}>{t('applications.reject')}</DropdownItem>
            </PermissionAction>
          )}
          <PermissionAction
            permissions={[
              Permissions.BO__APPLICATIONS__CHANGE_MANAGER,
              Permissions.BO__USERS__VIEW_BRIEF,
            ]}
          >
            <DropdownItem onClick={changeManager}>
              {t('applications.manager.assign.title')}
            </DropdownItem>
          </PermissionAction>
          <PermissionAction
            permissions={[
              Permissions.BO__APPLICATIONS__CHANGE_ANALYTIC,
              Permissions.BO__USERS__VIEW_BRIEF,
            ]}
          >
            <DropdownItem onClick={changeAnalytic}>
              {t('applications.analytic.assign.title')}
            </DropdownItem>
          </PermissionAction>
          <PermissionAction permissions={Permissions.BO__APPLICATIONS__CHANGE_OWNER}>
            <DropdownItem onClick={changeOwner}>{t('applications.change_owner')}</DropdownItem>
          </PermissionAction>
          {application && application.status === ApplicationStatus.Pending && (
            <PermissionAction permissions={Permissions.BO__APPLICATIONS__CREATE}>
              <DropdownItem onClick={submitApplication}>{t('applications.submit')}</DropdownItem>
            </PermissionAction>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
};

export const ApplicationDropdown = withApplication(ApplicationDropdownComponent);
