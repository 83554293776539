import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import {
  UserCompanyBeneficiaryResponseDto,
  UserCompanyInvestorKnowYourCompanyResponseDto,
  UserCompanyProjectDeveloperKnowYourCompanyResponseDto,
  UserCompanyPublicOfficialResponseDto,
  UserCompanyResponseDto,
} from 'src/types/api/userCompanies';
import Api from 'src/api';
import { Container, Row } from 'reactstrap';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter, UserType } from 'src/types';
import { FinancialRiskCalculatorResponseDto } from 'src/types/api/user';
import { Cell, Column } from 'react-table';
import Table from 'src/components/Table';

interface Props extends BaseTabProps {
  company?: UserCompanyResponseDto;
}

const INITIAL_SORT_ORDER = [
  {
    id: 'submitted_at',
    desc: true,
  },
];

const TabLegal: React.FC<Props> = ({ id, ...props }) => {
  const { t } = useTranslation();
  const [company, setCompany] = useState<UserCompanyResponseDto | null>(null);
  const [companyKnowYourBusiness, setCompanyKnowYourBusiness] = useState<
    | any
    | UserCompanyInvestorKnowYourCompanyResponseDto
    | UserCompanyProjectDeveloperKnowYourCompanyResponseDto
  >(null);

  const [financialRiskSubmits, setFinancialRiskSubmits] =
    useState<PaginationData<FinancialRiskCalculatorResponseDto>>();

  useEffect(() => {
    if (props.company) {
      setCompany(props.company);
    } else if (id) {
      Api.userCompanies.fetchCompany(id).then((response: UserCompanyResponseDto) => {
        setCompany(response);
      });
    }

    if (company && company.kyb) {
      setCompanyKnowYourBusiness(company.kyb.data);
    }
  }, [props, id, company]);

  const formatActivityRegions = () => {
    return companyKnowYourBusiness.activity_regions
      ?.map((region: string) =>
        region !== 'other'
          ? t(`users.company.activity_regions.${region}`)
          : companyKnowYourBusiness.other_activity_regions_text,
      )
      .join(', ');
  };

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'string',
      },
      {
        Header: t('table.annual_income'),
        accessor: 'annual_income',
        sortType: 'string',
      },
      {
        Header: t('table.liquid_net_worth'),
        accessor: 'liquid_net_worth',
        sortType: 'string',
      },
      {
        Header: t('table.annual_obligations'),
        accessor: 'annual_obligations',
        sortType: 'number',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.result'),
        accessor: 'result',
        sortType: 'number',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.submitted_at'),
        accessor: 'submitted_at',
        sortType: 'string',
        Cell: (cell: Cell<FinancialRiskCalculatorResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!props.company?.id) return Promise.resolve();

      return Api.userCompanies
        .fetchFinancialRiskSubmits(props.company.id, request)
        .then((response) => setFinancialRiskSubmits(response));
    },
    [props.company?.id],
  );

  return (
    <>
      {companyKnowYourBusiness && company ? (
        <Container fluid>
          <Row>
            {company?.type === UserType.Investor ? (
              <>
                <SidebarDataRow
                  label={t('label.company.planned_investment_amount')}
                  value={companyKnowYourBusiness.planned_investment_amount}
                  displayValue={t(`users.${companyKnowYourBusiness.planned_investment_amount}`)}
                />
                <SidebarDataRow
                  label={t('label.company.activity_regions')}
                  value={formatActivityRegions()}
                />
                <SidebarDataRow
                  label={t('label.company.has_cash_operations')}
                  value={companyKnowYourBusiness.has_cash_operations}
                  displayValue={
                    companyKnowYourBusiness.has_cash_operations ? t('common.yes') : t('common.no')
                  }
                />
                <SidebarDataRow
                  label={t('label.company.ytd_cash_operations_amount')}
                  value={companyKnowYourBusiness?.ytd_cash_operations_amount}
                  displayValue={t(`users.${companyKnowYourBusiness?.ytd_cash_operations_amount}`)}
                />
                <SidebarDataRow
                  label={t('label.company.investment_amount_source')}
                  value={companyKnowYourBusiness.investment_amount_source}
                />
                <SidebarDataRow
                  label={t('label.company.ytd_income')}
                  value={companyKnowYourBusiness?.ytd_income}
                  displayValue={t('common.money', {
                    value: companyKnowYourBusiness?.ytd_income,
                  })}
                />
                <SidebarDataRow
                  label={t('label.company.management_structure')}
                  value={companyKnowYourBusiness.management_structure}
                  displayValue={t(`users.company.${companyKnowYourBusiness.management_structure}`)}
                />
                <SidebarDataRow
                  label={t('label.company.has_bankruptcy_procedure')}
                  value={
                    companyKnowYourBusiness.has_bankruptcy_procedure
                      ? t('common.yes')
                      : t('common.no')
                  }
                />
                <SidebarDataRow
                  label={t(
                    'label.company.is_shareholder_beneficiary_family_member_public_official',
                  )}
                  value={
                    companyKnowYourBusiness.is_shareholder_beneficiary_family_member_public_official
                      ? t('common.yes')
                      : t('common.no')
                  }
                />
                <SidebarDataRow
                  label={t('label.company.has_confirmed_data_integrity')}
                  value={
                    companyKnowYourBusiness.has_confirmed_data_integrity
                      ? t('common.yes')
                      : t('common.no')
                  }
                />
              </>
            ) : (
              <>
                <SidebarDataRow
                  label={t('label.company.management_structure')}
                  value={companyKnowYourBusiness.management_structure}
                  displayValue={t(`users.company.${companyKnowYourBusiness.management_structure}`)}
                />
                <SidebarDataRow
                  label={t('label.company.has_bankruptcy_procedure')}
                  value={
                    companyKnowYourBusiness.has_bankruptcy_procedure
                      ? t('common.yes')
                      : t('common.no')
                  }
                />
                <SidebarDataRow
                  label={t(
                    'label.company.is_shareholder_beneficiary_family_member_public_official',
                  )}
                  value={
                    companyKnowYourBusiness.is_shareholder_beneficiary_family_member_public_official
                      ? t('common.yes')
                      : t('common.no')
                  }
                />
                <SidebarDataRow
                  label={t('label.company.has_confirmed_data_integrity')}
                  value={
                    companyKnowYourBusiness.has_confirmed_data_integrity
                      ? t('common.yes')
                      : t('common.no')
                  }
                />
              </>
            )}
          </Row>
          <hr />
          <h6 className={'mb-3'}>
            <strong>{t('label.company.beneficiaries')}</strong>
          </h6>
          {companyKnowYourBusiness.beneficiaries?.map(
            (beneficiary: UserCompanyBeneficiaryResponseDto, index: number) => {
              return (
                <Row key={index} className={'mb-5'}>
                  <SidebarDataRow label={t('label.full_name')} value={beneficiary.full_name} />
                  <SidebarDataRow label={t('label.company.shares')} value={beneficiary.shares} />
                  <SidebarDataRow label={t('label.gov_code')} value={beneficiary.gov_code} />
                  <SidebarDataRow
                    label={t('label.company.is_public_official')}
                    value={beneficiary.is_public_official ? t('common.yes') : t('common.no')}
                  />
                  <SidebarDataRow
                    label={t('label.company.is_family_member_public_official')}
                    value={
                      beneficiary.is_family_member_public_official
                        ? t('common.yes')
                        : t('common.no')
                    }
                  />
                  <SidebarDataRow
                    label={t('label.nationality')}
                    value={t(`countries.${beneficiary.nationality}`)}
                  />
                </Row>
              );
            },
          )}
          {companyKnowYourBusiness.public_officials?.map(
            (publicOfficial: UserCompanyPublicOfficialResponseDto, index: number) => {
              return (
                <div key={index}>
                  <hr />
                  <h6 className={'mb-3'}>
                    <strong>{t('label.company.public_officials')}</strong>
                  </h6>
                  <Row key={index}>
                    <SidebarDataRow label={t('label.details')} value={publicOfficial.details} />
                  </Row>
                </div>
              );
            },
          )}
          {props.company?.type === UserType.Investor && (
            <>
              <hr />
              <h1>{t('common.calculated_financial_risks')}</h1>
              <Table
                title={'financial-risk-submits'}
                columns={columns}
                data={financialRiskSubmits}
                onFetchData={fetchData}
                initialSortBy={INITIAL_SORT_ORDER}
              />
            </>
          )}
        </Container>
      ) : (
        <p>{t('common.no_data')}</p>
      )}
    </>
  );
};

export default TabLegal;
