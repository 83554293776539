import React, { createContext, useContext } from 'react';
import { BorrowerPayoutResponseDto } from 'src/types/api/payments/borrowerPayouts';

export interface BorrowerPayoutContextState {
  borrowerPayout: BorrowerPayoutResponseDto | undefined;
  setBorrowerPayout: (payment: BorrowerPayoutResponseDto) => void;
}

const BorrowerPayoutContext = createContext<BorrowerPayoutContextState>({
  borrowerPayout: undefined,
  setBorrowerPayout: () => {
    return;
  },
});

export const BorrowerPayoutProvider: React.FC<BorrowerPayoutContextState> = ({
  borrowerPayout,
  setBorrowerPayout,
  children,
}) => {
  return (
    <BorrowerPayoutContext.Provider
      value={{ borrowerPayout: borrowerPayout, setBorrowerPayout: setBorrowerPayout }}
    >
      {children}
    </BorrowerPayoutContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export function withBorrowerPayout<
  P extends BorrowerPayoutContextState = BorrowerPayoutContextState,
>(Component: React.ComponentType<P>) {
  const WithBorrowerPayout = ({ ...props }: Optionalize<P, BorrowerPayoutContextState>) => {
    const c = useContext(BorrowerPayoutContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithBorrowerPayout.displayName = `withBorrowerPayout${Component.displayName}`;

  return WithBorrowerPayout;
}

export default BorrowerPayoutProvider;
