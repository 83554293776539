import React, { useCallback, useState } from 'react';
import { FormGroup, ModalBody, ModalHeader } from 'reactstrap';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import TextInput from 'src/components/Form/TextInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import Api from 'src/api';
import { ProjectResponseDto, UpdateProjectArticleRequest } from 'src/types/api/projects';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { success } from 'src/services/toastr';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import SaveButton from 'src/components/Form/SaveButton';
import { defaultTranslatableValue, transformErrors } from 'src/helpers';
import LocaleFormik from 'src/components/Form/LocaleFormik/LocaleFormik';
import { ProjectArticleCreateSchema } from 'src/pages/Project/Update/Schemas/ProjectArticleSchema';

interface Props {
  projectId: string;
  setProject: (project: ProjectResponseDto) => void;
}

const INITIAL_VALUES: UpdateProjectArticleRequest = {
  value: defaultTranslatableValue(),
  title: defaultTranslatableValue(),
  published_at: '',
};

const NewsArticleCreateModal: React.FC<Props> = ({ projectId, setProject }) => {
  const { hideModal } = useGlobalModalContext();
  const [articles] = useState<UpdateProjectArticleRequest>(INITIAL_VALUES);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (request: UpdateProjectArticleRequest, helpers: FormikHelpers<any>) => {
      try {
        const response = await Api.projects.storeArticleNews(projectId, request);
        setProject(response);
        hideModal();
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [projectId, setProject, hideModal, t],
  );

  return (
    <ModalComponent size={'lg'}>
      <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
        {t('projects.articles.add')}
      </ModalHeader>
      <ModalBody>
        <LocaleFormik
          initialValues={articles}
          enableReinitialize={true}
          onSubmit={onSubmit}
          validationSchema={ProjectArticleCreateSchema()}
        >
          {({ isSubmitting, locale, setLocale, multiLocaleAttrs }) => (
            <>
              <LanguageSwitcher locale={locale} setLocale={setLocale} />
              <FormGroup>
                <TextInput {...multiLocaleAttrs('title')} />
              </FormGroup>
              <FormGroup>
                <TextAreaInput
                  style={{ height: '150px' }}
                  {...multiLocaleAttrs('value')}
                  placeholder={t('label.content')}
                />
              </FormGroup>
              <FormGroup>
                <DatePickerInput name={'published_at'} showTimeSelect={true} />
              </FormGroup>
              <FormGroup>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </FormGroup>
            </>
          )}
        </LocaleFormik>
      </ModalBody>
    </ModalComponent>
  );
};

export default NewsArticleCreateModal;
