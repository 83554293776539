import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormGroup, Col, ColProps } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import TextInput from 'src/components/Form/TextInput';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import {
  JobPositionResponseDto,
  SaveJobPositionRequestDto,
} from 'src/types/api/landingPage/jobPosition';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import { defaultTranslatableValue } from 'src/helpers';

const INITIAL_STATE: SaveJobPositionRequestDto = {
  title: defaultTranslatableValue(),
  short_description: defaultTranslatableValue(),
  description: defaultTranslatableValue(),
};

interface JobPositionsFormProps {
  jobPosition?: JobPositionResponseDto;
  onSubmit: (
    request: SaveJobPositionRequestDto,
    helper: FormikHelpers<SaveJobPositionRequestDto>,
  ) => void;
}

const JobPositionsForm: React.FC<JobPositionsFormProps> = ({ jobPosition, onSubmit }) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.LT);

  const JobPositionSchema = Yup.object().shape({
    title: Yup.string().translatableValueSchema(Yup.string().nullable().max(255)),
    short_description: Yup.string().translatableValueSchema(Yup.string().nullable()),
    description: Yup.string().translatableValueSchema(Yup.string().nullable()),
  });

  const [jobPositionRequest, setJobPositionRequest] =
    useState<SaveJobPositionRequestDto>(INITIAL_STATE);

  useEffect(() => {
    if (jobPosition) {
      setJobPositionRequest(jobPosition);
    }
  }, [jobPosition]);

  const multiLocaleAttrs = useCallback(
    (inputName: string) => {
      return {
        name: inputName + '.' + language,
        placeholder: t('label.' + inputName),
      };
    },
    [language, t],
  );

  const colProps: ColProps = {
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <LanguageSwitcher locale={language} setLocale={setLanguage} />
      <Formik
        initialValues={jobPositionRequest}
        enableReinitialize={true}
        validationSchema={JobPositionSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Col {...colProps}>
                <TextInput {...multiLocaleAttrs('title')} />
              </Col>
              <Col {...colProps}>
                <WYSIWYGInput {...multiLocaleAttrs('short_description')} />
              </Col>
              <Col {...colProps}>
                <WYSIWYGInput {...multiLocaleAttrs('description')} />
              </Col>
              <Col {...colProps}>
                <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
              </Col>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default JobPositionsForm;
