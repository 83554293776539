import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { MarketingContactType } from 'src/helpers/Enums/MarketingContactType';
import { MarketingReportFilterEnum } from 'src/helpers/Enums/MarketingReportFilterEnum';

interface Props extends StateManagerProps {
  name: string;
  placeholder?: string;
}

const SelectDirectMarketingInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [options] = useState<ReactSelectOption[]>([
    {
      value: '',
      label: t('common.all'),
    },
    {
      value: MarketingContactType.PHONE,
      label: t('reports.filter.phone'),
    },
    {
      value: MarketingContactType.EMAIL,
      label: t('reports.filter.email'),
    },
    {
      value: MarketingReportFilterEnum.BOTH,
      label: t('reports.filter.not_empty'),
    },
    {
      value: MarketingReportFilterEnum.NONE,
      label: t('reports.filter.none'),
    },
  ]);

  return <SelectInput {...props} options={options} />;
};

export default SelectDirectMarketingInput;
