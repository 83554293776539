import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form } from 'reactstrap';

import { DashboardCardGrid } from 'src/pages/Common/Dashboard/DashboardCardGrid';
import { getCurrentMonthFirstDay, getCurrentMonthLastDay } from 'src/helpers/utilts';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SaveButton from 'src/components/Form/SaveButton';
import Api from 'src/api';
import {
  DashboardPageGraphSearchDto,
  MarketingDashboardPageResponseDto,
} from 'src/types/api/dashboard';

export const CustomDatesMarketingDataBlock = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<MarketingDashboardPageResponseDto | null>(null);

  const Schema = Yup.object().shape({
    start_date: Yup.date().required(),
    end_date: Yup.date()
      .required()
      .when('start_date', (start_date, schema) => {
        return start_date
          ? schema.min(start_date, t('validation.date.min', { min: start_date }))
          : schema;
      }),
  });

  const handleSearch = async (values: DashboardPageGraphSearchDto) => {
    const response = await Api.dashboard.fetchMarketingDashboardHistorical(
      values.start_date,
      values.end_date,
    );
    setData(response);

    return true;
  };

  return (
    <div>
      <h3 className="card-ml-1">{t('dashboard.historical')}</h3>

      <div className={'d-flex'}>
        <div className="w-100 card-m-1">
          <Formik
            initialValues={{
              start_date: getCurrentMonthFirstDay(),
              end_date: getCurrentMonthLastDay(),
            }}
            validationSchema={Schema}
            enableReinitialize={true}
            onSubmit={handleSearch}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div style={{ display: 'inline-flex' }} className="w-100">
                  <div className="mb-2 w-50">
                    <div className="mr-5">
                      <DatePickerInput name="start_date" />
                    </div>
                  </div>
                  <div className="mb-2 w-50">
                    <div className="ml-5">
                      <DatePickerInput name="end_date" />
                    </div>
                  </div>
                </div>
                <SaveButton title={t('common.search')} submitting={isSubmitting} />
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {data === null ? (
        <></>
      ) : (
        <>
          <DashboardCardGrid
            cards={[
              {
                title: t('dashboard.marketing.registered_users'),
                value: data.registered_users,
                tooltipElementId: 'registered_users',
                tooltipValue: t('dashboard.marketing.registered_users.tooltip'),
              },
              {
                title: t('dashboard.marketing.registered_users_referral'),
                value: data.registered_users_referral,
                tooltipElementId: 'registered_users_referral',
                tooltipValue: t('dashboard.marketing.registered_users_referral.tooltip'),
              },
              {
                title: t('dashboard.marketing.registered_users_referral_conversion'),
                value: data.registered_users_referral_conversion,
                tooltipElementId: 'registered_users_referral_conversion',
                tooltipValue: t('dashboard.marketing.registered_users_referral_conversion.tooltip'),
              },
              {
                title: t('dashboard.marketing.active_users'),
                value: data.active_users,
                tooltipElementId: 'active_users',
                tooltipValue: t('dashboard.marketing.active_users.tooltip'),
              },
              {
                title: t('dashboard.marketing.average_project_funding_duration'),
                value: data.average_project_funding_duration,
              },
            ]}
          />
          <DashboardCardGrid
            cards={[
              {
                title: t('dashboard.marketing.successful_identity_tries'),
                value: data.successful_identity_tries,
                tooltipElementId: 'successful_identity_tries',
                tooltipValue: t('dashboard.marketing.successful_identity_tries.tooltip'),
              },
              {
                title: t('dashboard.marketing.successful_identity_tries_conversion'),
                value: data.successful_identity_tries_conversion,
                tooltipElementId: 'successful_identity_tries_conversion',
                tooltipValue: t('dashboard.marketing.successful_identity_tries_conversion.tooltip'),
              },
              {
                title: t('dashboard.marketing.failed_identity_tries'),
                value: data.failed_identity_tries,
                tooltipElementId: 'failed_identity_tries',
                tooltipValue: t('dashboard.marketing.failed_identity_tries.tooltip'),
              },
              {
                title: t('dashboard.marketing.failed_identity_tries_conversion'),
                value: data.failed_identity_tries_conversion,
                tooltipElementId: 'failed_identity_tries_conversion',
                tooltipValue: t('dashboard.marketing.failed_identity_tries_conversion.tooltip'),
              },
              {
                title: t('dashboard.marketing.largest_active_portfolio'),
                value: t('common.money', {
                  value: data.largest_active_portfolio,
                }),
              },
            ]}
          />
          <DashboardCardGrid
            cards={[
              {
                title: t('dashboard.marketing.new_investors_count'),
                value: data.new_investors_count,
                tooltipElementId: 'new_investors_count',
                tooltipValue: t('dashboard.marketing.new_investors_count.tooltip'),
              },
              {
                title: t('dashboard.marketing.new_investors_count_conversion'),
                value: data.new_investors_count_conversion,
                tooltipElementId: 'new_investors_count_conversion',
                tooltipValue: t('dashboard.marketing.new_investors_count_conversion.tooltip'),
              },
              {
                title: t('dashboard.marketing.average_investment_to_project'),
                value: t('common.money', {
                  value: data.average_investment_to_project,
                }),
                tooltipElementId: 'average_investment_to_project',
                tooltipValue: t('dashboard.marketing.average_investment_to_project.tooltip'),
              },
              {
                title: t('dashboard.marketing.largest_investment'),
                value: t('common.money', {
                  value: data.largest_investment,
                }),
              },
              {
                title: t('dashboard.marketing.average_project_duration'),
                value: data.average_project_duration,
                tooltipElementId: 'average_project_duration',
                tooltipValue: t('dashboard.marketing.average_project_duration.tooltip'),
              },
            ]}
          />
        </>
      )}
    </div>
  );
};
