import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import SanitizedHtmlBlock from 'src/components/SanitizedHtmlBlock';
import TextInput from 'src/components/Inputs/TextInput';

interface Props {
  subject: string;
  message: string;
  date: Date | null;
}

const ViewCustomEmailListModal: React.FC<Props> = ({ subject, message, date }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
          {t('common.view_custom_message')}
        </ModalHeader>

        <ModalBody className={'custom-history-notification-form-style'}>
          <Form id={'viewCustomHistoryNotificationIndex'}>
            <FormGroup>
              <Row>
                <Col sm={12}>
                  <div className={'mb-3'}>
                    <label htmlFor="">{t('label.date')}</label>
                    <div className={'custom-history-notification'}>{date}</div>
                  </div>

                  <div className={'mb-3'}>
                    <TextInput
                      label={t('label.subject')}
                      name={'subject'}
                      value={subject}
                      readOnly
                    />
                  </div>

                  <div>
                    <label htmlFor="">{t('label.message')}</label>
                    <div>
                      <SanitizedHtmlBlock
                        content={message}
                        className={'custom-history-notification-textarea'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className={'d-flex justify-content-center'}></ModalFooter>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ViewCustomEmailListModal;
