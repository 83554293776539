import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import RoleForm from '../RoleForm';
import { SaveRoleRequestDto } from 'src/types/api/roles';

const RoleUpdate: React.FC = () => {
  const { t } = useTranslation();
  const { roleId } = useParams<{ roleId: string }>();
  const [roleRequest, setRoleRequest] = useState<SaveRoleRequestDto>();

  const onSubmit = useCallback(
    async (request: SaveRoleRequestDto, helpers: FormikHelpers<SaveRoleRequestDto>) => {
      try {
        await Api.roles.updateRole(roleId, request);

        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }

      return true;
    },
    [roleId, t],
  );

  useEffect(() => {
    Api.roles.fetchRole(roleId).then((role) => {
      setRoleRequest(role);
      setGlobalLoading(false);
    });
  }, [roleId]);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-4'}>{t('pages.edit_role')}</h4>
            <RoleForm onSubmit={onSubmit} initial={roleRequest} />
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default RoleUpdate;
