import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import { ColProps } from 'reactstrap/types/lib/Col';

interface DataBlockProps {
  label: string;
  value: any | null | undefined;
  isVisible?: boolean;
  colProps?: ColProps;
}

const DEFAULT_COLUMN_PROPS: ColProps = {
  lg: 3,
  md: 4,
  sm: 6,
  xs: 12,
};

const DataBlock: React.FC<DataBlockProps> = ({ label, value, isVisible, colProps }) => {
  const [cProps, setCProps] = useState<ColProps>(colProps ?? DEFAULT_COLUMN_PROPS);

  useEffect(() => {
    setCProps(colProps ?? DEFAULT_COLUMN_PROPS);
  }, [colProps]);

  if (isVisible !== undefined && !isVisible) {
    return null;
  }

  return (
    <>
      <Col {...cProps} className={'mb-3'}>
        <div>{label}:</div>
        <div className="fw-bold">{value}</div>
      </Col>
    </>
  );
};
export default DataBlock;
