import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { success } from 'src/services/toastr';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import TextInput from 'src/components/Form/TextInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import {
  DocumentTemplateListResponseDto,
  UpdateDocumentTemplateRequestDto,
} from 'src/types/api/documentTemplates';
import FileUpload, {
  DOCUMENT_TEMPLATE_TYPES,
  FileUploadHandle,
} from 'src/components/Form/FileUpload/FileUpload';
import { FormikProps } from 'formik/dist/types';
import Api from 'src/api';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';

interface Props {
  documentTemplate: DocumentTemplateListResponseDto;
  onSubmit: (request: UpdateDocumentTemplateRequestDto) => Promise<any>;
}

const EditDocumentTemplateModal: React.FC<Props> = ({ documentTemplate, onSubmit }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const [updateDocumentTemplate] = useState<UpdateDocumentTemplateRequestDto>({
    name: documentTemplate.name ?? '',
    description: documentTemplate.description ?? '',
    media_ids: [],
  });

  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);

  const DocumentTemplateSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
    media_ids: Yup.array().max(1),
  });

  const onAction = useCallback(
    async (values: UpdateDocumentTemplateRequestDto, helpers) => {
      try {
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        return onSubmit(values).then(() => {
          success(t('common.updated_success'));
          hideModal();
        });
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [hideModal, onSubmit, t],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'lg'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('common.edit')}
          </ModalHeader>
          <Formik
            innerRef={formRef}
            initialValues={updateDocumentTemplate}
            enableReinitialize={true}
            validationSchema={DocumentTemplateSchema}
            onSubmit={onAction}
          >
            {({ handleSubmit, isSubmitting }) => (
              <>
                <ModalBody className={''}>
                  <Form id={'documentTemplateForm'} onSubmit={handleSubmit}>
                    <FormGroup>
                      <Row>
                        <Col sm={5}>
                          <FileUpload
                            maxFiles={1}
                            accept={DOCUMENT_TEMPLATE_TYPES}
                            ref={fileUploadRef}
                            name={'media_ids'}
                            onPresign={(request) =>
                              Api.documentTemplates.uploadDocuments(documentTemplate.id, request)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                        <Col sm={7}>
                          <div className="mb-3">
                            <TextInput name={'name'} />
                          </div>
                          <div className="mb-3">
                            <TextAreaInput name={'description'} />
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      title={t('common.submit')}
                      submitting={isSubmitting}
                      form={'documentTemplateForm'}
                    />
                  </Col>
                </ModalFooter>
              </>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default EditDocumentTemplateModal;
