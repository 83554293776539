import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { Col, ColProps, FormGroup, Row } from 'reactstrap';
import {
  ProjectSecurityDepositDto,
  UpdateProjectSecuritiesRequestDto,
} from 'src/types/api/projects';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import SingleSecurityDeposit from 'src/pages/Project/Update/Tabs/Securities/SingleSecurityDeposit';
import TextInput from 'src/components/Form/TextInput';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import SaveButton from 'src/components/Form/SaveButton';
import ActiveLocaleFlag from 'src/components/ActiveLocaleFlag';
import { UseNumbers } from 'src/helpers/useNumbers';

const INITIAL_REQUEST: UpdateProjectSecuritiesRequestDto = {
  asset_description: null,
  security_deposits: null,
  security_deposit_appraisers: null,
  security_deposit_value: null,
  hypothec_number: null,
};

const TabSecurities: React.FC<ProjectContextState> = ({ project, setProject, language }) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<UpdateProjectSecuritiesRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!project) return;

    setRequest(() => ({
      hypothec_number: project.hypothec_number,
      asset_description: project.asset_description,
      security_deposits: project.security_deposits,
      security_deposit_appraisers: project.security_deposit_appraisers,
      security_deposit_value: project.security_deposit_value,
    }));
  }, [project]);

  const onSubmit = useCallback(
    async (
      request: UpdateProjectSecuritiesRequestDto,
      helpers: FormikHelpers<UpdateProjectSecuritiesRequestDto>,
    ) => {
      if (!project) return;

      try {
        const response = await Api.projects.storeProjectSecurities(project.id, request);
        setProject(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [project, setProject, t],
  );

  const colProps: ColProps = {
    md: 4,
    className: 'mb-4',
  };

  // Method used to generate common attributes by input name
  const attrs = useCallback(
    (inputName: string) => {
      return {
        name: inputName + '.' + language,
        placeholder: t('label.' + inputName),
      };
    },
    [language, t],
  );

  return (
    <React.Fragment>
      <h3 className={'flex'}>
        {t('projects.securities')}
        <ActiveLocaleFlag locale={language} className={'tabs'} />
      </h3>
      <hr />
      <Formik initialValues={request} enableReinitialize={true} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting, handleChange }) => (
          <Form onSubmit={handleSubmit} onChange={handleChange}>
            <FormGroup>
              <Row>
                <Col {...colProps} md={12}>
                  <WYSIWYGInput {...attrs('asset_description')} />
                </Col>
                <Col {...colProps} md={4}>
                  <TextInput name={'hypothec_number'} />
                </Col>
                <Col {...colProps} md={4}>
                  <TextInput {...attrs('security_deposit_appraisers')} />
                </Col>
                <Col {...colProps} md={4}>
                  <label htmlFor={'security_deposit_value'}>
                    {project?.security_deposits_includes_vat
                      ? t('label.security_deposit_value_vat')
                      : t('label.security_deposit_value_no_vat')}
                  </label>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'security_deposit_value'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                    hideLabel={true}
                  />
                </Col>
                <table className={'table table-sm small'}>
                  <thead>
                    <tr>
                      <th className={'col-2'}>{t('label.real_estate_type')}</th>
                      <th>{t('label.pid')}</th>
                      <th>{t('label.real_estate_unique')}</th>
                      <th>{t('label.real_estate_plot')}</th>
                      <th>{t('label.real_estate_address')}</th>
                      <th>{t('label.real_estate_value')}</th>
                      <th>{t('label.real_estate_appraiser')}</th>
                      <th>{t('label.real_estate_appraised_at')}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <FieldArray name="security_deposits">
                      {(formikArrayHelpers) => {
                        const meta = formikArrayHelpers.form.getFieldMeta<
                          Array<ProjectSecurityDepositDto>
                        >(formikArrayHelpers.name);

                        if (!meta.value) {
                          return <></>;
                        }

                        return meta.value.map((deposit: any, index: any) => {
                          return (
                            <SingleSecurityDeposit
                              namePrefix={`${formikArrayHelpers.name}[${index}]`}
                              formikArrayHelpers={formikArrayHelpers}
                              index={index}
                              key={index}
                            />
                          );
                        });
                      }}
                    </FieldArray>
                  </tbody>
                </table>

                <div className={'mt-4 mb-4'}>
                  <SaveButton title={t('common.save')} submitting={isSubmitting} />
                </div>
              </Row>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withProject(TabSecurities);
