import Client from 'src/api/services/client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  InvoiceListResponseDto,
  StoreInvoiceRequestDto,
  UpdateInvoiceStatusRequestDto,
} from 'src/types/api/payments/invoices';
import { UserCompanyBrief } from 'src/types/api/userCompanies';
import { SignUploadFilesResponseDto, SignUploadRequestDto } from 'src/types/api/common';
import { FetchProjectsBriefResponse } from 'src/types/api/projects';

class Invoices extends Client {
  api = {
    FETCH_FILTERED_INVOICES: `${this.http.baseUrl}/payments/invoices/filter`,
    STORE_INVOICE: `${this.http.baseUrl}/payments/invoices/invoice`,
    UPLOAD_INVOICE_DOCUMENT: `${this.http.baseUrl}/payments/invoices/invoice/document`,
    FETCH_COMPANIES_BRIEF_LIST: `${this.http.baseUrl}/payments/invoices/companies/brief`,
    UPDATE_INVOICE_STATUS: `${this.http.baseUrl}/payments/invoices/invoice/{invoiceId}/update-status`,
    FETCH_COMPANY_PROJECTS_BRIEF_LIST: `${this.http.baseUrl}/payments/invoices/companies/{companyId}/projects/brief`,
  };

  fetchFilteredInvoices = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvoiceListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_INVOICES, { params: request });
  };

  storeInvoice = (request: StoreInvoiceRequestDto): Promise<any> => {
    return this.http.post(this.api.STORE_INVOICE, request);
  };

  updateStatus = (invoiceId: string, request: UpdateInvoiceStatusRequestDto): Promise<any> => {
    const url = this.buildUrl(this.api.UPDATE_INVOICE_STATUS, { invoiceId });
    return this.http.put(url, request);
  };

  uploadDocument = (request: SignUploadRequestDto): Promise<SignUploadFilesResponseDto> => {
    return this.http.put(this.api.UPLOAD_INVOICE_DOCUMENT, request);
  };

  fetchCompaniesBriefList = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UserCompanyBrief>> => {
    return this.http.get(this.api.FETCH_COMPANIES_BRIEF_LIST, { params: request });
  };

  fetchCompanyProjectsBriefList = (
    companyId: string,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<FetchProjectsBriefResponse>> => {
    const url = this.buildUrl(this.api.FETCH_COMPANY_PROJECTS_BRIEF_LIST, { companyId });
    return this.http.get(url, { params: request });
  };
}

export default Invoices;
