import Client from 'src/api/services/client';
import {
  PaymentListResponseDto,
  PaymentResponseDto,
  ProjectLoanFineResponseDto,
  ProjectLoanFinesResponseDto,
  SetProjectPayoutLoanAttributeRequestDto,
  StoreProjectLoanFineRequestDto,
  UpdatePaymentBorrowerPayoutDateRequestDto,
  UpdatePaymentProjectLoanRequestDto,
} from 'src/types/api/payments/projectPayouts';
import { PaginationData, PaginationDataFilter } from 'src/types';

class ProjectPayouts extends Client {
  api = {
    FETCH_FILTERED_PAYMENTS: `${this.http.baseUrl}/payments/project-payouts/filter`,
    FETCH_FILTERED_LOAN_FINES: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/loan-fines/filter`,
    FETCH_PAYMENT: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}`,
    FETCH_LOAN_FINE: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/loan-fines/loan-fine/{loanFineId}`,
    UPDATE_PROJECT_LOAN: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/loan`,
    STORE_LOAN_FINE: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/loan-fines/loan-fine`,
    UPDATE_LOAN_FINE: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/loan-fines/loan-fine/{loanFineId}`,
    DELETE_LOAN_FINE: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/loan-fines/loan-fine/{loanFineId}`,
    UPDATE_BORROWER_PAYOUT_DATE: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/payout/{payoutId}`,
    CONFIRM_BORROWER_PAYOUT_DATES: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/confirm-dates`,
    RECALCULATE_PAYOUTS: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/recalculate-payouts`,
    REGENERATE_PAYOUT_DOCUMENTS: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/regenerate-schedule-docs`,
    SET_ADDITIONAL_LOAN_ATTRIBUTE: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/set-additional-loan-attribute`,
    TRANSFER_FUNDS: `${this.http.baseUrl}/payments/project-payouts/project/{projectId}/transfer-funds`,
  };

  fetchFilteredPayments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<PaymentListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_PAYMENTS, { params: request });
  };

  fetchFilteredLoanFines = (
    projectId: string,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ProjectLoanFinesResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_FILTERED_LOAN_FINES, { projectId });

    return this.http.get(url, { params: request });
  };

  storeLoanFine = (
    projectId: string,
    request: StoreProjectLoanFineRequestDto,
  ): Promise<ProjectLoanFineResponseDto> => {
    const url = this.buildUrl(this.api.STORE_LOAN_FINE, { projectId });

    return this.http.post(url, request);
  };

  fetchLoanFine = (
    projectId: number | string,
    loanFineId: number | string,
  ): Promise<ProjectLoanFineResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_LOAN_FINE, { projectId, loanFineId });

    return this.http.get(url);
  };

  updateProjectLoanFine = (
    projectId: number | string,
    loanFineId: number | string,
    request: StoreProjectLoanFineRequestDto,
  ): Promise<PaymentResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_LOAN_FINE, { projectId, loanFineId });

    return this.http.put(url, request);
  };

  deleteProjectLoanFine = (
    projectId: number | string,
    loanFineId: number | string,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_LOAN_FINE, { projectId, loanFineId });

    return this.http.delete(url);
  };

  fetchPayment = (projectId: string): Promise<PaymentResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_PAYMENT, { projectId });
    return this.http.get(url);
  };

  updateProjectLoan = (
    projectId: number | string,
    request: UpdatePaymentProjectLoanRequestDto,
  ): Promise<PaymentResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_PROJECT_LOAN, { projectId });

    return this.http.put(url, request);
  };

  updateBorrowerPayoutDate = (
    projectId: number | string,
    payoutId: number | string,
    request: UpdatePaymentBorrowerPayoutDateRequestDto,
  ): Promise<PaymentResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_BORROWER_PAYOUT_DATE, { projectId, payoutId });

    return this.http.put(url, request);
  };

  confirmBorrowerPayoutDates = (projectId: number | string): Promise<PaymentResponseDto> => {
    const url = this.buildUrl(this.api.CONFIRM_BORROWER_PAYOUT_DATES, { projectId });

    return this.http.post(url);
  };

  // TODO remove after analytics tested
  recalculatePayouts = (projectId: number | string): Promise<PaymentResponseDto> => {
    const url = this.buildUrl(this.api.RECALCULATE_PAYOUTS, { projectId });

    return this.http.post(url);
  };

  regeneratePayoutDocuments = (projectId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.REGENERATE_PAYOUT_DOCUMENTS, { projectId });

    return this.http.post(url);
  };

  setProjectPayoutAdditionalLoanAttribute = (
    projectId: number | string,
    request: SetProjectPayoutLoanAttributeRequestDto,
  ): Promise<PaymentResponseDto> => {
    const url = this.buildUrl(this.api.SET_ADDITIONAL_LOAN_ATTRIBUTE, { projectId });

    return this.http.post(url, request);
  };

  transferFunds = (projectId: number | string): Promise<PaymentResponseDto> => {
    const url = this.buildUrl(this.api.TRANSFER_FUNDS, { projectId });

    return this.http.post(url);
  };
}

export default ProjectPayouts;
