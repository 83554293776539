import React from 'react';

import AuthRoute from 'src/pages/AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import AutoInvestment from 'src/pages/AutoInvestment/AutoInvestment';

const AutoInvestmentRoutes: any = () => [
  <AuthRoute
    path={RouteList.AUTO_INVESTMENT.VIEW}
    component={AutoInvestment}
    layout={PageLayout}
    permissions={[Permissions.BO__AUTO_INVESTMENT__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.AUTO_INVESTMENT.VIEW}
    exact
  />,
];

export default AutoInvestmentRoutes;
