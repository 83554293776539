import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import Api from 'src/api';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { success } from 'src/services/toastr';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import { ChangeApplicationOwnerRequestDto } from 'src/types/api/applications';
import { SelectAsyncInput } from 'src/components/Form/Select';
import SaveButton from 'src/components/Form/SaveButton';
import DenyButton from 'src/components/Form/DenyButton';
import { formatArray } from 'src/helpers/formatters/common';
import { PaginationDataFilter } from 'src/types';

const ChangeOwnerModal: React.FC<ApplicationContextState> = ({ application, setApplication }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [changeApplicationOwnerRequest] = useState<ChangeApplicationOwnerRequestDto>({
    owner_id: application?.owner?.id ?? '',
  });

  const fetchCompanies = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response = await Api.userCompanies.fetchCompaniesBriefList(request);
    const companies: ReactSelectOption[] = [];

    response.data.map((company) => {
      companies.push({
        value: company.id ?? '',
        label: formatArray([company.private_id, company.name]),
      });
    });

    return companies;
  };

  const onSubmit = useCallback(
    async (
      request: ChangeApplicationOwnerRequestDto,
      helper: FormikHelpers<ChangeApplicationOwnerRequestDto>,
    ) => {
      if (!application) {
        return;
      }

      try {
        const response = await Api.applications.changeOwner(application.id, request);
        success(t('common.updated_success'));
        setApplication(response);
        hideModal();
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }

      return true;
    },
    [application, t, hideModal, setApplication],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('applications.change_owner.title')}
          </ModalHeader>
          <Formik initialValues={changeApplicationOwnerRequest} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <p className={'text-pre'}>{t('applications.change_owner.body')}</p>
                  <SelectAsyncInput
                    name={'owner_id'}
                    isClearable={true}
                    isMulti={false}
                    loadOptions={fetchCompanies}
                  />
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default withApplication(ChangeOwnerModal);
