import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { setGlobalLoading } from 'src/modules/app/actions';
import { NewsListResponseDto, NewsResponseDto } from 'src/types/api/landingPage/news';
import { Cell, Column } from 'react-table';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { generatePath, Link } from 'react-router-dom';
import { RouteList } from 'src/routes';
import { Permissions } from 'src/helpers/auth/permissions';
import PermissionAction from 'src/components/PermissionAction';
import DeleteEntityModal from 'src/components/Modal/Modals/DeleteEntityModal';
import Api from 'src/api';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';
import { getEnumFilterSelectValues } from 'src/helpers/Enums/enumHelper';
import { NewsThemeEnum } from 'src/helpers/Enums/News/NewsThemeEnum';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import i18n from 'src/services/i18n';
import ColumnMultiSelectFilter from 'src/components/Table/Filters/ColumnMultiSelectFilter';

const News: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<NewsResponseDto>>();
  const { showModal } = useGlobalModalContext();
  const [language, setLanguage] = useState<LanguageEnum>(i18n.resolvedLanguage as LanguageEnum);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const INITIAL_SORT_ORDER = [
    {
      id: 'order_number',
      desc: false,
    },
  ];

  const columns = useMemo<Column<NewsListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.picture'),
        accessor: 'picture',
        sortType: 'string',
        width: 100,
        disableSortBy: true,
        Cell: (cell: Cell<NewsListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {row.picture ? <img src={row.picture} alt={'Picture news'} width={100} /> : ''}
            </div>
          );
        },
      },
      {
        Header: t('table.title'),
        accessor: 'title',
        sortType: 'string',
        width: 200,
        disableSortBy: true,
        Cell: (cell: Cell<NewsListResponseDto>) => {
          const row = cell.row.original;
          return <div className={'d-flex gap-2'}>{row.title?.[language]}</div>;
        },
      },
      {
        Header: t('table.slug'),
        accessor: 'slug',
        sortType: 'string',
        width: 200,
        disableSortBy: true,
        Cell: (cell: Cell<NewsListResponseDto>) => {
          const row = cell.row.original;
          return <div className={'d-flex gap-2'}>{row.slug?.[language]}</div>;
        },
      },
      {
        Header: t('table.theme'),
        accessor: 'theme',
        sortType: 'string',
        width: 200,
        disableSortBy: true,
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          options: getEnumFilterSelectValues(NewsThemeEnum, 'news.theme'),
        },
        Cell: (cell: Cell<NewsListResponseDto>) => {
          return t('news.theme.' + cell.value);
        },
      },
      {
        Header: t('table.description'),
        accessor: 'description',
        sortType: 'string',
        width: 150,
        disableSortBy: true,
        Cell: (cell: Cell<NewsListResponseDto>) => {
          const row = cell.row.original;
          return <div className={'d-flex'}>{row.description?.[language]}</div>;
        },
      },
      {
        Header: t('table.published_at'),
        accessor: 'published_at',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<NewsListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.visible_landing_page'),
        accessor: 'visible_landing_page',
        sortType: 'boolean',
        width: 120,
        disableSortBy: true,
        Cell: (cell: Cell<NewsListResponseDto>) => (
          <div>{cell.value ? t('common.yes') : t('common.no')}</div>
        ),
      },
      {
        Header: t('table.order_number'),
        accessor: 'order_number',
        sortType: 'string',
        width: 80,
      },
      {
        Header: t('table.action'),
        width: 100,
        Cell: (cell: Cell<NewsListResponseDto> | any) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                route={generatePath(RouteList.LANDING_PAGE.NEWS.UPDATE, {
                  newsId: row.id,
                })}
                permissions={Permissions.BO__LANDING_PAGE__NEWS__UPDATE}
              />
              <PermissionAction permissions={Permissions.BO__LANDING_PAGE__NEWS__DELETE}>
                <Link
                  to={'#'}
                  title={t('common.delete')}
                  onClick={(e) => {
                    e.preventDefault();
                    showModal(
                      <DeleteEntityModal
                        title={t('pages.delete_news')}
                        body={t('common.delete_generic')}
                        onDelete={() => {
                          return Api.news
                            .deleteNews(row.id)
                            .then(() => cell.dispatch({ type: 'refreshData' }));
                        }}
                      />,
                    );
                  }}
                  className={'link-danger'}
                >
                  <i className={'fas fa-trash-alt'} />
                </Link>
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [language, showModal, t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.news.fetchFilteredNews(request).then((response) => setData(response));
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-3'}>{t('menu.landing_page.news')}</h4>
            <LanguageSwitcher locale={language} setLocale={setLanguage} />
            <HiddenColumnsProvider title={'NewsIndex'}>
              <Table
                title={'NewsIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                disableFiltersOutsideTable={true}
                createComponent={
                  <PermissionAction
                    permissions={Permissions.BO__LANDING_PAGE__EXTERNAL_NEWS__CREATE}
                  >
                    <Link
                      to={RouteList.LANDING_PAGE.NEWS.CREATE}
                      className={'btn btn-primary w-100'}
                    >
                      {t('common.create_new')}
                    </Link>
                  </PermissionAction>
                }
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default News;
