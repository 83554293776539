import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import SanitizedHtmlBlock from 'src/components/SanitizedHtmlBlock';
import { NotificationResponseDto } from 'src/types/api/notifications';
import timeAgo from 'src/helpers/timeAgo';

const Notification: React.FC<NotificationResponseDto> = ({ title, body, cta, created_at }) => {
  const ref = useRef<SimpleBar>();

  return (
    <SimpleBar
      className={'selected-notification'}
      forceVisible={'x'}
      ref={ref as React.LegacyRef<SimpleBar>}
    >
      <h2 className={'notification-title'}>{title}</h2>
      <p className="mb-6 d-flex align-items-center justify-content-center text-muted small">
        <i className={'icon icon-clock align-middle'} />
        {timeAgo(created_at)}
      </p>
      <SanitizedHtmlBlock content={body} className={'ps-4 pe-4'} />
      <div>
        {cta && (
          <div className={'m-4'}>
            {cta.map((c, index) => (
              <Link key={index} className={'btn btn-primary w-25 me-3 mt-3'} to={c.link}>
                {c.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </SimpleBar>
  );
};

export default Notification;
