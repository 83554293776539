import React, { createContext, useContext } from 'react';
import { UserCompanyResponseDto } from 'src/types/api/userCompanies';

export interface CompanyContextState {
  company: UserCompanyResponseDto | undefined;
  setCompany: (company: UserCompanyResponseDto) => void;
}

const CompanyContext = createContext<CompanyContextState>({
  company: undefined,
  setCompany: () => {
    return;
  },
});

export const CompanyProvider: React.FC<CompanyContextState> = ({
  company,
  setCompany,
  children,
}) => {
  return (
    <CompanyContext.Provider value={{ company, setCompany }}>{children}</CompanyContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export function withCompany<P extends CompanyContextState = CompanyContextState>(
  Component: React.ComponentType<P>,
) {
  const WithCompany = ({ ...props }: Optionalize<P, CompanyContextState>) => {
    const c = useContext(CompanyContext);

    return <Component {...c} {...(props as P)} />;
  };

  WithCompany.displayName = `withCompany${Component.displayName}`;

  return WithCompany;
}

export default CompanyProvider;
