import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PaymentContextState,
  withPayment,
} from 'src/pages/Payment/ProjectPayments/Update/PaymentContext';
import { Row } from 'reactstrap';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';

const TabLoanInformation: React.FC<PaymentContextState> = ({ payment }) => {
  const { t } = useTranslation();

  const loan = payment?.project_loan;

  if (!loan) return null;

  // TODO refactor this to accept multiple args
  const sum = (no_gpm: number | null, only_gpm: number | null) => {
    if (no_gpm === null && only_gpm === null) {
      return null;
    }

    let sum = 0;

    if (no_gpm !== null) {
      sum += no_gpm;
    }

    if (only_gpm !== null) {
      sum += only_gpm;
    }

    return sum;
  };

  const weightedInterest =
    (sum(loan?.basic_interest_no_gpm, loan?.basic_interest_only_gpm) ?? 0) +
    (sum(loan?.project_bonus_interest_only_gpm, loan?.project_bonus_interest_no_gpm) ?? 0);

  return (
    <React.Fragment>
      <h3>{t('payments.loan_information')}</h3>
      <hr />
      <Row>
        <SidebarDataRow label={t('label.fine_on_pre_return')} value={loan?.fine_on_pre_return} />
        <SidebarDataRow label={t('label.debt_fee')} value={loan?.debt_fee} />
        <SidebarDataRow
          label={t('label.additional_interest_profitus')}
          value={loan?.additional_interest_profitus}
        />
        <SidebarDataRow
          label={t('label.additional_interest_investment')}
          value={sum(
            loan?.additional_interest_investment_no_gpm,
            loan?.additional_interest_investment_only_gpm,
          )}
          displayValue={`${sum(
            loan?.additional_interest_investment_no_gpm,
            loan?.additional_interest_investment_only_gpm,
          )} %`}
        />
        <SidebarDataRow
          label={t('label.basic_interest')}
          value={sum(loan?.basic_interest_no_gpm, loan?.basic_interest_only_gpm)}
          displayValue={`${sum(loan?.basic_interest_no_gpm, loan?.basic_interest_only_gpm)} %`}
        />
        <SidebarDataRow
          label={t('label.project_bonus_interest')}
          value={sum(loan?.project_bonus_interest_only_gpm, loan?.project_bonus_interest_no_gpm)}
          displayValue={`${sum(
            loan?.project_bonus_interest_only_gpm,
            loan?.project_bonus_interest_no_gpm,
          )} %`}
        />
        <SidebarDataRow
          label={t('label.profitus_bonus_interest')}
          value={sum(loan?.profitus_bonus_interest_no_gpm, loan?.profitus_bonus_interest_only_gpm)}
          displayValue={`${sum(
            loan?.profitus_bonus_interest_no_gpm,
            loan?.profitus_bonus_interest_only_gpm,
          )} %`}
        />
      </Row>
      <Row>
        <SidebarDataRow
          label={t('label.developer_weighted_interest')}
          value={weightedInterest}
          displayValue={`${weightedInterest} %`}
        />
      </Row>
    </React.Fragment>
  );
};

export default withPayment(TabLoanInformation);
