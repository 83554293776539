import React, { useEffect, useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInput from 'src/components/Form/TextInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import { SaveRoleRequestDto } from 'src/types/api/roles';
import SaveButton from 'src/components/Form/SaveButton';

const INITIAL_STATE: SaveRoleRequestDto = {
  name: '',
  description: '',
};

interface RoleFormProps {
  initial?: SaveRoleRequestDto;
  onSubmit: (request: SaveRoleRequestDto, helper: any) => void;
}

const RoleForm: React.FC<RoleFormProps> = ({ initial, onSubmit }) => {
  const { t } = useTranslation();

  const RoleSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
  });

  const [roleRequest, setRoleRequest] = useState<SaveRoleRequestDto>(INITIAL_STATE);

  useEffect(() => {
    if (initial) {
      setRoleRequest(initial);
    }
  }, [initial]);

  return (
    <React.Fragment>
      <Formik
        initialValues={roleRequest}
        enableReinitialize={true}
        validationSchema={RoleSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div className={'mb-3'}>
                <TextInput name={'name'} />
              </div>
              <div className={'mb-3'}>
                <TextAreaInput name={'description'} />
              </div>
              <div className={'mt-4 mb-4'}>
                <SaveButton title={t('common.submit')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default RoleForm;
