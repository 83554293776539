import React from 'react';

import Flag from 'src/components/Flag';
import { useTranslation } from 'react-i18next';

interface LabelProps {
  code: string;
}

const CountryLabel: React.FC<LabelProps> = ({ code }) => {
  const { t } = useTranslation();

  return (
    <div className={'d-flex align-items-center'}>
      <Flag countryCode={code} /> <div className={'ms-2'}>{t('countries.' + code)}</div>
    </div>
  );
};

export default CountryLabel;
