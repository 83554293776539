import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Api from 'src/api';
import { BaseTabProps } from 'src/components/Tabs';
import { Permissions } from 'src/helpers/auth/permissions';
import { UserContextState, withUser } from '../UserContext';
import { success } from 'src/services/toastr';
import PermissionAction from 'src/components/PermissionAction';
import { StoreUserCommentRequestDto, UserCommentResponseDto } from 'src/types/api/user';
import UserCommentForm from '../../UserCommentForm';
import { FormikHelpers } from 'formik';
import ActivityFeedItem from 'src/components/ActivityFeedItem';
import { EditableFieldData } from 'src/components/Table/Fields/EditableFied';
import { LegalEntityClassEnum } from 'src/helpers/Enums/LegalEntityClassEnum';

interface Props extends BaseTabProps, UserContextState {}

const TabComments: React.FC<Props> = ({ id: userId }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<Array<UserCommentResponseDto>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    if (!userId) return Promise.resolve();
    setIsLoading(true);
    return Api.user.fetchUserComments(userId).then((response) => {
      setData(response);
      setIsLoading(false);
    });
  }, [userId]);

  const onSubmit = useCallback(
    async (
      request: StoreUserCommentRequestDto,
      helpers: FormikHelpers<StoreUserCommentRequestDto>,
    ) => {
      try {
        await Api.user.createUserComment(request).then(() => {
          fetchData();
        });
        success(t('common.updated_success'));
        helpers.resetForm();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
      return true;
    },
    [fetchData, t],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!userId) {
    return null;
  }
  return (
    <React.Fragment>
      <PermissionAction permissions={Permissions.BO__USERS__CREATE_COMMENT}>
        <UserCommentForm
          initial={{ user_id: userId, user_type: LegalEntityClassEnum.USER, comment: '' }}
          onSubmit={onSubmit}
        />
      </PermissionAction>
      <PermissionAction permissions={Permissions.BO__USERS__VIEW_COMMENTS}>
        {isLoading && (
          <div className="text-center">
            <div className="spinner-border spinner-border-md" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <ul className="activity-feed mb-0 ps-2">
          {data?.map((item) => {
            return (
              <ActivityFeedItem
                key={item.id}
                data={{
                  id: item.id,
                  date: item.updated_at,
                  author: item.author_name,
                  message: item.comment,
                }}
                permissions={Permissions.BO__USERS__DELETE_COMMENT}
                onDelete={() =>
                  Api.user.deleteUserComment(item.id).then(() => {
                    success(t('common.deleted_success'));
                    fetchData();
                  })
                }
                onUpdate={(fieldData: EditableFieldData) =>
                  Api.user
                    .updateUserComment(item.id, {
                      user_id: item.user_id,
                      user_type: item.user_type,
                      comment: fieldData.value.toString(),
                    })
                    .then(() => {
                      success(t('common.updated_success'));
                    })
                }
              />
            );
          })}
        </ul>
      </PermissionAction>
    </React.Fragment>
  );
};

export default withUser(TabComments);
