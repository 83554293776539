import React from 'react';
import usePermissions from 'src/helpers/usePermissions';

interface Props {
  permissions?: string | string[];
  event: () => void;
}

const EditEntityButtonEvent: React.FC<Props> = ({ permissions, event }) => {
  const p = usePermissions();

  if (permissions && p.cannot(permissions)) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="cursor-pointer">
        <i onClick={event} className={'fas fa-pencil-alt link-primary'} />
      </div>
    </React.Fragment>
  );
};

export default EditEntityButtonEvent;
