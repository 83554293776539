import React, { useCallback, useEffect, useState } from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import { Formik, FormikHelpers } from 'formik';
import { ApplicationFinancialInfoRequestDto } from 'src/types/api/applications';
import { useTranslation } from 'react-i18next';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import * as Yup from 'yup';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import { UseNumbers } from 'src/helpers/useNumbers';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';

interface Props extends BaseTabProps, ApplicationTabProps, ApplicationContextState {}

const INITIAL_REQUEST: ApplicationFinancialInfoRequestDto = {
  amount_of_own_funds: 0,
  area_of_property_on_sale: 0,
  selling_price: 0,
  value_of_parcel_of_land: 0,
  construction_cost: 0,
  project_cost: 0,
  loan_cost: 0,
  total_project_cost: 0,
  total_income: 0,
  project_profit: 0,
};

const TabFinancialInfo: React.FC<Props> = ({ application, setApplication, showSubmit }) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<any>(INITIAL_REQUEST);

  useEffect(() => {
    if (!application) return;

    setRequest({
      amount_of_own_funds: application.financial_info?.amount_of_own_funds,
      area_of_property_on_sale: application.financial_info?.area_of_property_on_sale,
      selling_price: application.financial_info?.selling_price,
      value_of_parcel_of_land: application.financial_info?.value_of_parcel_of_land,
      construction_cost: application.financial_info?.construction_cost,
      project_cost: application.financial_info?.project_cost,
      loan_cost: application.financial_info?.loan_cost,
      total_project_cost: application.financial_info?.total_project_cost,
      total_income: application.financial_info?.total_income,
      project_profit: application.financial_info?.project_profit,
    });
  }, [application]);

  const FinancialInfoSchema = Yup.object().shape({});

  const onSubmit = useCallback(
    async (
      request: ApplicationFinancialInfoRequestDto,
      helpers: FormikHelpers<ApplicationFinancialInfoRequestDto>,
    ) => {
      if (!application) return;
      try {
        const response = await Api.applications.UpdateFinancialInfo(application.id, request);
        setApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
      return true;
    },
    [application, setApplication, t],
  );

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.financial_info')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton
              title={t('applications.tabs_submit.financial_info')}
              tabName={'financial_info'}
            />
          </div>
        )}
      </div>
      <hr />
      <Formik
        initialValues={request}
        enableReinitialize={true}
        validationSchema={FinancialInfoSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col md={6} className={'mb-4'}>
                  <TextInput
                    type={'number'}
                    name={'amount_of_own_funds'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col md={6} className={'mb-4'}>
                  <TextInput
                    type={'number'}
                    name={'area_of_property_on_sale'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col md={6} className={'mb-4'}>
                  <TextInput
                    type={'number'}
                    name={'selling_price'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col md={6} className={'mb-4'}>
                  <TextInput
                    type={'number'}
                    name={'value_of_parcel_of_land'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col md={6} className={'mb-4'}>
                  <TextInput
                    type={'number'}
                    name={'construction_cost'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col md={6} className={'mb-4'}>
                  <TextInput
                    type={'number'}
                    name={'project_cost'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col md={6} className={'mb-4'}>
                  <TextInput
                    type={'number'}
                    name={'loan_cost'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col md={6} className={'mb-4'}>
                  <TextInput
                    type={'number'}
                    name={'total_project_cost'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col md={6} className={'mb-4'}>
                  <TextInput
                    type={'number'}
                    name={'total_income'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col md={6} className={'mb-4'}>
                  <TextInput
                    type={'number'}
                    name={'project_profit'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
              </Row>
              <div className={'mt-4 mb-4'}>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withApplication(TabFinancialInfo);
