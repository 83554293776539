import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';

interface Props {
  name: string;
  wrapperClass: string;
}

const FormikField: React.FC<Props> = ({ wrapperClass, name, children }) => {
  const [, meta] = useField(name);

  const { submitCount } = useFormikContext();
  const [isError, setError] = useState<boolean>(false);
  useEffect(() => {
    setError(submitCount > 0 && !!meta.error);
  }, [meta.error, submitCount]);

  return (
    <>
      <div className={`${wrapperClass} ${isError ? 'is-invalid' : ''}`}>{children}</div>
      {isError && <div className={'invalid-feedback'}>{meta.error}</div>}
    </>
  );
};

export default FormikField;
