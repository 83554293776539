import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import SaveButton from 'src/components/Form/SaveButton';
import {
  AffiliateInvestmentTransactionsReportFilterForm,
  GenerateReportRequestDto,
} from 'src/types/api/reports';
import { appendDateFilter, appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import { Permissions } from 'src/helpers/auth/permissions';
import TextInput from 'src/components/Form/TextInput';
import { SelectInput } from 'src/components/Form/Select';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { AffiliateAmbassadorTypeEnum } from 'src/helpers/Enums/AffiliateAmbassadorTypeEnum';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { addMonths, endOfMonth, formatRFC3339 } from 'date-fns';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';

const AVAILABLE_COLUMNS: string[] = [
  'type',
  'user_id',
  'referrer',
  'new_user_id',
  'new_user_name',
  'new_user_last_name',
  'transaction_amount',
  'transaction_year',
  'transaction_month',
  'transaction_day',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.AFFILIATE_INVESTMENT_TRANSACTIONS;

const AffiliateInvestmentTransactionsReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [showTitleFilter, setShowTitleFilter] = useState<boolean>(true);

  const [request] = useState<AffiliateInvestmentTransactionsReportFilterForm>({
    interval_at: null,
    created_at_from: null,
    created_at_to: null,
    type: null,
    ambassador_key: null,
    name: null,
    title: null,
    columns: AVAILABLE_COLUMNS,
  });

  const [typeOptions] = useState<ReactSelectOption[]>([
    ...Object.values(AffiliateAmbassadorTypeEnum).map((type) => ({
      value: type,
      label: t('users.affiliate_ambassador_type.' + type),
    })),
  ]);

  const onSubmit = useCallback(
    async (
      request: AffiliateInvestmentTransactionsReportFilterForm,
      helper: FormikHelpers<AffiliateInvestmentTransactionsReportFilterForm>,
    ) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  const mapData = (
    form: AffiliateInvestmentTransactionsReportFilterForm,
  ): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendDateFilter(request, 'created_at', form.created_at_from, form.created_at_to);
    appendFilter(request, 'type', form.type);
    appendFilter(request, 'ambassador_key', form.ambassador_key);
    appendFilter(request, 'ambassador_key', form.ambassador_key);
    if (form.type === AffiliateAmbassadorTypeEnum.COMPANY) {
      appendFilter(request, 'title', form.title);
    }

    return request;
  };

  if (!p.hasAll([mapManagePermission(TYPE), Permissions.BO__REPORTS__GENERATE])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <DatePickerInput
                          name={'interval_at'}
                          showQuarterYearPicker={true}
                          dateFormat={'yyyy, QQQ'}
                          onChangeAction={(option: any) => {
                            const q1Start = option ? formatRFC3339(option) : undefined;
                            const q2End = option
                              ? formatRFC3339(endOfMonth(addMonths(option, 2)))
                              : undefined;
                            setFieldValue('created_at_from', q1Start);
                            setFieldValue('created_at_to', q2End);
                          }}
                        />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <DatePickerInput
                          name={'created_at_from'}
                          showTimeSelect
                          matchFieldValueAndDisplayValue={true}
                        />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <DatePickerInput
                          name={'created_at_to'}
                          showTimeSelect
                          matchFieldValueAndDisplayValue={true}
                        />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'ambassador_key'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput
                          placeholder={t('label.type')}
                          name={'type'}
                          options={typeOptions}
                          isClearable={true}
                          onChangeAction={(option: any) => {
                            setShowTitleFilter(
                              !(option && option.value === AffiliateAmbassadorTypeEnum.COMPANY),
                            );
                          }}
                        />
                      </Col>
                      <Col hidden={showTitleFilter} sm={2} className={'mb-4'}>
                        <TextInput name={'title'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default AffiliateInvestmentTransactionsReportFilter;
