import React, { useCallback, useEffect, useState } from 'react';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import { Col, ColProps, Form, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { StoreProtocolSalesManagerRequestDto } from 'src/types/api/applications';
import { useTranslation } from 'react-i18next';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import SaveButton from 'src/components/Form/SaveButton';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import InvestmentBonusInterest from 'src/pages/Application/Update/Tabs/Protocol/InvestmentBonusInterest';
import { transformErrors } from 'src/helpers';
import { UseNumbers } from 'src/helpers/useNumbers';
import { BonusInterestRequestDto } from 'src/types/api/common';

interface Props extends ApplicationTabProps, ApplicationContextState {}

const INITIAL_INVESTMENT_BONUS: BonusInterestRequestDto = {
  amount: 0,
  bonus_interest: 0,
};

const INITIAL_REQUEST: StoreProtocolSalesManagerRequestDto = {
  days_to_get_money: 21,
  early_return_rules: '',
  interest_rate_offer: null,
  ltv_external: null,
  reason_to_lend_description: '',
  risks_description: '',
  total_planned_credit: null,
  increased_interest: 10,
  exceptional_investment_conditions_description: null,
  bonus_interests: null,
};

const ProtocolSalesManager: React.FC<Props> = ({ application, setApplication, showSubmit }) => {
  const { t } = useTranslation();
  const [request, setRequest] = useState<StoreProtocolSalesManagerRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!application?.protocol) return;

    setRequest({
      days_to_get_money: application.protocol.days_to_get_money,
      early_return_rules: application.protocol.early_return_rules,
      interest_rate_offer: application.protocol.interest_rate_offer,
      ltv_external: application.protocol.ltv_external,
      reason_to_lend_description: application.protocol.reason_to_lend_description,
      risks_description: application.protocol.risks_description,
      total_planned_credit: application.protocol.total_planned_credit,
      increased_interest: application.protocol.increased_interest,
      exceptional_investment_conditions_description:
        application.protocol.exceptional_investment_conditions_description,
      bonus_interests: application.bonus_interests,
    });
  }, [application?.protocol, application?.bonus_interests]);

  const ProtocolSchema = Yup.object().shape({
    // email: Yup.string().required().email(),
  });

  const onSubmit = useCallback(
    async (request: any, helpers: FormikHelpers<StoreProtocolSalesManagerRequestDto>) => {
      if (!application) return;

      try {
        const response = await Api.applications.storeProtocolSalesManager(application.id, request);
        setApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [application, setApplication, t],
  );

  const colProps: ColProps = {
    sm: 3,
    className: 'mb-4',
  };

  const colPropsHalf: ColProps = {
    sm: 6,
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.protocol.sales_manager')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton title={t('applications.tabs_submit.protocol')} tabName={'protocol'} />
          </div>
        )}
      </div>
      <hr />
      <Formik
        initialValues={request}
        enableReinitialize={true}
        validationSchema={ProtocolSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'total_planned_credit'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'ltv_external'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'increased_interest'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'interest_rate_offer'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'days_to_get_money'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col {...colPropsHalf}>
                  <WYSIWYGInput name={'risks_description'} />
                </Col>
                <Col {...colPropsHalf}>
                  <WYSIWYGInput name={'reason_to_lend_description'} />
                </Col>
                <Col {...colPropsHalf}>
                  <TextAreaInput name={'early_return_rules'} />
                </Col>
                <Col {...colPropsHalf}>
                  <TextAreaInput name={'exceptional_investment_conditions_description'} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FieldArray name="bonus_interests">
                    {(formikArrayHelpers) => (
                      <>
                        <h3>{t('label.bonus_interests')}</h3>
                        <PrimaryButton
                          title={t('common.add')}
                          type={'button'}
                          className={'btn btn-success'}
                          submitting={isSubmitting}
                          onClick={() => formikArrayHelpers.push(INITIAL_INVESTMENT_BONUS)}
                        />
                        <table className={'table table-sm small'}>
                          <thead>
                            <tr>
                              <th className={'col-2'}>{t('label.bonus_amount')}</th>
                              <th className={'col-2'}>{t('label.bonus_interest')}</th>
                              <th className={'col-1'} />
                            </tr>
                          </thead>
                          <tbody>
                            {values.bonus_interests
                              ? values.bonus_interests.map((bonus, index) => {
                                  return (
                                    <InvestmentBonusInterest
                                      formikArrayHelpers={formikArrayHelpers}
                                      index={index}
                                      key={index}
                                    />
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </>
                    )}
                  </FieldArray>
                </Col>
              </Row>
              <div className={'mt-4 mb-4'}>
                <SaveButton
                  disabled={!!application?.protocol?.submitted_sales_manager_at}
                  title={application?.protocol ? t('common.save') : t('common.create')}
                  submitting={isSubmitting}
                />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withApplication(ProtocolSalesManager);
