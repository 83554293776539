import Client from 'src/api/services/client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  FetchFilterAutoInvestmentDraftQueueRequest,
  FetchProjectAutoInvestmentDraftQueueResponse,
} from 'src/types/api/autoInvestment';
import { FetchProjectsBriefResponse } from 'src/types/api/projects';

class AutoInvestment extends Client {
  api = {
    FETCH_PROJECT_DRAFT_QUEUE: `${this.http.baseUrl}/auto-investment/projects/{projectId}/draft-queue`,
    FETCH_FILTER_DRAFT_QUEUE: `${this.http.baseUrl}/auto-investment/filter/draft-queue`,
    FETCH_PROJECTS_BRIEF: `${this.http.baseUrl}/auto-investment/projects/brief`,
  };

  fetchAutoInvestmentProjectDraftQueue = (
    projectId: string | number,
  ): Promise<FetchProjectAutoInvestmentDraftQueueResponse> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT_DRAFT_QUEUE, { projectId });

    return this.http.get(url);
  };

  fetchAutoInvestmentFilterDraftQueue = (
    request: FetchFilterAutoInvestmentDraftQueueRequest,
  ): Promise<FetchProjectAutoInvestmentDraftQueueResponse> => {
    return this.http.get(this.api.FETCH_FILTER_DRAFT_QUEUE, { params: request });
  };

  fetchProjectsBriefList = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<FetchProjectsBriefResponse>> => {
    return this.http.get(this.api.FETCH_PROJECTS_BRIEF, { params: request });
  };
}

export default AutoInvestment;
