import React, { useCallback } from 'react';
import { Card, CardBody, Form, FormGroup } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import Api from 'src/api';
import {
  CurrentUserResponseDto,
  UpdateCurrentUserPasswordRequestDto,
} from 'src/types/api/currentUser';
import PasswordInput from 'src/components/Form/PasswordInput';
import SaveButton from 'src/components/Form/SaveButton';
import { success } from 'src/services/toastr';

interface Props {
  user: CurrentUserResponseDto;
}

const INITIAL_STATE: UpdateCurrentUserPasswordRequestDto = {
  password: '',
  password_confirmation: '',
};

const PasswordCard: React.FC<Props> = () => {
  const { t } = useTranslation();

  const ProfileSchema = Yup.object().shape({
    password: Yup.string().password().required(),
    password_confirmation: Yup.string().passwordMatch('password').required(),
  });

  const onSubmit = useCallback(
    async (request: UpdateCurrentUserPasswordRequestDto, helpers: FormikHelpers<any>) => {
      try {
        await Api.currentUser.updateUserPassword(request).then(() => {
          success(t('common.updated_success'));
          helpers.resetForm();
        });
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [t],
  );

  return (
    <Card>
      <CardBody>
        <h4 className={'mb-4'}>{t('label.password')}</h4>
        <Formik
          initialValues={INITIAL_STATE}
          enableReinitialize={true}
          validationSchema={ProfileSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <div className={'mb-3'}>
                  <PasswordInput name={'password'} placeholder={t('label.password')} />
                </div>
                <div className={'mb-3'}>
                  <PasswordInput
                    name={'password_confirmation'}
                    placeholder={t('label.password_confirmation')}
                  />
                </div>
                <div className={'mb-3'}>
                  <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default PasswordCard;
