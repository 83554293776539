import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import CommunicationManagement from 'src/pages/Communication/Management/View';
import CustomHistoryList from 'src/pages/Communication/CustomHistory/List';
import AutoHistoryList from 'src/pages/Communication/AutoHistory/List';
import CustomEmailCreate from 'src/pages/Communication/CustomEmailList/Create';
import CustomEmailHistoryList from 'src/pages/Communication/CustomEmailList/List';
import { Permissions } from 'src/helpers/auth/permissions';

const CommunicationRoutes: any = () => [
  <AuthRoute
    path={RouteList.COMMUNICATION.MANAGEMENT}
    component={CommunicationManagement}
    layout={PageLayout}
    permissions={[Permissions.BO__COMMUNICATION__MANAGEMENT__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.COMMUNICATION.MANAGEMENT}
    exact
  />,
  <AuthRoute
    path={RouteList.COMMUNICATION.CUSTOM_HISTORY}
    component={CustomHistoryList}
    layout={PageLayout}
    permissions={[Permissions.BO__COMMUNICATION__CUSTOM_HISTORY__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.COMMUNICATION.CUSTOM_HISTORY}
    exact
  />,
  <AuthRoute
    path={RouteList.COMMUNICATION.AUTO_HISTORY}
    component={AutoHistoryList}
    layout={PageLayout}
    permissions={[Permissions.BO__COMMUNICATION__AUTO_HISTORY__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.COMMUNICATION.AUTO_HISTORY}
    exact
  />,
  <AuthRoute
    path={RouteList.COMMUNICATION.CUSTOM_EMAIL.CREATE}
    component={CustomEmailCreate}
    layout={PageLayout}
    permissions={[Permissions.BO__COMMUNICATION__MANAGEMENT__NOTIFY]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.COMMUNICATION.CUSTOM_EMAIL.CREATE}
    exact
  />,
  <AuthRoute
    path={RouteList.COMMUNICATION.CUSTOM_EMAIL.LIST}
    component={CustomEmailHistoryList}
    layout={PageLayout}
    permissions={[Permissions.BO__COMMUNICATION__MANAGEMENT__NOTIFY]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.COMMUNICATION.CUSTOM_EMAIL.LIST}
    exact
  />,
];

export default CommunicationRoutes;
