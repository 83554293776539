import React, { useCallback, useEffect, useState } from 'react';
import { FormikHelpers } from 'formik';
import { Col, FormGroup, Row } from 'reactstrap';
import SaveButton from 'src/components/Form/SaveButton';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SetProjectPayoutLoanAttributeRequestDto } from 'src/types/api/payments/projectPayouts';
import Api from 'src/api';
import { defaultTranslatableValue, transformErrors, translatableValueSchema } from 'src/helpers';
import * as Yup from 'yup';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import { success } from 'src/services/toastr';
import LocaleFormik from 'src/components/Form/LocaleFormik/LocaleFormik';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import {
  PaymentContextState,
  withPayment,
} from 'src/pages/Payment/ProjectPayments/Update/PaymentContext';
import { ProjectLoanStatusEnum } from 'src/helpers/Enums/Project/ProjectLoanStatusEnum';

const AdditionalLoanAttribute: React.FC<PaymentContextState> = ({ payment, setPayment }) => {
  const { t } = useTranslation();

  const { projectId } = useParams<{ projectId: string }>();

  const [initialValues, setInitialValues] = useState<SetProjectPayoutLoanAttributeRequestDto>();

  const onSubmit = useCallback(
    async (
      request: SetProjectPayoutLoanAttributeRequestDto,
      helpers: FormikHelpers<SetProjectPayoutLoanAttributeRequestDto>,
    ) => {
      try {
        const res = await Api.payments.projectPayouts.setProjectPayoutAdditionalLoanAttribute(
          projectId,
          request,
        );

        setPayment(res);

        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [projectId, setPayment, t],
  );

  const DataSchema = Yup.object().shape({
    purpose: translatableValueSchema(Yup.string().required()),
    status: Yup.string().required(),
  });

  const [options] = useState<ReactSelectOption[]>([
    {
      label: t('payments.status.other'),
      value: ProjectLoanStatusEnum.OTHER,
    },
    {
      label: t('payments.status.recovering'),
      value: ProjectLoanStatusEnum.RECOVERING,
    },
  ]);

  useEffect(() => {
    if (payment) {
      setInitialValues({
        purpose:
          payment && payment.project_loan && payment.project_loan.latest_status_reason
            ? payment.project_loan.latest_status_reason
            : defaultTranslatableValue(),
        status:
          payment && payment.project_loan && payment.project_loan.status
            ? payment.project_loan.status
            : ProjectLoanStatusEnum.OTHER,
      });
    }
  }, [payment, setInitialValues]);

  return (
    <React.Fragment>
      <h3>{t('payments.additional_loan_attribute')}</h3>
      <hr />
      {initialValues && (
        <LocaleFormik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={DataSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, locale, setLocale, multiLocaleAttrs }) => (
            <>
              <LanguageSwitcher locale={locale} setLocale={setLocale} />
              <FormGroup>
                <Row>
                  <Col sm={12} className={'mb-4'}>
                    <SelectInput name={'status'} options={options} />
                  </Col>
                  <Col sm={12} className={'mb-4'}>
                    <TextAreaInput {...multiLocaleAttrs('purpose')} />
                  </Col>
                </Row>
                <div className={'mt-4 mb-4'}>
                  <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                </div>
              </FormGroup>
            </>
          )}
        </LocaleFormik>
      )}
    </React.Fragment>
  );
};

export default withPayment(AdditionalLoanAttribute);
