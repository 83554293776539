import Client from 'src/api/services/client';
import { EstimateSinglePayoutRequestDto } from 'src/types/api/payments/borrowerPayouts';

class Estimates extends Client {
  api = {
    ESTIMATE_PAYOUT: `${this.http.baseUrl}/payments/borrower-payouts/estimate-payout`,
  };

  estimatePayout = (request: EstimateSinglePayoutRequestDto): Promise<any> => {
    return this.http.post(this.api.ESTIMATE_PAYOUT, request);
  };
}

export default Estimates;
