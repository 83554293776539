import React from 'react';
import { useTranslation } from 'react-i18next';

import TextInput from 'src/components/Form/TextInput';
import { ArrayHelpers } from 'formik/dist/FieldArray';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { UseNumbers } from 'src/helpers/useNumbers';

const InvestmentBonusInterest: React.FC<{
  index: number;
  formikArrayHelpers: ArrayHelpers;
}> = ({ index, formikArrayHelpers }) => {
  const { t } = useTranslation();
  const bonusAmount = `bonus_interests[${index}].amount`;
  const bonusInterest = `bonus_interests[${index}].bonus_interest`;

  const deleteItem = () => {
    formikArrayHelpers.remove(index);
  };

  return (
    <>
      <tr>
        <td>
          <TextInput
            type={'number'}
            step={'any'}
            name={bonusAmount}
            placeholder={t('label.bonus_amount')}
            hideLabel={true}
            onKeyPress={UseNumbers.preventNonNumericalInput}
          />
        </td>
        <td>
          <TextInput
            type={'number'}
            step={'any'}
            name={bonusInterest}
            placeholder={t('label.bonus_interest')}
            hideLabel={true}
            onKeyPress={UseNumbers.preventNonNumericalInput}
          />
        </td>
        <td className="text-end">
          <PrimaryButton
            type={'button'}
            className={'btn btn-danger'}
            title={t('common.delete')}
            style={{ backgroundColor: '#f46a6a' }}
            onClick={() => deleteItem()}
          />
        </td>
      </tr>
    </>
  );
};

export default InvestmentBonusInterest;
