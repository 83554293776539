import Client from './client';
import {
  CurrentUserPermissionsResponseDto,
  CurrentUserResponseDto,
  RefreshTokenResponseDto,
  UpdateCurrentUserPasswordRequestDto,
} from 'src/types/api/currentUser';

class CurrentUser extends Client {
  api = {
    CURRENT_USER: `${this.http.baseUrl}/user/current`,
    CURRENT_USER_PERMISSIONS: `${this.http.baseUrl}/user/permissions`,
    REFRESH_TOKEN: `${this.http.baseUrl}/user/refresh`,
    LOGOUT: `${this.http.baseUrl}/user/logout`,
    UPDATE_USER_PASSWORD: `${this.http.baseUrl}/user/current/password`,
  };

  fetchUser = (): Promise<CurrentUserResponseDto> => {
    return this.http.get(this.api.CURRENT_USER);
  };

  fetchPermissions = (): Promise<CurrentUserPermissionsResponseDto> => {
    return this.http.get(this.api.CURRENT_USER_PERMISSIONS);
  };

  refreshToken = (): Promise<RefreshTokenResponseDto> => {
    return this.http.put(this.api.REFRESH_TOKEN);
  };

  logout = (): Promise<any> => {
    return this.http.post(this.api.LOGOUT);
  };

  updateUserPassword = (request: UpdateCurrentUserPasswordRequestDto): Promise<any> => {
    return this.http.post(this.api.UPDATE_USER_PASSWORD, request);
  };
}

export default CurrentUser;
