import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from 'src/types';

interface Props {
  type: UserType | string | undefined;
}

const UserTypeView: React.FC<Props> = ({ type }) => {
  const [userType, setUserType] = useState('---');
  const { t } = useTranslation();

  useEffect(() => {
    if (type === UserType.Investor) {
      setUserType(t('common.type_investor'));
    } else if (type === UserType.ProjectDeveloper) {
      setUserType(t('common.type_project_developer'));
    }
  }, [t, type]);

  return <span>{userType}</span>;
};

export default UserTypeView;
