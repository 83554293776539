import React, { useCallback, useState } from 'react';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormGroup, ModalBody, ModalHeader } from 'reactstrap';

import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import {
  ProjectArticleResponse,
  ProjectResponseDto,
  UpdateProjectArticleRequest,
} from 'src/types/api/projects';
import Api from 'src/api';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import TextInput from 'src/components/Form/TextInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { success } from 'src/services/toastr';
import { ProjectArticleCreateSchema } from 'src/pages/Project/Update/Schemas/ProjectArticleSchema';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import SaveButton from 'src/components/Form/SaveButton';
import LocaleFormik from 'src/components/Form/LocaleFormik/LocaleFormik';
import { transformErrors } from 'src/helpers';

interface Props {
  projectId: string;
  articleId: string;
  article: ProjectArticleResponse;
  setProject: (project: ProjectResponseDto) => void;
}

const NewsArticleEditModal: React.FC<Props> = ({ projectId, articleId, article, setProject }) => {
  const { hideModal } = useGlobalModalContext();
  const [request] = useState<UpdateProjectArticleRequest>({
    value: article.value,
    published_at: article.published_at,
    title: article.title,
  });
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (request, helpers: FormikHelpers<UpdateProjectArticleRequest>) => {
      try {
        const response = await Api.projects.updateArticle(projectId, articleId, request);
        setProject(response);
        hideModal();
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [projectId, articleId, setProject, hideModal, t],
  );

  return (
    <ModalComponent size={'lg'}>
      <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
        {t('projects.articles.edit')}
      </ModalHeader>
      <ModalBody>
        <LocaleFormik
          initialValues={request}
          enableReinitialize={true}
          onSubmit={onSubmit}
          validationSchema={ProjectArticleCreateSchema()}
        >
          {({ isSubmitting, locale, setLocale, multiLocaleAttrs }) => (
            <>
              <LanguageSwitcher locale={locale} setLocale={setLocale} />
              <FormGroup>
                <TextInput {...multiLocaleAttrs('title')} />
              </FormGroup>
              <FormGroup>
                <TextAreaInput
                  style={{ height: '150px' }}
                  {...multiLocaleAttrs('value')}
                  placeholder={t('label.content')}
                />
              </FormGroup>
              <FormGroup>
                <DatePickerInput name={'published_at'} showTimeSelect={true} />
              </FormGroup>
              <FormGroup>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </FormGroup>
            </>
          )}
        </LocaleFormik>
      </ModalBody>
    </ModalComponent>
  );
};

export default NewsArticleEditModal;
