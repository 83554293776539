import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  onEdit: () => void;
}

const EditButton: React.FC<Props> = ({ onEdit, className, type, onClick, ...props }) => {
  const { t } = useTranslation();

  return (
    <button
      className={`btn btn-warning ` + className}
      type={type ?? 'button'}
      onClick={onClick ?? onEdit}
      {...props}
    >
      {t('common.edit')}
    </button>
  );
};

export default EditButton;
