export enum BorrowerPayoutStatusEnum {
  INIT = 'init',
  NEW = 'new',
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  PENDING = 'pending',
  RECEIVED = 'received',
  PERMISSION_TO_SELL = 'permission_to_sell',
  PAID = 'paid',
}
