import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { WalletProviderEnum } from 'src/helpers/Enums/Payments/WalletProviderEnum';

interface Props {
  value: WalletProviderEnum | null;
}

const WalletProviderBadge: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const [color, setColor] = useState<string>('#bc3e55');

  useEffect(() => {
    switch (value) {
      case WalletProviderEnum.MOCK:
        setColor('#514f44');
        break;
      case WalletProviderEnum.PAYSERA:
        setColor('#f47424');
        break;
      case WalletProviderEnum.TRUSTLY:
        setColor('#0ce36b');
        break;
      case WalletProviderEnum.LEMONWAY:
        setColor('#2c3c64');
        break;
      default:
        setColor('#bc3e55');
    }
  }, [value]);

  return (
    <Badge style={{ backgroundColor: color }} color={''}>
      {value ? t('wallet.provider.' + value) : 'No wallet'}
    </Badge>
  );
};

export default WalletProviderBadge;
