import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { setGlobalLoading } from 'src/modules/app/actions';
import Table from 'src/components/Table';
import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { Permissions } from 'src/helpers/auth/permissions';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import PermissionAction from 'src/components/PermissionAction';
import StoreApplicationModal from 'src/pages/Application/List/StoreApplicationModal';
import ControlDropdown from 'src/pages/Application/List/ControlDropdown';
import { RouteList } from 'src/routes';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { ApplicationListResponseDto } from 'src/types/api/applications';
import { ApplicationStatus } from 'src/types/app/applications';
import { getEnumFilterSelectValues } from 'src/helpers/Enums/enumHelper';
import ColumnMultiSelectFilter from 'src/components/Table/Filters/ColumnMultiSelectFilter';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const ApplicationList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<ApplicationListResponseDto>>();
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<ApplicationListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'pid',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.project_name'),
        accessor: 'project_name',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.company_name'),
        accessor: 'company_name',
        sortType: 'string',
        width: 200,
        disableSortBy: true,
      },
      {
        Header: t('table.manager_name'),
        accessor: 'manager_name',
        sortType: 'string',
        width: 300,
        disableSortBy: true,
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          request: Api.user.fetchManagersOptions(),
        },
      },
      {
        Header: t('table.project_amount'),
        accessor: 'amount',
        Cell: (cell: Cell<ApplicationListResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        width: 300,
        disableSortBy: true,
        Cell: (cell: Cell<ApplicationListResponseDto>) => {
          return t('applications.status.' + cell.value);
        },
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          options: getEnumFilterSelectValues(ApplicationStatus, 'applications.status'),
        },
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<ApplicationListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        Cell: (cell: Cell<ApplicationListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                permissions={Permissions.BO__APPLICATIONS__VIEW}
                route={generatePath(RouteList.APPLICATION.VIEW, { applicationId: row.id })}
              />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const showStoreApplicationModal = useCallback(() => {
    showModal(<StoreApplicationModal />);
  }, [showModal]);

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.applications
      .fetchFilteredApplications(request)
      .then((response) => setData(response));
  }, []);

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'ApplicationListIndex'}>
        <Container fluid>
          <Card>
            <CardBody>
              <div className={'d-flex align-items-center mb-4'}>
                <h4 className={'m-0'}>{t('menu.applications')}</h4>
                <ControlDropdown className={'ms-auto'} />
              </div>
              <Table
                title={'ApplicationListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                disableFiltersOutsideTable={true}
                initialSortBy={INITIAL_SORT_ORDER}
                createComponent={
                  <PermissionAction permissions={Permissions.BO__APPLICATIONS__CREATE}>
                    <button onClick={showStoreApplicationModal} className={'btn btn-primary w-100'}>
                      {t('common.create_new')}
                    </button>
                  </PermissionAction>
                }
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default ApplicationList;
