import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { CardImg, Form, FormGroup, Col, ColProps } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInput from 'src/components/Form/TextInput';
import {
  SaveTeamMemberRequestDto,
  TeamMemberResponseDto,
} from 'src/types/api/landingPage/teamMembers';
import FileUpload, {
  FileUploadHandle,
  IMAGE_TYPES,
} from 'src/components/Form/FileUpload/FileUpload';
import Api from 'src/api';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { UseNumbers } from 'src/helpers/useNumbers';
import { defaultTranslatableValue } from 'src/helpers';

const INITIAL_STATE: SaveTeamMemberRequestDto = {
  name: '',
  surname: '',
  linkedin_url: '',
  responsibilities: defaultTranslatableValue(),
  order_number: null,
  media_ids: [],
};

interface TeamMembersFormProps {
  teamMember?: TeamMemberResponseDto;
  onSubmit: (request: SaveTeamMemberRequestDto, helper: any) => void;
  uploadRef: RefObject<FileUploadHandle> | null;
}

const TeamMemberForm: React.FC<TeamMembersFormProps> = ({ teamMember, onSubmit, uploadRef }) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.LT);

  const TeamMemberSchema = Yup.object().shape({
    name: Yup.string().required().max(255),
    surname: Yup.string().required().max(255),
    linkedin_url: Yup.string().required().max(255),
    order_number: Yup.number().nullable().max(10000),
    responsibilities: Yup.object().shape({
      ee: Yup.string().nullable(),
      de: Yup.string().nullable(),
      lt: Yup.string().nullable(),
      en: Yup.string().nullable(),
    }),
    media_ids: Yup.array().required().max(1),
  });

  const [teamMemberRequest, setTeamMemberRequest] =
    useState<SaveTeamMemberRequestDto>(INITIAL_STATE);

  useEffect(() => {
    if (teamMember) {
      setTeamMemberRequest({ ...teamMember, media_ids: [] });
    }
  }, [teamMember]);

  const multiLocaleAttrs = useCallback(
    (inputName: string) => {
      return {
        name: inputName + '.' + language,
        placeholder: t('label.' + inputName),
      };
    },
    [language, t],
  );

  const colProps: ColProps = {
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <LanguageSwitcher locale={language} setLocale={setLanguage} />
      <Formik
        initialValues={teamMemberRequest}
        enableReinitialize={true}
        validationSchema={TeamMemberSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Col {...colProps}>
                <TextInput type={'text'} name={'name'} />
              </Col>
              <Col {...colProps}>
                <TextInput type={'text'} name={'surname'} />
              </Col>
              <Col {...colProps}>
                <TextInput name={'linkedin_url'} />
              </Col>
              <Col {...colProps}>
                <TextInput {...multiLocaleAttrs('responsibilities')} />
              </Col>
              <Col {...colProps}>
                <TextInput
                  name={'order_number'}
                  step={'1'}
                  type={'number'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
              <Col {...colProps}>
                <FileUpload
                  maxFiles={1}
                  accept={IMAGE_TYPES}
                  ref={uploadRef}
                  name={'media_ids'}
                  onPresign={(request) => Api.teamMembers.uploadPicture(request)}
                  disabled={isSubmitting}
                />
                {teamMember?.picture && (
                  <div className="row">
                    <CardImg
                      top
                      src={teamMember.picture}
                      className={'fit-image placeholder-glow'}
                      style={{ height: '400px', width: '400px' }}
                    />
                  </div>
                )}
              </Col>
              <Col {...colProps}>
                <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
              </Col>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default TeamMemberForm;
