import React, { useState } from 'react';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import * as Flags from 'country-flag-icons/string/3x2';
import FlagIcon from 'src/components/FlagIcon';
import i18n from 'src/services/i18n';

interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  locale: LanguageEnum;
  setLocale: (language: LanguageEnum) => void;
}

const LanguageSwitcher: React.FC<Props> = ({ locale, setLocale, ...props }) => {
  const [availableFlags] = useState<{
    [key in LanguageEnum]: string;
  }>({
    [LanguageEnum.EN]: Flags.US,
    [LanguageEnum.LT]: Flags.LT,
    [LanguageEnum.DE]: Flags.DE,
    [LanguageEnum.EE]: Flags.EE,
  });

  return (
    <div className={'d-flex gap-2 locale-switcher'} {...props}>
      {(Object.keys(availableFlags) as Array<LanguageEnum>).map((flagKey, i) => (
        <div key={i} className={`locale ${flagKey === locale ? 'active' : ''}`}>
          <FlagIcon
            onClick={() => {
              setLocale(flagKey);
              i18n.changeLanguage(flagKey);
            }}
            className={'cursor'}
            locale={flagKey}
            icon={availableFlags[flagKey]}
          />
        </div>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
