import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import Table from 'src/components/Table';
import { PaginationData, PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import { Cell, Column } from 'react-table';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import DeleteEntityModal from 'src/components/Modal/Modals/DeleteEntityModal';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';
import { RouteList } from 'src/routes';
import { truncate } from 'lodash';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { RoleListResponseDto } from 'src/types/api/roles';

const RoleList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<RoleListResponseDto>>();
  const { showModal } = useGlobalModalContext();

  const columns = useMemo<Column<RoleListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'number',
        width: 200,
        // Filter: StringFilter,
      },
      {
        Header: t('table.name'),
        accessor: 'name',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.is_system_role'),
        accessor: 'is_system',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<RoleListResponseDto>) => (
          <span>{cell.value ? t('common.yes') : t('common.no')}</span>
        ),
      },
      {
        Header: t('table.description'),
        accessor: 'description',
        disableSortBy: true,
        width: 600,
        Cell: (cell: Cell<RoleListResponseDto>) => (
          <span>{truncate(cell.value, { length: 100 })}</span>
        ),
      },
      {
        Header: t('table.action'),
        Cell: (cell: Cell<RoleListResponseDto> | any) => {
          const row = cell.row.values;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                permissions={Permissions.BO__ROLES__UPDATE}
                route={generatePath(RouteList.ROLES.UPDATE, { roleId: row.id })}
                show={!row.is_system}
              />
              <PermissionAction permissions={Permissions.BO__ROLES__DELETE}>
                <Link
                  to={'#'}
                  title={t('common.delete')}
                  hidden={row.is_system}
                  onClick={() => {
                    showModal(
                      <DeleteEntityModal
                        title={t('pages.delete_role')}
                        body={t('common.delete_specific', { value: cell.row.values.name })}
                        onDelete={() => {
                          return (
                            Api.roles
                              .deleteRole(cell.row.values.id)
                              // Dispatching event to react-table, to update list of values
                              .then(() => cell.dispatch({ type: 'refreshData' }))
                          );
                        }}
                      />,
                    );
                  }}
                  className={'link-danger'}
                >
                  <i className={'fas fa-trash-alt'} />
                </Link>
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [showModal, t],
  );

  const fetchData = useCallback((request: PaginationDataFilter | undefined) => {
    return Api.roles.fetchFilteredRoles(request).then((response) => setData(response));
  }, []);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'RoleListIndex'}>
        <Container fluid>
          <Card>
            <CardBody>
              <h4 className={'mb-4'}>{t('menu.roles')}</h4>
              <Table
                title={'RoleListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                createComponent={
                  <PermissionAction permissions={Permissions.BO__ROLES__CREATE}>
                    <Link to={RouteList.ROLES.CREATE} className={'btn btn-primary w-100'}>
                      {t('common.create_new')}
                    </Link>
                  </PermissionAction>
                }
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default RoleList;
