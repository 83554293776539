import React, { useContext } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { action } from 'typesafe-actions';

import FileUploadContext from './module/FileUploadContext';
import * as FileUploadReducer from './module/FileUploadReducer';
import FileUploadListItem from './FileUploadListItem';

const FileUploadList: React.FC = () => {
  const { state, dispatch } = useContext(FileUploadContext);

  if (Object.keys(state.files).length == 0) {
    return null;
  }

  return (
    <div className={'file-upload-list mt-2'}>
      <Card>
        <Row>
          <Col>
            {Object.keys(state.files).map((key, index) => (
              <FileUploadListItem
                file={state.files[key]}
                key={index}
                onRemove={() => dispatch(action(FileUploadReducer.FILE_REMOVED, key))}
              />
            ))}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default FileUploadList;
