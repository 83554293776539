import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { history } from 'src/helpers/navigate';
import * as serviceWorker from './serviceWorker';
import { Route, Router } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { Cookies, CookiesProvider } from 'react-cookie';
import { enableSentry } from './helpers/sentry';
import { QueryClient, QueryClientProvider } from 'react-query';

enableSentry();

const queryClient = new QueryClient();

export const cookies = new Cookies();
ReactDOM.render(
  <React.Fragment>
    <QueryClientProvider client={queryClient}>
      <CookiesProvider cookies={cookies}>
        <Router history={history}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <App />
          </QueryParamProvider>
        </Router>
      </CookiesProvider>
    </QueryClientProvider>
  </React.Fragment>,
  document.getElementById('root'),
);

serviceWorker.unregister();
