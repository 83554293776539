import React from 'react';
import NotificationsProvider from '../../components/Notifications/NotificationProvider';
import NotificationsList from '../../components/Notifications/NotificationList';

const NotificationsWrapper: React.FC = () => {
  return (
    <NotificationsProvider>
      <Notifications />
    </NotificationsProvider>
  );
};

const Notifications: React.FC = () => {
  return (
    <div className={'notifications-page'}>
      <NotificationsList />
    </div>
  );
};

export default NotificationsWrapper;
