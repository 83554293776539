import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik } from 'formik';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { UpdateUserRiskLevelRequestDto, UserResponseDto } from 'src/types/api/user';
import { success } from 'src/services/toastr';
import { UserRiskLevelEnum } from 'src/helpers/Enums/UserRiskLevelEnum';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import * as Yup from 'yup';

interface Props {
  user: UserResponseDto | undefined;
  onAction: (request: UpdateUserRiskLevelRequestDto) => Promise<UserResponseDto | void>;
}

const UpdateRiskLevelModal: React.FC<Props> = ({ user, onAction }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const [options] = useState<ReactSelectOption[]>([
    ...Object.values(UserRiskLevelEnum)
      .filter((type) => type != user?.risk_level)
      .map((type) => ({
        value: type,
        label: t('users.aml.risk_level.' + type),
      })),
  ]);

  const [request] = useState<UpdateUserRiskLevelRequestDto>({
    risk_level: options[0].value as UserRiskLevelEnum,
    reason: '',
  });

  const Schema = Yup.object().shape({
    risk_level: Yup.string().required(),
    reason: Yup.string().nullable(),
  });

  const onSubmit = useCallback(
    (request: UpdateUserRiskLevelRequestDto) => {
      return onAction(request).then(() => {
        success(t('common.updated_success'));
        hideModal();
      });
    },
    [hideModal, onAction, t],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('users.aml.change_risk_level')}
          </ModalHeader>
          <Formik initialValues={request} onSubmit={onSubmit} validationSchema={Schema}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <div className="mb-3">
                    <SelectInput
                      placeholder={t('users.aml.risk_level')}
                      name={'risk_level'}
                      options={options}
                      isClearable={true}
                    />
                  </div>
                  <div className="mb-3">
                    <TextAreaInput name={'reason'} />
                  </div>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default UpdateRiskLevelModal;
