import { BaseTabProps } from 'src/components/Tabs';
import {
  DocumentTemplateContextState,
  withDocumentTemplate,
} from 'src/pages/DocumentTemplates/DocumentTemplateContext';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import Table from 'src/components/Table';
import { printObjects } from 'src/helpers/formatters/object';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { DocumentTemplateActionListResponseDto } from 'src/types/api/documentTemplates';

interface Props extends BaseTabProps, DocumentTemplateContextState {}

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const TabActionHistory: React.FC<Props> = ({ documentTemplate }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<DocumentTemplateActionListResponseDto>>();

  const columns = useMemo<Column<DocumentTemplateActionListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.user'),
        accessor: 'user_name',
        disableSortBy: true,
        Cell: (cell: Cell<DocumentTemplateActionListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {row.user_name} {row.original_user_name && <>({row.original_user_name})</>}
            </div>
          );
        },
      },
      {
        Header: t('table.event'),
        accessor: 'event',
        disableSortBy: true,
        Cell: (cell: Cell<DocumentTemplateActionListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div>
              {t('audit.event.' + row.event, { type: t('audit.entity.' + row.audit_type) })}
            </div>
          );
        },
      },
      {
        Header: t('table.new_values'),
        accessor: 'new_values',
        disableSortBy: true,
        Cell: (cell: Cell<DocumentTemplateActionListResponseDto>) => (
          <div className={'d-flex flex-wrap'}>{printObjects(cell.value)}</div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<DocumentTemplateActionListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!documentTemplate) return Promise.resolve();

      return Api.documentTemplates
        .fetchDocumentTemplateActions(documentTemplate.id, request)
        .then((response) => setResponse(response));
    },
    [documentTemplate],
  );

  return (
    <>
      <HiddenColumnsProvider title={'DocumentTemplatesTabsTabActionHistory'}>
        <Table
          title={'DocumentTemplatesTabsTabActionHistory'}
          columns={columns}
          data={response}
          onFetchData={fetchData}
          initialSortBy={INITIAL_SORT_ORDER}
        />
      </HiddenColumnsProvider>
    </>
  );
};

export default withDocumentTemplate(TabActionHistory);
