import { Filters } from 'react-table';
import { AppStateInterface } from './app';
import { CurrentUserResponseDto } from 'src/types/api/currentUser';

export interface ProfileStateInterface {
  fetching: boolean;
  currentUser: CurrentUserResponseDto | undefined;
}

export interface MainState {
  app: AppStateInterface;
  profile: ProfileStateInterface;
}

export interface PaginationMeta {
  current_page: number;
  from: number;
  last_page: number;
  to: number;
  total: number;
  per_page: number | undefined;
}

export interface PaginationData<T> {
  data: T[];
  meta: PaginationMeta;
}

export interface PaginationDataFilter {
  page?: number;
  limit?: number;
  sort?: any;
  search?: string;
  filters?: Filters<object>;
  with?: Array<string>;
}

export enum LegalEntity {
  Personal = 'personal',
  Company = 'company',
}

export enum UserType {
  Unknown,
  Investor = 'investor',
  ProjectDeveloper = 'project_developer',
}

export type AbortableAxiosResponse<T> = {
  promise: Promise<T>;
  signal: AbortController;
};
