import React from 'react';
import DOMPurify from 'dompurify';

interface Props {
  className?: string;
  content: string;
}

const SanitizedHtmlBlock: React.FC<Props> = ({ className, content }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content),
      }}
      className={`html-block ${className}`}
    />
  );
};
export default SanitizedHtmlBlock;
