import React from 'react';

import PageLayout from 'src/containers/PageLayout';
import { RouteList } from 'src/routes';
import { Permissions } from 'src/helpers/auth/permissions';
import AuthRoute from 'src/pages/AuthRoute';
import Reports from 'src/pages/Reports/View';

const ReportRoutes: any = () => [
  <AuthRoute
    path={RouteList.REPORTS.VIEW}
    component={Reports}
    layout={PageLayout}
    permissions={[Permissions.BO__REPORTS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.REPORTS.VIEW}
    exact
  />,
];

export default ReportRoutes;
