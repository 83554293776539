import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseNumbers } from 'src/helpers/useNumbers';

interface Props {
  pageIndex: number;
  lastPage: number;
  gotoPage: (pageIndex: number) => void;
}

const SearchablePagination: React.FC<Props> = ({ lastPage, pageIndex, gotoPage }) => {
  const { t } = useTranslation();
  const [inputPage, setInputPage] = useState<string>(String(pageIndex));

  const changePage = (page: string | number) => {
    let pageN: number;
    if (typeof page === 'string') {
      pageN = Number(page);
    } else {
      pageN = page;
      page = String(page);
    }

    setInputPage(page);

    if (pageN <= lastPage && pageN >= 1) {
      gotoPage(pageN);
    }
  };

  return (
    <div className={'justify-content-md-end justify-content-center align-items-center row'}>
      <div className={'col-md-auto col'}>
        <div className={'d-flex gap-2'}>
          <button
            className={'btn btn-primary'}
            disabled={pageIndex === 1}
            onClick={() => changePage(1)}
          >
            {'<<'}
          </button>
          <button
            className={'btn btn-primary'}
            disabled={pageIndex === 1}
            onClick={() => changePage(pageIndex - 1)}
          >
            {'<'}
          </button>
        </div>
      </div>
      <div className="col-md-auto d-none d-md-block col">
        {t('table.page')}{' '}
        <strong>{t('table.page_countable', { current: pageIndex, total: lastPage })}</strong>
      </div>
      <div className="col-md-auto col">
        <input
          type="number"
          className="form-control"
          onChange={(e) => changePage(e.target.value)}
          value={inputPage}
          onKeyPress={UseNumbers.preventNonNumericalInput}
        />
      </div>
      <div className="col-md-auto col">
        <div className="d-flex gap-2">
          <button
            type="button"
            className="btn btn-primary"
            disabled={pageIndex === lastPage}
            onClick={() => changePage(pageIndex + 1)}
          >
            &gt;
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={pageIndex === lastPage}
            onClick={() => changePage(lastPage)}
          >
            &gt;&gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchablePagination;
