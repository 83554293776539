import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Cell, Column } from 'react-table';
import { ProjectInvestmentListResponseDto, ProjectResponseDto } from 'src/types/api/projects';
import { ModelField } from 'src/components/Table/Fields/ModelField';
import DownloadButton from 'src/components/DownloadButton';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import GroupedInvestmentsModal from 'src/pages/Project/List/Modals/GroupedInvestmentsModal';
import EditEntityButtonEvent from 'src/components/Table/Buttons/EditEntityButtonEvent';
import FormModal from 'src/components/Modal/Modals/FormModal';
import { AddBonusToInvestmentModal } from '../../Modals/AddBonusToInvestmentModal';
import PermissionAction from 'src/components/PermissionAction';
import Api from 'src/api';
import { PaginationData } from 'src/types';
import { success } from 'src/services/toastr';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import usePermissions from 'src/helpers/usePermissions';
import { Permissions } from 'src/helpers/auth/permissions';
import { ProjectInvestmentStatusEnum } from 'src/helpers/Enums/ProjectInvestmentStatusEnum';

interface Props {
  project: ProjectResponseDto | undefined;
  investments: PaginationData<ProjectInvestmentListResponseDto> | undefined;
  setInvestments: Dispatch<
    SetStateAction<PaginationData<ProjectInvestmentListResponseDto> | undefined>
  >;
}

const useInvestmentColumns = ({ project, investments, setInvestments }: Props) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { can } = usePermissions();

  const handleViewGroupedInvestments = useCallback(
    (investmentId: string) => () => {
      if (project) {
        showModal(
          <GroupedInvestmentsModal projectId={project.id} projectInvestmentId={investmentId} />,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project],
  );

  const handleCancelInvestment = useCallback(
    async (investmentId: string) => {
      if (project) {
        Api.projects.cancelInvestment(project.id, investmentId).then((response) => {
          const clonedInvestments = { ...investments };
          if (clonedInvestments.data && clonedInvestments.meta) {
            const index = clonedInvestments.data.findIndex(
              (clonedInvestment) => clonedInvestment.id === response.id,
            );
            if (index !== -1) {
              clonedInvestments.data[index] = response;
            }

            setInvestments({
              data: clonedInvestments.data,
              meta: clonedInvestments.meta,
            });

            success(t('common.project_investment_cancelled'));
          }
        });
      }
    },
    [project, investments, setInvestments, t],
  );

  const showCancelInvestmentConfirmModal = useCallback(
    (investmentId: string) => () => {
      showModal(
        <ActionModal
          title={t('label.cancel_investment.title')}
          body={t('label.cancel_investment.description')}
          onAction={() => handleCancelInvestment(investmentId)}
          actionText={t('common.confirm')}
        />,
      );
    },
    [handleCancelInvestment, showModal, t],
  );

  return useMemo<Column<ProjectInvestmentListResponseDto>[] | any>(
    () => [
      {
        Header: t('label.owner'),
        accessor: 'owner',
        disableSortBy: true,
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => {
          const value = cell.row.original.owner;

          return <ModelField model={value} showPid={true} />;
        },
      },
      {
        Header: t('label.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('label.type'),
        accessor: 'type',
        sortType: 'string',
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => <div>{cell.value}</div>,
      },
      {
        Header: t('label.status'),
        accessor: 'calculated_status',
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => <div>{cell.value}</div>,
      },
      {
        Header: t('label.basic_interest'),
        accessor: 'basic_interest',
        sortType: 'number',
        width: 100,
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => (
          <div>{t('common.percents', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('label.profitus_interest'),
        accessor: 'profitus_interest',
        sortType: 'number',
        width: 100,
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => (
          <div>{t('common.percents', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('label.owner_interest'),
        accessor: 'owner_interest',
        sortType: 'number',
        width: 100,
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => (
          <div>{t('common.percents', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('label.total_interest'),
        accessor: 'total_interest',
        sortType: 'number',
        width: 100,
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => (
          <div>{t('common.percents', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<ProjectInvestmentListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.investment_conditions'),
        accessor: 'investment_conditions',
        Cell: ({ row }: Cell<ProjectInvestmentListResponseDto>) => (
          <div>
            {row.original.investment_conditions && (
              <DownloadButton url={row.original.investment_conditions.url} />
            )}
          </div>
        ),
      },
      {
        Header: t('table.loan_agreement'),
        accessor: 'loan_agreement',
        Cell: ({ row }: Cell<ProjectInvestmentListResponseDto>) => (
          <div>
            {row.original.loan_agreement && (
              <DownloadButton url={row.original.loan_agreement.url} />
            )}
          </div>
        ),
      },
      {
        Header: t('table.payment_schedule'),
        accessor: 'payment_schedule',
        Cell: ({ row }: Cell<ProjectInvestmentListResponseDto>) => (
          <div>
            {row.original.payment_schedule && (
              <DownloadButton url={row.original.payment_schedule.url} />
            )}
          </div>
        ),
      },
      {
        Header: t('table.other_documents'),
        accessor: 'other_documents',
        Cell: ({ row }: Cell<ProjectInvestmentListResponseDto>) => (
          <div>
            {row.original.other_documents && (
              <DownloadButton url={row.original.other_documents.url} />
            )}
          </div>
        ),
      },
      {
        Header: t('table.actions'),
        accessor: 'is_groupable_investment',
        width: 200,
        Cell: ({ row }: Cell<ProjectInvestmentListResponseDto>) => (
          <div className="d-flex gap-2">
            {row.original.is_groupable_investment && (
              <button
                className="btn btn-primary"
                onClick={handleViewGroupedInvestments(row.original.id)}
              >
                {t('table.view_grouped')}
              </button>
            )}
            {row.original.calculated_status !== ProjectInvestmentStatusEnum.REFUNDED && (
              <PermissionAction permissions={Permissions.BO__PROJECTS__ADD_BONUS_TO_INVESTMENT}>
                <EditEntityButtonEvent
                  event={() =>
                    showModal(
                      <FormModal
                        title={t('projects.investments.edit_investment')}
                        size={'xl'}
                        form={
                          <AddBonusToInvestmentModal
                            investment={row.original}
                            projectId={project?.id}
                          />
                        }
                      />,
                    )
                  }
                />
              </PermissionAction>
            )}

            {can(Permissions.BO__PROJECTS__REFUND_INVESTMENTS) && row.original.is_refundable && (
              <button
                className="btn btn-primary"
                onClick={showCancelInvestmentConfirmModal(row.original.id)}
              >
                {t('table.cancel_investment')}
              </button>
            )}
          </div>
        ),
      },
    ],
    [
      t,
      handleViewGroupedInvestments,
      showModal,
      project?.id,
      showCancelInvestmentConfirmModal,
      can,
    ],
  );
};

export default useInvestmentColumns;
