import React, { useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { BonusSystemResponseDto, StoreBonusRequestDto } from 'src/types/api/bonusSystem';
import TextInput from 'src/components/Form/TextInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import CheckboxInput from 'src/components/Form/CheckboxInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SelectBonusTypesInput from 'src/components/Form/Select/SelectBonusTypeInput';
import SelectBonusRulesInput from 'src/components/Form/Select/SelectBonusRulesInput';
import { SelectAsyncInput } from 'src/components/Form/Select';
import Api from 'src/api';
import { InvestorBriefResponseDto } from 'src/types/api/user';
import { ProjectListResponseDto } from 'src/types/api/projects';
import { PaginationDataFilter } from 'src/types';
import RadioInputGroup from 'src/components/RadioGroup/RadioInputGroup';
import { BonusConditionEnum } from 'src/helpers/Enums/BonusSystem/BonusConditionEnum';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { Permissions } from 'src/helpers/auth/permissions';
import PermissionAction from 'src/components/PermissionAction';
import { success } from 'src/services/toastr';
import { RouteList } from 'src/routes';
import { BonusStatusEnum } from 'src/helpers/Enums/BonusSystem/StatusEnum';
import DataBlock from 'src/components/DataBlocks/DataBlock';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import { navigate } from 'src/helpers/navigate';

const INITIAL_STATE: StoreBonusRequestDto = {
  user_id: null,
  user_type: null,
  projects: [],
  code: '',
  amount: null,
  percentage: null,
  percentage_amount_limit: null,
  summable: true,
  visible: false,
  multiple: false,
  usable_once: false,
  sellable: false,
  start_at: null,
  expire_at: null,
  type: '',
  rules: [],
  bonus_condition: BonusConditionEnum.AMOUNT,
};

interface BonusFormProps {
  bonusSystem?: BonusSystemResponseDto;
  onSubmit: (request: StoreBonusRequestDto, helper: any) => void;
}

const BonusForm: React.FC<BonusFormProps> = ({ bonusSystem, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const BonusSchema = Yup.object().shape({
    user_id: Yup.number().nullable(),
    user_type: Yup.string().nullable(),
    projects: Yup.array().nullable(),
    code: Yup.string().required(),
    bonus_condition: Yup.string().required(),
    amount: Yup.number().when('bonus_condition', {
      is: BonusConditionEnum.AMOUNT,
      then: Yup.number().required(),
      otherwise: Yup.number().nullable(),
    }),
    percentage: Yup.number().when('bonus_condition', {
      is: BonusConditionEnum.PERCENTAGE,
      then: Yup.number().min(0.1).max(5).required(),
      otherwise: Yup.number().nullable(),
    }),
    percentage_amount_limit: Yup.number().when('bonus_condition', {
      is: BonusConditionEnum.PERCENTAGE,
      then: Yup.number().min(0).nullable(),
      otherwise: Yup.number().nullable(),
    }),
    summable: Yup.boolean(),
    visible: Yup.boolean(),
    multiple: Yup.boolean(),
    usable_once: Yup.boolean(),
    sellable: Yup.boolean(),
    start_at: Yup.date().nullable(),
    expire_at: Yup.date()
      .when('start_at', (start_at, schema) => (start_at ? schema.min(start_at) : schema))
      .nullable(),
    type: Yup.string().required(),
    rules: Yup.array(),
  });

  const [bonusRequest] = useState<StoreBonusRequestDto>(bonusSystem ?? INITIAL_STATE);

  const fetchUsers = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };
    const response = await Api.user.fetchFilteredInvestorsUsers(request);

    return response.data.map((user: InvestorBriefResponseDto) => ({
      value: user.id,
      label: [user.private_id, user.name].filter((val) => val).join(' - '),
      class: user.class,
    }));
  };

  const fetchProjects = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };
    const response = await Api.projects.fetchFilteredProjects(request).promise;

    return response.data.map((project: ProjectListResponseDto) => ({
      value: project.id,
      label: `${project.pid} ${
        project.project_name?.[i18n.resolvedLanguage as LanguageEnum] ?? ''
      }`,
    }));
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={bonusRequest}
        enableReinitialize={true}
        validateOnChange={true}
        validationSchema={BonusSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, values, setValues }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              {bonusSystem && (
                <div className="mb-3">
                  <DataBlock
                    label={t('label.status')}
                    value={t('bonus_system.status.' + bonusSystem.status)}
                  />
                </div>
              )}
              <div className="mb-3">
                <SelectBonusTypesInput name={'type'} />
              </div>
              <div className="mb-3">
                <SelectAsyncInput
                  name={'user_id'}
                  isClearable={true}
                  loadOptions={fetchUsers}
                  onChange={(user: any) => {
                    setValues((prevState) => ({
                      ...prevState,
                      user_id: user?.value,
                      user_type: user?.class,
                    }));
                  }}
                />
              </div>
              <div className="mb-3">
                <SelectBonusRulesInput name={'rules'} placeholder={t('bonus_system.rules')} />
              </div>
              <div className="mb-3">
                <SelectAsyncInput
                  name={'projects'}
                  loadOptions={fetchProjects}
                  isMulti={true}
                  isClearable={true}
                />
              </div>
              <div className={`mt-4`}>
                <RadioInputGroup
                  name={'bonus_condition'}
                  values={[BonusConditionEnum.AMOUNT, BonusConditionEnum.PERCENTAGE]}
                  showPlaceholder={true}
                  placeholder={t('bonus_system.bonus_condition')}
                />
              </div>
              {values.bonus_condition == BonusConditionEnum.PERCENTAGE ? (
                <>
                  <div className="mb-3">
                    <TextInput type={'number'} step={'any'} name={BonusConditionEnum.PERCENTAGE} />
                  </div>
                  <div className="mb-3">
                    <TextInput type={'number'} step={'any'} name="percentage_amount_limit" />
                  </div>
                </>
              ) : (
                <div className="mb-3">
                  <TextInput type={'number'} step={'any'} name={BonusConditionEnum.AMOUNT} />
                </div>
              )}
              <div className="mb-3">
                <TextInput name={'code'} />
              </div>
              <div className="mb-3">
                <DatePickerInput name={'start_at'} showTimeSelect />
              </div>
              <div className="mb-3">
                <DatePickerInput name={'expire_at'} showTimeSelect />
              </div>
              <div className="mb-3">
                <CheckboxInput name={'summable'} />
              </div>
              <div className="mb-3">
                <CheckboxInput name={'visible'} />
              </div>
              <div className="mb-3">
                <CheckboxInput
                  name={'multiple'}
                  placeholder={t('bonus_system.multiple')}
                  title={t('bonus_system.multiple.info')}
                />
              </div>
              {values.multiple ? (
                <div className="mb-3">
                  <CheckboxInput
                    name={'usable_once'}
                    placeholder={t('bonus_system.usable_once')}
                    title={t('bonus_system.usable_once.info')}
                  />
                </div>
              ) : null}
              <div className="mb-3">
                <CheckboxInput name={'sellable'} />
              </div>
              <PermissionAction permissions={Permissions.BO__BONUS_SYSTEM__EDIT}>
                <div className={'mt-4 mb-4'}>
                  <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
                </div>
              </PermissionAction>
              <PermissionAction permissions={Permissions.BO__BONUS_SYSTEM__DISABLE}>
                {bonusSystem && bonusSystem.status !== BonusStatusEnum.DISABLED && (
                  <div className={'mt-4 mb-4'}>
                    <PrimaryButton
                      btype={'btn-danger'}
                      title={t('common.disable')}
                      type={'button'}
                      submitting={isSubmitting}
                      onClick={() =>
                        showModal(
                          <ActionModal
                            title={t('bonus_system.alert.disable_bonus.title')}
                            onAction={() =>
                              Api.bonusSystem.disableBonus(bonusSystem.id).then(() => {
                                success(t('bonus_system.alert.disable_bonus.alert_success'));
                                navigate(RouteList.BONUS_SYSTEM.LIST);
                              })
                            }
                            body={t('bonus_system.alert.disable_bonus.body')}
                            actionText={t('common.disable')}
                          />,
                        )
                      }
                    />
                  </div>
                )}
              </PermissionAction>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default BonusForm;
