import React from 'react';
import { Container } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/Loader';
import { DashboardCardGrid } from '../DashboardCardGrid';
import { useCurrentMonthMarketingStatisticQuery } from 'src/api/queries/statistics/marketing/useCurrentMonthMarketingStatisticQuery';
import { useLastMonthMarketingStatisticQuery } from 'src/api/queries/statistics/marketing/useLastMonthMarketingStatisticQuery';
import { useGeneralMarketingStatisticQuery } from 'src/api/queries/statistics/marketing/useGeneralMarketingStatisticQuery';
import { CustomDatesMarketingDataBlock } from './CustomDatesMarketingDataBlock';

const MarketingDataBlock: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const currentMonthStatisticQuery = useCurrentMonthMarketingStatisticQuery();
  const lastMonthStatisticQuery = useLastMonthMarketingStatisticQuery();
  const generalStatisticQuery = useGeneralMarketingStatisticQuery();

  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <h3 className="card-ml-1">{t('dashboard.general')}</h3>
          {generalStatisticQuery.isLoading || generalStatisticQuery.data === undefined ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
            <>
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.marketing.efficiency'),
                    value: generalStatisticQuery.data.efficiency,
                    tooltipElementId: 'efficiency',
                    tooltipValue: t('dashboard.marketing.efficiency.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.active_investors'),
                    value: generalStatisticQuery.data.active_investors,
                    tooltipElementId: 'active_investors',
                    tooltipValue: t('dashboard.marketing.active_investors.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.active_portfolio'),
                    value: t('common.money', {
                      value: generalStatisticQuery.data.active_portfolio,
                    }),
                    tooltipElementId: 'active_portfolio',
                    tooltipValue: t('dashboard.marketing.active_portfolio.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.average_investors_portfolio'),
                    value: t('common.money', {
                      value: generalStatisticQuery.data.average_investors_portfolio,
                    }),
                    tooltipElementId: 'average_investors_portfolio',
                    tooltipValue: t('dashboard.marketing.average_investors_portfolio.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.return_rate'),
                    value: generalStatisticQuery.data.return_rate,
                    tooltipElementId: 'return_rate',
                    tooltipValue: t('dashboard.marketing.return_rate.tooltip'),
                  },
                ]}
              />
            </>
          )}
          <h3 className="card-ml-1">{t('dashboard.current_month')}</h3>
          {currentMonthStatisticQuery.isLoading || currentMonthStatisticQuery.data === undefined ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
            <>
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.marketing.registered_users'),
                    value: currentMonthStatisticQuery.data.registered_users,
                    tooltipElementId: 'current_month_registered_users',
                    tooltipValue: t('dashboard.marketing.registered_users.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.registered_users_referral'),
                    value: currentMonthStatisticQuery.data.registered_users_referral,
                    tooltipElementId: 'current_month_registered_users_referral',
                    tooltipValue: t('dashboard.marketing.registered_users_referral.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.registered_users_referral_conversion'),
                    value: currentMonthStatisticQuery.data.registered_users_referral_conversion,
                    tooltipElementId: 'current_month_registered_users_referral_conversion',
                    tooltipValue: t(
                      'dashboard.marketing.registered_users_referral_conversion.tooltip',
                    ),
                  },
                  {
                    title: t('dashboard.marketing.active_users'),
                    value: currentMonthStatisticQuery.data.active_users,
                    tooltipElementId: 'current_month_active_users',
                    tooltipValue: t('dashboard.marketing.active_users.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.average_project_funding_duration'),
                    value: currentMonthStatisticQuery.data.average_project_funding_duration,
                  },
                ]}
              />
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.marketing.successful_identity_tries'),
                    value: currentMonthStatisticQuery.data.successful_identity_tries,
                    tooltipElementId: 'current_month_successful_identity_tries',
                    tooltipValue: t('dashboard.marketing.successful_identity_tries.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.successful_identity_tries_conversion'),
                    value: currentMonthStatisticQuery.data.successful_identity_tries_conversion,
                    tooltipElementId: 'current_month_successful_identity_tries_conversion',
                    tooltipValue: t(
                      'dashboard.marketing.successful_identity_tries_conversion.tooltip',
                    ),
                  },
                  {
                    title: t('dashboard.marketing.failed_identity_tries'),
                    value: currentMonthStatisticQuery.data.failed_identity_tries,
                    tooltipElementId: 'current_month_failed_identity_tries',
                    tooltipValue: t('dashboard.marketing.failed_identity_tries.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.failed_identity_tries_conversion'),
                    value: currentMonthStatisticQuery.data.failed_identity_tries_conversion,
                    tooltipElementId: 'current_month_failed_identity_tries_conversion',
                    tooltipValue: t('dashboard.marketing.failed_identity_tries_conversion.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.largest_active_portfolio'),
                    value: t('common.money', {
                      value: currentMonthStatisticQuery.data.largest_active_portfolio,
                    }),
                  },
                ]}
              />
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.marketing.new_investors_count'),
                    value: currentMonthStatisticQuery.data.new_investors_count,
                    tooltipElementId: 'current_month_new_investors_count',
                    tooltipValue: t('dashboard.marketing.new_investors_count.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.new_investors_count_conversion'),
                    value: currentMonthStatisticQuery.data.new_investors_count_conversion,
                    tooltipElementId: 'current_month_new_investors_count_conversion',
                    tooltipValue: t('dashboard.marketing.new_investors_count_conversion.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.average_investment_to_project'),
                    value: t('common.money', {
                      value: currentMonthStatisticQuery.data.average_investment_to_project,
                    }),
                    tooltipElementId: 'current_month_average_investment_to_project',
                    tooltipValue: t('dashboard.marketing.average_investment_to_project.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.largest_investment'),
                    value: t('common.money', {
                      value: currentMonthStatisticQuery.data.largest_investment,
                    }),
                  },
                  {
                    title: t('dashboard.marketing.average_project_duration'),
                    value: currentMonthStatisticQuery.data.average_project_duration,
                    tooltipElementId: 'current_month_average_project_duration',
                    tooltipValue: t('dashboard.marketing.average_project_duration.tooltip'),
                  },
                ]}
              />
            </>
          )}
          <h3 className="card-ml-1">{t('dashboard.previous_month')}</h3>
          {lastMonthStatisticQuery.isLoading || lastMonthStatisticQuery.data === undefined ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
            <>
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.marketing.registered_users'),
                    value: lastMonthStatisticQuery.data.registered_users,
                    tooltipElementId: 'last_month_registered_users',
                    tooltipValue: t('dashboard.marketing.registered_users.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.registered_users_referral'),
                    value: lastMonthStatisticQuery.data.registered_users_referral,
                    tooltipElementId: 'last_month_registered_users_referral',
                    tooltipValue: t('dashboard.marketing.registered_users_referral.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.registered_users_referral_conversion'),
                    value: lastMonthStatisticQuery.data.registered_users_referral_conversion,
                    tooltipElementId: 'last_month_registered_users_referral_conversion',
                    tooltipValue: t(
                      'dashboard.marketing.registered_users_referral_conversion.tooltip',
                    ),
                  },
                  {
                    title: t('dashboard.marketing.active_users'),
                    value: lastMonthStatisticQuery.data.active_users,
                    tooltipElementId: 'last_month_active_users',
                    tooltipValue: t('dashboard.marketing.active_users.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.average_project_funding_duration'),
                    value: lastMonthStatisticQuery.data.average_project_funding_duration,
                  },
                ]}
              />
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.marketing.successful_identity_tries'),
                    value: lastMonthStatisticQuery.data.successful_identity_tries,
                    tooltipElementId: 'last_month_successful_identity_tries',
                    tooltipValue: t('dashboard.marketing.successful_identity_tries.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.successful_identity_tries_conversion'),
                    value: lastMonthStatisticQuery.data.successful_identity_tries_conversion,
                    tooltipElementId: 'last_month_successful_identity_tries_conversion',
                    tooltipValue: t(
                      'dashboard.marketing.successful_identity_tries_conversion.tooltip',
                    ),
                  },
                  {
                    title: t('dashboard.marketing.failed_identity_tries'),
                    value: lastMonthStatisticQuery.data.failed_identity_tries,
                    tooltipElementId: 'last_month_failed_identity_tries',
                    tooltipValue: t('dashboard.marketing.failed_identity_tries.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.failed_identity_tries_conversion'),
                    value: lastMonthStatisticQuery.data.failed_identity_tries_conversion,
                    tooltipElementId: 'last_month_failed_identity_tries_conversion',
                    tooltipValue: t('dashboard.marketing.failed_identity_tries_conversion.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.largest_active_portfolio'),
                    value: t('common.money', {
                      value: lastMonthStatisticQuery.data.largest_active_portfolio,
                    }),
                  },
                ]}
              />
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.marketing.new_investors_count'),
                    value: lastMonthStatisticQuery.data.new_investors_count,
                    tooltipElementId: 'last_month_new_investors_count',
                    tooltipValue: t('dashboard.marketing.new_investors_count.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.new_investors_count_conversion'),
                    value: lastMonthStatisticQuery.data.new_investors_count_conversion,
                    tooltipElementId: 'last_month_new_investors_count_conversion',
                    tooltipValue: t('dashboard.marketing.new_investors_count_conversion.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.average_investment_to_project'),
                    value: t('common.money', {
                      value: lastMonthStatisticQuery.data.average_investment_to_project,
                    }),

                    tooltipElementId: 'last_month_average_investment_to_project',
                    tooltipValue: t('dashboard.marketing.average_investment_to_project.tooltip'),
                  },
                  {
                    title: t('dashboard.marketing.largest_investment'),
                    value: t('common.money', {
                      value: lastMonthStatisticQuery.data.largest_investment,
                    }),
                  },
                  {
                    title: t('dashboard.marketing.average_project_duration'),
                    value: lastMonthStatisticQuery.data.average_project_duration,
                    tooltipElementId: 'last_month_average_project_duration',
                    tooltipValue: t('dashboard.marketing.average_project_duration.tooltip'),
                  },
                ]}
              />
            </>
          )}
          <CustomDatesMarketingDataBlock />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MarketingDataBlock);
