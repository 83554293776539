import React, { useCallback, useState } from 'react';
import usePermissions from 'src/helpers/usePermissions';

export interface EditableDropDownFieldData {
  id: number | string;

  [key: string]: string | number;
}

interface OptionsInterface {
  value: string;
  title: string;
}

interface Props {
  permissions?: string | string[];
  data: EditableDropDownFieldData;
  options: OptionsInterface[];
  text: string;
  submit: (fieldData: EditableDropDownFieldData) => void;
}

export const EditableDropDownField: React.FC<Props> = ({
  permissions,
  options,
  data,
  text,
  submit,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);
  const p = usePermissions();

  const handleOnClick = useCallback(() => {
    if (permissions && p.can(permissions)) {
      setIsEditing(true);
    }
  }, [p, permissions]);

  const onSubmit = async (v: string) => {
    setValue(v);
    submit({
      ...data,
      value: v,
    });

    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <select className="form-select" onChange={(e) => onSubmit(e.target.value)} value={value}>
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.title}
          </option>
        ))}
      </select>
    );
  }

  return (
    <p className="span-editable" onClick={handleOnClick}>
      {options.find((item) => item.value === value)?.title || (value as string)}
    </p>
  );
};
