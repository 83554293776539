import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';

import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { BaseTabProps } from 'src/components/Tabs';
import Table from 'src/components/Table';
import DownloadButton from 'src/components/DownloadButton';
import { AttachFilesRequestDto, LemonwayDocumentResponseDto } from 'src/types/api/common';
import { CompanyContextState, withCompany } from 'src/pages/User/Update/Company/CompanyContext';
import FileUpload, { FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';
import { FormikProps } from 'formik/dist/types';
import { Form, Formik, FormikHelpers } from 'formik';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { DEFAULT_LIMIT as TABLE_DEFAULT_LIMIT } from 'src/components/Table';
import { DEFAULT_PAGE as TABLE_DEFAULT_PAGE } from 'src/components/Table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import {
  LemonwayDocumentInternalStatusEnum,
  LemonwayDocumentInternalTypeEnum,
  LemonWayDocumentStatusEnum,
  LemonWayDocumentTypeEnum,
} from 'src/helpers/Enums/LemonwayTypeEnum';
import { Permissions } from 'src/helpers/auth/permissions';
import {
  EditableDropDownField,
  EditableDropDownFieldData,
} from 'src/components/Table/Fields/EditableDropdownField';
import { UpdateLemonwayDocumentRequestDto } from 'src/types/api/user';
import { success } from 'src/services/toastr';
import usePermissions from 'src/helpers/usePermissions';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

interface Props extends CompanyContextState, BaseTabProps {}

const TabLemonwayDocuments: React.FC<Props> = ({ company }) => {
  const { t } = useTranslation();
  const p = usePermissions();
  const [data, setData] = useState<PaginationData<LemonwayDocumentResponseDto>>();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [initialFormValues] = useState<AttachFilesRequestDto>({
    media_ids: [],
  });

  const handleUnmarkAsLWDocument = (companyId: number | string, mediaId: number | string) => () => {
    if (data?.data) {
      setLoading(true);
      Api.userCompanies.unmarkAsLemonwayDocument(companyId, mediaId).then(() => {
        const updatedData = data.data.filter((item) => item.media.id !== mediaId);
        setData({
          meta: data.meta,
          data: updatedData,
        });
        success(t('users.unmarked_as_lemonway_document'));
        setLoading(false);
      });
    }
  };

  const handleSendFileToLemonway = (companyId: number | string, mediaId: number | string) => () => {
    if (data?.data) {
      setLoading(true);
      Api.userCompanies
        .sendFileToLemonway(companyId, mediaId)
        .then((response) => {
          const updatedData = data.data.map((item) => {
            if (item.media.id === mediaId) {
              return response;
            }
            return item;
          });
          setData({
            meta: data.meta,
            data: updatedData,
          });
        })
        .finally(() => {
          success(t('users.sent_to_lemonway'));
          setLoading(false);
        });
    }
  };

  const handleUpdateDocumentType = (
    companyId: number | string,
    mediaId: number | string,
    updatedValues: EditableDropDownFieldData,
  ) => {
    if (data?.data) {
      const request: UpdateLemonwayDocumentRequestDto = {
        type: updatedValues.value as LemonwayDocumentInternalTypeEnum,
      };

      Api.userCompanies
        .updateLemonwayDocument(companyId, mediaId, request)
        .then((response) => {
          const updatedData = data.data.map((item) => {
            if (item.media.id === mediaId) {
              return response;
            }
            return item;
          });
          setData({
            meta: data.meta,
            data: updatedData,
          });
        })
        .finally(() => {
          success(t('common.updated_success'));
        });
    }
  };

  const columns = useMemo<Column<LemonwayDocumentResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'media.id',
      },
      {
        Header: t('table.file_name'),
        accessor: 'media.name',
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        Cell: (cell: Cell<LemonwayDocumentResponseDto>) => {
          if (
            cell.row.original.status === LemonwayDocumentInternalStatusEnum.PENDING &&
            p.can(Permissions.BO__USERS__EDIT_LEMONWAY_DOCUMENTS) &&
            company
          ) {
            return (
              <EditableDropDownField
                permissions={Permissions.BO__USERS__EDIT_LEMONWAY_DOCUMENTS}
                options={Object.values(LemonwayDocumentInternalTypeEnum).map((item) => ({
                  value: item,
                  title: t('table.lemonway_document_type.' + item),
                }))}
                data={{ id: cell.row.original.media.id }}
                text={cell.value}
                submit={(updatedValues) =>
                  handleUpdateDocumentType(company.id, cell.row.original.media.id, updatedValues)
                }
              />
            );
          }

          return <span>{t('table.lemonway_document_type.' + cell.value)}</span>;
        },
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        Cell: (cell: Cell<LemonwayDocumentResponseDto>) => <span>{cell.value}</span>,
      },
      {
        Header: t('table.lemonway_type'),
        accessor: 'lemonway_type',
        Cell: (cell: Cell<LemonwayDocumentResponseDto>) => {
          return <span>{getKeyFromValue(LemonWayDocumentTypeEnum, cell.value)}</span>;
        },
      },
      {
        Header: t('table.lemonway_status'),
        accessor: 'lemonway_status',
        Cell: (cell: Cell<LemonwayDocumentResponseDto>) => {
          return <span>{getKeyFromValue(LemonWayDocumentStatusEnum, cell.value)}</span>;
        },
      },
      {
        Header: t('table.lemonway_comment'),
        accessor: 'lemonway_comment',
        Cell: (cell: Cell<LemonwayDocumentResponseDto>) => {
          return <span>{cell.value}</span>;
        },
      },
      {
        Header: t('table.created_at'),
        accessor: 'media.created_at',
        Cell: (cell: Cell<LemonwayDocumentResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        accessor: 'media.url',
        disableSortBy: true,
        width: 380,
        Cell: (cell: Cell<LemonwayDocumentResponseDto>) => {
          const status = cell.row.original.status;
          const statusesThatCanSendToLemonway = [
            LemonwayDocumentInternalStatusEnum.PENDING,
            LemonwayDocumentInternalStatusEnum.CANT_SEND_NO_ROOM,
            LemonwayDocumentInternalStatusEnum.FAILED_TO_SEND,
          ];

          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.value} />
              {company && (
                <>
                  {status === LemonwayDocumentInternalStatusEnum.PENDING &&
                    p.can(Permissions.BO__USERS__UPLOAD_LEMONWAY_DOCUMENTS) && (
                      <button
                        disabled={loading}
                        className="btn btn-success"
                        onClick={handleUnmarkAsLWDocument(company.id, cell.row.original.media.id)}
                      >
                        {t('common.unmark_as_lemonway_document')}
                      </button>
                    )}

                  {statusesThatCanSendToLemonway.includes(status) &&
                    company.has_primary_wallet &&
                    p.can(Permissions.BO__USERS__UPLOAD_LEMONWAY_DOCUMENTS) && (
                      <button
                        disabled={loading}
                        className="btn btn-warning"
                        onClick={handleSendFileToLemonway(company.id, cell.row.original.media.id)}
                      >
                        {t('common.send_to_lemonway')}
                      </button>
                    )}
                </>
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, handleUnmarkAsLWDocument, handleSendFileToLemonway, handleUpdateDocumentType],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      if (company) {
        return Api.userCompanies
          .filterCompanyLemonwayDocuments(company.id, request)
          .then((response) => setData(response));
      }
    },
    [company],
  );

  const onSubmit = useCallback(
    async (request: AttachFilesRequestDto, helpers: FormikHelpers<any>) => {
      if (!company) return;

      try {
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        await Api.userCompanies.attachLemonwayDocuments(company.id, request);
        // on new file upload refresh table
        await fetchData({
          page: TABLE_DEFAULT_PAGE,
          limit: TABLE_DEFAULT_LIMIT,
          sort: [],
          search: undefined,
        });

        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [company, fetchData],
  );

  if (!company) {
    return <></>;
  }

  return (
    <React.Fragment>
      {p.can(Permissions.BO__USERS__UPLOAD_LEMONWAY_DOCUMENTS) && (
        <>
          <Formik
            innerRef={formRef}
            enableReinitialize={true}
            initialValues={initialFormValues}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <FileUpload
                  ref={fileUploadRef}
                  name={'media_ids'}
                  onPresign={(request) =>
                    Api.userCompanies.uploadLemonwayDocuments(company.id, request)
                  }
                />
                <PrimaryButton
                  disabled={values.media_ids.length === 0}
                  className={'btn btn-primary w-100 my-2'}
                  submitting={isSubmitting}
                  type={'submit'}
                  title={t('common.attach')}
                />
              </Form>
            )}
          </Formik>
          <hr />
        </>
      )}
      <HiddenColumnsProvider title={'UserUpdateCompanyTabsTabDocuments'}>
        <Table
          title={'UserUpdateCompanyTabsTabDocuments'}
          onFetchData={fetchData}
          columns={columns}
          data={data}
          searchable={true}
          initialSortBy={INITIAL_SORT_ORDER}
        />
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

const getKeyFromValue = <T extends string>(
  enumObject: Record<string, T>,
  value: T,
): keyof typeof enumObject | null => {
  const keys = Object.keys(enumObject) as Array<keyof typeof enumObject>;
  for (const key of keys) {
    if (enumObject[key] === value) {
      return key;
    }
  }

  return value;
};

export default withCompany(TabLemonwayDocuments);
