import React, { useEffect, useState } from 'react';
import * as Flags from 'country-flag-icons/string/3x2';

interface Props
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  show: boolean;
  locale: string;
}

const IsVisibleLocaleBadge: React.FC<Props> = ({ show, locale, ...props }) => {
  const [localeIcon, setLocaleIcon] = useState<string>();

  useEffect(() => {
    switch (locale) {
      case 'lt':
        setLocaleIcon(Flags.LT);
        break;
      case 'de':
        setLocaleIcon(Flags.DE);
        break;
      case 'ee':
        setLocaleIcon(Flags.EE);
        break;
      case 'en':
        setLocaleIcon(Flags.US);
        break;
      default:
        setLocaleIcon(undefined);
        break;
    }
  }, [locale]);

  if (!show) {
    return null;
  }

  return (
    <React.Fragment>
      {localeIcon === undefined ? (
        'Unknown icon'
      ) : (
        <img
          style={{ maxWidth: '16px' }}
          src={`data:image/svg+xml;base64,${window.btoa(localeIcon)}`}
          alt={locale.toLocaleUpperCase()}
          title={locale.toLocaleUpperCase()}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default IsVisibleLocaleBadge;
