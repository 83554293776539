import React from 'react';
import { Badge } from 'reactstrap';
import { formatISO9075 } from 'date-fns';
import i18n from 'i18next';
import DOMPurify from 'dompurify';

import prefixes from 'src/helpers/data/translation_prefixes.json';

const tryTranslateValue = (val: string) => {
  for (let i = 0; i < prefixes.length; i++) {
    const key = `${prefixes[i]}.${val}`;
    const translated = i18n.t(key);

    if (translated !== key) return translated;
  }

  return val;
};

const htmlRegex = /<\/?[^>]+(>|$)/g;

const formatValue = (val: any) => {
  if (val === null || val === undefined) return '-';
  if (typeof val === 'boolean') return val ? i18n.t('common.yes') : i18n.t('common.no');
  if (/^-?\d+$/.test(val)) return val;
  if (Date.parse(val)) return formatISO9075(Date.parse(val));
  if (typeof val === 'string' && new RegExp(htmlRegex).test(val))
    return DOMPurify.sanitize(val).replace(htmlRegex, '');
  if (typeof val === 'string') return tryTranslateValue(val);

  return val;
};

export const printObjects = (obj: any) => {
  if (obj === undefined || obj === null) return null;

  if (typeof obj === 'object') {
    const values = Object.entries(obj);

    return (
      <>
        {values.map(([key, val], index) => {
          return (
            <Badge key={index} className={'bg-primary me-1 mb-1'}>
              <strong>{key}</strong> : {formatValue(val)}
            </Badge>
          );
        })}
      </>
    );
  }

  return <></>;
};
