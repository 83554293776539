import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { ReportStatusEnum } from 'src/helpers/Enums/ReportStatusEnum';

interface Props {
  value: ReportStatusEnum;
}

const ReportStatusBadge: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const [color, setColor] = useState<string>('#bc3e55');

  useEffect(() => {
    switch (value) {
      case 'pending':
        setColor('#d1cd45');
        break;
      case 'processing':
      case 'mapping':
      case 'writing':
        setColor('#323c77');
        break;
      case 'processed':
        setColor('#81cf74');
        break;
      case 'failed':
        setColor('#bc3e55');
        break;
    }
  }, [value]);

  return (
    <Badge style={{ backgroundColor: color }} color={''}>
      {t('reports.status.' + value)}
    </Badge>
  );
};

export default ReportStatusBadge;
