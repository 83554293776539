import React from 'react';
import { useGlobalModalContext } from './GlobalModal';

const ModalCloseButton: React.FC = () => {
  const { hideModal } = useGlobalModalContext();
  return (
    <div onClick={hideModal} className="close pointer" aria-label="Close">
      <i className={'fas fa-times'} />
    </div>
  );
};

export default ModalCloseButton;
