import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import TabAccreditations from 'src/pages/User/Update/User/Tabs/TabAccreditations';
import { setGlobalLoading } from 'src/modules/app/actions';
import CopyOnClick from 'src/components/CopyOnClick';
import { join } from 'lodash';
import UserTypeView from 'src/components/Views/UserTypeView';
import LegalEntityView from 'src/components/Views/LegalEntityView';
import TabActions from 'src/pages/User/Update/User/Tabs/TabActions';
import Api from 'src/api';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';
import VerifiedBadge from 'src/pages/User/Badges/VerifiedBadge';
import DirectMarketingBadge from 'src/pages/User/Badges/DirectMarketingBadge';
import InvestedBadge from 'src/pages/User/Badges/InvestedBadge';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import ReasonModal from 'src/components/Modal/Modals/ReasonModal';
import { UserResponseDto } from 'src/types/api/user';
import UserEdit from 'src/pages/User/Update/User/UserEdit';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import AttachRolesModal from 'src/pages/User/Update/User/AttachRolesModal';
import TabData, { BaseTabProps, TabInfo } from 'src/components/Tabs';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import UserContext from 'src/pages/User/Update/User/UserContext';
import {
  TabDocuments,
  TabAgreements,
  TabInvestments,
  TabRelatedAccounts,
  TabLegal,
  TabComments,
  TabWalletOperations,
} from 'src/pages/User/Update/User/Tabs';
import FormModal from 'src/components/Modal/Modals/FormModal';
import CustomNotificationForm from '../CustomNotificationForm';
import { RouteList } from 'src/routes';
import { LegalEntityClassEnum } from 'src/helpers/Enums/LegalEntityClassEnum';
import TabWalletP2POperations from 'src/pages/User/Update/User/Tabs/TabWalletP2POperations';
import WalletExistsBadge from 'src/pages/User/Badges/WalletExistsBadge';
import CountryLabel from 'src/components/Labels/CountryLabel';
import { StringParam, useQueryParam } from 'use-query-params';
import TabAML from './Tabs/TabAML';
import { navigate } from 'src/helpers/navigate';
import AddAffiliateAmbassadorModal from 'src/components/Modal/Modals/AddAffiliateAmbassadorModal';
import EditAffiliateAmbassadorModal from 'src/components/Modal/Modals/EditAffiliateAmbassadorModal';
import { success } from 'src/services/toastr';

const Tabs: TabInfo<BaseTabProps>[] = [
  {
    name: 'users.accreditations',
    iconClass: 'fas fa-passport',
    component: TabAccreditations,
    permission: Permissions.BO__USERS__VIEW_ACCREDITATIONS,
  },
  {
    name: 'users.documents',
    iconClass: 'far fa-file',
    component: TabDocuments,
    permission: Permissions.BO__USERS__VIEW_DOCUMENTS,
  },
  {
    name: 'users.actions',
    iconClass: 'fas fa-history',
    component: TabActions,
    permission: Permissions.BO__USERS__VIEW_ACTIONS,
  },
  {
    name: 'users.agreements',
    iconClass: 'fas fa-handshake',
    component: TabAgreements,
    permission: Permissions.BO__USERS__VIEW_AGREEMENTS,
  },
  {
    name: 'users.investments',
    iconClass: 'fas fa-euro-sign',
    component: TabInvestments,
    permission: Permissions.BO__USERS__VIEW_INVESTMENTS,
  },
  {
    name: 'users.related_accounts',
    iconClass: 'fas fa-user',
    component: TabRelatedAccounts,
    permission: Permissions.BO__USERS__VIEW_RELATED_ACCOUNTS,
  },
  {
    name: 'users.legal',
    iconClass: 'fas fa-user',
    component: TabLegal,
    permission: Permissions.BO__USERS__VIEW,
  },
  {
    name: 'users.comments',
    iconClass: 'fas fa-comment-dots',
    component: TabComments,
    permission: Permissions.BO__USERS__VIEW_COMMENTS,
  },
  {
    name: 'users.wallet_operations',
    iconClass: 'fas fa-wallet',
    component: TabWalletOperations,
    permission: Permissions.BO__USERS__VIEW_WALLET_MONEY_OPERATIONS,
  },
  {
    name: 'users.wallet_p2p_operations',
    iconClass: 'fas fa-exchange-alt',
    component: TabWalletP2POperations,
    permission: Permissions.BO__USERS__VIEW_WALLET_P2P_OPERATIONS,
  },
  {
    name: 'users.aml',
    iconClass: 'fas fa-shield-alt',
    component: TabAML,
    permission: Permissions.BO__USERS__VIEW_AML,
  },
];

const User: React.FC = () => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();
  const [tab] = useQueryParam('tab', StringParam);
  const [user, setUser] = useState<UserResponseDto>();
  const [isUserEdit, setUserEdit] = useState(false);
  const { showModal } = useGlobalModalContext();
  const history = useHistory();

  useEffect(() => {
    setGlobalLoading(true);
    if (userId) {
      Api.user.fetchUser(userId).then((response) => {
        setUser(response);
        setGlobalLoading(false);
      });
    }
  }, [userId]);

  const impersonate = useCallback(
    async (event: any) => {
      event.preventDefault();
      const response = await Api.user.impersonateUser(userId);
      window.open(response.url, '_blank');
    },
    [userId],
  );

  const syncWallet = useCallback(
    async (event: any) => {
      event.preventDefault();
      return Api.user.syncUserWallet(userId).then((response) => {
        setUser(response);
        success(t('users.sync_wallet_success'));
      });
    },
    [userId, t],
  );

  const removeTwoFactorAuthForUser = useCallback(
    async (event: any) => {
      event.preventDefault();
      return Api.user.forceRemoveTwoFactorAuth(userId).then((response) => {
        setUser(response);
        success(t('users.two_factor_auth_removed'));
      });
    },
    [userId, t],
  );

  const suspend = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ReasonModal
          title={t('users.suspend')}
          body={t('users.suspend_body', { name: user?.email })}
          successMessage={t('users.suspend_success')}
          actionText={t('users.suspend')}
          onAction={async (reason) => {
            return Api.user.suspendUser(userId, { reason }).then((response) => setUser(response));
          }}
        />,
      );
    },
    [showModal, t, user?.email, userId],
  );

  const removeSuspension = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ActionModal
          title={t('users.remove_suspension')}
          body={t('users.remove_suspension_body', { name: user?.email })}
          successMessage={t('users.remove_suspension_success')}
          actionText={t('common.submit')}
          onAction={async () => {
            return Api.user.removeUserSuspension(userId).then((response) => setUser(response));
          }}
        />,
      );
    },
    [showModal, t, user?.email, userId],
  );

  const sendEmailConfirmation = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ActionModal
          title={t('users.send_email_confirmation')}
          body={t('users.send_email_confirmation_body', { name: user?.email })}
          successMessage={t('users.send_email_success')}
          actionText={t('common.send')}
          onAction={async () => {
            return Api.user.sendUserEmailConfirmation(userId).then((response) => setUser(response));
          }}
        />,
      );
    },
    [showModal, t, user?.email, userId],
  );

  const sendUserTemporaryPassword = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ActionModal
          title={t('users.send_temporary_password')}
          body={t('users.send_temporary_password_body', { name: user?.email })}
          successMessage={t('users.send_email_success')}
          actionText={t('common.send')}
          onAction={async () => {
            return Api.user.sendUserTemporaryPassword(userId);
          }}
        />,
      );
    },
    [showModal, t, user?.email, userId],
  );

  const addAccreditation = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ReasonModal
          title={t('users.add_accreditation')}
          body={t('users.add_accreditation_body', { name: user?.email })}
          successMessage={t('users.add_accreditation_success')}
          actionText={t('common.add')}
          onAction={async (reason) => {
            return Api.user
              .addUserAccreditation(userId, { reason })
              .then((response) => setUser(response));
          }}
        />,
      );
    },
    [showModal, t, user?.email, userId],
  );

  const addAffiliateAmbassador = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <AddAffiliateAmbassadorModal
          title={t('users.add_affiliate_ambassador')}
          body={t('users.add_affiliate_ambassador_body', { name: user?.email })}
          successMessage={t('users.add_affiliate_ambassador_success')}
          actionText={t('common.add')}
          onAction={async (request) => {
            return Api.user
              .addAffiliateAmbassador(userId, request)
              .then((response) => setUser(response));
          }}
        />,
      );
    },
    [showModal, t, user?.email, userId],
  );

  const editRemoveAffiliateAmbassador = useCallback(
    async (event: any) => {
      event.preventDefault();
      if (user?.affiliate_ambassador) {
        showModal(
          <EditAffiliateAmbassadorModal
            title={t('users.edit_affiliate_ambassador')}
            initialValues={user.affiliate_ambassador}
            body={t('users.edit_affiliate_ambassador_body', { name: user?.email })}
            successMessage={t('users.edit_affiliate_ambassador_success')}
            actionText={t('common.save')}
            onAction={async (request) => {
              if (user?.affiliate_ambassador?.id) {
                return Api.user
                  .editAffiliateAmbassador(userId, user?.affiliate_ambassador?.id, request)
                  .then((response) => setUser(response));
              }
            }}
            onDeleteAction={async () => {
              if (user?.affiliate_ambassador?.id) {
                return Api.user
                  .deleteAffiliateAmbassador(userId, user?.affiliate_ambassador?.id)
                  .then((response) => setUser(response));
              }
            }}
          />,
        );
      }
    },
    [showModal, t, user?.affiliate_ambassador, user?.email, userId],
  );

  const addGroupableInvestments = useCallback(
    async (event: any) => {
      event.preventDefault();
      Api.user.enableGroupableInvestments(userId).then((response) => {
        success(t('users.enabled_groupable_investments_success'));
        setUser(response);
      });
    },
    [userId, t],
  );

  const removeGroupableInvestments = useCallback(
    async (event: any) => {
      event.preventDefault();
      Api.user.disableGroupableInvestments(userId).then((response) => {
        success(t('users.disabled_groupable_investments_success'));
        setUser(response);
      });
    },
    [userId, t],
  );

  const removeAccreditation = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ReasonModal
          title={t('users.remove_accreditation')}
          body={t('users.remove_accreditation_body', { name: user?.email })}
          successMessage={t('users.remove_accreditation_success')}
          actionText={t('common.remove')}
          onAction={async (reason) => {
            return Api.user
              .removeUserAccreditation(userId, { reason })
              .then((response) => setUser(response));
          }}
        />,
      );
    },
    [showModal, t, user?.email, userId],
  );

  const deleteUser = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ActionModal
          title={t('users.delete')}
          body={t('users.delete_body', {
            id: (user?.private_id_prefix ?? '') + (user?.private_id_suffix ?? ''),
          })}
          successMessage={t('common.deleted_success')}
          actionText={t('common.delete')}
          onAction={async () => {
            return Api.user.deleteUser(userId).then(() => navigate(RouteList.USERS.LIST));
          }}
        />,
      );
    },
    [showModal, t, user?.private_id_prefix, user?.private_id_suffix, userId],
  );

  const customNotification = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <FormModal
          title={t('users.send_custom_notification')}
          size={'xl'}
          form={
            <CustomNotificationForm
              onSubmit={(request) => {
                return Api.sharedUsers.sendCustomNotification(request);
              }}
              initial={{
                user_id: userId,
                user_type: LegalEntityClassEnum.USER,
                notification: {
                  message_types: [],
                  subject: '',
                  message: '',
                },
              }}
              successMessage={t('users.send_custom_notification_success')}
              agreedToDirectMarketing={!!user?.direct_marketing?.via_email}
            />
          }
        />,
      );
    },
    [showModal, t, userId, user?.direct_marketing?.via_email],
  );

  return (
    <React.Fragment>
      <UserContext user={user} setUser={setUser}>
        <Container fluid>
          <Row>
            <Col md={1} className={'mb-4'}>
              <div>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Link to={history?.location?.state?.from ?? RouteList.USERS.LIST}>
                  <PrimaryButton title={t('common.back')} type={'button'} />
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className={'mb-4'}>
              <Card className="card card-blue">
                <CardBody>
                  <div className="text-center">
                    <Row>
                      <Col sm={2} className={'flex-center text-light'}>
                        <Row>
                          <CopyOnClick value={user?.id} />
                          <CopyOnClick
                            value={`${user?.private_id_prefix ?? ''}${
                              user?.private_id_suffix ?? ''
                            }`}
                          />
                        </Row>
                      </Col>
                      <Col sm={2} className={'flex-center text-light'}>
                        <Row>
                          <div>{t('users.wallet_balance')}</div>
                          <h3>{t('common.money', { value: user?.wallet_balance })}</h3>
                        </Row>
                      </Col>
                      <Col sm={3} className={'flex-center text-light'}>
                        <div>
                          <div>
                            <LegalEntityView entity={user?.legal_entity} />
                          </div>
                          <h2 className="mb-1">
                            {join([user?.first_name, user?.middle_name, user?.last_name], ' ')}
                            {user && user.roles && (
                              <div className={'my-2 d-flex gap-2 justify-content-center'}>
                                {user.roles.map((role) => (
                                  <Badge className={'bg-success'} key={role.id}>
                                    {role.name}
                                  </Badge>
                                ))}
                                {user.affiliate_ambassador && (
                                  <Badge className={'bg-warning'}>
                                    {t('users.affiliate_ambassador')}
                                  </Badge>
                                )}
                                {user.investor_properties.is_groupable_investments && (
                                  <Badge className={'bg-warning'}>
                                    {t('users.groupable_investments')}
                                  </Badge>
                                )}
                              </div>
                            )}
                          </h2>
                          <p>
                            <UserTypeView type={user?.type} />
                          </p>
                        </div>
                      </Col>
                      <Col sm={2} className={'flex-center text-light'}>
                        <div>
                          <Row>
                            <div>{t('users.historic_investments')}</div>
                            <h3>{t('common.money', { value: user?.historic_investments })}</h3>
                          </Row>
                          <Row>
                            <div>{t('users.active_investments')}</div>
                            <h3>{t('common.money', { value: user?.active_investments })}</h3>
                          </Row>
                        </div>
                      </Col>
                      <Col sm={2} className={'flex-center'}>
                        <div className={'card p-3'}>
                          <h5
                            className={
                              'actions d-flex gap-3 font-size-20 mb-0 justify-content-center'
                            }
                          >
                            <VerifiedBadge verified={!!user?.verified} />
                            <DirectMarketingBadge
                              via_email={user?.direct_marketing?.via_email ?? false}
                              via_phone={user?.direct_marketing?.via_phone ?? false}
                            />
                            <InvestedBadge val_invested={!!user?.invested} />
                            <WalletExistsBadge wallet_exists={!!user?.wallet_verified} />
                          </h5>
                        </div>
                      </Col>
                      <Col sm={1}>
                        <UncontrolledDropdown className="float-end">
                          <DropdownToggle tag="a" className="cursor text-light font-size-16" caret>
                            <i className="fas fa-ellipsis-h" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            {user && (
                              <>
                                <PermissionAction
                                  permissions={Permissions.BO__USERS__SEND_TEMPORARY_PASSWORD}
                                >
                                  <DropdownItem
                                    href="#"
                                    onClick={(e) => sendUserTemporaryPassword(e)}
                                  >
                                    {t('users.send_temporary_password')}
                                  </DropdownItem>
                                </PermissionAction>

                                <PermissionAction
                                  permissions={Permissions.BO__USERS__SEND_EMAIL_CONFIRMATION}
                                >
                                  <DropdownItem href="#" onClick={(e) => sendEmailConfirmation(e)}>
                                    {t('users.send_email_confirmation')}
                                  </DropdownItem>
                                </PermissionAction>
                              </>
                            )}
                            {user && (
                              <PermissionAction
                                permissions={Permissions.BO__USERS__ADD_ACCREDITATION}
                              >
                                <DropdownItem href="#" onClick={(e) => addAccreditation(e)}>
                                  {t('users.add_accreditation')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            {user && user.affiliate_ambassador === null && (
                              <PermissionAction
                                permissions={Permissions.BO__USERS__MANAGE_AFFILIATE_AMBASSADORS}
                              >
                                <DropdownItem href="#" onClick={(e) => addAffiliateAmbassador(e)}>
                                  {t('users.add_affiliate_ambassador')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            {user && user.affiliate_ambassador && (
                              <PermissionAction
                                permissions={Permissions.BO__USERS__MANAGE_AFFILIATE_AMBASSADORS}
                              >
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => editRemoveAffiliateAmbassador(e)}
                                >
                                  {t('users.edit_remove_affiliate_ambassador')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            {user && !user.investor_properties.is_groupable_investments && (
                              <PermissionAction
                                permissions={Permissions.BO__USERS__MANAGE_GROUPABLE_INVESTMENTS}
                              >
                                <DropdownItem href="#" onClick={(e) => addGroupableInvestments(e)}>
                                  {t('users.allow_groupable_investments')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            {user && user.investor_properties.is_groupable_investments && (
                              <PermissionAction
                                permissions={Permissions.BO__USERS__MANAGE_GROUPABLE_INVESTMENTS}
                              >
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => removeGroupableInvestments(e)}
                                >
                                  {t('users.disable_groupable_investments')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            {user && user.verified && (
                              <PermissionAction
                                permissions={Permissions.BO__USERS__REMOVE_ACCREDITATION}
                              >
                                <DropdownItem href="#" onClick={(e) => removeAccreditation(e)}>
                                  {t('users.remove_accreditation')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            <PermissionAction permissions={Permissions.BO__USERS__IMPERSONATE}>
                              <DropdownItem href="#" onClick={(e) => impersonate(e)}>
                                {t('users.impersonate')}
                              </DropdownItem>
                            </PermissionAction>
                            {user && user.has_primary_wallet && (
                              <PermissionAction permissions={Permissions.BO__USERS__SYNC_WALLET}>
                                <DropdownItem href="#" onClick={(e) => syncWallet(e)}>
                                  {t('users.sync_wallet_balance')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            {user && user.two_factor_enabled && (
                              <PermissionAction permissions={Permissions.BO__USERS__REMOVE_2FA}>
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => removeTwoFactorAuthForUser(e)}
                                >
                                  {t('users.remove_2fa_for_user')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            <PermissionAction permissions={Permissions.BO__USERS__SUSPEND}>
                              {user && !user?.suspended_at && (
                                <DropdownItem href="#" onClick={(e) => suspend(e)}>
                                  {t('users.suspend')}
                                </DropdownItem>
                              )}
                              {user && user?.suspended_at && (
                                <DropdownItem href="#" onClick={(e) => removeSuspension(e)}>
                                  {t('users.remove_suspension')}
                                </DropdownItem>
                              )}
                            </PermissionAction>
                            <PermissionAction permissions={Permissions.BO__USERS__DELETE_USER}>
                              <DropdownItem
                                href="#"
                                onClick={(e) => deleteUser(e)}
                                className="text-danger"
                              >
                                {t('users.delete')}
                              </DropdownItem>
                            </PermissionAction>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xs="12" className={'mb-4'}>
              <Card className="card h-100">
                <CardBody>
                  <div className="text-muted">
                    <Row className={'justify-content-end'}>
                      <PermissionAction permissions={Permissions.BO__USERS__SEND_NOTIFICATION}>
                        <Col md={2}>
                          <PrimaryButton
                            title={t('users.send_custom_notification')}
                            type={'button'}
                            onClick={(e) => customNotification(e)}
                          />
                        </Col>
                      </PermissionAction>
                      <Col md={2}>
                        <PrimaryButton
                          title={isUserEdit ? t('common.cancel_edit') : t('common.edit')}
                          type={'button'}
                          onClick={() => setUserEdit((prevState) => !prevState)}
                        />
                      </Col>
                      <Col md={2}>
                        <PrimaryButton
                          title={t('users.attach_role')}
                          type={'button'}
                          onClick={() => {
                            showModal(
                              <AttachRolesModal
                                onAction={(request) =>
                                  Api.user
                                    .attachUserRoles(userId, request)
                                    .then((user) => setUser(user))
                                }
                                user={user}
                              />,
                            );
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className={'mt-4'}>
                      {isUserEdit && user && (
                        <UserEdit
                          onUpdate={(request) =>
                            Api.user
                              .updateUser(user.id, request)
                              .then((response) => setUser(response))
                              .then(() => setUserEdit(false))
                          }
                          user={user}
                        />
                      )}
                    </Row>
                    <Row className="mt-4">
                      <SidebarDataRow label={t('users.email')} value={user?.email} />
                      <SidebarDataRow label={t('users.first_name')} value={user?.first_name} />
                      <SidebarDataRow label={t('users.middle_name')} value={user?.middle_name} />
                      <SidebarDataRow label={t('users.last_name')} value={user?.last_name} />
                      <SidebarDataRow label={t('users.phone')} value={user?.phone} />
                      <SidebarDataRow
                        label={t('users.dob')}
                        isVisible={!!user?.dob}
                        value={t('common.date', { date: user?.dob })}
                      />
                      <SidebarDataRow label={t('users.gov_code')} value={user?.gov_code} />
                      <SidebarDataRow
                        label={t('users.residence')}
                        value={user?.residence}
                        displayValue={
                          user?.residence ? <CountryLabel code={user?.residence} /> : undefined
                        }
                      />
                      <SidebarDataRow label={t('users.city')} value={user?.city} />
                      <SidebarDataRow label={t('users.street')} value={user?.street} />
                      <SidebarDataRow label={t('users.house_number')} value={user?.house_number} />
                      <SidebarDataRow
                        label={t('users.personal_income_tax')}
                        value={
                          user?.personal_income_tax != null ? `${user?.personal_income_tax}%` : '-'
                        }
                      />
                      <SidebarDataRow
                        label={t('users.email_verified_at')}
                        isVisible={!!user?.email_verified_at}
                        value={t('common.date_full', { date: user?.email_verified_at })}
                      />
                      <SidebarDataRow
                        label={t('users.suspended_at')}
                        isVisible={!!user?.suspended_at}
                        value={t('common.date_full', { date: user?.suspended_at })}
                      />
                      <SidebarDataRow
                        label={t('users.suspension_reason')}
                        value={user?.suspended_reason}
                      />
                      <SidebarDataRow
                        label={t('users.created_at')}
                        isVisible={!!user?.created_at}
                        value={t('common.date_full', { date: user?.created_at })}
                      />
                      <SidebarDataRow
                        label={t('users.two_factor_enabled')}
                        isVisible={true}
                        value={user?.two_factor_enabled ? t('common.yes') : t('common.no')}
                      />
                      <SidebarDataRow
                        label={t('users.investor_experience_level')}
                        isVisible={true}
                        value={
                          user?.investor_properties.investor_experience_level
                            ? t(
                                'users.investor_experience_level.' +
                                  user?.investor_properties.investor_experience_level,
                              )
                            : '-'
                        }
                      />
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-0">
                {user && (
                  <TabData tabs={Tabs} props={{ id: user.id, user: user }} initialActive={tab} />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </UserContext>
    </React.Fragment>
  );
};

export default User;
