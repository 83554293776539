import React from 'react';
import { Container } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DashboardCardGrid } from './DashboardCardGrid';
import { useTranslation } from 'react-i18next';
import DashboardCharts from 'src/pages/Common/Dashboard/DashboardCharts';
import Loader from 'src/components/Loader';
import { useCurrentMonthStatisticQuery } from 'src/api/queries/statistics/useCurrentMonthStatisticQuery';
import { useLastMonthStatisticQuery } from 'src/api/queries/statistics/useLastMonthStatisticQuery';
import { useGenericStatisticQuery } from 'src/api/queries/statistics/useGenericStatisticQuery';
import { DashboardInvestorsCategories } from 'src/pages/Common/Dashboard/DashboardInvestorsCategories';

const Dashboard: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  const currentMonthStatisticQuery = useCurrentMonthStatisticQuery();
  const lastMonthStatisticQuery = useLastMonthStatisticQuery();
  const genericStatisticQuery = useGenericStatisticQuery();

  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <h3 className="card-ml-1">{t('dashboard.current_month')}</h3>
          {currentMonthStatisticQuery.isLoading || currentMonthStatisticQuery.data === undefined ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
            <>
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.registered_users'),
                    value: currentMonthStatisticQuery.data.registered_users,
                  },
                  {
                    title: t('dashboard.failed_identity_tries'),
                    value: currentMonthStatisticQuery.data.failed_identity_tries,
                  },
                  {
                    title: t('dashboard.successful_identity_tries'),
                    value: currentMonthStatisticQuery.data.successful_identity_tries,
                  },
                  {
                    title: t('dashboard.non_resident_count'),
                    value: currentMonthStatisticQuery.data.non_resident_count,
                  },
                ]}
              />
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.investors_count'),
                    value: currentMonthStatisticQuery.data.investors_count,
                  },
                  {
                    title: t('dashboard.collected_money_sum'),
                    value: t('common.money', {
                      value: currentMonthStatisticQuery.data.collected_money_sum,
                    }),
                  },
                  {
                    title: t('dashboard.project_count'),
                    value: currentMonthStatisticQuery.data.project_count,
                  },
                  {
                    title: t('dashboard.paid_interest_rate_sum'),
                    value: t('common.money', {
                      value: currentMonthStatisticQuery.data.paid_interest_rate_sum,
                    }),
                  },
                ]}
              />
            </>
          )}
          <h3 className="card-ml-1">{t('dashboard.previous_month')}</h3>
          {lastMonthStatisticQuery.isLoading || lastMonthStatisticQuery.data === undefined ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
            <>
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.registered_users'),
                    value: lastMonthStatisticQuery.data.registered_users,
                  },
                  {
                    title: t('dashboard.failed_identity_tries'),
                    value: lastMonthStatisticQuery.data.failed_identity_tries,
                  },
                  {
                    title: t('dashboard.successful_identity_tries'),
                    value: lastMonthStatisticQuery.data.successful_identity_tries,
                  },
                  {
                    title: t('dashboard.non_resident_count'),
                    value: lastMonthStatisticQuery.data.non_resident_count,
                  },
                ]}
              />
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.investors_count'),
                    value: lastMonthStatisticQuery.data.investors_count,
                  },
                  {
                    title: t('dashboard.collected_money_sum'),
                    value: t('common.money', {
                      value: lastMonthStatisticQuery.data.collected_money_sum,
                    }),
                  },
                  {
                    title: t('dashboard.project_count'),
                    value: lastMonthStatisticQuery.data.project_count,
                  },
                  {
                    title: t('dashboard.paid_interest_rate_sum'),
                    value: t('common.money', {
                      value: lastMonthStatisticQuery.data.paid_interest_rate_sum,
                    }),
                  },
                ]}
              />
            </>
          )}
          <h3 className="card-ml-1">{t('dashboard.all_data')}</h3>
          {genericStatisticQuery.isLoading || genericStatisticQuery.data === undefined ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
            <>
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.registered_users'),
                    value: genericStatisticQuery.data.registered_users,
                  },
                  {
                    title: t('dashboard.failed_identity_tries'),
                    value: genericStatisticQuery.data.failed_identity_tries,
                  },
                  {
                    title: t('dashboard.successful_identity_tries'),
                    value: genericStatisticQuery.data.successful_identity_tries,
                  },
                  {
                    title: t('dashboard.non_resident_count'),
                    value: genericStatisticQuery.data.non_resident_count,
                  },
                ]}
              />
              <DashboardCardGrid
                cards={[
                  {
                    title: t('dashboard.investors_count'),
                    value: genericStatisticQuery.data.investors_count,
                  },
                  {
                    title: t('dashboard.collected_money_sum'),
                    value: t('common.money', {
                      value: genericStatisticQuery.data.collected_money_sum,
                    }),
                  },
                  {
                    title: t('dashboard.project_count'),
                    value: genericStatisticQuery.data.project_count,
                  },
                  {
                    title: t('dashboard.paid_interest_rate_sum'),
                    value: t('common.money', {
                      value: genericStatisticQuery.data.paid_interest_rate_sum,
                    }),
                  },
                ]}
              />
            </>
          )}
          <DashboardCharts />
          <DashboardInvestorsCategories />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Dashboard);
