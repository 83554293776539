import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput, { ReactSelectOption, ReactSelectProps } from './SelectInput';
import { BonusTypeEnum } from 'src/helpers/Enums/BonusSystem/TypeEnum';

const SelectBonusTypeInput: React.FC<Omit<ReactSelectProps, 'options'>> = ({
  name,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();

  const [options] = useState<ReactSelectOption[]>([
    {
      label: t(`bonus_system.types.${BonusTypeEnum.BONUS_CODE}`),
      value: BonusTypeEnum.BONUS_CODE,
    },
    {
      label: t(`bonus_system.types.${BonusTypeEnum.CLIENT_SUPPORT}`),
      value: BonusTypeEnum.CLIENT_SUPPORT,
    },
    {
      label: t(`bonus_system.types.${BonusTypeEnum.CASHBACK}`),
      value: BonusTypeEnum.CASHBACK,
    },
  ]);

  return <SelectInput name={name} options={options} placeholder={placeholder} {...props} />;
};

export default SelectBonusTypeInput;
