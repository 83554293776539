import Client from 'src/api/services/client';
import {
  FilterReportsRequestDto,
  GenerateReportRequestDto,
  ReportListResponseDto,
  UpdateReportRequestDto,
} from 'src/types/api/reports';
import { PaginationData } from 'src/types';

class Reports extends Client {
  api = {
    GENERATE_REPORT: `${this.http.baseUrl}/reports/generate`,
    FILTER_REPORTS: `${this.http.baseUrl}/reports/filter`,
    UPDATE_REPORT: `${this.http.baseUrl}/reports/report/{reportId}`,
    DELETE_REPORT: `${this.http.baseUrl}/reports/report/{reportId}`,
  };

  generateReport = (request: GenerateReportRequestDto): Promise<any> => {
    return this.http.post(this.api.GENERATE_REPORT, request);
  };

  fetchFilteredReports = (
    request: FilterReportsRequestDto | undefined = undefined,
  ): Promise<PaginationData<ReportListResponseDto>> => {
    return this.http.get(this.api.FILTER_REPORTS, { params: request });
  };

  updateReport = (
    reportId: string,
    request: UpdateReportRequestDto | undefined = undefined,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPDATE_REPORT, { reportId });

    return this.http.put(url, request);
  };

  deleteReport = (reportId: string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_REPORT, { reportId });

    return this.http.delete(url);
  };
}

export default Reports;
