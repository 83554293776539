import { queries } from '../index';
import Api from '../../index';
import { useQuery } from 'react-query';

const useShortNotificationsQuery = () => {
  return useQuery(
    [queries.notifications],
    async () => {
      return await Api.notifications.fetchShortNotifications();
    },
    {
      refetchInterval: 60 * 1000, // every minute fetch latest notifications
      staleTime: 0,
      enabled: true,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};

export { useShortNotificationsQuery };
