import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useGlobalModalContext } from '../Modal/GlobalModal';
import NotificationModal from './NotificationModal';
import SanitizedHtmlBlock from '../SanitizedHtmlBlock';
import { NotificationShortResponseDto } from 'src/types/api/notifications';
import { useNotifications } from './NotificationProvider';
import useWindowSize from 'src/helpers/useWindowSize';
import { BootstrapContainerWidthEnum } from 'src/helpers/Enums/BootstrapContainerWithEnum';
import timeAgo from 'src/helpers/timeAgo';
import { RouteList } from 'src/routes';

type Props = NotificationShortResponseDto;

const NotificationsSidebarItem: React.FC<Props> = ({ ...props }) => {
  const [hasBeenRead, setHasBeenRead] = useState<boolean | null>(props.has_been_read);
  const [hasBeenSelected, setHasBeenSelected] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  const { selectedNotification } = useNotifications();
  const { width } = useWindowSize();
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    setHasBeenRead(props.has_been_read);
  }, [props.has_been_read]);

  useEffect(() => {
    if (selectedNotification?.id === props.id) {
      if (clicked && width && width < BootstrapContainerWidthEnum.Md && selectedNotification) {
        showModal(
          <NotificationModal
            title={selectedNotification.title}
            content={selectedNotification.body}
            cta={selectedNotification.cta}
            created_at={selectedNotification.created_at}
          />,
        );
        setClicked(false);
      }

      setHasBeenSelected(true);
      setHasBeenRead(true);
    } else {
      setHasBeenSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, selectedNotification, clicked]);

  const onNotificationClick = useCallback(() => {
    setClicked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, selectedNotification]);

  return (
    <Link
      className={`notifications-sidebar-item ${hasBeenRead ? 'text-muted' : 'unread'} ${
        hasBeenSelected ? 'notifications-sidebar-item-active' : ''
      }`}
      to={{
        pathname: RouteList.NOTIFICATIONS.LIST,
        hash: props.id,
      }}
      onClick={onNotificationClick}
    >
      <h6 className={'title mb-1'}>{props.title}</h6>
      <p className="mb-1 d-flex align-items-center">
        <i className={'far fa-clock align-middle me-1'} />
        {timeAgo(props.created_at)}
      </p>
      <SanitizedHtmlBlock content={props.body ?? ''} />
    </Link>
  );
};

export default NotificationsSidebarItem;
