import create from 'zustand';
import { ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';

interface Actions {
  refreshTable: () => void;
  setType: (type: ReportTypeEnum) => void;
}

interface State {
  type: ReportTypeEnum | undefined;
  refresh: boolean;
}

const initialState: State = {
  type: undefined,
  refresh: false,
};

const useReportTypeStore = create<State & Actions>((set) => ({
  ...initialState,

  refreshTable: () =>
    set((s) => ({
      ...s,
      refresh: !s.refresh,
    })),

  setType: (type) =>
    set((s) => ({
      ...s,
      type: type,
    })),
}));

export default useReportTypeStore;
