import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setGlobalLoading } from 'src/modules/app/actions';
import Api from 'src/api';
import {
  DocumentTemplateResponseDto,
  UpdateDocumentTemplateRequestDto,
} from 'src/types/api/documentTemplates';
import TabData, { BaseTabProps, TabInfo } from 'src/components/Tabs';
import { Permissions } from 'src/helpers/auth/permissions';
import TabTemplateData from 'src/pages/DocumentTemplates/Tabs/TabTemplateData';
import TabUploads from 'src/pages/DocumentTemplates/Tabs/TabUploads';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import EditDocumentTemplateModal from 'src/pages/DocumentTemplates/EditDocumentTemplateModal';
import PermissionAction from 'src/components/PermissionAction';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import DocumentTemplateProvider from 'src/pages/DocumentTemplates/DocumentTemplateContext';
import TabActionHistory from 'src/pages/DocumentTemplates/Tabs/TabActionHistory';
import TabLegend from './Tabs/TabLegend';

const Tabs: TabInfo<BaseTabProps>[] = [
  {
    name: 'document_templates.info',
    iconClass: 'fas fa-file',
    component: TabTemplateData,
    permission: Permissions.BO__DOCUMENT_TEMPLATES__VIEW,
  },
  {
    name: 'document_templates.legend',
    iconClass: 'fas fa-list',
    component: TabLegend,
    permission: Permissions.BO__DOCUMENT_TEMPLATES__VIEW,
  },
  {
    name: 'document_templates.upload',
    iconClass: 'fas fa-upload',
    component: TabUploads,
    permission: Permissions.BO__DOCUMENT_TEMPLATES__VIEW,
  },
  {
    name: 'document_templates.action_history',
    iconClass: 'fas fa-history',
    component: TabActionHistory,
    permission: Permissions.BO__DOCUMENT_TEMPLATES__VIEW__ACTIONS,
  },
];

const DocumentTemplate = () => {
  const { t } = useTranslation();
  const { documentTemplateId } = useParams<{ documentTemplateId: string }>();
  const [documentTemplate, setDocumentTemplate] = useState<DocumentTemplateResponseDto>();
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    setGlobalLoading(true);
    if (documentTemplateId) {
      Api.documentTemplates.fetchDocumentTemplate(documentTemplateId).then((response) => {
        setDocumentTemplate(response);
        setGlobalLoading(false);
      });
    }
  }, [documentTemplateId]);

  if (!documentTemplate) {
    return <></>;
  }

  return (
    <>
      <DocumentTemplateProvider
        documentTemplate={documentTemplate}
        setDocumentTemplate={setDocumentTemplate}
      >
        <Container fluid>
          <Row>
            <Col xs="12" className={'mb-4'}>
              <Card className="card h-100">
                <CardBody>
                  <Row>
                    <Col>
                      <div>{t('table.name')}</div>
                      <div>{documentTemplate.name}</div>
                    </Col>
                    <Col>
                      <div>{t('table.version')}</div>
                      <div>{documentTemplate.version}</div>
                    </Col>
                    <Col>
                      <div>{t('table.class_name')}</div>
                      <div>{documentTemplate.class_name}</div>
                    </Col>
                    <Col className={'ms-3'}>
                      <PermissionAction permissions={Permissions.BO__DOCUMENT_TEMPLATES__EDIT}>
                        <PrimaryButton
                          title={t('common.edit')}
                          type={'button'}
                          onClick={() => {
                            showModal(
                              <EditDocumentTemplateModal
                                documentTemplate={documentTemplate}
                                onSubmit={(
                                  request: UpdateDocumentTemplateRequestDto,
                                ): Promise<any> => {
                                  return Api.documentTemplates
                                    .updateDocumentTemplate(documentTemplate.id, request)
                                    .then((response: DocumentTemplateResponseDto) =>
                                      setDocumentTemplate(response),
                                    );
                                }}
                              />,
                            );
                          }}
                        />
                      </PermissionAction>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-4">
                <CardBody>
                  <TabData tabs={Tabs} vertical={true} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </DocumentTemplateProvider>
    </>
  );
};

export default DocumentTemplate;
