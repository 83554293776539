import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownItem,
  DropdownMenu,
  DropdownProps,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import BorrowerPayoutContext, {
  BorrowerPayoutContextState,
  withBorrowerPayout,
} from 'src/pages/Payment/BorrowerPayouts/Update/BorrowerPayoutContext';
import { BorrowerPayoutStatusEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutStatusEnum';
import RecalculateBorrowerPayoutModal, {
  ConfirmRecalculateBorrowerPayoutRequestDto,
} from 'src/pages/Payment/BorrowerPayouts/Update/Modal/RecalculateBorrowerPayoutModal';
import Api from 'src/api';
import ConfirmBorrowerPayoutModal from 'src/pages/Payment/BorrowerPayouts/Update/Modal/ConfirmBorrowerPayoutModal';
import { ImportInvestmentPayouts } from 'src/pages/Payment/BorrowerPayouts/Update/ImportInvestmentPayoutsModal';
import DebtWarningModal from './Modal/DebtWarningModal';
import {
  BorrowerPayoutResponseDto,
  PrecalculateBorrowerPayoutResponseDto,
} from 'src/types/api/payments/borrowerPayouts';
import usePusherStoreState from 'src/modules/pusheStoreState';
import { success, error } from 'src/services/toastr';
import LaravelPusher from 'src/services/LaravelPusher';
import { LaravelPusherEventEnum } from 'src/helpers/Enums/Pusher/LaravelPusherEventEnum';

interface PusherBorrowerPayoutResponse {
  data: BorrowerPayoutResponseDto | null;
  success: boolean;
  error: string | null;
}

interface Props extends DropdownProps, BorrowerPayoutContextState {}

const BorrowerPayoutActionsDropdown: React.FC<Props> = ({
  borrowerPayout,
  setBorrowerPayout,
  props,
}) => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useGlobalModalContext();
  const { borrowerPayoutRecalculateLoading, setBorrowerPayoutRecalculateLoading } =
    usePusherStoreState();

  const isRecalculateButtonVisible = (): boolean => {
    return !!(
      borrowerPayout &&
      borrowerPayout.status !== BorrowerPayoutStatusEnum.PAID &&
      (borrowerPayout.status === BorrowerPayoutStatusEnum.SCHEDULED ||
        borrowerPayout.status === BorrowerPayoutStatusEnum.RECEIVED) &&
      borrowerPayout.hash === null
    );
  };

  useEffect(() => {
    if (borrowerPayout && isRecalculateButtonVisible()) {
      const laravelPusher = new LaravelPusher(
        'borrower-payout-' + borrowerPayout.id,
        LaravelPusherEventEnum.BorrowerPayoutRecalculationCompletedEvent,
        (data: PusherBorrowerPayoutResponse) => {
          if (data.success && data.data) {
            setBorrowerPayout(data.data);
            setBorrowerPayoutRecalculateLoading(false);
            success(t('payments.borrower_payouts.recalculate.success'));
          } else {
            setBorrowerPayoutRecalculateLoading(false);
            error(data.error);
          }
        },
      );

      return () => {
        laravelPusher.stopListening(
          LaravelPusherEventEnum.BorrowerPayoutRecalculationCompletedEvent,
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, borrowerPayout]);

  if (!borrowerPayout || borrowerPayoutRecalculateLoading) {
    return <></>;
  }

  const handleRecalculateBorrowerPayout = (id: string, request: any) => {
    return Api.payments.borrowerPayouts.recalculateBorrowerPayout(id, request).then(() => {
      hideModal();
    });
  };

  const syncWallets = () => {
    return Api.payments.borrowerPayouts
      .syncOwnerAndProjectWallets(borrowerPayout.id)
      .then((res) => {
        setBorrowerPayout({
          ...borrowerPayout,
          owner_wallet: res.owner_wallet,
          project_wallet: res.project_wallet,
        });
        success(t('payments.borrower_payouts.sync_wallets.success'));
      });
  };

  const initDebtWarning = (
    debtInfo: PrecalculateBorrowerPayoutResponseDto,
    request: ConfirmRecalculateBorrowerPayoutRequestDto,
  ) => {
    return showModal(
      <BorrowerPayoutContext borrowerPayout={borrowerPayout} setBorrowerPayout={setBorrowerPayout}>
        <DebtWarningModal
          debtInfo={debtInfo}
          borrowerPayout={borrowerPayout}
          onAction={async () => {
            setBorrowerPayoutRecalculateLoading(true);
            return handleRecalculateBorrowerPayout(borrowerPayout.id, request);
          }}
          onException={async () => {
            setBorrowerPayoutRecalculateLoading(false);
            hideModal();
          }}
        ></DebtWarningModal>
      </BorrowerPayoutContext>,
    );
  };
  const initiateRecalculation = () => {
    showModal(
      <BorrowerPayoutContext borrowerPayout={borrowerPayout} setBorrowerPayout={setBorrowerPayout}>
        <RecalculateBorrowerPayoutModal
          borrowerPayout={borrowerPayout}
          onAction={async (request) => {
            setBorrowerPayoutRecalculateLoading(true);
            return Api.payments.borrowerPayouts
              .precalculateBorrowerPayout(borrowerPayout.id, request)
              .then((precalculateRes) => {
                if (precalculateRes.debt_amount <= 0) {
                  return handleRecalculateBorrowerPayout(borrowerPayout.id, request);
                } else {
                  setBorrowerPayoutRecalculateLoading(false);
                  initDebtWarning(precalculateRes, request);
                }
              });
          }}
          onException={async () => {
            setBorrowerPayoutRecalculateLoading(false);
            hideModal();
          }}
        />
      </BorrowerPayoutContext>,
    );
  };

  const importInvestmentPayouts = () => {
    showModal(
      <BorrowerPayoutContext borrowerPayout={borrowerPayout} setBorrowerPayout={setBorrowerPayout}>
        <ImportInvestmentPayouts />
      </BorrowerPayoutContext>,
    );
  };

  const confirmBorrowerPayout = () => {
    showModal(
      <BorrowerPayoutContext borrowerPayout={borrowerPayout} setBorrowerPayout={setBorrowerPayout}>
        <ConfirmBorrowerPayoutModal
          borrowerPayout={borrowerPayout}
          onAction={async () => {
            return Api.payments.borrowerPayouts
              .confirmBorrowerPayout(borrowerPayout.id, {
                hash: borrowerPayout.hash,
              })
              .then((response) => {
                setBorrowerPayout(response);
              });
          }}
          onException={async () => {
            hideModal();
          }}
        />
      </BorrowerPayoutContext>,
    );
  };

  if (borrowerPayout.status === BorrowerPayoutStatusEnum.PAID) {
    return <></>;
  }

  return borrowerPayout.status === BorrowerPayoutStatusEnum.SCHEDULED ||
    borrowerPayout.status === BorrowerPayoutStatusEnum.RECEIVED ? (
    <React.Fragment>
      <UncontrolledDropdown {...props}>
        <DropdownToggle tag="a" className="cursor text-dark font-size-16" caret>
          <i className="fas fa-ellipsis-h" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <PermissionAction
            permissions={Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__CONFIRM_BORROWER_PAYOUT}
          >
            {borrowerPayout.hash === null && (
              <>
                <DropdownItem onClick={initiateRecalculation}>
                  {t('payments.borrower_payouts.recalculate')}
                </DropdownItem>
              </>
            )}
            {borrowerPayout.hash !== null && (
              <DropdownItem onClick={confirmBorrowerPayout}>
                {t('payments.borrower_payouts.confirm')}
              </DropdownItem>
            )}
          </PermissionAction>
          <PermissionAction
            permissions={Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__IMPORT_INVESTMENT_PAYOUTS}
          >
            {borrowerPayout.hash === null && (
              <DropdownItem onClick={importInvestmentPayouts}>
                {t('payments.borrower_payouts.import')}
              </DropdownItem>
            )}
          </PermissionAction>

          {borrowerPayout.hash === null && (
            <DropdownItem onClick={syncWallets}>
              {t('payments.borrower_payouts.sync_wallets')}
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  ) : null;
};

export const BorrowerPayoutActions = withBorrowerPayout(BorrowerPayoutActionsDropdown);
