import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Form/TextInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { UseNumbers } from 'src/helpers/useNumbers';
import SelectRealEstateTypeInput from 'src/components/Form/Select/SelectRealEstateTypeInput';
import { SingleSecurityDepositProp } from 'src/pages/Application/Update/Tabs/Securities/SingleSecurityDeposit';
import { SecurityDepositActionType } from 'src/helpers/Enums/SecurityDepositActionType';
import { useField } from 'formik';
import { ApplicationSingleSecurityDepositDto } from 'src/types/api/applications';

const SplitSingleSecurityDeposit: React.FC<SingleSecurityDepositProp> = ({
  index,
  formikArrayHelpers,
}) => {
  const { t } = useTranslation();
  const [, , h] = useField(`security_deposits[${index}].action_type`);
  const [securityDepositsField, , securityDeposits] = useField(`security_deposits`);

  const realEstateType = `security_deposits[${index}].real_estate_type`;
  const realEstateUnique = `security_deposits[${index}].real_estate_unique`;
  const realEstatePlot = `security_deposits[${index}].real_estate_plot`;
  const realEstateAddress = `security_deposits[${index}].real_estate_address`;
  const realEstateValue = `security_deposits[${index}].real_estate_value`;
  const realEstateAppraiser = `security_deposits[${index}].real_estate_appraiser`;
  const realEstateAppraiserAt = `security_deposits[${index}].real_estate_appraised_at`;

  const resetType = useCallback(() => {
    securityDeposits.setValue(
      securityDepositsField.value.filter(
        (d: ApplicationSingleSecurityDepositDto) =>
          d.action_type !== SecurityDepositActionType.SPLIT_MUTATED_DEPOSIT,
      ),
    );

    h.setValue(null);
  }, [h, securityDeposits, securityDepositsField.value]);

  return (
    <>
      <tr className={'table-tr-distort-fix table-active'}>
        <td>
          <SelectRealEstateTypeInput
            name={realEstateType}
            isClearable={false}
            isDisabled={true}
            placeholder={t('label.real_estate_type')}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateUnique}
            placeholder={t('label.real_estate_unique')}
            hideLabel={true}
            disabled={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstatePlot}
            placeholder={t('label.real_estate_plot')}
            hideLabel={true}
            disabled={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateAddress}
            placeholder={t('label.real_estate_address')}
            hideLabel={true}
            disabled={true}
          />
        </td>
        <td>
          <TextInput
            type={'number'}
            step={'any'}
            name={realEstateValue}
            placeholder={t('label.real_estate_value')}
            hideLabel={true}
            disabled={true}
            onKeyPress={UseNumbers.preventNonNumericalInput}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateAppraiser}
            placeholder={t('label.real_estate_appraiser')}
            hideLabel={true}
            disabled={true}
          />
        </td>
        <td className={'align-top'}>
          <DatePickerInput
            name={realEstateAppraiserAt}
            placeholder={t('label.real_estate_appraised_at')}
            hideLabel={true}
            disabled={true}
          />
        </td>
        <td className={'align-middle'}>
          <div className={'d-flex align-items-center'} style={{ boxShadow: 'none' }}>
            <PrimaryButton
              type={'button'}
              className={'btn btn-success m-0 me-2'}
              title={t('common.add')}
              onClick={() =>
                formikArrayHelpers?.insert(index + 1, {
                  action_type: SecurityDepositActionType.SPLIT_MUTATED_DEPOSIT,
                })
              }
            />
            <div>
              <PrimaryButton
                type={'button'}
                className={'btn btn-warning m-0'}
                title={t(`applications.stage_action_cancel.split`)}
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => resetType()}
              />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SplitSingleSecurityDeposit;
