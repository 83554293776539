import React from 'react';
import { Container } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DashboardWalletInformationBlock } from 'src/pages/Common/Dashboard/WalletInfo/DashboardWalletInformationBlock';
import { DashboardRichestWalletsBlock } from 'src/pages/Common/Dashboard/WalletInfo/DashboardRichestWalletsBlock';
import WalletInfoDashboardCharts from 'src/pages/Common/Dashboard/WalletInfo/WalletInfoDashboardCharts';

const WalletInfo: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <DashboardWalletInformationBlock />
          <DashboardRichestWalletsBlock />
          <WalletInfoDashboardCharts />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(WalletInfo);
