import React, { useCallback, useEffect, useState } from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { ApplicationUpdateCreditDetailsDevelopmentRequestDto } from 'src/types/api/applications';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { Col, ColProps, Form, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import SaveButton from 'src/components/Form/SaveButton';
import { transformErrors } from 'src/helpers';
import { UseNumbers } from 'src/helpers/useNumbers';

interface Props extends BaseTabProps, ApplicationContextState {
  showSubmit?: boolean;
}

const INITIAL_REQUEST: ApplicationUpdateCreditDetailsDevelopmentRequestDto = {
  project_address: '',
  object_name: '',
  project_status: '',
  start_construction_at: '',
  end_construction_at: '',
  number_of_re: 0,
  total_area: 0,
  useful_area: 0,
  project_plot_price: 0,
  project_engineering_price: 0,
  project_documentation_price: 0,
  project_construction_price: 0,
  existing_investment_plot_price: 0,
  existing_investment_engineering_price: 0,
  existing_investment_documentation_price: 0,
  existing_investment_construction_price: 0,
  presale_contracts_amount: 0,
  presale_contracts: 0,
  notarised_contracts_amount: 0,
  notarised_contracts: 0,
  building_permit: false,
};

const RealEstateDevelopment: React.FC<Props> = ({ application, setApplication, showSubmit }) => {
  const { t } = useTranslation();

  const [request, setRequest] =
    useState<ApplicationUpdateCreditDetailsDevelopmentRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!application) return;

    setRequest({
      project_address: application.development?.project_address,
      object_name: application.development?.object_name,
      project_status: application.development?.project_status,
      start_construction_at: application.development?.start_construction_at,
      end_construction_at: application.development?.end_construction_at,
      number_of_re: application.development?.number_of_re,
      total_area: application.development?.total_area,
      useful_area: application.development?.useful_area,
      project_plot_price: application.estimate?.project_plot_price,
      project_engineering_price: application.estimate?.project_engineering_price,
      project_documentation_price: application.estimate?.project_documentation_price,
      project_construction_price: application.estimate?.project_construction_price,
      existing_investment_plot_price: application.estimate?.existing_investment_plot_price,
      existing_investment_engineering_price:
        application.estimate?.existing_investment_engineering_price,
      existing_investment_documentation_price:
        application.estimate?.existing_investment_documentation_price,
      existing_investment_construction_price:
        application.estimate?.existing_investment_construction_price,
      presale_contracts_amount: application.estimate?.presale_contracts_amount,
      presale_contracts: application.estimate?.presale_contracts,
      notarised_contracts_amount: application.estimate?.notarised_contracts_amount,
      notarised_contracts: application.estimate?.notarised_contracts,
      building_permit: application.estimate?.building_permit ?? false,
    });
  }, [application]);

  const CreditSchema = Yup.object().shape({});

  const onSubmit = useCallback(
    async (
      request: any,
      helpers: FormikHelpers<ApplicationUpdateCreditDetailsDevelopmentRequestDto>,
    ) => {
      if (!application) return;

      try {
        const response = await Api.applications.UpdateCreditDetailsDevelopment(
          application.id,
          request,
        );
        setApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [application, setApplication, t],
  );

  const colProps2: ColProps = {
    md: 6,
    className: 'mb-4',
  };

  const colProps3: ColProps = {
    md: 4,
    className: 'mb-4',
  };

  const colProps4: ColProps = {
    md: 3,
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.type.real_estate_development')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton
              title={t('applications.tabs_submit.credit_details')}
              tabName={'credit_details'}
            />
          </div>
        )}
      </div>
      <hr />
      <Formik
        initialValues={request}
        enableReinitialize={true}
        validationSchema={CreditSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colProps2}>
                  <TextInput type={'text'} name={'project_address'} />
                </Col>
                <Col {...colProps2}>
                  <TextInput type={'text'} name={'object_name'} />
                </Col>
                <Col {...colProps2}>
                  <TextInput type={'text'} name={'project_status'} />
                </Col>
                <Col {...colProps2}>
                  <DatePickerInput name={'start_construction_at'} />
                </Col>
                <Col {...colProps2}>
                  <DatePickerInput name={'end_construction_at'} />
                </Col>
                <Col {...colProps3}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'number_of_re'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps3}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'total_area'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps3}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'useful_area'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps4}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'project_plot_price'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps4}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'project_engineering_price'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps4}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'project_documentation_price'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps4}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'project_construction_price'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps4}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'existing_investment_plot_price'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps4}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'existing_investment_engineering_price'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps4}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'existing_investment_documentation_price'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps4}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'existing_investment_construction_price'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps2}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'presale_contracts_amount'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps2}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'presale_contracts'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps2}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'notarised_contracts_amount'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps2}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'notarised_contracts'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
              </Row>
              <div className={'mt-4 mb-4'}>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withApplication(RealEstateDevelopment);
