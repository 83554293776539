import Client from './client';
import { ImportResponseDto } from 'src/types/api/import';

class Import extends Client {
  api = {
    FETCH_IMPORT: `${this.http.baseUrl}/imports/import/{importId}`,
  };

  fetchImport = (importId: number | string): Promise<ImportResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_IMPORT, { importId });

    return this.http.get(url);
  };
}

export default Import;
