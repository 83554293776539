import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { setGlobalLoading } from 'src/modules/app/actions';
import { FormikHelpers } from 'formik';
import Api from 'src/api';
import { RouteList } from 'src/routes';
import { toast } from 'react-toastify';
import success = toast.success;
import { navigate } from 'src/helpers/navigate';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import {
  ExternalNewsResponseDto,
  SaveExternalNewsRequestDto,
} from 'src/types/api/landingPage/externalNews';
import ExternalNewsForm from 'src/pages/LandingPage/ExternalNews/ExternalNewsForm';

const ExternalNewsUpdate: React.FC = () => {
  const { t } = useTranslation();
  const { externalNewsId } = useParams<{ externalNewsId: string }>();
  const [externalNewsResponse, setExternalNewsResponse] = useState<ExternalNewsResponseDto>();

  const onSubmit = useCallback(
    async (
      request: SaveExternalNewsRequestDto,
      helpers: FormikHelpers<SaveExternalNewsRequestDto>,
    ) => {
      try {
        await Api.externalNews.updateExternalNews(externalNewsId, request).then((response) => {
          setExternalNewsResponse(response);
          success(t('common.updated_success'));
          navigate(RouteList.LANDING_PAGE.EXTERNAL_NEWS.LIST);
        });
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }

      return true;
    },
    [externalNewsId, t],
  );

  useEffect(() => {
    Api.externalNews.fetchSingleExternalNews(externalNewsId).then((response) => {
      setExternalNewsResponse(response);
      setGlobalLoading(false);
    });
  }, [externalNewsId]);
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={1} className={'mb-4'}>
            <Link to={RouteList.LANDING_PAGE.EXTERNAL_NEWS.LIST}>
              <PrimaryButton title={t('common.back')} type={'button'} />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Card>
              <CardBody>
                <h4 className={'mb-3'}>{t('pages.update_external_news')}</h4>
                <ExternalNewsForm onSubmit={onSubmit} externalNews={externalNewsResponse} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ExternalNewsUpdate;
