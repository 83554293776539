import { BaseTabProps } from 'src/components/Tabs';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup } from 'reactstrap';

import Api from 'src/api';
import {
  FetchFilterAutoInvestmentDraftQueueRequest,
  FetchProjectAutoInvestmentDraftQueueResponse,
} from 'src/types/api/autoInvestment';
import { success } from 'src/services/toastr';
import SaveButton from 'src/components/Form/SaveButton';
import SelectCreditDurationInput from 'src/components/Form/Select/SelectCreditDurationInput';
import SelectRatingInput from 'src/components/Form/Select/SelectRatingInput';
import TextInput from 'src/components/Form/TextInput';
import AutoInvestmentDraftResults from 'src/pages/AutoInvestment/AutoInvestmentDraftResults';
import { UseNumbers } from 'src/helpers/useNumbers';

type Props = BaseTabProps;

const TabFilter: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  const [request] = useState<FetchFilterAutoInvestmentDraftQueueRequest>({
    basic_interest: 0,
    ltv_external: 0,
    credit_duration: null,
    rating_profitus: '',
  });

  const [response, setResponse] = useState<
    FetchProjectAutoInvestmentDraftQueueResponse | undefined
  >();

  const onSubmit = useCallback(
    async (
      request: FetchFilterAutoInvestmentDraftQueueRequest,
      helper: FormikHelpers<FetchFilterAutoInvestmentDraftQueueRequest>,
    ) => {
      try {
        await Api.autoInvestment
          .fetchAutoInvestmentFilterDraftQueue(request)
          .then((r) => setResponse(r));
        success(t('common.success'));
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [t],
  );

  return (
    <>
      <div className={'mb-5'}>
        <Formik initialValues={request} onSubmit={onSubmit} enableReinitialize={true}>
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <div className={'mb-4'}>
                  <TextInput
                    name={'basic_interest'}
                    placeholder={t('label.basic_interest')}
                    type={'number'}
                    step={'any'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </div>
                <div className={'mb-4'}>
                  <TextInput
                    name={'ltv_external'}
                    placeholder={t('label.ltv_external')}
                    type={'number'}
                    step={'any'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </div>
                <div className={'mb-4'}>
                  <SelectRatingInput
                    name={'rating_profitus'}
                    placeholder={t('label.rating_profitus')}
                    isClearable={true}
                  />
                </div>
                <div className={'mb-4'}>
                  <SelectCreditDurationInput
                    name={'credit_duration'}
                    placeholder={t('label.credit_duration')}
                    isClearable={true}
                  />
                </div>
                <div className={'mb-4 mt-5'}>
                  <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
      {response && (
        <div className={'mb-4'}>
          <AutoInvestmentDraftResults results={response} />
        </div>
      )}
    </>
  );
};

export default TabFilter;
