import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouterProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';
import { Card, CardBody, Container } from 'reactstrap';

import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { AmlIssuesLogListResponseDto } from 'src/types/api/amlManagement';
import { setGlobalLoading } from 'src/modules/app/actions';
import Table, { Column } from 'src/components/Table';
import { ModelField } from 'src/components/Table/Fields/ModelField';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { success } from 'src/services/toastr';
import CheckboxInput from 'src/components/Inputs/CheckboxInput';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const AmlIssues: React.FC<RouterProps> = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<AmlIssuesLogListResponseDto>>();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const onSubmit = useCallback(
    async (
      checked: boolean,
      auditId: string,
      cell: Cell<AmlIssuesLogListResponseDto> | any,
    ): Promise<void> => {
      await Api.amlManagement
        .markIssue(auditId, {
          delete: checked,
        })
        .then(() => {
          success(t('common.updated_success'));
          cell.dispatch({ type: 'refreshData' });
        });
    },
    [t],
  );

  const columns = useMemo<Column<AmlIssuesLogListResponseDto>[] | any>(() => {
    return [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'number',
        width: 150,
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        width: 150,
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.account_id'),
        accessor: 'account.id',
        width: 100,
      },
      {
        Header: t('table.account'),
        accessor: 'account',
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => {
          const value = cell.row.original.account;

          return <ModelField model={value} />;
        },
      },
      {
        Header: t('table.issue_trigger'),
        accessor: 'issue_trigger',
        width: 400,
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => {
          const row = cell.row.original;
          return <div>{t('users.aml.triggers.' + row.issue_trigger)}</div>;
        },
      },
      {
        Header: t('table.action'),
        accessor: 'marked_for_expiration',
        width: 100,
        Cell: (cell: Cell<AmlIssuesLogListResponseDto>) => {
          const row = cell.row;

          return (
            <CheckboxInput
              name={'delete'}
              initialChecked={row.values.marked_for_expiration}
              onChange={(e) => onSubmit(e.target.checked, row.original.id, cell)}
            />
          );
        },
      },
    ];
  }, [onSubmit, t]);

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.amlManagement
        .fetchFilteredIssuesLog(request)
        .then((response) => setData(response));
    },

    [],
  );

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <div className={'d-flex align-items-center mb-4'}>
              <h4 className={'m-0'}>{t('menu.aml_management.issues')}</h4>
            </div>
            <HiddenColumnsProvider title={'AmlRiskLevelChangeLogListIndex'}>
              <Table
                title={'AmlRiskLevelChangeLogListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                disableFiltersOutsideTable={true}
                initialSortBy={INITIAL_SORT_ORDER}
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(AmlIssues);
