import React, { useCallback } from 'react';

import { BaseTabProps } from 'src/components/Tabs';
import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { CompanyContextState, withCompany } from '../CompanyContext';
import { WalletP2POperationsResponseDto } from 'src/types/api/operations';
import MoneyOperationTable from 'src/components/Operations/MoneyOperationTable';

interface Props extends BaseTabProps, CompanyContextState {}

const TabWalletOperations: React.FC<Props> = ({ company }) => {
  const fetchData = useCallback(
    (
      request: PaginationDataFilter | undefined,
    ): Promise<PaginationData<WalletP2POperationsResponseDto>> => {
      if (!company) {
        return Promise.reject();
      }

      return Api.userCompanies.fetchWalletMoneyOperations(company.id, request);
    },
    [company],
  );

  if (!company) {
    return null;
  }

  return (
    <>
      <MoneyOperationTable name={'CompanyWalletMoneyOperations'} onFetch={fetchData} />
    </>
  );
};

export default withCompany(TabWalletOperations);
