import React, { useCallback, useEffect, useState } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export interface Props extends PhoneInputProps {
  name: string;
  hideLabel?: boolean;
}

const PhoneNumberInput: React.FC<Props> = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  const { submitCount } = useFormikContext();
  const [isError, setError] = useState<boolean>(false);
  useEffect(() => {
    setError(submitCount > 0 && !!meta.error);
  }, [meta.error, submitCount]);

  if (!props.placeholder) {
    props.placeholder = t('label.' + props.name);
  }

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onChange = useCallback(
    (v: string) => {
      helper.setValue(v);
    },
    [helper],
  );

  return (
    <>
      <div className={`form-label-group ${isError ? 'is-invalid' : ''}`}>
        {!props.hideLabel && <label htmlFor={props.name}>{props.placeholder}</label>}
        <PhoneInput
          onChange={onChange}
          onBlur={() => helper.setTouched(true)}
          value={field.value || ''}
          country={'lt'}
          preferredCountries={['lt', 'gb', 'de', 'ee']}
          masks={{ lt: '... .. ...' }}
          {...props}
        />
        {isError && (
          <div
            className={`text-pre ${isError ? 'invalid-feedback' : ''}`}
            style={{ display: 'block' }}
          >
            <ErrorMessage
              component={'div'}
              name={props.name}
              render={(msg: string | string[]) => {
                if (typeof msg === 'string') {
                  return msg;
                }

                return msg.join('\n');
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PhoneNumberInput;
