import { PaginationData, PaginationDataFilter } from 'src/types';
import Client from './client';
import {
  TranslationListResponseDto,
  UpdateTranslationRequestDto,
  UpdateTranslationResponseDto,
} from 'src/types/api/translations';
import { FilterSelectValuesResponseDto, ImportDataRequestDto } from 'src/types/api/common';
class Translation extends Client {
  api = {
    FETCH_FILTERED_TRANSLATIONS: `${this.http.baseUrl}/locale/translations/filter`,
    EXPORT_FILTERED_TRANSLATIONS_CSV: `${this.http.baseUrl}/locale/translations/export/csv`,
    IMPORT_TRANSLATIONS: `${this.http.baseUrl}/locale/translations/import/csv`,
    UPDATE_TRANSLATION: `${this.http.baseUrl}/locale/translations/translation/{translationId}`,
    DELETE_TRANSLATION: `${this.http.baseUrl}/locale/translations/translation/{translationId}`,
    FETCH_TRANSLATION_GROUPS: `${this.http.baseUrl}/locale/translations/translationGroups`,
  };

  fetchFilteredTranslations = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<TranslationListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_TRANSLATIONS, { params: request });
  };

  exportTranslationDataCsv = (filter?: PaginationDataFilter): Promise<any> => {
    return this.http.get(this.api.EXPORT_FILTERED_TRANSLATIONS_CSV, { params: filter });
  };

  importTranslationData = (request: ImportDataRequestDto): Promise<any> => {
    const formData = new FormData();

    Object.keys(request).forEach((key) => {
      const k = key as keyof ImportDataRequestDto;

      if (request[k] != null) {
        if (typeof request[k] == 'string') {
          formData.append(key, request[k] as string);
        } else {
          const file = request[k] as File;
          formData.append(key, file);
        }
      }
    });

    return this.http.post(this.api.IMPORT_TRANSLATIONS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  fetchTranslationGroups = (): Promise<FilterSelectValuesResponseDto[]> => {
    return this.http.get(this.api.FETCH_TRANSLATION_GROUPS);
  };

  updateTranslation = (
    translationId: number | string,
    request: UpdateTranslationRequestDto,
  ): Promise<UpdateTranslationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_TRANSLATION, { translationId });
    return this.http.put(url, request);
  };

  deleteTranslation = (translationId: number | string): Promise<UpdateTranslationResponseDto> => {
    const url = this.buildUrl(this.api.DELETE_TRANSLATION, { translationId });
    return this.http.delete(url);
  };
}
export default Translation;
