import { statistics } from 'src/api/queries';
import Api from 'src/api/index';
import { defaultOptions, UseQueryOptionsObject } from 'src/api/queries/queries.utils';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import { DashboardPageInvestorsCategoriesInformationDto } from 'src/types/api/dashboard';

const localDefaultOptions: typeof defaultOptions = {
  ...defaultOptions,
  retry: false,
  retryOnMount: false,
};

const useInvestorsCategoriesStatisticsQuery = (
  options?: UseQueryOptionsObject<DashboardPageInvestorsCategoriesInformationDto>,
) => {
  return useMakeQuery<DashboardPageInvestorsCategoriesInformationDto>(
    [statistics.investors],
    () => Api.dashboard.fetchInvestorsCategoriesInformation(),
    {
      staleTime: 120 * 1000,
      ...localDefaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useInvestorsCategoriesStatisticsQuery, localDefaultOptions };
