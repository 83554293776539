import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';

import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { BaseTabProps } from 'src/components/Tabs';
import Table from 'src/components/Table';
import DownloadButton from 'src/components/DownloadButton';
import { AttachFilesRequestDto, DocumentResponseDto } from 'src/types/api/common';
import { CompanyContextState, withCompany } from 'src/pages/User/Update/Company/CompanyContext';
import FileUpload, { FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';
import { FormikProps } from 'formik/dist/types';
import { Form, Formik, FormikHelpers } from 'formik';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { DEFAULT_LIMIT as TABLE_DEFAULT_LIMIT } from 'src/components/Table';
import { DEFAULT_PAGE as TABLE_DEFAULT_PAGE } from 'src/components/Table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import IsOutdatedBadge from 'src/components/Badges/IsOutdatedBadge';
import { success } from 'src/services/toastr';
import usePermissions from 'src/helpers/usePermissions';
import { Permissions } from 'src/helpers/auth/permissions';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

interface Props extends CompanyContextState, BaseTabProps {}

const TabDocuments: React.FC<Props> = ({ company }) => {
  const { t } = useTranslation();
  const p = usePermissions();
  const [data, setData] = useState<PaginationData<DocumentResponseDto>>();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [initialFormValues] = useState<AttachFilesRequestDto>({
    media_ids: [],
  });

  const handleMarkAsLWDocument = (companyId: number | string, mediaId: number | string) => () => {
    if (data?.data) {
      setLoading(true);
      Api.userCompanies.markAsLemonwayDocument(companyId, mediaId).then((response) => {
        const updatedData = data.data.map((item) => {
          if (item.id === mediaId) {
            return response;
          }
          return item;
        });
        setData({
          meta: data.meta,
          data: updatedData,
        });
        success(t('users.marked_as_lemonway_document'));
        setLoading(false);
      });
    }
  };

  const columns = useMemo<Column<DocumentResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
      },
      {
        Header: t('table.file_name'),
        accessor: 'name',
      },
      {
        Header: t('table.mime_type'),
        accessor: 'mime_type',
      },
      {
        Header: t('table.size'),
        accessor: 'size',
      },
      {
        Header: t('table.information'),
        width: 100,
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div className={'d-flex justify-content-center gap-2 text-center'}>
            <IsOutdatedBadge show={!!cell.row.original.is_outdated} />
          </div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 200,
        Cell: (cell: Cell<DocumentResponseDto>) => {
          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.row.original.url} />
              {!cell.row.original.is_lemonway_document &&
                company &&
                p.can(Permissions.BO__USERS__UPLOAD_LEMONWAY_DOCUMENTS) && (
                  <button
                    disabled={loading}
                    className="btn btn-success"
                    onClick={handleMarkAsLWDocument(company.id, cell.row.original.id)}
                  >
                    {t('common.mark_as_lemonway_document')}
                  </button>
                )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, handleMarkAsLWDocument],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      if (company) {
        return Api.userCompanies
          .filterCompanyDocuments(company.id, request)
          .then((response) => setData(response));
      }
    },
    [company],
  );

  const onSubmit = useCallback(
    async (request: AttachFilesRequestDto, helpers: FormikHelpers<any>) => {
      if (!company) return;

      try {
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        await Api.userCompanies.attachDocuments(company.id, request);
        // on new file upload refresh table
        await fetchData({
          page: TABLE_DEFAULT_PAGE,
          limit: TABLE_DEFAULT_LIMIT,
          sort: [],
          search: undefined,
        });

        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [company, fetchData],
  );

  if (!company) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <FileUpload
              ref={fileUploadRef}
              name={'media_ids'}
              onPresign={(request) => Api.userCompanies.uploadDocuments(company.id, request)}
            />
            <PrimaryButton
              disabled={values.media_ids.length === 0}
              className={'btn btn-primary w-100 my-2'}
              submitting={isSubmitting}
              type={'submit'}
              title={t('common.attach')}
            />
          </Form>
        )}
      </Formik>
      <hr />
      <HiddenColumnsProvider title={'UserUpdateCompanyTabsTabDocuments'}>
        <Table
          title={'UserUpdateCompanyTabsTabDocuments'}
          onFetchData={fetchData}
          columns={columns}
          data={data}
          searchable={true}
          initialSortBy={INITIAL_SORT_ORDER}
        />
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default withCompany(TabDocuments);
