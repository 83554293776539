import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import Login from './Login/Login';
import AuthenticationLayout from 'src/containers/AuthenticationLayout';
import ResetPassword from './ResetPassword/ResetPassword';
import ConfirmResetPassword from './ResetPassword/ConfirmResetPassword';
import { Permissions } from 'src/helpers/auth/permissions';

const AuthenticationRoutes: any = () => [
  <AuthRoute
    path={RouteList.AUTHENTICATION.LOGIN}
    component={Login}
    layout={AuthenticationLayout}
    permissions={[Permissions.BO__GUEST]}
    redirect={RouteList.DASHBOARD.DASHBOARD.LIST}
    key={RouteList.AUTHENTICATION.LOGIN}
    exact
  />,
  <AuthRoute
    path={RouteList.AUTHENTICATION.RESET_PASSWORD}
    component={ResetPassword}
    layout={AuthenticationLayout}
    permissions={[Permissions.BO__GUEST]}
    redirect={RouteList.DASHBOARD.DASHBOARD.LIST}
    key={RouteList.AUTHENTICATION.RESET_PASSWORD}
    exact
  />,
  <AuthRoute
    path={RouteList.AUTHENTICATION.CONFIRM_RESET_PASSWORD}
    component={ConfirmResetPassword}
    layout={AuthenticationLayout}
    permissions={[Permissions.BO__GUEST]}
    redirect={RouteList.DASHBOARD.DASHBOARD.LIST}
    key={RouteList.AUTHENTICATION.CONFIRM_RESET_PASSWORD}
    exact
  />,
];

export default AuthenticationRoutes;
