import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';
import {
  DropdownItem,
  DropdownMenu,
  DropdownProps,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import Api from 'src/api';
import PermissionAction from 'src/components/PermissionAction';
import { success } from 'src/services/toastr';
import {
  InvoiceListResponseDto,
  UpdateInvoiceStatusRequestDto,
} from 'src/types/api/payments/invoices';
import { InvoiceStatusEnum } from 'src/helpers/Enums/InvoiceStatusEnum';
import { Permissions } from 'src/helpers/auth/permissions';

interface Props extends DropdownProps {
  invoiceId: string;
  cell: Cell<InvoiceListResponseDto> | any;
}

const InvoiceDropdown: React.FC<Props> = ({ invoiceId, cell, ...props }) => {
  const { t } = useTranslation();

  const onChangeStatus = useCallback(
    async (request: UpdateInvoiceStatusRequestDto) => {
      await Api.payments.invoices.updateStatus(invoiceId, request).then(() => {
        success(t('common.updated_success'));
        cell.dispatch({ type: 'refreshData' });
      });
    },
    [cell, invoiceId, t],
  );

  return (
    <React.Fragment>
      <UncontrolledDropdown {...props}>
        <DropdownToggle tag="a" className="cursor text-body font-size-16" caret>
          <i className="fas fa-ellipsis-h" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <PermissionAction permissions={Permissions.BO__PAYMENTS__INVOICES__UPDATE}>
            <DropdownItem onClick={() => onChangeStatus({ status: InvoiceStatusEnum.PAID })}>
              {t('payments.invoices.mark_as_paid')}
            </DropdownItem>
          </PermissionAction>
          <PermissionAction permissions={Permissions.BO__PAYMENTS__INVOICES__UPDATE}>
            <DropdownItem onClick={() => onChangeStatus({ status: InvoiceStatusEnum.UNPAID })}>
              {t('payments.invoices.mark_as_unpaid')}
            </DropdownItem>
          </PermissionAction>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
};

export default InvoiceDropdown;
