import React, { useState } from 'react';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import * as Flags from 'country-flag-icons/string/3x2';
import FlagIcon from 'src/components/FlagIcon';

interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  locale: LanguageEnum | undefined;
}

const ActiveLocaleFlag: React.FC<Props> = ({ locale, ...props }) => {
  const [availableFlags] = useState<{
    [key in LanguageEnum]: string;
  }>({
    [LanguageEnum.EN]: Flags.US,
    [LanguageEnum.LT]: Flags.LT,
    [LanguageEnum.DE]: Flags.DE,
    [LanguageEnum.EE]: Flags.EE,
  });

  return (
    <div {...props}>
      {(Object.keys(availableFlags) as Array<LanguageEnum>).map((flagKey, i) => (
        <div key={i} className={`locale ${flagKey === locale ? 'active active-flag' : 'd-none'}`}>
          <FlagIcon className={'cursor'} locale={flagKey} icon={availableFlags[flagKey]} />
        </div>
      ))}
    </div>
  );
};

export default ActiveLocaleFlag;
