import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  wallet_exists: boolean;
}

const WalletExistsBadge: React.FC<Props> = ({ wallet_exists }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <i
        title={t(`${wallet_exists ? 'common.wallet_exists' : 'common.wallet_not_exists'}`)}
        className={`fas fa-wallet ${wallet_exists ? 'text-success' : 'text-danger'}`}
      />
    </React.Fragment>
  );
};

export default WalletExistsBadge;
