import React, { useMemo } from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import {
  DocumentTemplateContextState,
  withDocumentTemplate,
} from 'src/pages/DocumentTemplates/DocumentTemplateContext';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import SimpleTable from 'src/components/SimpleTable';
import DownloadButton from 'src/components/DownloadButton';
import { MediaResponseDto } from 'src/types/api/common';

interface Props extends BaseTabProps, DocumentTemplateContextState {}

const TabUploads: React.FC<Props> = ({ documentTemplate }) => {
  const { t } = useTranslation();
  const columns = useMemo<Column<MediaResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('table.file_name'),
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: t('table.version'),
        accessor: 'version',
        disableSortBy: true,
      },
      {
        Header: t('table.mime_type'),
        accessor: 'mime_type',
        disableSortBy: true,
      },
      {
        Header: t('table.size'),
        accessor: 'size',
        disableSortBy: true,
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        disableSortBy: true,
        Cell: (cell: Cell<MediaResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        accessor: 'url',
        disableSortBy: true,
        Cell: (cell: Cell<MediaResponseDto>) => {
          if (!documentTemplate) return null;
          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.value} />
            </div>
          );
        },
      },
    ],
    [documentTemplate, t],
  );

  return (
    <>
      <SimpleTable columns={columns} data={documentTemplate?.documents ?? []} />
    </>
  );
};

export default withDocumentTemplate(TabUploads);
