import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { useField } from 'formik';
import TextAreaInput, { Props as TextAreaProps } from 'src/components/Form/TextAreaInput';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import {
  isOfLanguage,
  isTranslatable as isTranslatableGuard,
  LanguageEnum,
  TranslatableObject,
} from 'src/helpers/Enums/LanguageEnum';
import { useTranslation } from 'react-i18next';

type PropsToExchange = 'name' | 'value' | 'onChange' | 'onBlur';

export interface Props
  extends Omit<StateManagerProps, PropsToExchange>,
    Pick<TextAreaProps, PropsToExchange> {
  options: ReactSelectOption[];
  otherOptionLabel: string;
  otherPlaceholder?: string;
  hideLabel?: boolean;
  isTranslatable?: boolean;
  language?: LanguageEnum;
}

const SelectCustomValueInput: React.FC<Props> = ({
  name,
  value: baseValue,
  onChange,
  onBlur,
  options: baseOptions,
  otherOptionLabel,
  otherPlaceholder,
  isTranslatable,
  ...props
}) => {
  const { t } = useTranslation();
  const [field, meta, helper] = useField<TranslatableObject | string>(name || '');

  const OTHER_OPTION = useMemo<ReactSelectOption>(
    () => ({
      label: otherOptionLabel,
      value: '',
    }),
    [otherOptionLabel],
  );

  const value = useMemo<typeof baseValue>(() => {
    if (isTranslatable && isOfLanguage(props.language) && isTranslatableGuard(baseValue)) {
      return baseValue[props.language];
    }

    return baseValue;
  }, [baseValue, props.language, isTranslatable]);

  const options = useMemo<ReactSelectOption[]>(() => {
    return [...baseOptions, OTHER_OPTION];
  }, [baseOptions, OTHER_OPTION]);

  const selectValue = useMemo<ReactSelectOption | null>(() => {
    const match =
      (options.find((o) => o.value === value) || options.find((o) => o.label === value)) ?? null;
    if ((!match && value) || value === '') {
      return options[options.length - 1];
    }

    return match;
  }, [options, value]);

  const handleSelectChange = useCallback(
    (option: unknown) => {
      const optionValue = (option as ReactSelectOption | null)?.value;

      if (isTranslatable && isOfLanguage(props.language) && isTranslatableGuard(baseValue)) {
        const newValue = Object.values(LanguageEnum).reduce((current, locale) => {
          current[locale] = t(optionValue as string, { lng: locale });

          return current;
        }, {} as TranslatableObject);

        helper.setValue(newValue);

        return;
      }

      helper.setValue(optionValue as string);
    },
    // eslint-disable-next-line
    [baseValue, isTranslatable, props.language],
  );

  const isTextAreaHidden =
    value === null ||
    (value !== OTHER_OPTION.value && !!baseOptions.find((o) => o.value === value));

  const [isHidden, setIsHidden] = useState(true);
  useEffect(() => {
    if (selectValue?.label !== OTHER_OPTION.label) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }, [OTHER_OPTION.label, selectValue?.label, value]);

  const inputName = useMemo<typeof name>(() => {
    if (isOfLanguage(props.language)) {
      return `${name}.${props.language}`;
    }

    return name;
  }, [props.language, name]);

  const errorMessage = useMemo<string>(() => {
    if (isTranslatable && isTranslatableGuard(meta.error) && isOfLanguage(props.language)) {
      const err = meta.error[props.language];
      return Array.isArray(err) ? err.join(', ') : err;
    }

    const err = meta.error ?? '';
    return Array.isArray(err) ? err.join(', ') : err;
  }, [isTranslatable, meta.error, props.language]);

  return (
    <div className={`d-flex flex-column${!!errorMessage ? ' is-invalid' : ''}`}>
      {!props.hideLabel && <label htmlFor={name}>{props.placeholder}</label>}
      <Select
        options={options}
        onChange={handleSelectChange}
        onBlur={field.onBlur}
        value={selectValue}
        className={'mb-2'}
        {...props}
      />
      <TextAreaInput
        hideLabel={true}
        name={inputName}
        placeholder={otherPlaceholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value || ''}
        hidden={isTextAreaHidden || !isHidden}
      />
    </div>
  );
};

export default SelectCustomValueInput;
