import React, { useContext, useMemo } from 'react';
import { DropzoneProps, ErrorCode, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { action } from 'typesafe-actions';

import FileUploadContext from './module/FileUploadContext';
import * as FileUploadReducer from './module/FileUploadReducer';
import FormikField from 'src/components/Form/FormikField';
import {
  FileUploadProps,
  getClassName,
  SHOWN_ERRORS,
} from 'src/components/Form/FileUpload/FileUpload';
import { error } from 'src/services/toastr';

const FileUploadZone: React.FC<FileUploadProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(FileUploadContext);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    validator: () => {
      const p = props as DropzoneProps;

      if (p.maxFiles && p.maxFiles <= Object.keys(state.files).length) {
        return {
          code: ErrorCode.TooManyFiles,
          message: ErrorCode.TooManyFiles,
        };
      }

      return null;
    },
    onDrop: (acceptedFiles) => {
      dispatch(action(FileUploadReducer.FILES_ADDED, acceptedFiles));
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map((rejection) =>
        rejection.errors
          .filter((error) => SHOWN_ERRORS.includes(error.code))
          .map((err) =>
            error(t('validation.file_upload.' + err.code, { file: rejection.file.name })),
          ),
      );
    },
    ...props,
  });

  const className = useMemo(
    () => getClassName(isFocused, isDragAccept, isDragReject, props.disabled),
    [props.disabled, isFocused, isDragAccept, isDragReject],
  );

  return (
    <>
      <FormikField name={props.name} wrapperClass={'form-label-group'}>
        <div {...getRootProps({ className })}>
          <input {...getInputProps()} />
          <div>
            <i className={'icon-upload'} />
            {t('label.file_upload.drag_and_drop')}
          </div>
          <div className={'splitter'}>{t('label.file_upload.or')}</div>
          <div>
            <a>{t('label.file_upload.open_file_browser')}</a>
          </div>
        </div>
      </FormikField>
    </>
  );
};

export default FileUploadZone;
