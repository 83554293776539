import React from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import Api from 'src/api';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useDefaultQuery } from 'src/api/queries/useDefaultQuery';

const ImportRead: React.FC = () => {
  const { importId } = useParams<{ importId: string }>();

  const { data, isFetching } = useDefaultQuery(['import', importId], () =>
    Api.import.fetchImport(importId),
  );

  return (
    <React.Fragment>
      <Container>
        <Card className="card h-100">
          <CardBody>
            {isFetching ? (
              <Skeleton height={500} />
            ) : (
              <div className={'log-container'}>{data?.logs}</div>
            )}
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default ImportRead;
