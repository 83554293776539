import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { BorrowerPayoutStatusEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutStatusEnum';

interface Props {
  value: BorrowerPayoutStatusEnum;
}

const BorrowerPayoutStatus: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const [color, setColor] = useState<string>('#bc3e55');

  useEffect(() => {
    switch (value) {
      case BorrowerPayoutStatusEnum.INIT:
        setColor('#514f44');
        break;
      case BorrowerPayoutStatusEnum.PAID:
        setColor('#81CF74');
        break;
      case BorrowerPayoutStatusEnum.NEW:
        setColor('#e72790');
        break;
      case BorrowerPayoutStatusEnum.DRAFT:
        setColor('#262854');
        break;
      case BorrowerPayoutStatusEnum.SCHEDULED:
        setColor('#323c77');
        break;
      case BorrowerPayoutStatusEnum.PENDING:
        setColor('#d1cd45');
        break;
      case BorrowerPayoutStatusEnum.RECEIVED:
        setColor('#7b3ebc');
        break;
      case BorrowerPayoutStatusEnum.PERMISSION_TO_SELL:
        setColor('#bc3e55');
        break;
      default:
        setColor('#bc3e55');
    }
  }, [value]);

  return (
    <Badge style={{ backgroundColor: color }} color={''}>
      {t('payments.borrower_payouts.status.' + value)}
    </Badge>
  );
};

export default BorrowerPayoutStatus;
