import React, { useCallback, useMemo, useState } from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import Table from 'src/components/Table';
import { UserInvestmentsResponseDto } from 'src/types/api/user';
import { ProjectInvestmentTypeEnum } from 'src/helpers/Enums/ProjectInvestmentTypeEnum';

const INITIAL_SORT_ORDER = [
  {
    id: 'invested_at',
    desc: true,
  },
];

const TabInvestments: React.FC<BaseTabProps> = ({ id: userId }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<UserInvestmentsResponseDto>>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'pid',
        sortType: 'string',
        width: 100,
      },
      {
        Header: t('table.project_name'),
        accessor: 'project_name',
        sortType: 'string',
        width: 250,
      },
      {
        Header: t('table.project_investment_status'),
        accessor: 'project_investment_status',
        sortType: 'string',
        width: 100,
      },
      {
        Header: t('table.project_status'),
        accessor: 'project_status',
        sortType: 'string',
        width: 100,
        Cell: (cell: Cell<UserInvestmentsResponseDto>) => {
          const project_status = cell.row.original.project_status;
          return <span>{t('projects.status.' + project_status)}</span>;
        },
      },
      {
        Header: t('table.type'),
        accessor: 'type',
        sortType: 'string',
        width: 100,
        Cell: (cell: Cell<UserInvestmentsResponseDto>) => {
          const type = cell.row.original.type;
          return (
            <span>
              {type === ProjectInvestmentTypeEnum.MANUAL ? t('common.manual') : t('common.auto')}
            </span>
          );
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'amount',
        sortType: 'string',
        width: 150,
      },
      {
        Header: t('table.remaining_amount'),
        accessor: 'remaining_amount',
        sortType: 'string',
        width: 150,
      },
      {
        Header: t('table.basic_interest'),
        accessor: 'basic_interest',
        sortType: 'string',
        width: 100,
      },
      {
        Header: t('table.invested_at'),
        accessor: 'invested_at',
        sortType: 'string',
        width: 250,
        Cell: (cell: Cell<UserInvestmentsResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!userId) return Promise.resolve();

      return Api.user
        .fetchUserInvestments(userId, request)
        .then((response) => setResponse(response));
    },
    [userId],
  );

  return (
    <React.Fragment>
      <Table
        title={'investments'}
        columns={columns}
        data={response}
        onFetchData={fetchData}
        searchable={true}
        initialSortBy={INITIAL_SORT_ORDER}
      />
    </React.Fragment>
  );
};

export default TabInvestments;
