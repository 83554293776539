import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { success } from 'src/services/toastr';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { DocumentResponseDto, DocumentUpdateRequestDto } from 'src/types/api/common';
import TextInput from 'src/components/Form/TextInput';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import SelectProjectDocumentTypeInput from 'src/components/Form/Select/SelectProjectDocumentTypeInput';
import CheckboxInput from 'src/components/Form/CheckboxInput';
import { locales } from 'src/helpers';
import { transformErrors } from 'src/helpers';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';

interface Props {
  document: DocumentResponseDto;
  onAction: (request: DocumentUpdateRequestDto) => Promise<any>;
}

const DocumentControlModal: React.FC<Props> = ({ onAction, document }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const EditDocumentRequestSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const [renameRequest] = useState<DocumentUpdateRequestDto>({
    name: document.name,
    collection_name: document.collection_name ?? '',
    is_visible: !!document.is_visible,
    visible_locales: document.visible_locales ?? [],
  });

  const onSubmit = useCallback(
    (request: DocumentUpdateRequestDto, helper: FormikHelpers<DocumentUpdateRequestDto>) => {
      return onAction(request)
        .then(() => {
          success(t('common.updated_success'));
          hideModal();
        })
        .catch((e) => {
          helper.setErrors(transformErrors(e.response?.errors));
        });
    },
    [hideModal, onAction, t],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('projects.document_control.title')}
          </ModalHeader>
          <Formik
            initialValues={renameRequest}
            validationSchema={EditDocumentRequestSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <>
                <ModalBody className={''}>
                  <p className={'text-pre'}>{t('projects.document_control.body')}</p>

                  <Form onSubmit={handleSubmit} id={'form'}>
                    <FormGroup>
                      <div className="mb-3">
                        <TextInput value={values.name} name="name" />
                      </div>
                      <div className="mb-3">
                        <SelectProjectDocumentTypeInput
                          name={'collection_name'}
                          placeholder={t('label.collection_name')}
                        />
                      </div>
                      <div className="mb-3">
                        <CheckboxInput name={'is_visible'} showPlaceholder={true} />
                      </div>
                      <div className="mb-3">
                        <label>{t('common.visible_locales')}</label>
                        {locales.map((locale, key) => (
                          <CheckboxInput
                            key={key}
                            name={'visible_locales'}
                            value={locale}
                            placeholder={t('locale.' + locale)}
                            showPlaceholder={true}
                          />
                        ))}
                      </div>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      form={'form'}
                      submitting={isSubmitting}
                      title={t('common.submit')}
                      type={'submit'}
                    />
                  </Col>
                </ModalFooter>
              </>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default DocumentControlModal;
