import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { InvestmentPayoutStatusEnum } from 'src/helpers/Enums/Payments/InvestmentPayoutStatusEnum';

interface Props {
  value: InvestmentPayoutStatusEnum;
}

const InvestmentPayoutStatus: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const [color, setColor] = useState<string>('#bc3e55');

  useEffect(() => {
    switch (value) {
      case InvestmentPayoutStatusEnum.INIT:
        setColor('#514f44');
        break;
      case InvestmentPayoutStatusEnum.NEW:
        setColor('#e72790');
        break;
      case InvestmentPayoutStatusEnum.DRAFT:
        setColor('#262854');
        break;
      case InvestmentPayoutStatusEnum.SCHEDULED:
        setColor('#323c77');
        break;
      case InvestmentPayoutStatusEnum.PENDING:
        setColor('#d1cd45');
        break;
      case InvestmentPayoutStatusEnum.PAID:
        setColor('#81cf74');
        break;
      case InvestmentPayoutStatusEnum.FAILED:
        setColor('#bc3e55');
        break;
      default:
        setColor('#bc3e55');
    }
  }, [value]);

  return (
    <Badge style={{ backgroundColor: color }} color={''}>
      {t('payments.investment_payouts.status.' + value)}
    </Badge>
  );
};

export default InvestmentPayoutStatus;
