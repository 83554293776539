import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import Notifications from './Notifications';
import { Permissions } from 'src/helpers/auth/permissions';

const NotificationsRoutes: any = () => [
  <AuthRoute
    path={RouteList.NOTIFICATIONS.LIST}
    component={Notifications}
    layout={PageLayout}
    permissions={[Permissions.BO__ACTIONS__ACCESS_PANEL]}
    key={RouteList.NOTIFICATIONS.LIST}
    exact
  />,
];

export default NotificationsRoutes;
