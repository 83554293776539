import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setGlobalLoading } from 'src/modules/app/actions';

const NotFound: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <h1>Not Found</h1>
    </React.Fragment>
  );
};

export default withRouter(NotFound);
