export enum InvestmentPurposeEnum {
  RE_RECONSTRUCTION = 're_reconstruction',
  RE_RENT = 're_rent',
  RE_DEVELOPMENT = 're_development',
  RE_ACQUISITION = 're_acquisition',
  BUSINESS_LOAN = 'business_loan',
  WORKING_CAPITAL = 'working_capital',
  BONDS = 'bonds',
  REFINANCING = 'refinancing',
}
