import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { ApplicationUpdateCreditInfoRequestDto } from 'src/types/api/applications';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { Col, ColProps, Form, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { ApplicationType } from 'src/helpers/Enums/Application/ApplicationType';
import SelectCreditDurationInput from 'src/components/Form/Select/SelectCreditDurationInput';
import SelectCreditReturnInput from 'src/components/Form/Select/SelectCreditReturnInput';
import SelectInterestFrequencyInput from 'src/components/Form/Select/SelectInterestFrequencyInput';
import SaveButton from 'src/components/Form/SaveButton';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';
import { transformErrors } from 'src/helpers';
import { UseNumbers } from 'src/helpers/useNumbers';
import { ApplicationCreditReturn } from 'src/helpers/Enums/Application/ApplicationCreditReturn';

interface Props extends ApplicationTabProps, ApplicationContextState {}

const INITIAL_REQUEST: ApplicationUpdateCreditInfoRequestDto = {
  project_name: null,
  type: null,
  minimal_credit_amount: 0,
  required_amount: 0,
  credit_duration: null,
  interest_frequency: null,
  credit_return: null,
  source_of_funds: '',
  ltv_max: null,
  total_project_amount: null,
  total_project_duration: null,
};

const TabCredit: React.FC<Props> = ({ application, setApplication, showSubmit }) => {
  const { t } = useTranslation();

  const [request, setRequest] = useState<ApplicationUpdateCreditInfoRequestDto>(INITIAL_REQUEST);

  const [applicationTypes, setApplicationTypes] = useState<ReactSelectOption[] | undefined>(
    undefined,
  );

  useEffect(() => {
    const applicationTypes: ReactSelectOption[] = [];

    Object.values(ApplicationType).map((applicationType) => {
      applicationTypes.push({
        value: applicationType,
        label: t('applications.type.' + applicationType),
      });

      setApplicationTypes(applicationTypes);
    });
  }, [t]);

  useEffect(() => {
    if (!application) return;

    setRequest({
      project_name: application.project_name,
      type: application.type,
      minimal_credit_amount: application.minimal_credit_amount,
      required_amount: application.required_amount,
      credit_duration: application.credit_duration,
      interest_frequency: application.interest_frequency,
      credit_return: application.credit_return ?? ApplicationCreditReturn.TERM_END,
      source_of_funds: application.source_of_funds,
      ltv_max: application.ltv_max,
      total_project_duration: application.total_project_duration,
      total_project_amount: application.total_project_amount,
    });
  }, [application]);

  const CreditSchema = Yup.object().shape({
    project_name: Yup.string().nullable(),
    type: Yup.string().nullable(),
    minimal_credit_amount: Yup.number().nullable(),
    required_amount: Yup.number()
      .greaterThanOrEqualField('minimal_credit_amount', t('label.minimal_credit_amount'))
      .nullable(),
    credit_duration: Yup.number().required(),
    interest_frequency: Yup.string().required(),
    credit_return: Yup.string().required(),
    source_of_funds: Yup.string().nullable(),
    total_project_duration: Yup.number().nullable(),
    total_project_amount: Yup.number().nullable(),
    ltv_max: Yup.number().nullable(),
  });

  const onSubmit = useCallback(
    async (request: any, helpers: FormikHelpers<ApplicationUpdateCreditInfoRequestDto>) => {
      if (!application) return;

      try {
        const response = await Api.applications.UpdateCreditInfo(application.id, request);
        setApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [application, setApplication, t],
  );

  const colProps: ColProps = {
    md: 6,
    className: 'mb-4',
  };

  const colProps2: ColProps = {
    md: 12,
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.credit')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton title={t('applications.tabs_submit.credit')} tabName={'credit'} />
          </div>
        )}
      </div>
      <hr />
      <Formik
        initialValues={request}
        enableReinitialize={true}
        validationSchema={CreditSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colProps2}>
                  <TextInput type={'text'} name={'project_name'} />
                </Col>
                <Col {...colProps}>
                  <SelectInput
                    placeholder={t('label.application_type')}
                    name={'type'}
                    options={applicationTypes}
                    isClearable={true}
                  />
                </Col>
                <Col {...colProps}>
                  <SelectInterestFrequencyInput
                    name={'interest_frequency'}
                    placeholder={t('label.interest_frequency')}
                  />
                </Col>
                <Col {...colProps}>
                  <SelectCreditDurationInput
                    name={'credit_duration'}
                    placeholder={t('label.credit_duration')}
                  />
                </Col>
                <Col {...colProps}>
                  <SelectCreditReturnInput
                    placeholder={t('label.credit_return')}
                    name={'credit_return'}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'minimal_credit_amount'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'required_amount'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps2}>
                  <TextAreaInput name={'source_of_funds'} />
                </Col>
              </Row>
              {application?.is_stageable && (
                <Row>
                  <hr />
                  <Col {...colProps}>
                    <TextInput
                      type={'number'}
                      step={'any'}
                      name={'ltv_max'}
                      onKeyPress={UseNumbers.preventNonNumericalInput}
                    />
                  </Col>
                  <Col {...colProps}>
                    <SelectCreditDurationInput name={'total_project_duration'} isClearable={true} />
                  </Col>
                  <Col {...colProps}>
                    <TextInput
                      type={'number'}
                      step={'any'}
                      name={'total_project_amount'}
                      onKeyPress={UseNumbers.preventNonNumericalInput}
                    />
                  </Col>
                </Row>
              )}
              <div className={'mt-4 mb-4'}>
                <SaveButton title={t('common.save')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withApplication(TabCredit);
