import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import SaveButton from 'src/components/Form/SaveButton';
import { BaseFilterForm } from 'src/types/api/reports';
import { initialRequest } from 'src/pages/Reports/View/helpers';
import { Permissions } from 'src/helpers/auth/permissions';
import TextInput from 'src/components/Form/TextInput';

const TYPE: ReportTypeEnum = ReportTypeEnum.NEWSLETTER_SUBSCRIBERS;

const NewsletterSubscribersReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<BaseFilterForm>({
    name: null,
    columns: [],
  });

  const onSubmit = useCallback(
    async (request: BaseFilterForm, helper: FormikHelpers<BaseFilterForm>) => {
      try {
        await Api.reports.generateReport(initialRequest(request, TYPE)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  if (!p.hasAll([mapManagePermission(TYPE), Permissions.BO__REPORTS__GENERATE])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={3} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default NewsletterSubscribersReportFilter;
