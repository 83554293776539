import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSelectOption } from './SelectInput';
import CreatableSelectInput, {
  ReactSelectProps,
} from 'src/components/Form/Select/CreatableSelectInput';

const MONTHS = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 24, 30, 36];

const SelectCreditDurationInput: React.FC<Omit<ReactSelectProps, 'options'>> = (props) => {
  const { t } = useTranslation();

  const [options] = useState<ReactSelectOption[]>(
    MONTHS.map((type) => ({
      value: type.toString(),
      label: t('common.month', { value: type }),
    })),
  );

  return (
    <CreatableSelectInput
      isValidNewOption={(s, value, options) => {
        const val = parseInt(s);

        return val > 0 && val <= 120 && !options.find((x) => x.value?.toString() === s);
      }}
      formatCreateLabel={(val: string) => t('common.month', { value: val })}
      formatOptionLabel={(o: any) => t('common.month', { value: o.value })}
      {...props}
      options={options}
    />
  );
};

export default SelectCreditDurationInput;
