import React, { useCallback, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';

import { PaginationData, PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import Table from 'src/components/Table';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { Permissions } from 'src/helpers/auth/permissions';
import { RouteList } from 'src/routes';
import { ApplicationRelatedProjectsResponseDto } from 'src/types/api/applications';

const INITIAL_SORT_ORDER = [
  {
    id: 'pid',
    desc: true,
  },
];

const TabRelatedProjects: React.FC<ApplicationContextState> = ({ application }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<ApplicationRelatedProjectsResponseDto>>();

  const columns = useMemo<Column<ApplicationRelatedProjectsResponseDto>[] | any>(
    () => [
      {
        Header: t('table.project_id'),
        accessor: 'pid',
      },
      {
        Header: t('table.project_name'),
        width: 300,
        accessor: 'project_name',
      },
      {
        Header: t('table.amount'),
        width: 300,
        accessor: 'amount',
        Cell: (cell: Cell<ApplicationRelatedProjectsResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.funded_at'),
        accessor: 'funded_at',
        Cell: (cell: Cell<ApplicationRelatedProjectsResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 100,
        Cell: (cell: Cell<ApplicationRelatedProjectsResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                permissions={Permissions.BO__PROJECTS__VIEW}
                route={generatePath(RouteList.PROJECTS.VIEW_PROJECT, { projectId: row.id })}
              />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      if (!application) return Promise.resolve();

      return Api.applications
        .fetchApplicationRelatedProjects(application.id, request)
        .then((response) => setResponse(response));
    },
    [application],
  );

  return (
    <React.Fragment>
      <h3>{t('applications.related_projects')}</h3>
      <hr />
      <div className={'small lh-sm'}>
        <HiddenColumnsProvider title={'ApplicationUpdateTabsTabStatusHistory'}>
          <Table
            title={'ApplicationUpdateTabsTabStatusHistory'}
            columns={columns}
            data={response}
            onFetchData={fetchData}
            initialSortBy={INITIAL_SORT_ORDER}
          />
        </HiddenColumnsProvider>
      </div>
    </React.Fragment>
  );
};

export default withApplication(TabRelatedProjects);
