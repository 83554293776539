import React, { useCallback, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Col, Form, FormGroup, ModalBody, ModalFooter } from 'reactstrap';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { success } from 'src/services/toastr';
import * as Yup from 'yup';
import {
  UpdateUserAccreditationRequestDto,
  UserAccreditationListResponseDto,
} from 'src/types/api/user';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import Api from 'src/api';
import TextInput from 'src/components/Form/TextInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SelectCountryInput from 'src/components/Form/Select/SelectCountryInput';

interface Props {
  accreditation: UserAccreditationListResponseDto;
  handleAccreditationUpdate: (accreditation: UserAccreditationListResponseDto) => void;
}

const EditUserAccreditationForm = ({ accreditation, handleAccreditationUpdate }: Props) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const [initialValues] = useState<UpdateUserAccreditationRequestDto>({
    first_name: accreditation.first_name,
    middle_name: accreditation.middle_name,
    last_name: accreditation.last_name,
    gov_code: accreditation.gov_code,
    dob: accreditation.dob,
    residence: accreditation.residence,
    gender: accreditation.gender,
    document_number: accreditation.document_number,
    document_doi: accreditation.document_doi,
    document_doe: accreditation.document_doe,
    document_reason: accreditation.failed_at
      ? accreditation.fail_reason
      : accreditation.accreditation_reason,
  });

  const UpdateUserAccreditationRequestSchema = Yup.object().shape({
    first_name: Yup.string().nullable(),
    middle_name: Yup.string().nullable(),
    last_name: Yup.string().nullable(),
    gov_code: Yup.string().nullable(),
    dob: Yup.date().nullable(),
    residence: Yup.string().nullable(),
    gender: Yup.string().nullable(),
    document_number: Yup.string().nullable(),
    document_doi: Yup.date().nullable(),
    document_doe: Yup.date().nullable(),
    document_reason: Yup.string().required(),
  });

  const onSubmit = useCallback(
    (
      request: UpdateUserAccreditationRequestDto,
      helper: FormikHelpers<UpdateUserAccreditationRequestDto>,
    ) => {
      Api.user
        .updateUserAccreditation(accreditation.id, request)
        .then((res) => {
          handleAccreditationUpdate(res);
          success(t('common.updated_success'));
          hideModal();
        })
        .catch((e) => {
          helper.setErrors(e.response?.errors);
        });
    },
    [accreditation.id, hideModal, t, handleAccreditationUpdate],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UpdateUserAccreditationRequestSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <>
          <ModalBody className={''}>
            <Form onSubmit={handleSubmit} id={'reason-form'}>
              <FormGroup>
                <div className="mb-3">
                  <TextInput name="first_name" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <TextInput name="middle_name" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <TextInput name="last_name" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <TextInput name="gov_code" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <DatePickerInput name="dob" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <TextInput name="gender" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <SelectCountryInput name="residence" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <TextInput name="document_number" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <DatePickerInput name="document_doi" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <DatePickerInput name="document_doe" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="mb-3">
                  <TextInput name="document_reason" />
                </div>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-center'}>
            <Col>
              <DenyButton title={t('common.cancel')} onClick={hideModal} />
            </Col>
            <Col>
              <SaveButton
                form={'reason-form'}
                submitting={isSubmitting}
                title={t('common.save')}
                type={'submit'}
              />
            </Col>
          </ModalFooter>
        </>
      )}
    </Formik>
  );
};

export default EditUserAccreditationForm;
