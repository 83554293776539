import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { default as env } from 'src/env.json';
import { Subject } from 'rxjs';
import { error as toastrError, errorCta as toastrErrorCta } from 'src/services/toastr';
import { AbortableAxiosResponse } from 'src/types';
import { AuthUtils } from 'src/services/useAuth/Auth.utils';

export type CustomAxiosError = AxiosError & {
  status: number | null;
};

type AbortableRequestInterface = <T = any, R = AxiosResponse<T>>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig | undefined,
) => AbortableAxiosResponse<R>;

type RequestInterface = <T = any, R = AxiosResponse<T>>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig | undefined,
) => Promise<R>;

class Http {
  axios: AxiosInstance;

  post: RequestInterface;

  get: AxiosInstance['get'];

  abortableGet: AbortableRequestInterface;

  put: RequestInterface;

  delete: RequestInterface;

  baseUrl = env.BASE_URL;

  session: Subject<Date>;

  constructor() {
    this.session = new Subject<Date>();

    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Expose-Headers': 'Access-Control-*',
      'Access-Control-Allow-Headers':
        'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      'Access-Control-Allow-Origin': '*',
      Allow: 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      crossdomain: true,
    };

    this.axios = axios.create({
      withCredentials: true,
      headers: defaultHeaders,
    });

    this.post = this.axios.post;
    this.get = this.axios.get;

    this.abortableGet = (url, data) => {
      return {
        promise: this.axios.get(url, data),
        signal: new AbortController(),
      };
    };

    this.put = this.axios.put;
    this.delete = (url: string, data?: any, config?: AxiosRequestConfig | undefined) =>
      this.axios.delete(url, { ...config, data: data });

    this.axios.interceptors.request.use((request) => {
      return request;
    }, this.errorInterceptor);

    this.axios.interceptors.response.use((response: AxiosResponse) => {
      return response.data;
    }, this.errorInterceptor);
  }

  setBearer(accessToken: string | null): void {
    if (accessToken === null) {
      return;
    }

    this.axios.defaults.headers.common = {
      ...this.axios.defaults.headers.common,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  removeBearer(): void {
    this.axios.defaults.headers.common = {
      ...this.axios.defaults.headers.common,
      Authorization: undefined,
    };
  }

  errorInterceptor = async (error: AxiosError): Promise<CustomAxiosError> => {
    const status = error.response?.status || null;

    const data =
      error?.response?.data instanceof Blob
        ? JSON.parse(await error.response.data.text())
        : error?.response?.data;

    error.message = data?.message || data?.error || 'Something went wrong. Please try again';
    error.response = data || {};

    if (data?.cta_url) {
      toastrErrorCta(error.message, data.cta_url);
    } else {
      toastrError(error.message);
    }

    if (status === 401) {
      AuthUtils.removeJwt();
    }

    return Promise.reject({
      ...error,
      status: status,
    });
  };
}

export default Http;
