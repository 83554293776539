import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useField } from 'formik';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';

export interface ReactGroupSelectProps extends StateManagerProps {
  name: string;
  groupedOptions: GroupedOption[] | undefined;
  hideLabel?: boolean;
}

export interface GroupedOption {
  label: string;
  options: ReactSelectOption[];
}

const ReactSelectGroupInput: React.FC<ReactGroupSelectProps> = ({
  name,
  groupedOptions,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const [field, meta, helper] = useField(name);

  if (!props.placeholder) {
    props.placeholder = t('label.' + name);
  }

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const selectValue = () => {
    if (!groupedOptions) return '';

    for (const options of groupedOptions) {
      for (const option of options.options) {
        if (option.value === field.value) return option;
      }
    }
    return '';
  };

  const defaultStyles = {
    ...props.styles,
  };

  return (
    <>
      <div className={meta?.error ? 'is-invalid' : ''}>
        {!props.hideLabel && <label htmlFor={field.name}>{props.placeholder}</label>}
        <Select
          options={groupedOptions}
          name={name}
          onBlur={field.onBlur}
          value={selectValue()}
          isLoading={groupedOptions === undefined}
          {...props}
          onChange={(option: any, meta: any) => {
            if (Array.isArray(option)) {
              helper.setValue((option as ReactSelectOption[]).map((option) => option.value));
            } else {
              helper.setValue(option?.value);
            }
            props.onChange && props.onChange(option, meta);
          }}
          styles={defaultStyles}
        />
      </div>
      {meta.error && <div className={!!meta.error ? 'invalid-feedback' : ''}>{meta.error}</div>}
    </>
  );
};

ReactSelectGroupInput.defaultProps = {
  hideLabel: false,
};

export default ReactSelectGroupInput;
