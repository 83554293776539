import React, { useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Container, Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import TextInput from 'src/components/Form/TextInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import Api from 'src/api';
import { RouteList } from 'src/routes';
import { setGlobalLoading } from 'src/modules/app/actions';
import { LoginRequestDto } from 'src/types/api/authentication';
import { useAuth } from 'src/services/useAuth';

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();

  const { login } = useAuth();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const [loginRequest] = useState<LoginRequestDto>({
    email: '',
    password: '',
  });

  const onSubmit = useCallback(
    async (request: LoginRequestDto, helpers: FormikHelpers<LoginRequestDto>) => {
      try {
        const response = await Api.authentication.login(request);

        if (response.access_token) {
          await login(response.access_token, response.permissions);
          history.push(RouteList.DASHBOARD.DASHBOARD.LIST);
        }
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [history, login],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{t('common.welcome_back')}</h5>
                  </div>
                  <div className="p-2 mt-4">
                    <Formik
                      initialValues={loginRequest}
                      validateOnBlur={false}
                      validateOnChange={false}
                      isInitialValid={true}
                      validationSchema={LoginSchema}
                      onSubmit={onSubmit}
                    >
                      {({
                        values,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormGroup>
                            <div className="mb-3">
                              <TextInput value={values.email} name="email" />
                            </div>
                            <div className="mb-3">
                              <div className="text-end">
                                <Link
                                  className="text-muted"
                                  to={RouteList.AUTHENTICATION.RESET_PASSWORD}
                                >
                                  {t('common.forgot_password')}
                                </Link>
                              </div>
                              <TextInput value={values.password} name="password" type="password" />
                            </div>
                            <div className={'mt-4 mb-4'}>
                              <PrimaryButton title={t('common.login')} submitting={isSubmitting} />
                            </div>
                          </FormGroup>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
