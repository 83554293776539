import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { Cell, Column } from 'react-table';
import Api from 'src/api';
import Table from 'src/components/Table';
import { Permissions } from 'src/helpers/auth/permissions';

import { CustomEmailListHistoryResponseDto } from 'src/types/api/communication';
import PermissionAction from 'src/components/PermissionAction';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ViewCustomEmailListModal from '../ViewCustomEmailListModal';
import ViewInvalidEmailsModal from '../ViewInvalidEmailsModal';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const CustomEmailHistoryList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<CustomEmailListHistoryResponseDto>>();
  const { showModal } = useGlobalModalContext();
  const capitalizeFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const columns = useMemo<Column<CustomEmailListHistoryResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'string',
        width: 100,
      },
      {
        Header: t('table.file_name'),
        accessor: 'file_name',
        sortType: 'string',
        width: 100,
      },
      {
        Header: t('table.emails_total'),
        accessor: 'traits.total_emails_count',
        sortType: 'number',
        width: 75,
      },
      {
        Header: t('table.valid_emails'),
        accessor: 'traits.valid_emails_count',
        sortType: 'number',
        width: 57,
      },
      {
        Header: t('table.invalid_emails'),
        accessor: 'traits.invalid_emails_count',
        sortType: 'number',
        width: 75,
        Cell: (cell: Cell<CustomEmailListHistoryResponseDto>) => {
          let emails: string[] = [];
          try {
            const logsJson = JSON.parse(cell.row.original.logs);
            emails = logsJson.invalid_emails;
          } catch (error) {
            console.error('Error parsing logs', error);
          }
          return (
            <div className={'actions d-flex gap-2 justify-content-center'}>
              <span>{cell.row.original.traits.invalid_emails_count}</span>
              {cell.row.original.traits.invalid_emails_count > 0 &&
                cell.row.original.logs.length > 0 && (
                  <PermissionAction
                    permissions={Permissions.BO__COMMUNICATION__CUSTOM_HISTORY__VIEW}
                  >
                    <a
                      className={'link-primary'}
                      style={{ cursor: 'pointer' }}
                      title={t('common.view')}
                      onClick={() => {
                        showModal(<ViewInvalidEmailsModal emails={emails} />);
                      }}
                    >
                      <i className={'fas fa-eye'} />
                    </a>
                  </PermissionAction>
                )}
            </div>
          );
        },
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'number',
        width: 75,
        Cell: (cell: Cell<CustomEmailListHistoryResponseDto>) => {
          return <span>{capitalizeFirst(cell.row.original.status)}</span>;
        },
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        width: 75,
        Cell: (cell: Cell<CustomEmailListHistoryResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.completed_at'),
        accessor: 'completed_at',
        Cell: (cell: Cell<CustomEmailListHistoryResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
        width: 80,
      },
      {
        Header: t('table.failed_at'),
        accessor: 'failed_at',
        sortType: 'datetime',
        width: 90,
        Cell: (cell: Cell<CustomEmailListHistoryResponseDto>) => (
          <div>{cell.value ? t('common.date_full', { date: cell.value }) : '-'}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 50,
        Cell: (cell: Cell<CustomEmailListHistoryResponseDto>) => {
          return (
            <div className={'actions d-flex gap-2 justify-content-center'}>
              <PermissionAction permissions={Permissions.BO__COMMUNICATION__CUSTOM_HISTORY__VIEW}>
                <a
                  className={'link-primary'}
                  style={{ cursor: 'pointer' }}
                  title={t('common.view')}
                  onClick={() => {
                    showModal(
                      <ViewCustomEmailListModal
                        subject={cell.row.original.traits.subject}
                        message={cell.row.original.traits.message}
                        date={t('common.date_full', { date: cell.row.original.traits.sent_at })}
                      />,
                    );
                  }}
                >
                  <i className={'fas fa-eye'} />
                </a>
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [showModal, t],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.communication.fetchNotifyEmailHistory(request).then((response) => {
      setData(response);
    });
  }, []);

  return (
    <React.Fragment>
      <HiddenColumnsProvider title={'CustomNotificationHistoryListIndex'}>
        <Container fluid>
          <Card>
            <CardBody>
              <h4 className={'mb-4'}>{t('menu.custom_history')}</h4>
              <Table
                title={'CustomNotificationHistoryListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                disableFiltersInsideTable={false}
              />
            </CardBody>
          </Card>
        </Container>
      </HiddenColumnsProvider>
    </React.Fragment>
  );
};

export default CustomEmailHistoryList;
