import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormGroup, Col, ColProps } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import WYSIWYGInput from 'src/components/Form/WYSIWYGInput';
import { defaultTranslatableValue } from 'src/helpers';
import {
  CategoryResponseDto,
  FaqResponseDto,
  SaveFaqRequestDto,
} from 'src/types/api/landingPage/faq';
import { SelectAsyncInput } from 'src/components/Form/Select';
import { PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import TextInput from 'src/components/Form/TextInput';
import { UseNumbers } from 'src/helpers/useNumbers';

const INITIAL_STATE: SaveFaqRequestDto = {
  question: defaultTranslatableValue(),
  answer: defaultTranslatableValue(),
  category_id: '',
  sort_number: null,
};

interface FaqFormProps {
  faq?: FaqResponseDto;
  onSubmit: (request: SaveFaqRequestDto, helper: FormikHelpers<SaveFaqRequestDto>) => void;
}

const FaqForm: React.FC<FaqFormProps> = ({ faq, onSubmit }) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.LT);

  const FaqSchema = Yup.object().shape({
    question: Yup.string().translatableValueSchema(Yup.string().required()),
    answer: Yup.string().translatableValueSchema(Yup.string().required()),
    category_id: Yup.string().required(),
    sort_number: Yup.number().nullable(),
  });

  const [faqRequest, setFaqRequest] = useState<SaveFaqRequestDto>(INITIAL_STATE);

  useEffect(() => {
    if (faq) {
      setFaqRequest({ ...faq, category_id: faq.category.id });
    }
  }, [faq]);

  const multiLocaleAttrs = useCallback(
    (inputName: string) => {
      return {
        name: inputName + '.' + language,
        placeholder: t('label.' + inputName),
      };
    },
    [language, t],
  );

  const colProps: ColProps = {
    className: 'mb-4',
  };

  const fetchCategories = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };
    const response = await Api.faq.fetchFilteredCategories(request);

    return response.data.map((category: CategoryResponseDto) => ({
      value: category.id,
      label: `${category.title ?? ''}`,
    }));
  };

  return (
    <React.Fragment>
      <LanguageSwitcher locale={language} setLocale={setLanguage} />
      <Formik
        initialValues={faqRequest}
        enableReinitialize={true}
        validateOnChange={false}
        validationSchema={FaqSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Col {...colProps}>
                <SelectAsyncInput
                  name={'category_id'}
                  loadOptions={fetchCategories}
                  isMulti={false}
                  isClearable={true}
                  placeholder={t('label.category')}
                />
              </Col>
              <Col {...colProps}>
                <WYSIWYGInput {...multiLocaleAttrs('question')} />
              </Col>
              <Col {...colProps}>
                <WYSIWYGInput {...multiLocaleAttrs('answer')} />
              </Col>
              <Col {...colProps}>
                <TextInput
                  name={'sort_number'}
                  step={'1'}
                  type={'number'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </Col>
              <Col {...colProps}>
                <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
              </Col>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default FaqForm;
