import React from 'react';
import { BaseTabProps } from 'src/components/Tabs';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import Refinancing from './CreditDetails/Refinancing';
import WorkingCapital from './CreditDetails/WorkingCapital';
import RealEstateAcquisitions from './CreditDetails/RealEstateAcquisition';
import Other from 'src/pages/Application/Update/Tabs/CreditDetails/Other';
import { ApplicationType } from 'src/helpers/Enums/Application/ApplicationType';
import RealEstateDevelopment from 'src/pages/Application/Update/Tabs/CreditDetails/RealEstateDevelopment';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';
import { useTranslation } from 'react-i18next';

interface Props extends BaseTabProps, ApplicationTabProps, ApplicationContextState {}

const TabCreditDetails: React.FC<Props> = ({ application, showSubmit }) => {
  const { t } = useTranslation();

  if (application?.type == ApplicationType.REAL_ESTATE_DEVELOPMENT) {
    return <RealEstateDevelopment showSubmit={showSubmit} />;
  }

  if (application?.type == ApplicationType.REFINANCING) {
    return <Refinancing showSubmit={showSubmit} />;
  }

  if (application?.type == ApplicationType.WORKING_CAPITAL) {
    return <WorkingCapital showSubmit={showSubmit} />;
  }

  if (application?.type == ApplicationType.REAL_ESTATE_ACQUISITION) {
    return <RealEstateAcquisitions showSubmit={showSubmit} />;
  }

  if (application?.type == ApplicationType.OTHER) {
    return <Other showSubmit={showSubmit} />;
  }

  return (
    <span>
      <b>{t('applications.type.application_type_is_not_set')}</b>
    </span>
  );
};

export default withApplication(TabCreditDetails);
