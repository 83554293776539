import React from 'react';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';

interface Props {
  show?: boolean;
  active?: boolean;
}

const NotificationIcon: React.FC<Props> = ({ show, active }) => {
  return (
    <>
      {show && (
        <Icon
          path={mdiAlertCircleOutline}
          color={active ? 'white' : 'red'}
          style={{ height: '1.5rem', minWidth: '1.5rem' }}
        />
      )}
    </>
  );
};

export default NotificationIcon;
