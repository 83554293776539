import { createContext } from 'react';
import { LocaleFormikContextObject } from './LocaleFormik.types';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import i18n from 'src/services/i18n';

const LocaleFormikContext = createContext<LocaleFormikContextObject>({
  state: {
    locale: i18n.resolvedLanguage as LanguageEnum,
  },
  setState: () => ({}),
});

export { LocaleFormikContext };
