import Client from './client';
import { SendCustomNotificationRequestDto } from 'src/types/api/user';

class SharedUsers extends Client {
  api = {
    SEND_CUSTOM_NOTIFICATION: `${this.http.baseUrl}/users/shared/notify`,
  };

  sendCustomNotification = (request: SendCustomNotificationRequestDto): Promise<any> => {
    return this.http.post(this.api.SEND_CUSTOM_NOTIFICATION, request);
  };
}

export default SharedUsers;
