import Client from 'src/api/services/client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { FilterSelectValuesResponseDto } from 'src/types/api/common';
import { FailedInvestmentPayoutsListResponseDto } from 'src/types/api/payments/failedInvestmentPayouts';

class FailedInvestmentPayouts extends Client {
  api = {
    FETCH_FAILED_INVESTMENT_PAYOUTS: `${this.http.baseUrl}/payments/failed-investment-payouts/filter`,
    FETCH_PAYOUT_PROJECTS: `${this.http.baseUrl}/payments/failed-investment-payouts/filter/projects`,

    FETCH_MIGRATED_ACCOUNT_FAILED_INVESTMENT_PAYOUTS: `${this.http.baseUrl}/payments/failed-investment-payouts/migrated-accounts/filter`,
    RETRY_MIGRATED_ACCOUNTS_FAILED_INVESTMENT_PAYOUTS: `${this.http.baseUrl}/payments/failed-investment-payouts/migrated-accounts/retry`,
  };

  fetchFailedInvestmentPayouts = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<FailedInvestmentPayoutsListResponseDto>> => {
    return this.http.get(this.api.FETCH_FAILED_INVESTMENT_PAYOUTS, { params: request });
  };

  fetchPayoutProjects = (): Promise<PaginationData<FilterSelectValuesResponseDto[]>> => {
    return this.http.get(this.api.FETCH_PAYOUT_PROJECTS);
  };

  fetchMigratedAccountsFailedInvestmentPayouts = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<FailedInvestmentPayoutsListResponseDto>> => {
    return this.http.get(this.api.FETCH_MIGRATED_ACCOUNT_FAILED_INVESTMENT_PAYOUTS, {
      params: request,
    });
  };

  retryMigratedAccountsFailedInvestmentPayouts = (): Promise<void> => {
    return this.http.post(this.api.RETRY_MIGRATED_ACCOUNTS_FAILED_INVESTMENT_PAYOUTS);
  };
}

export default FailedInvestmentPayouts;
