import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { DashboardPageGraphDataDto, DashboardPageGraphSearchDto } from 'src/types/api/dashboard';
import * as Yup from 'yup';
import Api from 'src/api';
import { getCurrentMonthFirstDay, getCurrentMonthLastDay } from 'src/helpers/utilts';
import { Formik } from 'formik';
import { Form } from 'reactstrap';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SaveButton from 'src/components/Form/SaveButton';
import Loader from 'src/components/Loader';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const ProjectsWalletsSumByDayChart = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<DashboardPageGraphDataDto[]>([]);

  const Schema = Yup.object().shape({
    start_date: Yup.date().required(),
    end_date: Yup.date()
      .required()
      .when('start_date', (start_date, schema) => {
        return start_date
          ? schema.min(start_date, t('validation.date.min', { min: start_date }))
          : schema;
      }),
  });

  useEffect(() => {
    Api.dashboard
      .fetchProjectsWalletSumsByDayChartData(getCurrentMonthFirstDay(), getCurrentMonthLastDay())
      .then((response) => {
        setData(response.data);
      });
  }, []);

  const handleSearch = async (values: DashboardPageGraphSearchDto) => {
    const response = await Api.dashboard.fetchProjectsWalletSumsByDayChartData(
      values.start_date,
      values.end_date,
    );
    setData(response.data);

    return true;
  };

  return (
    <div className="w-100">
      <div className="card-ml-2 card-mr-2">
        <h3>{t('dashboard.projects_wallet_sum_by_day_chart')}</h3>
        <Formik
          initialValues={{
            start_date: getCurrentMonthFirstDay(),
            end_date: getCurrentMonthLastDay(),
          }}
          validationSchema={Schema}
          enableReinitialize={true}
          onSubmit={handleSearch}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <div style={{ display: 'inline-flex' }} className="w-100">
                <div className="mb-2 w-50">
                  <div className="mr-5">
                    <DatePickerInput name="start_date" />
                  </div>
                </div>
                <div className="mb-2 w-50">
                  <div className="ml-5">
                    <DatePickerInput name="end_date" />
                  </div>
                </div>
              </div>
              <SaveButton title={t('common.search')} submitting={isSubmitting} />
            </Form>
          )}
        </Formik>
        {data.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <Loader />
          </div>
        ) : (
          <ResponsiveContainer
            className="mt-5"
            width="100%"
            height="100%"
            minHeight="500px"
            maxHeight={500}
          >
            <BarChart width={500} height={300} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" name={t('dashboard.wallet_sum')} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default ProjectsWalletsSumByDayChart;
