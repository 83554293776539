import { useField, useFormikContext, Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  value: string;
  showPlaceholder: boolean;
}

const RadioInput: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props.name);
  const formik = useFormikContext();

  if (!props.placeholder && props.showPlaceholder) {
    props.placeholder = t('label.' + props.value);
  }

  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;

  return (
    <div className={`form-check`}>
      <label className={`form-check-label form-label ${isError ? ' is-invalid' : ''}`}>
        <Field
          onChange={field.onChange}
          onBlur={field.onBlur}
          type="radio"
          className={`form-check-input${isError ? ' is-invalid' : ''}`}
          name={props.name}
          value={props.value}
        />
        <div className={'ms-2'}>{props.placeholder}</div>
      </label>
    </div>
  );
};

export default RadioInput;
