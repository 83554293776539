import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import { getObjectKeys } from 'src/helpers';
import { LocaleFormikStateObject } from 'src/components/Form/LocaleFormik/LocaleFormik.types';
import React from 'react';

const useFieldValidationErrors = () => {
  const checkFormikErrors = (
    errors: any,
    state: LocaleFormikStateObject,
    setState: React.Dispatch<React.SetStateAction<LocaleFormikStateObject>>,
  ) => {
    const locales = Object.values(LanguageEnum);
    const keys = getObjectKeys(errors);
    keys.forEach((key) => {
      const entry = errors[key];

      if (typeof entry !== 'object') {
        return;
      }

      if (state.locale in entry) {
        return;
      }

      for (let i = 0; i < locales.length; i++) {
        const loc = locales[i];
        if (loc in entry) {
          setState((prev: any) => ({ ...prev, locale: loc }));
          return;
        }
      }
    });
  };

  return {
    checkFormikErrors,
  };
};

export default useFieldValidationErrors;
