import React, { HTMLAttributes } from 'react';
import usePermissions from '../helpers/usePermissions';

interface Props extends HTMLAttributes<any> {
  permissions: string | string[];
}

const PermissionAction: React.FC<Props> = ({ permissions, children, ...props }) => {
  const p = usePermissions();

  if (p.doesNotHaveAny(permissions)) {
    return null;
  }

  return <React.Fragment {...props}>{children}</React.Fragment>;
};

export default PermissionAction;
