import React, { useCallback, useState } from 'react';

import { BaseTabProps } from 'src/components/Tabs';
import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';
import { ProjectInvestmentListResponseDto } from 'src/types/api/projects';
import useInvestmentColumns from 'src/pages/Project/Update/Tabs/Investments/hooks/useInvestmentColumns';
import useReservedInvestmentColumns from 'src/pages/Project/Update/Tabs/Investments/hooks/useReservedInvestmentColumns';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import FormModal from 'src/components/Modal/Modals/FormModal';
import CreateReservedInvestmentModalForm from 'src/pages/Project/Update/Tabs/Investments/modals/CreateReservedInvestmentModalForm';

interface Props extends BaseTabProps, ProjectContextState {}

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const TabInvestments: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const [investments, setInvestments] =
    useState<PaginationData<ProjectInvestmentListResponseDto>>();
  const [reservedInvestments, setReservedInvestments] =
    useState<PaginationData<ProjectInvestmentListResponseDto>>();

  const fetchInvestmentData = useCallback(
    (request: PaginationDataFilter | undefined): Promise<any> => {
      if (!project) {
        return Promise.reject();
      }

      return Api.projects.filterProjectInvestments(project?.id, request).then((res) => {
        setInvestments(res);
      });
    },
    [project],
  );

  const fetchReservedInvestmentData = useCallback(
    (request: PaginationDataFilter | undefined): Promise<any> => {
      if (!project) {
        return Promise.reject();
      }

      return Api.projects.filterProjectReservedInvestments(project?.id, request).then((res) => {
        setReservedInvestments(res);
      });
    },
    [project],
  );

  const handleCreateReservedInvestment = () => {
    showModal(
      <FormModal
        title={t('projects.investment.create_reserved_investment')}
        size={'xl'}
        form={
          <CreateReservedInvestmentModalForm
            project={project}
            afterCreateInvestmentEvent={handleAfterCreateReservedInvestmentEvent}
          />
        }
      />,
    );
  };

  const handleAfterCreateReservedInvestmentEvent = (
    investment: ProjectInvestmentListResponseDto,
  ) => {
    if (reservedInvestments) {
      const clonedReservedInvestments = { ...reservedInvestments };
      clonedReservedInvestments.data.push(investment);

      setReservedInvestments(clonedReservedInvestments);
    }
  };

  const moveReservedInvestmentToInvestmentsTable = (
    investment: ProjectInvestmentListResponseDto,
  ) => {
    if (investments && reservedInvestments) {
      const clonedInvestments = { ...investments };
      const clonedReservedInvestments = { ...reservedInvestments };

      clonedInvestments.data.push(investment);

      setInvestments(clonedInvestments);

      const reservedInvestmentToBeDeleted = clonedReservedInvestments.data.findIndex(
        (item) => item.id === investment.id,
      );

      if (reservedInvestmentToBeDeleted !== -1) {
        clonedReservedInvestments.data.splice(reservedInvestmentToBeDeleted, 1);

        setReservedInvestments(clonedReservedInvestments);
      }
    }
  };

  const updateReservedInvestmentTable = (investment: ProjectInvestmentListResponseDto) => {
    if (reservedInvestments) {
      const clonedReservedInvestments = { ...reservedInvestments };

      const reservedInvestmentToBeUpdated = clonedReservedInvestments.data.findIndex(
        (item) => item.id === investment.id,
      );

      if (reservedInvestmentToBeUpdated !== -1) {
        clonedReservedInvestments.data[reservedInvestmentToBeUpdated] = investment;

        setReservedInvestments(clonedReservedInvestments);
      }
    }
  };

  const investmentColumns = useInvestmentColumns({ project, investments, setInvestments });
  const reservedInvestmentColumns = useReservedInvestmentColumns({
    project,
    moveReservedInvestmentToInvestmentsTable,
    updateReservedInvestmentTable,
  });

  if (!project) {
    return null;
  }

  return (
    <>
      <h3>{t('projects.investment.investments')}</h3>
      <hr />
      <HiddenColumnsProvider title={'ProjectInvestments'}>
        <Table
          title={'ProjectInvestments'}
          onFetchData={fetchInvestmentData}
          columns={investmentColumns}
          data={investments}
          searchable={true}
          enableQueryFilter={true}
          initialSortBy={INITIAL_SORT_ORDER}
        />
      </HiddenColumnsProvider>
      <div className="d-flex justify-content-between align-items-center mt-5">
        <h3>{t('projects.investment.reserved_investments')}</h3>
        <PrimaryButton
          type={'button'}
          className={'btn btn-success'}
          title={t('projects.investment.reserve_investment')}
          onClick={() => handleCreateReservedInvestment()}
        />
      </div>
      <hr />
      <HiddenColumnsProvider title={'ProjectReservedInvestments'}>
        <Table
          title={'ProjectReservedInvestments'}
          onFetchData={fetchReservedInvestmentData}
          columns={reservedInvestmentColumns}
          data={reservedInvestments}
          searchable={true}
          enableQueryFilter={true}
          initialSortBy={INITIAL_SORT_ORDER}
        />
      </HiddenColumnsProvider>
    </>
  );
};

export default withProject(TabInvestments);
