import React, { useCallback, useState } from 'react';
import { ModalComponent } from '../ModalComponent';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from '../GlobalModal';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ModalCloseButton from '../ModalCloseButton';
import { success } from 'src/services/toastr';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import TextInput from 'src/components/Form/TextInput';
import { AffiliateAmbassadorTypeEnum } from 'src/helpers/Enums/AffiliateAmbassadorTypeEnum';
import { SelectInput } from 'src/components/Form/Select';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { AffiliateAmbassadorResponseDto } from 'src/types/api/user';

interface Props {
  onAction: (request: EditAffiliateAmbassadorModalRequest) => Promise<any>;
  onDeleteAction: () => Promise<any>;
  initialValues: AffiliateAmbassadorResponseDto;
  actionText: string;
  title: string;
  body: string;
  successMessage: string;
}

export interface EditAffiliateAmbassadorModalRequest {
  type: AffiliateAmbassadorTypeEnum | undefined;
  title: string | undefined;
  ambassador_key: string | undefined;
}

const EditAffiliateAmbassadorModal: React.FC<Props> = ({
  onAction,
  title,
  body,
  successMessage,
  actionText,
  initialValues,
  onDeleteAction,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const [typeOptions] = useState<ReactSelectOption[]>([
    ...Object.values(AffiliateAmbassadorTypeEnum).map((type) => ({
      value: type,
      label: t('users.affiliate_ambassador_type.' + type),
    })),
  ]);

  const [showAmbassadorTitle, setShowAmbassadorTitle] = useState<boolean>(
    initialValues.type === AffiliateAmbassadorTypeEnum.COMPANY,
  );

  const AffiliateAmbassadorRequestSchema = Yup.object().shape({
    type: Yup.string().nullable(),
    title: Yup.string().nullable(),
    ambassador_key: Yup.string().nullable(),
  });

  const [affiliateAmbassadorRequest] = useState<EditAffiliateAmbassadorModalRequest>({
    type: initialValues.type ?? undefined,
    title: initialValues.title ?? undefined,
    ambassador_key: initialValues.ambassador_key ?? undefined,
  });

  const onSubmit = useCallback(
    (
      request: EditAffiliateAmbassadorModalRequest,
      helper: FormikHelpers<EditAffiliateAmbassadorModalRequest>,
    ) => {
      return onAction(request)
        .then(() => {
          success(successMessage);
          hideModal();
        })
        .catch((e) => {
          helper.setErrors(e.response?.errors);
        });
    },
    [hideModal, onAction, successMessage],
  );

  const onDelete = useCallback(() => {
    return onDeleteAction().then(() => {
      success(t('users.remove_affiliate_ambassador_success'));
      hideModal();
    });
  }, [hideModal, onDeleteAction, t]);

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title}
          </ModalHeader>
          <Formik
            initialValues={affiliateAmbassadorRequest}
            validationSchema={AffiliateAmbassadorRequestSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <>
                <ModalBody className={''}>
                  <p className={'text-pre'}>{body}</p>

                  <Form onSubmit={handleSubmit} id={'reason-form'}>
                    <FormGroup>
                      <div className="mb-3">
                        <SelectInput
                          placeholder={t('label.type')}
                          name={'type'}
                          options={typeOptions}
                          isClearable={true}
                          onChangeAction={(option) => {
                            setShowAmbassadorTitle(
                              option && option.value === AffiliateAmbassadorTypeEnum.COMPANY,
                            );
                          }}
                        />
                      </div>
                      <div hidden={!showAmbassadorTitle} className="mb-3">
                        <TextInput value={values.title} name="title" />
                      </div>
                      <div className="mb-3">
                        <TextInput value={values.ambassador_key} name="ambassador_key" />
                      </div>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <DenyButton title={t('common.delete')} onClick={() => onDelete()} />
                  </Col>
                  <Col>
                    <SaveButton
                      form={'reason-form'}
                      submitting={isSubmitting}
                      title={actionText}
                      type={'submit'}
                    />
                  </Col>
                </ModalFooter>
              </>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default EditAffiliateAmbassadorModal;
