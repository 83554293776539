import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Card, CardBody, Container } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import Table from 'src/components/Table';
import Api from 'src/api';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { Permissions } from 'src/helpers/auth/permissions';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { UserAdminResponseDto, UserResponseDto } from 'src/types/api/user';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const AdminList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<UserAdminResponseDto>>();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<UserResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.name'),
        accessor: 'name',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.email'),
        accessor: 'email',
        sortType: 'string',
        width: 300,
      },
      {
        Header: t('table.roles'),
        accessor: 'roles',
        disableSortBy: true,
        width: 300,
        Cell: (cell: Cell<UserAdminResponseDto>) => (
          <div className={'my-2 d-flex gap-2'}>
            {cell.row.original.roles.map((role) => (
              <Badge className={'bg-success'} key={role.id}>
                {role.name}
              </Badge>
            ))}
          </div>
        ),
      },
      {
        Header: t('table.action'),
        width: 100,
        Cell: (cell: Cell<UserResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                route={generatePath(RouteList.USERS.VIEW_USER, { userId: row.id })}
                permissions={Permissions.BO__USERS__ADMIN_FILTER}
                previousRoute={RouteList.USERS.ADMIN_LIST}
              />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.user.fetchFilteredAdminUsers(request).then(setData);
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-4'}>{t('menu.admins')}</h4>
            <HiddenColumnsProvider title={'AdminUserListIndex'}>
              <Table
                title={'AdminUserListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default AdminList;
