import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form } from 'reactstrap';

import { DashboardCardGrid } from 'src/pages/Common/Dashboard/DashboardCardGrid';
import Loader from 'src/components/Loader';
import { getCurrentMonthLastDay } from 'src/helpers/utilts';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SaveButton from 'src/components/Form/SaveButton';
import Api from 'src/api';
import {
  DashboardPageGraphSearchDto,
  DashboardPortfolioInformationDto,
} from 'src/types/api/dashboard';

export const DashboardPortfolioInformationBlock = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<DashboardPortfolioInformationDto | null>(null);

  const Schema = Yup.object().shape({
    start_date: Yup.date(),
    end_date: Yup.date()
      .required()
      .when('start_date', (start_date, schema) => {
        return start_date
          ? schema.min(start_date, t('validation.date.min', { min: start_date }))
          : schema;
      }),
  });

  useEffect(() => {
    Api.dashboard.fetchPortfolioInformation(null, getCurrentMonthLastDay()).then((response) => {
      setData(response);
    });
  }, []);

  const handleSearch = async (values: DashboardPageGraphSearchDto) => {
    const response = await Api.dashboard.fetchPortfolioInformation(
      values.start_date,
      values.end_date,
    );
    setData(response);

    return true;
  };

  return (
    <div>
      <h3 className="card-ml-1">{t('dashboard.portfolio_information')}</h3>

      <div className={'d-flex'}>
        <div className="w-100 card-m-1">
          <Formik
            initialValues={{
              start_date: '',
              end_date: getCurrentMonthLastDay(),
            }}
            validationSchema={Schema}
            enableReinitialize={true}
            onSubmit={handleSearch}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div style={{ display: 'inline-flex' }} className="w-100">
                  <div className="mb-2 w-50">
                    <div className="mr-5">
                      <DatePickerInput name="start_date" />
                    </div>
                  </div>
                  <div className="mb-2 w-50">
                    <div className="ml-5">
                      <DatePickerInput name="end_date" />
                    </div>
                  </div>
                </div>
                <SaveButton title={t('common.search')} submitting={isSubmitting} />
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {data === null ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <>
          <DashboardCardGrid
            cards={[
              {
                title: t('dashboard.funded'),
                value: t('common.money', {
                  value: data.funded_amount,
                }),
              },
              {
                title: t('dashboard.balance'),
                value: t('common.money', {
                  value: data.balance_amount,
                }),
              },
              {
                title: t('dashboard.not-late'),
                value: t('common.money', {
                  value: data.not_late_amount,
                }),
              },
              {
                title: t('dashboard.late'),
                value: t('common.money', {
                  value: data.late_amount,
                }),
              },
            ]}
          />
          <DashboardCardGrid
            cards={[
              {
                title: t('dashboard.repaid'),
                value: t('common.money', {
                  value: data.repaid_amount,
                }),
              },
            ]}
          />
        </>
      )}
    </div>
  );
};
