import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import usePermissions from '../helpers/usePermissions';
import { RouteList } from '../routes';

const AuthRoute: React.FC<Props> = ({
  component: Component,
  layout: Layout,
  permissions,
  location,
  redirect,
  ...rest
}) => {
  const remotePermissions = usePermissions();

  const getRedirectionToPath = (currentLocation: any, targetPathname: string) => {
    return (
      <Redirect
        to={{
          pathname: targetPathname,
          state: { from: currentLocation },
        }}
      />
    );
  };

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!remotePermissions.hasAll(permissions)) {
          const pathname = redirect !== undefined ? redirect : RouteList.HOME;
          return getRedirectionToPath(location, pathname);
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

interface Props extends RouteProps {
  component: React.FC<any> | React.ComponentClass<any>;
  layout: React.FC<any> | React.ComponentClass<any>;
  permissions: Array<string>;
  redirect?: string;
}

export default AuthRoute;
