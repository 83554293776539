import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import { setGlobalLoading } from 'src/modules/app/actions';
import { ExternalNewsResponseDto } from 'src/types/api/landingPage/externalNews';
import { Cell, Column } from 'react-table';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { generatePath, Link } from 'react-router-dom';
import { RouteList } from 'src/routes';
import { Permissions } from 'src/helpers/auth/permissions';
import PermissionAction from 'src/components/PermissionAction';
import DeleteEntityModal from 'src/components/Modal/Modals/DeleteEntityModal';
import Api from 'src/api';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import Table from 'src/components/Table';

const ExternalNews: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<ExternalNewsResponseDto>>();
  const { showModal } = useGlobalModalContext();
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.LT);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const INITIAL_SORT_ORDER = [
    {
      id: 'published_at',
      desc: true,
    },
  ];

  const columns = useMemo<Column<ExternalNewsResponseDto>[] | any>(
    () => [
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'datetime',
        width: 100,
        Cell: (cell: Cell<ExternalNewsResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.short_description'),
        accessor: 'short_description',
        sortType: 'string',
        width: 150,
        disableSortBy: true,
        Cell: (cell: Cell<ExternalNewsResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.short_description?.[language]}</span>;
        },
      },
      {
        Header: t('table.alt_text'),
        accessor: 'alt_text',
        sortType: 'string',
        width: 150,
        disableSortBy: true,
        Cell: (cell: Cell<ExternalNewsResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.alt_text?.[language]}</span>;
        },
      },
      {
        Header: t('table.picture_url'),
        accessor: 'picture_url',
        sortType: 'string',
        width: 150,
        disableSortBy: true,
        Cell: (cell: Cell<ExternalNewsResponseDto>) => {
          const row = cell.row.original;
          return (
            <span>
              <a href={row.picture_url} target={'_blank'} rel="noreferrer">
                {row.picture_url}
              </a>
            </span>
          );
        },
      },
      {
        Header: t('table.news_url'),
        accessor: 'news_url',
        sortType: 'string',
        width: 100,
        disableSortBy: true,
        Cell: (cell: Cell<ExternalNewsResponseDto>) => {
          const row = cell.row.original;
          return (
            <span>
              <a href={row.news_url} target={'_blank'} rel="noreferrer">
                {row.news_url}
              </a>
            </span>
          );
        },
      },
      {
        Header: t('table.published_at'),
        accessor: 'published_at',
        sortType: 'datetime',
        width: 100,
        Cell: (cell: Cell<ExternalNewsResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.visible_landing_page'),
        accessor: 'visible_landing_page',
        sortType: 'boolean',
        width: 120,
        disableSortBy: true,
        Cell: (cell: Cell<ExternalNewsResponseDto>) => (
          <div>{cell.value ? t('common.yes') : t('common.no')}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 80,
        Cell: (cell: Cell<ExternalNewsResponseDto> | any) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                route={generatePath(RouteList.LANDING_PAGE.EXTERNAL_NEWS.UPDATE, {
                  externalNewsId: row.id,
                })}
                permissions={Permissions.BO__LANDING_PAGE__EXTERNAL_NEWS__UPDATE}
              />
              <PermissionAction permissions={Permissions.BO__LANDING_PAGE__EXTERNAL_NEWS__DELETE}>
                <Link
                  to={'#'}
                  title={t('common.delete')}
                  onClick={(e) => {
                    e.preventDefault();
                    showModal(
                      <DeleteEntityModal
                        title={t('pages.delete_external_news')}
                        body={t('common.delete_generic')}
                        onDelete={() => {
                          return Api.externalNews
                            .deleteExternalNews(row.id)
                            .then(() => cell.dispatch({ type: 'refreshData' }));
                        }}
                      />,
                    );
                  }}
                  className={'link-danger'}
                >
                  <i className={'fas fa-trash-alt'} />
                </Link>
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [language, showModal, t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.externalNews
      .fetchFilteredExternalNews(request)
      .then((response) => setData(response));
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-3'}>{t('menu.landing_page.external_news')}</h4>
            <LanguageSwitcher locale={language} setLocale={setLanguage} />
            <HiddenColumnsProvider title={'ExternalNewsIndex'}>
              <Table
                title={'ExternalNewsIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                createComponent={
                  <PermissionAction
                    permissions={Permissions.BO__LANDING_PAGE__EXTERNAL_NEWS__CREATE}
                  >
                    <Link
                      to={RouteList.LANDING_PAGE.EXTERNAL_NEWS.CREATE}
                      className={'btn btn-primary w-100'}
                    >
                      {t('common.create_new')}
                    </Link>
                  </PermissionAction>
                }
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default ExternalNews;
