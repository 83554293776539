import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';

interface Props {
  emails: string[];
}

const ViewInvalidEmailsModal: React.FC<Props> = ({ emails }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
          {t('table.invalid_emails')}
        </ModalHeader>

        <ModalBody className={'custom-history-notification-form-style'}>
          <Form id={'viewCustomHistoryNotificationIndex'}>
            <FormGroup>
              <Row>
                <Col sm={12}>
                  <p>
                    {emails.map((email, index) => (
                      <span key={index}>
                        {email} {index + 1 < emails.length && ', '}
                      </span>
                    ))}
                  </p>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className={'d-flex justify-content-center'}></ModalFooter>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ViewInvalidEmailsModal;
