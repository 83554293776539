import React, { createContext, useContext, useState } from 'react';

type ContextType = {
  showModal: (modalType: JSX.Element, modalProps?: any) => void;
  hideModal: () => void;
};

const initalState: ContextType = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideModal: () => {},
};

const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC = ({ children }) => {
  const [modal, setModal] = useState<JSX.Element>();

  const showModal = (Modal: JSX.Element) => {
    setModal(<Modal.type {...Modal?.props} />);
  };

  const hideModal = () => {
    setModal(undefined);
  };

  return (
    <GlobalModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {modal}
    </GlobalModalContext.Provider>
  );
};
