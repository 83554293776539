import React from 'react';
import { Col, Row } from 'reactstrap';
import { BaseTabProps } from 'src/components/Tabs';
import {
  DocumentTemplateContextState,
  withDocumentTemplate,
} from 'src/pages/DocumentTemplates/DocumentTemplateContext';
import { useTranslation } from 'react-i18next';
import CopyOnClick from 'src/components/CopyOnClick';

interface Props extends BaseTabProps, DocumentTemplateContextState {}

const TabLegend: React.FC<Props> = ({ documentTemplate }) => {
  const { t } = useTranslation();
  const legend = Object.entries(documentTemplate?.legend ?? []);

  return (
    <>
      <h5 className="mb-3">{t('document_templates.legend')}</h5>
      <hr />
      {legend.length <= 0 && <>{t('document_templates.no_legend_info')}</>}
      <Row>
        {legend.map(([key, val], index) => {
          return (
            <Col lg={6} md={12} sm={12} xs={12} className={'mb-3'} key={index}>
              <span className="fw-bold">
                <CopyOnClick value={key} />
              </span>
              : {val}
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default withDocumentTemplate(TabLegend);
