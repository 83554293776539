import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { SelectInput } from 'src/components/Form/Select/index';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

interface YesNoReactSelectProps extends StateManagerProps {
  name: string;
  true_label?: string;
  false_label?: string;
  hideLabel?: boolean;
}

const SelectYesNo: React.FC<YesNoReactSelectProps> = ({ true_label, false_label, ...props }) => {
  const { t } = useTranslation();
  const [options] = useState<ReactSelectOption[]>([
    {
      value: true,
      label: true_label ?? t('common.yes'),
    },
    {
      value: false,
      label: false_label ?? t('common.no'),
    },
  ]);

  return (
    <>
      <SelectInput {...props} options={options} />
    </>
  );
};
export default SelectYesNo;
