import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { default as env } from 'src/env.json';
import buildYupLocale from './yup';
import { format, parseISO } from 'date-fns';
import { buildYupValidations } from 'src/services/validations';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: false,
      react: {
        useSuspense: true,
      },
      backend: {
        loadPath: env.BASE_URL + '/locale/{{lng}}',
        allowMultiLoading: false,
      },
      lng: 'en',
      fallbackLng: 'en',
      saveMissing: true,
      missingKeyHandler: (lngs, ns, key) => {
        console.info('Missing key translation key', key, lngs);
      },
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
        format: function (value, formatting, lng, options) {
          switch (formatting) {
            case 'bytes':
              const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

              let l = 0,
                n = parseInt(value, 10) || 0;

              while (n >= 1024 && ++l) {
                n = n / 1024;
              }

              return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
            case 'money':
              let formatter;

              if (lng === LanguageEnum.EE) {
                formatter = new Intl.NumberFormat(lng, {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  useGrouping: true,
                });
              } else {
                formatter = new Intl.NumberFormat(lng, {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                });
              }
              return formatter.format(value);
            case 'percents':
              let val = 0;

              if (value === null || value === undefined || isNaN(value)) {
                val = NaN;
              } else if (value % 1 === 0) {
                val = value.toFixed(0);
              } else {
                val = value.toFixed(2);
              }

              return val + '%';
          }

          if (value instanceof Date && formatting) return format(value, formatting);

          if (formatting && options && 'date' in options && options['date']) {
            const date = options['date'];
            return format(parseISO(date), formatting);
          }

          return value;
        },
      },
      preload: ['lt', 'de', 'ee', 'en'],
      load: 'languageOnly',
    },
    () => {
      buildYupLocale();
      buildYupValidations();
    },
  );

export default i18n;
