import React from 'react';
import { Form, Formik, FormikConfig as BaseFormikConfig, FormikValues } from 'formik';
import i18n from 'i18next';

import isFunction from 'lodash/isFunction';
import { LocaleFormikProvider } from './LocaleFormik.provider';
import { FormikProps } from 'formik/dist/types';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import useFieldValidationErrors from 'src/components/Form/LocaleFormik/useFieldValidationErrors';

type Children<T> =
  | React.ReactNode
  | ((
      props: FormikProps<T> & {
        locale: LanguageEnum;
        setLocale: (locale: LanguageEnum) => void;
        multiLocaleAttrs: (inputName: string) => {
          name: string;
          placeholder: string;
        };
      },
    ) => React.ReactNode);

type FormikConfig<T> = Omit<BaseFormikConfig<T>, 'children'> & {
  children?: Children<T>;
};

const LocaleFormik = <T1 extends FormikValues = FormikValues, T2 = Record<string, any>>(
  props: FormikConfig<T1> & T2 & Children<T1>,
): JSX.Element => {
  const { children, onSubmit } = props;
  const { checkFormikErrors } = useFieldValidationErrors();

  return (
    <LocaleFormikProvider>
      {({ state, setState }) => (
        <Formik<any>
          {...(props as any)}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={async (values, { setErrors, ...helpers }) => {
            onSubmit?.(values, {
              ...helpers,
              setErrors: (errors) => {
                checkFormikErrors(errors, state, setState);
                setErrors(errors);
              },
            });
          }}
        >
          {({ handleSubmit, errors, ...formikProps }: FormikProps<T1>) => (
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                checkFormikErrors(errors, state, setState);
                handleSubmit(e);
              }}
            >
              {!!children
                ? isFunction(children)
                  ? children({
                      handleSubmit,
                      errors,
                      ...formikProps,
                      locale: state.locale,
                      setLocale: (locale) => setState((prev) => ({ ...prev, locale })),
                      multiLocaleAttrs: (inputName: string) => ({
                        name: inputName + '.' + state.locale,
                        placeholder: i18n.t('label.' + inputName),
                      }),
                    })
                  : children
                : null}
            </Form>
          )}
        </Formik>
      )}
    </LocaleFormikProvider>
  );
};

export default LocaleFormik;
