import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { success } from 'src/services/toastr';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { SelectAsyncInput, SelectInput } from 'src/components/Form/Select';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { StoreApplicationRequestDto } from 'src/types/api/applications';
import Api from 'src/api';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ApplicationType } from 'src/helpers/Enums/Application/ApplicationType';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { PaginationDataFilter } from 'src/types';
import { formatArray } from 'src/helpers/formatters/common';
import { navigate } from 'src/helpers/navigate';

const StoreApplicationModal: React.FC = () => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [applicationTypes, setApplicationTypes] = useState<ReactSelectOption[] | undefined>(
    undefined,
  );
  const [changeApplicationOwnerRequest] = useState<StoreApplicationRequestDto>({
    owner_id: '',
    type: ApplicationType.REAL_ESTATE_DEVELOPMENT,
  });

  useEffect(() => {
    const applicationTypes: ReactSelectOption[] = [];

    Object.values(ApplicationType).map((applicationType) => {
      applicationTypes.push({
        value: applicationType,
        label: t('applications.type.' + applicationType),
      });
    });

    setApplicationTypes(applicationTypes);
  }, [t]);

  const fetchCompanies = async (inputValue?: string) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
    };

    const response = await Api.userCompanies.fetchCompaniesBriefList(request);
    const companies: ReactSelectOption[] = [];

    response.data.map((company) => {
      companies.push({
        value: company.id ?? '',
        label: formatArray([company.private_id, company.name]),
      });
    });

    return companies;
  };

  const onSubmit = useCallback(
    async (
      request: StoreApplicationRequestDto,
      helper: FormikHelpers<StoreApplicationRequestDto>,
    ) => {
      try {
        const response = await Api.applications.storeApplication(request);
        success(t('common.created_success'));
        hideModal();

        navigate(generatePath(RouteList.APPLICATION.VIEW, { applicationId: response.id }));
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [hideModal, t],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('applications.store.title')}
          </ModalHeader>
          <Formik initialValues={changeApplicationOwnerRequest} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <div className={'mb-4'}>
                    <SelectAsyncInput
                      placeholder={t('label.owner_id')}
                      name={'owner_id'}
                      isClearable={true}
                      loadOptions={fetchCompanies}
                    />
                  </div>
                  <div>
                    <SelectInput
                      placeholder={t('label.application_type')}
                      name={'type'}
                      options={applicationTypes}
                      isClearable={true}
                    />
                  </div>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default StoreApplicationModal;
