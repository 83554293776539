import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  CategoryResponseDto,
  FaqResponseDto,
  SaveFaqRequestDto,
} from 'src/types/api/landingPage/faq';

class Faq extends Client {
  api = {
    FETCH_SINGLE_FAQ: `${this.http.baseUrl}/faq-tab/faq/{faqId}`,
    FETCH_FAQ: `${this.http.baseUrl}/faq-tab/filter`,
    CREATE_FAQ: `${this.http.baseUrl}/faq-tab/faq`,
    UPDATE_FAQ: `${this.http.baseUrl}/faq-tab/faq/{faqId}`,
    DELETE_FAQ: `${this.http.baseUrl}/faq-tab/faq/{faqId}`,
    FETCH_FILTERED_CATEGORIES: `${this.http.baseUrl}/faq-tab/categories/filter`,
  };

  fetchFaq = (faqId: number | string): Promise<FaqResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_SINGLE_FAQ, { faqId });

    return this.http.get(url);
  };

  fetchFilteredFaq = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<FaqResponseDto>> => {
    return this.http.get(this.api.FETCH_FAQ, { params: request });
  };

  createFaq = (request: SaveFaqRequestDto): Promise<FaqResponseDto> => {
    return this.http.post(this.api.CREATE_FAQ, request);
  };

  updateFaq = (faqId: number | string, request: SaveFaqRequestDto): Promise<FaqResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_FAQ, { faqId });

    return this.http.put(url, request);
  };

  deleteFaq = (faqId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_FAQ, { faqId });

    return this.http.delete(url);
  };

  fetchFilteredCategories = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CategoryResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_CATEGORIES, { params: request });
  };
}

export default Faq;
