import Client from './client';
import {
  SaveTeamMemberRequestDto,
  TeamMemberResponseDto,
} from 'src/types/api/landingPage/teamMembers';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { SignUploadRequestDto } from 'src/types/api/common';

class TeamMembers extends Client {
  api = {
    FETCH_TEAM_MEMBER: `${this.http.baseUrl}/team-members/team-member/{teamMemberId}`,
    FETCH_TEAM_MEMBERS: `${this.http.baseUrl}/team-members/filter`,
    CREATE_TEAM_MEMBER: `${this.http.baseUrl}/team-members/team-member`,
    UPDATE_TEAM_MEMBER: `${this.http.baseUrl}/team-members/team-member/{teamMemberId}`,
    UPLOAD_PICTURES: `${this.http.baseUrl}/team-members/team-member/pictures/upload`,
    DELETE_TEAM_MEMBER: `${this.http.baseUrl}/team-members/team-member/{teamMemberId}`,
  };

  fetchTeamMember = (teamMemberId: number | string): Promise<TeamMemberResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_TEAM_MEMBER, { teamMemberId });

    return this.http.get(url);
  };

  fetchFilteredTeamMembers = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<TeamMemberResponseDto>> => {
    return this.http.get(this.api.FETCH_TEAM_MEMBERS, { params: request });
  };

  deleteTeamMember = (teamMemberId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_TEAM_MEMBER, { teamMemberId });

    return this.http.delete(url);
  };

  createTeamMember = (request: SaveTeamMemberRequestDto): Promise<TeamMemberResponseDto> => {
    return this.http.post(this.api.CREATE_TEAM_MEMBER, request);
  };

  updateTeamMember = (
    teamMemberId: number | string,
    request: SaveTeamMemberRequestDto,
  ): Promise<TeamMemberResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_TEAM_MEMBER, { teamMemberId });

    return this.http.put(url, request);
  };

  uploadPicture = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.put(this.api.UPLOAD_PICTURES, request);
  };
}

export default TeamMembers;
