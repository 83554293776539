import React, { useCallback } from 'react';

import { BaseTabProps } from 'src/components/Tabs';
import Api from 'src/api';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { CompanyContextState, withCompany } from 'src/pages/User/Update/Company/CompanyContext';
import { WalletP2POperationsResponseDto } from 'src/types/api/operations';
import P2POperationTable from 'src/components/Operations/P2POperationTable';

interface Props extends BaseTabProps, CompanyContextState {}

const TabWalletP2POperations: React.FC<Props> = ({ company }) => {
  const fetchData = useCallback(
    (
      request: PaginationDataFilter | undefined,
    ): Promise<PaginationData<WalletP2POperationsResponseDto>> => {
      if (!company) {
        return Promise.reject();
      }

      return Api.userCompanies.fetchWalletP2POperations(company.id, request);
    },
    [company],
  );

  if (!company) {
    return null;
  }

  return (
    <>
      <P2POperationTable name={'CompanyWalletP2POperations'} onFetch={fetchData} />
    </>
  );
};

export default withCompany(TabWalletP2POperations);
