import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { setGlobalLoading } from 'src/modules/app/actions';
import { Cell, Column } from 'react-table';
import { truncate } from 'lodash';
import Api from 'src/api';
import { InquiryResponseDto } from 'src/types/api/landingPage/inquiry';
import Table from 'src/components/Table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';

const Inquiries: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<InquiryResponseDto>>();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<InquiryResponseDto>[] | any>(
    () => [
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<InquiryResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.company_name'),
        accessor: 'company_name',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.company_name}</span>;
        },
      },
      {
        Header: t('table.email'),
        accessor: 'email',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.email}</span>;
        },
      },
      {
        Header: t('table.phone'),
        accessor: 'phone_number',
        width: 200,
        disableSortBy: true,
      },
      {
        Header: t('table.required_amount'),
        accessor: 'required_amount',
        sortType: 'number',
        width: 150,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          const row = cell.row.original;
          return <div>{t('common.money', { value: row.required_amount })}</div>;
        },
      },
      {
        Header: t('table.real_estate_value'),
        accessor: 'real_estate_value',
        sortType: 'number',
        width: 200,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          const row = cell.row.original;
          return <div>{t('common.money', { value: row.real_estate_value })}</div>;
        },
      },
      {
        Header: t('table.credit_purpose'),
        accessor: 'credit_purpose',
        sortType: 'string',
        width: 200,
        disableSortBy: true,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          const row = cell.row.original;
          return <span>{truncate(row.credit_purpose, { length: 100 })}</span>;
        },
      },
      {
        Header: t('table.credit_duration'),
        accessor: 'credit_duration',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          const row = cell.row.original;
          return <div>{t('common.month', { value: row.credit_duration })}</div>;
        },
      },
      {
        Header: t('table.inquiry_status'),
        accessor: 'inquiry_status',
        sortType: 'string',
        width: 100,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          return t('inquiries.inquiry_status.' + cell.value);
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.inquiries.fetchFilteredInquiries(request).then((response) => setData(response));
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-3'}>{t('menu.landing_page.inquiries')}</h4>
            <HiddenColumnsProvider title={'InquiryIndex'}>
              <Table
                title={'InquiryIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Inquiries;
