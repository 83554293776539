import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

const AuthenticationLayout: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <div className={'p-4'}>
        <Container>
          <Row>
            <div>{children}</div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(AuthenticationLayout);
