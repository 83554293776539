import { Permissions } from 'src/helpers/auth/permissions';

export enum ReportTypeEnum {
  REGISTRATIONS = 'registrations',
  INVESTMENTS = 'investments',
  APPLICATIONS = 'applications',
  PROJECTS = 'projects',
  LATE_PROJECTS = 'late_projects',
  TRANSACTIONS = 'transactions',
  WALLETS = 'wallets',
  SECONDARY_MARKET_INVESTMENTS = 'secondary_market_investments',
  BONUSES = 'bonuses',
  BONUSES_AMOUNT = 'bonuses_amount',
  BONUSES_INTEREST = 'bonuses_interest',
  INVESTORS = 'investors',
  VMI313 = 'vmi313',
  VMI312 = 'vmi312',
  LB = 'lb',
  NEWSLETTER_SUBSCRIBERS = 'newsletter_subscribers',
  AFFILIATE_REGISTRATIONS = 'affiliate_registrations',
  AFFILIATE_INVESTMENT_TRANSACTIONS = 'affiliate_investment_transactions',
  INVESTMENT_PAYOUTS = 'investment_payouts',
  BORROWER_PAYOUTS = 'borrower_payouts',
  REFERRALS = 'referrals',
  PROJECT_DETAILED = 'project_detailed',
  PROJECT_PAYMENT_SCHEDULE = 'project_payment_schedule',
  INVESTORS_ACTIVE_PORTFOLIO_CHANGES = 'investors_active_portfolio_changes',
}

export const reportTypeFromString = (
  val: string | null | undefined,
): ReportTypeEnum | undefined => {
  if (val === null || val === undefined) return undefined;
  return Object.values(ReportTypeEnum).find((type) => type == val);
};

export function mapManagePermission(type: ReportTypeEnum) {
  const map = new Map<ReportTypeEnum, string>([
    [ReportTypeEnum.REGISTRATIONS, Permissions.BO__REPORTS__REGISTRATIONS__MANAGE],
    [ReportTypeEnum.INVESTMENTS, Permissions.BO__REPORTS__INVESTMENTS__MANAGE],
    [ReportTypeEnum.APPLICATIONS, Permissions.BO__REPORTS__APPLICATIONS__MANAGE],
    [ReportTypeEnum.PROJECTS, Permissions.BO__REPORTS__PROJECTS__MANAGE],
    [ReportTypeEnum.LATE_PROJECTS, Permissions.BO__REPORTS__LATE_PROJECTS__MANAGE],
    [ReportTypeEnum.TRANSACTIONS, Permissions.BO__REPORTS__TRANSACTIONS__MANAGE],
    [ReportTypeEnum.WALLETS, Permissions.BO__REPORTS__WALLETS__MANAGE],
    [
      ReportTypeEnum.SECONDARY_MARKET_INVESTMENTS,
      Permissions.BO__REPORTS__SECONDARY_MARKET_INVESTMENTS__MANAGE,
    ],
    [ReportTypeEnum.BONUSES, Permissions.BO__REPORTS__BONUSES__MANAGE],
    [ReportTypeEnum.BONUSES_AMOUNT, Permissions.BO__REPORTS__BONUSES_AMOUNT__MANAGE],
    [ReportTypeEnum.BONUSES_INTEREST, Permissions.BO__REPORTS__BONUSES_INTEREST__MANAGE],
    [ReportTypeEnum.INVESTORS, Permissions.BO__REPORTS__INVESTORS__MANAGE],
    [ReportTypeEnum.VMI313, Permissions.BO__REPORTS__VMI313__MANAGE],
    [ReportTypeEnum.VMI312, Permissions.BO__REPORTS__VMI312__MANAGE],
    [ReportTypeEnum.LB, Permissions.BO__REPORTS__LB__MANAGE],
    [
      ReportTypeEnum.NEWSLETTER_SUBSCRIBERS,
      Permissions.BO__REPORTS__NEWSLETTER_SUBSCRIBERS__MANAGE,
    ],
    [
      ReportTypeEnum.AFFILIATE_REGISTRATIONS,
      Permissions.BO__REPORTS__AFFILIATE_REGISTRATIONS__MANAGE,
    ],
    [
      ReportTypeEnum.AFFILIATE_INVESTMENT_TRANSACTIONS,
      Permissions.BO__REPORTS__AFFILIATE_INVESTMENT_TRANSACTIONS__MANAGE,
    ],
    [ReportTypeEnum.INVESTMENT_PAYOUTS, Permissions.BO__REPORTS__INVESTMENT_PAYOUTS__MANAGE],
    [ReportTypeEnum.BORROWER_PAYOUTS, Permissions.BO__REPORTS__BORROWER_PAYOUTS__MANAGE],
    [ReportTypeEnum.REFERRALS, Permissions.BO__REPORTS__REFERRALS__MANAGE],
    [ReportTypeEnum.PROJECT_DETAILED, Permissions.BO__REPORTS__DETAILED_PROJECTS__MANAGE],
    [
      ReportTypeEnum.PROJECT_PAYMENT_SCHEDULE,
      Permissions.BO__REPORTS__PROJECT_PAYMENT_SCHEDULE__MANAGE,
    ],
    [
      ReportTypeEnum.INVESTORS_ACTIVE_PORTFOLIO_CHANGES,
      Permissions.BO__REPORTS__INVESTORS_PORTFOLIO_ACTIVITY__MANAGE,
    ],
  ]);

  const permission = map.get(type);

  if (permission === undefined) throw new Error();

  return permission;
}
