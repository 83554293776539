import React, { useEffect, useState } from 'react';
import { LocaleFormikContext } from './LocaleFormik.context';
import { LocaleFormikContextObject, LocaleFormikStateObject } from './LocaleFormik.types';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import isFunction from 'lodash/isFunction';
import i18n from 'src/services/i18n';

interface Props {
  locale?: LanguageEnum;
  children?: React.ReactNode | null | ((wrapper: LocaleFormikContextObject) => void);
}

const LocaleFormikProvider: React.FC<Props> = ({ locale, children }) => {
  const [state, setState] = useState<LocaleFormikStateObject>({
    locale: locale ?? (i18n.resolvedLanguage as LanguageEnum),
  });

  useEffect(() => {
    if (locale) {
      setState((prev) => ({ ...prev, locale }));
    }
  }, [locale]);

  return (
    <LocaleFormikContext.Provider value={{ state, setState }}>
      {children ? (isFunction(children) ? children({ state, setState }) : children) : null}
    </LocaleFormikContext.Provider>
  );
};

export { LocaleFormikProvider };
