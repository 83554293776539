import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { success } from 'src/services/toastr';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { MediaRenameRequestDto } from 'src/types/api/common';
import TextInput from 'src/components/Form/TextInput';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';

interface Props {
  onAction: (name: string) => Promise<any>;
  name: string;
}

const PictureControlModal: React.FC<Props> = ({ onAction, name }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const EditPictureRequestSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const [renameRequest] = useState<MediaRenameRequestDto>({ name: name });

  const onSubmit = useCallback(
    (request: MediaRenameRequestDto, helper: FormikHelpers<MediaRenameRequestDto>) => {
      return onAction(request.name)
        .then(() => {
          success(t('common.updated_success'));
          hideModal();
        })
        .catch((e) => {
          helper.setErrors(e.response?.errors);
        });
    },
    [hideModal, onAction, t],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('projects.picture_control.title')}
          </ModalHeader>
          <Formik
            initialValues={renameRequest}
            validationSchema={EditPictureRequestSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <>
                <ModalBody className={''}>
                  <p className={'text-pre'}>{t('projects.picture_control.body')}</p>

                  <Form onSubmit={handleSubmit} id={'rename-form'}>
                    <FormGroup>
                      <div className="mb-3">
                        <TextInput value={values.name} name="name" />
                      </div>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <PrimaryButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <PrimaryButton
                      form={'rename-form'}
                      submitting={isSubmitting}
                      btype={'btn-danger'}
                      title={t('common.submit')}
                      type={'submit'}
                    />
                  </Col>
                </ModalFooter>
              </>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default PictureControlModal;
