import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { SelectInput } from 'src/components/Form/Select';
import { GenerateReportRequestDto, SecondaryMarketReportFilterForm } from 'src/types/api/reports';
import { appendDateFilter, appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import { SecondaryMarketInvestmentStatusEnum } from 'src/helpers/Enums/SecondaryMarketInvestmentStatusEnum';

const AVAILABLE_COLUMNS: string[] = [
  'id',
  'seller_pid',
  'seller_name',
  'seller_residence',
  'buyer_pid',
  'buyer_name',
  'buyer_residence',
  'amount',
  'tax',
  'receivable_amount_left',
  'project_pid',
  'status',
  'created_at',
  'sold_at',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.SECONDARY_MARKET_INVESTMENTS;

const SecondaryMarketReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<SecondaryMarketReportFilterForm>({
    name: null,
    columns: AVAILABLE_COLUMNS,
    created_at_from: null,
    created_at_to: null,
    status: null,
    project_pid: null,
  });

  const Schema = Yup.object().shape({
    created_at_from: Yup.date().nullable(),
    created_at_to: Yup.date().when(
      'created_at_from',
      (created_at_from: Date, schema: Yup.DateSchema) => {
        return created_at_from
          ? schema.min(created_at_from, t('label.created_at_from'))
          : schema.nullable();
      },
    ),
  });

  const mapData = (form: SecondaryMarketReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendDateFilter(request, 'created_at', form.created_at_from, form.created_at_to);
    appendFilter(request, 'status', form.status);
    appendFilter(request, 'project_pid', form.project_pid);

    return request;
  };

  const onSubmit = useCallback(
    async (
      request: SecondaryMarketReportFilterForm,
      helper: FormikHelpers<SecondaryMarketReportFilterForm>,
    ) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  const [secondaryMarketStatusOptions] = useState<ReactSelectOption[]>([
    {
      value: '',
      label: t('common.all'),
    },
    ...Object.values(SecondaryMarketInvestmentStatusEnum).map((type) => ({
      value: type,
      label: t('reports.secondary_market_status.' + type),
    })),
  ]);

  if (!p.hasAll([mapManagePermission(TYPE)])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={Schema}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={3} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'created_at_from'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'created_at_to'} showTimeSelect />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput name={'status'} options={secondaryMarketStatusOptions} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'project_pid'} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default SecondaryMarketReportFilter;
