import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import RoleMatrix from './Matrix';
import RoleList from './List';
import RoleUpdate from './Update';
import RoleCreate from './Create';

const RolesRoutes: any = () => [
  <AuthRoute
    path={RouteList.ROLES.MATRIX}
    component={RoleMatrix}
    layout={PageLayout}
    permissions={[Permissions.BO__ROLES__FILTER]}
    key={RouteList.ROLES.MATRIX}
    exact
  />,
  <AuthRoute
    path={RouteList.ROLES.CREATE}
    component={RoleCreate}
    layout={PageLayout}
    permissions={[Permissions.BO__ROLES__CREATE]}
    key={RouteList.ROLES.CREATE}
    exact
  />,
  <AuthRoute
    path={RouteList.ROLES.UPDATE}
    component={RoleUpdate}
    layout={PageLayout}
    permissions={[Permissions.BO__ROLES__UPDATE]}
    key={RouteList.ROLES.UPDATE}
    exact
  />,
  <AuthRoute
    path={RouteList.ROLES.LIST}
    component={RoleList}
    layout={PageLayout}
    permissions={[Permissions.BO__ROLES__FILTER]}
    key={RouteList.ROLES.LIST}
    exact
  />,
];

export default RolesRoutes;
