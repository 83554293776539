import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { success } from 'src/services/toastr';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { SelectAsyncInput } from 'src/components/Form/Select';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { StoreCommitteeMembersRequestDto } from 'src/types/api/applications';
import Api from 'src/api';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import { UserBriefResponseDto } from 'src/types/api/user';
import { CommitteeMemberType } from 'src/helpers/Enums/CommitteeMemberType';
import { PaginationDataFilter } from 'src/types';

const AssignCommitteeMemberModal: React.FC = () => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const [chiefCommitteeMembers, setChiefCommitteeMembers] = useState<UserBriefResponseDto[]>([]);
  const [assignedCommitteeMembersRequest, setAssignedCommitteeMembersRequest] =
    useState<StoreCommitteeMembersRequestDto>({
      committee_member_ids: undefined,
      chief_committee_member: '',
    });

  useEffect(() => {
    Api.information.fetchCommitteeMembers().then((response) => {
      setAssignedCommitteeMembersRequest({
        committee_member_ids: response.map((user) => user.id),
        chief_committee_member:
          response.find((member) => member.type == CommitteeMemberType.CHIEF_COMMITTEE_MEMBER)
            ?.id ?? '',
      });
    });
  }, []);

  const fetchCommitteeMembers = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response = await Api.user.fetchFilterableCommitteeMembers(request);
    const users: UserBriefResponseDto[] = [...response.data];
    const members: ReactSelectOption[] = response.data.map((user) => {
      return {
        value: user.id ?? '',
        label: user.name ?? '',
      };
    });

    const newChiefCommitteeMembers: UserBriefResponseDto[] = [];
    users.forEach((u) => {
      if (!chiefCommitteeMembers.includes(u)) {
        newChiefCommitteeMembers.push(u);
      }
    });

    setChiefCommitteeMembers((prevState) => [...prevState, ...newChiefCommitteeMembers]);
    return members;
  };

  const onSubmit = useCallback(
    async (
      request: StoreCommitteeMembersRequestDto,
      helper: FormikHelpers<StoreCommitteeMembersRequestDto>,
    ) => {
      try {
        await Api.applications.storeCommitteeMembers(request);
        success(t('common.updated_success'));
        hideModal();
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [hideModal, t],
  );

  const getChiefCommitteeMemberLabel = (id: string): string => {
    return (
      chiefCommitteeMembers.find((member: UserBriefResponseDto) => member.id == id)?.name ?? id
    );
  };

  return (
    <React.Fragment>
      <ModalComponent
        size={'md'}
        skeletonHeight={350}
        isLoading={assignedCommitteeMembersRequest.committee_member_ids === undefined}
      >
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('applications.committee_members.title')}
          </ModalHeader>
          <Formik
            enableReinitialize={true}
            initialValues={assignedCommitteeMembersRequest}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <div className={'mb-4'}>
                    <SelectAsyncInput
                      name={'committee_member_ids'}
                      isMulti={true}
                      isClearable={true}
                      loadOptions={fetchCommitteeMembers}
                    />
                  </div>
                  <div className={'mb-4'}>
                    <SelectInput
                      name={'chief_committee_member'}
                      options={(values.committee_member_ids ?? []).map((el: string) => ({
                        label: getChiefCommitteeMemberLabel(el),
                        value: el,
                      }))}
                      isClearable={true}
                      className={'mt-2'}
                    />
                  </div>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default AssignCommitteeMemberModal;
