import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  PermissionListResponseDto,
  RoleListResponseDto,
  RoleMatrixListResponseDto,
  RoleResponseDto,
  SaveRoleRequestDto,
  UpdateRolesPermissionsRequestDto,
} from 'src/types/api/roles';

class Roles extends Client {
  api = {
    FETCH_ROLES: `${this.http.baseUrl}/roles`,
    CREATE_ROLE: `${this.http.baseUrl}/roles/role`,
    FETCH_ROLE: `${this.http.baseUrl}/roles/role/{roleId}`,
    UPDATE_ROLE: `${this.http.baseUrl}/roles/role/{roleId}`,
    DELETE_ROLE: `${this.http.baseUrl}/roles/role/{roleId}`,
    FETCH_FILTERED_ROLES: `${this.http.baseUrl}/roles/filter`,
    FETCH_PERMISSIONS: `${this.http.baseUrl}/roles/permissions`,
    UPDATE_ROLES_PERMISSIONS: `${this.http.baseUrl}/roles`,
  };

  fetchRoles = (): Promise<RoleMatrixListResponseDto[]> => {
    return this.http.get(this.api.FETCH_ROLES);
  };

  fetchRole = (roleId: number | string): Promise<RoleResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_ROLE, { roleId });

    return this.http.get(url);
  };

  fetchFilteredRoles = (
    filter?: PaginationDataFilter,
  ): Promise<PaginationData<RoleListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_ROLES, { params: filter });
  };

  updateRolesPermissions = (request: UpdateRolesPermissionsRequestDto): Promise<any> => {
    return this.http.put(this.api.UPDATE_ROLES_PERMISSIONS, request);
  };

  createRole = (request: SaveRoleRequestDto): Promise<RoleResponseDto> => {
    return this.http.post(this.api.CREATE_ROLE, request);
  };

  updateRole = (roleId: number | string, request: SaveRoleRequestDto): Promise<RoleResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_ROLE, { roleId });

    return this.http.put(url, request);
  };

  deleteRole = (roleId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_ROLE, { roleId });

    return this.http.delete(url);
  };

  fetchAllPermissions = (): Promise<PermissionListResponseDto[]> => {
    return this.http.get(this.api.FETCH_PERMISSIONS);
  };
}

export default Roles;
