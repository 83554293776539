import React, { useCallback, useMemo, useState } from 'react';
import Table from 'src/components/Table';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { WalletP2POperationsResponseDto } from 'src/types/api/operations';
import WalletOperationStatusBadge from 'src/components/Badges/WalletOperationStatusBadge';
import { WalletOwnerField } from '../Table/Fields/WalletOwnerField';
import { TableInitialSortBy } from 'src/types/api/common';

interface Props {
  name: string;
  onFetch: (
    request: PaginationDataFilter | undefined,
  ) => Promise<PaginationData<WalletP2POperationsResponseDto>>;
}

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'executed_at',
    desc: true,
  },
];

const P2POperationTable: React.FC<Props> = ({ name, onFetch }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<PaginationData<WalletP2POperationsResponseDto>>();

  const columns = useMemo<Column<WalletP2POperationsResponseDto>[] | any>(
    () => [
      {
        Header: t('label.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<WalletP2POperationsResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('label.status'),
        accessor: 'status',
        width: 100,
        Cell: (cell: Cell<WalletP2POperationsResponseDto>) => {
          const value = cell.row.original.status;

          return <WalletOperationStatusBadge value={value} />;
        },
      },
      {
        Header: t('label.type'),
        accessor: 'type',
        width: 100,
        Cell: (cell: Cell<WalletP2POperationsResponseDto>) => {
          const value = cell.row.original.type;

          return t(`wallet.types.${value}`);
        },
      },
      {
        Header: t('label.receiver'),
        accessor: 'receiver',
        Cell: (cell: Cell<WalletP2POperationsResponseDto>) => {
          const row = cell.row.original;

          return <WalletOwnerField model={row.receiver} provider={row.receiver_provider} />;
        },
      },
      {
        Header: t('label.sender'),
        accessor: 'sender',
        Cell: (cell: Cell<WalletP2POperationsResponseDto>) => {
          const row = cell.row.original;

          return <WalletOwnerField model={row.sender} provider={row.sender_provider} />;
        },
      },
      {
        Header: t('label.executed_at'),
        accessor: 'executed_at',
        width: 100,
        Cell: (cell: Cell<WalletP2POperationsResponseDto>) => {
          const value = cell.row.original.executed_at;

          return <div>{t('common.date_full', { date: value })}</div>;
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(
    (request: PaginationDataFilter | undefined) => {
      return onFetch(request).then((response) => setResponse(response));
    },
    [onFetch],
  );

  return (
    <HiddenColumnsProvider title={name}>
      <Table
        title={name}
        onFetchData={fetchData}
        columns={columns}
        data={response}
        searchable={true}
        enableQueryFilter={true}
        initialSortBy={INITIAL_SORT_ORDER}
      />
    </HiddenColumnsProvider>
  );
};

export default P2POperationTable;
