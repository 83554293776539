import React, { useCallback, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import FileUpload, {
  FileUploadHandle,
  IMAGE_TYPES,
} from 'src/components/Form/FileUpload/FileUpload';
import Api from 'src/api';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik/dist/types';
import { AttachFilesRequestDto } from 'src/types/api/common';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import PictureList from 'src/components/PictureList/PictureList';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';
import { transformErrors } from 'src/helpers';

interface Props extends ApplicationTabProps, ApplicationContextState {}

const TabPictures: React.FC<Props> = ({ application, setApplication, showSubmit }) => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);

  const [initialFormValues] = useState<AttachFilesRequestDto>({
    media_ids: [],
  });

  const onSubmit = useCallback(
    async (request: AttachFilesRequestDto, helpers: FormikHelpers<any>) => {
      if (!application) return;

      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        const response = await Api.applications.attachPictures(application.id, request);
        setApplication(response);
        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [application, setApplication],
  );

  if (!application) {
    return <></>;
  }

  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.pictures.list')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton title={t('applications.tabs_submit.pictures')} tabName={'pictures'} />
          </div>
        )}
      </div>
      <hr />
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ submitForm, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FileUpload
              accept={IMAGE_TYPES}
              ref={fileUploadRef}
              name={'media_ids'}
              onPresign={(request) => Api.applications.uploadPictures(application.id, request)}
              onDropAccepted={submitForm}
            />
          </Form>
        )}
      </Formik>
      <div className="row">
        <PictureList
          pictures={application.pictures}
          onOrderUpdate={(request) =>
            Api.applications.updateOrder(application.id, request).then(setApplication)
          }
          onPictureDelete={(mediaId) =>
            Api.applications.detachPictures(application.id, mediaId).then(setApplication)
          }
          onPictureRename={(name, mediaId) =>
            Api.applications.renameMedia(application.id, mediaId, { name }).then(setApplication)
          }
        />
      </div>
    </React.Fragment>
  );
};

export default withApplication(TabPictures);
