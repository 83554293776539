import { createContext, useReducer } from 'react';
import { FileState, FileUploadReducer, initialState } from './FileUploadReducer';

interface IFileUploadContext {
  state: FileState;
  dispatch: React.Dispatch<any>;
}

const FileUploadContext = createContext<IFileUploadContext>({
  state: initialState,
  dispatch: () => {
    return;
  },
});

export const FileUploadContextProvider: React.FC = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(FileUploadReducer, initialState);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <FileUploadContext.Provider value={{ state, dispatch }}>{children}</FileUploadContext.Provider>
  );
};

export default FileUploadContext;
