import React, { useCallback } from 'react';
import SaveButton from 'src/components/Form/SaveButton';
import { success } from 'src/services/toastr';
import Api from 'src/api';
import { useTranslation } from 'react-i18next';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';

interface Props extends ApplicationContextState {
  title: string;
  tabName: string;
}

const SubmitTabButton: React.FC<Props> = ({ title, tabName, application, setApplication }) => {
  const { t } = useTranslation();

  const onSubmit = useCallback(() => {
    if (application) {
      Api.applications
        .submitApplicationTab(application.id, { tab_name: tabName })
        .then((response) => setApplication(response))
        .then(() => success(t('common.submit_success')));
    }
  }, [application, tabName, setApplication, t]);

  return <SaveButton title={title} onClick={onSubmit} />;
};

export default withApplication(SubmitTabButton);
