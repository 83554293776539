import React from 'react';

interface Props {
  stage: string;
  active: boolean;
  index: string;
}

const ApplicationTimeline: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <div className={`timeline-step${props.active ? ' active' : ''}`}>
        <div className="timeline-content">
          <span className="inner-circle">{props.index}</span>
          <p className="h6 mt-3 mb-1">
            {props.active ? <strong>{props.stage}</strong> : props.stage}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ApplicationTimeline;
