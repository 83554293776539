import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Form/TextInput';
import { ArrayHelpers } from 'formik/dist/FieldArray';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SelectRealEstateTypeInput from 'src/components/Form/Select/SelectRealEstateTypeInput';
import { UseNumbers } from 'src/helpers/useNumbers';

const SingleSecurityDeposit: React.FC<{
  namePrefix: string;
  index: number;
  formikArrayHelpers: ArrayHelpers;
}> = ({ namePrefix }) => {
  const { t } = useTranslation();

  const realEstateType = `${namePrefix}.real_estate_type`;
  const pid = `${namePrefix}.pid`;
  const realEstateUnique = `${namePrefix}.real_estate_unique`;
  const realEstatePlot = `${namePrefix}.real_estate_plot`;
  const realEstateAddress = `${namePrefix}.real_estate_address`;
  const realEstateValue = `${namePrefix}.real_estate_value`;
  const realEstateAppraiser = `${namePrefix}.real_estate_appraiser`;
  const realEstateAppraiserAt = `${namePrefix}.real_estate_appraised_at`;

  return (
    <>
      <tr>
        <td>
          <SelectRealEstateTypeInput
            isDisabled={true}
            placeholder={t('label.real_estate_type')}
            name={realEstateType}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput name={pid} disabled={true} placeholder={t('label.pid')} hideLabel={true} />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateUnique}
            placeholder={t('label.real_estate_unique')}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstatePlot}
            placeholder={t('label.real_estate_plot')}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateAddress}
            placeholder={t('label.real_estate_address')}
            hideLabel={true}
          />
        </td>
        <td>
          <TextInput
            type={'number'}
            step={'any'}
            name={realEstateValue}
            placeholder={t('label.real_estate_value')}
            hideLabel={true}
            onKeyPress={UseNumbers.preventNonNumericalInput}
          />
        </td>
        <td>
          <TextInput
            type={'text'}
            name={realEstateAppraiser}
            disabled={true}
            placeholder={t('label.real_estate_appraiser')}
            hideLabel={true}
          />
        </td>
        <td className={'align-top'}>
          <DatePickerInput
            disabled={true}
            name={realEstateAppraiserAt}
            placeholder={t('label.real_estate_appraised_at')}
            hideLabel={true}
          />
        </td>
      </tr>
    </>
  );
};

export default SingleSecurityDeposit;
