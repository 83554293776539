import { statistics } from 'src/api/queries';
import Api from 'src/api/index';
import { defaultOptions, UseQueryOptionsObject } from 'src/api/queries/queries.utils';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';
import {
  DashboardPageWalletInformationDto,
  DashboardWalletInfoWalletInformationDto,
} from 'src/types/api/dashboard';

const localDefaultOptions: typeof defaultOptions = {
  ...defaultOptions,
  retry: false,
  retryOnMount: false,
};

const useWalletInformationStatisticQuery = (
  options?: UseQueryOptionsObject<DashboardPageWalletInformationDto>,
) => {
  return useMakeQuery<DashboardPageWalletInformationDto>(
    [statistics.walletInformation],
    () => Api.dashboard.fetchWalletInformation(),
    {
      staleTime: 60 * 1000,
      ...localDefaultOptions,
      ...(options ?? {}),
    },
  );
};

const useRichestWalletsQuery = (
  options?: UseQueryOptionsObject<DashboardWalletInfoWalletInformationDto[]>,
) => {
  return useMakeQuery<DashboardWalletInfoWalletInformationDto[]>(
    [statistics.richestWallets],
    () => Api.dashboard.fetchRichestWallets(),
    {
      staleTime: 60 * 1000,
      ...localDefaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useWalletInformationStatisticQuery, useRichestWalletsQuery };
