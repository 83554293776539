import React, { useEffect } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import Api from 'src/api';
import { useTranslation } from 'react-i18next';
import MoneyOperationTable from 'src/components/Operations/MoneyOperationTable';

const WalletMoneyOperationList: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-4'}>{t('menu.wallet_money_operations')}</h4>
            <MoneyOperationTable
              name={'WalletMoneyOperations'}
              onFetch={Api.operations.fetchFilteredMoneyOperations}
            />
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default WalletMoneyOperationList;
