import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Container, Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import TextInput from 'src/components/Form/TextInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { setGlobalLoading } from 'src/modules/app/actions';
import { ResetPasswordRequestDto } from 'src/types/api/authentication';

const INITIAL_FORM: ResetPasswordRequestDto = {
  email: '',
};

const ConfirmResetPassword: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const [resetPasswordRequest] = useState<ResetPasswordRequestDto>(INITIAL_FORM);

  const onSubmit = useCallback(
    async (request: ResetPasswordRequestDto, helpers: FormikHelpers<ResetPasswordRequestDto>) => {
      try {
        await Api.authentication.resetPassword(request);

        success(t('auth.reset_password_success'));

        helpers.resetForm();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [t],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{t('auth.reset_password')}</h5>
                  </div>
                  <div className="p-2 mt-4">
                    <Formik
                      initialValues={resetPasswordRequest}
                      validationSchema={ResetPasswordSchema}
                      enableReinitialize={true}
                      onSubmit={onSubmit}
                    >
                      {({
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormGroup>
                            <div className="mb-3">
                              <TextInput name="email" type={'email'} />
                            </div>
                            <div className={'mt-4 mb-4'}>
                              <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
                            </div>
                          </FormGroup>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ConfirmResetPassword);
