import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import PaymentList from 'src/pages/Payment/ProjectPayments/List';
import Payment from 'src/pages/Payment/ProjectPayments/Update/Payment';
import BorrowerPayoutList from 'src/pages/Payment/BorrowerPayouts/List';
import BorrowerPayout from 'src/pages/Payment/BorrowerPayouts/Update/BorrowerPayout';
import InvoiceList from 'src/pages/Payment/Invoices/List';
import InvoiceCreate from 'src/pages/Payment/Invoices/Create';
import ManualWithdrawalList from 'src/pages/Payment/ManualWithdrawals/List';
import CreatePartialReturn from 'src/pages/Payment/BorrowerPayouts/CreatePartialReturn';
import FailedInvestmentPayoutsList from 'src/pages/Payment/FailedInvestmentPayouts/List';
import MigratedAccountsFailedInvestmentPayoutsList from './FailedInvestmentPayouts/MigratedAccountsList/List';
import EstimatePayout from 'src/pages/Payment/Estimates/EstimatePayout';

const BorrowerPayoutRoutes = Object.values(RouteList.PAYMENTS.BORROWER_PAYOUTS.LIST).map(
  (route) => (
    <AuthRoute
      path={route}
      component={BorrowerPayoutList}
      layout={PageLayout}
      permissions={[Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__LIST]}
      redirect={RouteList.AUTHENTICATION.LOGIN}
      key={route}
      exact
    />
  ),
);

const PaymentRoutes: any = () => [
  <AuthRoute
    path={RouteList.PAYMENTS.PROJECT_PAYOUTS.LIST}
    component={PaymentList}
    layout={PageLayout}
    permissions={[Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PAYMENTS.PROJECT_PAYOUTS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.PAYMENTS.PROJECT_PAYOUTS.VIEW}
    component={Payment}
    layout={PageLayout}
    permissions={[Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PAYMENTS.PROJECT_PAYOUTS.VIEW}
    exact
  />,
  <AuthRoute
    path={RouteList.PAYMENTS.BORROWER_PAYOUTS.VIEW}
    component={BorrowerPayout}
    layout={PageLayout}
    permissions={[Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PAYMENTS.BORROWER_PAYOUTS.VIEW}
    exact
  />,
  <AuthRoute
    path={RouteList.PAYMENTS.BORROWER_PAYOUTS.CREATE_PARTIAL_RETURN}
    component={CreatePartialReturn}
    layout={PageLayout}
    permissions={[Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__CREATE_PARTIAL_RETURN]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PAYMENTS.BORROWER_PAYOUTS.CREATE_PARTIAL_RETURN}
    exact
  />,
  <AuthRoute
    path={RouteList.PAYMENTS.BORROWER_PAYOUTS.ESTIMATES}
    component={EstimatePayout}
    layout={PageLayout}
    permissions={[Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__ESTIMATE__PAYOUT]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PAYMENTS.BORROWER_PAYOUTS.ESTIMATES}
    exact
  />,
  <AuthRoute
    path={RouteList.PAYMENTS.INVOICES.LIST}
    component={InvoiceList}
    layout={PageLayout}
    permissions={[Permissions.BO__PAYMENTS__INVOICES__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PAYMENTS.INVOICES.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.PAYMENTS.INVOICES.CREATE}
    component={InvoiceCreate}
    layout={PageLayout}
    permissions={[Permissions.BO__PAYMENTS__INVOICES__STORE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PAYMENTS.INVOICES.CREATE}
    exact
  />,
  <AuthRoute
    path={RouteList.PAYMENTS.MANUAL_WITHDRAWALS.LIST}
    component={ManualWithdrawalList}
    layout={PageLayout}
    permissions={[Permissions.BO__PAYMENTS__MANUAL_WITHDRAWALS__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PAYMENTS.MANUAL_WITHDRAWALS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.PAYMENTS.FAILED_INVESTMENT_PAYOUTS.LIST}
    component={FailedInvestmentPayoutsList}
    layout={PageLayout}
    permissions={[Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PAYMENTS.FAILED_INVESTMENT_PAYOUTS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.PAYMENTS.FAILED_INVESTMENT_PAYOUTS.MIGRATED_ACCOUNTS.LIST}
    component={MigratedAccountsFailedInvestmentPayoutsList}
    layout={PageLayout}
    permissions={[Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PAYMENTS.FAILED_INVESTMENT_PAYOUTS.MIGRATED_ACCOUNTS.LIST}
    exact
  />,
  ...BorrowerPayoutRoutes,
];

export default PaymentRoutes;
