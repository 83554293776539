import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { BorrowerPayoutTypeEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutTypeEnum';

interface Props {
  value: BorrowerPayoutTypeEnum;
}

const BorrowerPayoutType: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const [color, setColor] = useState<string>('#FF00FF');

  useEffect(() => {
    switch (value) {
      case BorrowerPayoutTypeEnum.INTEREST:
        setColor('#81cf74');
        break;
      case BorrowerPayoutTypeEnum.PARTIAL_RETURN:
        setColor('#da90b7');
        break;
      case BorrowerPayoutTypeEnum.FINAL_RETURN:
        setColor('#3d514f');
        break;
      case BorrowerPayoutTypeEnum.DEBT:
        setColor('#1a42d6');
        break;
      default:
        setColor('#8a3196');
    }
  }, [value]);

  return (
    <Badge style={{ backgroundColor: color }} color={''}>
      {t('payments.borrower_payouts.type.' + value)}
    </Badge>
  );
};

export default BorrowerPayoutType;
