import React, { ButtonHTMLAttributes } from 'react';

interface Props
  extends React.DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  title?: string;
  submitting?: any;
  btype?: string;
}

const PrimaryButton: React.FC<Props> = (props) => {
  const propsInput: any = {};

  Object.keys(props).forEach(function (key) {
    if (key === 'submitting') return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    propsInput[key] = props[key];
  });

  return (
    <button
      className={`btn w-100 ${props.btype ?? 'btn-primary'}`}
      type="submit"
      disabled={props.submitting}
      {...propsInput}
    >
      {props.submitting ? (
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>{props.title}</>
      )}
    </button>
  );
};

export default PrimaryButton;
