import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Container, Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import TextInput from 'src/components/Form/TextInput';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import Api from 'src/api';
import { toast } from 'react-toastify';
import { RouteList } from 'src/routes';
import { setGlobalLoading } from 'src/modules/app/actions';
import { ConfirmPasswordResetRequestDto } from 'src/types/api/authentication';

const INITIAL_FORM: ConfirmPasswordResetRequestDto = {
  password: '',
  password_confirmation: '',
  token: '',
};

const ConfirmResetPassword: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();

  const ConfirmPasswordSchema = Yup.object().shape({
    password: Yup.string().required(),
    password_confirmation: Yup.string()
      .nullable()
      .oneOf([Yup.ref('password'), null], t('validation.passwords-match')),
  });

  const [confirmPasswordRequest] = useState<ConfirmPasswordResetRequestDto>({
    ...INITIAL_FORM,
    token: token,
  });

  const onSubmit = useCallback(
    async (
      request: ConfirmPasswordResetRequestDto,
      helpers: FormikHelpers<ConfirmPasswordResetRequestDto>,
    ) => {
      try {
        await Api.authentication.confirmPassword(request);
        toast(t('auth.confirmed_success'), { type: 'success' });

        history.push(RouteList.HOME);
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [history, t],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{t('auth.reset_password')}</h5>
                  </div>
                  <div className="p-2 mt-4">
                    <Formik
                      initialValues={confirmPasswordRequest}
                      validateOnBlur={false}
                      validateOnChange={true}
                      isInitialValid={true}
                      validationSchema={ConfirmPasswordSchema}
                      onSubmit={onSubmit}
                    >
                      {({
                        values,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormGroup>
                            <div className="mb-3">
                              <TextInput value={values.password} name="password" type="password" />
                            </div>
                            <div className="mb-3">
                              <TextInput
                                value={values.password_confirmation}
                                name="password_confirmation"
                                type="password"
                              />
                            </div>
                            <div className={'mt-4 mb-4'}>
                              <PrimaryButton title={t('common.submit')} submitting={isSubmitting} />
                            </div>
                          </FormGroup>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ConfirmResetPassword);
