import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik } from 'formik';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import { success } from 'src/services/toastr';
import SaveButton from 'src/components/Form/SaveButton';
import DenyButton from 'src/components/Form/DenyButton';
import { BorrowerPayoutResponseDto } from 'src/types/api/payments/borrowerPayouts';
import { multipleArgSum } from 'src/helpers/utilts';

interface Props {
  borrowerPayout: BorrowerPayoutResponseDto;
  onAction: (request: any) => Promise<any>;
  onException?: () => void;
}

const ConfirmBorrowerPayoutModal: React.FC<Props> = ({ borrowerPayout, onAction, onException }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const onSubmit = useCallback(
    async (request) => {
      if (borrowerPayout) {
        return onAction(request)
          .then(() => {
            success(t('payments.borrower_payouts.confirm.success'));
            hideModal();
          })
          .catch(() => {
            onException && onException();
          });
      }
    },
    [borrowerPayout, onAction, t, hideModal, onException],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('payments.borrower_payouts.confirm.title')}
          </ModalHeader>
          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <span>
                    {t(
                      'payments.borrower_payouts.confirm.diff_loan_repayment_and_investment_loan_repayment',
                    )}
                    <h3 className="text-danger">
                      {t('common.money', {
                        value: Math.abs(
                          (borrowerPayout.loan_repayment ?? 0) -
                            (borrowerPayout.investment_payout_loan_repayment ?? 0),
                        ),
                      })}
                    </h3>
                  </span>
                  <span>
                    {t(
                      'payments.borrower_payouts.confirm.diff_interest_payout_total_and_borrower_interest',
                    )}
                    <h3 className="text-danger">
                      {t('common.money', {
                        value: Math.abs(
                          (borrowerPayout.borrower_payout_interest_total.sum_round ?? 0) -
                            multipleArgSum(
                              borrowerPayout?.investment_payouts_sum_borrower_total_interest_no_gpm,
                              borrowerPayout?.investment_payouts_sum_borrower_total_interest_only_gpm,
                            ),
                        ),
                      })}
                    </h3>
                  </span>
                  <hr />
                  <p>{t('payments.borrower_payouts.confirm.body')}</p>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      title={t('payments.borrower_payouts.confirm')}
                      submitting={isSubmitting}
                    />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ConfirmBorrowerPayoutModal;
