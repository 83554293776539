import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormikField from './FormikField';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  hideLabel?: boolean;
}

const TextInput: React.FC<Props> = ({ hideLabel, ...props }) => {
  const { t } = useTranslation();
  const [field] = useField(props.name);

  if (!props.placeholder) {
    props.placeholder = t('label.' + props.name);
  }

  return (
    <FormikField name={props.name} wrapperClass={'form-label-group'}>
      {!hideLabel && <label htmlFor={props.name}>{props.placeholder}</label>}
      <input
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value ?? ''}
        id={props.name}
        placeholder={props.placeholder}
        className={`form-control`}
        {...props}
      />
    </FormikField>
  );
};
export default TextInput;

TextInput.defaultProps = {
  hideLabel: false,
};
