import React, { createContext, useContext } from 'react';
import { PaymentResponseDto } from 'src/types/api/payments/projectPayouts';

export interface PaymentContextState {
  payment: PaymentResponseDto | undefined;
  setPayment: (payment: PaymentResponseDto) => void;
}

const PaymentContext = createContext<PaymentContextState>({
  payment: undefined,
  setPayment: () => {
    return;
  },
});

export const PaymentProvider: React.FC<PaymentContextState> = ({
  payment,
  setPayment,
  children,
}) => {
  return (
    <PaymentContext.Provider value={{ payment, setPayment }}>{children}</PaymentContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export function withPayment<P extends PaymentContextState = PaymentContextState>(
  Component: React.ComponentType<P>,
) {
  const WithPayment = ({ ...props }: Optionalize<P, PaymentContextState>) => {
    const c = useContext(PaymentContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithPayment.displayName = `withPayment${Component.displayName}`;

  return WithPayment;
}

export default PaymentProvider;
