import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { Table } from 'reactstrap';

import Api from 'src/api';
import { RouteList } from 'src/routes';
import { Permissions } from 'src/helpers/auth/permissions';
import UpdateBorrowerPayoutPaymentDate from 'src/pages/Payment/ProjectPayments/Update/Tabs/BorrowerPayouts/UpdateBorrowerPayoutPaymentDate';
import {
  PaymentContextState,
  withPayment,
} from 'src/pages/Payment/ProjectPayments/Update/PaymentContext';
import BorrowerPayoutType from 'src/components/Projects/BorrowerPayoutType';
import BorrowerPayoutStatus from 'src/components/Projects/BorrowerPayoutStatus';
import ConfirmButton from 'src/components/ConfirmButton';
import { success } from 'src/services/toastr';
import ViewEntityButton from 'src/components/Table/Buttons/ViewEntityButton';
import PermissionAction from 'src/components/PermissionAction';

const TabPayments: React.FC<PaymentContextState> = ({ payment, setPayment }) => {
  const { t } = useTranslation();

  const confirmDates = useCallback(() => {
    if (payment) {
      Api.payments.projectPayouts.confirmBorrowerPayoutDates(payment.id).then((response) => {
        setPayment(response);
        success(t('payments.borrower_payouts.confirm_dates.success'));
      });
    }
  }, [payment, setPayment, t]);

  // TODO remove after analytics tested
  const recalculatePayouts = useCallback(async () => {
    if (payment) {
      await Api.payments.projectPayouts.recalculatePayouts(payment.id).then((response) => {
        setPayment(response);
        success('Recalculated');
      });
    }
  }, [payment, setPayment]);

  const regeneratePayoutDocuments = useCallback(async () => {
    if (payment) {
      await Api.payments.projectPayouts.regeneratePayoutDocuments(payment.id).then(() => {
        success(t('payments.borrower_payouts.regenerate_scheduled_payout_documents.success'));
      });
    }
  }, [payment, t]);

  return (
    <React.Fragment>
      <h3>{t('payments.borrower_payouts')}</h3>
      <hr />
      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>{t('table.payment_date')}</th>
            <th>{t('table.type')}</th>
            <th>{t('table.status')}</th>
            <th>{t('table.paid_at')}</th>
            <th>{t('table.action')}</th>
          </tr>
        </thead>
        <tbody>
          {payment?.borrower_payouts.map((payout, key) => (
            <tr key={payout.id}>
              <th scope="row">{key + 1}</th>
              <td>
                <UpdateBorrowerPayoutPaymentDate payout={payout} />
              </td>
              <td>
                <BorrowerPayoutType value={payout.type} />
              </td>
              <td>
                <BorrowerPayoutStatus value={payout.status} />
              </td>
              <td>{payout.paid_at ? t('common.date', { date: payout.paid_at }) : '-'}</td>
              <td>
                <div className={'actions d-flex gap-2'}>
                  <ViewEntityButton
                    permissions={Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__VIEW}
                    route={{
                      pathname: generatePath(RouteList.PAYMENTS.BORROWER_PAYOUTS.VIEW, {
                        borrowerPayoutId: payout.id,
                        status: payout.status,
                      }),
                      state: { prevPath: location.pathname },
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td />
            <td>
              <div className={'gap-2 d-flex'}>
                <ConfirmButton
                  disabled={payment?.project_loan?.borrower_payout_dates_confirmed}
                  onAction={async () => confirmDates()}
                  body={t('payments.borrower_payouts.confirm_dates.body')}
                  actionText={t('payments.borrower_payouts.confirm_dates')}
                  title={t('payments.borrower_payouts.confirm_dates')}
                />
                <PermissionAction
                  permissions={Permissions.BO__PAYMENTS__BORROWER_PAYOUTS__CONFIRM_BORROWER_PAYOUT}
                >
                  <ConfirmButton
                    className={'btn btn-warning'}
                    onAction={async () => recalculatePayouts()}
                    body={'Do you REALLY want to recalculate payouts?'}
                    actionText={'Recalculate'}
                    title={'Investment and Borrower payout recalculation'}
                  />
                </PermissionAction>
                <PermissionAction
                  permissions={
                    Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__REGENERATE_PAYMENT_SCHEDULE_DOCS
                  }
                >
                  <ConfirmButton
                    className={'btn btn-danger'}
                    onAction={async () => regeneratePayoutDocuments()}
                    body={t('payments.borrower_payouts.regenerate_scheduled_payout_documents.body')}
                    actionText={t('common.regenerate')}
                    title={t('payments.borrower_payouts.regenerate_scheduled_payout_documents')}
                  />
                </PermissionAction>
              </div>
            </td>
            <td colSpan={3} />
          </tr>
        </tfoot>
      </Table>
    </React.Fragment>
  );
};

export default withPayment(TabPayments);
