import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openMedia } from 'src/services/media';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  url: string;
}

const DownloadButton: React.FC<Props> = ({ url, className, type, ...props }) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [generatedUrl, setGeneratedUrl] = useState<string>();

  return (
    <>
      {generatedUrl && (
        <a
          target={'_blank'}
          download
          className={`btn btn-primary ${className ?? ''}`}
          href={generatedUrl}
          rel="noreferrer"
        >
          {t('common.download')}
        </a>
      )}
      {!generatedUrl && (
        <button
          className={`btn btn-primary ${className ?? ''}`}
          type={type ?? 'button'}
          disabled={disabled}
          onClick={() => {
            setDisabled(true);

            openMedia(url)
              .then((r) => setGeneratedUrl(r.url))
              .finally(() => {
                setDisabled(false);
              });
          }}
          {...props}
        >
          {t('common.download')}
        </button>
      )}
    </>
  );
};

export default DownloadButton;
