import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';
import Table from 'src/components/Table';
import PermissionAction from 'src/components/PermissionAction';
import { generatePath, Link } from 'react-router-dom';
import { RouteList } from 'src/routes';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { PaginationData, PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import { setGlobalLoading } from 'src/modules/app/actions';
import { Cell, Column } from 'react-table';
import { truncate } from 'lodash';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import DeleteEntityModal from 'src/components/Modal/Modals/DeleteEntityModal';
import { FaqResponseDto } from 'src/types/api/landingPage/faq';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { Permissions } from 'src/helpers/auth/permissions';

const INITIAL_SORT_ORDER = [
  {
    id: 'sort_number',
    desc: false,
  },
];

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<FaqResponseDto>>();
  const { showModal } = useGlobalModalContext();
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.LT);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<FaqResponseDto>[] | any>(
    () => [
      {
        Header: t('table.question'),
        accessor: 'question',
        sortType: 'string',
        width: 200,
        disableSortBy: true,
        Cell: (cell: Cell<FaqResponseDto>) => {
          const row = cell.row.original;
          return <span>{truncate(row.question?.[language], { length: 100 })}</span>;
        },
      },
      {
        Header: t('table.answer'),
        accessor: 'answer',
        sortType: 'string',
        width: 200,
        disableSortBy: true,
        Cell: (cell: Cell<FaqResponseDto>) => {
          const row = cell.row.original;
          return <span>{truncate(row.answer?.[language], { length: 100 })}</span>;
        },
      },
      {
        Header: t('table.category'),
        accessor: 'category_id',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<FaqResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.category.title}</span>;
        },
      },
      {
        Header: t('table.sort_number'),
        accessor: 'sort_number',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<FaqResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.sort_number}</span>;
        },
      },
      {
        Header: t('table.action'),
        width: 50,
        Cell: (cell: Cell<FaqResponseDto> | any) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                route={generatePath(RouteList.LANDING_PAGE.FAQ.UPDATE, {
                  faqId: row.id,
                })}
                permissions={Permissions.BO__LANDING_PAGE__FAQ__UPDATE}
              />
              <PermissionAction permissions={Permissions.BO__LANDING_PAGE__FAQ__DELETE}>
                <Link
                  to={'#'}
                  title={t('common.delete')}
                  onClick={(e) => {
                    e.preventDefault();
                    showModal(
                      <DeleteEntityModal
                        title={t('pages.delete_faq')}
                        body={t('common.delete_generic')}
                        onDelete={() => {
                          return Api.faq
                            .deleteFaq(row.id)
                            .then(() => cell.dispatch({ type: 'refreshData' }));
                        }}
                      />,
                    );
                  }}
                  className={'link-danger'}
                >
                  <i className={'fas fa-trash-alt'} />
                </Link>
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [language, showModal, t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.faq.fetchFilteredFaq(request).then((response) => setData(response));
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-3'}>{t('menu.landing_page.faq')}</h4>
            <LanguageSwitcher locale={language} setLocale={setLanguage} />
            <HiddenColumnsProvider title={'FaqIndex'}>
              <Table
                title={'FaqIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                initialSortBy={INITIAL_SORT_ORDER}
                createComponent={
                  <PermissionAction permissions={Permissions.BO__LANDING_PAGE__FAQ__CREATE}>
                    <Link
                      to={RouteList.LANDING_PAGE.FAQ.CREATE}
                      className={'btn btn-primary w-100'}
                    >
                      {t('common.create_new')}
                    </Link>
                  </PermissionAction>
                }
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default FAQ;
