import React, { useCallback, useState } from 'react';
import { ModalComponent } from '../ModalComponent';
import { useTranslation } from 'react-i18next';
import { useGlobalModalContext } from '../GlobalModal';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ModalCloseButton from '../ModalCloseButton';
import { success } from 'src/services/toastr';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';

interface Props {
  onDelete: () => Promise<any>;
  title?: string;
  body?: string;
}

const DeleteEntityModal: React.FC<Props> = ({ onDelete, title, body }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [isInAction, setIsInAction] = useState(false);

  const onAction = useCallback(() => {
    if (isInAction) return;
    setIsInAction(true);

    onDelete()
      .then(() => {
        success(t('common.deleted_success'));
        hideModal();
      })
      .finally(() => {
        setIsInAction(false);
      });
  }, [hideModal, isInAction, onDelete, t]);

  return (
    <React.Fragment>
      <ModalComponent size={'sm'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title ?? t('common.delete')}
          </ModalHeader>
          <ModalBody className={''}>
            <p className={'text-pre'}>{body ?? t('common.delete')}</p>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-center'}>
            <Col>
              <DenyButton title={t('common.cancel')} onClick={hideModal} />
            </Col>
            <Col>
              <SaveButton title={t('common.delete')} onClick={onAction} />
            </Col>
          </ModalFooter>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default DeleteEntityModal;
