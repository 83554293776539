import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput, { ReactSelectOption } from './SelectInput';
import { ProjectDocumentType } from 'src/helpers/Enums/ProjectDocumentType';

interface Props {
  name: string;
  placeholder: string;
}

const SelectProjectDocumentTypeInput: React.FC<Props> = ({ name, placeholder }) => {
  const { t } = useTranslation();

  const [options] = useState<ReactSelectOption[]>([
    {
      label: t('projects.documents.types.project_documents'),
      value: ProjectDocumentType.PROJECT_DOCUMENTS,
    },
  ]);

  return <SelectInput name={name} options={options} placeholder={placeholder} />;
};

export default SelectProjectDocumentTypeInput;
