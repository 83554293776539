import { LegalEntity, UserType } from 'src/types';

export enum GeneralUserTypeEnum {
  NATURAL = 'natural',
  COMPANY_INVESTOR = 'company_investor',
  COMPANY_DEVELOPER = 'company_developer',
}

export const generalUserToLegalEntityAndType = (type: GeneralUserTypeEnum) => {
  const legalEntityMap = new Map([
    [GeneralUserTypeEnum.NATURAL, LegalEntity.Personal],
    [GeneralUserTypeEnum.COMPANY_INVESTOR, LegalEntity.Company],
    [GeneralUserTypeEnum.COMPANY_DEVELOPER, LegalEntity.Company],
  ]);
  const typeMap = new Map([
    [GeneralUserTypeEnum.NATURAL, UserType.Investor],
    [GeneralUserTypeEnum.COMPANY_INVESTOR, UserType.Investor],
    [GeneralUserTypeEnum.COMPANY_DEVELOPER, UserType.ProjectDeveloper],
  ]);

  return { legal_entity: legalEntityMap.get(type), type: typeMap.get(type) };
};
