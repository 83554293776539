import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import { SubmitCommitteeMemberVoteRequestDto } from 'src/types/api/applications';
import SelectYesNo from 'src/components/Form/Select/SelectYesNoInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import * as Yup from 'yup';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';

interface Props extends ApplicationContextState {
  isCommitteeChief?: boolean;
}

const SubmitCommitteeMemberVoteModal: React.FC<Props> = ({
  application,
  setApplication,
  isCommitteeChief,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [submitCommitteeMemberVote] = useState<SubmitCommitteeMemberVoteRequestDto>({
    reason: '',
    vote: false,
  });

  const SubmitCommitteeSchema = Yup.object().shape({
    vote: Yup.boolean().required(),
    reason: Yup.string().nullable().when('vote', {
      is: false,
      then: Yup.string().required(),
    }),
  });

  const onSubmit = useCallback(
    async (
      request: SubmitCommitteeMemberVoteRequestDto,
      helper: FormikHelpers<SubmitCommitteeMemberVoteRequestDto>,
    ) => {
      if (!application) {
        return;
      }

      try {
        let response;
        if (isCommitteeChief) {
          response = await Api.applications.submitChiefCommitteeMemberVote(application.id, {
            ...request,
            reason: !request.vote ? request.reason : null,
          });
        } else {
          response = await Api.applications.submitCommitteeMemberVote(application.id, {
            ...request,
            reason: !request.vote ? request.reason : null,
          });
        }

        success(t('common.updated_success'));
        setApplication(response);
        hideModal();
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }

      return true;
    },
    [application, t, hideModal, setApplication, isCommitteeChief],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('applications.committee_members.title')}
          </ModalHeader>
          <Formik
            enableReinitialize={true}
            initialValues={submitCommitteeMemberVote}
            onSubmit={onSubmit}
            validationSchema={SubmitCommitteeSchema}
          >
            {({ handleSubmit, isSubmitting, values }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <div>
                    <SelectYesNo
                      true_label={t('common.approve')}
                      false_label={t('common.reject')}
                      name={'vote'}
                      isClearable={false}
                      placeholder={t('applications.committee_members.body')}
                    />
                  </div>
                  <div className={'mt-2'} hidden={values.vote}>
                    <TextAreaInput disabled={values.vote} name={'reason'} />
                  </div>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default withApplication(SubmitCommitteeMemberVoteModal);
