import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';

import { WalletOperationStatusEnum } from 'src/helpers/Enums/Wallet/WalletOperationStatusEnum';

interface Props {
  value: WalletOperationStatusEnum;
}

const WalletOperationStatusBadge: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  function getBadgeClassname(value: string): string {
    switch (value) {
      case WalletOperationStatusEnum.SUCCESS:
        return 'bg-success';
      case WalletOperationStatusEnum.PENDING:
      case WalletOperationStatusEnum.RESERVED:
        return 'bg-warning';
      case WalletOperationStatusEnum.FAILED:
        return 'bg-danger';
      default:
        return '';
    }
  }

  return <Badge className={getBadgeClassname(value)}>{t(`common.status.${value}`)}</Badge>;
};

export default WalletOperationStatusBadge;
