import { CurrentUserResponseDto } from 'src/types/api/currentUser';
import { queries } from 'src/api/queries';
import Api from 'src/api/index';
import { defaultOptions, UseQueryOptionsObject } from 'src/api/queries/queries.utils';
import { useMakeQuery } from 'src/api/queries/useMakeQuery';

const useProfileQuery = (options?: UseQueryOptionsObject<CurrentUserResponseDto>) => {
  return useMakeQuery<CurrentUserResponseDto>(
    [queries.profile],
    () => Api.currentUser.fetchUser(),
    {
      staleTime: 60 * 1000,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useProfileQuery };
