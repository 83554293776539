import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  verified: boolean;
}

const VerifiedBadge: React.FC<Props> = ({ verified }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <i
        title={t(`${verified ? 'common.verified' : 'common.unverified'}`)}
        className={`fas fa-fingerprint ${verified ? 'text-success' : 'text-danger'}`}
      />
    </React.Fragment>
  );
};

export default VerifiedBadge;
