import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import InquiriesList from 'src/pages/LandingPage/Inquiries';
import News from 'src/pages/LandingPage/News';
import NewsCreate from 'src/pages/LandingPage/News/Create';
import NewsUpdate from 'src/pages/LandingPage/News/Update';
import JobPositionsList from 'src/pages/LandingPage/JobPositions';
import JobPositionCreate from 'src/pages/LandingPage/JobPositions/Create';
import JobPositionUpdate from 'src/pages/LandingPage/JobPositions/Update';
import TeamMembersList from 'src/pages/LandingPage/TeamMembers';
import TeamMemberCreate from 'src/pages/LandingPage/TeamMembers/Create';
import TeamMemberUpdate from 'src/pages/LandingPage/TeamMembers/Update';
import Translations from 'src/pages/LandingPage/Translations';
import ExternalNews from 'src/pages/LandingPage/ExternalNews';
import ExternalNewsCreate from 'src/pages/LandingPage/ExternalNews/Create';
import ExternalNewsUpdate from 'src/pages/LandingPage/ExternalNews/Update';

import Faq from 'src/pages/LandingPage/FAQ';
import FaqCreate from 'src/pages/LandingPage/FAQ/Create';
import FaqUpdate from 'src/pages/LandingPage/FAQ/Update';
import SEOLandingPage from './SEO';
import SEOLandingPageUpdate from 'src/pages/LandingPage/SEO/Update';

const LandingPageRoutes: any = () => [
  <AuthRoute
    path={RouteList.LANDING_PAGE.INQUIRIES.LIST}
    component={InquiriesList}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__INQUIRIES__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.INQUIRIES.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.NEWS.LIST}
    component={News}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__NEWS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.NEWS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.NEWS.CREATE}
    component={NewsCreate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__NEWS__CREATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.NEWS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.NEWS.UPDATE}
    component={NewsUpdate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__NEWS__UPDATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.NEWS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.EXTERNAL_NEWS.LIST}
    component={ExternalNews}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__EXTERNAL_NEWS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.EXTERNAL_NEWS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.EXTERNAL_NEWS.CREATE}
    component={ExternalNewsCreate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__EXTERNAL_NEWS__CREATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.EXTERNAL_NEWS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.EXTERNAL_NEWS.UPDATE}
    component={ExternalNewsUpdate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__EXTERNAL_NEWS__UPDATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.EXTERNAL_NEWS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.JOB_POSITIONS.LIST}
    component={JobPositionsList}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__JOB_POSITIONS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.JOB_POSITIONS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.JOB_POSITIONS.CREATE}
    component={JobPositionCreate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__JOB_POSITIONS__CREATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.JOB_POSITIONS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.JOB_POSITIONS.UPDATE}
    component={JobPositionUpdate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__JOB_POSITIONS__UPDATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.JOB_POSITIONS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.TEAM_MEMBERS.LIST}
    component={TeamMembersList}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__TEAM_MEMBERS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.TEAM_MEMBERS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.TEAM_MEMBERS.CREATE}
    component={TeamMemberCreate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__TEAM_MEMBERS__CREATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.TEAM_MEMBERS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.TEAM_MEMBERS.UPDATE}
    component={TeamMemberUpdate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__TEAM_MEMBERS__UPDATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.TEAM_MEMBERS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.FAQ.LIST}
    component={Faq}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__FAQ__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.FAQ.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.FAQ.CREATE}
    component={FaqCreate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__FAQ__CREATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.FAQ.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.FAQ.UPDATE}
    component={FaqUpdate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__FAQ__UPDATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.FAQ.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.TRANSLATIONS}
    component={Translations}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__TRANSLATIONS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.TRANSLATIONS}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.SEO.LIST}
    component={SEOLandingPage}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__SEO__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.SEO.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.LANDING_PAGE.SEO.UPDATE}
    component={SEOLandingPageUpdate}
    layout={PageLayout}
    permissions={[Permissions.BO__LANDING_PAGE__SEO__UPDATE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.LANDING_PAGE.SEO.LIST}
    exact
  />,
];

export default LandingPageRoutes;
