import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  TranslationListResponseDto,
  UpdateTranslationRequestDto,
  UpdateTranslationResponseDto,
} from 'src/types/api/translations';
import { FilterSelectValuesResponseDto, ImportDataRequestDto } from 'src/types/api/common';

class LandingPageTranslations extends Client {
  api = {
    FETCH_FILTERED_LANDING_PAGE_TRANSLATIONS: `${this.http.baseUrl}/locale/landing-page/filter`,
    UPDATE_LANDING_PAGE_TRANSLATION: `${this.http.baseUrl}/locale/landing-page/translations/{translationId}`,
    DELETE_LANDING_PAGE_TRANSLATION: `${this.http.baseUrl}/locale/landing-page/translations/{translationId}`,
    FETCH_LANDING_PAGE_GROUPS: `${this.http.baseUrl}/locale/landing-page/translations/translationGroups`,
    EXPORT_FILTERED_LANDING_PAGE_TRANSLATIONS_CSV: `${this.http.baseUrl}/locale/landing-page/export/csv`,
    IMPORT_LANDING_PAGE_TRANSLATIONS: `${this.http.baseUrl}/locale/landing-page/import/csv`,
  };

  fetchFilteredLandingPageTranslations = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<TranslationListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_LANDING_PAGE_TRANSLATIONS, { params: request });
  };

  fetchLandingPageTranslationGroups = (): Promise<FilterSelectValuesResponseDto[]> => {
    return this.http.get(this.api.FETCH_LANDING_PAGE_GROUPS);
  };

  updateLandingPageTranslation = (
    translationId: number | string,
    request: UpdateTranslationRequestDto,
  ): Promise<UpdateTranslationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_LANDING_PAGE_TRANSLATION, { translationId });
    return this.http.put(url, request);
  };

  deleteLandingPageTranslation = (
    translationId: number | string,
  ): Promise<UpdateTranslationResponseDto> => {
    const url = this.buildUrl(this.api.DELETE_LANDING_PAGE_TRANSLATION, { translationId });
    return this.http.delete(url);
  };

  exportLandingPageTranslationDataCsv = (filter?: PaginationDataFilter): Promise<any> => {
    return this.http.get(this.api.EXPORT_FILTERED_LANDING_PAGE_TRANSLATIONS_CSV, {
      params: filter,
    });
  };

  importLandingPageTranslationData = (request: ImportDataRequestDto): Promise<any> => {
    const formData = new FormData();

    Object.keys(request).forEach((key) => {
      const k = key as keyof ImportDataRequestDto;

      if (request[k] != null) {
        if (typeof request[k] == 'string') {
          formData.append(key, request[k] as string);
        } else {
          const file = request[k] as File;
          formData.append(key, file);
        }
      }
    });

    return this.http.post(this.api.IMPORT_LANDING_PAGE_TRANSLATIONS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
}

export default LandingPageTranslations;
