import React, { Suspense, useEffect } from 'react';
import './assets/scss/app.scss';
import { setGlobalLoading } from './modules/app/actions';
import './services/i18n';
import GlobalLoader from './components/GlobalLoader';
import { ToastContainer } from 'react-toastify';
import Routes from './pages/Routes';
import { GlobalModal } from './components/Modal/GlobalModal';
import { Permissions } from 'src/helpers/auth/permissions';
import ChristmasWishes from 'src/teamCheer/ChristmasWishes';
import { useAuth } from './services/useAuth';
import Api from './api';
import useAppState from './modules/appState';

const App: React.FC = () => {
  const { globalLoading, sidebarOpen, isAppStarted, permissions } = useAppState();

  const { refresh, mount, dismount } = useAuth();

  useEffect(() => {
    document.body.classList.toggle('vertical-collpsed', !sidebarOpen);
  }, [sidebarOpen]);

  useEffect(() => {
    setGlobalLoading(true);

    refresh(Api.currentUser.refreshToken).finally(() => {
      setGlobalLoading(false);
    });

    mount();
    return () => {
      dismount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <GlobalModal>
        <Suspense fallback={<GlobalLoader />}>
          {(!isAppStarted || globalLoading) && <GlobalLoader />}
          {isAppStarted && <Routes />}
          <ToastContainer />
          {isAppStarted && <ChristmasWishes isGuest={permissions[0] === Permissions.BO__GUEST} />}
        </Suspense>
      </GlobalModal>
    </React.Fragment>
  );
};

export default App;
