import React, { useEffect, useState } from 'react';
import { FilterValue, Row } from 'react-table';
import { usePopper } from 'react-popper';
import useOnClickOutsideNode from 'src/helpers/useOnClickOutsideNode';

export interface AdvanceFilterProps {
  start?: any;
  end?: any;
  hidePredefinedDates: boolean;
  column: {
    filterValue: FilterValue;
    setFilter: (updater: ((filterValue: FilterValue) => FilterValue) | FilterValue) => void;
    preFilteredRows?: Array<Row<any>>;
    id?: string;
  };
}

interface Props {
  title: string;
  icon: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  hidePredefinedDates?: boolean;
}

const AdvanceFilter: React.FC<Props> = (props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  useOnClickOutsideNode(referenceElement, () => props.setIsOpen(false));

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 5],
        },
      },
    ],
    strategy: 'fixed',
  });

  useEffect(() => {
    const updatePosition = async () => {
      if (update) await update();
    };
    updatePosition();
  }, [props.title, update]);

  const toggle = async () => {
    props.setIsOpen(!props.isOpen);
    if (update) await update();
  };

  const getClassName = (): string => {
    if (props.isOpen || props.title) {
      return 'filter-selected';
    }
    return '';
  };

  return (
    <div className={'action'} ref={setReferenceElement} onClick={(e) => e.stopPropagation()}>
      <button
        className={`btn action-btn ${getClassName()}`}
        onClick={(e) => {
          e.stopPropagation();
          toggle();
        }}
      >
        <i className={`icon ${props.icon}`} />
        {props.title && <span className={'ms-1'}>{props.title}</span>}
      </button>
      <div
        ref={setPopperElement}
        className={'action-options'}
        data-show={props.isOpen}
        style={styles.popper}
        {...attributes.popper}
      >
        <div>{props.children}</div>
      </div>
    </div>
  );
};
export default AdvanceFilter;
