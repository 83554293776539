import { useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import { IAllProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';

export interface Props extends IAllProps {
  name: string;
  placeholder?: string;
  hideLabel?: boolean;
  customValue?: string;
}

const WYSIWYGInput: React.FC<Props> = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const [field, meta, helper] = useField(props.name);
  const editorRef = useRef<Editor>(null);
  const { submitCount } = useFormikContext();
  const [isError, setError] = useState<boolean>(false);

  if (!props.placeholder) {
    props.placeholder = t('label.' + props.name);
  }

  const onChange = useCallback(
    (newValue: string) => {
      helper.setValue(newValue ?? '');
    },
    [helper],
  );

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setError(submitCount > 0 && !!meta.error);
  }, [meta.error, submitCount]);

  return (
    <>
      <div className={`${isError ? 'is-invalid' : ''}`}>
        <label htmlFor={field.name}>{props.placeholder}</label>
        <div className={'wysiwyg-section-wrapper'}>
          <div className={'wysiwyg-editor-wrapper'}>
            <Editor
              ref={editorRef}
              value={props.customValue === undefined ? meta.value ?? '' : props.customValue}
              onEditorChange={(e) => {
                onChange(e);
              }}
              init={{
                height: 300,
                menubar: true,
                contextmenu: 'link image table',
                branding: false,
                table_use_colgroups: false,
                plugins:
                  'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons',
                toolbar:
                  'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                formats: {
                  h1: { inline: 'span', styles: { fontSize: '2em', fontWeight: 'bold' } },
                  h2: { inline: 'span', styles: { fontSize: '1.5em', fontWeight: 'bold' } },
                  h3: { inline: 'span', styles: { fontSize: '1.17em', fontWeight: 'bold' } },
                  h4: { inline: 'span', styles: { fontSize: '1em', fontWeight: 'bold' } },
                  h5: { inline: 'span', styles: { fontSize: '0.83em', fontWeight: 'bold' } },
                  h6: { inline: 'span', styles: { fontSize: '0.67em', fontWeight: 'bold' } },
                },
              }}
              {...props}
            />
          </div>
        </div>
      </div>
      {isError && <div className={'invalid-feedback'}>{meta.error}</div>}
    </>
  );
};
export default WYSIWYGInput;

WYSIWYGInput.defaultProps = {
  disabled: false,
};
