import React, { useCallback, useEffect, useState } from 'react';
import { MediaOrderRequestDto, MediaResponseDto } from 'src/types/api/common';
import { Picture } from 'src/types/app';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { Card, CardBody, CardImg, CardSubtitle } from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';
import { useTranslation } from 'react-i18next';
import DeleteButton from 'src/components/DeleteButton';
import Lightbox from 'react-image-lightbox';
import EditButton from 'src/components/EditButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import PictureControlModal from 'src/components/PictureList/PictureControlModal';
import ViewButton from 'src/components/ViewButton';

interface Props {
  pictures: Array<MediaResponseDto>;
  onOrderUpdate?: (request: MediaOrderRequestDto) => Promise<void>;
  onPictureDelete?: (mediaId: string) => Promise<void>;
  onPictureRename?: (mediaId: string, name: string) => Promise<void>;
}

const PictureList: React.FC<Props> = ({
  pictures,
  onOrderUpdate,
  onPictureDelete,
  onPictureRename,
}) => {
  const { t } = useTranslation();
  const [statePictures, setStatePictures] = useState<Array<Picture>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    const newPictures: Array<Picture> = [];

    pictures.forEach((picture) => {
      const pic: Picture = {
        id: picture.id,
        name: picture.name,
        external_url: statePictures.find((i) => i.id === picture.id)?.external_url ?? null,
        mime_type: picture.mime_type,
        order_column: picture.order_column,
        size: picture.size,
      };

      newPictures.push(pic);

      if (pic?.external_url) return;

      Api.media.fetchExternal(picture.url).then((media) => {
        setStatePictures((prevState) => {
          const state = [...prevState];
          const index = state.findIndex((i) => i.id === picture.id);
          state[index].external_url = media.url;

          return state;
        });
      });
    });

    setStatePictures(newPictures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictures]);

  const onView = useCallback((index: number) => {
    setPhotoIndex(index);
    setIsOpen(true);
  }, []);

  return (
    <>
      <ReactSortable
        className={'gap-4 card-group'}
        list={statePictures ?? []}
        setList={setStatePictures}
        disabled={!onOrderUpdate}
        onEnd={() => {
          const request: MediaOrderRequestDto = {
            media_ids: statePictures.map((i) => i.id),
          };

          onOrderUpdate &&
            onOrderUpdate(request).then(() => {
              success(t('common.updated_success'));
            });
        }}
      >
        {statePictures.map((media, key) => {
          return (
            <Card
              className={onOrderUpdate ? 'move ' : '' + 'placeholder-glow'}
              key={media.id}
              style={{ minWidth: '225px', maxWidth: '300px' }}
            >
              {media.external_url && (
                <CardImg
                  onClick={() => {
                    setPhotoIndex(key);
                    setIsOpen(true);
                  }}
                  top
                  src={media.external_url}
                  className={'fit-image placeholder-glow'}
                  style={{ height: '150px', width: '100%' }}
                />
              )}
              {!media.external_url && (
                <div
                  className={'fit-image placeholder '}
                  style={{ height: '150px', width: '100%' }}
                />
              )}
              <CardBody className={'p-0 py-3'}>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  {media.name}
                </CardSubtitle>
                <div className={'d-flex gap-2'}>
                  {onPictureDelete && <DeleteButton onDelete={() => onPictureDelete(media.id)} />}
                  {onPictureRename && (
                    <EditButton
                      onEdit={() =>
                        showModal(
                          <PictureControlModal
                            name={media.name}
                            onAction={(name) => onPictureRename(name, media.id)}
                          />,
                        )
                      }
                    />
                  )}
                  <ViewButton onView={() => onView(key)} />
                </div>
              </CardBody>
            </Card>
          );
        })}
      </ReactSortable>
      {isOpen && statePictures[photoIndex]?.external_url && (
        <Lightbox
          mainSrc={statePictures[photoIndex]?.external_url ?? ''}
          nextSrc={statePictures[photoIndex]?.external_url ?? ''}
          prevSrc={statePictures[photoIndex]?.external_url ?? ''}
          imageTitle={statePictures[photoIndex].name}
          toolbarButtons={[
            <div key={1}>
              {onPictureDelete && (
                <DeleteButton
                  className={'me-2'}
                  onDelete={() =>
                    onPictureDelete(statePictures[photoIndex].id).then(() => setIsOpen(false))
                  }
                />
              )}
              {onPictureRename && (
                <EditButton
                  className={'me-2'}
                  onEdit={() =>
                    showModal(
                      <PictureControlModal
                        name={statePictures[photoIndex]?.name}
                        onAction={(name) => onPictureRename(name, statePictures[photoIndex]?.id)}
                      />,
                    )
                  }
                />
              )}
            </div>,
          ]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((prevState) =>
              prevState - 1 < 0 ? statePictures.length - 1 : prevState - 1,
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((prevState) =>
              prevState + 1 >= statePictures.length ? 0 : prevState + 1,
            )
          }
        />
      )}
    </>
  );
};

export default PictureList;
