import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import ApplicationList from 'src/pages/Application/List';
import { Permissions } from 'src/helpers/auth/permissions';
import Application from 'src/pages/Application/Update/Application';

const ApplicationRoutes: any = () => [
  <AuthRoute
    path={RouteList.APPLICATION.LIST}
    component={ApplicationList}
    layout={PageLayout}
    permissions={[Permissions.BO__APPLICATIONS__FILTER]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.APPLICATION.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.APPLICATION.VIEW}
    component={Application}
    layout={PageLayout}
    permissions={[Permissions.BO__APPLICATIONS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.APPLICATION.VIEW}
    exact
  />,
];

export default ApplicationRoutes;
