import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormikField from './FormikField';
import { formatSlug } from 'src/helpers/formatters/common';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
}

const TitleInput: React.FC<Props> = ({ ...props }) => {
  const { i18n } = useTranslation();
  const [field] = useField(props.name);
  const [, , { setValue, setTouched }] = useField(`slug.${i18n.language}`);

  const handleChange = (e: any) => {
    setValue(formatSlug(e.target.value));
    setTouched(true);
  };

  return (
    <FormikField name={props.name} wrapperClass={'form-label-group'}>
      <label htmlFor={props.name}>{props.placeholder}</label>
      <input
        id={props.name}
        onChange={(e) => {
          field.onChange(e);
          handleChange(e);
        }}
        value={field.value ?? ''}
        className={`form-control`}
        {...props}
      />
    </FormikField>
  );
};
export default TitleInput;
