import { ApplicationResponseDto } from 'src/types/api/applications';
import React, { createContext, useContext } from 'react';

export interface ApplicationContextState {
  application: ApplicationResponseDto | undefined;
  setApplication: (application: ApplicationResponseDto) => void;
}

const ApplicationContext = createContext<ApplicationContextState>({
  application: undefined,
  setApplication: () => {
    return;
  },
});

export const ApplicationProvider: React.FC<ApplicationContextState> = ({
  application,
  setApplication,
  children,
}) => {
  return (
    <ApplicationContext.Provider value={{ application, setApplication }}>
      {children}
    </ApplicationContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export function withApplication<P extends ApplicationContextState = ApplicationContextState>(
  Component: React.ComponentType<P>,
) {
  const WithApplication = ({ ...props }: Optionalize<P, ApplicationContextState>) => {
    const c = useContext(ApplicationContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithApplication.displayName = `withApplication${Component.displayName}`;

  return WithApplication;
}

export default ApplicationProvider;
