import React, { AnchorHTMLAttributes } from 'react';

interface Props
  extends React.DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  title?: string;
  submitting?: any;
  btype?: string;
}

const LinkButton: React.FC<Props> = (props) => {
  const propsInput: any = {};

  Object.keys(props).forEach(function (key) {
    if (key === 'submitting') return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    propsInput[key] = props[key];
  });

  return (
    <a className={`btn w-100 ${props.btype ?? 'btn-primary'}`} href {...propsInput}>
      {props.submitting ? (
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>{props.children}</>
      )}
    </a>
  );
};

export default LinkButton;
