import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import Api from 'src/api';
import SelectInput, { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { Form, Formik } from 'formik';
import { AttachUserRoleRequestDto, UserResponseDto } from 'src/types/api/user';
import { success } from 'src/services/toastr';

interface Props {
  user: UserResponseDto | undefined;
  onAction: (request: AttachUserRoleRequestDto) => Promise<UserResponseDto | void>;
}

const AttachRolesModal: React.FC<Props> = ({ onAction, user }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [roles, setRoles] = useState<ReactSelectOption[] | undefined>(undefined);
  const [attachUserRoleRequest] = useState<AttachUserRoleRequestDto>({
    roles: user?.roles.map((r) => r.id) ?? [],
  });

  useEffect(() => {
    Api.roles.fetchRoles().then((response) => {
      const roles: ReactSelectOption[] = [];

      response.map((role) => {
        roles.push({
          value: role.id,
          label: role.name,
        });
      });

      setRoles(roles);
    });
  }, [user]);

  const onSubmit = useCallback(
    (request: AttachUserRoleRequestDto) => {
      return onAction(request).then(() => {
        success(t('common.updated_success'));
        hideModal();
      });
    },
    [hideModal, onAction, t],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('users.attach_role')}
          </ModalHeader>
          <Formik initialValues={attachUserRoleRequest} onSubmit={onSubmit}>
            {({
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <SelectInput
                    placeholder={t('users.attach_role_body')}
                    isMulti={true}
                    name={'roles'}
                    options={roles}
                    isClearable={true}
                  />
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <PrimaryButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <PrimaryButton
                      btype={'btn-danger'}
                      title={t('common.submit')}
                      submitting={isSubmitting}
                    />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default AttachRolesModal;
