import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import BonusSystemList from 'src/pages/BonusSystem/List';
import BonusSystemCreate from 'src/pages/BonusSystem/Create';
import BonusSystemUpdate from 'src/pages/BonusSystem/Update';

const BonusSystemRoutes: any = () => [
  <AuthRoute
    path={RouteList.BONUS_SYSTEM.LIST}
    component={BonusSystemList}
    layout={PageLayout}
    permissions={[Permissions.BO__BONUS_SYSTEM__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.BONUS_SYSTEM.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.BONUS_SYSTEM.CREATE}
    component={BonusSystemCreate}
    layout={PageLayout}
    permissions={[Permissions.BO__BONUS_SYSTEM__STORE]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.BONUS_SYSTEM.CREATE}
    exact
  />,
  <AuthRoute
    path={RouteList.BONUS_SYSTEM.UPDATE}
    component={BonusSystemUpdate}
    layout={PageLayout}
    permissions={[Permissions.BO__BONUS_SYSTEM__EDIT]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.BONUS_SYSTEM.UPDATE}
    exact
  />,
];

export default BonusSystemRoutes;
