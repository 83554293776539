import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';
import ProjectList from 'src/pages/Project/List';
import Project from 'src/pages/Project/Update/Project';

const ProjectRoutes: any = () => [
  <AuthRoute
    path={RouteList.PROJECTS.LIST}
    component={ProjectList}
    layout={PageLayout}
    permissions={[Permissions.BO__PROJECTS__FILTER]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PROJECTS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.PROJECTS.VIEW_PROJECT}
    component={Project}
    layout={PageLayout}
    permissions={[Permissions.BO__PROJECTS__VIEW]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PROJECTS.VIEW_PROJECT}
    exact
  />,
];

export default ProjectRoutes;
