import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import Api from 'src/api';
import {
  ApplicationRiskListResponseDto,
  ApplicationUpdateRisksRequestDto,
} from 'src/types/api/applications';
import { Field, Formik, FormikHelpers } from 'formik';
import { success } from 'src/services/toastr';
import { Form } from 'reactstrap';
import RiskSkeleton from 'src/pages/Application/Update/Tabs/Risks/RiskSkeleton';
import SubmitTabButton from 'src/pages/Application/Update/Tabs/Components/SubmitTabButton';
import SaveButton from 'src/components/Form/SaveButton';
import { ApplicationTabProps } from 'src/pages/Application/Update/Tabs/ApplicationTabData';
import { transformErrors } from 'src/helpers';

interface Props extends ApplicationTabProps, ApplicationContextState {}

const EMPTY_REQUEST: ApplicationUpdateRisksRequestDto = {
  risks: [],
};

const TabCredit: React.FC<Props> = ({ application, setApplication, showSubmit }) => {
  const { t } = useTranslation();
  const [localLoading, setLocalLoading] = useState<boolean>(true);
  const [risks, setRisks] = useState<ApplicationRiskListResponseDto>();
  const [request, setRequest] = useState<ApplicationUpdateRisksRequestDto>(EMPTY_REQUEST);

  useEffect(() => {
    setLocalLoading(true);
    if (application) {
      Api.applications.fetchApplicationRisks(application.id).then((response) => {
        if (response.risks) {
          setRisks(response);
          setRequest({
            risks: response.risks.filter((risk) => risk.selected).map((risk) => risk.id),
          });
        }
        setLocalLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    async (
      request: ApplicationUpdateRisksRequestDto,
      helpers: FormikHelpers<ApplicationUpdateRisksRequestDto>,
    ) => {
      if (!application) return;

      try {
        const response = await Api.applications.storeApplicationRisks(application.id, request);
        setApplication(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }

      return true;
    },
    [application, setApplication, t],
  );

  if (localLoading) {
    return <RiskSkeleton />;
  }

  const riskList = risks?.risks?.map((risk) => (
    <div className={`form-check`} key={risk.id}>
      <label className={'form-check-label form-label'} htmlFor={risk.id}>
        <Field
          type="checkbox"
          className={`form-check-input`}
          name={'risks'}
          id={risk.id}
          value={risk.id}
        />
        <p>
          <strong>{t(risk.name)}</strong> {t(risk.description ?? '')}
        </p>
      </label>
    </div>
  ));

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h3>{t('applications.risks')}</h3>
        </div>
        {showSubmit && (
          <div>
            <SubmitTabButton title={t('applications.tabs_submit.risks')} tabName={'risks'} />
          </div>
        )}
      </div>
      <hr />
      <Formik initialValues={request} onSubmit={onSubmit} enableReinitialize={true}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {riskList}
            <div className={'mt-4 mb-4'}>
              <SaveButton title={t('common.save')} submitting={isSubmitting} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withApplication(TabCredit);
