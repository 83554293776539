import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormikField from 'src/components/Form/FormikField';

export interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string;
  hideLabel?: boolean;
}

const TextAreaInput: React.FC<Props> = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  const { submitCount } = useFormikContext();
  const [isError, setError] = useState<boolean>(false);
  useEffect(() => {
    setError(submitCount > 0 && !!meta.error);
  }, [meta.error, submitCount]);

  if (!props.placeholder) {
    props.placeholder = t('label.' + props.name);
  }

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      <FormikField name={props.name} wrapperClass={'form-label-group'}>
        {!props.hideLabel && <label htmlFor={props.name}>{props.placeholder}</label>}
        <textarea
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value ?? ''}
          rows={3}
          id={props.name}
          placeholder={props.placeholder}
          className={`form-control${isError ? ' is-invalid' : ''}`}
          {...props}
        />
      </FormikField>
    </>
  );
};
export default TextAreaInput;
