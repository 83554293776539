import React from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions from 'src/helpers/usePermissions';
import { Link } from 'react-router-dom';

interface Props {
  permissions?: string | string[];
  show?: boolean;
  route: string;
  previousRoute?: string;
}

const EditEntityButton: React.FC<Props> = ({ permissions, route, show, previousRoute }) => {
  const { t } = useTranslation();
  const p = usePermissions();

  if ((show !== undefined && !show) || (permissions && p.cannot(permissions))) {
    return null;
  }

  return (
    <React.Fragment>
      <Link
        to={{ pathname: route, state: { from: previousRoute } }}
        className={'link-primary'}
        title={t('common.edit')}
      >
        <i className={'fas fa-pencil-alt'} />
      </Link>
    </React.Fragment>
  );
};

export default EditEntityButton;
