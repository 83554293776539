import React, { useCallback, useState } from 'react';
import { Card, CardBody, Col, Container, Form, FormGroup, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { PaginationDataFilter } from 'src/types';
import Api from 'src/api';
import { ProjectListResponseDto } from 'src/types/api/projects';
import { SelectAsyncInput } from 'src/components/Form/Select';
import TextInput from 'src/components/Form/TextInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { Formik } from 'formik';
import {
  EstimateSinglePayoutRequestDto,
  EstimateSinglePayoutResponseDto,
} from 'src/types/api/payments/borrowerPayouts';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import { ProjectStatus } from 'src/types/app/projects';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import EstimatePayoutInformation from 'src/pages/Payment/Estimates/EstimatePayout/EstimatePayoutInformation';

const INITIAL_STATE: EstimateSinglePayoutRequestDto = {
  project_id: null,
  amount: 0,
  payment_date: format(new Date(), 'yyyy-MM-dd'),
};

const EstimatePayout: React.FC<RouteComponentProps<object>> = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<EstimateSinglePayoutResponseDto | undefined>(undefined);
  const [isCalculated, setCalculated] = useState<boolean>(false);

  const fetchProjects = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
      filters: [
        {
          id: 'status',
          value: ProjectStatus.Confirmed,
        },
      ],
    };
    const response = await Api.projects.fetchFilteredProjects(request).promise;

    return response.data.map((project: ProjectListResponseDto) => ({
      value: project.id,
      label: `${project.pid} ${
        project.project_name?.[i18n.resolvedLanguage as LanguageEnum] ?? ''
      }`,
    }));
  };

  const onChange = (values: EstimateSinglePayoutRequestDto, setValues: any) => {
    setValues(values);
    if (Schema.isValidSync(values)) {
      debounceOnChange(values);
    } else {
      setData(undefined);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce((request: EstimateSinglePayoutRequestDto) => {
      setCalculated(false);
      Api.payments.estimates
        .estimatePayout(request)
        .then((response: EstimateSinglePayoutResponseDto) => {
          setData(response);
          setCalculated(true);
        });
    }, 300),
    [],
  );

  const Schema = Yup.object().shape({
    project_id: Yup.string().required(),
    amount: Yup.number().required().min(0),
    payment_date: Yup.date().required(),
  });

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} className={'mb-4'}>
            <Card>
              <CardBody>
                <h4 className={'mb-4'}>{t('menu.payments.estimates')}</h4>
                <Formik
                  initialValues={INITIAL_STATE}
                  enableReinitialize={true}
                  validationSchema={Schema}
                  validateOnChange={true}
                  onSubmit={debounceOnChange} //don't need any logic on submit
                >
                  {({ handleChange, handleSubmit, values, setValues }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <FormGroup>
                        <div className="mb-3">
                          <SelectAsyncInput
                            name={'project_id'}
                            loadOptions={fetchProjects}
                            isClearable={true}
                            isMulti={false}
                            onChange={(option: any) => {
                              onChange({ ...values, project_id: option.value }, setValues);
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <TextInput
                            type={'number'}
                            step={'any'}
                            name={'amount'}
                            onChange={(e): void => {
                              handleChange(e);
                              onChange(
                                {
                                  ...values,
                                  amount: !!e.target.value ? Number(e.target.value) : null,
                                },
                                setValues,
                              );
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <DatePickerInput
                            name={'payment_date'}
                            onChangeAction={(e): void => {
                              onChange(
                                { ...values, payment_date: format(e, 'yyyy-MM-dd') },
                                setValues,
                              );
                            }}
                          />
                        </div>
                        <div className={'mt-4 mb-4'}>
                          {!!data && (
                            <EstimatePayoutInformation calculated={isCalculated} data={data} />
                          )}
                        </div>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EstimatePayout;
