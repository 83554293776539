import React from 'react';
import AuthRoute from '../AuthRoute';
import Profile from './Profile';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import { Permissions } from 'src/helpers/auth/permissions';

const ProfileRoutes: any = () => [
  <AuthRoute
    path={RouteList.DASHBOARD.DASHBOARD.LIST}
    component={Profile}
    layout={PageLayout}
    permissions={[Permissions.BO__ACTIONS__ACCESS_PANEL]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.DASHBOARD.DASHBOARD.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.PROFILE.PROFILE}
    component={Profile}
    layout={PageLayout}
    permissions={[Permissions.BO__ACTIONS__ACCESS_PANEL]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.PROFILE.PROFILE}
    exact
  />,
];

export default ProfileRoutes;
