import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { Form, Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import { locales } from 'src/helpers';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { BaseTabProps } from 'src/components/Tabs';
import SimpleTable from 'src/components/SimpleTable';
import DownloadButton from 'src/components/DownloadButton';
import FileUpload, { FileUploadHandle } from 'src/components/Form/FileUpload/FileUpload';
import { AttachFilesRequestDto, DocumentResponseDto } from 'src/types/api/common';
import DeleteButton from 'src/components/DeleteButton';
import { FormikProps } from 'formik/dist/types';
import EditButton from 'src/components/EditButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import DocumentControlModal from 'src/pages/Project/Update/Tabs/Modals/DocumentControlModal';
import IsVisibleLocaleBadge from 'src/pages/Project/Update/Tabs/Badges/IsVisibleLocaleBadge';
import IsVisibleBadge from 'src/components/Badges/IsVisibleBadge';
import ProjectDeveloperLoanAgreementSection from 'src/pages/Project/Update/Tabs/LoanAgreement/ProjectDeveloperLoanAgreementSection';
import PermissionAction from 'src/components/PermissionAction';
import { Permissions } from 'src/helpers/auth/permissions';
import InvestorLoanAgreementSection from './LoanAgreement/InvestorLoanAgreementSection';
import { ProjectStatus } from 'src/types/app/projects';
import IsOutdatedBadge from 'src/components/Badges/IsOutdatedBadge';
import CopyPublicMediaLinkButton from 'src/components/CopyPublicMediaLinkButton';
import usePermissions from 'src/helpers/usePermissions';

interface Props extends ProjectContextState, BaseTabProps {}

const TabDocuments: React.FC<Props> = ({ project, setProject, onErrorHandling }) => {
  const { t } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);
  const { showModal } = useGlobalModalContext();
  const { can } = usePermissions();

  const [initialFormValues] = useState<AttachFilesRequestDto>({
    media_ids: [],
  });

  const columns = useMemo<Column<DocumentResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'id',
      },
      {
        Header: t('table.file_name'),
        accessor: 'name',
      },
      {
        Header: t('table.mime_type'),
        accessor: 'mime_type',
      },
      {
        Header: t('table.size'),
        accessor: 'size',
      },
      {
        Header: t('table.information'),
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div className={'d-flex justify-content-center gap-2 text-center'}>
            <IsOutdatedBadge show={!!cell.row.original.is_outdated} />
            <IsVisibleBadge show={!!cell.row.original.is_visible} />
            {locales.map((locale, key) => (
              <IsVisibleLocaleBadge
                className={'w-100'}
                key={key}
                locale={locale}
                show={!!cell.row.original.visible_locales?.find((s) => s === locale)}
              />
            ))}
          </div>
        ),
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        Cell: (cell: Cell<DocumentResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        accessor: 'url',
        Cell: (cell: Cell<DocumentResponseDto>) => {
          const original = cell.row.original;
          if (!project) return null;
          return (
            <div className={'d-flex gap-2'}>
              <DownloadButton url={cell.value} />
              {can(Permissions.BO__MEDIA_GENERATE_PUBLIC_URL) && (
                <CopyPublicMediaLinkButton mediaId={original.id} />
              )}
              <EditButton
                onEdit={() =>
                  showModal(
                    <DocumentControlModal
                      document={original}
                      onAction={(request) =>
                        Api.projects
                          .updateDocument(project.id, original.id, request)
                          .then(setProject)
                      }
                    />,
                  )
                }
              />
              <DeleteButton
                onDelete={() =>
                  Api.projects.detachDocuments(project.id, cell.row.values.id).then(setProject)
                }
              />
            </div>
          );
        },
      },
    ],
    [project, setProject, showModal, t, can],
  );

  const onSubmit = useCallback(
    async (request: AttachFilesRequestDto, helpers: FormikHelpers<any>) => {
      if (!project) return;

      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        const response = await Api.projects.attachDocuments(project.id, request);
        setProject(response);
        fileUploadRef?.current?.reset();
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
        onErrorHandling(e);
      }
    },
    [onErrorHandling, project, setProject],
  );

  if (!project) {
    return <></>;
  }

  return (
    <React.Fragment>
      <h3>{t('projects.documents')}</h3>
      <hr />

      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ submitForm, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FileUpload
              ref={fileUploadRef}
              name={'media_ids'}
              onPresign={(request) => Api.projects.uploadDocuments(project.id, request)}
              onDropAccepted={submitForm}
            />
          </Form>
        )}
      </Formik>

      <SimpleTable columns={columns} data={project?.documents ?? []} />
      {(project.status === ProjectStatus.Funded ||
        project.status === ProjectStatus.Confirmed ||
        project.status === ProjectStatus.Finished) && (
        <>
          <PermissionAction
            permissions={Permissions.BO__PROJECTS__DEVELOPER_LOAN_AGREEMENT__MANAGE}
          >
            <ProjectDeveloperLoanAgreementSection />
          </PermissionAction>
          <PermissionAction permissions={Permissions.BO__PROJECTS__INVESTOR_LOAN_AGREEMENT__MANAGE}>
            <InvestorLoanAgreementSection />
          </PermissionAction>
        </>
      )}
    </React.Fragment>
  );
};

export default withProject(TabDocuments);
