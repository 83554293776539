import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { InvestmentPayoutTypeEnum } from 'src/helpers/Enums/Payments/InvestmentPayoutTypeEnum';

interface Props {
  value: InvestmentPayoutTypeEnum;
}

const InvestmentPayoutType: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const [color, setColor] = useState<string>('#FF00FF');

  useEffect(() => {
    switch (value) {
      case InvestmentPayoutTypeEnum.INTEREST:
        setColor('#81cf74');
        break;
      case InvestmentPayoutTypeEnum.PARTIAL_RETURN:
        setColor('#da90b7');
        break;
      case InvestmentPayoutTypeEnum.FINAL_RETURN:
        setColor('#3d514f');
        break;
      case InvestmentPayoutTypeEnum.DEBT:
        setColor('#1a42d6');
        break;
      default:
        setColor('#8a3196');
    }
  }, [value]);

  return (
    <Badge style={{ backgroundColor: color }} color={''}>
      {t('payments.investment_payouts.type.' + value)}
    </Badge>
  );
};

export default InvestmentPayoutType;
