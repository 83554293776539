import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import Api from 'src/api';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import usePermissions from 'src/helpers/usePermissions';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import SaveButton from 'src/components/Form/SaveButton';
import { GenerateReportRequestDto, BorrowerPayoutsReportFilterForm } from 'src/types/api/reports';
import { initialRequest } from 'src/pages/Reports/View/helpers';
import { Permissions } from 'src/helpers/auth/permissions';
import TextInput from 'src/components/Form/TextInput';
import { SelectInput } from 'src/components/Form/Select';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import { BorrowerPayoutStatusEnum } from 'src/helpers/Enums/Payments/BorrowerPayoutStatusEnum';

const AVAILABLE_COLUMNS: string[] = [
  'paid_at',
  'payment_date',
  'project_id',
  'borrower_payout_id',
  'project_name',
  'project_owner_pid',
  'project_owner_name',
  'project_owner_email',
  'project_owner_phone',
  'manager_name',
  'type',
  'status',
  'fine_on_pre_return',
  'debt_fee',
  'standard_interest',
  'additional_interest_profitus',
  'additional_interest_investment',
  'loan_repayment',
  'borrower_total',
  'profitus_total',
  'created_at',
  'received_at',
  'has_failed_payouts',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.BORROWER_PAYOUTS;

const InvestmentPayoutsReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<BorrowerPayoutsReportFilterForm>({
    project_pid: null,
    paid_from: null,
    paid_to: null,
    owner_pid: null,
    status: null,
    name: null,
    columns: AVAILABLE_COLUMNS,
  });

  const [statusOptions] = useState<ReactSelectOption[]>([
    ...Object.values(BorrowerPayoutStatusEnum).map((type) => ({
      value: type,
      label: t('payments.borrower_payouts.status.' + type),
    })),
  ]);

  const onSubmit = useCallback(
    async (
      request: BorrowerPayoutsReportFilterForm,
      helper: FormikHelpers<BorrowerPayoutsReportFilterForm>,
    ) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  const mapData = (form: BorrowerPayoutsReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendFilter(request, 'project_pid', form.project_pid);
    appendFilter(request, 'paid_from', form.paid_from);
    appendFilter(request, 'paid_to', form.paid_to);
    appendFilter(request, 'owner_pid', form.owner_pid);
    appendFilter(request, 'status', form.status);

    return request;
  };

  if (!p.hasAll([mapManagePermission(TYPE), Permissions.BO__REPORTS__GENERATE])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik
              initialValues={request}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'project_pid'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'paid_from'} showTimeSelect={true} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <DatePickerInput name={'paid_to'} showTimeSelect={true} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'owner_pid'} />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput
                          placeholder={t('label.status')}
                          name={'status'}
                          options={statusOptions}
                          isClearable={true}
                        />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export const appendFilter = (
  request: GenerateReportRequestDto,
  id: string,
  value: string | object | boolean | null | undefined,
): void => {
  if (value !== null && value !== undefined && value !== '') {
    request.filters.push({
      id: id,
      value: value,
    });
  }
};

export default InvestmentPayoutsReportFilter;
