import React, { useCallback, useEffect, useState } from 'react';

import { BaseTabProps } from 'src/components/Tabs';
import { ProjectContextState, withProject } from 'src/pages/Project/Update/ProjectContext';
import { useTranslation } from 'react-i18next';
import ActiveLocaleFlag from 'src/components/ActiveLocaleFlag';
import { Form, Formik, FormikHelpers } from 'formik';
import { Col, ColProps, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import { UseNumbers } from 'src/helpers/useNumbers';
import SaveButton from 'src/components/Form/SaveButton';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { transformErrors } from 'src/helpers';
import { UpdateProjectFinancialInfoRequestDto } from 'src/types/api/projects';

interface Props extends BaseTabProps, ProjectContextState {}

const INITIAL_REQUEST: UpdateProjectFinancialInfoRequestDto = {
  maximum_costs_note: null,
  preliminary_data_note: null,
  amount_of_own_funds: 0,
  area_of_property_on_sale: 0,
  selling_price: 0,
  value_of_parcel_of_land: 0,
  construction_cost: 0,
  project_cost: 0,
  loan_cost: 0,
  total_project_cost: 0,
  total_income: 0,
  project_profit: 0,
};

const TabFinancialInfo: React.FC<Props> = ({ project, setProject, language, onErrorHandling }) => {
  const { t } = useTranslation();
  const [request, setRequest] = useState<UpdateProjectFinancialInfoRequestDto>(INITIAL_REQUEST);

  useEffect(() => {
    if (!project) return;

    setRequest(() => ({
      maximum_costs_note: project.financial_info?.maximum_costs_note,
      preliminary_data_note: project.financial_info?.preliminary_data_note,
      amount_of_own_funds: project.financial_info?.amount_of_own_funds,
      area_of_property_on_sale: project.financial_info?.area_of_property_on_sale,
      selling_price: project.financial_info?.selling_price,
      value_of_parcel_of_land: project.financial_info?.value_of_parcel_of_land,
      construction_cost: project.financial_info?.construction_cost,
      project_cost: project.financial_info?.project_cost,
      loan_cost: project.financial_info?.loan_cost,
      total_project_cost: project.financial_info?.total_project_cost,
      total_income: project.financial_info?.total_income,
      project_profit: project.financial_info?.project_profit,
    }));
  }, [project]);

  const multiLocaleAttrs = useCallback(
    (inputName: string) => {
      return {
        name: inputName + '.' + language,
        placeholder: t('label.' + inputName),
      };
    },
    [language, t],
  );

  const onSubmit = useCallback(
    async (
      request: UpdateProjectFinancialInfoRequestDto,
      helpers: FormikHelpers<UpdateProjectFinancialInfoRequestDto>,
    ) => {
      if (!project) return;

      try {
        const response = await Api.projects.storeProjectFinancialInfo(project.id, request);
        setProject(response);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(transformErrors(e.response?.errors));
        onErrorHandling(e);
      }

      return true;
    },
    [onErrorHandling, project, setProject, t],
  );

  const colProps: ColProps = {
    md: 6,
    className: 'mb-4',
  };

  return (
    <React.Fragment>
      <h3 className={'flex'}>
        {t('projects.financial_info')}
        <ActiveLocaleFlag locale={language} className={'tabs'} />
      </h3>
      <hr />

      <Formik initialValues={request} enableReinitialize={true} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'amount_of_own_funds'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'area_of_property_on_sale'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'selling_price'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'value_of_parcel_of_land'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'construction_cost'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'project_cost'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'loan_cost'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'total_project_cost'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'total_income'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
                <Col {...colProps}>
                  <TextInput
                    type={'number'}
                    step={'any'}
                    name={'project_profit'}
                    onKeyPress={UseNumbers.preventNonNumericalInput}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col {...colProps}>
                  <TextAreaInput {...multiLocaleAttrs('maximum_costs_note')} />
                </Col>
                <Col {...colProps}>
                  <TextAreaInput {...multiLocaleAttrs('preliminary_data_note')} />
                </Col>
              </Row>
              <hr />
              <Row>
                <div className={'mt-4 mb-4'}>
                  <SaveButton title={t('common.save')} submitting={isSubmitting} />
                </div>
              </Row>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withProject(TabFinancialInfo);
