import * as Yup from 'yup';
import i18n from 'i18next';
import { CompanyRepresentativeTitleEnum } from 'src/helpers/Enums/CompanyRepresentativeTitleEnum';
import { GovernmentDocumentEnum } from 'src/helpers/Enums/GovernmentDocumentEnum';
import Api from 'src/api';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import { ObjectShape } from 'yup/lib/object';
import { BaseSchema } from 'yup';

declare module 'yup' {
  interface StringSchema {
    password(): StringSchema;

    governmentCode(field: string): StringSchema;

    governmentDocument(): StringSchema;

    passwordMatch(field: string): StringSchema;

    firstName(): StringSchema;

    lastName(): StringSchema;

    phone(): StringSchema;

    percentage(): StringSchema;

    companyLegalCode(): StringSchema;

    companyRepresentativeTitle(): StringSchema;

    companyVatCode(): StringSchema;

    companyName(): StringSchema;

    residence(): StringSchema;

    addressLine(): StringSchema;

    city(): StringSchema;

    translatableValueSchema(schema: BaseSchema): StringSchema;
  }
}

declare module 'yup' {
  interface NumberSchema {
    money(): NumberSchema;

    greaterThanField(field: string, fieldTranslation: string): NumberSchema;
    greaterThanOrEqualField(field: string, fieldTranslation: string): NumberSchema;

    lessThanField(field: string, fieldTranslation: string): NumberSchema;
    lessThanOrEqualField(field: string, fieldTranslation: string): NumberSchema;
  }
}

declare module 'yup' {
  interface DateSchema {
    laterThanField(field: string, fieldTranslation: string): DateSchema;
  }
}

const rPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).+$/;
const rPhone = /^[0-9]*$/;
const rLtGovCodeStart = /^[3456]/;
const rPercentage = /^[0-9]+[%]?$/;

export const buildYupValidations = () => {
  Yup.addMethod(Yup.string, 'residence', function (): any {
    return Yup.string().oneOf([]);
  });

  Api.countries.fetchCountries().then((countries) => {
    Yup.addMethod(Yup.string, 'residence', function (): any {
      return Yup.string().oneOf(countries.primary_countries.concat(countries.secondary_countries));
    });
  });

  Yup.addMethod(Yup.number, 'money', function (): any {
    return Yup.number().min(1).max(9999999999);
  });
  Yup.addMethod(Yup.string, 'password', function (): any {
    return Yup.string()
      .matches(rPassword, {
        name: 'password',
        message: i18n.t('validation.passwords-invalid'),
        excludeEmptyString: true,
      })
      .min(8)
      .max(124);
  });

  Yup.addMethod(Yup.string, 'percentage', function (): any {
    return Yup.string()
      .matches(rPercentage, {
        name: 'percentage',
        message: i18n.t('validation.percentage-invalid'),
        excludeEmptyString: true,
      })
      .max(3);
  });

  Yup.addMethod(Yup.string, 'passwordMatch', function (matchingfield): any {
    return Yup.string().test(
      'passwords-match',
      i18n.t('validation.passwords-match'),
      function (value) {
        return this.parent[matchingfield] === value;
      },
    );
  });

  Yup.addMethod(Yup.string, 'firstName', function (): any {
    return Yup.string().max(30);
  });

  Yup.addMethod(Yup.string, 'lastName', function (): any {
    return Yup.string().max(50);
  });

  Yup.addMethod(Yup.string, 'phone', function (): any {
    return Yup.string()
      .min(8)
      .max(21)
      .matches(rPhone, {
        name: 'phone',
        message: i18n.t('validation.invalid'),
        excludeEmptyString: true,
      });
  });

  Yup.addMethod(Yup.string, 'governmentCode', function (residence): any {
    return Yup.string()
      .max(20)
      .when(residence, {
        is: 'lt',
        then: Yup.string()
          .length(11)
          .matches(rLtGovCodeStart, {
            name: 'startsWith',
            message: i18n.t('validation.government_code.must_start', { symbols: '3, 4, 5, 6' }),
            excludeEmptyString: true,
          }),
      });
  });

  Yup.addMethod(Yup.string, 'governmentDocument', function (): any {
    return Yup.string().oneOf([
      GovernmentDocumentEnum.IdentificationCard,
      GovernmentDocumentEnum.IdCard,
      GovernmentDocumentEnum.Passport,
      GovernmentDocumentEnum.DriverLicense,
      GovernmentDocumentEnum.ResidencePermit,
      GovernmentDocumentEnum.InternalPassport,
      GovernmentDocumentEnum.SocialId,
    ]);
  });

  Yup.addMethod(Yup.string, 'companyLegalCode', function (): any {
    return Yup.string().max(20);
  });

  Yup.addMethod(Yup.string, 'companyRepresentativeTitle', function (): any {
    return Yup.string().oneOf([
      CompanyRepresentativeTitleEnum.PROXY,
      CompanyRepresentativeTitleEnum.BOARD_MEMBER,
      CompanyRepresentativeTitleEnum.DIRECTOR,
      CompanyRepresentativeTitleEnum.OTHER,
    ]);
  });

  Yup.addMethod(Yup.string, 'companyVatCode', function (): any {
    return Yup.string().min(1).max(15);
  });

  Yup.addMethod(Yup.string, 'companyName', function (): any {
    return Yup.string().min(1).max(150);
  });

  Yup.addMethod(Yup.string, 'addressLine', function (): any {
    return Yup.string().min(1).max(100);
  });

  Yup.addMethod(Yup.string, 'city', function (): any {
    return Yup.string().min(1).max(30);
  });

  Yup.addMethod(Yup.string, 'translatableValueSchema', function (schema: BaseSchema): any {
    return Yup.object().shape({
      ...Object.values(LanguageEnum).reduce((accumulator, currentValue) => {
        return {
          ...accumulator,
          [currentValue]: schema,
        };
      }, {} as ObjectShape),
    });
  });

  Yup.addMethod(
    Yup.number,
    'greaterThanField',
    function (anotherField, anotherFieldTranslation): any {
      return Yup.number().test(
        'greater-than-field',
        i18n.t('validation.greater_than_field', { field: anotherFieldTranslation }),
        function (value) {
          if (value) return this.parent[anotherField] < value;
          return true;
        },
      );
    },
  );

  Yup.addMethod(
    Yup.number,
    'greaterThanOrEqualField',
    function (anotherField, anotherFieldTranslation): any {
      return Yup.number().test(
        'greater-than-or-equal-field',
        i18n.t('validation.greater_than_equal_field', { field: anotherFieldTranslation }),
        function (value) {
          if (value) return this.parent[anotherField] <= value;
          return true;
        },
      );
    },
  );

  Yup.addMethod(Yup.number, 'lessThanField', function (anotherField, anotherFieldTranslation): any {
    return Yup.number().test(
      'less-than-field',
      i18n.t('validation.less_than_field', { field: anotherFieldTranslation }),
      function (value) {
        if (value) return this.parent[anotherField] > value;
        return true;
      },
    );
  });

  Yup.addMethod(
    Yup.number,
    'lessThanOrEqualField',
    function (anotherField, anotherFieldTranslation): any {
      return Yup.number().test(
        'less-than-or-equal-field',
        i18n.t('validation.less_than_equal_field', { field: anotherFieldTranslation }),
        function (value) {
          if (value) return this.parent[anotherField] >= value;
          return true;
        },
      );
    },
  );

  Yup.addMethod(Yup.date, 'laterThanField', function (anotherField, anotherFieldTranslation): any {
    return Yup.date().test(
      'later-than-field',
      i18n.t('validation.later_than_field', { field: anotherFieldTranslation }),
      function (value) {
        if (value) return this.parent[anotherField].getTime() < value.getTime();
        return true;
      },
    );
  });
};
