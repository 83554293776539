import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';

import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { BorrowerPayoutContextState, withBorrowerPayout } from '../BorrowerPayoutContext';
import { UncontrolledTooltip } from 'reactstrap';
import { multipleArgSum } from 'src/helpers/utilts';

const TabInvestmentPayouts: React.FC<BorrowerPayoutContextState> = ({ borrowerPayout }) => {
  const { t } = useTranslation();

  if (!borrowerPayout) return null;

  return (
    <React.Fragment>
      <h3>{t('payments.borrower_payouts.information')}</h3>
      <hr />
      <Row>
        <SidebarDataRow
          label={t('label.payment_date')}
          value={borrowerPayout.payment_date}
          displayValue={t('common.date', { date: borrowerPayout.payment_date })}
        />
        <SidebarDataRow
          label={t('label.received_at')}
          value={borrowerPayout.received_at}
          displayValue={t('common.date_full', { date: borrowerPayout.received_at })}
        />
        <SidebarDataRow
          label={t('label.paid_at')}
          value={borrowerPayout.paid_at}
          displayValue={t('common.date_full', { date: borrowerPayout.paid_at })}
        />
        <SidebarDataRow
          label={t('label.days_standard_late')}
          value={
            borrowerPayout.days_interest &&
            borrowerPayout.days_late_interest &&
            `${borrowerPayout.days_interest} d. / ${borrowerPayout.days_late_interest} d.`
          }
          displayValue={`${borrowerPayout.days_interest} d. / ${borrowerPayout.days_late_interest} d.`}
        />
      </Row>
      <Row>
        <SidebarDataRow
          label={t('label.loan_repayment')}
          value={borrowerPayout.loan_repayment}
          displayValue={t('common.money', { value: borrowerPayout.loan_repayment })}
        />
        <SidebarDataRow
          id="basic_and_project_bonus_interest"
          label={t('label.standard_interest')}
          tooltip={'Basic interest + Project bonus interest = SUM'}
          value={multipleArgSum(
            borrowerPayout?.basic_interest_no_gpm,
            borrowerPayout?.basic_interest_only_gpm,
            borrowerPayout?.project_bonus_interest_no_gpm,
            borrowerPayout?.project_bonus_interest_only_gpm,
          )}
          displayValue={t('common.money', {
            value: multipleArgSum(
              borrowerPayout?.basic_interest_no_gpm,
              borrowerPayout?.basic_interest_only_gpm,
              borrowerPayout?.project_bonus_interest_no_gpm,
              borrowerPayout?.project_bonus_interest_only_gpm,
            ),
          })}
        />
        <UncontrolledTooltip
          placement="bottom"
          target="basic_and_project_bonus_interest"
          trigger="click"
          style={{ maxWidth: 600 }}
        >
          <Row>
            <SidebarDataRow
              colProps={{
                lg: 6,
                md: 6,
                sm: 12,
                xs: 12,
              }}
              label={t('label.basic_interest')}
              tooltip={'NO GPM+ONLY GPM = SUM'}
              value={multipleArgSum(
                borrowerPayout?.basic_interest_no_gpm,
                borrowerPayout?.basic_interest_only_gpm,
              )}
              displayValue={
                t('common.money', { value: borrowerPayout.basic_interest_no_gpm }) +
                '+' +
                t('common.money', { value: borrowerPayout.basic_interest_only_gpm }) +
                ' = ' +
                t('common.money', {
                  value: multipleArgSum(
                    borrowerPayout?.basic_interest_no_gpm,
                    borrowerPayout?.basic_interest_only_gpm,
                  ),
                })
              }
            />
            <SidebarDataRow
              colProps={{
                lg: 6,
                md: 6,
                sm: 12,
                xs: 12,
              }}
              label={t('label.project_bonus_interest')}
              tooltip={'NO GPM+ONLY GPM = SUM'}
              value={multipleArgSum(
                borrowerPayout?.project_bonus_interest_no_gpm,
                borrowerPayout?.project_bonus_interest_only_gpm,
              )}
              displayValue={
                t('common.money', { value: borrowerPayout.project_bonus_interest_no_gpm }) +
                '+' +
                t('common.money', { value: borrowerPayout.project_bonus_interest_only_gpm }) +
                ' = ' +
                t('common.money', {
                  value: multipleArgSum(
                    borrowerPayout?.project_bonus_interest_no_gpm,
                    borrowerPayout?.project_bonus_interest_only_gpm,
                  ),
                })
              }
            />
          </Row>
        </UncontrolledTooltip>

        <SidebarDataRow
          label={t('label.additional_interest_profitus')}
          value={borrowerPayout.additional_interest_profitus}
          displayValue={t('common.money', {
            value: borrowerPayout.additional_interest_profitus,
          })}
        />
        <SidebarDataRow
          label={t('label.additional_interest_investment')}
          tooltip={'NO GPM+ONLY GPM = SUM'}
          value={multipleArgSum(
            borrowerPayout?.additional_interest_investment_no_gpm,
            borrowerPayout?.additional_interest_investment_only_gpm,
          )}
          displayValue={
            t('common.money', { value: borrowerPayout.additional_interest_investment_no_gpm }) +
            '+' +
            t('common.money', { value: borrowerPayout.additional_interest_investment_only_gpm }) +
            ' = ' +
            t('common.money', {
              value: multipleArgSum(
                borrowerPayout?.additional_interest_investment_no_gpm,
                borrowerPayout?.additional_interest_investment_only_gpm,
              ),
            })
          }
        />

        <SidebarDataRow
          label={t('label.fine_on_pre_return')}
          value={borrowerPayout.fine_on_pre_return}
          displayValue={t('common.money', { value: borrowerPayout.fine_on_pre_return })}
        />
        <SidebarDataRow
          label={t('label.debt_fee')}
          value={borrowerPayout.debt_fee}
          displayValue={t('common.money', { value: borrowerPayout.debt_fee })}
        />

        <div className="borrower_payout_grand_total">
          <strong>{t('label.grand_total')}</strong>
          <span>
            {t('common.money', {
              value: borrowerPayout?.borrower_payout_grand_total,
            })}
          </span>
        </div>
        <hr />
      </Row>
      <h3>{t('payments.borrower_payouts.borrower_payout')}</h3>

      <Row>
        <SidebarDataRow
          label={t('label.loan_repayment')}
          value={borrowerPayout.loan_repayment}
          displayValue={t('common.money', { value: borrowerPayout.loan_repayment })}
        />
        <SidebarDataRow
          label={t('label.borrower_payout_interest_total')}
          tooltip={`NO GPM(${borrowerPayout?.borrower_payout_interest_total.no_gpm}) + ONLY GPM(${borrowerPayout?.borrower_payout_interest_total.only_gpm}) = SUM`}
          value={borrowerPayout?.borrower_payout_interest_total.sum_round}
          displayValue={
            t('common.money', {
              value: borrowerPayout?.borrower_payout_interest_total.no_gpm_round,
            }) +
            '+' +
            t('common.money', {
              value: borrowerPayout?.borrower_payout_interest_total.only_gpm,
            }) +
            ' = ' +
            t('common.money', {
              value: borrowerPayout?.borrower_payout_interest_total.sum_round,
            })
          }
        />

        <SidebarDataRow
          label={t('label.borrower_payout_profitus_bonus')}
          tooltip={'NO GPM + ONLY GPM = SUM'}
          value={multipleArgSum(
            borrowerPayout?.profitus_bonus_interest_no_gpm,
            borrowerPayout?.profitus_bonus_interest_only_gpm,
          )}
          displayValue={
            t('common.money', {
              value: multipleArgSum(borrowerPayout?.profitus_bonus_interest_no_gpm),
            }) +
            '+' +
            t('common.money', {
              value: multipleArgSum(borrowerPayout?.profitus_bonus_interest_only_gpm),
            }) +
            ' = ' +
            t('common.money', {
              value: multipleArgSum(
                borrowerPayout?.profitus_bonus_interest_no_gpm,
                borrowerPayout?.profitus_bonus_interest_only_gpm,
              ),
            })
          }
        />
        <SidebarDataRow
          label={t('label.borrower_payout_total')}
          value={borrowerPayout?.borrower_payout_grand_total}
          displayValue={t('common.money', {
            value: borrowerPayout?.borrower_payout_grand_total,
          })}
        />
      </Row>

      <h3>{t('payments.borrower_payouts.investor_payouts')}</h3>
      <Row>
        <SidebarDataRow
          label={t('label.loan_repayment')}
          value={borrowerPayout?.investment_payout_loan_repayment}
          displayValue={t('common.money', {
            value: borrowerPayout?.investment_payout_loan_repayment,
          })}
        />

        <SidebarDataRow
          id="borrower_interest_approx"
          tooltip={'NO GPM + ONLY GPM = SUM'}
          label={t('label.investment_payout_borrower_interest')}
          value={multipleArgSum(
            borrowerPayout?.investment_payouts_sum_borrower_total_interest_no_gpm,
            borrowerPayout?.investment_payouts_sum_borrower_total_interest_only_gpm,
          )}
          displayValue={
            t('common.money', {
              value: borrowerPayout?.investment_payouts_sum_borrower_total_interest_no_gpm,
            }) +
            '+' +
            t('common.money', {
              value: borrowerPayout?.investment_payouts_sum_borrower_total_interest_only_gpm,
            }) +
            ' = ' +
            t('common.money', {
              value: multipleArgSum(
                borrowerPayout?.investment_payouts_sum_borrower_total_interest_no_gpm,
                borrowerPayout?.investment_payouts_sum_borrower_total_interest_only_gpm,
              ),
            })
          }
        />
        <UncontrolledTooltip
          placement="bottom"
          target="borrower_interest_approx"
          trigger="click"
          style={{ maxWidth: 600 }}
        >
          <Row>
            <SidebarDataRow
              colProps={{
                lg: 4,
                md: 4,
                sm: 12,
                xs: 12,
              }}
              label={t('label.additional_interest_investment')}
              tooltip={'NO GPM+ONLY GPM = SUM'}
              value={multipleArgSum(
                borrowerPayout?.investment_payout_additional_interest_investment_no_gpm,
                borrowerPayout?.investment_payout_additional_interest_investment_only_gpm,
              )}
              displayValue={
                '~' +
                t('common.money', {
                  value: borrowerPayout.investment_payout_additional_interest_investment_no_gpm,
                }) +
                '+ ~' +
                t('common.money', {
                  value: borrowerPayout.investment_payout_additional_interest_investment_only_gpm,
                }) +
                ' =  ~' +
                t('common.money', {
                  value: multipleArgSum(
                    borrowerPayout?.investment_payout_additional_interest_investment_no_gpm,
                    borrowerPayout?.investment_payout_additional_interest_investment_only_gpm,
                  ),
                })
              }
            />
            <SidebarDataRow
              colProps={{
                lg: 4,
                md: 4,
                sm: 12,
                xs: 12,
              }}
              label={t('label.basic_interest')}
              tooltip={'NO GPM+ONLY GPM = SUM'}
              value={multipleArgSum(
                borrowerPayout?.investment_payout_basic_interest_no_gpm,
                borrowerPayout?.investment_payout_basic_interest_only_gpm,
              )}
              displayValue={
                '~' +
                t('common.money', {
                  value: borrowerPayout.investment_payout_basic_interest_no_gpm,
                }) +
                '+ ~' +
                t('common.money', {
                  value: borrowerPayout.investment_payout_basic_interest_only_gpm,
                }) +
                ' =  ~' +
                t('common.money', {
                  value: multipleArgSum(
                    borrowerPayout?.investment_payout_basic_interest_no_gpm,
                    borrowerPayout?.investment_payout_basic_interest_only_gpm,
                  ),
                })
              }
            />
            <SidebarDataRow
              colProps={{
                lg: 4,
                md: 4,
                sm: 12,
                xs: 12,
              }}
              label={t('label.project_bonus_interest')}
              tooltip={'NO GPM+ONLY GPM = SUM'}
              value={multipleArgSum(
                borrowerPayout?.investment_payout_project_bonus_interest_no_gpm,
                borrowerPayout?.investment_payout_project_bonus_interest_only_gpm,
              )}
              displayValue={
                '~' +
                t('common.money', {
                  value: borrowerPayout.investment_payout_project_bonus_interest_no_gpm,
                }) +
                '+ ~' +
                t('common.money', {
                  value: borrowerPayout.investment_payout_project_bonus_interest_only_gpm,
                }) +
                ' =  ~' +
                t('common.money', {
                  value: multipleArgSum(
                    borrowerPayout?.investment_payout_project_bonus_interest_no_gpm,
                    borrowerPayout?.investment_payout_project_bonus_interest_only_gpm,
                  ),
                })
              }
            />
          </Row>
        </UncontrolledTooltip>
        <SidebarDataRow
          label={t('label.investment_payout_profitus_bonus_interest')}
          tooltip={'NO GPM+ONLY GPM = SUM'}
          value={multipleArgSum(
            borrowerPayout?.investment_payout_profitus_bonus_interest_no_gpm,
            borrowerPayout?.investment_payout_profitus_bonus_interest_only_gpm,
          )}
          displayValue={
            t('common.money', {
              value: borrowerPayout.investment_payout_profitus_bonus_interest_no_gpm,
            }) +
            '+' +
            t('common.money', {
              value: borrowerPayout.investment_payout_profitus_bonus_interest_only_gpm,
            }) +
            ' = ' +
            t('common.money', {
              value: multipleArgSum(
                borrowerPayout?.investment_payout_profitus_bonus_interest_no_gpm,
                borrowerPayout?.investment_payout_profitus_bonus_interest_only_gpm,
              ),
            })
          }
        />
        <SidebarDataRow
          label={t('label.investment_payouts_total')}
          value={borrowerPayout?.investment_payout_grand_total}
          displayValue={t('common.money', {
            value: borrowerPayout?.investment_payout_grand_total,
          })}
        />
      </Row>
    </React.Fragment>
  );
};

export default withBorrowerPayout(TabInvestmentPayouts);
