import Client from './client';
import { AbortableAxiosResponse, PaginationData, PaginationDataFilter } from 'src/types';
import {
  UpdateProjectFinancialInfoRequestDto,
  ProjectInvestmentListResponseDto,
  ProjectListResponseDto,
  ProjectResponseDto,
  UpdateProjectArticleRequest,
  UpdateProjectInformationRequestDto,
  UpdateProjectInvestmentRequestDto,
  UpdateProjectOwnerRequestDto,
  UpdateProjectSecuritiesRequestDto,
  UpdateInvestorProjectLoanAgreementTypeRequestDto,
  ChangeProjectStatusRequestDto,
  GroupedInvestmentResponseDto,
  ChangeProjectStatusToFinishRequestDto,
} from 'src/types/api/projects';
import { BonusCodesDto } from 'src/types/api/bonusSystem';
import {
  AttachFilesRequestDto,
  CommonActionListResponseDto,
  DocumentUpdateRequestDto,
  EntityResponseDto,
  MediaOrderRequestDto,
  MediaRenameRequestDto,
  RedirectResponseDto,
  SignUploadRequestDto,
} from 'src/types/api/common';
import { ProjectStatus } from 'src/types/app/projects';
import { WalletP2POperationsResponseDto } from 'src/types/api/operations';
import {
  CreateReservedInvestmentRequestDto,
  EditReservedInvestmentRequestDto,
} from 'src/types/api/user';

class Project extends Client {
  api = {
    FETCH_FILTERED_PROJECTS: `${this.http.baseUrl}/projects/filter`,
    FETCH_PROJECT: `${this.http.baseUrl}/projects/project/{projectId}`,
    PREVIEW_PROJECT: `${this.http.baseUrl}/projects/project/{projectId}/preview`,
    FETCH_PROJECT_FILTERED_INVESTMENTS: `${this.http.baseUrl}/projects/project/{projectId}/investments`,
    FETCH_PROJECT_GROUPED_INVESTMENTS: `${this.http.baseUrl}/projects/project/{projectId}/investment/{projectInvestmentId}/grouped-investments`,

    DELETE_INVESTMENT: `${this.http.baseUrl}/projects/project/{projectId}/investment/{projectInvestmentId}`,

    FETCH_PROJECT_FILTERED_RESERVED_INVESTMENTS: `${this.http.baseUrl}/projects/project/{projectId}/reserved-investments`,
    CREATE_RESERVED_INVESTMENT: `${this.http.baseUrl}/projects/project/{projectId}/reserved-investment`,
    UPLOAD_RESERVED_INVESTMENT_TAC: `${this.http.baseUrl}/projects/project/{projectId}/reserved-investment/investment-tac`,
    UPDATE_RESERVED_INVESTMENT: `${this.http.baseUrl}/projects/project/{projectId}/reserved-investment/{projectInvestmentId}`,
    DELETE_RESERVED_INVESTMENT: `${this.http.baseUrl}/projects/project/{projectId}/reserved-investment/{projectInvestmentId}`,
    MAKE_RESERVED_INVESTMENT_AS_REGULAR_INVESTMENT: `${this.http.baseUrl}/projects/project/{projectId}/reserved-investment/{projectInvestmentId}/unmark-as-reserved`,

    FETCH_WALLET_P2P_OPERATIONS: `${this.http.baseUrl}/projects/project/{projectId}/operations/p2p`,
    FETCH_PROJECT_ACTIONS: `${this.http.baseUrl}/projects/project/{projectId}/actions`,
    FETCH_PROJECT_ACTIONS_STATUS: `${this.http.baseUrl}/projects/project/{projectId}/actions/status`,

    STORE_PROJECT_INVESTMENT: `${this.http.baseUrl}/projects/project/{projectId}/investment`,
    STORE_PROJECT_FINANCIAL_INFO: `${this.http.baseUrl}/projects/project/{projectId}/financial-info`,
    STORE_PROJECT_INFORMATION: `${this.http.baseUrl}/projects/project/{projectId}/project-information`,
    STORE_PROJECT_OWNER: `${this.http.baseUrl}/projects/project/{projectId}/owner`,
    STORE_PROJECT_SECURITIES: `${this.http.baseUrl}/projects/project/{projectId}/securities`,

    UPDATE_INVESTMENT_BONUSES: `${this.http.baseUrl}/projects/project/{projectId}/investment/{investmentId}/add-bonus`,

    UPLOAD_DOCUMENTS: `${this.http.baseUrl}/projects/project/{projectId}/documents`,
    ATTACH_DOCUMENTS: `${this.http.baseUrl}/projects/project/{projectId}/documents`,
    DETACH_DOCUMENTS: `${this.http.baseUrl}/projects/project/{projectId}/documents/{mediaId}`,
    UPLOAD_DOCUMENTS_DEVELOPER_LOAN_AGREEMENT: `${this.http.baseUrl}/projects/project/{projectId}/documents/loan-agreement/developer`,
    ATTACH_DOCUMENTS_DEVELOPER_LOAN_AGREEMENT: `${this.http.baseUrl}/projects/project/{projectId}/documents/loan-agreement/developer`,
    UPLOAD_DOCUMENTS_INVESTOR_LOAN_AGREEMENT_TEMPLATE: `${this.http.baseUrl}/projects/project/{projectId}/documents/loan-agreement/investor/template`,
    UPDATE_DOCUMENTS_INVESTOR_LOAN_AGREEMENT_TEMPLATE: `${this.http.baseUrl}/projects/project/{projectId}/documents/loan-agreement/investor/template`,
    PREVIEW_DOCUMENTS_INVESTOR_LOAN_AGREEMENT_TEMPLATE: `${this.http.baseUrl}/projects/project/{projectId}/documents/loan-agreement/investor/template`,
    UPDATE_DOCUMENT: `${this.http.baseUrl}/projects/project/{projectId}/documents/{mediaId}`,
    UPLOAD_PICTURES: `${this.http.baseUrl}/projects/project/{projectId}/pictures`,
    ATTACH_PICTURES: `${this.http.baseUrl}/projects/project/{projectId}/pictures`,
    DETACH_PICTURES: `${this.http.baseUrl}/projects/project/{projectId}/pictures/{mediaId}`,
    RENAME_MEDIA: `${this.http.baseUrl}/projects/project/{projectId}/rename/{mediaId}`,
    UPDATE_ORDER: `${this.http.baseUrl}/projects/project/{projectId}/order`,
    STORE_NEWS_ARTICLE: `${this.http.baseUrl}/projects/project/{projectId}/articles/news`,
    UPDATE_ARTICLE: `${this.http.baseUrl}/projects/project/{projectId}/articles/article/{articleId}`,
    DELETE_ARTICLE: `${this.http.baseUrl}/projects/project/{projectId}/articles/{articleId}`,
    STAGE_PROJECT: `${this.http.baseUrl}/projects/project/{projectId}/stage`,

    MARK_READY_FOR_INVESTMENTS: `${this.http.baseUrl}/projects/project/{projectId}/mark/ready-for-investments`,
    MARK_COMING_SOON: `${this.http.baseUrl}/projects/project/{projectId}/mark/coming-soon`,
    MARK_OPEN_FOR_INVESTMENTS: `${this.http.baseUrl}/projects/project/{projectId}/mark/open-for-investments`,
    MARK_FUNDED: `${this.http.baseUrl}/projects/project/{projectId}/mark/funded`,
    MARK_NOT_FUNDED: `${this.http.baseUrl}/projects/project/{projectId}/mark/not-funded`,
    MARK_CONFIRMED: `${this.http.baseUrl}/projects/project/{projectId}/mark/confirmed`,
    MARK_FINISHED: `${this.http.baseUrl}/projects/project/{projectId}/mark/finished`,
  };

  fetchFilteredProjects = (
    request: PaginationDataFilter | undefined = undefined,
  ): AbortableAxiosResponse<PaginationData<ProjectListResponseDto>> => {
    return this.http.abortableGet(this.api.FETCH_FILTERED_PROJECTS, { params: request });
  };

  fetchWalletP2POperations = (
    projectId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<WalletP2POperationsResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_WALLET_P2P_OPERATIONS, { projectId });

    return this.http.get(url, { params: request });
  };

  filterProjectInvestments = (
    projectId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ProjectInvestmentListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT_FILTERED_INVESTMENTS, { projectId });

    return this.http.get(url, { params: request });
  };

  filterGroupedProjectInvestments = (
    projectId: string | number,
    projectInvestmentId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<GroupedInvestmentResponseDto[]> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT_GROUPED_INVESTMENTS, {
      projectId,
      projectInvestmentId,
    });

    return this.http.get(url, { params: request });
  };

  cancelInvestment = (
    projectId: string | number,
    projectInvestmentId: string | number,
  ): Promise<ProjectInvestmentListResponseDto> => {
    const url = this.buildUrl(this.api.DELETE_INVESTMENT, {
      projectId,
      projectInvestmentId,
    });

    return this.http.delete(url);
  };

  filterProjectReservedInvestments = (
    projectId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ProjectInvestmentListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT_FILTERED_RESERVED_INVESTMENTS, { projectId });

    return this.http.get(url, { params: request });
  };

  createReservedInvestment = (
    projectId: string | number,
    request: CreateReservedInvestmentRequestDto | undefined = undefined,
  ): Promise<ProjectInvestmentListResponseDto> => {
    const url = this.buildUrl(this.api.CREATE_RESERVED_INVESTMENT, { projectId });

    return this.http.post(url, request);
  };

  updateReservedInvestment = (
    projectId: string | number,
    projectInvestmentId: string | number,
    request: EditReservedInvestmentRequestDto | undefined = undefined,
  ): Promise<ProjectInvestmentListResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_RESERVED_INVESTMENT, {
      projectId,
      projectInvestmentId,
    });

    return this.http.put(url, request);
  };

  deleteReservedInvestment = (
    projectId: string | number,
    projectInvestmentId: string | number,
  ): Promise<ProjectInvestmentListResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_RESERVED_INVESTMENT, {
      projectId,
      projectInvestmentId,
    });

    return this.http.delete(url);
  };

  makeReservedInvestmentAsRegularInvestment = (
    projectId: string | number,
    projectInvestmentId: string | number,
  ): Promise<ProjectInvestmentListResponseDto> => {
    const url = this.buildUrl(this.api.MAKE_RESERVED_INVESTMENT_AS_REGULAR_INVESTMENT, {
      projectId,
      projectInvestmentId,
    });

    return this.http.put(url, { params: {} });
  };

  fetchProjectActionsStatus = (
    projectId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CommonActionListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT_ACTIONS_STATUS, { projectId });

    return this.http.get(url, { params: request });
  };

  fetchProjectActions = (
    projectId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CommonActionListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT_ACTIONS, { projectId });

    return this.http.get(url, { params: request });
  };

  fetchProject = (projectId: string | number): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT, { projectId });

    return this.http.get(url);
  };

  previewProject = (projectId: string | number): Promise<RedirectResponseDto> => {
    const url = this.buildUrl(this.api.PREVIEW_PROJECT, { projectId });

    return this.http.get(url);
  };

  uploadDocuments = (projectId: string | number, request: SignUploadRequestDto): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_DOCUMENTS, { projectId });
    // TODO: recheck UPLOAD_DOCUMENTS url
    return this.http.put(url, request);
  };

  uploadProjectDeveloperLoanAgreementDocument = (
    projectId: string | number,
    request: SignUploadRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_DOCUMENTS_DEVELOPER_LOAN_AGREEMENT, { projectId });
    return this.http.put(url, request);
  };

  uploadReservedInvestmentTacDocument = (
    projectId: string | number,
    request: SignUploadRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_RESERVED_INVESTMENT_TAC, { projectId });
    return this.http.post(url, request);
  };

  attachProjectDeveloperLoanAgreementDocument = (
    projectId: string | number,
    request: AttachFilesRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.ATTACH_DOCUMENTS_DEVELOPER_LOAN_AGREEMENT, { projectId });

    return this.http.post(url, request);
  };

  uploadInvestorLoanAgreementDocumentTemplate = (
    projectId: string | number,
    request: SignUploadRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_DOCUMENTS_INVESTOR_LOAN_AGREEMENT_TEMPLATE, {
      projectId,
    });
    return this.http.put(url, request);
  };

  updateInvestorLoanAgreementDocumentTemplate = (
    projectId: string | number,
    request: UpdateInvestorProjectLoanAgreementTypeRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPDATE_DOCUMENTS_INVESTOR_LOAN_AGREEMENT_TEMPLATE, {
      projectId,
    });
    return this.http.post(url, request);
  };

  previewInvestorLoanTemplate = (projectId: string | number): Promise<Blob> => {
    const url = this.buildUrl(this.api.PREVIEW_DOCUMENTS_INVESTOR_LOAN_AGREEMENT_TEMPLATE, {
      projectId,
    });

    return this.http.get(url, {
      responseType: 'blob',
    });
  };

  storeProjectInvestment = (
    projectId: string | number,
    request: UpdateProjectInvestmentRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.STORE_PROJECT_INVESTMENT, { projectId });
    return this.http.put(url, request);
  };

  updateInvestmentBonuses = (
    projectId: string | number,
    investmentId: string | number,
    request: BonusCodesDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_INVESTMENT_BONUSES, { projectId, investmentId });
    return this.http.post(url, request);
  };

  storeProjectFinancialInfo = (
    projectId: string | number,
    request: UpdateProjectFinancialInfoRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.STORE_PROJECT_FINANCIAL_INFO, { projectId });
    return this.http.put(url, request);
  };

  storeProjectInformation = (
    projectId: string | number,
    request: UpdateProjectInformationRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.STORE_PROJECT_INFORMATION, { projectId });
    return this.http.put(url, request);
  };

  storeProjectOwner = (
    projectId: string | number,
    request: UpdateProjectOwnerRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.STORE_PROJECT_OWNER, { projectId });
    return this.http.put(url, request);
  };

  storeProjectSecurities = (
    projectId: string | number,
    request: UpdateProjectSecuritiesRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.STORE_PROJECT_SECURITIES, { projectId });
    return this.http.put(url, request);
  };

  attachDocuments = (
    projectId: string | number,
    request: AttachFilesRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.ATTACH_DOCUMENTS, { projectId });

    return this.http.post(url, request);
  };

  detachDocuments = (
    projectId: string | number,
    mediaId: string | number,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.DETACH_DOCUMENTS, { projectId, mediaId });

    return this.http.delete(url);
  };

  uploadPictures = (projectId: string | number, request: SignUploadRequestDto): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_PICTURES, { projectId });

    return this.http.put(url, request);
  };

  attachPictures = (
    projectId: string | number,
    request: AttachFilesRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.ATTACH_PICTURES, { projectId });

    return this.http.post(url, request);
  };

  detachPictures = (
    projectId: string | number,
    mediaId: string | number,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.DETACH_PICTURES, { projectId, mediaId });

    return this.http.delete(url);
  };

  updateOrder = (
    projectId: string | number,
    request: MediaOrderRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_ORDER, { projectId });

    return this.http.post(url, request);
  };

  renameMedia = (
    projectId: string | number,
    mediaId: string | number,
    request: MediaRenameRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.RENAME_MEDIA, { projectId, mediaId });

    return this.http.put(url, request);
  };

  updateDocument = (
    projectId: string | number,
    mediaId: string | number,
    request: DocumentUpdateRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_DOCUMENT, { projectId, mediaId });

    return this.http.put(url, request);
  };

  storeArticleNews = (
    projectId: string | number,
    request: UpdateProjectArticleRequest,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.STORE_NEWS_ARTICLE, { projectId });

    return this.http.post(url, request);
  };

  updateArticle = (
    projectId: string | number,
    articleId: string | number,
    request: UpdateProjectArticleRequest,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_ARTICLE, { projectId, articleId });

    return this.http.put(url, request);
  };

  deleteArticle = (
    projectId: string | number,
    articleId: string | number,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.DELETE_ARTICLE, { projectId, articleId });

    return this.http.delete(url);
  };

  stageProject = (projectId: string | number): Promise<EntityResponseDto> => {
    const url = this.buildUrl(this.api.STAGE_PROJECT, { projectId });

    return this.http.post(url);
  };

  markProjectForStatus = (
    projectId: string | number,
    status: ProjectStatus,
  ): Promise<ProjectResponseDto> => {
    switch (status) {
      case ProjectStatus.ComingSoon:
        return this.markAsComingSoon(projectId);
      case ProjectStatus.OpenForInvestments:
        return this.markAsOpenForInvestments(projectId);
      case ProjectStatus.ReadyForInvestments:
        return this.markAsReadyForInvestments(projectId);
      case ProjectStatus.Confirmed:
        return this.markAsConfirmed(projectId, {
          ignore_validation: false,
        });
      case ProjectStatus.Funded:
        return this.markAsFunded(projectId);
      case ProjectStatus.NotFunded:
        return this.markAsNotFunded(projectId);
      case ProjectStatus.Finished:
        return this.markAsFinished(projectId, {
          disable_notifications: false,
        });
    }

    throw 'error';
  };

  markAsReadyForInvestments = (projectId: string | number): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.MARK_READY_FOR_INVESTMENTS, { projectId });

    return this.http.put(url);
  };

  markAsComingSoon = (projectId: string | number): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.MARK_COMING_SOON, { projectId });

    return this.http.put(url);
  };

  markAsOpenForInvestments = (projectId: string | number): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.MARK_OPEN_FOR_INVESTMENTS, { projectId });

    return this.http.put(url);
  };

  markAsFunded = (projectId: string | number): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.MARK_FUNDED, { projectId });

    return this.http.put(url);
  };

  markAsNotFunded = (projectId: string | number): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.MARK_NOT_FUNDED, { projectId });

    return this.http.put(url);
  };

  markAsConfirmed = (
    projectId: string | number,
    request: ChangeProjectStatusRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.MARK_CONFIRMED, { projectId });

    return this.http.put(url, request);
  };

  markAsFinished = (
    projectId: string | number,
    request: ChangeProjectStatusToFinishRequestDto,
  ): Promise<ProjectResponseDto> => {
    const url = this.buildUrl(this.api.MARK_FINISHED, { projectId });

    return this.http.put(url, request);
  };
}

export default Project;
