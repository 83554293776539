import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';

import {
  BorrowerPayoutResponseDto,
  PaymentResponseDto,
} from 'src/types/api/payments/projectPayouts';
import {
  PaymentContextState,
  withPayment,
} from 'src/pages/Payment/ProjectPayments/Update/PaymentContext';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import usePermissions from 'src/helpers/usePermissions';
import { Permissions } from 'src/helpers/auth/permissions';

interface Props extends PaymentContextState {
  payout: BorrowerPayoutResponseDto;
}

const UpdateBorrowerPayoutPaymentDate: React.FC<Props> = ({ payout, payment, setPayment }) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const { t } = useTranslation();
  const { can } = usePermissions();

  useEffect(() => {
    setStartDate(new Date(payout.payment_date));
  }, [payout]);

  const isDisabled = () => {
    if (can(Permissions.BO__PAYMENTS__PROJECT_PAYOUTS__UPDATE__CONFIRMED_BORROWER_PAYOUTS)) {
      return false;
    }

    return payment?.project_loan?.borrower_payout_dates_confirmed ?? true;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEventHandler = useCallback(
    debounce((date: string, paymentId: string, callback: (r: PaymentResponseDto) => void) => {
      Api.payments.projectPayouts
        .updateBorrowerPayoutDate(paymentId, payout.id, {
          payment_date: date,
        })
        .then(callback);
    }, 300),
    [],
  );

  return (
    <React.Fragment>
      <DatePicker
        dateFormat={'yyyy-MM-dd'}
        selected={startDate}
        disabled={isDisabled()}
        onChange={(date) => {
          if (payment && date) {
            const d = format(date, 'yyyy-MM-dd');
            debouncedEventHandler(d, payment.id, (response: PaymentResponseDto) => {
              setPayment(response);
              success(t('common.updated_success'));
            });

            setStartDate(date);
          }
        }}
      />
    </React.Fragment>
  );
};

export default withPayment(UpdateBorrowerPayoutPaymentDate);
