import React, { useState } from 'react';
import SelectInput, { ReactSelectOption } from './SelectInput';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { RiskCategoryEnum } from 'src/helpers/Enums/Application/RiskCategoryEnum';

interface Props extends StateManagerProps {
  name: string;
  placeholder: string;
}

const RiskCategories: Array<string> = Object.values(RiskCategoryEnum);

const SelectRiskCategoryInput: React.FC<Props> = (props) => {
  const [options] = useState<ReactSelectOption[]>([
    ...RiskCategories.map((riskCategory) => ({
      label: riskCategory,
      value: riskCategory,
    })),
  ]);

  return <SelectInput {...props} options={options} />;
};

export default SelectRiskCategoryInput;
