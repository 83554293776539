import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { UserRiskLevelEnum } from 'src/helpers/Enums/UserRiskLevelEnum';

interface Props {
  value: UserRiskLevelEnum | null;
}

const UserRiskLevelBadge: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();

  const getClass = () => {
    switch (value) {
      case UserRiskLevelEnum.LOW:
        return 'bg-success';
      case UserRiskLevelEnum.MEDIUM:
        return 'bg-warning';
      case UserRiskLevelEnum.HIGH:
        return 'bg-danger';
      default:
        return '';
    }
  };

  return (
    <Badge className={getClass()}>
      {value != null ? t('users.aml.risk_level.' + value) : '---'}
    </Badge>
  );
};

export default UserRiskLevelBadge;
