import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Col, ColProps, Row } from 'reactstrap';
import DataBlock from 'src/components/DataBlocks/DataBlock';
import { useTranslation } from 'react-i18next';
import {
  CalculatePartialReturnResponseDto,
  CreatePartialReturnRequestDto,
} from 'src/types/api/payments/borrowerPayouts';
import Loader from 'src/components/Loader';
import { error } from 'src/services/toastr';

interface Props {
  setStartedCalculating: (calculating: boolean) => void;
  calculated: boolean;
  data: CalculatePartialReturnResponseDto | undefined;
}

const PartialReturnInformation: React.FC<Props> = ({
  setStartedCalculating,
  calculated,
  data,
}: Props) => {
  const { t } = useTranslation();
  const { isValid } = useFormikContext<CreatePartialReturnRequestDto>();

  useEffect(() => {
    if (!isValid) {
      error(t('common.invalid_form'));
      setStartedCalculating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const colProps: ColProps = {
    lg: 6,
    sm: 12,
    className: 'mb-2',
  };

  return (
    <React.Fragment>
      {calculated ? (
        <>
          <Row>
            <Col {...colProps}>
              <DataBlock
                label={t('label.basic_interest')}
                value={t('common.money', {
                  value: data?.basic_interest ?? 0,
                })}
              />
            </Col>
            <Col {...colProps}>
              <DataBlock
                label={t('label.fine_on_pre_return')}
                value={t('common.money', {
                  value: data?.fine_on_pre_return ?? 0,
                })}
              />
            </Col>
            <Col {...colProps}>
              <DataBlock
                label={t('label.additional_interest')}
                value={t('common.money', {
                  value: data?.additional_interest ?? 0,
                })}
              />
            </Col>
            <Col {...colProps}>
              <DataBlock
                label={t('label.debt_fee')}
                value={t('common.money', {
                  value: data?.debt_fee ?? 0,
                })}
              />
            </Col>
            <Col {...colProps}>
              <DataBlock
                label={t('label.loan_repayment')}
                value={t('common.money', {
                  value: data?.loan_repayment ?? 0,
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col {...colProps}>
              <DataBlock
                label={t('label.total')}
                value={t('common.money', {
                  value: data?.total ?? 0,
                })}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col {...colProps}>
              <DataBlock
                label={t('label.current_loan_remaining_amount')}
                value={t('common.money', {
                  value: data?.current_loan_remaining_amount ?? 0,
                })}
              />
            </Col>
            <Col {...colProps}>
              <DataBlock
                label={t('label.loan_remaining_amount_after')}
                value={t('common.money', {
                  value: (data?.current_loan_remaining_amount ?? 0) - (data?.loan_repayment ?? 0),
                })}
              />
            </Col>
          </Row>
          {(data?.current_loan_remaining_amount ?? 0) - (data?.loan_repayment ?? 0) < 1 && (
            <Row>
              <span className="text-danger">
                {t('payments.borrower_payouts.partial_return.warning')}
              </span>
            </Row>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loader />
        </div>
      )}
    </React.Fragment>
  );
};

export default PartialReturnInformation;
