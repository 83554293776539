import React, { useEffect, useState } from 'react';
import { FilterValue } from 'react-table';
import { FilterSelectValuesResponseDto } from 'src/types/api/common';

export interface ColumnFilterProps {
  column: {
    filterValue: FilterValue;
    setFilter: (updater: ((filterValue: FilterValue) => FilterValue) | FilterValue) => void;
  };
  request?: Promise<FilterSelectValuesResponseDto[]>;
  options?: FilterSelectValuesResponseDto[];
}

const ColumnFilter: React.FC<ColumnFilterProps> = ({ column, request, options }) => {
  const [data, setData] = useState<FilterSelectValuesResponseDto[]>([]);

  useEffect(() => {
    if (request) {
      request.then((response) => setData(response));
    } else if (options) {
      setData(options);
    }
  }, [options, request]);

  if (!request && !options) return null;

  return (
    <select
      value={column.filterValue}
      onChange={(e) => {
        column.setFilter(e.target.value || undefined);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={'form-control'}
    >
      <option value="">All</option>
      {data.map((option, i) => (
        <option key={i} value={option.value}>
          {option.key}
        </option>
      ))}
    </select>
  );
};

export default ColumnFilter;
