import React, { FunctionComponent, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PermissionAction from 'src/components/PermissionAction';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiArrowExpandHorizontal, mdiFormatHorizontalAlignCenter } from '@mdi/js';
import {
  ApplicationContextState,
  withApplication,
} from 'src/pages/Application/Update/ApplicationContext';
import { ApplicationResponseDto, ApplicationTabDataResponseDto } from 'src/types/api/applications';
import NotificationIcon from 'src/components/NotificationIcon';
import { BaseTabProps } from 'src/components/Tabs';
import usePermissions from 'src/helpers/usePermissions';

export type ApplicationTabProps = BaseTabProps & {
  showSubmit?: boolean;
};

export interface TabInfo<Props extends ApplicationTabProps> {
  name: string;
  icon?: string;
  component: FunctionComponent<Props>;
  permission: string | string[];
  visible?: (application?: ApplicationResponseDto) => boolean;
}

interface TabDataProps<T extends ApplicationTabProps> extends ApplicationContextState {
  tabs: TabInfo<T>[];
  props?: T;
  vertical?: boolean;
  tabRef: any;
}

const ApplicationTabData: React.FC<TabDataProps<any>> = ({
  tabs,
  props,
  vertical,
  tabRef,
  application,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<TabInfo<ApplicationTabProps>>(tabs[0]);
  const [open, setOpen] = useState<boolean>(true);
  const p = usePermissions();

  useEffect(() => {
    tabRef.current = changeToProtocol;

    //calculate active tab
    tabs.every((tab) => {
      if ((!tab.visible || (tab.visible && tab.visible(application))) && p.hasAny(tab.permission)) {
        setActiveTab(tab);
        return false;
      }

      return true;
    });

    // eslint-disable-next-line
  }, []);

  const changeTab = (tab: TabInfo<ApplicationTabProps>) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const changeToProtocol = () => {
    setOpen(true);
    const protocolTab = tabs.find((tab) => tab.name == 'applications.protocol');
    if (protocolTab) changeTab(protocolTab);
  };

  const shouldShowNotification = (name: string) => {
    name = name.split('.')[1];
    const showValue = application?.tab_data[name as keyof ApplicationTabDataResponseDto];
    return showValue === null || showValue === undefined ? false : !showValue;
  };

  return (
    <React.Fragment>
      <button
        className={`btn btn-primary mb-3`}
        onClick={() => {
          setOpen((prevState) => !prevState);
        }}
      >
        <Icon path={open ? mdiFormatHorizontalAlignCenter : mdiArrowExpandHorizontal} size={1} />
      </button>

      <div className={`nav-direction-${vertical ? 'vertical' : 'horizontal'}`}>
        {open && (
          <Nav tabs justified={true} className={`vertical-nav-tabs bg-blue-400 p-2`}>
            {tabs.map(
              (tab, key) =>
                (!tab.visible || tab.visible(application)) && (
                  <PermissionAction key={key} permissions={tab.permission}>
                    <NavItem className={'text-start mb-1'}>
                      <NavLink
                        active={activeTab === tab}
                        onClick={() => changeTab(tab)}
                        className={'cursor'}
                      >
                        <div
                          className={
                            'd-flex align-middle justify-content-between text-light align-items-center'
                          }
                        >
                          <div>
                            {tab.icon && <Icon path={tab.icon} size={1} />}
                            <span className="ms-2">{t(tab.name)}</span>
                          </div>
                          <NotificationIcon
                            show={shouldShowNotification(tab.name)}
                            active={tab == activeTab}
                          />
                        </div>
                      </NavLink>
                    </NavItem>
                  </PermissionAction>
                ),
            )}
          </Nav>
        )}
        <TabContent activeTab={activeTab.name} className={`p-4`}>
          {tabs.map(
            ({ name, component: Component, permission, visible }, key) =>
              (!visible || visible(application)) && (
                <PermissionAction key={key} permissions={permission}>
                  <TabPane tabId={name}>
                    {activeTab.name === name && activeTab.component === Component && (
                      <Component showSubmit={shouldShowNotification(activeTab.name)} {...props} />
                    )}
                  </TabPane>
                </PermissionAction>
              ),
          )}
        </TabContent>
      </div>
    </React.Fragment>
  );
};

export default withApplication(ApplicationTabData);
