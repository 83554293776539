import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
}

const IsOutdatedBadge: React.FC<Props> = ({ show }) => {
  const { t } = useTranslation();

  if (!show) {
    return null;
  }

  return (
    <React.Fragment>
      <i
        style={{ lineHeight: 'unset' }}
        title={t('common.visible')}
        className={'fas fa-hourglass-end text-danger'}
      />
    </React.Fragment>
  );
};

export default IsOutdatedBadge;
