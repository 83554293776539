import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';

import { NotificationResponseDto } from 'src/types/api/notifications';
import Api from 'src/api';
import { useShortNotificationsQuery } from 'src/api/queries/useNotificaitonsQuery/useShortNotificationsQuery';

export interface NotificationsContextState {
  selectedNotification: NotificationResponseDto | undefined;
  setSelectedNotification?: (value: NotificationResponseDto) => void;
  shouldReload: boolean;
  setShouldReload?: (value: boolean) => void;
  markAllRead?: () => any;
  isLoading: boolean;
}

const NotificationsContext = createContext<NotificationsContextState>({
  selectedNotification: undefined,
  isLoading: true,
  shouldReload: false,
});

export const NotificationsProvider: React.FC = ({ children }) => {
  const [shouldReload, setShouldReload] = useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] = useState<
    NotificationResponseDto | undefined
  >(undefined);
  const location = useLocation();
  const queryClient = new QueryClient();
  const { refetch } = useShortNotificationsQuery();

  const { data, isLoading } = useQuery(
    `notification-${location.hash.replace('#', '')}`,
    async () => {
      if (location.hash === '') {
        return undefined;
      }

      return await Api.notifications.fetchNotification(location.hash.replace('#', ''));
    },
    {
      cacheTime: Infinity,
      staleTime: 24 * 60 * 60 * 1000, // 24 hours
      refetchOnWindowFocus: false,
    },
  );

  const markAllRead = async () => {
    await Api.notifications.markAllAsRead().then(() => {
      setShouldReload(!shouldReload);
      refetch();
    });
  };

  useEffect(() => {
    data && setSelectedNotification(data);
  }, [data]);

  return (
    <QueryClientProvider client={queryClient}>
      <NotificationsContext.Provider
        value={{
          selectedNotification,
          setSelectedNotification,
          isLoading,
          markAllRead,
          shouldReload,
          setShouldReload,
        }}
      >
        {children}
      </NotificationsContext.Provider>
    </QueryClientProvider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export function withNotifications<P extends NotificationsContextState = NotificationsContextState>(
  Component: React.ComponentType<P>,
) {
  const WithNotifications = ({ ...props }: Optionalize<P, NotificationsContextState>) => {
    const c = useContext(NotificationsContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithNotifications.displayName = `withNotifications${Component.displayName}`;

  return withNotifications;
}

export const useNotifications = () => {
  return useContext(NotificationsContext);
};

export default NotificationsProvider;
