import React, { useCallback } from 'react';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ActionModal from 'src/components/Modal/Modals/ActionModal';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  onAction: () => Promise<any>;
  body: string;
  actionText: string;
  title: string;
}

const ConfirmButton: React.FC<Props> = ({
  onAction,
  body,
  actionText,
  className,
  type,
  onClick,
  title,
  ...props
}) => {
  const { showModal } = useGlobalModalContext();

  const modal = useCallback(() => {
    showModal(
      <ActionModal title={title} onAction={() => onAction()} body={body} actionText={actionText} />,
    );
  }, [actionText, body, onAction, showModal, title]);

  return (
    <button
      className={className ?? 'btn btn-danger'}
      type={type ?? 'button'}
      onClick={onClick ?? modal}
      {...props}
    >
      {actionText}
    </button>
  );
};

export default ConfirmButton;
