import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  WalletMoneyOperationsResponseDto,
  WalletP2POperationsResponseDto,
} from 'src/types/api/operations';

class P2POperation extends Client {
  api = {
    FETCH_FILTERED_P2P_OPERATIONS: `${this.http.baseUrl}/operations/p2p/filter`,
    FETCH_FILTERED_MONEY_OPERATIONS: `${this.http.baseUrl}/operations/money/filter`,
  };

  fetchFilteredP2POperations = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<WalletP2POperationsResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_P2P_OPERATIONS, { params: request });
  };

  fetchFilteredMoneyOperations = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<WalletMoneyOperationsResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_MONEY_OPERATIONS, { params: request });
  };
}

export default P2POperation;
