import React from 'react';
import { Container } from 'reactstrap';
import PasswordCard from 'src/pages/Profile/Profile/Cards/PasswordCard';
import ProfileCard from 'src/pages/Profile/Profile/Cards/ProfileCard';
import { useProfileQuery } from 'src/api/queries';

const Profile: React.FC = () => {
  const { data, isLoading } = useProfileQuery();

  if (isLoading) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Container fluid>
        {data && <ProfileCard user={data} />}
        {data && <PasswordCard user={data} />}
      </Container>
    </React.Fragment>
  );
};

export default Profile;
