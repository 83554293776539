import i18n from 'i18next';
import { setLocale } from 'yup';

const buildYupLocale = () => {
  i18n.on('languageChanged', () => {
    updateYupValidationText();
  });

  updateYupValidationText();
};

function updateYupValidationText() {
  setLocale({
    mixed: {
      default: i18n.t('validation.invalid'),
      notType: i18n.t('validation.required'),
      required: i18n.t('validation.required'),
    },
    number: {
      min: ({ min }) => i18n.t('validation.number.min', { min }),
      max: ({ max }) => i18n.t('validation.number.max', { max }),
    },
    string: {
      email: i18n.t('validation.invalid'),
      min: ({ min }) => i18n.t('validation.min', { min }),
      max: ({ max }) => i18n.t('validation.max', { max }),
    },
    array: {
      min: ({ min }) => i18n.t('validation.array.min', { min }),
      max: ({ max }) => i18n.t('validation.array.max', { max }),
    },
    date: {
      min: ({ min }) => {
        min = i18n.t('common.date_full', { date: min });
        return i18n.t('validation.date.min', { min });
      },
      max: ({ max }) => {
        max = i18n.t('common.date_full', { date: max });
        return i18n.t('validation.date.max', { max });
      },
    },
  });
}

export default buildYupLocale;
